import React, { useState, useCallback, useEffect, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { createInitialFromEmail, createInitialFromUsername } from '../../helpers/CommonFunctions';

import './MentionBox.scss';

const MentionBox = ({
    users,
    handleSelectCallback,
    filter,
    customPosElementRef,
    wrapperRef,
    addComment,
    closeMentions
}) => {
    const isBuildCardLinked = useSelector((state) => !!state.board.studioBuildCardID);
    const [selected, setSelected] = useState(null);
    const [mutableUsers, setMutableUsers] = useState([]);
    const [itemIndexes, setItemIndexes] = useState({});
    const [customPos, setCustomPos] = useState();
    const [inputDistanceToBottom, setInputDistanceToBottom] = useState(0);
    const ulRef = useRef();

    const scrollToActive = () => {
        const active = ulRef?.current?.querySelector('.selected');

        if (active)
            active?.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
    };

    const handleGetMentionItems = useCallback((key) => {
        let mentionItems = document.querySelectorAll('.mention__item');
        let activeIndex = 0;
        let mutableUsersLength = mutableUsers.length - 1;
        if (mutableUsersLength === 0) return;
        mentionItems.forEach((item, index) => {
            if (item.className.includes('selected')) {
                activeIndex = index;
            }
            item.classList.remove('selected');
        })
        if (key === 'down' && mentionItems[activeIndex]) {
            setSelected(mentionItems[activeIndex === mutableUsersLength ? 0 : activeIndex + 1].dataset.id);
        }
        if (key === 'up' && mentionItems[activeIndex]) {
            setSelected(mentionItems[activeIndex === 0 ? mutableUsersLength : activeIndex -1].dataset.id);
        }
    
    },[mutableUsers])

    const handleSelect = useCallback(() => {
        const obj = users.find(user => user.id.toString() === selected);
        handleSelectCallback(obj);
    }, [users, handleSelectCallback, selected]);

    const handleSelectItem = (e) => {
        let target = e.target;
        if (e.target.className === 'mention__item--email' || e.target.className === 'mention__item--name') {
            target = e.target.parentElement;
        }
        if (target.dataset.id) {
            setSelected(target.dataset.id);
        }
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.keyCode === 38) {
                handleGetMentionItems('up');
            }

            //down
            if (event.keyCode === 40) {
                handleGetMentionItems('down');
            }
      
            if (event.key === 'Enter' || event.key === 'Tab') {
                if (addComment && mutableUsers?.length === 0) {
                    closeMentions();
                    event.key === 'Enter' && addComment();
                    return
                }
                handleSelect();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [selected, itemIndexes, mutableUsers, handleSelect]);

    useEffect(() => {
        if (filter === '' || filter === ' ') {
            setMutableUsers(users);
        } else {
            const filteredUsers = users.filter((user) =>
                user.name
                    .toLowerCase()
                    .concat(' ', user.email?.toLowerCase())
                    .concat(' ', user.commentUsername?.toLowerCase())
                    .includes(filter.toLowerCase())
            );
            setMutableUsers(filteredUsers);
        }
    }, [filter, users]);

    useEffect(() => {
        if (mutableUsers.length > 0) {
            let checkMentions = document.querySelectorAll('.mention__item');
            let countSelected = 0;
            checkMentions.forEach((item) => {
                if (item.className.includes('selected')) {
                    countSelected = 1;
                }
            })
            if (countSelected === 0) checkMentions[0].classList.add('selected');
            setSelected(mutableUsers[0].id)
        }
    }, [mutableUsers]);
    
    useEffect(() => {
        const elems = document.querySelectorAll('.mention__item');
        elems.forEach(elem => {
            elem.addEventListener('mouseover', handleSelectItem);
            elem.addEventListener('focus', handleSelectItem);
        })
        return () => {
            elems.forEach(elem => {
                elem.removeEventListener('mouseover', handleSelectItem);
                elem.removeEventListener('focus', handleSelectItem);
            })
        }
    }, [users, handleSelectCallback, selected, handleSelect])
    
    useEffect(() => {
        let query = document.querySelector('.mention__item');
        if (query) query.dispatchEvent(new Event('mouseover'));
    }, [mutableUsers])

    useEffect(() => {
        document.querySelectorAll('.mention__item').forEach(item => {
            item.classList.remove('selected');
            if (item.dataset.id === selected) {
                item.classList.add('selected')
                scrollToActive();
            }
        })
    }, [selected])

    useEffect(() => {
        setItemIndexes(
            users.reduce((acc, item, index) => ({ ...acc, [item.id]: index }), {})
        );
    }, [users]);

    useEffect(() => {
        if (
            customPosElementRef &&
      wrapperRef &&
      customPosElementRef.current &&
      wrapperRef.current
        ) {
            let contentPosition =
        wrapperRef.current.getBoundingClientRect().bottom -
        customPosElementRef.current.getBoundingClientRect().top +
        10;
            setCustomPos(contentPosition);
        }
    }, [customPosElementRef, wrapperRef]);

  
    useEffect(() => {
        let commentInput = document.querySelectorAll('.commentInput--input')[1]?.getBoundingClientRect() ?? document.querySelectorAll('.commentInput--input')[0]?.getBoundingClientRect();
        setInputDistanceToBottom(window.innerHeight - commentInput?.top);
    }, [document.querySelectorAll('.commentInput--input')])

    return (
        <ul
            className={clsx('mention__list-wrap', {
                'reverse-positioning':  inputDistanceToBottom < 280,
                'downwards': inputDistanceToBottom > 700,
                'build-card-linked': isBuildCardLinked
            })}
            data-testid="mention-box"
            ref={ulRef}
            style={
                customPos ? {
                    position: 'fixed',
                    bottom: `${inputDistanceToBottom > 700 ? customPos - ulRef?.current?.clientHeight - 40 : customPos}px`,
                } : null
            }
        >
            {mutableUsers.length > 0 ? (
                (Array.isArray(mutableUsers) ? mutableUsers.filter((user) => user.joinedViaPublicLink !== true) : []).map((item) => {
                    const isUserAlreadySigned = !(item.info?.isUserSigned === false && item.name === 'FirstName');

                    return (
                        <li
                            className={clsx('mention__item', {
                                selected: item.id === selected,
                            })}
                            data-id={item.id}
                            key={item.id}
                            onClick={() => handleSelect()}
                            onMouseEnter={() => setSelected(item.id)}
                            role="button"
                            tabIndex="0"
                        >
                            <article>
                                <aside className="avatar-section">
                                    {item.avatar ? (
                                        <img alt={`${item.name} Avatar`} src={item.avatar} />
                                    ) : (
                                        <span>{isUserAlreadySigned ? createInitialFromUsername(item.name) : createInitialFromEmail(item.email)}</span>
                                    )}
                                </aside>
                                <main>
                                    <p className="mention__item--name">{isUserAlreadySigned ? item.name : '<no name>'}</p>
                                    {!isBuildCardLinked ? (
                                        <p className="mention__item--email">{item.email}</p>
                                    ) : null}
                                </main>
                            </article>
                        </li>
                    )
                })
            ) : (
                <li className="no-user-found">No suggestions</li>
            )}
        </ul>
    );
};

MentionBox.propTypes = {
    users: PropTypes.arrayOf(PropTypes.shape({
        commentEmail: PropTypes.string,
        commentUsername: PropTypes.string,
        email: PropTypes.string,
        id: PropTypes.number,
        name: PropTypes.string,
        permission: PropTypes.string
    })),
    handleSelectCallback: PropTypes.func.isRequired,
    filter: PropTypes.string,
    customPosElementRef: PropTypes.shape({
        current: PropTypes.instanceOf(HTMLTextAreaElement)
    }),
    wrapperRef: PropTypes.shape({
        current: PropTypes.instanceOf(HTMLDivElement)
    }),
    addComment: PropTypes.func.isRequired,
    closeMentions: PropTypes.func.isRequired,
}

export default MentionBox;
