import React from 'react';

const SeperatorItem = () => {
    return (
        <div className="seperator" />
    )
};

SeperatorItem.propTypes = {}

export default SeperatorItem;