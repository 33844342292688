/**
 * @param lineIndex
 * @param charIndex
 */
export default function _getStyleDeclaration(lineIndex, charIndex) {
    var lineStyle = this.styles && this.styles[lineIndex];
    if (!lineStyle) {
        return null;
    }
    
    const charStyle = lineStyle[charIndex];
    if (charStyle && charStyle.fontFamily) {
        charStyle.fontFamily = charStyle.fontFamily.replace(/"/g, '\'');
    }
    return charStyle;
}