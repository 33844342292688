import React from 'react';
import PropTypes from 'prop-types';

const UsersMultipleNeutral2 = ({ width = 24, height = 24 }) => (
    <svg
        fill="none"
        height={height}
        viewBox="0 0 24 18"
        width={width}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            clipRule="evenodd"
            d="M7.5 9C9.77817 9 11.625 7.15317 11.625 4.875C11.625 2.59683 9.77817 0.75 7.5 0.75C5.22183 0.75 3.375 2.59683 3.375 4.875C3.375 7.15317 5.22183 9 7.5 9Z"
            fillRule="evenodd"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
        />
        <path
            d="M0.75 17.25C0.75 13.5221 3.77208 10.5 7.5 10.5C11.2279 10.5 14.25 13.5221 14.25 17.25"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
        />
        <path
            clipRule="evenodd"
            d="M17.727 10.5C19.591 10.5 21.102 8.98896 21.102 7.125C21.102 5.26104 19.591 3.75 17.727 3.75C15.863 3.75 14.352 5.26104 14.352 7.125C14.352 8.98896 15.863 10.5 17.727 10.5Z"
            fillRule="evenodd"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
        />
        <path
            d="M15.813 12.068C17.5079 11.4434 19.4011 11.6865 20.8832 12.7192C22.3652 13.7518 23.249 15.4437 23.25 17.25"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
        />
    </svg>
);

UsersMultipleNeutral2.propTypes = {
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

UsersMultipleNeutral2.defaultProps = {
    width: 24,
    height: 24
}

export default UsersMultipleNeutral2;
