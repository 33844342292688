// eslint-disable-next-line
export const mentionPattern = /\B@\[([\w\s\.]+)\]\((\d+)\)/g
export const newMentionPattern = /@([\w.]+)/g

/**
 * @param str
 * @param replacement
 * @param index
 * @param length
 */
export function replaceAt(
    str,
    replacement,
    index,
    length = 0
) {
    const prefix = str.substr(0, index);
    const suffix = str.substr(index + length);
  
    return prefix + replacement + suffix;
}

/**
 * Returns active word.
 * @param input
 * @param taggedUsers
 * @param cursorPosition
 */
export function getActiveWord(input, taggedUsers, cursorPosition) {
    let content = input.split(/[\s\n]/);
    
    if(Array.isArray(taggedUsers) && taggedUsers.length > 0){
        for (const element of taggedUsers) {
            content.splice(element.index, 1, `@${element.id}@`);
        }
    }
    
    content = content.filter(item => item !== null).join(' ');
    
    const actualCursorPosition = cursorPosition - input.length + content.length
    
    const newTokenizedQuery = content.split(/[\s\n]/).reduce((acc, word, index) => {
        const previous = acc[index - 1];
        const start = index === 0 ? index : previous.range[1] + 1;
        const end = start + word.length;
        
        return acc.concat([{ word, range: [start, end], index }]);
    }, []);
    
    const newActiveWord = newTokenizedQuery.find(
        ({ range }) => range[0] < actualCursorPosition && range[1] >= actualCursorPosition
    );
    
    const tokenizedQuery = input.split(/[\s\n]/).reduce((acc, word, index) => {
        const previous = acc[index - 1];
        const start = index === 0 ? index : previous.range[1] + 1;
        const end = start + word.length;
  
        return acc.concat([{ word, range: [start, end], index }]);
    }, []);
  
    if (cursorPosition === undefined || actualCursorPosition === undefined) {
        return undefined;
    }
  
    const activeWord = tokenizedQuery.find(
        ({ range }) => range[0] < cursorPosition && range[1] >= cursorPosition
    );
  
    return {activeWord, newActiveWord};
}

/**
 * Renders comments content as html (wraps all mentions with span element).
 * @param {str} content 
 */
export function renderCommentContent(content) {
    return content.replaceAll(
        mentionPattern,
        "<span class='commentItem__comment--mention' data-user='$2'>@$1</span>"
    )
}
  

/**
 * @param content
 */
export function getPlainContent(content) {
    return content.replaceAll(
        mentionPattern,
        '@$1'
    )
}

/**
 * @param newContent
 * @param users
 */
export function replaceMentions(content, users) {
    let updatedContent = content;
    
    // Step 1: Replace @id@ only within the specified ranges
    users.forEach(user => {
        const { id, commentUsername, newRange } = user;
        
        // Extract the content in the range
        const rangeStart = newRange[0];
        const rangeEnd = newRange[1];
        
        // Slice the content based on the range
        const beforeTag = content.substring(0, rangeStart);
        const taggedPortion = content.substring(rangeStart, rangeEnd);
        const afterTag = content.substring(rangeEnd);
        
        // Only replace the portion that matches @id@
        if (taggedPortion === `@${id}@`) {
            updatedContent = beforeTag + `@${commentUsername}` + afterTag;
        }
    });
    
    // Step 2: Convert @username patterns into span elements
    updatedContent = updatedContent.replace(
        /@([\w.]+)/g,
        (match, p1) => {
            const user = users.find(u => u.commentUsername === p1);
            return user ? `<span class='commentItem__comment--mention' data-user='${user.id}'>@${p1}</span>` : match;
        }
    );
    
    return updatedContent;
}