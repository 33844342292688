/**
 *
 */
const TriangleIcon = () => {
    return (
        <svg
            fill="none"
            height="20"
            viewBox="0 0 22 21.5"
            width="20"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.698 18.563L9.84 1.8a1 1 0 011.79-.02L15.856 10l4.393 8.543A1 1 0 0119.361 20H2.597a1 1 0 01-.9-1.437z"
                stroke="#3C3E49"
                strokeWidth="1.5"
            />
        </svg>
    );
}

export default TriangleIcon;