import {fabric} from 'fabric';

function arrayFromCoords(coords) {
    return [
        new fabric.Point(coords.tl.x, coords.tl.y),
        new fabric.Point(coords.tr.x, coords.tr.y),
        new fabric.Point(coords.br.x, coords.br.y),
        new fabric.Point(coords.bl.x, coords.bl.y)
    ];
}

/**
 * Returns bounding box of the object.
 * Since fabric js's getBoundingRect returns weird positions if the object is in the active selection
 * we can use this method to get bounding rect.
 * @returns {{left: number, top: number, width: number, height: number}} The bounding box of the object.
 */
export function getCompleteBoundingRect() {
    const center = this.getCenterPoint();
    if (this.group && this.group.type === 'activeSelection') {
        const groupCenter = this.group.getCenterPoint();
        center.x += groupCenter.x;
        center.y += groupCenter.y;
    }
    let translateMatrix = [1, 0, 0, 1, center.x, center.y];
    let rotateMatrix = this._calcRotateMatrix(),
        finalMatrix = fabric.util.multiplyTransformMatrices(translateMatrix, rotateMatrix),
        dim = this._getTransformedDimensions(),
        w = dim.x / 2, h = dim.y / 2;
    const calculatedACoords = {
        // corners
        tl: fabric.util.transformPoint({ x: -w, y: -h }, finalMatrix),
        tr: fabric.util.transformPoint({ x: w, y: -h }, finalMatrix),
        bl: fabric.util.transformPoint({ x: -w, y: h }, finalMatrix),
        br: fabric.util.transformPoint({ x: w, y: h }, finalMatrix)
    };
    const arrayCoords = arrayFromCoords(calculatedACoords);
    return fabric.util.makeBoundingBoxFromPoints(arrayCoords) ;
}