
/**
 * This class developed in order to using the shapes in other components/js files by canvas initializing.
 * After canvas is initalized, all objects are cleaned to not overload the browser in heavy boards.
 * New Scenario: ObjectStore has been also set and cleaned during duplication as well.
 */

class ObjectStore {
    objects = new Map()

    init(objects) {
        if (Array.isArray(objects) && objects.length > 0) {
            objects.forEach((object) => {
                this.objects.set(object.uuid, object.properties);
            })
        }
    }

    initFromCanvas(canvas) {
        if (!canvas || typeof canvas?.getObjects !== 'function') return;
        const objects = canvas.getObjects();

        if (Array.isArray(objects) && objects.length > 0) {
            objects.forEach((object) => {
                this.objects.set(object.uuid, object);
            })
        }
    }

    getObject(uuid) {
        return this.objects.get(uuid);
    }

    hasObject() {
        return this.objects.size > 0;
    }

    clear() {
        this.objects.clear();
    }
}

const store = new ObjectStore();
export default store;