import { createSlice } from '@reduxjs/toolkit';

import { initialBoardState } from './InitialBoardState';


const boardSlice = createSlice({
    name: 'board',
    initialState: initialBoardState,
    reducers: {
        changeCurrentWidth: (state, action) => {
            state.currentWidth = action.payload;
        },
        changeCurrentMode: (state, action) => {
            state.currentMode = action.payload;
        },
        changeHideComments: (state, action) => {
            state.hideComments = action.payload;
        },
        changeHideResolvedComments: (state, action) => {
            state.hideResolvedComments = action.payload;
        },
        changeBoardDetails: (state, action) => {
            const { payload } = action;

            const {
                boardName,
                canvasInitialized,
                publicShareKey,
                publicSharePermission,
                studioBuildCardID,
                userFlowDiagram_status,
                users,
                whiteBoardId,
                whiteBoardOwnerId,
                whiteBoardSlugId,
                requestList,
                nimaWbId,
                nimaImportStatus,
                nimaImportUpdatedAt,
                nimaImportUpdatedBy,
                isPublicAccess,
                appName,
                properties
            } = payload;

            if (payload.boardName) {
                state.boardName = boardName;
            }

            if (payload.canvasInitialized) {
                state.canvasInitialized = canvasInitialized;
            }

            if (publicShareKey) {
                state.publicShareKey = publicShareKey;
            }

            if (publicSharePermission) {
                state.publicSharePermission = {
                    ...state.publicSharePermission,
                    ...publicSharePermission,
                };
            }

            if (payload.hasOwnProperty('studioBuildCardID')) {
                // id might be null so check for hasOwnProperty
                state.studioBuildCardID = studioBuildCardID;
            }

            if (payload.userFlowDiagram_status) {
                state.flowchartData.status = userFlowDiagram_status;
            }

            if (users) {
                state.users = users;
            }

            if (payload.whiteBoardId) {
                state.whiteBoardId = whiteBoardId;
            }

            if (whiteBoardOwnerId) {
                state.whiteBoardOwnerId = whiteBoardOwnerId;
            }

            if (payload.whiteBoardSlugId) {
                state.whiteBoardSlugId = whiteBoardSlugId;
            }
            if (nimaWbId) {
                state.migrationData.nimaWbId = nimaWbId
            }
            if (nimaImportStatus) {
                state.migrationData.nimaImportStatus = nimaImportStatus
            }
            if (nimaImportUpdatedBy) {
                state.migrationData.nimaImportUpdatedBy = nimaImportUpdatedBy
            }
            if (nimaImportUpdatedAt) {
                state.migrationData.nimaImportUpdatedAt = nimaImportUpdatedAt
            }

            if (payload.requestList) {
                state.requestList = requestList;
            }
            if (typeof isPublicAccess !== 'undefined') {
                state.isPublicAccess = isPublicAccess;
            }

            if (appName) {
                state.appName = appName;
            }

            if (Array.isArray(properties?.recentSelectedColors) && properties.recentSelectedColors.length > 0) {
                const prevRecentcolors = state.recentSelectedColors.filter((color) => {
                    return properties.recentSelectedColors.indexOf(color) === -1
                });

                state.recentSelectedColors = [...properties.recentSelectedColors, ...prevRecentcolors].slice(0, 10);
            }
        },
        changeIsPublicAccess: (state, action) => {
            state.isPublicAccess = action.payload;
        },
        updateStackOrderCheck: (state, action) => { 
            state.stackOrderUpdated = action.payload
        },
        changeLineType: (state, action) => {
            state.defaultLineType = action.payload.defaultLineType;
        },
        resetPublicAccess: (state) => {
            state.isPublicAccess = false;
            state.isPublicLink = false;
        },
        appendColorToRecentColors: (state, action) => {
            if (action.payload.color) {
                // If color exist, move that color into the beginning.
                if (state.recentSelectedColors.includes(action.payload.color)) {
                    const colorIdx = state.recentSelectedColors.indexOf(action.payload.color);
                    state.recentSelectedColors.splice(colorIdx, 1);
                    state.recentSelectedColors.unshift(action.payload.color);
                } else { // If not push the color as a first element in array
                    state.recentSelectedColors.unshift(action.payload.color);
    
                    if (state.recentSelectedColors.length > 10) {
                        state.recentSelectedColors.pop();
                    }
                }
            }
        },
        updateRecentColorList: (state, action) => {
            if (Array.isArray(action.payload.colors) && action.payload.colors.length > 0) {
                const initialColors = initialBoardState.recentSelectedColors.filter((color) => {
                    return action.payload.colors.indexOf(color) === -1
                });

                state.recentSelectedColors = [...action.payload.colors, ...initialColors].slice(0, 10);
            }
        },
        resetBoardState: () => {
            return initialBoardState;
        }
    }
});


export default boardSlice.reducer;
