import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param {object} props
 * @param props.color
 */
const TextHighlightIcon = ({color}) => {
    return (
        <svg
            fill="none"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                clipRule="evenodd"
                d="M17.1967 1.73552L20.1052 4.64477C20.5444 5.08408 20.5444 5.79621 20.1052 6.23552L12 14.3438L8.625 15.4688L6.375 13.2188L7.5 9.84377L15.6053 1.73552C15.8162 1.52439 16.1025 1.40576 16.401 1.40576C16.6995 1.40576 16.9858 1.52439 17.1967 1.73552Z"
                fillRule="evenodd"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.4978 9.84375L11.9978 14.3438"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.65405 13.5L3.5603 16.5938H6.9353L8.34155 15.1875"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.1853 8.15619L12.7928 2.76444C12.3535 2.32526 11.6414 2.32526 11.2021 2.76444L8.0603 5.90619"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <rect fill={`rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`} height="4" transform="translate(0 20)" width="24" />
            <path
                d="M23 20C23.5523 20 24 20.4477 24 21L24 23C24 23.5523 23.5523 24 23 24L1 24C0.447717 24 1.73729e-06 23.5523 1.76373e-06 23L1.85947e-06 21C1.88591e-06 20.4477 0.447716 20 1 20L23 20Z"
                fill="url(#pattern0)"
                fillOpacity="0.3"
            />
            <defs>
                <pattern
                    height="0.453333"
                    id="pattern0"
                    patternContentUnits="objectBoundingBox"
                    width="2.72"
                >
                    <use
                        transform="scale(0.0425 0.00708333)"
                    />
                </pattern>
                <image
                    height="64"
                    id="image0_195_183508"
                    width="64"
                />
            </defs>
        </svg>
    );
}

TextHighlightIcon.propTypes = {
    color: PropTypes.shape({
        r: PropTypes.number,
        g: PropTypes.number,
        b: PropTypes.number,
        a: PropTypes.number,
    }).isRequired 
}

export default TextHighlightIcon;
