import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import DownloadIcon from '../../svgIcons/canvasRedesign/IconDownload';


const DownloadItem = ({
    isDisabled,
    onClick,
    isLoading
}) => {

    return (
        <div
            className={clsx('button', { disabled: isDisabled, imageDownloading: isLoading })}
            onClick={onClick}
        >
            <DownloadIcon />
            <span className={clsx('tooltipText', { imageDownloading: isLoading })}>
                {isLoading ? 'Please wait, Download in progress' : 'Download'}
            </span>
        </div>
    )
}

DownloadItem.propTypes = {
    isDisabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    isLoading: PropTypes.bool
}

export default DownloadItem;