import { fabric } from 'fabric';
import { changeObjectsSelectableProp, clearHighlightForObject, createHighlightForObject, getFabricObject, isObjectInsideOfObject } from '../helpers/FabricMethods';
import { modes, SHAPE_DEFAULTS, DEFAULT_TEXT_SIZE } from '../helpers/Constant';
import { removeCanvasListener } from '../helpers/CommonFunctions'
import createFrameWithOptions from '../helpers/frame/CreateFrameWithOptions';
import { attachToFrame, isObjectAttachableToFrame } from '../helpers/frame/FrameMethods';
import store from '../redux/Store';
import { getShortenedText } from '../helpers/CommonUtils';
import { getTextMetrics } from '../helpers/TextWrapHelpers';

let drawInstance = null,
    mouseDown = false,
    origX,
    origY;

const attachedShapes = new Set();

export const Frame = fabric.util.createClass(fabric.Rect, {
    type: 'frame',
    shapeType: 'frame',
    cornerStyle: 'rect',
    dirty: true,
    borderColor: '#000000',
    borderDashArray: null,
    text: null,
    objectCaching: false,
    textMB: 3,  // margin bottom for title
    // props for highlighting text when this frame is searched
    highlightText: false,
    highlightTextBorderStroke: 'red',
    highlightTextBorderWidth: 1,
    highlightAreaPadding: 10,
    initialize(...args) {

        // support old frames
        if (args.length > 1) {
            const options = args[1]
            const frameRect = options.objects[0];
            options.fill = frameRect.fill;
            options.strokeWidth = frameRect.strokeWidth;
            options.stroke = frameRect.stroke;
            options.shadow = frameRect.shadow;

            const frameText = options.objects[1];
            if (frameText) {
                if (frameText.visible) {
                    options.top = options.top + frameText.height;
                }
                this.text = frameText.text;
            }
            this.callSuper('initialize', options);
        } else {
            const options = args[0]
            this.callSuper('initialize', options);
        }
        this.lockScalingFlip = true;
    },
    getObjects() {
        return []
    },
    /**
     * @override
     */
    calcACoords: function() {
        var rotateMatrix = this._calcRotateMatrix(),
            translateMatrix = this._calcTranslateMatrix(),
            finalMatrix = fabric.util.multiplyTransformMatrices(translateMatrix, rotateMatrix),
            dim = this._getTransformedDimensions(),
            w = dim.x / 2, h = dim.y / 2;


        const absoluteCoords =  {
            // corners
            tl: fabric.util.transformPoint({ x: -w, y: -h }, finalMatrix),
            tr: fabric.util.transformPoint({ x: w, y: -h }, finalMatrix),
            bl: fabric.util.transformPoint({ x: -w, y: h }, finalMatrix),
            br: fabric.util.transformPoint({ x: w, y: h }, finalMatrix)
        };

        // if text is present, then calculate the text bounding box and add it to the absolute coords
        if (this.canvas && typeof this.text === 'string' && this.text.trim().length > 0 && this) {
            const zoomLevel = this.canvas.getZoom();
            const textMetrics = getTextMetrics(this.text, `${DEFAULT_TEXT_SIZE / zoomLevel}px Rubik, sans-serif`);
            const textHeight = Math.abs(textMetrics.actualBoundingBoxAscent) + Math.abs(textMetrics.actualBoundingBoxDescent);
            const textBoundingBox = {
                width: textMetrics.width >= this.width * this.scaleX ? this.width * this.scaleX : textMetrics.width,
                height: textHeight * 2,
                left: -w,
                top: -h - textHeight * 2,
            }
            absoluteCoords.textTL = fabric.util.transformPoint({ x: textBoundingBox.left, y: textBoundingBox.top }, finalMatrix);
            absoluteCoords.textTR = fabric.util.transformPoint({ x: textBoundingBox.left + textBoundingBox.width, y: textBoundingBox.top }, finalMatrix);
            absoluteCoords.textBL = fabric.util.transformPoint({ x: textBoundingBox.left, y: textBoundingBox.top + textBoundingBox.height }, finalMatrix);
            absoluteCoords.textBR = fabric.util.transformPoint({ x: textBoundingBox.left + textBoundingBox.width, y: textBoundingBox.top + textBoundingBox.height }, finalMatrix);
        }
        return absoluteCoords;
    },
    /**
     * @override
     */
    calcLineCoords: function() {
        var vpt = this.getViewportTransform(),
            padding = this.padding, angle = fabric.util.degreesToRadians(this.angle),
            cos = fabric.util.cos(angle), sin = fabric.util.sin(angle),
            cosP = cos * padding, sinP = sin * padding, cosPSinP = cosP + sinP,
            cosPMinusSinP = cosP - sinP, aCoords = this.calcACoords();

        var lineCoords = {
            tl: fabric.util.transformPoint(aCoords.tl, vpt),
            tr: fabric.util.transformPoint(aCoords.tr, vpt),
            bl: fabric.util.transformPoint(aCoords.bl, vpt),
            br: fabric.util.transformPoint(aCoords.br, vpt),
        };

        if (aCoords.hasOwnProperty('textTL') && aCoords.hasOwnProperty('textTR') && aCoords.hasOwnProperty('textBL') && aCoords.hasOwnProperty('textBR')) {
            const textCoords = {
                textTL: fabric.util.transformPoint(aCoords.textTL, vpt),
                textTR: fabric.util.transformPoint(aCoords.textTR, vpt),
                textBL: fabric.util.transformPoint(aCoords.textBL, vpt),
                textBR: fabric.util.transformPoint(aCoords.textBR, vpt),
            }
            lineCoords = {
                ...lineCoords,
                ...textCoords
            }
        }

        if (padding) {
            lineCoords.tl.x -= cosPMinusSinP;
            lineCoords.tl.y -= cosPSinP;
            lineCoords.tr.x += cosPSinP;
            lineCoords.tr.y -= cosPMinusSinP;
            lineCoords.bl.x -= cosPSinP;
            lineCoords.bl.y += cosPMinusSinP;
            lineCoords.br.x += cosPMinusSinP;
            lineCoords.br.y += cosPSinP;
        }

        return lineCoords;
    },
    /**
     * @override
     */
    _getImageLines: function(oCoords) {

        var lines = {
            topline: {
                o: oCoords.tl,
                d: oCoords.tr
            },
            rightline: {
                o: oCoords.tr,
                d: oCoords.br
            },
            bottomline: {
                o: oCoords.br,
                d: oCoords.bl
            },
            leftline: {
                o: oCoords.bl,
                d: oCoords.tl
            },
        };
        if (this.isTitleRendering && oCoords.hasOwnProperty('textTL') && oCoords.hasOwnProperty('textTR') && oCoords.hasOwnProperty('textBL') && oCoords.hasOwnProperty('textBR')) {

            const newLines = {
                textTopline: {
                    o: oCoords.textTL,
                    d: oCoords.textTR
                },
                textRightline: {
                    o: oCoords.textTR,
                    d: oCoords.textBR
                },
                textBottomline: {
                    o: oCoords.textBR,
                    d: oCoords.textBL
                },
                textLeftline: {
                    o: oCoords.textBL,
                    d: oCoords.textTL
                }
            }
            lines = {
                ...lines,
                ...newLines
            }
        }

        return lines;
    },
    _shouldRenderRect() {
        if (!this.renderingOptions) return true;
        if (this.renderingOptions?.renderingDefault) return true;
        if (!this.renderingOptions?.isRenderingWithTiles) return true;
        if (this.renderingOptions.isRenderingDynamic) return false;
        return true;
        
    },
    _shouldRenderTitle() {
        if (!this.text) return false;
        if (!this.renderingOptions) return true;
        if (this.renderingOptions?.renderingDefault) return true;
        if (!this.renderingOptions?.isRenderingWithTiles) return true;
        return this.renderingOptions?.isRenderingDynamic;
    },
    _render(ctx) {
        if (this._shouldRenderRect()) {
            this.callSuper('_render', ctx);
        }
        if (this._shouldRenderTitle()) {
            this.renderTitle(ctx);
        }
        // do not render if width/height are zeros or object is not visible
        // if (this.width === 0 || this.height === 0 || !this.visible) return;
        // ctx.save();
        // ctx.restore();
    },
    renderTitle(ctx) {
        ctx.save();
        const zoom = this.canvas ? this.canvas.getZoom() : 1;
        ctx.scale(1 / this.scaleX, 1 / this.scaleY);
        const fixedFontSize = DEFAULT_TEXT_SIZE / zoom;
        const thicknessX = (this.strokeWidth * this.scaleX) / 2;
        const thicknessY = (this.strokeWidth * this.scaleY) / 2;
        ctx.font = `${fixedFontSize}px Rubik, sans-serif`;
        const renderingText = getShortenedText(this.text, this.width * this.scaleX, ctx);

        const yPadding = this.textMB / zoom;
        const textPosition = {
            x: (-this.width * this.scaleX) / 2 - thicknessX,
            y: (-this.height * this.scaleY) / 2 - thicknessY - yPadding
        }
        const zoomRestrictRatio = this.width * this.scaleX / 920;
        const zoomRestrict = 10 / zoomRestrictRatio;
        const shouldShowTextInThisZoom = zoom * 100 >= zoomRestrict;

        if (renderingText?.length > 0 && !this.tempHideText && shouldShowTextInThisZoom) {
            ctx.fillStyle = SHAPE_DEFAULTS.TEXT_COLOR;
            ctx.textBaseline = 'bottom';
            ctx.fillText(
                renderingText,
                textPosition.x,
                textPosition.y
            );

        }
        this.isTitleRendering = renderingText.length > 0 && shouldShowTextInThisZoom;

        // if this frame is searched, render highlighted area
        if (this.highlightText && this.text) {
            ctx.beginPath()
            ctx.fillStyle = '#B388FF40'
            ctx.lineWidth = this.highlightTextBorderWidth / zoom;
            ctx.strokeStyle = this.highlightTextBorderStroke || 'red';

            const highlightRectPadding = this.highlightAreaPadding / zoom;
            ctx.roundRect(
                textPosition.x - highlightRectPadding,
                textPosition.y - fixedFontSize - highlightRectPadding,
                this.width * this.scaleX + highlightRectPadding * 2,
                fixedFontSize + highlightRectPadding * 2,
                5 / zoom
            )
            ctx.stroke()
            ctx.fill()
        }

        ctx.restore();
    },
    getText() {
        return this.text;
    },
    // Previously, addWithUpdate was used for group objects.
    // Now, since frames are treated as individual objects
    // rather than groups, this method is no longer applicable for frames.
    // but we are adding this as a method just in case if this method is called
    addWithUpdate() {
        // do nothing
    },
    getActualPosition() {
        if (this?._objects?.length > 1) {
            const frameRect = this._objects[0];
            const thisCenter = this.getCenterPoint();
            return {
                left: frameRect.left + thisCenter.x,
                top: frameRect.top + thisCenter.y,
            }
        }
        return {
            left: this.left,
            top: this.top,
        }
    },
    getAdditionalPosition() {
        if (this?._objects?.length > 1) {
            const thisAdditionalPos = this.getActualPosition();
            return {
                left: thisAdditionalPos.left - this.left,
                top: thisAdditionalPos.top - this.top,
            }
        }
        return {
            left: 0,
            top: 0,
        }
    },
    getManualActualPosition() {

    },
});

export const frameFromObject = function (object, callback) {
    if (object.objects && Array.isArray(object.objects)) {
        callback(new fabric.Frame(null, object));
    } else {
        callback(new fabric.Frame(object));
    }
};

/**
 * Creates rectangle and deletes it after creation. In the place of rectangle, creates frame with text.
 * @param canvas
 * @param emitOnMouseDown
 * @param onMouseDownLineHandler
 * @param uuidGenerator
 * @param handleChangeSelectMode
 */
export const createFrame = (canvas, emitOnMouseDown, onMouseDownLineHandler, uuidGenerator, handleChangeSelectMode) => {
    store.dispatch({
        type: 'board/changeCurrentMode',
        payload: modes.FRAME
    });

    removeCanvasListener(canvas);
    canvas.toggleDragMode(canvas, false);
    canvas.on('mouse:down', startAddFrame(canvas));
    canvas.on('mouse:move', startDrawingFrame(canvas));
    canvas.on('mouse:up', () => {
        mouseDown = false;
        removeCanvasListener(canvas);
        onMouseDownLineHandler(canvas);

        const frame = createFrameWithOptions(canvas, {
            left: drawInstance.left,
            top: drawInstance.top,
            width: drawInstance.width,
            height: drawInstance.height,
            uuid: uuidGenerator(canvas),
        });
        if (frame) {
            frame.attachedShapes = [];
            frame.attachments = [];  // update only when frame is created or modified or new attachment
            console.log(attachedShapes);
            for (const uuid of attachedShapes) {
                const obj = getFabricObject(canvas, 'uuid', uuid);
                if (obj) {
                    console.log('attaching', obj);
                    attachToFrame(obj, frame);
                    clearHighlightForObject(canvas, obj);
                    frame.attachments.push(uuid);
                }
            }

            emitOnMouseDown(frame, frame);
        }
        attachedShapes.clear();
        canvas.remove(drawInstance);
        handleChangeSelectMode('select');
    });
    canvas.selection = false;
    canvas.defaultCursor = 'default';
    canvas.hoverCursor = 'auto';
    canvas.isDrawingMode = false;
    changeObjectsSelectableProp(canvas, false);
    canvas.discardActiveObject().requestRenderAll();


};

const startAddFrame = (canvas) => {
    return _ref3 => {
        let {
            e
        } = _ref3;
        const shadow = new fabric.Shadow({ 
            color: '#b388ff70', 
            blur: 3,
        // affectStroke: true // if true, shadow also will be applied to stroke
        });
        mouseDown = true;
        const pointer = canvas.getPointer(e);
        origX = pointer.x;
        origY = pointer.y;
        //Old
        drawInstance = new fabric.Rect({
            stroke: '#000',
            strokeWidth: 1,
            fill: 'rgba(255, 255, 255, 0.5)',
            left: origX,
            top: origY,
            width: 200,
            height: 200,
            selectable: false,
            type: 'mockFrame',
            shadow,
            isDynamic: true  // tells that we will use this instance only for mocking
        });
        canvas.add(drawInstance);
    };
}
  
const startDrawingFrame = (canvas) => {
    return _ref4 => {
        let {
            e
        } = _ref4;
  
        if (mouseDown) {
            const pointer = canvas.getPointer(e);
  
            if (pointer.x < origX) {
                drawInstance.set('left', pointer.x);
            }
  
            if (pointer.y < origY) { 
                drawInstance.set('top', pointer.y);
            }
  
            drawInstance.set({
                width: Math.abs(pointer.x - origX),
                height: Math.abs(pointer.y - origY)
            });
            drawInstance.setCoords();
            canvas.getObjects().forEach(obj => {
                if (isObjectAttachableToFrame(canvas, obj, drawInstance) && obj.type !== 'frame') {
                    if (isObjectInsideOfObject(drawInstance, obj)) {
                        createHighlightForObject(canvas, obj);
                        attachedShapes.add(obj.uuid);
                    } else if (attachedShapes.has(obj.uuid)) {
                        clearHighlightForObject(canvas, obj);
                        attachedShapes.delete(obj.uuid);
                    }
                }

            })
            canvas.renderAll();
        }
    };
}

const _findTarget = fabric.Canvas.prototype.findTarget;

export const moveObjectInFrameHandler = () => {
    fabric.util.object.extend(fabric.Canvas.prototype, {
        altSelectionKey: 'ctrlKey',

        /**
         * `this.selection = false` durng nested object selection for this to work properly.
         * @param e
         * @param skipGroup
         */
        findTarget: function (e, skipGroup) {
            const target = _findTarget.call(this, e, skipGroup);
            if (
                e.type === 'mousedown' &&
                target &&
                target.type === 'frame' &&
                !target.onSelect({ e })
            ) {
                const pointer = this.getPointer(e, true);
                const child = this._searchPossibleTargets(target._objects, pointer);
                if (child && child.type === 'text') {
                    let isDblClick = false;
                    // if user double clicks on text, then we need to fire frame-title:dbl-click event
                    // in order to open text editor for the frame
                    const isFrameLocked = target.lockMovementX && target.lockMovementY;
                    if (target.lastTime && e.timeStamp - target.lastTime < 700 && !isFrameLocked) {
                        this.fire('frame-title:dbl-click', child);
                        isDblClick = true;
                    }
                    target.lastTime = e.timeStamp; 
                    if (isDblClick) {
                        return null;
                    }
                }
            }
            return target;
        }
    });
};