/**
 * @param {Event} event
 */
export const handleLabelPosition = (event) => {
    const eventType = event?.type;
    const value = event?.target?.value;
    const label = event?.target?.previousElementSibling ?? event?.target?.parentElement?.previousElementSibling;

    if (eventType === 'focus') label?.classList?.add('active');
    else if (value === ''){
        label?.classList?.remove('active');
    }
}