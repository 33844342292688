import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ChangeShapeIcon from '../../svgIcons/ChangeShapeIcon';
import ShapeChangeMenu from '../menus/ShapeChangeMenu';
import { TOOLBAR_MENU } from '../../../helpers/Constant';


const ChangeShapeTypeItem = ({
    isLocked,
    onClick,
    isDisabled,
    showMenu,
    shapeType,
    isShapeHasTextOrEditMode,
    toggleMenu
}) => {
    const changeShapeTooltipRef = useRef();

    const menuOnClickHandler = () => {
        if (isDisabled) return;
        toggleMenu(TOOLBAR_MENU.CHANGE_SHAPE);
    }

    return (
        <div className={clsx('changeShapeDDL', { disabled: isDisabled })} onClick={menuOnClickHandler}>
            <ChangeShapeIcon />
            <em className="icon-arrow-down" />

            {showMenu ? <ShapeChangeMenu
                activeShapeType={shapeType}
                onClickHandler={onClick}
                tooltipRef={changeShapeTooltipRef}
            /> : null}

            {!isLocked ? (
                <span
                    className={clsx('tooltipText tooltipText--longContent', { 'onBottom': showMenu })}
                    ref={changeShapeTooltipRef}
                >
                    {(isShapeHasTextOrEditMode) ? 'Only empty shapes can be changed' : 'Change Shape'}
                </span>
            ) : null}
        </div>
    )
}

ChangeShapeTypeItem.propTypes = {
    isDisabled: PropTypes.bool,
    isLocked: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    toggleMenu: PropTypes.func.isRequired,
    showMenu: PropTypes.bool,
    shapeType: PropTypes.string,
    isShapeHasTextOrEditMode: PropTypes.bool
}

export default ChangeShapeTypeItem;