import React from 'react';
import PropTypes from 'prop-types';

const Close = ({ width = 24, height = 24 }) => (
    <svg
        fill="none"
        height={height}
        viewBox="0 0 24 24"
        width={width}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0.75 23.249L23.25 0.749023"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
        />
        <path
            d="M23.25 23.249L0.75 0.749023"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
        />
    </svg>
);

Close.propTypes = {
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

Close.defaultProps = {
    width: 24,
    height: 24
}

export default Close;
