
import axios from 'axios';
import { FILTER_OPTIONS_REQUESTS, apiEndpoints } from '../helpers/Constant';
import { environment } from '../environment';


/**
 * @param wbId
 */
export async function getInitComments(wbId) {
    try {
        const response = await axios.get(environment.REACT_APP_API_ENDPOINT + apiEndpoints.getInitComments.replace('{wbId}', wbId));
        return response;
    } catch (error) {
        return [];
    }
}


/**
 * @param wbId
 * @param commentUuid
 */
export async function getCommentThread(wbId, commentUuid) {
    try {
        const response = await axios.post(
            environment.REACT_APP_API_ENDPOINT + apiEndpoints.getCommentThread.replace('{wbId}', wbId),
            {
                commentUuid
            }
        );
        return response;
    } catch (error) {
        return [];
    }
}

/**
 * @param wbId
 * @param page
 * @param wbPageId
 */
export async function getComments(wbId, page = 1, wbPageId, filter, keyword) {
    let params = { page, wbPageId }
    if (filter && filter !== 'Show all') params = { ...params, filter: FILTER_OPTIONS_REQUESTS[filter] }
    if (keyword !== '') params = { ...params, search: keyword }
    try {
        const url = `${environment.REACT_APP_API_ENDPOINT}${apiEndpoints.getComments.replace('{wbId}', wbId)}`

        const response = await axios.get(url, {
            params
        });
        return response;
    } catch (error) {
        return [];
    }
}


/**
 * @param wbId
 * @param keyword
 * @param page
 * @param wbPageId
 */
export async function searchComments(wbId, keyword, page = 1, wbPageId) {
    try {
        const url = `${environment.REACT_APP_API_ENDPOINT}${apiEndpoints.searchComments.replace('{wbId}', wbId)}`

        const response = await axios.get(url, {
            params: { keyword, page, wbPageId }
        });

        return response;
    } catch (error) {
        return [];
    }
}


/**
 * @param wbId
 * @param page
 * @param wbPageId
 */
export async function getTaggedComments(wbId, page = 1, wbPageId) {
    try {
        const url = `${environment.REACT_APP_API_ENDPOINT}${apiEndpoints.getTaggedComments.replace('{wbId}', wbId)}`

        const response = await axios.get(url, {
            params: { page, wbPageId }
        });

        return response;
    } catch (error) {
        return [];
    }
}