import React, {useEffect, useRef, useState} from 'react'
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {isZoomLevelDisabled} from '../../helpers/ZoomHelper';
import {setZoomLevel as changePanZoomLevel} from '../../hooks/PanZoom';


/**
 * @param {object} props
 * @param props.canvas
 * @param props.canvasRef
 */
const ZoomCard = ({
    canvas,
    canvasRef
}) => {
    const [zoomLevel, setZoomLevel] = useState(100);
    const [showZoomDropdown, setShowZoomDropdown] = useState(false);

    const mouseLeaveTimeoutRef = useRef();

    const handleOnMouseEnter = () => {
        clearTimeout(mouseLeaveTimeoutRef?.current);
        setShowZoomDropdown(true) ;
    }

    const changeZoomLevel = (zoomLevel) => {
        setZoomLevel(zoomLevel);
        changePanZoomLevel(zoomLevel, canvas, canvasRef);
        setShowZoomDropdown(false);
    };


    const handleOnMouseLeave = () => {
        mouseLeaveTimeoutRef.current = setTimeout(() => {
            setShowZoomDropdown(false)
        }, 300);
    }

    useEffect(() => {
        if (canvas) {
            const boardZoomListener = () => {
                setZoomLevel(Math.round(canvas.getZoom() * 100));
            }

            boardZoomListener();
            canvas.on('board:zoom', boardZoomListener);
        }
    }, [canvas]);

    return (
        <div
            className="percentValue" 
            data-testid='zoomcard-percentage'
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}>
            {zoomLevel}%
            <div className={clsx('zoomOptions', { active: showZoomDropdown })} data-testid="zoomOptions">
                {[1, 50, 100, 400].map((item) => (
                    <div
                        className={clsx('zoomOptions__item', { disabled: isZoomLevelDisabled(canvas, item) })}
                        key={item}
                        onClick={() => changeZoomLevel(item)}
                    >
                        {item}%
                    </div>
                ))}
            </div>
        </div>
    )
}

ZoomCard.propTypes = {
    canvas: PropTypes.object,
    canvasRef: PropTypes.shape({
        current: PropTypes.object
    })
}

export default ZoomCard;