import { fabric } from 'fabric';
import { generateTableTitle, tableMouseDownHandler, tableMouseUpHandler, tableMovingHandler } from './TableEventHandlers';
import { removeCanvasListener } from '../CommonFunctions';
import { EMITTER_TYPES, modes } from '../Constant';
import { Table } from './shapes/Table';
import store from '../../redux/Store';
import { toast } from 'react-toastify';
import getToastIcon from '../media/GetToastIcon';
import eventEmitter from '../EventEmitter';

let mouseDown;

/**
 * @param canvas
 * @param emitOnMouseDown
 * @param onMouseDownLineHandler
 * @param handleChangeSelectMode
 * @param options
 */
export function createTable(canvas, emitOnMouseDown, onMouseDownLineHandler, handleChangeSelectMode, options) {
    mouseDown = true;

    store.dispatch({
        type: 'board/changeCurrentMode',
        payload: modes.TABLE
    });

    removeCanvasListener(canvas);
    onMouseDownLineHandler(canvas);

    canvas.on('mouse:up', (e) => {
        if (mouseDown) {
            const table = drawTable(e, canvas, options);
            mouseDown = false;
            emitOnMouseDown(table, table);
            handleChangeSelectMode('select');
            onTableDrawn(table);
        }
    });
    canvas.selection = false;
    canvas.defaultCursor = 'default';
    canvas.hoverCursor = 'auto';
    canvas.isDrawingMode = false;
    canvas.getObjects().map(item => item.set({
        selectable: false
    }));
    canvas.discardActiveObject().requestRenderAll();
}

const drawTable = (e, canvas, options) => {
    const pointer = canvas.getPointer(e.e);

    // Update Table Title
    const title = generateTableTitle(canvas);

    const table = new Table({
        left: pointer.x,
        top: pointer.y,
        lockScalingFlip: true,
        title,
        ...options
    });

    table.on('mousedown', tableMouseDownHandler);
    table.on('moving', tableMovingHandler);
    table.on('mouseup', tableMouseUpHandler);

    canvas.add(table);
    return table;
}

/**
 * Checking that is there any title which is same with the given title.
 * @param {fabric.Canvas} canvas 
 * @param {string} title 
 * @param {fabric.object} target 
 * @returns { boolean }
 */
export function isTableTitleTaken(canvas, title, target) {
    return canvas.getObjects().some((obj) => {
        return obj.type === 'table' && obj.uuid !== target.uuid && obj.title.toLowerCase() === title.toLowerCase()
    });
}

/**
 * Show Toast Message If Title is already taken.
 * @param {string} value
 */
export function showMessageForSameTableTitle(value) {
    toast.error(`Error: '${value}' name is already taken for Table. Please choose a unique name.`, {
        icon: getToastIcon('error'),
        className: 'wb_toast',
    });
}

/**
 * @override
 * @param {fabric.Object} object 
 * @param {Function} callback 
 */
export const tableFromObject = function (object, callback) {
    fabric.util.enlivenObjects(object.objects, function () {
        callback(new fabric.Table(object));
    });
};

/**
 * There are many ways to updating control visibility of table elements. 
 * So after those changes, we are using this function to avoid from unexpected issues.
 * @param {fabric.Object} object 
 */
export const onTableDrawn = (object) => {
    // First off the mouse events if exists.
    object.off('mousedown', tableMouseDownHandler);
    object.off('moving', tableMovingHandler);
    object.off('mouseup', tableMouseUpHandler);

    object.on('mousedown', tableMouseDownHandler);
    object.on('moving', tableMovingHandler);
    object.on('mouseup', tableMouseUpHandler);

    object.setControlsVisibility({
        rotate: false,
        ml: false,
        mr: false,
        mt: false,
        mb: false
    });

    if (Array.isArray(object.cells)) {
        object.setAllCellsAsUnSelected();
    }
}

/**
 * Changing active cell selection by the pressed arrow key direction.
 * @param {fabric.Object} table 
 * @param {'LEFT' | 'RIGHT' | 'UP' | 'DOWN'} direction
 */
export const tableArrowMovementHandler = (table, direction) => {
    // If multiple cells are selected then don't continue.
    if (table.selectedCellIds.length > 1) {
        return;
    }

    const currentCellId = table.selectedCellIds[0];
    if (!currentCellId && !Number.isInteger(currentCellId)) return;

    const activeCell = table.cells.find((cell) => cell.id === currentCellId);
    const [activeRow] = activeCell.row;
    const [activeCol] = activeCell.col;

    let newRow, newCol;

    if (direction === 'LEFT') {
        if (activeCol === 0) return;
        newCol = activeCol - 1;
        newRow = activeRow;
    } else if (direction === 'RIGHT') {
        if (activeCol === table.cols.length - 1) return;
        newCol = activeCol + 1;
        newRow = activeRow;
    } else if (direction === 'UP') {
        if (activeRow === 0) return;
        newRow = activeRow - 1;
        newCol = activeCol;
    } else if (direction === 'DOWN') {
        if (activeRow === table.rows.length - 1) return;
        newRow = activeRow + 1;
        newCol = activeCol;
    }

    const nextCell = table.cells.find((cell) => cell.row[0] === newRow && cell.col[0] === newCol);
    if (!nextCell) return;

    table.setCellAsSelected(nextCell.id);
    eventEmitter.fire(EMITTER_TYPES.TOOLBAR_HIDE);
    eventEmitter.fire(EMITTER_TYPES.TOOLBAR_SHOW);
    table.canvas.requestRenderAll();
}