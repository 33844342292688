export const environment = {
    REACT_APP_API_ENDPOINT: window['env'].REACT_APP_API_ENDPOINT,
    NOW_API_ENDPOINT: window['env'].NOW_API_ENDPOINT,
    REACT_APP_AUTH_TOKEN: window['env'].REACT_APP_AUTH_TOKEN,
    envType: window['env'].envType,
    USER_COOKIE: window['env'].USER_COOKIE,
    NOW_BASE_URL: window['env'].NOW_BASE_URL,
    MAINTENANCE: window['env'].MAINTENANCE,
    ENTERPRISE_AUTH_TOKEN: window['env'].ENTERPRISE_AUTH_TOKEN,
    NIMA_BOARD_CLIENT_ID: window['env'].NIMA_BOARD_CLIENT_ID
}