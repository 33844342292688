import { fabric } from 'fabric';
import { HIGHLIGHT_TYPE } from '../Constant';
import { clearHighlightForObject, createHighlightForObject, getFabricObject, isObjectInsideOfObject, isTargetLocked, compareObjectPositionInStack } from '../FabricMethods';
import { calculateAttachedPos, calculateObjPos, getAdditionalPositionForAttaching } from './CalculatePositions';
import getToastIcon from '../media/GetToastIcon';
import { toast } from 'react-toastify';
import eventEmitter from '../EventEmitter';


/**
 * Checks if the object is attachable to any frame. Useful during moving objects.
 * @param {fabric.Object} obj
 * @param {fabric.Object=} frame
 */
export const isObjectAttachableDuringMoving = (obj, frame) => {
    // If frame is exists and if its locked, then the object shouldn't be attached.
    if (frame && isTargetLocked(frame)) {
        return false;
    }
    // only allow one level nested frame
    if (obj.type === 'frame' && frame?.attachedFrameId) return false;

    return (
        obj.type &&
    obj.type !== 'mockFrame' &&
    obj.type !== HIGHLIGHT_TYPE &&
    obj.uuid
    );
}

/**
 * Checks if the object is attachable to any frame. Useful during resizing or drawing frame.
 * @param canvas
 * @param {fabric.Object} obj
 * @param {fabric.Object=} frame
 */
export const isObjectAttachableToFrame = (canvas, obj, frame) => {
    return isObjectAttachableDuringMoving(obj, frame) 
  && (!obj.attachedFrameId || !_checkIfFrameIsExisted(canvas, obj.attachedFrameId)) 
  && !isTargetLocked(obj);
}

const _checkIfFrameIsExisted = (canvas, frameId) =>{
    return canvas.getObjects().some(object => object.uuid === frameId);
}

/**
 * If the attached object is inside the frame, calculate the position of the object.
 * If not, detach the object from the frame.
 * @param {fabric.Canvas} canvas
 * @param {fabric.Object} frame
 * @param {object} options
 * @param {boolean} options.ignoreNestedFrame - If true, nested frames will be ignored during reattaching.
 */
export const detachOrChangePositionOfObject = (canvas, frame) => {
    const objects = canvas.getObjects().filter(
        o => o.attachedFrameId && o.attachedFrameId === frame.uuid
    );
    for (const o of objects) {
        if (o.isMoving || o.group?.isMoving) continue;
        let isObjectInsideOfTheFrame = isObjectInsideOfObject(frame, o, { manualCheck: true });

        if (isObjectInsideOfTheFrame) {
            attachToFrame(o, frame);
        } else {
            detachFromFrame(o, frame);
            if (o.highlightObject) {
                clearHighlightForObject(canvas, o);
            }
        }
    }
}

/**
 * Creates highlight for objects those ready to be attached during resizing.
 * @param canvas
 * @param frame
 */
export const attachObjectsDuringResizing = (canvas, frame) => {
    if (!frame.attachedWithResizing) frame.attachedWithResizing = new Set();

    const allCanvasObjects = canvas.getObjects();
    const attachableObjects = allCanvasObjects.filter(o => isObjectAttachableToFrame(canvas, o, frame));
    for (const object of attachableObjects) {
        if (object.type === 'frame') {
            if (object.attachedFrameId) {
                continue;
            }
            // if the frame is on top of the other frame, then do not attach
            if (compareObjectPositionInStack(allCanvasObjects, object, frame)) {
                continue;
            }

        }
        if (isObjectInsideOfObject(frame, object)) {
            attachToFrame(object, frame);

            createHighlightForObject(canvas, object);
            frame.attachedWithResizing.add(object.uuid);
        } else {
            if (frame.attachedWithResizing.has(object.uuid)) {
                clearHighlightForObject(canvas, object);
                frame.attachedWithResizing.delete(object.uuid);
            }
        }
    }
}


/**
 * Handles the mouse up event on the frame. This required for attaching the objects 
 * during resizing.
 * @param e
 */
export const frameMouseUpHandler = (e) => {
    const frame = e.target;
    if (frame.attachedWithResizing) {
        for (const uuid of frame.attachedWithResizing) {
        // get the attached object from the canvas and calculate attached pos
            const obj = getFabricObject(frame.canvas, 'uuid', uuid);
            attachToFrame(obj, frame);
            clearHighlightForObject(frame.canvas, obj);
        }
        frame.attachedWithResizing.clear();
    }
    clearLineOriginalPoints(frame);
}

/**
 * Clear the original points of the lines if their polygon shapes attached to the frame.
 * @param frame
 */
const clearLineOriginalPoints = (frame) => {
    if (frame && frame.canvas) {
        const attachedShapes = getFrameAttachedShapes(frame);
        if (attachedShapes) {
            attachedShapes.forEach(o => {
                if (o.lines && o.lines.length > 0){
                    o.lines.forEach(lineUuid => {
                        const line = frame.canvas.getObjects().find(o => o.uuid === lineUuid);
                        if (line) {
                            line.originalPoints = null;
                        }
                    });
                }
            });
        }
    }
}

/**
 * Using for attaching an object to frame.
 * @param {fabric.Object} shape 
 * @param {fabric.Object} frame 
 * @param {{x: number, y: number}=} options.overridedPos 
 * @param {boolean=} options.allowAttachingToLockedFrame - To allow attaching to locked frame (useful for shortcuts).
 * @param options
 */
export const attachToFrame = (shape, frame, options = {}) => {
    const isFrameLocked = isTargetLocked(frame);
    if (isFrameLocked && !options?.allowAttachingToLockedFrame) { return; }

    const calculatedPos = calculateAttachedPos(frame, shape, options?.overridedPos);
    shape.calculatedPos = calculatedPos;
    shape.wiredFrame = frame;
    shape.attachedFrameId = frame.uuid;
}

/**
 * Using for detaching an object from frame.
 * @param {fabric.Object} shape 
 * @param {fabric.Object=} frame 
 */
export const detachFromFrame = (shape) => {
    const oldShape = { ...shape };
    eventEmitter.fire('frameDetachmentUpdate', {shape: oldShape, status: 'detaching'});
    shape.calculatedPos = null;
    shape.wiredFrame = null;
    shape.attachedFrameId = null;
}


export const getFrameAttachedShapes = (frame, nested = false) => {
    const frameObjects = frame.canvas.getObjects().filter(o => o.attachedFrameId === frame.uuid);
    
    if (nested) {
        for (const nestedFrame of frameObjects.filter(o => o.type === 'frame')) {
            frameObjects.push(...getFrameAttachedShapes(nestedFrame))
        }
    }
    
    return frameObjects
}


export const getFrameAttachedShapeUuids = (frame) => {
    return frame.canvas.getObjects().filter(o => o.attachedFrameId === frame.uuid).map(o => o.uuid);
}


/**
 * Removes frame and all attached objects from the canvas.
 * @param frame
 */
export const removeFrameFromCanvas = (frame) => {
    const frameObjects = frame.canvas.getObjects().filter(o => o.attachedFrameId === frame.uuid);
    frameObjects.forEach(o => {
        o.canvas.remove(o);
    });
    frame.canvas.remove(frame);
}


/**
 * Updates linkedShapes for the shortcuts.
 * @param {fabric.Object} frame
 * @param {object} options
 * @param {boolean} options.shouldGenerateForNestedFrames
 */
export const generateLinkedShapes = (frame, options = {}) => {
    frame.linkedShapes = [];
                                
    const frameObjects = getFrameAttachedShapes(frame);
    if (frameObjects) {
    // curved lines should be at the end of the array
        frameObjects.sort((a) => a.shapeType === 'curvedLine' ? 1 : -1);
    
        if (options.shouldGenerateForNestedFrames) {
            frameObjects.sort((a, b) => {
                if (a.type === 'frame') return -1;  // frames should be at the beginning of the array
                if (a.type !== 'curvedLine' && b.type === 'curvedLine') return -1;  // if the object isn't curved line but the other one is, then it should be right before of the curved line
                return 1;  // otherwise, it should be at the end of the array (curved lines)
            });
        }
    }
    for (const frameObject of frameObjects) {
        frame.linkedShapes.push(frameObject);

        if (frameObject.type === 'frame' && options.shouldGenerateForNestedFrames) {
            generateLinkedShapes(frameObject);
        }
    }
}

/**
 * Set position of frame's attached shapes for the calculatedPos. This is useful when
 * the frame is moved for duplicating.
 * @param frame
 */
export const setAttachedShapesCoords = (frame) => {
    const frameObjects = getFrameAttachedShapes(frame);
    const handleSetAttachedShapeCoordsEachObject = (frame, obj) => {
        const calculatedPos = calculateObjPos(frame, obj.calculatedPos);
        const additionalPos = getAdditionalPositionForAttaching(obj);
        obj.set({
            left: calculatedPos.x - additionalPos.left,
            top: calculatedPos.y - additionalPos.top,
        });
        obj.setCoords();
    }
    for (const frameObject of frameObjects) {
        handleSetAttachedShapeCoordsEachObject(frame, frameObject);

        if (frameObject.type === 'frame') {
            const nestedFrameObjects = getFrameAttachedShapes(frameObject);
            for (const nestedFrameObject of nestedFrameObjects) {
                handleSetAttachedShapeCoordsEachObject(frameObject, nestedFrameObject);
            }
        }
    }
}


export const isFrameMoved = (prevData, newData) => {
    return prevData.left !== newData.left || prevData.top !== newData.top;
}

/**
 * When an object is attaching to a frame, new object is being created for highlighting purposes.
 * The highlighted object position always should be same as the original object position.
 * Via this function, we constantly update the highlighted object position.
 * @param {fabric.Object} obj 
 * @param {number} left 
 * @param {number} top 
 */
export const updateHighlightedObjectLocation = (obj, left, top) => {
    obj.highlightObject.set({ left, top });
    obj.setCoords();
}

/**
 * Checking that whether the selected object(s) has highlighted object.
 * @param {fabric.Canvas} canvas 
 * @returns Boolean.
 */
export const isActiveSelectionHasHighlightedObject = (canvas) => {
    const activeObject = canvas.getActiveObject();
    if (activeObject.type !== 'activeSelection') {
        return false;
    }

    const selectionObjects = activeObject.getObjects();
    return selectionObjects.some((o) => !!o.highlightObject);
}

/**
 * Attaching the object(s) to the frame when drawed a shape or paste a shape.
 * @param {fabric.Canvas} canvas 
 * @param {fabric.Object} drawInstance 
 * @param {boolean} isCopyAndPaste
 * @returns {boolean}
 */
export const attachObjectToFrameDuringCreation = (canvas, drawInstance, isCopyAndPaste = false) => {
    let notAllowedTypes = [];

    // If image object is copied then pasted into the frame, it should be allowed
    if (!isCopyAndPaste) {
        notAllowedTypes = [...notAllowedTypes, 'image', 'optimizedImage'];
    }

    let isAttached = false;
    
    try {
        if (!notAllowedTypes.includes(drawInstance?.type)) {
            const frames = canvas.getObjects().filter((o) => o.type === 'frame' && o !== drawInstance);
            for (const frame of frames.slice().reverse()) {
                try {
                    if (frame && isObjectInsideOfObject(frame, drawInstance, { manualCheck: true })) {
                        attachToFrame(drawInstance, frame);
                        if(drawInstance.shapeType === 'frame'){
                            let elderFrame = drawInstance;
                            
                            const getElderFrame = () => {
                                elderFrame = canvas.getObjects().find(item => item.uuid === elderFrame.attachedFrameId);
                                if(elderFrame.attachedFrameId) getElderFrame();
                                
                            }
                            
                            getElderFrame();
                            attachToFrame(drawInstance, elderFrame)
                        }
                        isAttached = true;
                        break;
                    }
                } catch (err) {
                    console.error('Error happened', err);
                }
            }
        }
    } catch (err) {
        console.error('Error while attaching object to frame: ', err);
    }

    return isAttached;
}

/**
 * Checks if an is attached to a frame and that frame is locked or not.
 * @param {fabric.Object} shape 
 * @param {fabric.Canvas} canvas 
 * @returns {[boolean, fabric.Object]}
 */
export const isLinkedFrameLocked = (shape, canvas) => {
    if (!shape || !shape?.attachedFrameId) { return [false]; }
    const frame = canvas.getObjects().find((o) => o.uuid === shape.attachedFrameId);
    if (!frame) { return [false]; }

    return [isTargetLocked(frame), frame];
}

/**
 * Moves the last added frame to the top of the all frames but the bottom of the other objects.
 * @param {fabric.Canvas} canvas 
 * @param {fabric.Object} frame 
 */
export const handleFrameStackOrder = (canvas, frame) => {
    // add newly added frame to the bottom of the stack
    // change layer stack for all frames

    let lastIndex = -1;
    const allObjects = canvas.getObjects();
    allObjects.filter(o => o !== frame && (o.type === 'frame')).forEach(o => {
        const idx = allObjects.indexOf(o);
        if (idx > lastIndex) {
            lastIndex = idx;
        }
    });

    // if there is no frame, then add the frame to the bottom of the stack
    if (lastIndex === -1) {
        canvas.moveTo(frame, 0);
    } else {
        canvas.moveTo(frame, lastIndex + 1);
    }
}


/**
 * Handles calculating new attached position of the object in case
 * object position is changed.
 * @param canvas
 * @param object
 */
export const handleAttachingObjectOnModified = (canvas, object) => {
    try {
        if (!object) return;

        if (object.wiredFrame) {
            const objCalculatedPosNew = calculateAttachedPos(object.wiredFrame, object);
            if (object?.calculatedPos?.x !== objCalculatedPosNew.x || object?.calculatedPos?.y !== objCalculatedPosNew.y) {
                object.calculatedPos = objCalculatedPosNew
            }
        }
    } catch (err) {
        console.error('Error happened', err);
    }
}

/**
 * Checking that is there any title which is same with the given title.
 * @param {fabric.Canvas} canvas 
 * @param {string} title 
 * @param {fabric.object} target 
 * @returns { boolean }
 */
export function isFrameTitleTaken(canvas, title, target) {
    return canvas.getObjects().some((obj) => {
        return obj.type === 'frame' && obj.uuid !== target.uuid && obj.text.toLowerCase() === title.toLowerCase()
    });
}

/**
 * Show Toast Message If Title is already taken.
 * @param {string} value
 */
export function showMessageForSameFrameTitle(value) {
    toast.error(`Error: '${value}' name is already taken for Frame. Please choose a unique name.`, {
        icon: getToastIcon('error'),
        className: 'wb_toast',
    });
}