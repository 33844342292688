import { fabric } from 'fabric';
import { convertImageUrlToDataUrl } from '../../helpers/media/Blob';
import { loadImage } from '../../helpers/media/LoadImageAsync';
import { IMAGE_TITLE_FONT_SIZE, SHAPE_DEFAULTS } from '../../helpers/Constant';
import { getTextMetrics } from '../../helpers/TextWrapHelpers';
import { getShortenedText } from '../../helpers/CommonUtils';
import promiseQueue from './PromisePool';
import {getZoomLevel} from '../../helpers/ZoomHelper';


const OptimizedImage = fabric.util.createClass(fabric.Image, {
    type: 'optimizedImage',
    shapeType: 'optimizedImage',
    minimumScaleTrigger: 0,
    // imageData: null,
    lockScalingFlip: true,
    lockSkewingX: true,
    lockSkewingY: true,
    text: null,
    isScaling: false,
    setSRCWithLoader: false,
    renderedFlowchartImage: false,
    startedLoadingImg: false,
    loadingSpinnerProgress: 0,
    uploading:false,
    isTitleRendering: false,  // is the title rendering. False when the title is overriding the parent frame title
    initialize: function (element, options) {
        if (options?.setSRCWithLoader) this.setSRCWithLoader = true;
        if (!element) {
            if (!this.setSRCWithLoader && !options.loader) return;
        }
        const { imageData } = options;
        this.imageData = imageData;
        // set xs element
        this.xsElement = element;
        this.callSuper('initialize', element, { ...options });
        this.firstRender = true;
    },
    getElementByQualityOrder() {
        if(this.xsElement && this.imageMode === 'xs') return this.xsElement;
        if(this.mediumElement && this.imageMode === 'medium') return this.mediumElement;
        if(this.fullElement && this.imageMode === 'full') return this.fullElement;
        return this._element;
    },
    drawMockImage(ctx, remove) {
        const { width: w, height: h } = this;
        const x = -w / 2;
        const y = -h / 2;
        const rx = this.rx ? Math.min(this.rx, w / 2) : 0;
        const ry = this.ry ? Math.min(this.ry, h / 2) : 0;

        // Clear the area where the shape is drawn
        if (remove) {
            ctx.clearRect(x, y, w, h);
            return;
        }
        ctx.fillStyle = '#f5f2f0';
        ctx.fillRect(x, y, w, h);
        // Draw the dashed rectangle border without filling the rectangle
        ctx.lineWidth = 2;
        ctx.setLineDash([5, 5]);
        ctx.strokeRect(x, y, w, h);

        // Draw the dashed diagonal lines
        ctx.beginPath();
        ctx.moveTo(x + rx, y);
        ctx.lineTo(x + w - rx, y + h - ry);
        ctx.moveTo(x + w - rx, y);
        ctx.lineTo(x, y + h - ry);
        ctx.stroke();
    },
    _getZoomLevel(){
        return getZoomLevel(this.canvas.getZoom());
    },
    _setZoomLevel(){
        this._zoomLevel = this._getZoomLevel();
    },
    _setScalingTime(){
        this._prevScalingTime = Date.now();  
    },
    _getOriginalWidth() {
        if(this.xsElement) return this.xsElement.naturalWidth * 10
        else if(this.mediumElement) return this.mediumElement.naturalWidth * 2;
        else if(this.fullElement) return this.fullElement.naturalWidth;
    },
    _render: async function (ctx) {
        if (!this._zoomLevel) {
            this._setZoomLevel();
        }
        fabric.util.setImageSmoothing(ctx, this.imageSmoothing);
        if (this.isMoving !== true && this.resizeFilter && this._needsResize()) {
            this.applyResizeFilters();
        }
        this._stroke(ctx);
        
        let { widthRatio } = this.getZoomedRatio();
        
        const originalWidth = this._getOriginalWidth();
        
        let mediumWidth = originalWidth / 3;
        mediumWidth = mediumWidth + mediumWidth * 0.1;
        
        let smallWidth =  originalWidth / 10;
        smallWidth = smallWidth + smallWidth * 0.1
        
        
        // if ((this.smallImageDownloaded && !this.loader && !this.fullImageDownloaded && this._getZoomLevel() > 15 && isTrulyVisible) || this.uploading) {
        if (this.uploading) {
            const maxRetries = 3;
            let retryCount = 0;
            

            const loadImageWithRetry = () => {
                const img = new Image();
                const imgObj = this;
                
                let imageObject = {}
                
                if (smallWidth < widthRatio && widthRatio < mediumWidth) imageObject = { imageMode: 'medium', imageType: 'med', element: 'mediumElement', downloaded: 'mediumImageDownloaded' }
                else if (widthRatio >= mediumWidth) imageObject = { imageMode: 'full', imageType: 'full', element: 'fullElement', downloaded: 'fullImageDownloaded' }
                else return

                const handleLoadSuccess = function () {
                    if (imgObj.isDeleted) {
                        return
                    }
                    imgObj.imageMode = imageObject?.imageMode;
                    imgObj.onShapeChanged();
                    imgObj[imageObject?.element] = this;
                    imgObj._element = this;
                    imgObj._setScalingTime();
                    imgObj.canvas.renderAll();
                    imgObj[imageObject?.downloaded] = true;
                };

                const handleError = function () {
                    if (retryCount < maxRetries) {
                        retryCount++;
                        setTimeout(loadImageWithRetry, 1000);
                    }
                };

                img.onerror = handleError;
                img.onload = handleLoadSuccess;
                img.crossOrigin = 'anonymous';
                img.src = this.imageData[imageObject?.imageType]?.url;
            };

            loadImageWithRetry();
            this.uploading = false;
        }
        if (this.loader) {
            this.drawMockImage(ctx);
            
            this.loader = false;

            const imgObj = this;
            const loadImageV = (url) => {
                return new Promise((resolve, reject) => {
                    const img = new Image();
                    img.onload = function () {
                        resolve(img);
                    };
                    img.onerror = function (err) {
                        reject(err);
                    };
                    img.crossOrigin = 'anonymous';
                    img.src = url;
                });
            };
            
            const handleLoadImage = ({imageMode, imageType, downloaded, returnCondition, element}) => {
                try {
                    const imagePromise = loadImageV(this.imageData[imageType].url);
                    
                    imagePromise.then((data) => {
                        if (returnCondition) {
                            return
                        }
                        imgObj.imageMode = imageMode
                        imgObj.onShapeChanged();
                        this[downloaded] = true;
                        imgObj[element] = data;
                        imgObj._element = data;
                        imgObj._setScalingTime();
                        this.uploading = false;
                        imgObj.canvas.renderAll();
                    });
                    
                } catch (err) {
                    console.error('Error loading images:', err);
                }
            }

            const loadSmallImage = async () => {
                handleLoadImage({imageMode: 'xs', imageType: 'xs', downloaded: 'smallImageDownloaded', returnCondition: this.fullImageDownloaded, element: 'xsElement'});
            };
            
            const loadMediumImage = async () => {
                handleLoadImage({imageMode: 'medium', imageType: 'med', downloaded: 'mediumImageDownloaded', returnCondition: this.mediumImageDownloaded, element: 'mediumElement'});
            };

            const loadFullImage = async () => {
                handleLoadImage({imageMode: 'full', imageType: 'full', downloaded: 'fullImageDownloaded', returnCondition: this.isDeleted, element: 'fullElement'});
            };
            // if(originalWidth > 1000){
            //     const lowerRatio = 7;
            //     const upperRatio = 12;
            //
            //     const ratioLimit = window.innerWidth / maxRatio;
            //
            //     if(upperRatio > ratioLimit && ratioLimit > lowerRatio) loadMediumImage();
            //     else if(ratioLimit <= lowerRatio)  promiseQueue.addToQueue(loadFullImage());
            //     else loadSmallImage();
            // }
            // else {

            if (smallWidth < widthRatio && widthRatio < mediumWidth) loadMediumImage();
            else if (widthRatio >= mediumWidth) promiseQueue.addToQueue(loadFullImage())
            else loadSmallImage();
            // }
        }
        if (this.setSRCWithLoader && !this.renderedFlowchartImage) {
            if (this.loadingSpinnerAnimationId) cancelAnimationFrame(this.loadingSpinnerAnimationId);
            this._renderLoader(ctx);
        }

        if (this.setSRCWithLoader && !this.renderedFlowchartImage) {
            if (!this.startedLoadingImg) {
                this.startedLoadingImg = true;

                // load image until it loads successfully
                let imgLoadAttempts = 0;
                // eslint-disable-next-line no-constant-condition
                while (true) {
                    imgLoadAttempts++;
                    try {
                        const img = await loadImage(this.imageData.xs.url, { crossOrigin: 'anonymous' });
                        this.imageMode = 'xs';
                        this._element = img;
                        this.renderedFlowchartImage = true;
                        this.startedLoadingImg = false;
                        this.loader = false;
                        this.uploading = false;
                        if (img) this.drawMockImage(ctx, true)
                        break;
                    } catch (e) {
                        console.log(`error while loading image (${imgLoadAttempts})`, e);
                        await new Promise(resolve => setTimeout(resolve, 5000));
                    }
                }
            }

        } else {
            this._renderPaintInOrder(ctx);
        }
        if (this.text && !this.tempHideText) {
            this.renderTitle(ctx);
        }
        if (this.altUrl) {
            this.renderUrl(ctx);
        }
    },
    _renderLoader(ctx) {
        this.loadingSpinnerProgress += 0.01;
        if (this.loadingSpinnerProgress > 1) {
            this.loadingSpinnerProgress = 0;
        }

        const loadingCircle = {
            center: {
                x: 0,
                y: 0
            },
            radius: 50,
            speed: 4
        }

        ctx.save();
        ctx.strokeStyle = '#000';
        ctx.lineWidth = this.strokeWidth;
        ctx.fillStyle = '#fff';
        // draw the white rectangle as a frame
        ctx.fillRect(-this.width / 2, -this.height / 2, this.width, this.height);
        ctx.stroke();

        // draw the loading spinner
        ctx.beginPath();
        const start = this.accelerateInterpolator(this.loadingSpinnerProgress) * loadingCircle.speed;
        const end = this.decelerateInterpolator(this.loadingSpinnerProgress) * loadingCircle.speed;
        ctx.arc(loadingCircle.center.x, loadingCircle.center.y, loadingCircle.radius, (start - 0.5) * Math.PI, (end - 0.5) * Math.PI);
        ctx.stroke();

        ctx.restore();

        // render the canvas to call this method again while the image is still loading
        this.loadingSpinnerAnimationId = requestAnimationFrame(() => {
            this?.canvas?.renderAll();
        });
    },
    _renderMockup(ctx) {
        const w = this.width;
        const h = this.height;
        const x = -w / 2;
        const y = -h / 2;
        const rx = this.rx ? Math.min(this.rx, w / 2) : 0;
        const ry = this.ry ? Math.min(this.ry, h / 2) : 0;

        ctx.save()
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.fillStyle = '#f5f2f0';
        ctx.rect(x, y, w, h);
        ctx.fill();
        ctx.stroke();
        ctx.strokeStyle = 'rgba(0, 0, 0, 0.5)';
        ctx.setLineDash([5, 5]);
        ctx.moveTo(x + rx, y);
        ctx.lineTo(x + w - rx, y + h - ry);

        ctx.moveTo(x + w - rx, y);
        ctx.lineTo(x, y + h - ry)
        ctx.closePath();
        ctx.stroke();
        ctx.restore()
    },
    accelerateInterpolator(x) {
        return x * x;
    },  
    decelerateInterpolator(x) {
        return 1 - ((1 - x) * (1 - x));
    },
    _renderFill(ctx) {
        const isTrulyVisible = this.defaultIsOnScreen();
        let elementToDraw = this.getElementByQualityOrder();
        if (!elementToDraw && !this.setSRCWithLoader && !this.loader) {
            this.drawMockImage(ctx);
            return;
        }
        
        const loadImage = ({imageMode, imageType, element, downloaded}) => {
            this.imageMode = imageMode;
            const img = new Image();
            const imgObj = this;
            this.uploading = false;
            img.onload = function () {
                imgObj.onShapeChanged();
                imgObj[element] = this;
                imgObj._element = this;
                imgObj[downloaded] = true;
                imgObj._setZoomLevel();
                imgObj._setScalingTime();
                imgObj._isScaling = true;
                
                imgObj?.canvas?.renderAll();
                
            }
            img.crossOrigin = 'anonymous';
            img.src = this.imageData[imageType].url;
        }
        
        const loadCurrentImage = ({imageMode, element}) => {
            this._setZoomLevel();
            this.imageMode = imageMode;
            this.onShapeChanged();
            this._element = element;
            this.canvas?.renderAll();
        }
        
        const loadSmallImage = () => {
            loadImage({imageMode:'xs', imageType: 'xs', element: 'xsElement', downloaded: 'smallImageDownloaded'})
        }
        const loadMediumImage = () => {
            loadImage({imageMode: 'medium', imageType: 'med', element: 'mediumElement', downloaded: 'mediumImageDownloaded'});
        }
        const loadFullImage = () => {
            loadImage({imageMode: 'full', imageType: 'full', element: 'fullElement', downloaded: 'fullImageDownloaded'})
        }
        
        let { widthRatio } = this.getZoomedRatio();
        const originalWidth = this._getOriginalWidth();
        let mediumWidth = originalWidth / 3;
        mediumWidth = mediumWidth + mediumWidth * 0.1;
        
        let smallWidth =  originalWidth / 10;
        smallWidth = smallWidth + smallWidth * 0.1;
        
        //zoom in/out check
        try {
            if ((this._zoomLevel !== this._getZoomLevel())) {
                this._startUpgrade = true;
                this.smallImageDownloaded = true;
                this._setZoomLevel();
                this._setScalingTime();
                this._isScaling = true;
            }
            else{
                if(this._startUpgrade && Date.now() - this._prevScalingTime > 200 && isTrulyVisible && !this.uploading){
                    const handleLoadMediumImage = () => {
                        if(!this.mediumElement) loadMediumImage();
                        else{
                            if(this.mediumElement && isTrulyVisible && this.imageMode !== 'medium'){
                                loadCurrentImage({imageMode: 'medium', element: this.mediumElement});
                            }
                        }
                    }
                    const handleLoadFullImage = () => {
                        if(!this.fullElement) loadFullImage();
                        else{
                            if(this.fullElement && isTrulyVisible && this.imageMode !== 'full'){
                                loadCurrentImage({imageMode: 'full', element: this.fullElement});
                            }
                        }
                    }
                    const handleLoadSmallImage = () => {
                        if(!this.xsElement) loadSmallImage();
                        else{
                            if(this.xsElement && isTrulyVisible && this.imageMode !== 'xs'){
                                loadCurrentImage({imageMode: 'xs', element: this.xsElement})
                            }
                        }
                    }
                    // if(originalWidth >= 1000){
                    //     const lowerRatio = 6;
                    //     const upperRatio = 11;
                    //    
                    //     const ratioLimit = (widthRatio > heightRatio ? window.innerWidth : window.innerHeight) / maxRatio;
                    //     if(upperRatio > ratioLimit && ratioLimit > lowerRatio) handleLoadMediumImage();
                    //     else if(ratioLimit <= lowerRatio)  promiseQueue.addToQueue(handleLoadFullImage());
                    //     else handleLoadSmallImage();
                    // }
                    // else {
                    
                    if (smallWidth < widthRatio && widthRatio < mediumWidth) handleLoadMediumImage();
                    else if (widthRatio >= mediumWidth) promiseQueue.addToQueue(handleLoadFullImage());
                    else handleLoadSmallImage();
                    // }
                }
            }
        }
        catch (e) {
            console.log('error while rendering fill', e);
            elementToDraw = this.getElementByQualityOrder();
        }
        
        // panning | moving check on board
        if((this.imageMode === 'xs' || this.imageMode === 'medium') && widthRatio > smallWidth && !this.loader){
            if(widthRatio >= mediumWidth && this.imageMode !== 'full') {
                if(!this.fullElement) promiseQueue.addToQueue(loadFullImage());
                else loadCurrentImage({imageMode:'full', element: this.fullElement})
            }
            else if(smallWidth < widthRatio && widthRatio < mediumWidth && this.imageMode !== 'medium') {
                if(!this.mediumElement) loadMediumImage();
                else loadCurrentImage({imageMode: 'medium', element: this.mediumElement})
            }
        }
        
        const scaleX = this._filterScalingX,
            scaleY = this._filterScalingY,
            w = this.width,
            h = this.height,
            // crop values cannot be lesser than 0.
            cropX = Math.max(this.cropX, 0),
            cropY = Math.max(this.cropY, 0),
            sX = cropX * scaleX,
            sY = cropY * scaleY,
            // the width height cannot exceed element width/height, starting from the crop offset.
            x = -w / 2,
            y = -h / 2;

        try {
            elementToDraw &&
            ctx.drawImage(elementToDraw, sX, sY, elementToDraw.naturalWidth, elementToDraw.naturalHeight, x, y, w, h);
        } catch (e) {
            console.error('error while drawing image', e);
        }
    },
    async asyncSetSrc(src) {
        return new Promise((resolve) => {
            this.setSrc(src, (img) => {
                resolve(img)
            });
        })
    },
    setDefaultElement(element) {
        this._element = element;
        this._originalElement = element;
        this.xsElement = element;

    },
    toObject: function() {
        const objData = fabric.util.object.extend(this.callSuper('toObject'), { imageData: this.imageData });
        delete objData.src
        return objData;
    },
    getImageUrl(order = 'DESC') {
        const imageURls = [
            this.imageData?.full?.url,
            this.imageData?.med?.url,
            this.imageData?.sm?.url,
            this.imageData?.xs?.url,
        ].filter(imageURL => imageURL !== undefined);
        if (order === 'DESC') {
            return imageURls[0];
        }
        return imageURls[imageURls.length - 1];
    },
    toSVG: async function(reviver) {
        const svgMarkup = await this._toSVG(reviver);
        return this._createBaseSVGMarkup(svgMarkup, { reviver: reviver });
    },
    _toSVG: async function() {
        let svgString = [], imageMarkup = [], strokeSvg, element = this.fullElement || this._element,
            x = -this.width / 2, y = -this.height / 2, clipPath = '', imageRendering = '';
        if (!element) {
            return [];
        }
        if (this.hasCrop()) {
            let clipPathId = fabric.Object.__uid++;
            svgString.push(
                '<clipPath id="imageCrop_' + clipPathId + '">\n',
                '\t<rect x="' + x + '" y="' + y + '" width="' + this.width + '" height="' + this.height + '" />\n',
                '</clipPath>\n'
            );
            clipPath = ' clip-path="url(#imageCrop_' + clipPathId + ')" ';
        }
        if (!this.imageSmoothing) {
            imageRendering = '" image-rendering="optimizeSpeed';
        }
        // change: instead of using this.getSvgSrc use element.src.
        const imageURL = this.getImageUrl();
        let dataURL = imageURL;
        try {
            dataURL = await convertImageUrlToDataUrl(imageURL);
        } catch (e) {
            console.log('error converting image url to data url', e);
        }
        imageMarkup.push('\t<image ', 'COMMON_PARTS', 'xlink:href="', dataURL,
            '" x="', x - this.cropX, '" y="', y - this.cropY,
            // we're essentially moving origin of transformation from top/left corner to the center of the shape
            // by wrapping it in container <g> element with actual transformation, then offsetting object to the top/left
            // so that object's center aligns with container's left/top
            // change: instead of using element's dimensions use obj dimensions
            '" width="', this.width,
            '" height="', this.height,
            imageRendering,
            '"', clipPath,
            '></image>\n');
  
        if (this.stroke || this.strokeDashArray) {
            let origFill = this.fill;
            this.fill = null;
            strokeSvg = [
                '\t<rect ',
                'x="', x, '" y="', y,
                '" width="', this.width, '" height="', this.height,
                '" style="', this.getSvgStyles(),
                '"/>\n'
            ];
            this.fill = origFill;
        }
        if (this.paintFirst !== 'fill') {
            svgString = svgString.concat(strokeSvg, imageMarkup);
        }
        else {
            svgString = svgString.concat(imageMarkup, strokeSvg);
        }
        return svgString;
    },
    /**
     * @override
     */
    _getImageLines: function(oCoords) {

        var lines = {
            topline: {
                o: oCoords.tl,
                d: oCoords.tr
            },
            rightline: {
                o: oCoords.tr,
                d: oCoords.br
            },
            bottomline: {
                o: oCoords.br,
                d: oCoords.bl
            },
            leftline: {
                o: oCoords.bl,
                d: oCoords.tl
            },
        };
        if (oCoords.hasOwnProperty('textTL') && oCoords.hasOwnProperty('textTR') && oCoords.hasOwnProperty('textBL') && oCoords.hasOwnProperty('textBR')) {
            const newLines = {
                textTopline: {
                    o: oCoords.textTL,
                    d: oCoords.textTR
                },
                textRightline: {
                    o: oCoords.textTR,
                    d: oCoords.textBR
                },
                textBottomline: {
                    o: oCoords.textBR,
                    d: oCoords.textBL
                },
                textLeftline: {
                    o: oCoords.textBL,
                    d: oCoords.textTL
                }
            }
            lines = {
                ...lines,
                ...newLines
            }
        }

  
        return lines;
    },
    /**
     * @override
     */
    containsPoint: function(point, lines, absolute, calculate) {
        let coords = this._getCoords(absolute, calculate);
        let xLines = lines || this._getImageLines(coords);
        let xPoints = this._findCrossPoints(point, xLines);
        // if xPoints is odd then point is inside the object
        return (xPoints !== 0 && xPoints % 2 === 1);
    },
    /**
     * @override
     */
    calcACoords: function() {
        var rotateMatrix = this._calcRotateMatrix(),
            translateMatrix = this._calcTranslateMatrix(),
            finalMatrix = fabric.util.multiplyTransformMatrices(translateMatrix, rotateMatrix),
            dim = this._getTransformedDimensions(),
            w = dim.x / 2, h = dim.y / 2;

        
        const absoluteCoords =  {
            // corners
            tl: fabric.util.transformPoint({ x: -w, y: -h }, finalMatrix),
            tr: fabric.util.transformPoint({ x: w, y: -h }, finalMatrix),
            bl: fabric.util.transformPoint({ x: -w, y: h }, finalMatrix),
            br: fabric.util.transformPoint({ x: w, y: h }, finalMatrix)
        };

        // if text is present, then calculate the text bounding box and add it to the absolute coords
        if (this.canvas && typeof this.text === 'string' && this.text.trim().length > 0) {
            const zoomLevel = this.canvas.getZoom();
            const textMetrics = getTextMetrics(this.text, `${IMAGE_TITLE_FONT_SIZE / zoomLevel}px Rubik, sans-serif`);
            const textHeight = Math.abs(textMetrics.actualBoundingBoxAscent) + Math.abs(textMetrics.actualBoundingBoxDescent);
            const textBoundingBox = {
                width: textMetrics.width >= this.width * this.scaleX ? this.width * this.scaleX : textMetrics.width,
                height: textHeight * 2,
                left: -w,
                top: -h - textHeight * 2,
            }
            absoluteCoords.textTL = fabric.util.transformPoint({ x: textBoundingBox.left, y: textBoundingBox.top }, finalMatrix);
            absoluteCoords.textTR = fabric.util.transformPoint({ x: textBoundingBox.left + textBoundingBox.width, y: textBoundingBox.top }, finalMatrix);
            absoluteCoords.textBL = fabric.util.transformPoint({ x: textBoundingBox.left, y: textBoundingBox.top + textBoundingBox.height }, finalMatrix);
            absoluteCoords.textBR = fabric.util.transformPoint({ x: textBoundingBox.left + textBoundingBox.width, y: textBoundingBox.top + textBoundingBox.height }, finalMatrix);

        }
        return absoluteCoords;
    },
    /**
     * @override
     */
    calcLineCoords: function() {
        var vpt = this.getViewportTransform(),
            padding = this.padding, angle = fabric.util.degreesToRadians(this.angle),
            cos = fabric.util.cos(angle), sin = fabric.util.sin(angle),
            cosP = cos * padding, sinP = sin * padding, cosPSinP = cosP + sinP,
            cosPMinusSinP = cosP - sinP, aCoords = this.calcACoords();
  
        var lineCoords = {
            tl: fabric.util.transformPoint(aCoords.tl, vpt),
            tr: fabric.util.transformPoint(aCoords.tr, vpt),
            bl: fabric.util.transformPoint(aCoords.bl, vpt),
            br: fabric.util.transformPoint(aCoords.br, vpt),
        };

        if (aCoords.hasOwnProperty('textTL') && aCoords.hasOwnProperty('textTR') && aCoords.hasOwnProperty('textBL') && aCoords.hasOwnProperty('textBR')) {
            const textCoords = {
                textTL: fabric.util.transformPoint(aCoords.textTL, vpt),
                textTR: fabric.util.transformPoint(aCoords.textTR, vpt),
                textBL: fabric.util.transformPoint(aCoords.textBL, vpt),
                textBR: fabric.util.transformPoint(aCoords.textBR, vpt),
            }
            lineCoords = {
                ...lineCoords,
                ...textCoords
            }
        }
  
        if (padding) {
            lineCoords.tl.x -= cosPMinusSinP;
            lineCoords.tl.y -= cosPSinP;
            lineCoords.tr.x += cosPSinP;
            lineCoords.tr.y -= cosPMinusSinP;
            lineCoords.bl.x -= cosPSinP;
            lineCoords.bl.y += cosPMinusSinP;
            lineCoords.br.x += cosPMinusSinP;
            lineCoords.br.y += cosPSinP;
        }
  
        return lineCoords;
    },
    renderTitle(ctx) {
        ctx.save();
        const zoom = this.canvas ? this.canvas.getZoom() : 1;
        ctx.scale(1 / this.scaleX, 1 / this.scaleY);
        ctx.font = `${IMAGE_TITLE_FONT_SIZE / zoom}px Rubik, sans-serif`;
        const thicknessX = (this.strokeWidth * this.scaleX) / 2;
        const thicknessY = (this.strokeWidth * this.scaleY) / 2;

        const measureText = ctx.measureText(this.text);
        let shouldRender = true;
        try {
            if (this.attachedFrameId) {
                const topOfText = this.top - measureText.actualBoundingBoxAscent * 2;
                if (this.canvas) {
                    let frameInstance = this.wiredFrame;
                    let frameTop;

                    // if there is wiredFrame instance in the props, don't mind to finding frame in the canvas
                    if (frameInstance) {
                        frameTop = frameInstance.top;
                    } else {
                        const actualFrame = this.canvas?.getObjects()?.find(obj => obj.uuid === this.attachedFrameId);
                        if (actualFrame) {
                            frameTop = actualFrame.top
                        }
                    }
                    if (topOfText < frameTop) {
                        shouldRender = false;
                    }
                }
            }
        } catch (err) {
            console.error('Error while rendering title', err);
        }

        this.isTitleRendering = shouldRender;
        if (shouldRender) {
            const renderingText = getShortenedText(this.text, this.width * this.scaleX, ctx);

            const textPosition = {
                x: (-this.width * this.scaleX) / 2 - thicknessX,
                y: (-this.height * this.scaleY) / 2 - thicknessY - measureText.actualBoundingBoxAscent
            }

            ctx.fillStyle = SHAPE_DEFAULTS.TEXT_COLOR;
            ctx.fillText(renderingText, textPosition.x, textPosition.y);
        }
        ctx.restore();
    },
    renderUrl(ctx) {
        ctx.save();
        const zoom = this.canvas ? this.canvas.getZoom() : 1;
        ctx.font = `${IMAGE_TITLE_FONT_SIZE / zoom}px Rubik, sans-serif`;
        const measureText = ctx.measureText(this.altUrl);
        const renderingText = getWantedTextFromWidth(this.altUrl, this.width, ctx);
        ctx.fillStyle = SHAPE_DEFAULTS.TEXT_COLOR;
        ctx.fillText(renderingText, -this.width / 2, (this.height / 2) + measureText.actualBoundingBoxAscent);
        ctx.restore();
    },
});


const getWantedTextFromWidth = (text, width, ctx) => {
    let fullText = '';
    for (const letter of text) {
        const measureText = ctx.measureText(fullText + letter);
        if (measureText.width > width) {
            return fullText;
        } else if (ctx.measureText(fullText + letter + '...').width > width) {
            if (!fullText.length) return '';
            return fullText + '...';
        }
        fullText += letter;
    }
    return fullText;
}


/**
 * Creates an OptimizedImage from an object representation.
 * @param {object} object Object to create OptimizedImage from 
 * @param {Function} callback callback function to call after image is loaded
 */
export const optimizedImageFromObject = async function (object, callback) {
    if (object.imageData?.xs?.url && object.imageData?.full?.url) {
        try {
            object.loader = true;
            object.fullImageDownloaded = false;
            callback && callback(new OptimizedImage(null, object));
        } catch (err) {
            const options = {
                ...object
            }
            // if the image is a flowchart image and we couldn't load the image,
            // retry loading
            if (object.flowchartProps) {
                options.setSRCWithLoader = true
            }
            callback && callback(new OptimizedImage(null, options));
        }
    }

};

export default OptimizedImage;