import React from 'react';
import ArrowLeft from '../svgIcons/arrows/ArrowLeft';
import { useNavigate } from 'react-router-dom';

const BackToLogin = () => {
    const navigate = useNavigate();

    const goBack = () => {
        navigate('/');
    };

    return (
        <div className="go-back-container">
            <button type='button' className="go-back" data-testid='go-back' onClick={goBack}>
                <ArrowLeft />
                Back to login
            </button>
        </div>
    );
}

export default BackToLogin;