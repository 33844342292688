import {RTree} from './RTree';
import {copyFromRect, createFromRect, equalsWithRect} from '../../viewPort/Viewport';


export class SpatialIndex {
    constructor() {
        this.objects = new Map();
        this.rtree = new RTree(16);
    }

    get size() {
        return this.objects.size;
    }

    destroy() {
        this.clear();
    }

    has(item) {
        return this.objects.has(item);
    }

    create(item, rect) {
        return {
            item,
            bbox: createFromRect(rect)
        };
    }

    load(items) {
        for (let i = 0, length = items.length; i < length; i++) {
            let item = items[i];
            this.objects.set(item.item, item);
        }
        this.rtree.load(items);
    }

    add(item, rect) {
        if (!this.objects.has(item)) {
            let entry = this.create(item, rect);
            this.objects.set(item, entry);
            this.rtree.insert(entry);
        }
    }

    remove(item) {
        let entry = this.objects.get(item);
        if (entry) {
            this.objects.delete(item);
            this.rtree.remove(entry);
        }
    }

    update(item, rect) {
        let entry = this.objects.get(item);
        if (entry && !equalsWithRect(entry.bbox, rect)) {
            this.rtree.remove(entry);
            copyFromRect(entry.bbox, rect);
            this.rtree.insert(entry);
        }
    }

    upsert(item, rect) {
        this.has(item) ? this.update(item, rect) : this.add(item, rect);
    }

    clear() {
        this.objects.clear();
        this.rtree.clear();
    }

    getIntersectedObjects(rect, callback) {
        return this.rtree.query(rect, callback);
    }

    forEachIntersectedObjects(rect, callback) {
        this.rtree.visit(rect, callback);
    }
}