/**
 *
 */
const IconArrowUp = () => {
    return (
        <svg fill="none" height="6" viewBox="0 0 10 6" width="10" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 5L5 1L1 5" stroke="#3C3E49"/>
        </svg>
    )
}

export default IconArrowUp;