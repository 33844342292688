import AddMessageBlack from '../../assets/images/comments/add_message_black.svg';
import AddMessageBlue from '../../assets/images/comments/add_message_blue.svg';
import AddMessageGreen from '../../assets/images/comments/add_message_green.svg';
import AddMessageOrange from '../../assets/images/comments/add_message_orange.svg';
import AddMessagePurple from '../../assets/images/comments/add_message_purple.svg';
import AddMessageRed from '../../assets/images/comments/add_message_red.svg';
import AddMessageWhite from '../../assets/images/comments/add_message_white.svg';
import AddMessageYellow from '../../assets/images/comments/add_message_yellow.svg';

export const colorOptions = {
    purple: {
        image: AddMessagePurple,
        id: 'purple',
        soft: '#F3EBFF',
        hard: '#7C4DFF',
    },
    red: {
        image: AddMessageRed,
        id: 'red',
        soft: '#FEE5E2',
        hard: '#D50000',
    },
    orange: {
        image: AddMessageOrange,
        id: 'orange',
        soft: '#F1E3CD',
        hard: '#F90',
    },
    yellow: {
        image: AddMessageYellow,
        id: 'yellow',
        soft: '#FDF8DD',
        hard: '#FFD64D',
    },
    green: {
        image: AddMessageGreen,
        id: 'green',
        soft: '#D9FFD2',
        hard: '#4BB43A',
    },
    blue: {
        image: AddMessageBlue,
        id: 'blue',
        soft: '#D4F0FF',
        hard: '#00A8FF',
    },
};

export const extendedColorOptions = {
    black: { image: AddMessageBlack, id: 'default', soft: '#DFE0E6' },
    ...colorOptions,
    white: {
        image: AddMessageWhite,
        id: 'white',
    },
};

/**
 * Returns comment icon image for given color.
 * @param {"blue"|"orange"|"red"|"green"|"purple"|"yellow"} color
 */
export default function getCommentIcon(color) {
    if (!Object.entries(extendedColorOptions).flat().includes(color))
        return extendedColorOptions.blue.image;
    return extendedColorOptions[color].image;
}

/**
 * @param color
 */
export function getColorConvention(color) {
    return extendedColorOptions[color];
}
