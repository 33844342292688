import { environment } from '../environment';

/**
 * @param email
 * @param browserInfo
 */
export function identityUser(email, browserInfo) {
    window.analytics.identify({
        email: email,
        browserInfo: browserInfo,
    });
}
/**
 * @param name
 * @param description
 * @param options
 */
export function trackAction(name, description, options) {
    if (environment.envType === 'prod') {
        window.analytics.track(name, {
            ...{ description: description },
            ...options,
        });
    }
}
