import React from 'react';
import PropTypes from 'prop-types';

const Hyperlink3 = ({ width, height }) => (
    <svg
        fill="none"
        height={height}
        viewBox="0 0 24 20"
        width={width}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.36403 16.5L8.43203 17.432C6.67466 19.1894 3.8254 19.1894 2.06803 17.432C0.310657 15.6747 0.310657 12.8254 2.06803 11.068L6.84103 6.29405C8.50064 4.63068 11.1607 4.5242 12.9479 6.04959C14.7352 7.57498 15.048 10.2187 13.666 12.119"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
        />
        <path
            d="M14.8183 3.56705L15.5683 2.81705C17.3257 1.05968 20.175 1.05968 21.9323 2.81705C23.6897 4.57442 23.6897 7.42368 21.9323 9.18105L17.1593 13.9541C15.4994 15.6167 12.8397 15.7225 11.0529 14.197C9.26608 12.6716 8.95354 10.0282 10.3353 8.12805"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
        />
    </svg>
);

Hyperlink3.propTypes = {
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

Hyperlink3.defaultProps = {
    width: 24,
    height: 24
}

export default Hyperlink3;
