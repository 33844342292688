import store from '../redux/Store';
import { USER_ROLES } from './Constant';
import { isBuilderAiUser } from './Validation';

/**
 * Checking the user is still has access to board after removing
 * @param {{ userPermission: string, builderUserPermission: string }} param0 
 * @returns {boolean}
 */
export const isUserHasAccessToBoardWhileNotMember = ({
    userPermission,
    builderUserPermission
}) => {
    const state = store.getState() || {};

    const { publicSharePermission } = state.board;
    const { email } = state.user

    // Public share permissions is coming as non_allowed from reducer as default in some places. So in those places; we should get the data from the initiator function.
    userPermission = userPermission || publicSharePermission.users; // Public Shareable Users Permission
    builderUserPermission = builderUserPermission || publicSharePermission.employees; // Public Shareable Builder User Permission

    const isBuilderUser = isBuilderAiUser(email);

    const hasPublicShareableAccessForUser = userPermission !== USER_ROLES.notAllowed.id;
    const hasPublicShareableAccessForBuilderUser = userPermission !== USER_ROLES.notAllowed.id || builderUserPermission !== USER_ROLES.notAllowed.id;

    return (
        (isBuilderUser && hasPublicShareableAccessForBuilderUser) ||
        (!isBuilderUser && hasPublicShareableAccessForUser)
    );
}

/**
 * 
 * @returns {boolean}
 */
export const hasPublicAccess = () => {
    const state = store.getState() || {};
    const { isPublicAccess } = state.board;
    return isPublicAccess;
}

export const isPublicLink = () => {
    const state = store.getState() || {};
    const { isPublicLink } = state.board;
    return isPublicLink;
}

export const isUserMemberOfTheBoard = (userId) => {
    const state = store.getState() || {};

    const { users } = state.board;
    return users.some((user) => user.id === userId);
}