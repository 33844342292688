import { fabric } from 'fabric';
import { scaleEquallyHandler, scaleXActionHandler, scaleYActionHandler } from './ActionHandler';
import { renderSquareWhiteControl } from '../CommonControlHelpers';

export const widthControlML = new fabric.Control({
    x: -0.5,
    y: 0,
    cursorStyleHandler: fabric.controlsUtils.scaleCursorStyleHandler,
    actionHandler: scaleXActionHandler,
});

export const widthControlMR = new fabric.Control({
    x: 0.5,
    y: 0,
    cursorStyleHandler: fabric.controlsUtils.scaleCursorStyleHandler,
    actionHandler: scaleXActionHandler,
});


export const heigthControlMT = new fabric.Control({
    x: 0,
    y: -0.5,
    cursorStyleHandler: fabric.controlsUtils.scaleCursorStyleHandler,
    actionHandler: scaleYActionHandler,
});

export const heigthControlMB = new fabric.Control({
    x: 0,
    y: 0.5,
    cursorStyleHandler: fabric.controlsUtils.scaleCursorStyleHandler,
    actionHandler: scaleYActionHandler,
});


export const scaleControlTR = new fabric.Control({
    x: 0.5,
    y: -0.5,
    cursorStyleHandler: fabric.controlsUtils.scaleCursorStyleHandler,
    actionHandler: scaleEquallyHandler,
    render: renderSquareWhiteControl
});

export const scaleControlTL = new fabric.Control({
    x: -0.5,
    y: -0.5,
    cursorStyleHandler: fabric.controlsUtils.scaleCursorStyleHandler,
    actionHandler: scaleEquallyHandler,
    render: renderSquareWhiteControl
});

export const scaleControlBL = new fabric.Control({
    x: -0.5,
    y: 0.5,
    cursorStyleHandler: fabric.controlsUtils.scaleCursorStyleHandler,
    actionHandler: scaleEquallyHandler,
    render: renderSquareWhiteControl
});

export const scaleControlBR = new fabric.Control({
    x: 0.5,
    y: 0.5,
    cursorStyleHandler: fabric.controlsUtils.scaleCursorStyleHandler,
    actionHandler: scaleEquallyHandler,
    render: renderSquareWhiteControl
});