import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const ToolbarItem = ({
    type,
    className,
    tutorial,
    onClick,
    children,
    isInnerButton,
    disabled
}) => {

    let dom = null;
    switch (type) {
        case 'seperator':
            dom = (
                <div className="separator" />
            );
            break;
        case 'button':
            dom = (
                <div
                    className={clsx({ 'button': !isInnerButton, 'inner__button': isInnerButton, [className]: true })}
                    data-tutorial={tutorial}
                    onClick={onClick}
                    disabled={disabled}
                >
                    {children}
                </div>
            );
            break;
        case 'subToolbarWrapper':
            dom = (
                <div
                    className={clsx('button', 'haveSubToolbar', { [className]: true })}
                >
                    {children[0]}
                    {children[1]}
                </div>
            );
            break;
        case 'subToolbarItem':
            dom = (
                <div
                    className={clsx('subToolbar', { [className]: true })}
                >
                    {children}
                </div>
            );
            break;
    }

    return dom;
};

ToolbarItem.propTypes = {
    type: PropTypes.oneOf(['seperator', 'button', 'subToolbarWrapper', 'subToolbarItem']).isRequired,
    className: PropTypes.string,
    tutorial: PropTypes.string,
    onClick: PropTypes.func,
    isInnerButton: PropTypes.bool,
    disabled: PropTypes.bool
}

ToolbarItem.defaultProps = {
    onClick: () => console.log('Item is clicked'),
    isInnerButton: false,
    disabled: false
}

export default ToolbarItem;