import {fabric} from 'fabric';

/**
 * This method is getting called with object.set({...}) or object.set('key', value).
 * @override
 */
export function _set(key, value) {
    let shouldConstrainValue = (key === 'scaleX' || key === 'scaleY'),
        isChanged = this[key] !== value, groupNeedsUpdate = false;

    if (shouldConstrainValue) {
        value = this._constrainScale(value);
    }
    if (key === 'scaleX' && value < 0) {
        this.flipX = !this.flipX;
        value *= -1;
    }
    else if (key === 'scaleY' && value < 0) {
        this.flipY = !this.flipY;
        value *= -1;
    }
    else if (key === 'shadow' && value && !(value instanceof fabric.Shadow)) {
        value = new fabric.Shadow(value);
    }
    else if (key === 'dirty' && this.group) {
        this.group.set('dirty', value);
    }

    this[key] = value;

    if (isChanged) {
        groupNeedsUpdate = this.group && this.group.isOnACache();
        if (this.cacheProperties.indexOf(key) > -1) {
            this.dirty = true;
            groupNeedsUpdate && this.group.set('dirty', true);
        }
        else if (groupNeedsUpdate && this.stateProperties.indexOf(key) > -1) {
            this.group.set('dirty', true);
        }
    }

    // override
    // if object has uuid and object is not in the map, add it to the map
    if (key === 'uuid' && this.canvas && !this.canvas?.objectsMap?.has(value)) {
        this.canvas.objectsMap.addWithUuid(value, this)
    }

    return this;
}