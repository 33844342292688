import React, { useLayoutEffect, useEffect, useRef, useState, useCallback } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { MAX_TABLE_BOX_COL_SIZE, MAX_TABLE_BOX_ROW_SIZE, MIN_TABLE_BOX_COL_SIZE, MIN_TABLE_BOX_ROW_SIZE } from '../../helpers/Constant';
import Tooltip from '../tooltip/Tooltip';
import './tableBox.scss';

const defaultState = { rows: 5, cols: 10, activeRow: 2, activeCol: 2, isHovered: false }

const TableBox = ({
    handleAddObject
}) => {
    const tableBoxRef = useRef();
    const [isTableBoxCentered, setIsTableBoxCentered] = useState(false);
    const [state, setState] = useState(defaultState);

    const addTable = () => {
        handleAddObject('table', { totalCols: state.activeCol + 1, totalRows: state.activeRow + 1 });
        tableBoxRef.current.style.display = 'none';

        setTimeout(() => {
            tableBoxRef.current.style.display = 'block';
        }, 300);
    }

    const onMouseMove = (e) => {
        const item = e.target;
        if (!e.target.classList.contains('tableBox__wrapper__items__item')) return;

        const activeRow = parseInt(item.getAttribute('data-row'), 10);
        const activeCol = parseInt(item.getAttribute('data-col'), 10);

        const isColChanged = state.activeCol !== activeCol;
        const isRowChanged = state.activeRow !== activeRow;
        
        if (!isColChanged && !isRowChanged) return;

        const rowDirection = state.activeRow < activeRow ? 'BOTTOM' : 'TOP';
        const colDirection = state.activeCol < activeCol ? 'RIGHT' : 'LEFT';

        setState((currState) => {
            let row = currState.rows;
            let col = currState.cols;

            if (isRowChanged && activeRow <= MAX_TABLE_BOX_ROW_SIZE) {
                if (rowDirection === 'BOTTOM' && activeRow + 1 === row) {
                    row = activeRow + 2;
                } else if (rowDirection === 'TOP' && activeCol <= MAX_TABLE_BOX_ROW_SIZE - 2) {
                    row = activeRow + 2;
                }
            }

            if (isColChanged && activeCol <= MAX_TABLE_BOX_COL_SIZE) {
                if (colDirection === 'RIGHT' && activeCol + 1 === col) {
                    col = activeCol + 2;
                } else if (colDirection === 'LEFT' && activeCol <= MAX_TABLE_BOX_COL_SIZE - 2) {
                    col = activeCol + 2;
                }
            }

            return {
                rows: Math.min(Math.max(row, MIN_TABLE_BOX_ROW_SIZE), MAX_TABLE_BOX_ROW_SIZE),
                cols: Math.min(Math.max(col, MIN_TABLE_BOX_COL_SIZE), MAX_TABLE_BOX_COL_SIZE),
                activeRow: activeRow,
                activeCol: activeCol,
                isHovered: true
            }
        })
    }

    const windowMouseMoveListener = useCallback((event) => {
        if (
            state.isHovered &&
            (!event.target ||
            (!event.target.classList.contains('addTableBtn') && !event.target.closest('.addTableBtn')))
        ) {
            setTimeout(() => {
                setState(defaultState);
            }, 300);
        }
    }, [state.isHovered]);

    useLayoutEffect(() => {
        const tablePos = tableBoxRef.current.getBoundingClientRect();
        const tableCellHeight = 23;
        const addableRowCount = MAX_TABLE_BOX_ROW_SIZE - MIN_TABLE_BOX_ROW_SIZE;
        const willTableboxExceedWindow = tablePos.bottom + (tableCellHeight * addableRowCount) > window.innerHeight - 50;

        if (willTableboxExceedWindow) {
            setIsTableBoxCentered(true);
        }
    }, []);

    useEffect(() => {
        if (state.isHovered) {
            window.addEventListener('mousemove', windowMouseMoveListener);
        }
        return () => window.removeEventListener('mousemove', windowMouseMoveListener);
    }, [windowMouseMoveListener, state.isHovered]);

    return (
        <div className={clsx('tableBox', { '--centered': isTableBoxCentered })} ref={tableBoxRef}>
            <div className="tableBox__wrapper">
                <span className="tableBox__wrapper__text">Hover to select rows and columns</span>

                <div
                    className="tableBox__wrapper__items"
                    onMouseMove={onMouseMove}
                    style={{
                        width: state.cols * 23 + 1,
                        height: state.rows * 23 + 1,
                    }}
                >
                    {Array.from(Array(state.rows)).map((row, rowIdx) => (
                        Array.from(Array(state.cols)).map((col, colIdx) => (
                            <div
                                className={clsx('tableBox__wrapper__items__item', {
                                    '--selected': rowIdx <= state.activeRow && colIdx <= state.activeCol
                                })}
                                data-col={colIdx}
                                data-row={rowIdx}
                                key={`col_${colIdx}_row_${rowIdx}`}
                                onClick={addTable}
                            >
                                <Tooltip position="top" text={`${colIdx + 1}x${rowIdx + 1}`} />
                            </div>
                        ))
                    ))}
                </div>
            </div>
        </div>
    )
}

TableBox.propTypes = {
    handleAddObject: PropTypes.func.isRequired
}

export default TableBox;