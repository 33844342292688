import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import { _hasLowerCase, _hasNumber, _hasUpperCase, passwordValidation } from '../../../helpers/Validation';
import {
    checkIfTheResetPasswordLinkIsValidate,
    resetPassword,
} from '../../../services/AuthService';
import getToastIcon from '../../../helpers/media/GetToastIcon';
import '../Auth.scss';
import { AUTH_VALIDATION_MESSAGE } from '../../../helpers/Constant';
import PasswordInput from '../../inputs/passwordInput/PasswordInput';
import ButtonLoading from '../../inputs/button/ButtonLoading';
import AuthBaseScreen from '../../authBaseScreen/AuthBaseScreen';
import LoadingScreen from '../../loader/Loading';
import { handleLabelPosition } from '../../../helpers/InputLabelHelper';
import BackToLogin from '../BackToLogin';
import SentSuccessful from '../../../assets/images/done.png';
import PasswordConfirmationBox from '../PasswordConfirmationBox';
import Scrollbar from 'react-scrollbars-custom';
/**
 *
 */
const ResetPasswordScreen = () => {
    const navigate = useNavigate();
    
    const passwordLabel = useRef();
    const confirmPasswordLabel = useRef();

    const [isLinkValidate, setLinkValidate] = useState(false);

    const checkIfLinkIsValidate = async (email, token) => {
        setLoading('loading');
        const response = await checkIfTheResetPasswordLinkIsValidate(email, token);
        
        if (response.status !== 'success') setLinkValidate(false)
        else setLinkValidate(true);
        setLoading(false);
    };

    const initialValues = {
        password: '',
        confirmPassword: '',
    };
    const [values, setValues] = useState(initialValues);

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState('loading');

    const [touched, setTouched] = useState({});
    const [showConfirmStatements, setShowConfirmStatements] = useState(false);
    const [isConfirmStatementsActivated, setIsConfirmStatementsActivated] = useState(false);

    const [searchParams] = useSearchParams();
    const email = searchParams.get('email');
    const token = searchParams.get('token');
    useEffect(() => {
        checkIfLinkIsValidate(email, token);
    }, []);

    const handleChange = (evt) => {
        const { name, value: newValue } = evt.target;
        if (name === 'password') {
            setShowConfirmStatements(true);
            setIsConfirmStatementsActivated(true);
        }
        
        let error = undefined;
        if(newValue === ''){
            if(name === 'password') error = AUTH_VALIDATION_MESSAGE.PASSWORD_REQUIRED
            else if(name === 'confirmPassword') error = AUTH_VALIDATION_MESSAGE.FIELD_REQUIRED
        }
        setErrors({ ...errors, [name]: error });
        setValues({
            ...values,
            [name]: newValue,
        });
        setTouched({
            ...touched,
            [name]: true,
        });
    };

    const handleSubmit = async (evt) => {
        evt.preventDefault();
        const formValidation = Object.keys(values).reduce(
            (acc, key) => {
                const newError =
          key == 'confirmPassword'
              ? passwordValidation(values[key], true, false)
              : passwordValidation(values[key]);
                const newTouched = { [key]: true };
                return {
                    errors: {
                        ...acc.errors,
                        ...(newError && { [key]: newError }),
                    },
                    touched: {
                        ...acc.touched,
                        ...newTouched,
                    },
                };
            },
            {
                errors: { ...errors },
                touched: { ...touched },
            }
        );
        setErrors(formValidation.errors);
        setTouched(formValidation.touched);

        if (
            formValidation.errors.confirmPassword === undefined &&
      formValidation.errors.password === undefined
        ) {
            if (values.password !== values.confirmPassword) {
                setErrors({
                    ...errors,
                    confirmPassword: AUTH_VALIDATION_MESSAGE.PASSWORD_NOT_MATCH,
                });
            } else {
                setLoading('loading');
                const response = await resetPassword(email, values.password, token);
                
                if (response.status !== 'success') {
                    toast.error(
                        response.message || AUTH_VALIDATION_MESSAGE.PASSWORD_NOT_RESET,
                        {
                            icon: getToastIcon('error'),
                            className: 'wb_toast',
                        }
                    );
                }
                else {
                    setLoading('success');
                }
            }
        }
    };

    const handleConfirmStatementVisual = (event) => {
        const eventType = event?.type;
        handleLabelPosition(event);
        if ((!isConfirmStatementsActivated || !checkValidation()) && eventType === 'blur') setShowConfirmStatements(false);
    }

    const checkButtonDisableStatus = () => {
        return values.password === '' || values.confirmPassword === '' || errors.password || errors.confirmPassword || checkValidation()
    }

    const checkValidation = () => {
        return values.password.length < 8 || !_hasLowerCase(values.password) || !_hasUpperCase(values.password) || !_hasNumber(values.password)
    }

    useEffect(() => {
        if (values.password !== '') {
            passwordLabel?.current?.classList.add('active');
        }
        if (values.confirmPassword !== '') {
            confirmPasswordLabel?.current?.classList.add('active');
        }
    }, [loading])

    if (loading === 'loading') return (
        <div className="tab_loader" data-testid="loading">
            <LoadingScreen />
        </div>
    )
    else if (loading === 'success') return (
        <AuthBaseScreen>
            <div className="sent-successful-container">
                <img className='sent-successful' src={SentSuccessful} alt="sent-successful"/>
                <div className='successful-description'>
                    Your password has been reset successfully
                </div>
                <span className='successful-description-sub'>Login using your updated password</span>
                <ButtonLoading
                    className='login-button'
                    onClick={() => navigate('/')}
                    text='Login'
                    type='submit'
                />
            </div>
        </AuthBaseScreen>
    )
    return (
        <AuthBaseScreen>
            {isLinkValidate ? (
                <Scrollbar trackYVisible={true} style={{position:''}}>
                    <form className="formElements reset-password" data-testid="form-reset-password">
                        <h3 className='reset-password-title'>Reset password</h3>
                        <ul className='reset-password-list'>
                            <li>
                                <div>
                                    <label ref={passwordLabel} id='password-label'>New password</label>
                                    <PasswordInput
                                        className={clsx('input', {error: errors.password})}
                                        minLength="8"
                                        name="password"
                                        onChange={handleChange}
                                        onFocus={handleConfirmStatementVisual}
                                        onBlur={handleConfirmStatementVisual}
                                        value={values.password}
                                    />
                                </div>
                                {touched.password && values.password === '' ?
                                    <div className={clsx('errorMsgBox', {'showError': values.password === ''})}>
                                        <div className="errorMsg">{AUTH_VALIDATION_MESSAGE.PASSWORD_REQUIRED}</div>
                                    </div> : null
                                }
                                {
                                    showConfirmStatements && values.password !== '' ?
                                        <PasswordConfirmationBox
                                            password={values.password}
                                            isActivated={isConfirmStatementsActivated}
                                        /> : null
                                }
                            </li>
                            <li>
                                <div>
                                    <label ref={confirmPasswordLabel} id='confirm-password-label'>Re-enter new
                                        password</label>
                                    <PasswordInput
                                        className={clsx('input', {error: !!errors.confirmPassword})}
                                        minLength="1"
                                        name="confirmPassword"
                                        onChange={handleChange}
                                        onFocus={handleLabelPosition}
                                        onBlur={handleLabelPosition}
                                        value={values.confirmPassword}
                                    />
                                </div>
                                {touched.confirmPassword && errors.confirmPassword ? <div
                                    className={`errorMsgBox ${errors.confirmPassword ? 'showError' : ''
                                    }`}
                                >
                                    <div className="errorMsg">{errors.confirmPassword}</div>
                                </div> : null}
                            </li>
                        </ul>
                        <div className="footersection reset-password">
                            <div className="actionbtn reset-password">
                                <div className={clsx('sign-in-button-wrapper', {disabled: checkButtonDisableStatus()})}>
                                    <ButtonLoading
                                        disabled={checkButtonDisableStatus()}
                                        className={clsx('doneButton', {loading: loading})}
                                        isLoading={loading === 'loading'}
                                        loadingClassName="loading"
                                        onClick={handleSubmit}
                                        text="Reset password"
                                        type="submit"
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </Scrollbar>
            ) : (
                <div className="link-expire-container" data-testid="link-expired-text">
                    <div className="link-expire-text">
                        This link has expired
                    </div>
                    <div className="link-expire-info">
                        Please resend the link by clicking on <br /> ‘Forgot password’ on login screen
                    </div>
                    <BackToLogin />
                </div>
            )}
        </AuthBaseScreen>
    );
}
export default ResetPasswordScreen;
