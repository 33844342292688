import { Rect } from './Rect';
import { Path } from './Path';
import { Point } from './Point';
import { Curve } from './Curve';
import { getLineDirections } from '../LineMethods';
import { CatmullRom } from '../CatmullRom';

/**
 * !Note: The algorithms that we use into this or imported classses are taken from JointJS Open Source Library.
 * The source code minified up to ~2000 lines from ~40000 lines. I only take the functions that we need for curved line.
 * https://github.com/clientIO/joint
 */
export class CurvedLineHelper {
    Directions = {
        AUTO: 'auto',
        HORIZONTAL: 'horizontal',
        VERTICAL: 'vertical',
        CLOSEST_POINT: 'closest-point',
        OUTWARDS: 'outwards'
    }

    TangentDirections = {
        UP: 'up',
        DOWN: 'down',
        LEFT: 'left',
        RIGHT: 'right',
        AUTO: 'auto',
        CLOSEST_POINT: 'closest-point',
        OUTWARDS: 'outwards'
    }

    points = [];

    constructor(points) {
        if (!Array.isArray(points) || !points) {
            this.points = [];
        } else if (Array.isArray(points[0])) {
            this.points = points.map((p) => ({ x: p[0], y: p[1] }));
        } else if (typeof points === 'object') {
            this.points = points;
        }
    }

    generatePath(object) {
        return object.curvedLineVersion === 'v2' ? this.generatePathViaBezier(object) : this.generatePathViaCatmullRom();
    }

    generatePathViaBezier(object) {
        const source = new Point(this.points[0].x, this.points[0].y);
        const target = new Point(this.points[this.points.length - 1].x, this.points[this.points.length - 1].y);
        const route = this.points.slice(1, this.points.length - 1)
        const opt = {
            targetDirection: 'auto',
            sourceDirection: 'auto',
            distanceCoefficient: 0.5
        }

        const { sourceCorner, targetCorner } = getLineDirections(object);
        if (sourceCorner) { opt.sourceDirection = sourceCorner; }
        if (targetCorner) { opt.targetDirection = targetCorner; }

        const linkView = {
            sourceBBox: new Rect(source.x, source.y),
            targetBBox: new Rect(target.x, target.y)
        }

        let path = '';
        try {
            path = this.drawCurve(source, target, route, opt, linkView);
        } catch (err) {
            console.error('Error happened during generating a path.', err)
        }

        return path;
    }

    generatePathViaCatmullRom() {
        let pathString = '';
        const ctx = {
            save: () => {},
            restore: () => {},
            setLineDash: () => {},
            beginPath: () => {},
            moveTo: () => {},
            lineTo: () => {},
            stroke: () => {},
            bezierCurveTo: () => {},
        }
        const catmullRom = new CatmullRom(ctx, 0.5);
        catmullRom.lineStart();

        this.points.forEach(function (point) {
            catmullRom.point(point.x, point.y);
        });

        catmullRom.lineEnd();
        pathString = catmullRom.pathString;

        return pathString;
    }

    drawCurve(sourcePoint, targetPoint, route, opt, linkView) {
        if (route === void 0) route = [];
        if (opt === void 0) opt = {};

        let raw = Boolean(opt.raw);
        // distanceCoefficient - a coefficient of the tangent vector length relative to the distance between points.
        // angleTangentCoefficient - a coefficient of the end tangents length in the case of angles larger than 45 degrees.
        // tension - a Catmull-Rom curve tension parameter.
        // sourceTangent - a tangent vector along the curve at the sourcePoint.
        // sourceDirection - a unit direction vector along the curve at the sourcePoint.
        // targetTangent - a tangent vector along the curve at the targetPoint.
        // targetDirection - a unit direction vector along the curve at the targetPoint.
        // precision - a rounding precision for path values.
        let direction = opt.direction; if (direction === void 0) direction = this.Directions.AUTO;
        let precision = opt.precision; if (precision === void 0) precision = 3;
        let options = {
            coeff: opt.distanceCoefficient || 0.6,
            angleTangentCoefficient: opt.angleTangentCoefficient || 80,
            tau: opt.tension || 0.5,
            sourceTangent: opt.sourceTangent ? new Point(opt.sourceTangent) : null,
            targetTangent: opt.targetTangent ? new Point(opt.targetTangent) : null,
            rotate: Boolean(opt.rotate)
        };
        if (typeof opt.sourceDirection === 'string') { options.sourceDirection = opt.sourceDirection; }
        else if (typeof opt.sourceDirection === 'number') { options.sourceDirection = new Point(1, 0).rotate(null, opt.sourceDirection); }
        else { options.sourceDirection = opt.sourceDirection ? new Point(opt.sourceDirection).normalize() : null; }

        if (typeof opt.targetDirection === 'string') { options.targetDirection = opt.targetDirection; }
        else if (typeof opt.targetDirection === 'number') { options.targetDirection = new Point(1, 0).rotate(null, opt.targetDirection); }
        else { options.targetDirection = opt.targetDirection ? new Point(opt.targetDirection).normalize() : null; }

        let completeRoute = [sourcePoint].concat(route, [targetPoint]).map(function (p) { return new Point(p); });

        // The calculation of a sourceTangent
        let sourceTangent;
        if (options.sourceTangent) {
            sourceTangent = options.sourceTangent;
        } else {
            let sourceDirection = this.getSourceTangentDirection(linkView, completeRoute, direction, options);
            let tangentLength = completeRoute[0].distance(completeRoute[1]) * options.coeff;
            let pointsVector = completeRoute[1].difference(completeRoute[0]).normalize();
            let angle = this.angleBetweenVectors(sourceDirection, pointsVector);
            if (angle > Math.PI / 4) {
                let updatedLength = tangentLength + (angle - Math.PI / 4) * options.angleTangentCoefficient;
                sourceTangent = sourceDirection.clone().scale(updatedLength, updatedLength);
            } else {
                sourceTangent = sourceDirection.clone().scale(tangentLength, tangentLength);
            }
        }

        // The calculation of a targetTangent
        let targetTangent;
        if (options.targetTangent) {
            targetTangent = options.targetTangent;
        } else {
            let targetDirection = this.getTargetTangentDirection(linkView, completeRoute, direction, options);
            let last = completeRoute.length - 1;
            let tangentLength$1 = completeRoute[last - 1].distance(completeRoute[last]) * options.coeff;
            let pointsVector$1 = completeRoute[last - 1].difference(completeRoute[last]).normalize();
            let angle$1 = this.angleBetweenVectors(targetDirection, pointsVector$1);
            if (angle$1 > Math.PI / 4) {
                let updatedLength$1 = tangentLength$1 + (angle$1 - Math.PI / 4) * options.angleTangentCoefficient;
                targetTangent = targetDirection.clone().scale(updatedLength$1, updatedLength$1);
            } else {
                targetTangent = targetDirection.clone().scale(tangentLength$1, tangentLength$1);
            }
        }

        let catmullRomCurves = this.createCatmullRomCurves(completeRoute, sourceTangent, targetTangent, options);
        let bezierCurves = catmullRomCurves.map((curve) => {
            return this.catmullRomToBezier(curve, options);
        });

        let path = new Path(bezierCurves).round(precision);

        return (raw) ? path : path.serialize();
    }

    getHorizontalSourceDirection(linkView, route, options) {
        let sourceBBox = linkView.sourceBBox;

        let sourceSide;
        let rotation;

        if (sourceBBox.x > route[1].x) {
            sourceSide = 'right';
        } else {
            sourceSide = 'left';
        }

        let direction;
        switch (sourceSide) {
            case 'left':
                direction = new Point(-1, 0);
                break;
            case 'right':
            default:
                direction = new Point(1, 0);
                break;
        }

        if (options.rotate && rotation) {
            direction.rotate(null, -rotation);
        }

        return direction;
    }

    getHorizontalTargetDirection(linkView, route, options) {
        let targetBBox = linkView.targetBBox;

        let targetSide;
        let rotation;

        if (targetBBox.x > route[route.length - 2].x) {
            targetSide = 'left';
        } else {
            targetSide = 'right';
        }

        let direction;
        switch (targetSide) {
            case 'left':
                direction = new Point(-1, 0);
                break;
            case 'right':
            default:
                direction = new Point(1, 0);
                break;
        }

        if (options.rotate && rotation) {
            direction.rotate(null, -rotation);
        }

        return direction;
    }

    getVerticalSourceDirection(linkView, route, options) {
        let sourceBBox = linkView.sourceBBox;

        let sourceSide;
        let rotation;

        if (sourceBBox.y > route[1].y) {
            sourceSide = 'bottom';
        } else {
            sourceSide = 'top';
        }


        let direction;
        switch (sourceSide) {
            case 'top':
                direction = new Point(0, -1);
                break;
            case 'bottom':
            default:
                direction = new Point(0, 1);
                break;
        }

        if (options.rotate && rotation) {
            direction.rotate(null, -rotation);
        }

        return direction;
    }

    getVerticalTargetDirection(linkView, route, options) {
        let targetBBox = linkView.targetBBox;

        let targetSide;
        let rotation;
        if (targetBBox.y > route[route.length - 2].y) {
            targetSide = 'top';
        } else {
            targetSide = 'bottom';
        }


        let direction;
        switch (targetSide) {
            case 'top':
                direction = new Point(0, -1);
                break;
            case 'bottom':
            default:
                direction = new Point(0, 1);
                break;
        }

        if (options.rotate && rotation) {
            direction.rotate(null, -rotation);
        }

        return direction;
    }

    getAutoSourceDirection(linkView, route, options) {
        let sourceBBox = linkView.sourceBBox;

        let sourceSide;
        let rotation;

        sourceSide = sourceBBox.sideNearestToPoint(route[1]);

        let direction;
        switch (sourceSide) {
            case 'top':
                direction = new Point(0, -1);
                break;
            case 'bottom':
                direction = new Point(0, 1);
                break;
            case 'right':
                direction = new Point(1, 0);
                break;
            case 'left':
                direction = new Point(-1, 0);
                break;
        }

        if (options.rotate && rotation) {
            direction.rotate(null, -rotation);
        }

        return direction;
    }

    getAutoTargetDirection(linkView, route, options) {
        let targetBBox = linkView.targetBBox;

        let targetSide;
        let rotation;
        targetSide = targetBBox.sideNearestToPoint(route[route.length - 2]);


        let direction;
        switch (targetSide) {
            case 'top':
                direction = new Point(0, -1);
                break;
            case 'bottom':
                direction = new Point(0, 1);
                break;
            case 'right':
                direction = new Point(1, 0);
                break;
            case 'left':
                direction = new Point(-1, 0);
                break;
        }

        if (options.rotate && rotation) {
            direction.rotate(null, -rotation);
        }

        return direction;
    }

    getClosestPointSourceDirection(linkView, route) {
        return route[1].difference(route[0]).normalize();
    }

    getClosestPointTargetDirection(linkView, route) {
        let last = route.length - 1;
        return route[last - 1].difference(route[last]).normalize();
    }

    getOutwardsSourceDirection(linkView, route) {
        let sourceBBox = linkView.sourceBBox;
        let sourceCenter = sourceBBox.center();
        return route[0].difference(sourceCenter).normalize();
    }

    getOutwardsTargetDirection(linkView, route) {
        let targetBBox = linkView.targetBBox;
        let targetCenter = targetBBox.center();
        return route[route.length - 1].difference(targetCenter).normalize();
    }

    getSourceTangentDirection(linkView, route, direction, options) {
        if (options.sourceDirection) {
            switch (options.sourceDirection) {
                case this.TangentDirections.UP:
                    return new Point(0, -1);
                case this.TangentDirections.DOWN:
                    return new Point(0, 1);
                case this.TangentDirections.LEFT:
                    return new Point(-1, 0);
                case this.TangentDirections.RIGHT:
                    return new Point(1, 0);
                case this.TangentDirections.AUTO:
                    return this.getAutoSourceDirection(linkView, route, options);
                case this.TangentDirections.CLOSEST_POINT:
                    return this.getClosestPointSourceDirection(linkView, route, options);
                case this.TangentDirections.OUTWARDS:
                    return this.getOutwardsSourceDirection(linkView, route, options);
                default:
                    return options.sourceDirection;
            }
        }

        switch (direction) {
            case this.Directions.HORIZONTAL:
                return this.getHorizontalSourceDirection(linkView, route, options);
            case this.Directions.VERTICAL:
                return this.getVerticalSourceDirection(linkView, route, options);
            case this.Directions.CLOSEST_POINT:
                return this.getClosestPointSourceDirection(linkView, route, options);
            case this.Directions.OUTWARDS:
                return this.getOutwardsSourceDirection(linkView, route, options);
            case this.Directions.AUTO:
            default:
                return this.getAutoSourceDirection(linkView, route, options);
        }
    }

    getTargetTangentDirection(linkView, route, direction, options) {
        if (options.targetDirection) {
            switch (options.targetDirection) {
                case this.TangentDirections.UP:
                    return new Point(0, -1);
                case this.TangentDirections.DOWN:
                    return new Point(0, 1);
                case this.TangentDirections.LEFT:
                    return new Point(-1, 0);
                case this.TangentDirections.RIGHT:
                    return new Point(1, 0);
                case this.TangentDirections.AUTO:
                    return this.getAutoTargetDirection(linkView, route, options);
                case this.TangentDirections.CLOSEST_POINT:
                    return this.getClosestPointTargetDirection(linkView, route, options);
                case this.TangentDirections.OUTWARDS:
                    return this.getOutwardsTargetDirection(linkView, route, options);
                default:
                    return options.targetDirection;
            }
        }

        switch (direction) {
            case this.Directions.HORIZONTAL:
                return this.getHorizontalTargetDirection(linkView, route, options);
            case this.Directions.VERTICAL:
                return this.getVerticalTargetDirection(linkView, route, options);
            case this.Directions.CLOSEST_POINT:
                return this.getClosestPointTargetDirection(linkView, route, options);
            case this.Directions.OUTWARDS:
                return this.getOutwardsTargetDirection(linkView, route, options);
            case this.Directions.AUTO:
            default:
                return this.getAutoTargetDirection(linkView, route, options);
        }
    }

    rotateVector(vector, angle) {
        let cos = Math.cos(angle);
        let sin = Math.sin(angle);
        let x = cos * vector.x - sin * vector.y;
        let y = sin * vector.x + cos * vector.y;
        vector.x = x;
        vector.y = y;
    }

    angleBetweenVectors(v1, v2) {
        let cos = v1.dot(v2) / (v1.magnitude() * v2.magnitude());
        if (cos < -1) { cos = -1; }
        if (cos > 1) { cos = 1; }
        return Math.acos(cos);
    }

    determinant(v1, v2) {
        return v1.x * v2.y - v1.y * v2.x;
    }

    createCatmullRomCurves(points, sourceTangent, targetTangent, options) {
        let tau = options.tau;
        let coeff = options.coeff;
        let distances = [];
        let tangents = [];
        let catmullRomCurves = [];
        let n = points.length - 1;

        for (let i = 0; i < n; i++) {
            distances[i] = points[i].distance(points[i + 1]);
        }

        tangents[0] = sourceTangent;
        tangents[n] = targetTangent;

        // The calculation of tangents of vertices
        for (let i$1 = 1; i$1 < n; i$1++) {
            let tpPrev = (void 0);
            let tpNext = (void 0);
            if (i$1 === 1) {
                tpPrev = points[i$1 - 1].clone().offset(tangents[i$1 - 1].x, tangents[i$1 - 1].y);
            } else {
                tpPrev = points[i$1 - 1].clone();
            }
            if (i$1 === n - 1) {
                tpNext = points[i$1 + 1].clone().offset(tangents[i$1 + 1].x, tangents[i$1 + 1].y);
            } else {
                tpNext = points[i$1 + 1].clone();
            }
            let v1 = tpPrev.difference(points[i$1]).normalize();
            let v2 = tpNext.difference(points[i$1]).normalize();
            let vAngle = this.angleBetweenVectors(v1, v2);

            let rot = (Math.PI - vAngle) / 2;
            let t = (void 0);
            let vectorDeterminant = this.determinant(v1, v2);
            let pointsDeterminant = (void 0);
            pointsDeterminant = this.determinant(points[i$1].difference(points[i$1 + 1]), points[i$1].difference(points[i$1 - 1]));
            if (vectorDeterminant < 0) {
                rot = -rot;
            }
            if ((vAngle < Math.PI / 2) && ((rot < 0 && pointsDeterminant < 0) || (rot > 0 && pointsDeterminant > 0))) {
                rot = rot - Math.PI;
            }
            t = v2.clone();
            this.rotateVector(t, rot);

            let t1 = t.clone();
            let t2 = t.clone();
            let scaleFactor1 = distances[i$1 - 1] * coeff;
            let scaleFactor2 = distances[i$1] * coeff;
            t1.scale(scaleFactor1, scaleFactor1);
            t2.scale(scaleFactor2, scaleFactor2);

            tangents[i$1] = [t1, t2];
        }

        // The building of a Catmull-Rom curve based of tangents of points
        for (let i$2 = 0; i$2 < n; i$2++) {
            let p0 = (void 0);
            let p3 = (void 0);
            if (i$2 === 0) {
                p0 = points[i$2 + 1].difference(tangents[i$2].x / tau, tangents[i$2].y / tau);
            } else {
                p0 = points[i$2 + 1].difference(tangents[i$2][1].x / tau, tangents[i$2][1].y / tau);
            }
            if (i$2 === n - 1) {
                p3 = points[i$2].clone().offset(tangents[i$2 + 1].x / tau, tangents[i$2 + 1].y / tau);
            } else {
                p3 = points[i$2].difference(tangents[i$2 + 1][0].x / tau, tangents[i$2 + 1][0].y / tau);
            }

            catmullRomCurves[i$2] = [p0, points[i$2], points[i$2 + 1], p3];
        }
        return catmullRomCurves;
    }

    // The function to convert Catmull-Rom curve to Bezier curve using the tension (tau)
    catmullRomToBezier(points, options) {
        let tau = options.tau;

        let bcp1 = new Point();
        bcp1.x = points[1].x + (points[2].x - points[0].x) / (6 * tau);
        bcp1.y = points[1].y + (points[2].y - points[0].y) / (6 * tau);

        let bcp2 = new Point();
        bcp2.x = points[2].x + (points[3].x - points[1].x) / (6 * tau);
        bcp2.y = points[2].y + (points[3].y - points[1].y) / (6 * tau);

        return new Curve(
            points[1],
            bcp1,
            bcp2,
            points[2]
        );
    }
}