import { fabric } from 'fabric';
/**
 * @param point1
 * @param point2
 */
function _distanceBetweenPoints(point1, point2) {
    var dx = point2.x - point1.x;
    var dy = point2.y - point1.y;
    return Math.sqrt(dx * dx + dy * dy);
}
  
fabric.Object.prototype.transparentCorners = false;
  
const DashedBrush = fabric.util.createClass(fabric.PencilBrush, {
    initialize: function (canvas) {
        this.callSuper('initialize', canvas);
        this.type = 'dashed_brush';
        this.lastPointer = null; // init lastPointer
    },
  
    onMouseDown: function (pointer, options) {
        this.callSuper('onMouseDown', pointer, options);
        this.canvas.isDrawingMode = true;
        this.lastPointer = pointer;
        this.distanceAccumulated = 0; //init a value to accumulated the distance
    },
  
    onMouseMove: function (pointer, options) {
        if (!this.canvas.isDrawingMode) {
            return;
        }
  
        var ctx = this.canvas.contextTop;
        this.updateDashBrush(ctx);
  
        if (this.lastPointer) {
        // Calculate distance between segments based on actual length
            var distance = _distanceBetweenPoints(this.lastPointer, pointer);
            var dashLength = 10; //length of each dashed segment
            //ddd distance to accumulator
            this.distanceAccumulated += distance;
            // calculate xDelta và yDelta
            var xDelta = (pointer.x - this.lastPointer.x) / this.distanceAccumulated;
            var yDelta = (pointer.y - this.lastPointer.y) / this.distanceAccumulated;
            // When the total accumulated distance is enough to draw a dashed line, then draw
            while (this.distanceAccumulated >= dashLength) {
                this.lastPointer.x += xDelta * dashLength;
                this.lastPointer.y += yDelta * dashLength;
                this.callSuper('onMouseMove', this.lastPointer, options);
                this.distanceAccumulated -= dashLength;
            }
        }
  
        this.lastPointer = pointer; // update the position of the last pointer
    },
  
    onMouseUp: function (options) {
        this.canvas.isDrawingMode = false;
        this.callSuper('onMouseUp', options);
        this.lastPointer = null;
    },
  
    updateDashBrush: function (ctx) {
        ctx.setLineDash([5, 5]);
        ctx.lineDashOffset = 0;
        ctx.strokeStyle = this.color;
        ctx.lineWidth = this.width;
    },
});

export default DashedBrush;