import { fabric } from 'fabric';

/**
 * Update width and height of the canvas for cache
 * returns true or false if canvas needed resize.
 * @override
 * @returns {boolean} True if the canvas has been resized.
 * @private
 */
export default function _updateCacheCanvas() {
    let targetCanvas = this.canvas;
    if (this.noScaleCache && targetCanvas && targetCanvas._currentTransform) {
        let target = targetCanvas._currentTransform.target,
            action = targetCanvas._currentTransform.action;
        if (this === target && action.slice && action.slice(0, 5) === 'scale') {
            return false;
        }
    }
    let canvas = this._cacheCanvas,
        dims = this._limitCacheSize(this._getCacheCanvasDimensions()),
        minCacheSize = fabric.minCacheSideLimit,
        width = dims.width, height = dims.height, drawingWidth, drawingHeight,
        zoomX = dims.zoomX, zoomY = dims.zoomY,
        dimensionsChanged = width !== this.cacheWidth || height !== this.cacheHeight,
        zoomChanged = this.zoomX !== zoomX && this.zoomY !== zoomY,
        isCacheExpired = true,
        additionalWidth = 0, additionalHeight = 0, shouldResizeCanvas = false;

    // To avoid from many rendering; we check some rules both for zoom and dimensions changes.
    if (targetCanvas?.cacheOperation === 'ENABLED') {
        if (zoomChanged) {
            if (this.zoomX !== zoomX && Math.abs(this.zoomX - zoomX) > 0.1) {
                zoomChanged = true;
            } else if (Math.abs(this.zoomY - zoomY) > 0.1) {
                zoomChanged = true;
            } else {
                zoomChanged = false;
            }
        }

        if (dimensionsChanged) {
            if ((this.cacheWidth > width && this.cacheWidth % width > 50) || (this.cacheWidth < width && width % this.cacheWidth > 50)) {
                dimensionsChanged = true;
            } else if ((this.cacheHeight > height && this.cacheHeight % height > 50) || (this.cacheHeight < height && height % this.cacheHeight > 50)) {
                dimensionsChanged = true;
            } else if (!this.cacheWidth || !this.cacheHeight) {
                dimensionsChanged = true;   
            } else {
                dimensionsChanged = false;
            }
        }

        if (this._lastCachedTimestamp) {
            isCacheExpired = ((Date.now() - this._lastCachedTimestamp) / 1000) >= 0.1; // In Seconds.
        }
    }

    let shouldRedraw = (dimensionsChanged || zoomChanged) && isCacheExpired;
    if (dimensionsChanged) {
        let canvasWidth = this._cacheCanvas.width,
            canvasHeight = this._cacheCanvas.height,
            sizeGrowing = width > canvasWidth || height > canvasHeight,
            sizeShrinking = (width < canvasWidth * 0.9 || height < canvasHeight * 0.9) &&
                canvasWidth > minCacheSize && canvasHeight > minCacheSize;
        shouldResizeCanvas = sizeGrowing || sizeShrinking;
        if (sizeGrowing && !dims.capped && (width > minCacheSize || height > minCacheSize)) {
            additionalWidth = width * 0.1;
            additionalHeight = height * 0.1;
        }
    }
    if (this instanceof fabric.Text && this.path) {
        shouldRedraw = true;
        shouldResizeCanvas = true;
        additionalWidth += this.getHeightOfLine(0) * this.zoomX;
        additionalHeight += this.getHeightOfLine(0) * this.zoomY;
    }
    if (shouldRedraw) {
        if (shouldResizeCanvas) {
            canvas.width = Math.ceil(width + additionalWidth);
            canvas.height = Math.ceil(height + additionalHeight);
        }
        else {
            this._cacheContext.setTransform(1, 0, 0, 1, 0, 0);
            this._cacheContext.clearRect(0, 0, canvas.width, canvas.height);
        }
        drawingWidth = dims.x / 2;
        drawingHeight = dims.y / 2;
        this.cacheTranslationX = Math.round(canvas.width / 2 - drawingWidth) + drawingWidth;
        this.cacheTranslationY = Math.round(canvas.height / 2 - drawingHeight) + drawingHeight;
        this.cacheWidth = width;
        this.cacheHeight = height;
        this._cacheContext.translate(this.cacheTranslationX, this.cacheTranslationY);
        this._cacheContext.scale(zoomX, zoomY);
        this._lastCachedTimestamp = Date.now();
        this.zoomX = zoomX;
        this.zoomY = zoomY;
        return true;
    }
    return false;
}