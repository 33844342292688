import { getObjectCoordinatesByOrigin, isObjectValidForAttachingComment } from '../FabricMethods';

/**
 * Finds possible target for attachment for comments.
 * @param canvas
 * @param pointer
 * @param oldTarget
 * @returns 
 */
const findPossibleTarget = (canvas, pointer, oldTarget) => {
    const objects = canvas.getObjects();
    let i = 0,
        target;
    while (i<objects.length) {
        const obj = objects[i];
        i++;
        if (!isObjectValidForAttachingComment(obj) || obj.type === 'comment') { 
            continue
        }
        const attachedElCoordinates = getObjectCoordinatesByOrigin(obj);
       
        if (pointer.x >= attachedElCoordinates.tl.x && 
            pointer.x <= attachedElCoordinates.tr.x &&
            pointer.y >= attachedElCoordinates.tl.y &&
            pointer.y <= attachedElCoordinates.bl.y)  {
            target = obj;
        }
        else if (oldTarget) {
            oldTarget.set('opacity', 1);
        }
    }
    return target
}

export default findPossibleTarget;