import React from 'react';
import PropTypes from 'prop-types';
import './AuthBaseScreen.scss';
import NewLoginLogo from '../../assets/images/new_builder_logo_login.svg';

import { ToastContainer } from 'react-toastify';
import {useNavigate} from 'react-router-dom';
const AuthBaseScreen = ({ children, toastPosition = 'bottom-center', toastClass }) => {
    const navigate = useNavigate();
    return (
        <div className="authBaseScreen">
            <div className="leftSide-wrapper">
                <div className="leftSide"/>
            </div>
            <div className="rightSide">
                {children}
            </div>
            <div onClick={()=> navigate('/')}><img className='new-logo' src={NewLoginLogo} alt="new-logo"/></div>
            <ToastContainer className={toastClass} closeOnClick={false} position={toastPosition} />
        </div>
    );
};

AuthBaseScreen.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    toastPosition: PropTypes.oneOf([
        'top-right',
        'top-center',
        'top-left',
        'bottom-right',
        'bottom-center',
        'bottom-left'
    ]),
    toastClass: PropTypes.string
}

export default AuthBaseScreen;
