export class Indexer {
    constructor(fabricCanvas) {
        this.fabricCanvas = fabricCanvas;
        this.objectCount = 0;
    }
    
    objectAdded(object) {
        this.objectCount += 1;
        
        this.assignZIndex(object, this.objectCount)
    }
    
    objectRemoved() {
    }
    
    
    assignZIndex(object, index) {
        object.zIndex = index;
    }
    
    fullUpdate() {
        this.objectCount = 0;
        for (const object of this.fabricCanvas.getObjects()) {
            if (object.isDynamic) {
                continue;
            }
            this.objectCount += 1;
            object.zIndex = this.objectCount;
        }
    }
}