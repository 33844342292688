import React, { forwardRef, useLayoutEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types'
import { ReactCSS } from 'reactcss';
import useOutsideClick from '../../../hooks/UseOutsideClick2';
import { getRgbaVal } from '../../../helpers/toolbar/ActiveStyles';
import { SUBTOOLBAR_ITEMS, TOOLBAR_MENU } from '../../../helpers/Constant';
import TextHighlightIcon from '../../svgIcons/TextHighlightIcon';
import IconTextColor from '../../svgIcons/IconTextColor';
import ColorPicker from '../../colorPicker/ColorPicker';


const ColorPickerWrapper = forwardRef(({
    children,
    itemType
}, ref) => (
    <div className="colorOptionDDL__wrapper" ref={ref}>
        {itemType === SUBTOOLBAR_ITEMS.HIGHLIGHT_COLOR.id ? (
            <div className="highlightTextDDL">
                {children}
            </div>
        ) : children}
    </div>
));

ColorPickerWrapper.displayName = 'ColorPickerWrapper';
ColorPickerWrapper.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    itemType: PropTypes.oneOf([
        SUBTOOLBAR_ITEMS.TEXT_COLOR.id,
        SUBTOOLBAR_ITEMS.OUTER_COLOR.id,
        SUBTOOLBAR_ITEMS.LINE_TEXT_COLOR.id,
        SUBTOOLBAR_ITEMS.INNER_COLOR.id,
        SUBTOOLBAR_ITEMS.HIGHLIGHT_COLOR.id,
    ])
}

const ColorPickerItem = ({
    itemType,
    isDisabled,
    showMenu,
    toggleMenu,
    stateColor,
    setColor,
    targetType,
    isTargetLine,
    recentColors,
    updateRecentColor
}) => {
    const popoverRef = useRef();
    const tooltipRef = useRef();
    const colorPickerRef = useRef();
    const isColorSelectedRef = useRef(false);

    const menuOnClickHandler = (type) => {
        if (isDisabled) return;
        if (showMenu) {
            toggleMenu('CLOSE_ALL_COLOR_PICKERS');
        } else {
            toggleMenu(TOOLBAR_MENU.TOGGLE_COLOR_PICKER, { colorType: type });
        }
    }

    const onColorChanged = (color) => {
        setColor({ ...color, isColorSelected: isColorSelectedRef.current });
    }

    useLayoutEffect(() => {
        if (!showMenu) return;

        const selectorWrapper = popoverRef.current;
        const boundingRect = selectorWrapper.getBoundingClientRect();

        if (boundingRect.bottom + 10 > window.innerHeight) {
            selectorWrapper.style.bottom = `28px`;
            tooltipRef.current.classList.add('onBottom');
        } else {
            tooltipRef.current.classList.remove('onBottom');
        }

        selectorWrapper.style.visibility = 'visible';
    }, [showMenu]);

    useOutsideClick(colorPickerRef, () => {        
        if (showMenu) {
            toggleMenu('CLOSE_ALL_COLOR_PICKERS');
        }
    });

    const colorStyles = useMemo(() => {
        const styles = ReactCSS({
            'default': {
                textColor: {
                    backgroundColor: stateColor,
                },
                outerColor: {
                    background: targetType === 'textbox' ? stateColor : 'none',
                    borderColor: stateColor,
                }
            }
        });

        return styles;
    }, [stateColor])

    const defaults = useMemo(() => {
        const defs = {};

        if (itemType === SUBTOOLBAR_ITEMS.LINE_TEXT_COLOR.id) {
            defs.type = 'text';
            defs.color = stateColor ? getRgbaVal(stateColor) : getRgbaVal('rgba(0,0,0,1)');
            defs.colorStyles = colorStyles.textColor;
            defs.tooltipText = 'Text color';
        } else if (itemType === SUBTOOLBAR_ITEMS.TEXT_COLOR.id) {
            defs.type = 'text';
            defs.color = getRgbaVal(stateColor);
            defs.colorStyles = colorStyles.textColor;
            defs.tooltipText = 'Text color';
        } else if (itemType === SUBTOOLBAR_ITEMS.OUTER_COLOR.id) {
            defs.type = 'outer';
            defs.color = stateColor;
            defs.colorStyles = colorStyles.outerColor;
            defs.tooltipText = targetType === 'textbox' ? 'Text color' : 'Border color'
        } else if (itemType === SUBTOOLBAR_ITEMS.HIGHLIGHT_COLOR.id) {
            defs.type = 'highlightText';
            defs.color = stateColor ? getRgbaVal(stateColor) : getRgbaVal('rgba(255,255,255,1)');
            defs.colorStyles = colorStyles.textColor;
            defs.tooltipText = 'Highlight';
        } else if (itemType === SUBTOOLBAR_ITEMS.INNER_COLOR.id) {
            defs.type = 'inner';
            defs.color = stateColor;
            defs.colorStyles = colorStyles.textColor;
            defs.tooltipText = isTargetLine ? 'Line color' : 'Inner Color';
        }

        return defs;
    }, [itemType, stateColor, colorStyles, targetType, isTargetLine]);

    return (
        <ColorPickerWrapper itemType={itemType} ref={showMenu ? colorPickerRef : null}>
            <div
                className={clsx({
                    colorPickerButton: true,
                    active: showMenu,
                    disabled: isDisabled,
                    colorOptionDDL: [
                        SUBTOOLBAR_ITEMS.LINE_TEXT_COLOR.id,
                        SUBTOOLBAR_ITEMS.OUTER_COLOR.id,
                        SUBTOOLBAR_ITEMS.INNER_COLOR.id,
                        SUBTOOLBAR_ITEMS.TEXT_COLOR.id,
                    ].includes(itemType),
                    highlightTextDDL__button: itemType === SUBTOOLBAR_ITEMS.HIGHLIGHT_COLOR.id
                })}
                onClick={() => menuOnClickHandler(defaults.type)}
            >
                {(itemType === SUBTOOLBAR_ITEMS.TEXT_COLOR.id) ? (
                    <IconTextColor width={20} height={20} color={stateColor} />
                ) : null}

                {(itemType === SUBTOOLBAR_ITEMS.HIGHLIGHT_COLOR.id) ? (
                    <TextHighlightIcon color={defaults.color} />
                ) : null}

                {(itemType === SUBTOOLBAR_ITEMS.OUTER_COLOR.id) ? (
                    <div className="styled-field" style={defaults.colorStyles} />
                ) : null}

                {([SUBTOOLBAR_ITEMS.LINE_TEXT_COLOR.id, SUBTOOLBAR_ITEMS.INNER_COLOR.id].includes(itemType)) ? (
                    <div className="colorTransparentWrapper">
                        <div style={defaults.colorStyles} className="styled-field" />
                    </div>
                ) : null}

                {(itemType !== SUBTOOLBAR_ITEMS.HIGHLIGHT_COLOR.id) ? (
                    <em className="icon-arrow-down" />
                ) : null}

                <span className="tooltipText tooltipText--colorPicker" ref={tooltipRef}>{defaults.tooltipText}</span>
            </div>

            <div
                className="color-picker-popover"
                ref={popoverRef}
                style={{ position: 'absolute', zIndex: 2 }}
                hidden={!showMenu}
            >
                <ColorPicker
                    color={defaults.color}
                    onChange={onColorChanged}
                    recentColors={recentColors}
                    updateRecentColor={updateRecentColor}
                    isColorSelectedRef={isColorSelectedRef}
                />
            </div>
        </ColorPickerWrapper>
    )
};

ColorPickerItem.propTypes = {
    itemType: PropTypes.oneOf([
        SUBTOOLBAR_ITEMS.TEXT_COLOR.id,
        SUBTOOLBAR_ITEMS.OUTER_COLOR.id,
        SUBTOOLBAR_ITEMS.LINE_TEXT_COLOR.id,
        SUBTOOLBAR_ITEMS.INNER_COLOR.id,
        SUBTOOLBAR_ITEMS.HIGHLIGHT_COLOR.id,
    ]),
    isDisabled: PropTypes.bool,
    showMenu: PropTypes.bool,
    stateColor: PropTypes.string,
    toggleMenu: PropTypes.func.isRequired,
    setColor: PropTypes.func.isRequired,
    targetType: PropTypes.string,
    isTargetLine: PropTypes.bool,
    recentColors: PropTypes.arrayOf(PropTypes.string),
    updateRecentColor: PropTypes.func.isRequired
}

export default ColorPickerItem;