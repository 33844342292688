import { fabric } from 'fabric';
import { calculateObjectCenterPoint, getFabricObject, isTargetAttachableToLine } from '../FabricMethods';
import { changeLineHeadPos } from './LineControls';
import { LINE_ENDS_BY_POLYGON_SIDE, LINE_POLYGON_POINTS, LINE_POLYGON_PREFIXES, LINE_TYPES, MAGNET_CIRCLE_TYPE } from '../Constant';
import { euclideanDistance } from './LineUtils';
import { rotatePointBack } from './AttachedObjectTransformHandlers';
import ObjectStore from '../ObjectStore';

/**
 * @param line
 * @param element
 */
export function isLineAttachedToElement(line, element) {
    try {
        if (line.leftPolygon && line.leftPolygon?.uuid === element?.uuid) return true;
        if (line.rightPolygon && line.rightPolygon?.uuid === element?.uuid) return true;
        return false;
    } catch (error) {
        return false;
    }
}

/**
 * Returns the attached point of the line to the element .
 * @param {fabric.CurvedLine} line 
 * @param {fabric.Object} element 
 * @returns 
 */
export function getAttachedPointOfLine(line, element) {
    try {
        if (line.leftPolygon && line.leftPolygon?.uuid === element?.uuid) return 'start'
        if (line.rightPolygon && line.rightPolygon?.uuid === element?.uuid) return 'end'
        return false;
    } catch (error) {
        return false;
    }
}


/**
 * Sets the points of the line to the actual position of the line.
 * @param line
 */
export function setLinePointsForCurrentPosition(line) {
    for (const pointIndex in line.points) {
        const point = line.points[pointIndex];
        const actualPoint = {
            x: line.left + point.x - line.pathOffset.x,
            y: line.top + point.y - line.pathOffset.y
        }
        line.points[pointIndex] = {
            x: actualPoint.x,
            y: actualPoint.y
        }
    }
    line._setPositionDimensions({});
    line.setCoords();
}


/**
 * Checks if the point is inside the shape with padding.
 * @param shape
 * @param point
 * @param padding
 */
export const customContainPointsWidthPadding = (shape, point, padding = 10) => {
    // get bounding rect is better method to handle rotate objects
    const shapeCoordinates = shape.getBoundingRect(true, true);
    const objectPos = {
        xStart: shapeCoordinates.left,
        xEnd: shapeCoordinates.left + shapeCoordinates.width,
        yStart: shapeCoordinates.top,
        yEnd: shapeCoordinates.top + shapeCoordinates.height
    }
  
    if (
        (point.x >= objectPos.xStart - padding) && point.x <= (objectPos.xEnd + padding)) {
        if (point.y >= objectPos.yStart - padding && point.y <= objectPos.yEnd + padding) return true;
    }
  
    return false;
}


/**
 * @param line
 */
export function clearLineBindings(line) {
    line.leftPolygon = null;
    line.rightPolygon = null;
    line.leftDeltaX = null;
    line.leftDeltaY = null;
    line.rightDeltaX = null;
    line.rightDeltaY = null;
}


/**
 * @param canvas
 * @param duplicatedLine
 * @param originalLine
 * @param originalShapesUuidMap
 */
export function bindDuplicatedLine(canvas, duplicatedLine, originalLine, originalShapesUuidMap, duplicatedInstancesMap) {
    if (duplicatedLine.shapeType === 'curvedLine') {
        if (originalLine.leftPolygon && originalShapesUuidMap.has(originalLine.leftPolygon.uuid)) {
            let lines = [duplicatedLine.uuid];
            const canvasObject = duplicatedInstancesMap.get(originalShapesUuidMap.get(originalLine.leftPolygon.uuid))
            duplicatedLine.leftPolygon = canvasObject;
            duplicatedLine.leftDeltaX = originalLine.leftDeltaX;
            duplicatedLine.leftDeltaY = originalLine.leftDeltaY;
            
            if (Array.isArray(canvasObject.lines)) {
                lines = canvasObject.lines.filter(line => line !== duplicatedLine.uuid);
                lines.push(duplicatedLine.uuid);
            }
            canvasObject.lines = lines;
        }
        if (originalLine.rightPolygon && originalShapesUuidMap.has(originalLine.rightPolygon.uuid)) {
            const canvasObject = duplicatedInstancesMap.get(originalShapesUuidMap.get(originalLine.rightPolygon.uuid));
            duplicatedLine.rightPolygon = canvasObject;
            duplicatedLine.rightDeltaX = originalLine.rightDeltaX;
            duplicatedLine.rightDeltaY = originalLine.rightDeltaY;
            
            let lines = [duplicatedLine.uuid];
            if (Array.isArray(canvasObject.lines)) {
                lines = canvasObject.lines.filter(line => line !== duplicatedLine.uuid);
                lines.push(duplicatedLine.uuid);
            }
            canvasObject.lines = lines;
        }
    }
}

/**
 * Updates the line left and right point .
 * @param {fabric.Canvas} canvas 
 * @param {fabric.CurvedLine} line 
 */
export function updateLineLeftAndRightPoint (canvas, line) {
    let isUpdated = false;

    try {
        LINE_POLYGON_POINTS.forEach(side => {
            const polygonSide = line[`${side}Polygon`];
            if (polygonSide) {
                const polygonInCanvas = canvas.getObjects().find(o => o.uuid === polygonSide?.uuid);
                isUpdated = true;
                const groupCoordinates = calculateObjectCenterPoint(polygonInCanvas);
                changeLineHeadPos(
                    line,
                    LINE_ENDS_BY_POLYGON_SIDE[side],
                    {
                        x: (groupCoordinates.x + line[`${side}DeltaX`]),
                        y: (groupCoordinates.y + line[`${side}DeltaY`])
                    },
                );
            }
        });
    } catch (error) {
        console.error('error while updating line left and right point', error);
    }
    
    return isUpdated
}


/**
 * Generates a mock point for the line.
 * @param {number|string} pointIndex 
 * @param {fabric.CurvedLine} line - The line that the mock point will be added to.
 * @param {object} options - Position of the mock point.
 * @param {number} options.left 
 * @param {number} options.top 
 * @returns 
 */
export function generateMockPointForLine(pointIndex, line, options = {}) {
    const mockPoint = new fabric.Circle({
        radius: 0,
        fill: 'transparent',
        stroke: 'transparent',
        left: options.left,
        top: options.top,
        originX: 'center',
        originY: 'center',
    }); 
    mockPoint.mockPointIndex = pointIndex; 
    if (!line.mockPoints) {
        line.mockPoints = [];
    }
    line.mockPoints.push(mockPoint);
    return mockPoint;
}


/**
 * Handles the line attaching movement in active selection.
 * @param {fabric.CurvedLine} line 
 * @param {'left'|'right'} side 
 * @param {fabric.Canvas} canvas 
 * @param {fabric.ActiveSelection} activeSelection 
 */
export function handleLineMovementInActiveSelection(line, side, canvas, activeSelection) {
    try {
        const polygonSide = line[`${side}Polygon`];

        if (polygonSide) {
            const polygonObj = canvas.getObjects().find(el => el.uuid === polygonSide.uuid);
            if (!polygonObj?.group) {
                activeSelection.remove(line)
                if (!activeSelection.removedLinesDuringMoving) {
                    activeSelection.removedLinesDuringMoving = [];
                }
                activeSelection.removedLinesDuringMoving.push(line);
                const groupCoordinates = calculateObjectCenterPoint(polygonObj);
    
                changeLineHeadPos(
                    line,
                    LINE_ENDS_BY_POLYGON_SIDE[side],
                    {
                        x: (groupCoordinates.x + line[`${side}DeltaX`]),
                        y: (groupCoordinates.y + line[`${side}DeltaY`])
                    }
                )
            }
    
        }
    } catch (error) {
        console.error('error while handling line movement in active selection', error)
    }
}


/**
 * Generates a mock point for the line while scaling if the line has outside polygons.
 * @param {fabric.CurvedLine} line 
 * @param {string|number} pointIndex - Point index of the line.
 * @param {fabric.Canvas} canvas 
 * @param {string[]} otherObjectUuids - Uuids of the other objects in the selection.
 * @returns 
 */
export function handleGeneratingLineMockPointWhileScaling (line, pointIndex, canvas, otherObjectUuids) {
    try {
        const parsedPointIndex = parseInt(pointIndex);

        let side;
    
        if (parsedPointIndex === 0) side = 'left';
        if (parsedPointIndex === line?.points?.length - 1) side = 'right';
    
        if (!side) return false;
    
        const polygonSide = line[`${side}Polygon`];

        if (!polygonSide) return false;
        if (otherObjectUuids.includes(polygonSide?.uuid)) return false;

        const polygonInCanvas = canvas.getObjects().find(o => o.uuid === polygonSide?.uuid);
    
        if (!polygonInCanvas) return false;
    
        const centerPoint = polygonInCanvas.getCenterPoint();
        generateMockPointForLine(pointIndex, line, {
            left: centerPoint.x + line[LINE_POLYGON_PREFIXES[side].deltaX],
            top: centerPoint.y + line[LINE_POLYGON_PREFIXES[side].deltaY],
        })
        return true;
    } catch (error) {
        console.error('error while generating line mock point while scaling', error)
        return false;
    }

}

/**
 * Returns the point index of the line from the side.
 * @param {'left'|'right'} side 
 * @param {fabric.CurvedLine} line 
 * @returns 
 */
export function getLinePointIndexFromSide(side, line) {
    if (side === 'left') return 0;
    if (side === 'right') return line.points.length - 1;
    return -1;
}

/**
 * Calculates middle point of two curved lines.
 * @param {string} pathString 
 * @param {fabric.Object} object
 * @returns {{ newPoint: fabric.Point, length: Number }}
 */
export function calculateMiddlePointsOfCurvedLines(pathString, object) {
    const svgNS = 'http://www.w3.org/2000/svg';
    const path = document.createElementNS(svgNS, 'path');
    // Set the path data (d attribute)
    path.setAttribute('d', pathString);

    const length = path.getTotalLength();
    const middlePoint = path.getPointAtLength(length / 2);
    const newPoint = new fabric.Point(
        middlePoint.x + object.pathOffset.x,
        middlePoint.y + object.pathOffset.y
    );

    return { newPoint, length };
}

/**
 * 
 * @param {fabric.Object} line 
 * @returns {{ leftPolygon: object, rightPolygon: object }}
 */
export function getPolygonsData(line) {
    let data = {
        leftPolygon: { shape: null },
        rightPolygon: { shape: null }
    }

    let leftPolygon = line.leftPolygon, rightPolygon = line.rightPolygon;
    const leftPolygonUuid = line.leftPolygon ?  (typeof leftPolygon === 'string' ? leftPolygon  : leftPolygon.uuid) : null;
    const rightPolygonUuid = line.rightPolygon ? (typeof rightPolygon === 'string' ? rightPolygon  : rightPolygon.uuid) : null;

    // If canvas is not initalized; then get polygons' properties from object store.
    if (!line.canvas && ObjectStore.hasObject() && (typeof line.leftPolygon === 'string' || typeof line.rightPolygon === 'string')) {
        if (leftPolygonUuid) {
            leftPolygon = ObjectStore.getObject(leftPolygonUuid);
        }

        if (rightPolygonUuid) {
            rightPolygon = ObjectStore.getObject(rightPolygonUuid);
        }
    }

    const properties = ['angle', 'scaleX', 'scaleY', 'width', 'height', 'left', 'top'];

    // First set the initial values
    properties.forEach((prop) => {
        if (leftPolygon) { data.leftPolygon[prop] = leftPolygon[prop]; }
        if (rightPolygon) { data.rightPolygon[prop] = rightPolygon[prop]; }
    })

    // Important Note: We must set initial values above. Otherwise, arrow directions won't be calculted or calculated wrong.
    if (!line.canvas) { return data; }

    // Find the actived shapes
    const activedShapes = line.canvas.getObjects().filter((obj) => !!obj.connectorCircles);

    // If there are no activated shapes and if there are no leftPolygon and rightPolygon, return the default values.
    if (activedShapes.length === 0 && (!line.leftPolygon && !line.rightPolygon)) {
        return data;
    }

    leftPolygon = (line.canvas && line.leftPolygon) ? getFabricObject(line.canvas, 'uuid', leftPolygonUuid) : null;
    rightPolygon = (line.canvas && line.rightPolygon) ? getFabricObject(line.canvas, 'uuid', rightPolygonUuid) : null;

    // If there are some actived shapes, then we need to update left and right polygons.
    if (activedShapes.length > 0 && (line.movingPointIndex === 0 || line.movingPointIndex === line.points.length - 1)) {
        const isRightPolygon = line.movingPointIndex === line.points.length - 1;
        const headPoint = isRightPolygon ? line.points[line.points.length - 1] : line.points[0];

        // If there is only one actived shape then no need to iterate all objects in order to find magner circles from the canvas.
        if (activedShapes.length === 1) {
            if (isRightPolygon) {
                rightPolygon = activedShapes[0];
            } else {
                leftPolygon = activedShapes[0];
            }
        } else { // If there are more than one actived shapes, need to iterate all magnet circles and find the connected one.
            // !NOTE: Some of the below codes are commented for now. Will update it soon.
            const magnetCircles = line.canvas.getObjects().filter((p) => p.shapeType === MAGNET_CIRCLE_TYPE);
            if (magnetCircles.length > 0) {
                // const activatedCircleShapes = line.canvas.getObjects().filter((obj) => !!(line.activedCirclesOfShapes.get(obj.uuid)));
    
                const connectedCircle = magnetCircles.find((circle) => {
                    const isFullyMatched = circle.left === headPoint.x && circle.top === headPoint.y;
                    if (isFullyMatched) { return true; }

                    // const obj = activatedCircleShapes.find((o) => o.uuid === circle.connectedShapeUuid);
                    // if (!obj) { return false; }
    
                    // const isPartiallyMatched = isPointerContainedWithObject(obj, headPoint) && (Math.abs(circle.left - headPoint.x) <= 5 || Math.abs(circle.top - headPoint.y) <= 5);
                    return false;
                });

                if (connectedCircle) {
                    const polygon = getFabricObject(line.canvas, 'uuid', connectedCircle.connectedShapeUuid);
        
                    if (isRightPolygon) {
                        rightPolygon = polygon
                    } else {
                        leftPolygon = polygon;
                    }
                }
            }
        }
    }

    // This case is prepared for the lines which created from left panel. When starting to the drawing from the shape coordinates, leftPolygon didn't set initally and movingPoint is the last point. So need to cover this scenario as well.
    if (line.isDrawingStop === false && line.movingPointIndex === line.points.length - 1 && leftPolygon === null) {
        const attachableObjects = line.canvas.getObjects().filter((obj) => isTargetAttachableToLine(obj));
        const polygon = attachableObjects.find((obj) => customContainPointsWidthPadding(obj, line.points[0]));

        if (polygon) {
            leftPolygon = polygon;
        }
    }

    if (leftPolygon) {
        data.leftPolygon.shape = leftPolygon;

        // Override the default values
        properties.forEach((prop) => {
            data.leftPolygon[prop] = leftPolygon[prop];
        })
    }

    if (rightPolygon) {
        data.rightPolygon.shape = rightPolygon;

        // Override the default values
        properties.forEach((prop) => {
            data.rightPolygon[prop] = rightPolygon[prop];
        });
    }

    return data;
}

/**
 * Calculating delta points of lines.
 * !Important Note: Its rounding the calculated points in order to avoid from floating point precision.
 * @param {fabric.Object} line
 * @param {boolean} returnInitialDuringMoving
 * @returns {{ leftDeltaX: number, leftDeltaY: number, leftAngle: number, rightDeltaX: number, rightDeltaY: number, rightAngle: number }}
 */
export function calculateDeltaPoints(line, returnInitialDuringMoving = false) {
    const headPoints = line?.canvas ? line.getHeadPoints() : {};
    let leftDeltaX = line.leftDeltaX;
    let leftDeltaY = line.leftDeltaY;
    let rightDeltaX = line.rightDeltaX;
    let rightDeltaY =  line.rightDeltaY;

    if (returnInitialDuringMoving) {
        return { leftDeltaX, leftDeltaY, rightDeltaX, rightDeltaY }
    }

    const { leftPolygon, rightPolygon } = getPolygonsData(line);

    if (leftPolygon.shape) {
        const centerPoints = leftPolygon.shape.getCenterPoint();
        const activeObject = line.canvas.getActiveObject();

        if (activeObject?.type === 'activeSelection') {
            const matrix = leftPolygon.shape.calcTransformMatrix();
            const coords = fabric.util.qrDecompose(matrix);

            centerPoints.x = coords.translateX;
            centerPoints.y = coords.translateY;
        }

        leftDeltaX = Math.round(headPoints[0].x) - Math.round(centerPoints.x);
        leftDeltaY = Math.round(headPoints[0].y) - Math.round(centerPoints.y);
    }

    if (rightPolygon.shape) {
        const centerPoints = rightPolygon.shape.getCenterPoint();
        const activeObject = line.canvas.getActiveObject();

        if (activeObject?.type === 'activeSelection') {
            const matrix = rightPolygon.shape.calcTransformMatrix();
            const coords = fabric.util.qrDecompose(matrix);

            centerPoints.x = coords.translateX;
            centerPoints.y = coords.translateY;
        }

        rightDeltaX = Math.round(headPoints[1].x) - Math.round(centerPoints.x);
        rightDeltaY = Math.round(headPoints[1].y) - Math.round(centerPoints.y);
    }

    return {
        leftDeltaX,
        leftDeltaY,
        rightDeltaX,
        rightDeltaY,
    }
}

/**
 * 
 * @param {{ line: fabric.Object, polygon: fabric.Object, delta: { x: number, y: number }}} param0 
 * @returns 
 */
const calculateDirectionOfLineForPolygon = ({
    // line,
    polygon,
    delta
}) => {
    const detachDistance = 150;

    let corner = null;
    const sWidth = polygon?.width * polygon?.scaleX;
    const sHeight = polygon?.height * polygon?.scaleY;
    
    // Adding minor threshold in order to adjust proper alignment
    let widthTreshold = 5;
    let heightTreshold = 5;

    if (
        Math.abs(delta.x) < sWidth / 2 + detachDistance &&
        Math.abs(delta.y) < sHeight / 2 + detachDistance
    ) {
        // Shape Corner Points
        const originPoints = {
            left: { x: polygon.left - (sWidth / 2), y: polygon.top },
            right: { x: polygon.left + (sWidth / 2), y: polygon.top },
            top: { x: polygon.left, y: polygon.top - (sHeight / 2) },
            bottom: { x: polygon.left, y: polygon.top + (sHeight / 2) }
        }

        // Arrow Point
        let arrowHeadPoint = {
            x: polygon.left + delta.x,
            y: polygon.top + delta.y
        }

        // If shape has some angle then rotate the arrow head point to find actual corner.
        if (polygon.angle !== 0) {
            arrowHeadPoint = rotatePointBack(arrowHeadPoint.x, arrowHeadPoint.y, polygon.left, polygon.top, polygon.angle);
        }

        // Find the corner
        if (arrowHeadPoint.y <= originPoints.top.y + heightTreshold) {
            corner = 'up';
        } else if (arrowHeadPoint.y >= originPoints.bottom.y - heightTreshold) {
            corner = 'down';
        } else if (arrowHeadPoint.x <= originPoints.left.x + widthTreshold) {
            corner = 'left';
        } else if (arrowHeadPoint.x >= originPoints.right.x - widthTreshold) {
            corner = 'right'
        } else {
            const distanceLocations = { 0: 'left', 1: 'right', 2: 'up', 3: 'down' };

            const distances = [
                euclideanDistance(arrowHeadPoint, originPoints.left),
                euclideanDistance(arrowHeadPoint, originPoints.right),
                euclideanDistance(arrowHeadPoint, originPoints.top),
                euclideanDistance(arrowHeadPoint, originPoints.bottom),
            ];

            const minDistanceIdx = distances.indexOf(Math.min(...distances));
            corner = distanceLocations[minDistanceIdx];
        }

        // Find the actual corner
        if (polygon.angle !== 0) {
            // Angle always should be higher than zero
            const angle = (polygon.angle < 0) ? 360 - polygon.angle : polygon.angle;
            const corners = ['left', 'up', 'right', 'down']; // Don't change the order of corners.
            const currentPosition = corners.findIndex((o) => o === corner);

            const count = Math.floor((angle + 45) / 90);
            const turn = count % corners.length;
          
            corner = corners[(currentPosition + turn) % corners.length];
        }
    }

    return corner;
}

/**
 * Returning the line directions for seamless alignment.
 * @param {fabric.Object} line Fabric.js Object.
 * @returns {{ sourceCorner: 'left' | 'right' | 'up' | 'down', targetCorner: 'left' | 'right' | 'up' | 'down' }} SourceCorner -> Left Polygon & rightCorner -> Right Polygon.
 */
export function getLineDirections(line) {
    const {
        leftDeltaX,
        leftDeltaY,
        rightDeltaX,
        rightDeltaY
    } = calculateDeltaPoints(line, line.isMoving);

    let leftPolygonCorner = null;
    let rightPolygonCorner = null;

    const { leftPolygon, rightPolygon } = getPolygonsData(line);

    if (leftPolygon) {
        leftPolygonCorner = calculateDirectionOfLineForPolygon({
            line,
            polygon: leftPolygon,
            delta: { x: leftDeltaX, y: leftDeltaY },
        });
    }

    if (rightPolygon) {
        rightPolygonCorner = calculateDirectionOfLineForPolygon({
            line,
            polygon: rightPolygon,
            delta: { x: rightDeltaX, y: rightDeltaY },
            pos: 'right'
        });
    }

    return { sourceCorner: leftPolygonCorner, targetCorner: rightPolygonCorner };
}

/**
 * Checking that is the line is curved line or not
 * @param {fabric.Object} line 
 * @returns {boolean}
 */
export const isCurvedLine = (line) => {
    if (!line) { return false; }
    if (line.lineType !== LINE_TYPES.CURVED) { return false; }
    if (line.curvedLineVersion !== 'v2' && line.points.length < 3) {
        return false;
    }

    return true;
}

/**
 * Checking that is the line is curved line or not
 * @param {fabric.Object} line 
 * @returns {boolean}
 */
export const isCurvedLineDrawingAsStraight = (line) => {
    if (
        line &&
        line.lineType === LINE_TYPES.CURVED &&
        line.curvedLineVersion !== 'v2' &&
        line.points.length < 3
    ) {
        return true;
    }

    return false;
}

/**
 * Sometimes delta values calculated wrong inside of connectToGroup function in useLine file due to floating point precision error.
 * @param {fabric.Object} line 
 * @returns {boolean}
 */
export const isLineDeltaCalculatedWrong = (line) => {
    const isDeltaCalculatedWrong = (
        (line?.leftDeltaX && line.leftDeltaX.toString().indexOf('e-') > -1)
        || (line?.rightDeltaX && line.rightDeltaX.toString().indexOf('e-') > -1)
    );

    return isDeltaCalculatedWrong;
}

/**
 * Converting curved line version to v2
 * @param {[fabric.Object]} lines 
 */
export const convertCurvedLineVersionToV2 = (lines) => {
    for (const line of lines) {
        if (line.curvedLineVersion !== 'v2') {
            line.onShapeChanged();
            line.set({ curvedLineVersion: 'v2' });
            line._setPositionDimensions({});
            line.organizeControls();
            line.setCoords();
        }
    }
}


/**
 * Gets the given polygon side's uuid.
 * @param {object|fabric.Object} line The line object or JSON representation of the line.
 * @param {'left'|'right'} side The polygon side of the line.
 * @returns {string} The uuid of the given polygon side.
 */
export function getPolygonUuid(line, side) {
    const polygon = line[`${side}Polygon`];
    if (typeof polygon === 'string') {
        return polygon;
    }
    return polygon?.uuid;
}

export function attachLinesInUuidList(object, canvas) {
    for (const line of object.lines) {
        const lineInstance = canvas.objectsMap.get(line);
        if (lineInstance) {
            lineInstance.attachPolygonFromUuid(object)

            if (lineInstance.curvedLineVersion === 'v2') {
                lineInstance._setPositionDimensions({});
                lineInstance.setCoords();
            }
        }
    }
}

export function attachPolygonsWithCanvasMap(line, canvas) {
    const leftPolygonUuid = getPolygonUuid(line, 'left');
    const rightPolygonUud = getPolygonUuid(line, 'right');
    
    const addToLinesArray = (polygon) => {
        if (!polygon) {
            return
        }
        if (Array.isArray(polygon?.lines)) {
            if (!polygon?.lines?.includes(line?.uuid)) {
                polygon.lines.push(line.uuid)
            }
        } else {
            polygon.lines = [line.uuid]
        } 
    }
    
    if (line.leftPolygon && canvas.objectsMap.has(leftPolygonUuid)) {
        const polygon = canvas.objectsMap.get(leftPolygonUuid);
        line.leftPolygon = polygon;
        addToLinesArray(polygon) 
    }
    if (line.rightPolygon && canvas.objectsMap.has(rightPolygonUud)) {
        const polygon = canvas.objectsMap.get(rightPolygonUud);
        line.rightPolygon = polygon
        addToLinesArray(polygon)
    }

    if (line.curvedLineVersion === 'v2') {
        line._setPositionDimensions({});
        line.setCoords();
    }
}