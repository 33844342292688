import { fabric } from 'fabric';

export const Rhombus = fabric.util.createClass(fabric.Object, {
    type: 'rhombus',

    initialize: function (options) {
        options = options || {};
        this.callSuper('initialize', options);
        this.set({
            width: options.width || 100,
            height: options.height || 100,
            fill: options.fill || 'transparent',
            strokeWidth: options.strokeWidth || 2,
            stroke: options.stroke || 'black',
            strokeDashArray: options.strokeDashArray || [],
        });
    },

    _render: function (ctx) {
        const scaleX = this.getObjectScaling().scaleX;
        const scaleY = this.getObjectScaling().scaleY;
        const scale = scaleX === scaleY ? scaleX : Math.min(scaleX, scaleY);
        ctx.beginPath();
        ctx.moveTo(0, -this.height / 2);
        ctx.lineTo(this.width / 2, 0);
        ctx.lineTo(0, this.height / 2);
        ctx.lineTo(-this.width / 2, 0);
        ctx.closePath();

        ctx.fillStyle = this.fill;
        ctx.fill();
        ctx.strokeStyle = this.stroke;
        ctx.lineWidth = this.strokeWidth / scale;
        ctx.setLineDash(this.strokeDashArray);
        ctx.stroke();
        this.callSuper('_render', ctx);
    },
});

export const rhombusFromObject = (object, callback) => {
    return fabric.Object._fromObject('Rhombus', object, callback);
};