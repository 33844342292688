export const isDarwin = /Mac|iPod|iPhone|iPad/.test(window.navigator.platform);

export const isWindows = /^Win/.test(window.navigator.platform);

export const KEYS = {
    CTRL_OR_CMD: isDarwin ? 'metaKey' : 'ctrlKey',
    SHIFT: 'shiftKey',
    ENTER:'Enter',
    TAB: 'Tab',
    A: ['a', 'A'],
    B: ['b', 'B'],
    C: ['c', 'C'],
    D: ['d', 'D'],
    E: ['e', 'E'],
    F: ['f', 'F'],
    G: ['g', 'G'],
    H: ['h', 'H'],
    I: ['i', 'I'],
    L: ['l', 'L'],
    O: ['o', 'O'],
    P: ['p', 'P'],
    Q: ['q', 'Q'],
    R: ['r', 'R'],
    S: ['s', 'S'],
    T: ['t', 'T'],
    U: ['u', 'U'],
    V: ['v', 'V'],
    X: ['x', 'X'],
    Y: ['y', 'Y'],
    Z: ['z', 'Z'],
    K: ['k', 'K'],
    N: ['n', 'N'],
    M: ['m', 'M'],
    ArrowUp: 'ArrowUp',
    ArrowDown: 'ArrowDown',
    ArrowRight: 'ArrowRight',
    ArrowLeft: 'ArrowLeft'
};

export const usedControlKeys = [
    'a',
    'b',
    'c',
    'd',
    'u',
    'i',
    'v',
    'x',
    'z',
    'y',
    '1',
    's',
    'A',
    'B',
    'C',
    'D',
    'U',
    'I',
    'V',
    'X',
    'Z',
    'Y',
    'S',
]

const textModificationKeys = [
    ...KEYS.B,
    ...KEYS.I,
    ...KEYS.U
]

/**
 * Checks if the action is for text modification.
 * @param event
 */
export const isActionForTextModification = (event) => {
    return textModificationKeys.includes(event.key) && event[KEYS.CTRL_OR_CMD]
}
