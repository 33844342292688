import {contains, intersects} from '../../viewPort/Viewport';


export class r {
    constructor(maxEntries = 9) {
        this._maxEntries = Math.max(4, maxEntries);
        this._minEntries = Math.max(2, Math.ceil(0.4 * this._maxEntries));
        this.clear();
    }
    all() {
        return this._all(this.data, [])
    }

    search(searchBounds) {
        let currentNode = this.data;
        let results = [];

        if (!spatialIntersects(searchBounds, currentNode)) {
            return results;
        }

        let toBBox = this.toBBox;
        let nodeStack = [];

        while (currentNode) {
            for (let i = 0; i < currentNode.children.length; i++) {
                let child = currentNode.children[i];
                let childBounds = currentNode.leaf ? toBBox(child) : child;
                if (spatialIntersects(searchBounds, childBounds)) {
                    if (currentNode.leaf) {
                        results.push(child);
                    } else if (spatialContained(searchBounds, childBounds)) {
                        this._all(child, results);
                    } else {
                        nodeStack.push(child);
                    }
                }
            }
            currentNode = nodeStack.pop();
        }
        return results;
    }

    collides(searchBounds) {
        let currentNode = this.data;

        if (!spatialIntersects(searchBounds, currentNode)) {
            return false;  // No intersection with the root node, return false immediately.
        }

        let stack = [];

        while (currentNode) {
            for (let i = 0; i < currentNode.children.length; i++) {
                let child = currentNode.children[i];
                let childBounds = currentNode.leaf ? this.toBBox(child) : child;
                if (spatialIntersects(searchBounds, childBounds)) {
                    if (currentNode.leaf || spatialContained(searchBounds, childBounds)) {
                        return true;  // Returns true immediately if a collision is detected.
                    }
                    stack.push(child);  // Push child to stack to check its children later.
                }
            }
            currentNode = stack.pop();  // Move to the next node to be processed.
        }

        return false;  // No collisions were found after checking all relevant nodes.
    }

    load(items) {
        if (!items || !items.length) {
            return this;
        }

        if (items.length < this._minEntries) {
            items.forEach(item => this.insert(item));
            return this;
        }

        let builtTree = this._build(items.slice(), 0, items.length - 1, 0);

        if (this.data.children.length) {
            if (this.data.height === builtTree.height) {
                this._splitRoot(this.data, builtTree);
            } else {
                if (this.data.height < builtTree.height) {
                    let temp = this.data;
                    this.data = builtTree;
                    builtTree = temp;
                }
                this._insert(builtTree, this.data.height - builtTree.height - 1, true);
            }
        } else {
            this.data = builtTree;
        }

        return this;
    }

    insert(item) {
        if (item) {
            this._insert(item, this.data.height - 1);
        }
        return this;
    }

    clear() {
        this.data = createEmptyNode([]);
        return this;
    }
    remove(e, t) {
        if (!e)
            return this;
        for (var r, n, i, o = this.data, s = this.toBBox(e), a = [], u = []; o || a.length; ) {
            if (o || (o = a.pop(),
            n = a[a.length - 1],
            r = u.pop(),
            i = !0),
            o.leaf) {
                var c = function(e, t, r) {
                    if (!r)
                        return t.indexOf(e);
                    for (var n = 0; n < t.length; n++)
                        if (r(e, t[n]))
                            return n;
                    return -1
                }(e, o.children, t);
                if (-1 !== c)
                    return o.children.splice(c, 1),
                    a.push(o),
                    this._condense(a),
                    this
            }
            i || o.leaf || !spatialContained(o, s) ? n ? (r++,
            o = n.children[r],
            i = !1) : o = null : (a.push(o),
            u.push(r),
            r = 0,
            n = o,
            o = o.children[0])
        }
        return this
    }

    findIndex(item, children, equalsFunc) {
        if (!equalsFunc) {
            return children.indexOf(item);
        }
        for (let i = 0; i < children.length; i++) {
            if (equalsFunc(item, children[i])) {
                return i;
            }
        }
        return -1;
    }

    toBBox(bbox) {
        return bbox;
    }

    compareMinX(a, b) {
        return a.minX - b.minX;
    }

    compareMinY(a, b) {
        return a.minY - b.minY;
    }

    toJSON() {
        return this.data;
    }

    fromJSON(jsonData) {
        this.data = jsonData;
        return this;
    }

    _all(currentNode, results) {
        let stack = [];

        while (currentNode) {
            if (currentNode.leaf) {
                results.push(...currentNode.children);
            } else {
                stack.push(...currentNode.children);
            }
            currentNode = stack.pop();
        }

        return results;
    }
    _build(elements, startIdx, endIdx, depth) {
        let length = endIdx - startIdx + 1;
        let maxEntries = this._maxEntries;

        if (length <= maxEntries) {
            let node = createEmptyNode(elements.slice(startIdx, endIdx + 1));
            calculateBoundsForNode(node, this.toBBox);
            return node;
        }

        if (!depth) {
            depth = Math.ceil(Math.log(length) / Math.log(maxEntries));
            maxEntries = Math.ceil(length / Math.pow(maxEntries, depth - 1));
        }

        let node = createEmptyNode([]);
        node.leaf = false;
        node.height = depth;

        let entriesPerGroup = Math.ceil(length / maxEntries);
        let sliceSize = entriesPerGroup * Math.ceil(Math.sqrt(maxEntries));

        partition(elements, startIdx, endIdx, sliceSize, this.compareMinX);

        for (let i = startIdx; i <= endIdx; i += sliceSize) {
            let upperBound = Math.min(i + sliceSize - 1, endIdx);
            partition(elements, i, upperBound, entriesPerGroup, this.compareMinY);

            for (let j = i; j <= upperBound; j += entriesPerGroup) {
                let groupEnd = Math.min(j + entriesPerGroup - 1, upperBound);
                node.children.push(this._build(elements, j, groupEnd, depth - 1));
            }
        }

        calculateBoundsForNode(node, this.toBBox);
        return node;
    }
    _chooseSubtree(boundingBox, node, targetLevel, path) {
        while (path.push(node), !node.leaf && path.length - 1 !== targetLevel) {
            let leastCost = Infinity;
            let bestArea = Infinity;
            let chosenNode = undefined;

            for (let i = 0; i < node.children.length; i++) {
                let child = node.children[i];
                let area = getArea(child);
                let cost = (Math.max(child.maxX, boundingBox.maxX) - Math.min(child.minX, boundingBox.minX)) *
                    (Math.max(child.maxY, boundingBox.maxY) - Math.min(child.minY, boundingBox.minY)) - area;

                if (cost < leastCost) {
                    leastCost = cost;
                    bestArea = area < bestArea ? area : bestArea;
                    chosenNode = child;
                } else if (cost === leastCost && area < bestArea) {
                    bestArea = area;
                    chosenNode = child;
                }
            }
            node = chosenNode || node.children[0];
        }
        return node;
    }
    _insert(item, level, isNode) {
        let bbox = isNode ? item : this.toBBox(item);
        let path = [];
        let subtree = this._chooseSubtree(bbox, this.data, level, path);

        subtree.children.push(item);
        updateBounds(subtree, bbox);

        while (level >= 0 && path[level].children.length > this._maxEntries) {
            this._split(path, level);
            level--;
        }

        this._adjustParentBBoxes(bbox, path, level);
    }

    _split(path, index) {
        let targetNode = path[index];
        let numChildren = targetNode.children.length;
        let minEntries = this._minEntries;

        this._chooseSplitAxis(targetNode, minEntries, numChildren);

        let splitIndex = this._chooseSplitIndex(targetNode, minEntries, numChildren);
        let newNode = createEmptyNode(targetNode.children.splice(splitIndex, numChildren - splitIndex));

        newNode.height = targetNode.height;
        newNode.leaf = targetNode.leaf;

        calculateBoundsForNode(targetNode, this.toBBox);
        calculateBoundsForNode(newNode, this.toBBox);

        if (index) {
            path[index - 1].children.push(newNode);
        } else {
            this._splitRoot(targetNode, newNode);
        }
    }
    _splitRoot(leftNode, rightNode) {
        this.data = createEmptyNode([leftNode, rightNode]);
        this.data.height = leftNode.height + 1;
        this.data.leaf = false;
        calculateBoundsForNode(this.data, this.toBBox);
    }

    _chooseSplitIndex(node, minEntries, maxEntries) {
        let bestIndex = null;
        let minOverlap = Infinity;
        let minArea = Infinity;

        for (let i = minEntries; i <= maxEntries - minEntries; i++) {
            let firstHalf = updateBoundingBoxForRange(node, 0, i, this.toBBox);
            let secondHalf = updateBoundingBoxForRange(node, i, maxEntries, this.toBBox);

            let overlap = Math.max(0, Math.min(firstHalf.maxX, secondHalf.maxX) - Math.max(firstHalf.minX, secondHalf.minX)) *
                Math.max(0, Math.min(firstHalf.maxY, secondHalf.maxY) - Math.max(firstHalf.minY, secondHalf.minY));

            let totalArea = getArea(firstHalf) + getArea(secondHalf);

            if (overlap < minOverlap) {
                minOverlap = overlap;
                bestIndex = i;
                minArea = totalArea;
            } else if (overlap === minOverlap && totalArea < minArea) {
                minArea = totalArea;
                bestIndex = i;
            }
        }

        return bestIndex || maxEntries - minEntries;
    }
    _chooseSplitAxis(node, minEntries, maxEntries) {
        let compareX = node.leaf ? this.compareMinX : compareMinXNonLeaf;
        let compareY = node.leaf ? this.compareMinY : compareMinYNonLeaf;

        if (this._allDistMargin(node, minEntries, maxEntries, compareX) < this._allDistMargin(node, minEntries, maxEntries, compareY)) {
            node.children.sort(compareX);
        }
    }

    _allDistMargin(node, minEntries, maxEntries, comparator) {
        node.children.sort(comparator);

        let toBBox = this.toBBox;
        let firstHalfBBox = updateBoundingBoxForRange(node, 0, minEntries, toBBox);
        let secondHalfBBox = updateBoundingBoxForRange(node, maxEntries - minEntries, maxEntries, toBBox);
        let totalMargin = calculatePerimeter(firstHalfBBox) + calculatePerimeter(secondHalfBBox);

        for (let i = minEntries; i < maxEntries - minEntries; i++) {
            let child = node.children[i];
            updateBounds(firstHalfBBox, node.leaf ? toBBox(child) : child);
            totalMargin += calculatePerimeter(firstHalfBBox);
        }

        for (let i = maxEntries - minEntries - 1; i >= minEntries; i--) {
            let child = node.children[i];
            updateBounds(secondHalfBBox, node.leaf ? toBBox(child) : child);
            totalMargin += calculatePerimeter(secondHalfBBox);
        }

        return totalMargin;
    }

    _adjustParentBBoxes(bbox, path, level) {
        for (let i = level; i >= 0; i--) {
            updateBounds(path[i], bbox);
        }
    }
    _condense(path) {
        for (let i = path.length - 1; i >= 0; i--) {
            if (path[i].children.length === 0) {
                if (i > 0) {
                    let siblings = path[i - 1].children;
                    siblings.splice(siblings.indexOf(path[i]), 1);
                } else {
                    this.clear();
                }
            } else {
                calculateBoundsForNode(path[i], this.toBBox);
            }
        }
    }
}
function calculateBoundsForNode(node, toBBox) {
    updateBoundingBoxForRange(node, 0, node.children.length, toBBox, node);
}
function updateBoundingBoxForRange(node, start, end, toBBox, bbox) {
    bbox = bbox || createEmptyNode(null);
    bbox.minX = Infinity;
    bbox.minY = Infinity;
    bbox.maxX = -Infinity;
    bbox.maxY = -Infinity;

    for (let i = start; i < end; i++) {
        let child = node.children[i];
        updateBounds(bbox, node.leaf ? toBBox(child) : child);
    }
    return bbox;
}

function updateBounds(bbox, bounds) {
    bbox.minX = Math.min(bbox.minX, bounds.minX);
    bbox.minY = Math.min(bbox.minY, bounds.minY);
    bbox.maxX = Math.max(bbox.maxX, bounds.maxX);
    bbox.maxY = Math.max(bbox.maxY, bounds.maxY);
    return bbox;
}

function compareMinXNonLeaf(a, b) {
    return a.minX - b.minX;
}

function compareMinYNonLeaf(a, b) {
    return a.minY - b.minY;
}

function getArea(rect) {
    return (rect.maxX - rect.minX) * (rect.maxY - rect.minY);
}

function calculatePerimeter(e) {
    return e.maxX - e.minX + (e.maxY - e.minY)
}

function spatialContained(a, b) {
    return a.minX <= b.minX && a.minY <= b.minY && b.maxX <= a.maxX && b.maxY <= a.maxY;
}

function spatialIntersects(a, b) {
    return b.minX <= a.maxX && b.minY <= a.maxY && b.maxX >= a.minX && b.maxY >= a.minY;
}

function createEmptyNode(e) {
    return {
        children: e,
        height: 1,
        leaf: !0,
        minX: 1 / 0,
        minY: 1 / 0,
        maxX: -1 / 0,
        maxY: -1 / 0
    }
}
function partition(array, start, end, threshold, compare) {
    let indexStack = [start, end];

    while (indexStack.length) {
        end = indexStack.pop();
        start = indexStack.pop();

        if ((end - start) > threshold) {
            let mid = start + Math.ceil((end - start) / threshold / 2) * threshold;

            (function recursiveSort(array, pivotIndex, low, high, compare) {
                while (high > low) {
                    if (high - low > 600) {
                        let range = high - low + 1;
                        let offset = pivotIndex - low + 1;
                        let logRange = Math.log(range);
                        let estimatedPivot = 0.5 * Math.exp(2 * logRange / 3);
                        let stdDeviation = 0.5 * Math.sqrt(logRange * estimatedPivot * (range - estimatedPivot) / range) * (offset - range / 2 < 0 ? -1 : 1);
                        let newLow = Math.max(low, Math.floor(pivotIndex - offset * estimatedPivot / range + stdDeviation));
                        let newHigh = Math.min(high, Math.floor(pivotIndex + (range - offset) * estimatedPivot / range + stdDeviation));

                        recursiveSort(array, pivotIndex, newLow, newHigh, compare);
                    }

                    let pivotValue = array[pivotIndex];
                    let left = low;
                    let right = high;

                    swap(array, low, pivotIndex);
                    if (compare(array[high], pivotValue) > 0) {
                        swap(array, low, high);
                    }

                    while (left < right) {
                        swap(array, left, right);
                        left++;
                        right--;

                        while (compare(array[left], pivotValue) < 0) {
                            left++;
                        }
                        while (compare(array[right], pivotValue) > 0) {
                            right--;
                        }
                    }

                    if (compare(array[low], pivotValue) === 0) {
                        swap(array, low, right);
                    } else {
                        swap(array, right + 1, high);
                    }

                    if (right <= pivotIndex) {
                        low = right + 1;
                    }
                    if (pivotIndex <= right) {
                        high = right - 1;
                    }
                }
            })(array, mid, start || 0, end || array.length - 1, compare || defaultComparator);

            indexStack.push(start, mid, mid, end);
        }
    }
}

function swap(array, i, j) {
    let temp = array[i];
    array[i] = array[j];
    array[j] = temp;
}

function defaultComparator(a, b) {
    return a < b ? -1 : (a > b ? 1 : 0);
}


export class RTree extends r {
    toBBox(bbox) {
        return bbox.bbox;
    }
    compareMinX(a, b) {
        return a.bbox.minX - b.bbox.minX;
    }
    compareMinY(a, b) {
        return a.bbox.minY - b.bbox.minY;
    }
    query(searchBounds, callback) {
        let node = this.data, results = [];
        if (!intersects(searchBounds, node))
            return results;
        let stack = [];
        while (node) {
            for (let i = 0, len = node.children.length; i < len; i++) {
                let child = node.children[i];
                if (node.leaf) {
                    if (intersects(searchBounds, child.bbox) && (!callback || callback(child.item, child.bbox) !== false)) {
                        results.push(child.item);
                    }
                } else {
                    if (intersects(searchBounds, child)) {
                        if (contains(searchBounds, child)) {
                            this.allFiltered(child, callback, results);
                        } else {
                            stack.push(child);
                        }
                    }
                }
            }
            node = stack.pop();
        }
        return results;
    }

    visit(searchBounds, visitor) {
        let node = this.data;
        if (!intersects(searchBounds, node))
            return;
        let stack = [];
        while (node) {
            for (let i = 0, len = node.children.length; i < len; i++) {
                let child = node.children[i];
                if (node.leaf) {
                    if (intersects(searchBounds, child.bbox)) {
                        visitor(child.item, child.bbox);
                    }
                } else {
                    if (intersects(searchBounds, child)) {
                        if (contains(searchBounds, child)) {
                            this.allVisitor(child, visitor);
                        } else {
                            stack.push(child);
                        }
                    }
                }
            }
            node = stack.pop();
        }
    }

    allFiltered(node, callback, results) {
        let current = node;
        let stack = [];
        while (current) {
            if (current.leaf) {
                for (let i = 0, len = current.children.length; i < len; i++) {
                    let child = current.children[i];
                    if (!callback || callback(child.item, child.bbox) !== false) {
                        results.push(child.item);
                    }
                }
            } else {
                stack.push(...current.children);
            }
            current = stack.pop();
        }
        return results;
    }
    allVisitor(node, visitor) {
        let current = node;
        let stack = [];
        while (current) {
            if (current.leaf) {
                for (let i = 0, len = current.children.length; i < len; i++) {
                    let child = current.children[i];
                    visitor(child.item, child.bbox);
                }
            } else {
                stack.push(...current.children);
            }
            current = stack.pop();
        }
    }
}