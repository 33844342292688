import React from 'react';
import logoGoogle from '../../assets/images/logo_google.svg';
import PropTypes from 'prop-types';
import WavingHand from '../../assets/images/waving-hand.svg'
import PartyPopper from '../../assets/images/party-popper.svg'

function LoginSignupHeader({onClick, page}) {
    return (
        <div className={`${page} auth-header`}>
            <h3 className='welcome-text'>{page === 'login' ? 'Welcome back ' : 'Let’s get started! '} <img src={page === 'login' ? WavingHand : PartyPopper} alt="emoji"/> </h3>
            <button type="button" className="socialLogins" onClick={onClick}>
                <img alt="Logo" className="icon-google" src={logoGoogle}/>
                <span className="sign-in-google">{page === 'login' ? 'Log in' : 'Sign up'} with Google</span>
            </button>
            <div className="or-container">
                <span className="or-text">or</span>
            </div>
        </div>
    );
}

LoginSignupHeader.propTypes = {
    onClick: PropTypes.func,
    page: PropTypes.oneOf(['login', 'signup']),
}

export default LoginSignupHeader;