import {fabric} from 'fabric';

export default function _searchPossibleTargets(objects, pointer) {
    // Cache all targets where their bounding box contains point.
    var target, i = objects.length, subTarget;
    // Do not check for currently grouped objects, since we check the parent group itself.
    // until we call this function specifically to search inside the activeGroup
    while (i--) {
        var objToCheck = objects[i];
        if (objToCheck.collabLocked) {
            continue;
        }
        var pointerToUse = objToCheck.group ?
            this._normalizePointer(objToCheck.group, pointer) : pointer;
        if (this._checkTarget(pointerToUse, objToCheck, pointer)) {
            target = objects[i];
            if (target.subTargetCheck && target instanceof fabric.Group) {
                subTarget = this._searchPossibleTargets(target._objects, pointer);
                subTarget && this.targets.push(subTarget);
            }
            break;
        }
    }
    return target;
}