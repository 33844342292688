import { useReducer } from 'react';
import PropTypes from 'prop-types';
import { CanvasesContext, CanvasesDispatchContext } from './CanvasesContext';
import { CANVASES_CTX_ACTIONS } from '../../helpers/Constant';


const initialCanvasesState = {
    canvases: {},
}
const canvasesReducer = (state, action) => {
    const { type, payload } = action;
    if (type === CANVASES_CTX_ACTIONS.SET_CANVAS) {
        return {
            ...state,
            canvases: {
                ...state.canvases,
                [payload.pageId]: payload.canvas
            }
        }
    } else if (type === CANVASES_CTX_ACTIONS.RESET_CANVASES) {
        return {
            ...state,
            canvases: {}
        }
    }
    return state;
}


/**
 * @param {object} props
 * @param props.children
 */
const CanvasesProvider = ({ children }) => {
    const [canvases, dispatch] = useReducer(canvasesReducer, initialCanvasesState);

    return (
        <CanvasesContext.Provider value={canvases}>
            <CanvasesDispatchContext.Provider value={dispatch}>
                {children}
            </CanvasesDispatchContext.Provider>
        </CanvasesContext.Provider>
    )
}

CanvasesProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
}

export default CanvasesProvider;