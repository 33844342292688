class PromiseQueue {
    constructor(maxConcurrent) {
        this.maxConcurrent = maxConcurrent;
        this.currentDownloads = 0;
        this.downloadCounter = 0;
        this.queue = [];
        this.pending = new Set();
    }

    addToQueue(task) {
        this.queue.push(task);
        if (this.downloadCounter === 0) {
            this.processQueue();
        }
    }

    startCounter(counter) {
        this.downloadCounter = counter;
    }

    decreaseCounter() {
        this.downloadCounter = this.downloadCounter - 1;
    }

    processQueue() {
        while (this.currentDownloads < this.maxConcurrent && this.queue.length > 0) {
            const task = this.queue.shift();
            this.currentDownloads++;
            this.pending.add(task);
            task().finally(() => {
                this.currentDownloads--;
                this.pending.delete(task);
                this.processQueue();
            });
        }
    }
}

const promiseQueue = new PromiseQueue(5);

export default promiseQueue
