import './LoadingSpinner.scss'
export default function LoadingSpinner() {
    return (
        <div className="loadingSpinner primary">
            <div className="lds-ring" style={{  }}>
                <div className="lds-ring-bg"></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}