/**
 *
 */
const ChevronLeftIcon = () => {
    return (
        <svg
            fill="none"
            height="10"
            viewBox="0 0 6 10"
            width="6"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.666 8.667L1.4 5.173a.253.253 0 01-.067-.172c0-.065.024-.127.067-.173l3.266-3.494"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </svg>
    );
}

export default ChevronLeftIcon;