import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { TEXT_SIZE_OPTIONS } from '../../../helpers/Constant';
import './NumberInput.scss';



/**
 * Number input component that is being used by Subtoolbar to change text size for the lines .
 * @param {object} Options 
 * @param {number} Options.defaultValue - Default value of the input.
 * @param {Function} Options.handleChange - Callback function that will be called when the value is changed.
 * @param {boolean} [Options.disabled] - If true, the component will be disabled. False by default.
 * @param Options.maximumSize
 */
const NumberInput = ({
    defaultValue,
    handleChange,
    disabled = false,
    maximumSize = 1000,
}) => {

    const dropdownRef = useRef(null);
    const textValues = TEXT_SIZE_OPTIONS;
    const [value, setValue] = useState(0);
    const [showDropdown, setShowDropdown] = useState(false);

    const [typing, setTyping] = useState(false);
    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue,]);

    useEffect(() => {

        if (dropdownRef.current && showDropdown) {
            const selectedOptionIndex = textValues.indexOf(value);
            if (selectedOptionIndex !== -1) {
                const optionElements = dropdownRef.current.querySelectorAll('.custom-option');
                if (optionElements[selectedOptionIndex]) {
                    optionElements[selectedOptionIndex].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                }
            }
        }
    }, [showDropdown, value]);

    const isIndexValid = index => {
        if (index < 0) return false;
        return textValues.at(index) ? true : false
    }
    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    }
    
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if(typing){
                changeTheTextSizeBaseOnInputValue();
                setTyping(false);
            }
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [value, typing]); //

    const changeTheTextSizeBaseOnInputValue = ()=>{
        if (value < 10 || value.length === 0) {
            handleChange(10);
            return;
        }
        if (value > 80) {
            handleChange(80);
            return;
        }
        handleChange(value);
    }
    /**
     * @param arr
     * @param target
     */
    function findNearestValuesIndex(arr, target) {
        let lowerIndex = -1;
        let higherIndex = -1;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] < target) {
                lowerIndex = i;
            } else if (arr[i] > target) {
                higherIndex = i;
                break;
            }
        }

        return { lowerIndex, higherIndex };
    }
    const onChangeText = evt => {
        const { value: newValue } = evt.target;
        const re = /^[0-9\b]+$/;
        // if value is not blank, then test the regex
        if (re.test(newValue) || newValue.length == 0) {
            let newTextSize = newValue;
            if (newTextSize.length > 2) newTextSize = 80;
            if (newTextSize > maximumSize) newTextSize = maximumSize;
            setValue(newTextSize);
            setTyping(true);
        }
    };
    const handleDropdownChange = (e)=>{
        if(e > maximumSize) return;
        setValue(e);
        handleChange(e);
    }
    
    const changeValue = (action) => {
        if (disabled) return;
        const indexFound = textValues.indexOf(value);
        let nextIndex;
        if (indexFound !== -1) {
            if (action === 'increase') {
                nextIndex = indexFound + 1;
            } else if (action === 'decrease') {
                nextIndex = indexFound - 1;
            }
        } else {
            const { lowerIndex, higherIndex } = findNearestValuesIndex(textValues, value);
            if (action === 'increase') {
                nextIndex = higherIndex;
            } else if (action === 'decrease') {
                nextIndex = lowerIndex;
            }
        }
        if (isIndexValid(nextIndex)) {
            const newTextSize = textValues.at(nextIndex);
            if(newTextSize > maximumSize) return;
            setValue(oldValue => {
                const isChangeSuccess = handleChange(newTextSize);
                if (isChangeSuccess)
                    return oldValue !== newTextSize ? newTextSize : oldValue;
                return oldValue;
            });
        }
    }

    return (
        <div className={clsx('numberInput', { disabled })}>
            <input
                disabled = {disabled}
                maxLength="2"
                onChange={onChangeText} 
                onClick={toggleDropdown}
                value={value}
            />
            {showDropdown ? <div className="custom-dropdown" ref={dropdownRef}>
                {textValues.map((option, index) => (
                    <div
                        className={clsx('custom-option', { 'selected': value === option, 'disabled': option > maximumSize })}
                        key={index}
                        onClick={() => handleDropdownChange(option)}
                    >
                        {option}
                    </div>
                ))}
            </div> : null}
            <div className="numberInput__buttons">
                <button type="button" className="numberInput__btn btn_sizeUp" onClick={() => changeValue('increase')}>
                    <svg aria-hidden="true" focusable="false" role="presentation" viewBox="0 0 10 5">
                        <path d="M5 0l5 5H0z" fill="currentColor" xmlns="http://www.w3.org/2000/svg" />
                    </svg>
                </button>
                <button type="button" className="numberInput__btn btn_sizeDown" onClick={() => changeValue('decrease')}>
                    <svg aria-hidden="true" focusable="false" role="presentation" viewBox="0 0 10 5">
                        <path d="M5 5l5-5H0z" fill="currentColor" xmlns="http://www.w3.org/2000/svg" />
                    </svg>
                </button>
            </div>
        </div>
    )
}

NumberInput.propTypes = {
    defaultValue: PropTypes.number,
    handleChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    maximumSize: PropTypes.number,
}

export default NumberInput;