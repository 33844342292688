import { isObjectFlowchartFrame } from '../flowchart/CustomFlowchartShapeUtils';

/**
 * @param {'created'|'deleted'|'duplicated'|'edited'} actionTaken 
 * @returns 'Added'|'Deleted'|'Duplicated'|'Edited'.
 */
const getActionName = (actionTaken) => {
    if (actionTaken === 'created') {
        return 'Added';
    } else if (actionTaken === 'deleted') {
        return 'Deleted';
    } else if (actionTaken === 'duplicated') {
        return 'Duplicated';
    }
    return 'Edited';
}

/**
 * Transform itemType and actionTaken to readable string.
 * @param itemType
 * @param actionTaken
 * @param properties
 */
const transformShapeAndActionStr = (itemType, actionTaken, properties) => {
    // log for importing third party board
    if (itemType === 'nima' && actionTaken === 'import') {
        return 'Copied data from older whiteboard'
    }
    
    // log for duplicated whiteboard
    if (actionTaken === 'whiteboardDuplicate') {
        return `Duplicated whiteboard`
    }
    
    let shape = 'shape';  // for rect, triangle and circle etc
    let action = getActionName(actionTaken);
    let isFlowchartFrame = properties?.flowchartProps && isObjectFlowchartFrame(properties);

    if (itemType === 'text' || itemType === 'textbox') {
        shape = 'text';
    } else if (itemType === 'sticky') {
        shape = 'sticky note';
    } else if (itemType === 'image' || itemType === 'optimizedImage')  {
        shape = 'image';
    } else if (itemType === 'path') {
        shape = 'drawing';
    } else if (itemType === 'frame') {
        shape = 'frame';
    } else if (itemType === 'table') {
        shape = 'table';
    } else if(itemType === 'curvedLine' && properties && properties.arrowEnabled){
        shape = 'line';
        if (properties.arrowEnabled) shape = 'arrow';
    }

    if (isFlowchartFrame) {
        return `Flowchart ${action}`;
    }

    return `${action} ${shape}`;
}

export default transformShapeAndActionStr;