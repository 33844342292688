import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { LEFT_DRAWER_TABS } from '../../helpers/Constant';
import ActivityLogTab from './activityLogTab/ActivityLogTab';
import FramesTab from './framesTab/FramesTab';
import ErrorBoundary from '../ErrorBoundary';
import LeftDrawerErrorFallBack from './LeftDrawerErrorFallback';

import './LeftDrawer.scss';
import { isUserHasAccessToFeature } from '../../helpers/CommonFunctions';

const TAB_LIST = [
    {
        id: LEFT_DRAWER_TABS.FRAMES,
        icon: 'icon-colors-square',
        title: 'Frames'
    },
    {
        id: LEFT_DRAWER_TABS.ACTIVITIES,
        icon: 'icon-activity-log',
        title: 'Activity log'
    }
]

const LeftDrawer = ({ 
    opened,
    currentUserId, 
    users, 
    closeDrawer, 
    canvas,
    whiteBoardId,
    socketRef,
    userAccess,
    emitData
}) => {
    const leftDrawerRef = useRef();
    const activeTab = useSelector(state => state.leftDrawer.activeTab);
    const dispatch = useDispatch();
    
    useEffect(() => {
        if (opened) {
            leftDrawerRef.current.classList.add('active');
        } else {
            leftDrawerRef.current.classList.remove('active');
        }
    }, [opened]);

    return (
        <div className="leftDrawer" ref={leftDrawerRef}>
            <ErrorBoundary fallback={<LeftDrawerErrorFallBack />}>
                <div className="leftDrawer__content">
                    {
                        activeTab === LEFT_DRAWER_TABS.FRAMES ? <FramesTab 
                            canvas={canvas}
                            closeDrawer={closeDrawer}
                            userAccess={userAccess}
                        /> : null
                    }
                    {
                        activeTab === LEFT_DRAWER_TABS.ACTIVITIES ? <ActivityLogTab 
                            canvas={canvas}
                            closeDrawer={closeDrawer}
                            currentUserId={currentUserId}
                            socketRef={socketRef}
                            userAccess={userAccess}
                            users={users}
                            whiteBoardId={whiteBoardId}
                            emitData={emitData}
                        /> : null
                    }
                </div>
            </ErrorBoundary>
            <div className="leftDrawer__nav">
                { TAB_LIST.map(tab => (
                    <button
                        type="button"
                        className={clsx('leftDrawer__nav--btn', { 'active': activeTab === tab.id })}
                        data-ignoreeventkeys
                        disabled={
                            (tab.id === LEFT_DRAWER_TABS.COMMENTS && !isUserHasAccessToFeature('comments', userAccess))
                            || (tab.id === LEFT_DRAWER_TABS.ACTIVITIES && !isUserHasAccessToFeature('activity_log', userAccess))
                            || (tab.id === LEFT_DRAWER_TABS.FRAMES && !isUserHasAccessToFeature('frames_tab', userAccess))
                        }
                        key={tab.id}
                        onClick={() => dispatch({
                            type: 'leftDrawer/setActiveTab',
                            payload: tab.id
                        })}
                    >
                        <em className={tab.icon} />
                        {tab.title}
                    </button>
                )) }
            </div>
        </div>
    )
}

LeftDrawer.propTypes = {
    opened: PropTypes.bool,
    currentUserId: PropTypes.number,
    users: PropTypes.arrayOf(PropTypes.shape({
        commentEmail: PropTypes.string,
        commentUsername: PropTypes.string,
        email: PropTypes.string,
        id: PropTypes.number,
        name: PropTypes.string,
        permission: PropTypes.oneOf(['view', 'comment', 'edit'])
    })),
    closeDrawer: PropTypes.func,
    canvas: PropTypes.object,
    whiteBoardId: PropTypes.number,
    socketRef: PropTypes.shape({
        current: PropTypes.object
    }),
    userAccess: PropTypes.oneOf(['view', 'comment', 'edit', 'removeAccess', 'NOT_ALLOWED']),
    emitData: PropTypes.func
}

export default LeftDrawer;