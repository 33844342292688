import React, { useEffect, useRef, useMemo, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import AlertCircle from '../../svgIcons/AlertCircle';
import LoaderGif from '../../../assets/images/loader_logo.gif';
import { emailCharactersValidation } from '../../../helpers/Validation';
import useOnClickOutside from '../../../hooks/UseOnClickOutside';

import AutoCompleteOption from './AutoCompleteOption';
import './AutocompleteSelectInput.scss';

const AutocompleteSelectInput = ({
    name,
    type,
    label,
    placeHolder,
    required,
    testId,
    errorMessage,
    isLoading,
    data,
    query = '',
    onInputChange,
    onOptionClick,
}) => {
    const inputRef = useRef(null);
    const ulRef = useRef(null);
    const [suggestionIndex, setSuggestionIndex] = useState(0);
    const [showOptions, setShowOptions] = useState(false);

    const scrollToActive = () => {
        const active = ulRef?.current?.querySelector('.active');

        if (active)
            active.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
    };

    const options = useMemo(
        () =>
            data.map((option, index) => {
                setTimeout(() => {
                    scrollToActive();
                }, [100]);

                return (
                    <AutoCompleteOption
                        active={index === suggestionIndex}
                        handleClick={(value) => {
                            onInputChange(value);
                            setShowOptions(false);
                            onOptionClick(value);
                        }}
                        handleHover={() => setSuggestionIndex(index)}
                        key={`option_${option.value}`}
                        option={option}
                    />
                );
            }),
        [data, suggestionIndex, onInputChange, onOptionClick]
    );

    /**
     * Handle cases when there is no error.
     */
    const content = () => {
        if (isLoading)
            return (
                <li className="option-loader" role="status">
                    <img alt="Loading..." src={LoaderGif} />
                </li>
            );
        if (options.length > 0) return options;
        else return <li className="option-no-suggestions">No suggestions</li>;
    };

    const handleChange = (value) => {
        let newValue = value.replace(/\s{2,}/, ' ');

        if (newValue?.split('@')?.length === 2) {
            const [localPart, domainPart] = newValue.split('@');
            newValue = `${localPart}@${domainPart.toLocaleLowerCase()}`;
        }

        onInputChange(newValue);
        setSuggestionIndex(0);

        if (newValue.length > 2 && emailCharactersValidation(newValue)) {
            setShowOptions(true);
        } else {
            setShowOptions(false);
        }
    };

    /**
     * Handle keydown events on the suggestions lists.
     * @param {{number}} keyCode - The code of the key that triggered the event.
     */
    const handleKeyDown = ({ keyCode }) => {
        if (keyCode === 38) {
            if (suggestionIndex === 0) return;
            setSuggestionIndex(suggestionIndex - 1);
        } else if (keyCode === 40) {
            if (suggestionIndex - 1 === options.length) return;
            setSuggestionIndex(suggestionIndex + 1);
        } else if (keyCode === 13 && !!data[suggestionIndex]) {
            onInputChange(data[suggestionIndex].value);
            setSuggestionIndex(0);
            setShowOptions(false);
            onOptionClick();
        } else if (keyCode === 9) {
            onInputChange(data[0].value);
            setShowOptions(false);
            onOptionClick();
        }
    };

    useOnClickOutside(ulRef, () => {
        setShowOptions(false);
    });

    /**
     * When the component's errorMessage changes and it is a message,
     * close the suggestions' list.
     */
    useEffect(() => {
        if (errorMessage) {
            setShowOptions(false);
        }
    }, [errorMessage]);

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    return (
        <div className={clsx('autoCompleteSelectInput', { error: errorMessage })}>
            <div className="inputWrapper">
                {label ? <label htmlFor={name}>{label}</label> : null}
                <input
                    autoComplete={type}
                    data-testid={testId}
                    name={name}
                    onChange={({ target: { value } }) => {
                        if (!/[^\s]/.test(value[0])) return;
                        handleChange(value);
                    }}
                    onFocus={() => {
                        if (query.length > 2 && !errorMessage) setShowOptions(true);
                    }}
                    onKeyDown={handleKeyDown}
                    placeholder={placeHolder}
                    ref={inputRef}
                    required={required}
                    type={type}
                    value={query}
                />
            </div>

            {/**
              * When there is an error, show the error message,.
              */}
            {errorMessage ? <div className="autoCompleteSelectInput-error">
                <AlertCircle height={10} width={10} />
                <span>{errorMessage}</span>
            </div> : null}
            {/**
              *  In either case (as it is intended for) show the suggestions list.
              */}
            {showOptions && !errorMessage ? <ul
                aria-label="autocomplete-suggestions"
                className="active"
                ref={ulRef}
            >
                {content()}
            </ul> : null}
        </div>
    );
};

AutocompleteSelectInput.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeHolder: PropTypes.string,
    required: PropTypes.bool,
    testId: PropTypes.string,
    errorMessage: PropTypes.string,
    isLoading: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.shape({
        avatar: PropTypes.string,
        label: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node,
            PropTypes.string,
            PropTypes.number
        ]),
        value: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node,
            PropTypes.string,
            PropTypes.number
        ])
    })),
    query: PropTypes.string,
    onInputChange: PropTypes.func,
    onOptionClick: PropTypes.func,
    type: PropTypes.oneOf([
        'button',
        'checkbox',
        'color',
        'date',
        'datetime-local',
        'email',
        'file',
        'hidden',
        'image',
        'month',
        'number',
        'password',
        'radio',
        'range',
        'reset',
        'search',
        'submit',
        'tel',
        'text',
        'time',
        'url',
        'week',
    ])  
}

AutocompleteSelectInput.defaultProps = {
    label: null,
    required: false,
    errorMessage: undefined    
}

export default React.memo(AutocompleteSelectInput);
