
/**
 * Remove cacheCanvas and its dimensions from the objects.
 * @override
 */
export default function _removeCacheCanvas() {
    this._cacheCanvas = null;
    this._cacheContext = null;
    this.cacheWidth = 0;
    this.cacheHeight = 0;
    this._lastCachedTimestamp = null;
}