import { fabric } from 'fabric';
import { Comment } from '../../hooks/UseComment';
import { getObjectCoordinatesByOrigin } from '../FabricMethods';
import findPossibleTarget from './FindPossibleTarget';
import getCommentIcon, { extendedColorOptions } from './GetCommentIcon';
import { attachCommentToTarget, detachCommentFromTarget, handleAttachComment } from './HandleAttachComments';

/**
 * @param {fabric.Canvas} canvas
 */
const commentMoveHandler = (canvas) => e => {
    const { transform: { target: comment } } = e;
    comment.isMoved = true;
    let nearestTarget = findPossibleTarget(canvas, e.pointer, comment.tempAttachedEl);
    if (nearestTarget) nearestTarget.set('opacity', .6);
    comment.tempAttachedEl = nearestTarget;
    canvas.fire('comment-moving', e);
}

/**
 * @param {Function} options.setSelectedCommentIcon - Updates the comment icon.
 * @param setSelectedCommentIcon
 */
const commentMouseUpHandler = (setSelectedCommentIcon) => e => {
    const { transform: { target: comment } } = e;
    comment?.set({hasBorders:false,hasControls:false})
    if (!comment.isMoved) setSelectedCommentIcon(comment);
    else {  // if commentObj is moved
        if (comment.tempAttachedEl) {
            const attachedElCoordinates = getObjectCoordinatesByOrigin(comment.tempAttachedEl);
            let pointDifs = {
                left: attachedElCoordinates.tl.x - comment.left,
                top: attachedElCoordinates.tl.y - comment.top,
            }
            if (comment.attachedShape && comment.attachedShape.uuid !== comment.tempAttachedEl.uuid) {
                detachCommentFromTarget(comment, comment.attachedShape)
            }
            attachCommentToTarget(comment, comment.tempAttachedEl, pointDifs);
            
            comment.tempAttachedEl.set('opacity', 1);
        } else {
            if (comment.attachedShape) {
                detachCommentFromTarget(comment, comment.attachedShape)
            }
        }
    }
    comment.isMoved = false;
}

/**
 * @param {number} unreadMessagesCount
 * @param {fabric.Circle} chip - Requires for setting position of text.
 * @returns
 */
const addTextToBadge = (unreadMessagesCount, chip) => {
    const renderText =
    unreadMessagesCount > 9 ? '+9' : unreadMessagesCount.toString();
    let textObj = new fabric.Textbox(renderText, {
        fill: '#fff',
        textAlign: 'center',
        fontFamily: 'Rubik, sans-serif',
        fontSize: 12,
        fontWeight: 400,
        left: chip.left + chip.width - (unreadMessagesCount > 9 ? 18 : 13),
        top: chip.top + chip.height / 4 + 1,
        width: unreadMessagesCount > 9 ? 15 : 8,
    });

    return textObj;
};

/**
 * Edits icon and badge as group.
 * @param {fabric.Canvas} canvas
 * @param {fabric.Image} element
 * @param {unreadMessagesCount} num
 * @param {"purple"|"red"|"orange"|"yellow"|"green"|"blue"} options.colorCode- - 
 * colorCode required for adding selected class to the color on toolbar.
 * @param {number} options.commentID
 * @param {boolean} options.autoSelect - When comment is created select it automatically.
 * @param {boolean} options.resolved - When a comment resolved or not.
 * @param {Function} options.setSelectedCommentIcon - Updates the comment icon.
 * @param {boolean} options.shouldHideComment - Decides should the comment hide or not.
 * @param {boolean} options.shouldHideResolvedComment - Decides should the resolved comment hide or not.
 * @param {number} options.left - Left position.
 * @param {number} options.top - Top position.
 */
function addCommentAsGroup({
    canvas,
    element,
    unreadMessagesCount,
    colorCode,
    commentID,
    autoSelect,
    resolved,
    setSelectedCommentIcon,
    shouldHideComment,
    shouldHideResolvedComment,
    left,
    top
}) {
    const elementCenterPoint = element.getCenterPoint();
  
    const chipColor =
    unreadMessagesCount > 0
        ? extendedColorOptions[colorCode].hard
        : extendedColorOptions[colorCode].soft;
    
    const chipOptions = {
        width: 32 + (unreadMessagesCount > 0 ? 12 : 0),
        height: 32,
        fill: chipColor,
        padding: 4,
        rx: 8,
        ry: 8,
        left: elementCenterPoint.x - 32 / 2,
        top: elementCenterPoint.y - 32 / 2,
        shadow: {
            color: 'rgba(0, 0, 0, 0.15)',
            offsetX: 0,
            offsetY: 4,
            blur: 16,
        }
    };

    const chip = new fabric.Rect(chipOptions);

    const pointer = new fabric.Rect({
        width: 13,
        height: 13,
        rx: 3,
        ry:3,
        fill: chipColor,
        left: unreadMessagesCount > 0 ? element.left + (element.width / 2) + 6 : element.left + (element.width / 2) + 1,
        top: element.top + element.height - 3,
        angle: 45
    })

    const textObj = addTextToBadge(unreadMessagesCount, chip);
    
    let commentObj = new Comment([chip, element, textObj, pointer], {
        commentID,
        colorCode,
        unreadCommentCount: unreadMessagesCount,
    // canvas,
    });

    commentObj.originX = 'center';
    commentObj.originY = 'bottom';

    // define group as comment
    Object.defineProperty(commentObj, 'type', {
        value: 'comment',
        writable: false,
        configurable: false,
        enumerable: true,
    });

    if (unreadMessagesCount === 0) {
        commentObj.hideCount(commentObj, canvas);
    } else {
        commentObj.showCount(commentObj, canvas);
    }

    // "Resolved" cannot be undone.
    if (resolved) commentObj.resolveComment(true, shouldHideResolvedComment);

    commentObj.left = left;
    commentObj.top = top;
    commentObj.isDynamic = true;
    // add group to canvas
    canvas.add(commentObj);
    commentObj.scaleX = 1 / canvas.getZoom();
    commentObj.scaleY = 1 / canvas.getZoom();

    commentObj.setCoords();
    //causing latency on comment rendering, performance issue, keeping in case of any corruption
    // canvas.renderAll();
    handleAttachComment(commentObj, canvas);

    if (autoSelect && setSelectedCommentIcon && !shouldHideComment)
        setSelectedCommentIcon(commentObj);

    if (shouldHideComment) {
        commentObj.hideComment();
    }
    //causing latency on comment rendering, performance issue, keeping in case of any corruption
    // canvas.renderAll();

    commentObj.on('moving', commentMoveHandler(canvas));
    commentObj.on('mouseup', commentMouseUpHandler(setSelectedCommentIcon));
}

/**
 * Adds comment Icon to the given points.
 * @param {object} options
 * @param {number} options.left - Left position.
 * @param {number} options.top - Top position.
 * @param options.canvas
 * @param {fabric.Canvas} canvas 
 * @param {"purple"|"red"|"orange"|"yellow"|"green"|"blue"} options.colorCode- - 
 * colorCode required for adding selected class to the color on toolbar.
 * @param {number} options.unreadMessagesCount - How many replies that user hasn't read.
 * @param {number} options.commentID
 * @param {boolean} options.autoSelect - When comment is created select it automatically.
 * @param {boolean} options.resolved - When a comment resolved or not.
 * @param {Function} options.setSelectedCommentIcon - Updates the comment icon.
 * @param options.shouldHideComment
 * @param options.shouldHideResolvedComment
 * @param options.colorCode
 */
export const addCommentIcon = ({
    canvas,
    left,
    top,
    colorCode,
    unreadMessagesCount,
    commentID,
    autoSelect,
    resolved,
    setSelectedCommentIcon,
    shouldHideComment,
    shouldHideResolvedComment
}) => {
    // Since we are adding a comment icon, this is always unread.
    const commentIcon =
    unreadMessagesCount > 0
        ? getCommentIcon(resolved ? 'black' : 'white')
        : extendedColorOptions[colorCode].image;

    fabric.Image.fromURL(commentIcon, (img) => {
        img.set({ left, top });
        img.hasControls = false;
        img.hasBorders = false;
        img.hoverCursor = 'pointer';
        img.dirty = true;
        addCommentAsGroup({
            canvas,
            element: img,
            unreadMessagesCount,
            colorCode,
            commentID,
            autoSelect,
            resolved,
            setSelectedCommentIcon,
            shouldHideComment,
            shouldHideResolvedComment,
            left,
            top
        });
    })
}
