
/**
 * @override
 * Check if cache is dirty
 * @param {Boolean} skipCanvas skip canvas checks because this object is painted
 * on parent canvas.
 */
export default function isCacheDirty(skipCanvas) {
    if (this.isNotVisible()) {
        return false;
    }
    if (this._cacheCanvas && this._cacheContext && !skipCanvas && this._updateCacheCanvas()) {
        // in this case the context is already cleared.
        return true;
    }
    else {
        if (this.dirty ||
            (this.clipPath && this.clipPath.absolutePositioned) ||
            (this.statefullCache && this.hasStateChanged('cacheProperties'))
        ) {
            if (this._cacheCanvas && this._cacheContext && !skipCanvas) {
                var width = this.cacheWidth / this.zoomX;
                var height = this.cacheHeight / this.zoomY;
                this._cacheContext.clearRect(-width / 2, -height / 2, width, height);
            }
            return true;
        }
    }
    return false;
}