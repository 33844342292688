import {getMapKeyByValue} from './CommonFunctions';

export class DuplicationSorter {
    constructor() {
        this.objectsOrderMap = new Map();
    }
    addToList(obj) {
        this.objectsOrderMap.set(obj.uuid, obj.zIndex);
    }
    sortObjects(objectList, duplicatedObjectsMap) {
        objectList.sort((a, b) => {
            const aBeforeUuid = getMapKeyByValue(duplicatedObjectsMap, a.uuid),
                bBeforeUuid = getMapKeyByValue(duplicatedObjectsMap, b.uuid);

            if (!aBeforeUuid || !bBeforeUuid) {
                return 0
            }

            const aZIndex = this.objectsOrderMap.get(aBeforeUuid),
                bZIndex = this.objectsOrderMap.get(bBeforeUuid);

            if (!aZIndex || !bZIndex) {
                return 0
            }

            if (aZIndex < bZIndex) {
                return -1;
            }
            if (aZIndex > bZIndex) {
                return 1;
            }

            return 0
        })
    }
}