import React, { useEffect, useState } from 'react'
import Slider from 'rc-slider';
import { Rating } from 'react-simple-star-rating'
import clsx from 'clsx';
import { toast } from 'react-toastify';
import FeedBackTextInput from './feedBackTextInput/FeedBackTextInput';
import { useDispatch } from 'react-redux';
import getToastIcon from '../../helpers/media/GetToastIcon';
import { submitFeedback } from '../../services/FeedbackService';
import store from '../../redux/Store';
import { setIsFeedbackShown } from '../../helpers/FeedbackMethods';
import {fitToScreenAssistance} from '../../helpers/FitToScreenAssistance';

import './FeedBack.scss';


const FeedBack = () => {
    const [actualRating, setActualRating] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [text, setText] = useState('');
    const [images, setImages] = useState([]);
    const dispatch = useDispatch();
    
    const handleClose = () => {
        dispatch({ type: 'modal/toggleFeedbackModal', payload: {
            shouldShow: false
        }});
    }

    const handleSubmit = async () => {
        if (isLoading) return;

        setIsLoading(true);
        let isError = false, errorMsg = '';
        try {
            const state = store.getState();
            const sendingText = typeof text === 'string' ? text.trim() : '';
            const response = await submitFeedback(state?.board?.whiteBoardId, sendingText, actualRating / 2, images.map(image => image?.file));
            if (response.createdAt) {
                toast.success('Your feedback has been submitted successfully!', {
                    icon: getToastIcon('success'),
                    className: 'wb_toast',
                });
                setIsFeedbackShown(state?.board?.whiteBoardSlugId);
            }
            else if (response.error) {
                isError = true;
                errorMsg = response.message;
            }
        } catch (error) {
            isError = true;
            errorMsg = error;
        } finally {
            handleClose();
            if (isError) {
                toast.error('An error has occurred while submitting your feedback. Please try again later!', {
                    icon: getToastIcon('error'),
                    className: 'wb_toast',
                });
                console.error('Error while submitting feedback:', errorMsg)
            }
        }
    }

    useEffect(() => {
        try {
            fitToScreenAssistance.destroy(true)
        } catch (err) {
            console.error(err)
        }
        return () => {
            setIsLoading(false);
        }
    }, []);

    return (
        <div className="feedback_modal__wrapper">
            <div 
                className="feedback_modal__overlay" 
                data-testid="feedback_modal__overlay"
                onClick={() => handleClose()} />

            <div className="feedback_modal">
                <div className="feedback_modal__header">
                    <h2 className="feedback_modal--title">Your opinion matter to us!</h2>
                    <button
                        type="button"
                        aria-label="Close feedback modal"
                        className="feedback_modal--closeBtn"
                        data-testid="feedback_modal--closeBtn"
                        onClick={() => handleClose()}
                    >
                        <em aria-hidden className="icon-close-circle" />
                    </button>
                </div>
                <div className="feedback_modal__body">
                    <p className="feedback_modal--text">How would you like to rate your experience using <strong>Whiteboard</strong>?</p>
                    <div className="feedback_modal__rating">
                        <div className="feedback_modal__rating--stars" data-testid="feedback_modal__rating--stars">
                            <Rating
                                allowFraction
                                className={clsx('feedback_modal__rating--input', {
                                    'disabled': isLoading
                                })}
                                emptyColor="#EFEFEF"
                                fillColor="#B388FF"
                                initialValue={actualRating / 2}
                                onClick={(star) => setActualRating(star * 2)}
                                rating={actualRating}
                                readonly={isLoading}
                                size={78}
                            />
                        </div>
                        <Slider
                            className="feedback_modal--slider"
                            disabled={isLoading}
                            max={10}
                            min={0}
                            onChange={(value) => setActualRating(value)}
                            value={actualRating}
                        />
                        <div className="feedback_modal__rating--info">
                            <span 
                                className={clsx({
                                    'active': actualRating < 5
                                })}
                                data-testid="feedback_modal__rating--info--notSatisfied"
                            >
                                Not Satisfied
                            </span>
                            <span 
                                className={clsx({
                                    'active': actualRating >= 5
                                })}
                                data-testid="feedback_modal__rating--info--satisfied"
                            >Very Satisfied</span>
                        </div>
                    </div>
                    <div 
                        className={clsx('feedback_modal__rating--actions', {
                            'active': actualRating > 0
                        })}
                        data-testid="feedback_modal__rating--actions"
                    >
                        <FeedBackTextInput 
                            disabled={isLoading}
                            images={images}
                            label="Can you please provide more detail on your feedback rating?"
                            setImages={setImages}
                            setText={setText}
                            style={{ marginTop: '50px'}}
                            text={text}
                        />
                        <button
                            type="button"
                            className='feedback_modal--submitBtn'
                            data-testid="feedback_modal--submitBtn"
                            disabled={isLoading}
                            onClick={() => handleSubmit()}
                        >
                            {isLoading ? 'Submitting...' : 'Submit'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeedBack;