export class LinePolygonUpdater {
    constructor(canvas, updaterFunc, shapeCount) {
        this.fabricCanvas = canvas;
        this.lines = [];
        this.updaterFunc = updaterFunc;
        this.maxShapeCount = shapeCount;
        this.addedShapeCount = 0;
    }
    addedShape() {
        this.addedShapeCount++;
        if (this.addedShapeCount >= this.maxShapeCount) {
            this.emit()
        }
    }
    add(data) {
        if (!data.properties || data.properties?.type !== 'curvedLine') {
            return
        }
        
        if (!data.properties.leftPolygon && !data.properties.rightPolygon) {
            return
        }
        
        if (
            typeof data.properties.leftPolygon === 'string' &&
            typeof data.properties.rightPolygon === 'string'
        ) {
            return
        }
        
        let isUpdated = false;
        
        if (data.properties.leftPolygon && typeof data.properties.leftPolygon !== 'string') {
            isUpdated = true
            data.properties.leftPolygon = data?.properties?.leftPolygon?.uuid;
        }
        
        if (data.properties.rightPolygon && typeof data.properties.rightPolygon !== 'string') {
            isUpdated = true
            data.properties.rightPolygon = data?.properties?.rightPolygon?.uuid;
        }
        
        if (!isUpdated) {
            return 
        }
        
        this.lines.push(data)
    }
    
    emit() {
        if (!this.lines.length) {
            return
        }

        this.updaterFunc && this.updaterFunc(this.lines)
    }
}