/**
 *
 */
const IconMenuLineType = () => {
    return (
        <svg
            fill="none"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4 5h4a3 3 0 013 3v4"
                stroke="#3C3E49"
                strokeLinecap="round"
            />
            <path
                d="M19 19h-5a3 3 0 01-3-3v-4"
                stroke="#3C3E49"
                strokeDasharray="1.2 1.4"
                strokeLinecap="round"
            />
        </svg>
    )
}

export default IconMenuLineType;