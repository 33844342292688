import { fabric } from 'fabric';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { calculateObjectCenterPoint, isTargetLine, changeObjectsSelectableProp } from './FabricMethods';
import { changeLineHeadPos } from './lines/LineControls';
import { getApproxMinLineWidth, getApproxMinLineHeight } from './TextWrapHelpers';
import { USER_ROLES, SHAPE_DEFAULTS, TEXTBOX_LINE_HEIGHT, AUTH_VALIDATION_MESSAGE } from './Constant';
import getToastIcon from './media/GetToastIcon';
import {getTextColorBaseOnShape} from './ColorHelper';
import { setMinWidthAndHeightToShape, onShapeTextboxChanged, getShapeTextareaDimensions } from './shapes/Common';
import { v4 as uuidv4 } from 'uuid';


/**
 * @param {Map} map 
 * @param {string} searchValue 
 * @returns String|undefined.
 */
export const getMapKeyByValue = (map, searchValue) => {
    if (!(map instanceof Map) || !searchValue) { return; }

    for (let [key, value] of map.entries()) {
        if (value === searchValue)
            return key;
    }
}

export const removeCanvasListener = (canvas) => {
    if (!canvas) return;
    canvas.off('mouse:move');
    canvas.off('mouse:down');
    canvas.off('mouse:up');
    canvas.fire('activate-shortcuts-mousemove');
};

export const stopDrawing = (canvas, drawInstance, emitOnMouseDown, onMouseDownLineHandler) => {
    let activeObject = {};

    if (drawInstance.type === 'textbox') {
        activeObject = drawInstance;
        emitOnMouseDown(drawInstance, drawInstance);
        canvas.fire('line-added', { target: drawInstance });  // this is for activity log
    } else if (drawInstance.type === 'curvedLine') {
        drawInstance.isDrawingStop = true;
        drawInstance.removeFromDirtyList();
        drawInstance.organizeControls()
        canvas.fire('emit-drawn-connector', { connector: drawInstance });
    } else {
        if (drawInstance.width <= 10) {
            if (drawInstance.type === 'ellipse') {
                drawInstance.rx = 100;
                drawInstance.ry = 100;
            }
            drawInstance.width = 200;
            drawInstance.height = 200;
        }
        let top = drawInstance.top;
        const left = drawInstance.left;
        const coordinates = drawInstance.getCenterPoint();

        drawInstance.set({
            originX: 'center',
            originY: 'center',
            left: coordinates.x,
            top: coordinates.y,
        });

        const textareaDimensions = getShapeTextareaDimensions(drawInstance);

        if (drawInstance.type === 'triangle') {
            top = textareaDimensions.top;
        } else {
            top += textareaDimensions.top;
        }

        let text = new fabric.Textbox('', {
            originX: 'center',
            originY: 'center',
            textAlign: 'center',
            fill: SHAPE_DEFAULTS.TEXT_COLOR,
            fontSize: 1,
            // splitByGrapheme: true,
            breakWords: true,
            fixedWidth: drawInstance.width,
            width: textareaDimensions.width,
            height: textareaDimensions.height,
            top,
            left: left + textareaDimensions.left,
            hasBorders: false,
            selectable: false,
            fontFamily: SHAPE_DEFAULTS.FONT_FAMILY,
            fontWeight: 'normal',
            underline: false,
            fontStyle: 'normal',
            lockScalingFlip: true,
            flipX: false,
            flipY: false
        });

        let group = new fabric.Group([drawInstance, text], {
            shapeType: drawInstance.type,
            originX: 'center',
            originY: 'center',
            lockScalingFlip: true,
            flipX: false,
            flipY: false,
            objectCaching: false
            // minScaleLimit: 0.6,
        });
        canvas.add(group);
        canvas.remove(drawInstance);
        activeObject = group;
        emitOnMouseDown(group, group);
    }

    removeCanvasListener(canvas);
    onMouseDownLineHandler(canvas);
    changeObjectsSelectableProp(canvas, true);
    canvas.hoverCursor = 'all-scroll';
    if (!isTargetLine(drawInstance)) canvas.setActiveObject(activeObject);
    canvas.renderAll();
}

export const onShapeDoubleClick = (
    group,
    canvas,
    emitOnMouseDown,
    userId
) => {
    group.off('mousedblclick');

    group.on('mousedblclick', async (e) => {
        if(e.target.type ==='textbox') canvas.fire('textbox-editing');
        else canvas.fire('shapeText-editing');
        if (group.editFor) {
            group.selectWord(group.getSelectionStartFromPointer(e.e));
        }
        if (e.target.type === 'comment') return;
        if (e.target && e.target.lockMovementX && e.target.lockMovementY) return;
        if (e.target._objects) {
            const processId = uuidv4();
            
            const {shapeUuids} = canvas.lockManager.startEditing([group], canvas.pageId, (data) => {
                if (!data.failedShapes || !data.failedShapes.includes(e.target.uuid)) {
                    return;
                }

                canvas.lockManager.stopEditing([group], canvas.pageId)
                group.editingTextObj = null;

                textboxObject.set({
                    ...originalTextParams
                });

                textboxObject.set({ styles: originalTextStyles, fill: originalTextParams.fill });
                group.onShapeChanged();
                canvas.renderAll();
                textForEditing.aborted = true;
                textForEditing.visible = false;
                canvas.remove(textForEditing);
                group.editingText = null;
                canvas.fire('remove-from-undo-stack', { processId });
            })
            
            if (!shapeUuids.length) {
                return
            }
            let shape = e.target._objects[0],
                textboxObject = e.target._objects[1],
                actualScaleX = group.scaleX * textboxObject.scaleX,
                actualScaleY = group.scaleY * textboxObject.scaleY,
                topPos = group.top,
                lastOnChangeInfo = { linesCount: -1 };
            if (textboxObject.fontSize < 10 && textboxObject.text.trim() === '') {
                textboxObject.fontSize = SHAPE_DEFAULTS.FONT_SIZE;
                canvas.requestRenderAll();
            }
            
            const originalTextParams = {
                text: textboxObject.text,
                visible: true,
                fontSize: textboxObject.fontSize,
                width: textboxObject.width,
                height: textboxObject.height,
                top: textboxObject.top,
                underline: textboxObject.underline,
                fontWeight: textboxObject.fontWeight,
                fontStyle: textboxObject.fontStyle,
                textAlign: textboxObject.textAlign,
                breakWords: true,
                splitByGrapheme: false,
                fill: textboxObject.fill
            }
            const originalTextStyles = textboxObject.styles;
            
            textboxObject.set({
                visible: false
            })
            group.onShapeChanged();
            let defaultFont = `${textboxObject.fontSize}px / ${TEXTBOX_LINE_HEIGHT} ${textboxObject.fontFamily}`;
            const minWidth = getApproxMinLineWidth(defaultFont);
            const minHeight = getApproxMinLineHeight(textboxObject.fontSize, TEXTBOX_LINE_HEIGHT);

            // Setting minimum width and height if drawn shape is smaller than min values.
            setMinWidthAndHeightToShape({
                canvas,
                group,
                shape,
                minHeight,
                minWidth
            });

            // Textarea Dimensions
            let textareaDimensions = getShapeTextareaDimensions(group);

            if (group.shapeType === 'triangle') {
                topPos = textareaDimensions.top;
            }

            const textColor = e.target.isTextColorApplied ? textboxObject.fill : getTextColorBaseOnShape(e.target);
            let textForEditing = new fabric.Textbox(textboxObject.text, {
                originX: 'center',
                originY: 'center',
                textAlign: textboxObject.textAlign,
                fontSize: textboxObject.fontSize,
                left: group.left + (textboxObject.left * actualScaleX),
                top: topPos,
                // splitByGrapheme: true,
                fill:textColor,
                breakWords: true,
                fixedWidth: textareaDimensions.width,
                width: textareaDimensions.width,
                height: textareaDimensions.height,
                hasBorders: false,
                selectable: false,
                fontFamily: textboxObject.fontFamily,
                fontWeight: textboxObject.fontWeight,
                selected: false,
                underline: textboxObject.underline,
                fontStyle: textboxObject.fontStyle,
                lockScalingFlip: true,
                flipX: false,
                flipY: false,
                scaleX: actualScaleX,
                scaleY: actualScaleY,
                angle: group.angle,
                isDynamic: true
            });

            textForEditing.set({ styles: textboxObject.styles });
            canvas.renderAll();
            textboxObject.visible = false;
            textForEditing.visible = true;
            textForEditing.hasConstrols = false;
            textForEditing.editFor = group;
            canvas.add(textForEditing);
            group.editingText = true;
            group.editingTextObj = textForEditing;
            canvas.setActiveObject(textForEditing);
            textForEditing.enterEditing();
            textForEditing.setSelectionStart(textForEditing.text.length);
            textForEditing.setSelectionEnd(textForEditing.text.length);

            canvas.on('before-reload-canvas', () => {
                if (canvas.getObjects().find(e => e.uuid === group.uuid)) {
                    canvas.fire('modified-with-event', { target: group });
                    canvas.fire('modify-to-undo-stack', { target: group });
                    emitOnMouseDown(group, group, true);
                    canvas.remove(textForEditing);
                    canvas.setActiveObject(group);
                    canvas.fire('exit-shape-text-editing');
                }
            });

            canvas.on('shape-height-updated-by-bolder', ({ object }) => {
                if (object?.uuid === group?.uuid) {
                    textareaDimensions = getShapeTextareaDimensions(object);
                }
            });

            // !NOTE: I think there is an issue here. Need to check uuid first beore changing fontSize
            canvas.on('change-text-size', (e) => {
                textForEditing.set({ fontSize: e });
                canvas.renderAll();
            });

            textForEditing.on('editing:exited', () => {
                if (textForEditing.aborted) {
                    return
                }
                canvas.lockManager.stopEditing([group], canvas.pageId)
                canvas.fire('exit-shape-text-editing');
                group.editingTextObj = null;
                const newVal = textForEditing.text;

                // Get exact point the target inside the group object.
                const pointOnObject = group.toLocalPoint(
                    new fabric.Point(textForEditing.left, textForEditing.top),
                    group.originX,
                    group.originY
                );

                const newTextParams = {
                    visible: true,
                    fontSize: textForEditing.fontSize,
                    width: textForEditing.width,
                    height: textForEditing.height,
                    top: pointOnObject.y / actualScaleY,
                    underline: textForEditing.underline,
                    fontWeight: textForEditing.fontWeight,
                    fontStyle: textForEditing.fontStyle,
                    textAlign: textForEditing.textAlign,
                    breakWords: true,
                    splitByGrapheme: false,
                }
                textboxObject.set({
                    text: newVal,
                    ...newTextParams 
                });

                group.modifiedBy = userId;
                emitOnMouseDown(group, group, true);
                canvas.fire('modified-with-event', { target: group });
                canvas.fire('modify-to-undo-stack', { target: group, processId });

                textboxObject.styles = null;
                textboxObject.set({ styles: textForEditing.styles, fill: textForEditing.fill });
                group.onShapeChanged();
                canvas.renderAll();
                textForEditing.visible = false;
                canvas.remove(textForEditing);
                group.editingText = null;
                canvas.setActiveObject(group);
            });

            textForEditing.on('changed', (opt) => onShapeTextboxChanged({
                opt,
                canvas,
                group,
                lastOnChangeInfo,
                textForEditing,
                textInstWidth: textareaDimensions.width,
                textInstHeight: textareaDimensions.height,
                actualScaleX,
                actualScaleY
            }));
        }
    });
};

export const getTrianglePaddingWidthTopWithScale = (drawInstance) => {
    let textInstWidth, textInstTop = 0, textInstLeft = 0;
    if (drawInstance.type === 'triangle') {
        textInstWidth = parseInt(drawInstance.width / 2);
        textInstLeft = parseInt(drawInstance.width / 2);
        if (drawInstance.height * drawInstance.scaleY > 200 * drawInstance.scaleY) {
            textInstTop = parseInt(drawInstance.height * drawInstance.scaleY / 6) + 20 * drawInstance.scaleY;
        }
        else if (drawInstance.height * drawInstance.scaleY > 130 * drawInstance.scaleY) {
            textInstTop = parseInt(drawInstance.height * drawInstance.scaleY / 6) + 10 * drawInstance.scaleY;
        }
        else if (drawInstance.height * drawInstance.scaleY > 100 * drawInstance.scaleY) {
            textInstTop = parseInt(drawInstance.height * drawInstance.scaleY / 6) + 5 * drawInstance.scaleY;
        }
        else if (drawInstance.height * drawInstance.scaleY > 30 * drawInstance.scaleY) {
            textInstTop = 14 * drawInstance.scaleY;
        }
    }
    const retObj = { 'width': textInstWidth, 'top': textInstTop, 'left': textInstLeft };
    return retObj;
}

/**
 * Calculates object center point with the given options.
 * @param {fabric.Object} object 
 * @param {object} options
 * @param {boolean} options.useOriginalTransformForGroup - If object's group is duplicating with alt key, use original transform for the group.
 * @param {boolean} options.useOriginalTransform - If object is duplicating with alt key, use original transform for the object.
 * @param {} e 
 * @returns 
 */
const calculateObjectCoordinates = (object, options, e) => {
    if (options.useOriginalTransformForGroup) {
        const objectCenter = object.getCenterPoint();
        const groupCenter = e?.transform?.original?.center;
        return {
            x: objectCenter.x + groupCenter.x,
            y: objectCenter.y + groupCenter.y
        }
    } else if (options.useOriginalTransform) {
        return e?.transform?.original?.center;
    } else {
        return calculateObjectCenterPoint(object);
    }
}

export const shapeLineMoveHandler = (group, canvas, options = {}, e = null) => {
    if (group.lines && group.lines.length) {
        let lines = group.lines;
        for (const connectorUuid of lines) {
            const line = canvas.getObjects().find(e => e.uuid === connectorUuid);
  
            // remove the line if its not exist
            if (!line) {
                group.lines = group.lines.filter(e => e !== connectorUuid);
                continue;
            }
      
            // if the line is in the same group with object, do not change the position line
            if (line && line.group !== undefined && group.group !== undefined && line.group === group.group) {
                continue;
            }
            // if mid point should change, keep the original points
            if (options.changeMidPoints) {
                if (line && !line.originalPoints) {
                    const originalPoints = line.points.map(e => ({...e}));
                    line.originalPoints = originalPoints;
                }
            }
            if (line && line.rightPolygon && group.uuid === line.rightPolygon.uuid) {
                const groupCoordinates = calculateObjectCoordinates(group, options, e);
                changeLineHeadPos(
                    line,
                    'end',
                    {
                        x: (groupCoordinates.x + line.rightDeltaX),
                        y: (groupCoordinates.y + line.rightDeltaY)
                    },
                );
            }
            else if (line && line.leftPolygon && group.uuid === line.leftPolygon.uuid) {
                const groupCoordinates = calculateObjectCoordinates(group, options, e);
                changeLineHeadPos(
                    line,
                    'start',
                    {
                        x: (groupCoordinates.x + line.leftDeltaX),
                        y: (groupCoordinates.y + line.leftDeltaY)
                    }
                );
            }
            // if mid point should change, calculate the new mid point according to original points
            // if mid point should change, calculate the new mid point according to original points
            if (options.changeMidPoints && line) {
                for (const pointIndex in line.points) {
                    const parsedPointIndex = parseInt(pointIndex);
                    // do not change the first and last point
                    if (parsedPointIndex === 0 || parsedPointIndex === line.points.length - 1) continue;

                    changeLineHeadPos(
                        line,
                        'middle',
                        {
                            x: line.originalPoints[parsedPointIndex].x + (line.points[0].x - line.originalPoints[0].x),
                            y: line.originalPoints[parsedPointIndex].y + (line.points[0].y - line.originalPoints[0].y),
                        },
                        {
                            pointIndex: parsedPointIndex
                        }
                    );
                }
            }
        }
    }
}
export const groupMoveHandler = (group, canvas) => {
    group.off('moving');
    group.on('moving', function () {
        shapeLineMoveHandler(group, canvas);
    });
};

export const createInitialFromUsername = (name = '') => {
    let names = name?.split(' ');
    names = names.filter((item) => !!item);
    let userName = '';

    if (names.length > 1) {
        userName = `${names[0][0].toLocaleUpperCase()}`;
    } else if (names.length === 1) {
        userName = names[0][0].toLocaleUpperCase();
    } else {
        userName = name;
    }

    return userName;
}

export const createInitialFromEmail = (email = '') => {
    if (!email) return '';
    const firstChar = email[0]?.toLocaleUpperCase();
    return firstChar;
}

export const createObjectToBeEmitted = (whiteBoardId, userId, shape, isDeleted, shapeType, options = {}) => {
    // if shape includes texbox, set breakWords to true
    if (shape.type === 'group'
      && Array.isArray(shape.objects) 
      && shape.objects.length > 1 
      && shape.objects[1].type === 'textbox') {
        shape.objects[1].breakWords = true;
        shape.objects[1].visible = true;
    } 
    let connectorAdded = false;
    let hideLog = false;
    try {
        if (options.hasOwnProperty('connectorAdded')) {
            connectorAdded = options.connectorAdded
        }
        if (options.hasOwnProperty('hideLog')) {
            hideLog = options.hideLog
        }
    } catch (err) {
        console.error(err)
    }

    const objectData = {
        shapeType: shapeType,
        whiteboardId: whiteBoardId,
        uuid: shape.uuid,
        type: shape.type,
        properties: shape,
        createdBy: shape.createdBy ? shape.createdBy : userId,
        modifiedBy: userId,
        isDeleted: isDeleted ? isDeleted : false,
        connectorAdded,
    }

    if (hideLog) {
        objectData.hideLog = true;
    }
    return objectData
};

/**
 * Getting email and name information from url and returns trimmed.
 * This function prepared in order to avoid unexpected issues related to spaces.
 * @param {string} search 
 * @returns {{ email: string|null, name: string|null, params: object}}
 */

export const getUserQueryParams = (search = window.location.search) => {
    const params = new URLSearchParams(search);

    const email = params.get('email')?.trim();
    const name = params.get('name')?.trim();
    const pageId = params.get('pageId')?.trim();
    const permission = params.get('permission')?.trim();

    return { email, name, permission, params, pageId }
}

/**
 * Checking is the user has access to the related feature.
 * @param {string} item 
 * @param {'view'|'comment'|'edit'} userAccess
 * @returns Boolean.
 */
export const isUserHasAccessToFeature = (item, userAccess) => {
    if (typeof item !== 'string' || !item) { return false; }

    switch (item.toLocaleLowerCase('en-US')) {
        case 'select':
        case 'lasso':
        case 'pen':
        case 'pencil':
        case 'shape':
        case 'ellipse':
        case 'triangle':
        case 'rectangle':
        case 'rhombus':
        case 'parallelogram':
        case 'arrow':
        case 'connector':
        case 'line':
        case 'sticky':
        case 'frame':
        case 'table':
        case 'text':
        case 'undo':
        case 'redo':
        case 'boardname':
        case 'import':
        case 'objectanimation':
        case 'subtoolbar':
        case 'shortcuts':
        case 'image_upload':
        case 'copy_paste':
        case 'edit_page':
        case 'page_duplicate':
        case 'add_page':
        case 'page_modification':
        case 'hyperlink_edit':
        case 'hyperlink_apply':
        case 'import_flowchart':
        case 'more_popup':
        case 'eraser':
        case 'remove_object':
        case 'undo_deleted': // If an element removed and tried to undo from history panel
        case 'change_permission':
        case 'history_create_or_delete': // If an element removed or created and tried to undo or redo from history panel
        case 'page_delete':
        case 'hyperlink_delete':
        case 'remove_flowchart':
            return userAccess === USER_ROLES.edit.id;
        case 'comment_modification':
            return [
                USER_ROLES.comment.id,
                USER_ROLES.edit.id
            ].includes(userAccess)
        case 'activity_log':
        case 'frames_tab':
        case 'pan':
        case 'activity_log_panel': // Dont fetch data if not allowed
        case 'users': // Dont fetch users if not allowed
        case 'comments': // Dont fetch comments if not allowed
        case 'export_board':
            return userAccess !== USER_ROLES.notAllowed.id;
        default:
            return false;
    }
}

/**
 * Find and returns the highest permission of user.
 * @param {'view' | 'comment' | 'edit'} userPermission
 * @param {'view' | 'comment' | 'edit'} userLinkPermission
 * @param {'view' | 'comment' | 'edit'} builderUserLinkPermission
 * @param {boolean} isBuilderAiUser
 * @param {boolean} isPublicSharableLinkExist
 * @returns {'view' | 'comment' | 'edit'}
 */
export const getHighestUserPermission = (
    userPermission,
    userLinkPermission,
    builderUserLinkPermission,
    isBuilderAiUser,
) => {
    let permissionList = [];

    if (isBuilderAiUser) {
        permissionList = [
            builderUserLinkPermission,
            userLinkPermission,
            userPermission,
        ];
    } else {
    // For regular users, we shouldn't use builder.ai users link permissions.
        permissionList = [userLinkPermission, userPermission];
    }

    return permissionList
        .map((permission) => {
            const p = permission === 'NOT_ALLOWED' ? 'notAllowed' : permission;
            return USER_ROLES[p];
        })
        .sort((a, b) => a?.tier - b?.tier)[0].id;
};

/**
 * Displaying tost message when api returns unauthorized error or user permission removed from board.
 * @param {string} errorMessage 
 * @param closable
 */
export const showUnauthorizedToastMessage = (errorMessage, closable = true, addGoToSection = true) => {
    const toastId = 'unauthorized_user';

    const isExternalProject = window.location.pathname.includes('teamBoard') || window.location.pathname.includes('bmeetBoard');

    // There are some dynamic messages. So can't send closable as false in those scenarios. So if the errorMessage includes the board unauthorized message; remove the close icon.
    if (typeof errorMessage === 'string' && errorMessage.indexOf(AUTH_VALIDATION_MESSAGE.BOARD_UNAUTHORIZED) > -1) {
        closable = false;
    }

    toast.error((
        <>
            {errorMessage}
            {(!isExternalProject && addGoToSection) ? (
                <span style={{ marginLeft: 4 }}>
                    Please go to
                    <Link style={{ marginLeft: 3 }} to="/boards">"My Boards."</Link>
                </span>
            ) : null}
        </>
    ), {
        toastId,
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        hideProgressBar: true,
        closeButton: closable,
        position: 'bottom-center',
        className: 'wb_toast',
        icon: getToastIcon('error')
    });
}

export const checkIfActiveObjectContainAnyLockedObject = (activeObject) => {
    if (!activeObject || (activeObject.isLocked && activeObject.lockMovementX && activeObject.lockMovementY)) return true;
    if (activeObject._objects) {
        if (activeObject._objects.find(object => object.isLocked)) return true;
    }
    return false;
} 

export const isExternalProject = () => {
    if (!window.location) return false;
    const pattern = /(teamBoard|bmeetBoard|enterprise)/;
    const isExternal = pattern.test(window.location?.pathname);
    return isExternal;
}

export const isEnterpriseProject = () => {
    if (!window.location) return false;
    const pattern = /enterprise/;
    const isEnterprise = pattern.test(window.location?.pathname);
    return isEnterprise;
}


export const generateUuidForShape = (canvas) => {
    let id = uuidv4();
    let result = canvas?.getObjects()?.filter(e => e.id && e.id === id);
    if (result.length) return generateUuidForShape(canvas);
    else return id;
}


export async function wait(ms = 1000) {
    return new Promise((resolve) => {
        setTimeout(() => resolve(true), ms)
    })
}