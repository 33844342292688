/**
 * Return the dimension and the zoom level needed to create a cache canvas
 * big enough to host the object to be cached.
 * @override
 * @returns {object}.zoomY zoomY zoom value to unscale the canvas before drawing cache.
 * @returns {object}.zoomX zoomX zoom value to unscale the canvas before drawing cache.
 * @returns {object}.height height of canvas.
 * @returns {object}.width width of canvas.
 * @returns {object}.y height of object to be cached.
 * @returns {object}.x width of object to be cached.
 * @private
 */
export default function _getCacheCanvasDimensions() {
    const objectScale = this.getTotalObjectScaling();
    const dim = this._getTransformedDimensions(0, 0); // caculate dimensions without skewing

    // @overrided content
    // Table and Frame have title above of the object. We need increase the height for them.
    const additionalHeight = this?.aCoords?.textTL ? (this.aCoords.textBL.y - this.aCoords.textTL.y) * objectScale.scaleY : 0;

    const neededX = dim.x * objectScale.scaleX / this.scaleX;
    const neededY = ((dim.y + additionalHeight) * objectScale.scaleY / this.scaleY) + additionalHeight;
    return {
        // for sure this 2 is slightly creating problem
        // in situation in which the cache canvas gets an upper limit
        // also objectScale contains already scaleX and scaleY
        width: neededX + 2,
        height: neededY + 2,
        zoomX: objectScale.scaleX,
        zoomY: objectScale.scaleY,
        x: neededX,
        y: neededY
    };
}