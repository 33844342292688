import { CachedLinkedList } from '../renderers/dataStructures/LinkedList';


let supportsIdleCallback = 'requestIdleCallback' in window;

export class ResourcePool {
    constructor(config) {
        this.available = new CachedLinkedList();
        this.acquired = new Map();
        this.gcJobId = 0;
        this.factory = config.factory;
        this.minSize = config.minSize ?? 0;
        this.maxSize = config.maxSize ?? Infinity;
        this.gcEnabled = config.collectGarbage ?? false;
        this.gcInterval = config.garbageCollectionInterval ?? 1000;
        this.gcLimit = config.maxItemsPerGarbageCollection ?? 100;
        this.idleTtl = config.idleTimeToLive ?? 1000;
        this.useIdleCallback = supportsIdleCallback && (config.useIdleCallback ?? false);
        this.idleCallbackTimeout = config.idleCallbackTimeout ?? 1000;
    }

    get availableCount() {
        return this.available.size;
    }

    get acquiredCount() {
        return this.acquired.size;
    }

    acquire() {
        let resource;
        if (this.available.size > 0) {
            resource = this.available.removeHead();
        } else {
            resource = {
                resource: this.factory.create(),
                lastUsed: 0
            };
        }
        this.acquired.set(resource.resource, resource);
        return resource.resource;
    }

    release(resource) {
        let slot = this.acquired.get(resource);
        if (slot) {
            this.releaseSlot(slot);
        }
    }

    releaseAll() {
        const now = Date.now();
        this.acquired.forEach(slot => {
            this.releaseSlot(slot, now);
        });
    }

    dispose(destroy = true) {
        if (destroy && this.factory.destroy) {
            this.acquired.forEach(this.destroySlot);
            this.available.forEach(this.destroySlot);
        }
        this.acquired.clear();
        this.available.clear();
        this.cancelGarbageCollection();
    }

    enableGarbageCollection() {
        this.gcEnabled = true;
    }

    disableGarbageCollection() {
        this.gcEnabled = false;
        this.cancelGarbageCollection();
    }

    scheduleGarbageCollection() {
        if (this.available.size > this.minSize) {
            this.gcJobId = window.setTimeout(this.useIdleCallback ? this.runGarbageCollectionWhenIdle : this.runAndScheduleGarbageCollection, this.gcInterval);
        }
    }

    cancelGarbageCollection() {
        window.clearTimeout(this.gcJobId);
        if (supportsIdleCallback) {
            window.cancelIdleCallback(this.gcJobId);
        }
    }

    collectGarbage() {
        this.gcJobId = 0;
        let count = this.available.size - this.minSize;
        if (count <= 0) return;
        count = Math.min(count, this.gcLimit);

        const now = Date.now();
        this.available.forEach(slot => {
            if (now - slot.lastUsed > this.idleTtl) {
                this.destroySlot(slot);
                this.available.remove(slot);
                count--;
                return count !== 0;
            }
        });
    }

    releaseSlot(slot, now = Date.now()) {
        slot.lastUsed = now;
        this.factory.reset?.(slot.resource);
        this.acquired.delete(slot.resource);
        if (this.available.size < this.maxSize) {
            this.available.append(slot);
        } else {
            this.destroySlot(slot);
        }
        if (this.gcEnabled && this.gcJobId === 0) {
            this.scheduleGarbageCollection();
        }
    }

    destroySlot(slot) {
        this?.factory.destroy?.(slot.resource);
    }

    runGarbageCollectionWhenIdle() {
        this.gcJobId = window.requestIdleCallback(this.runAndScheduleGarbageCollection, {
            timeout: this.idleCallbackTimeout
        });
    }

    runAndScheduleGarbageCollection() {
        this.collectGarbage();
        this.scheduleGarbageCollection();
    }
}