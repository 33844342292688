import {Viewport} from '../../../core/viewPort/Viewport';

/**
 * Calculates of the coordinates of the objects that are locked by other users.
 * This is not an override, but a helper function.
 * @returns {Map<number, Map<string, Array|number>>|null} Map of locked objects by user id.
 */
export default function getCollabLockedObjBbox() {
    const objects = this.getObjects().filter(o => o.collabLocked);
    if (!objects || !objects.length) {
        return null
    }

    const lockedObjectsByUsers = new Map();

    objects.forEach((obj) => {
        if (!lockedObjectsByUsers.has(obj.collabLockedBy.id)) {
            lockedObjectsByUsers.set(obj.collabLockedBy.id, new Map());
            lockedObjectsByUsers.get(obj.collabLockedBy.id).set('user', obj.collabLockedBy);
            lockedObjectsByUsers.get(obj.collabLockedBy.id).set('objects', []);
            lockedObjectsByUsers.get(obj.collabLockedBy.id).set('points', []);

        }
        const lockedByThisUser = lockedObjectsByUsers.get(obj.collabLockedBy.id);
        lockedByThisUser.get('objects').push(obj);

        // bbox for each object
        // getBoundingRect(true) returns the bounding rect of the object in the coordinate space of the canvas.
        // and we add the left top and right bottom values the points to the array to calculate the bbox of all objects.
        const boundingRect = obj.getCompleteBoundingRect(false);
        const point = [
            {
                x: boundingRect.left,
                y: boundingRect.top,
            },
            {
                x: boundingRect.left + boundingRect.width,
                y: boundingRect.top + boundingRect.height,
            }
        ];
        lockedByThisUser.get('points').push(...point);
    });

    for (const key of lockedObjectsByUsers.keys()) {
        const lockedByThisUser = lockedObjectsByUsers.get(key);
        const points = lockedByThisUser.get('points');
        const x = points.map((point) => point.x);
        const y = points.map((point) => point.y);

        // bbox for all objects
        const left = Math.min(...x);
        const right = Math.max(...x);
        const top = Math.min(...y);
        const bottom = Math.max(...y);

        // Pan the entire canvas so that the active object is centered in the viewport.
        // The object's relative position in the canvas should not change.
        const objWidth = Math.abs(right - left);
        const objHeight = Math.abs(bottom - top);

        const viewport = new Viewport(left, top, objWidth, objHeight)
        lockedByThisUser.set('viewport', viewport);
    }

    return lockedObjectsByUsers;
}