import { useCallback, useEffect, useState, useRef } from 'react';



/**
 * Html element move handler. Searchs for 'dragger' element inside of the given html element with
 * draggableElement paramater. If it founds it, it adds move handler for that element.
 * @param {React.RefObject} draggableElement
 */
const useMove = (draggableElement) =>  {
    const [isDown, setIsDown] = useState(false);
    const [offset, setOffset] = useState([]);
    const draggerRef = useRef();

    const obejctMouseDownListener = useCallback( e => {
        setIsDown(true);
        setOffset([
            draggableElement.current.offsetLeft - e.clientX,
            draggableElement.current.offsetTop - e.clientY
        ]);
    }, [draggableElement]);

    const documentMouseUpListener = () => {
        setIsDown(false);
    }
    const documentMoveListener = useCallback(e => {
        if (isDown) {
            draggableElement.current.style.left = (e.clientX + offset[0]) + 'px';
            draggableElement.current.style.top  = (e.clientY + offset[1]) + 'px';
        }
    }, [isDown, draggableElement, offset]);

    useEffect(() => {
        let dragElement = draggableElement.current;
        if (dragElement) {
            draggerRef.current = dragElement.querySelector('.dragger');
            if (draggerRef.current) {
                draggerRef.current.addEventListener('mousedown', obejctMouseDownListener);
            }
        }
        document.addEventListener('mouseup', documentMouseUpListener);
         
        document.addEventListener('mousemove', documentMoveListener);

        return () => {
            if (dragElement && draggerRef.current)
                draggerRef.current.removeEventListener('mousedown', obejctMouseDownListener);
            document.removeEventListener('mouseup', documentMouseUpListener);
            document.removeEventListener('mousemove', documentMoveListener);
        }
    }, [draggableElement, obejctMouseDownListener, documentMoveListener]);
    return isDown;
}

export default useMove;