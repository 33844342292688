/**
 *
 */
const SearchIcon = () => {
    return (
        <svg
            fill="none"
            height="15"
            viewBox="0 0 15 15"
            width="15"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                clipRule="evenodd"
                d="M8.746 11.292a4.814 4.814 0 10-3.766-8.86 4.814 4.814 0 003.766 8.86z"
                fillRule="evenodd"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.345"
            />
            <path
                d="M10.267 10.266L14.001 14"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.2"
            />
        </svg>
    )
}

export default SearchIcon;