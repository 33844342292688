import { frameMouseUpHandler } from './FrameMethods';

export const setFrameOptions = (frame) => {
    frame.set({
        objectCaching: false
    });
}

const onFrameDrawn = (frame) => {
    setFrameOptions(frame);
    // this is needed to handling events after resizing the frame
    frame.on('mouseup', frameMouseUpHandler);
}

export default onFrameDrawn;