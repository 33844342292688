import { fabric } from 'fabric';
import { SHAPE_DEFAULTS_AS_SHAPE_PROPS, modes } from '../helpers/Constant';
import { removeCanvasListener, stopDrawing } from '../helpers/CommonFunctions'
import store from '../redux/Store';
import { changeObjectsSelectableProp } from '../helpers/FabricMethods';

let drawInstance = null,
    mouseDown = false,
    origX,
    origY;

export const createRect = (canvas, emitOnMouseDown, onMouseDownLineHandler, handleChangeSelectMode) => {
    store.dispatch({
        type: 'board/changeCurrentMode',
        payload: modes.RECTANGLE
    });

    removeCanvasListener(canvas);
    canvas.toggleDragMode(canvas, false);
    canvas.on('mouse:down', startAddRect(canvas));
    canvas.on('mouse:move', startDrawingRect(canvas));
    canvas.on('mouse:up', () => {
        mouseDown = false;
        stopDrawing(canvas, drawInstance, emitOnMouseDown, onMouseDownLineHandler);
        handleChangeSelectMode('select');
    });
    canvas.selection = false;
    canvas.defaultCursor = 'default';
    canvas.hoverCursor = 'auto';
    canvas.isDrawingMode = false;
    changeObjectsSelectableProp(canvas, false);
    canvas.discardActiveObject().requestRenderAll();
}

const startAddRect = (canvas) => {
    return _ref3 => {
        let {
            e
        } = _ref3;
        mouseDown = true;
        const pointer = canvas.getPointer(e);
        console.log('startAddRect', pointer);
        console.log(e.clientX, ' ', e.clientY);
        origX = pointer.x;
        origY = pointer.y;
        //Old
        drawInstance = new fabric.Rect({
            // originX: 'center',
            // originY: 'center',
            ...SHAPE_DEFAULTS_AS_SHAPE_PROPS,
            left: origX,
            top: origY,
            width: 200,
            height: 200,
            selectable: false,
            strokeUniform: true,
            isDynamic: true  // tells that we will use this instance only for mocking
        });
        canvas.add(drawInstance);
    };
}

const startDrawingRect = (canvas) => {
    return _ref4 => {
        let {
            e
        } = _ref4;

        if (mouseDown) {
            const pointer = canvas.getPointer(e);

            if (pointer.x < origX) {
                drawInstance.set('left', pointer.x);
            }

            if (pointer.y < origY) {
                drawInstance.set('top', pointer.y);
            }

            drawInstance.set({
                width: Math.abs(pointer.x - origX),
                height: Math.abs(pointer.y - origY)
            });
            drawInstance.setCoords();
            canvas.renderAll();
        }
    };
}