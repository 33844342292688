import React from 'react';
/**
 *
 */
const ShareWithMe = () => {
    return (
        <svg
            fill="none"
            height="22"
            viewBox="0 0 22 22"
            width="22"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.625 15.3749C1.62601 13.1969 2.88422 11.2153 4.85489 10.2879C6.82556 9.36053 9.15448 9.65412 10.8333 11.0416"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="M4.28711 3.31738C5.36284 4.4327 6.84588 5.06262 8.39544 5.06238C9.17258 5.06291 9.94164 4.90467 10.6554 4.59738"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                clipRule="evenodd"
                d="M7.25 8.5C9.14848 8.5 10.6875 6.96098 10.6875 5.0625C10.6875 3.16402 9.14848 1.625 7.25 1.625C5.35152 1.625 3.8125 3.16402 3.8125 5.0625C3.8125 6.96098 5.35152 8.5 7.25 8.5Z"
                fillRule="evenodd"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                clipRule="evenodd"
                d="M12.25 17.875C13.2855 17.875 14.125 17.0355 14.125 16C14.125 14.9645 13.2855 14.125 12.25 14.125C11.2145 14.125 10.375 14.9645 10.375 16C10.375 17.0355 11.2145 17.875 12.25 17.875Z"
                fillRule="evenodd"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                clipRule="evenodd"
                d="M18.5 20.375C19.5355 20.375 20.375 19.5355 20.375 18.5C20.375 17.4645 19.5355 16.625 18.5 16.625C17.4645 16.625 16.625 17.4645 16.625 18.5C16.625 19.5355 17.4645 20.375 18.5 20.375Z"
                fillRule="evenodd"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                clipRule="evenodd"
                d="M18.5 14.125C19.5355 14.125 20.375 13.2855 20.375 12.25C20.375 11.2145 19.5355 10.375 18.5 10.375C17.4645 10.375 16.625 11.2145 16.625 12.25C16.625 13.2855 17.4645 14.125 18.5 14.125Z"
                fillRule="evenodd"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="M13.8535 15.0374L16.8952 13.2124"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="M13.9902 16.6958L16.7602 17.8041"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </svg>
    );
}
export default ShareWithMe;
