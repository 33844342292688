import { fabric } from 'fabric';

/**
 * An override function for fabric.Canvas.renderCanvas.
 * @param ctx
 * @param objects
 * @param {object} options 
 * @param {boolean} options.shallowRender 
 */
export default function renderCanvas(ctx, objects, options = {}) {
    if (options.shallowRender) {
        this.shallowRender = true;
    } else {
        this.shallowRender = false;
    }
    const renderingOptions = {}
    
    if (options?.defaultRender) {
        renderingOptions.isRenderingWithTiles = false;
        renderingOptions.isRenderingDynamic = false;
    }

    // original code
    var v = this.viewportTransform, path = this.clipPath;
    this.cancelRequestedRender();
    this.calcViewportBoundaries();
    this.clearContext(ctx);
    fabric.util.setImageSmoothing(ctx, this.imageSmoothingEnabled);
    this.fire('before:render', { ctx: ctx, });
    this._renderBackground(ctx);

    ctx.save();
    //apply viewport transform once for all rendering process
    ctx.transform(v[0], v[1], v[2], v[3], v[4], v[5]);
    this._renderObjects(ctx, objects, renderingOptions);
    ctx.restore();
    if (!this.controlsAboveOverlay && this.interactive) {
        this.drawControls(ctx);
    }
    if (path) {
        path.canvas = this;
        // needed to setup a couple of variables
        path.shouldCache();
        path._transformDone = true;
        path.renderCache({ forClipping: true });
        this.drawClipPathOnCanvas(ctx);
    }
    this._renderOverlay(ctx);
    if (this.controlsAboveOverlay && this.interactive) {
        this.drawControls(ctx);
    }
    this.fire('after:render', { ctx: ctx, });
}