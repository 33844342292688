const { Point } = require('./Point');
const { SegmentPrototype } = require('./Segments');
const { Curve } = require('./Curve');
const { Utils } = require('./Utils');

export const Curveto = function() {
    let arguments$1 = arguments;

    let args = [];
    let n = arguments.length;
    for (let i = 0; i < n; i++) {
        args.push(arguments$1[i]);
    }

    if (!(this instanceof Curveto)) { // switching context of `this` to Curveto when called without `new`
        return Utils.applyToNew(Curveto, args);
    }

    if (n === 0) {
        throw new Error('Curveto constructor expects a curve, 3 points, or 6 coordinates (none provided).');
    }

    let outputArray;

    if (args[0] instanceof Curve) { // curves provided
        if (n === 1) {
            this.controlPoint1 = args[0].controlPoint1.clone();
            this.controlPoint2 = args[0].controlPoint2.clone();
            this.end = args[0].end.clone();
            return this;

        } else {
            throw new Error('Curveto constructor expects a curve, 3 points, or 6 coordinates (' + n + ' curves provided).');
        }

    } else if (typeof args[0] === 'string' || typeof args[0] === 'number') { // coordinates provided
        if (n === 6) {
            this.controlPoint1 = new Point(+args[0], +args[1]);
            this.controlPoint2 = new Point(+args[2], +args[3]);
            this.end = new Point(+args[4], +args[5]);
            return this;

        } else if (n < 6) {
            throw new Error('Curveto constructor expects a curve, 3 points, or 6 coordinates (' + n + ' coordinates provided).');

        } else { // this is a poly-bezier segment
            let segmentCoords;
            outputArray = [];
            for (let i = 0; i < n; i += 6) { // coords come in groups of six

                segmentCoords = args.slice(i, i + 6); // will send fewer than six coords if args.length not divisible by 6
                outputArray.push(Utils.applyToNew(Curveto, segmentCoords));
            }
            return outputArray;
        }

    } else { // points provided (needs to be last to also cover plain objects with x and y)
        if (n === 3) {
            this.controlPoint1 = new Point(args[0]);
            this.controlPoint2 = new Point(args[1]);
            this.end = new Point(args[2]);
            return this;

        } else if (n < 3) {
            throw new Error('Curveto constructor expects a curve, 3 points, or 6 coordinates (' + n + ' points provided).');

        } else { // this is a poly-bezier segment
            let segmentPoints;
            outputArray = [];
            for (let i = 0; i < n; i += 3) { // points come in groups of three

                segmentPoints = args.slice(i, i + 3); // will send fewer than three points if args.length is not divisible by 3
                outputArray.push(Utils.applyToNew(Curveto, segmentPoints));
            }
            return outputArray;
        }
    }
};

const curvetoPrototype = {
    serialize: function() {
        let c1 = this.controlPoint1;
        let c2 = this.controlPoint2;
        let end = this.end;
        return this.type + ' ' + c1.x + ' ' + c1.y + ' ' + c2.x + ' ' + c2.y + ' ' + end.x + ' ' + end.y;
    }
};

Object.defineProperty(curvetoPrototype, 'type', {
    configurable: true,
    enumerable: true,
    value: 'C'
});

Curveto.prototype = Utils.extend(SegmentPrototype, Curve.prototype, curvetoPrototype);