export const setIsFeedbackShown = (whiteboardId) => {
    try {
        let wbId = whiteboardId;
        if (typeof wbId !== 'string' && wbId) { 
            wbId = wbId.toString();
        }
        const savedData = JSON.parse(sessionStorage.getItem('feedback'));
        if (!savedData[wbId]) {
            savedData[wbId] = {}
        }
        savedData[wbId].isFeedbackShown = true;
        sessionStorage.setItem('feedback', JSON.stringify(savedData));
    } catch (err) {
        console.log('sessionStorage not accessible', err);
    }
}
