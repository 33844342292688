import React from 'react';
import PropTypes from 'prop-types';

const SendEmailOne = ({ width, height }) => (
    <svg
        className="commentInput--btn--icon"
        fill="none"
        height={height}
        viewBox="0 0 24 25"
        width={width}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            className="commentInput--btn--icon"
            clipRule="evenodd"
            d="M2.04812 10.7868C1.42023 10.6097 0.981126 10.044 0.965286 9.39175C0.949446 8.73955 1.36057 8.15319 1.97912 7.94579L22.0501 1.39979C22.3182 1.31241 22.6126 1.38253 22.8125 1.58137C23.0124 1.78021 23.0841 2.07427 22.9981 2.34279L16.4571 22.4238C16.2506 23.0435 15.6637 23.4556 15.0107 23.4396C14.3578 23.4235 13.7918 22.9829 13.6161 22.3538L11.3701 13.0228L2.04812 10.7868Z"
            fillRule="evenodd"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
        />
        <path
            className="commentInput--btn--icon"
            d="M22.8141 1.57983L11.3711 13.0228"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
        />
    </svg>
);

SendEmailOne.propTypes = {
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

SendEmailOne.defaultProps = {
    width: 24,
    height: 24
}

export default SendEmailOne;
