/**
 * Custom helper to set object as not dynamic.
 * @param {boolean} shouldAddTileRenderer Determines if we should add the object to the tile renderer.
 */
export function removeFromDirtyList(shouldAddTileRenderer = true) {
    this.isDynamic = false;
    try {
        if (this.canvas) {
            this.canvas.dirtyObjects.remove(this);
        }
        if (shouldAddTileRenderer) {
            this.onShapeChanged();
        }
    } catch (err) {
        console.error(err);
    }
}

/**
 * Custom helper to set object as dynamic.
 * @param {boolean} shouldRemoveFromTileRenderer Determines if we should remove the object from the tile renderer.
 */
export function addToDirtyList(shouldRemoveFromTileRenderer = true) {
    this.isDynamic = true;
    try {
        if (this.canvas) {
            this.canvas?.dirtyObjects?.append(this);
        }
        if (shouldRemoveFromTileRenderer) {
            this.canvas?.engine?.tileRenderer?.spatialIndex?.remove(this)
        }
    } catch (err) {
        console.error(err);
    }
}