
/**
 * Its calculates the distance from two points.
 * @param {{ x: number, y: number }} point1 
 * @param {{ x: number, y: number }} point2 
 * @returns {number}
 */
export const euclideanDistance = (point1, point2) => {
    const dx = point2.x - point1.x;
    const dy = point2.y - point1.y;
    return Math.sqrt(dx * dx + dy * dy);
};