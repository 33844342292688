import { Point } from './Point';
import { SegmentPrototype } from './Segments';
import { Curve } from './Curve';
import { Utils } from './Utils';

export const Moveto = function() {
    let arguments$1 = arguments;

    let args = [];
    let n = arguments.length;
    for (let i = 0; i < n; i++) {
        args.push(arguments$1[i]);
    }

    if (!(this instanceof Moveto)) { // switching context of `this` to Moveto when called without `new`
        return Utils.applyToNew(Moveto, args);
    }

    if (n === 0) {
        throw new Error('Moveto constructor expects a line, a curve, 1 point, or 2 coordinates (none provided).');
    }

    let outputArray;

    if (args[0] instanceof Curve) { // curves provided
        if (n === 1) {
            this.end = args[0].end.clone();
            return this;

        } else {
            throw new Error('Moveto constructor expects a line, a curve, 1 point, or 2 coordinates (' + n + ' curves provided).');
        }

    } else if (typeof args[0] === 'string' || typeof args[0] === 'number') { // coordinates provided
        if (n === 2) {
            this.end = new Point(+args[0], +args[1]);
            return this;

        } else if (n < 2) {
            throw new Error('Moveto constructor expects a line, a curve, 1 point, or 2 coordinates (' + n + ' coordinates provided).');

        } else { // this is a moveto-with-subsequent-poly-line segment
            let segmentCoords;
            outputArray = [];
            for (let i = 0; i < n; i += 2) { // coords come in groups of two

                segmentCoords = args.slice(i, i + 2); // will send one coord if args.length not divisible by 2
                if (i === 0) { outputArray.push(Utils.applyToNew(Moveto, segmentCoords)); }
                else { console.log('Line To...') }
            }
            return outputArray;
        }

    } else { // points provided (needs to be last to also cover plain objects with x and y)
        if (n === 1) {
            this.end = new Point(args[0]);
            return this;

        } else { // this is a moveto-with-subsequent-poly-line segment
            let segmentPoint;
            outputArray = [];
            for (let i = 0; i < n; i += 1) { // points come one by one

                segmentPoint = args[i];
                if (i === 0) { outputArray.push(new Moveto(segmentPoint)); }
                else { console.log('Line To...') }
            }
            return outputArray;
        }
    }
};

const movetoPrototype = {
    round: function(precision) {
        this.end.round(precision);
        return this;
    },

    serialize: function() {
        let end = this.end;
        return this.type + ' ' + end.x + ' ' + end.y;
    },
};

Object.defineProperty(movetoPrototype, 'start', {
    configurable: true,
    enumerable: true,
    get: function() {
        throw new Error('Illegal access. Moveto segments should not need a start property.');
    }
});

Object.defineProperty(movetoPrototype, 'type', {
    configurable: true,
    enumerable: true,
    value: 'M'
});

Moveto.prototype = Utils.extend(SegmentPrototype, movetoPrototype); // does not inherit from any other geometry object