import { fabric } from 'fabric';
import C2S from 'canvas2svg';
import {
    lineActionHandler,
    lineAnchorWrapper,
    lineControlMouseUpHandler,
    linePointPosiitonHandler,
    renderControl
} from '../helpers/lines/LineControls';
import { rotatePoint } from '../helpers/FabricMethods';
import { DEFAULT_TEXT_SIZE, LINE_TYPES } from '../helpers/Constant';
import { deepClone } from '../helpers/CommonUtils';
import { calculateMiddlePointsOfCurvedLines, convertCurvedLineVersionToV2, isCurvedLine, isCurvedLineDrawingAsStraight, getPolygonUuid } from '../helpers/lines/LineMethods';
import { CurvedLineHelper } from '../helpers/lines/curves/index';


export const CurvedLine = fabric.util.createClass(fabric.Polyline, {
    type: 'curvedLine',
    shapeType: 'curvedLine',
    perPixelTargetFind: true,

    _curves: [],
    _path: Path2D,
    arrowSize: 5,
    arrowEnabled: true,
    hasBorders: false,
    objectCaching: false,
    text: '',
    textFontSize: DEFAULT_TEXT_SIZE,
    textColor: 'rgba(60, 62, 73, 1)',
    controlPointsAutoGenerated: [],
    tempHideText: false,  // temprorary hide text for updating it
    initialize(element, options) {
        options || (options = {});
        if (!options.lineType) {
            options.lineType = LINE_TYPES.CURVED;
        }
        this.callSuper('initialize', element, options);
        this.x1 = this.points[0].x;
        this.y1 = this.points[0].y;
        this.x2 = this.points[this.points.length - 1].x;
        this.y2 = this.points[this.points.length - 1].y;
        this.isLocked = false;
        this.cachedCurvedPathMap = new Map();
        this.organizeControls();
    },

    /**
     * Renders an object on a specified context
     * We are overriding this method to use isRenderingForHitDetection flag.
     * @param {CanvasRenderingContext2D} ctx - Context to render on.
     * @param {object} renderConfig Current rendering config.
     */
    render: function(ctx, renderConfig = {}) {
        // do not render if width/height are zeros or object is not visible
        if (this.isNotVisible()) {
            return;
        }
        if (this.canvas && this.canvas.skipOffscreen && !this.group && !this.isOnScreen()) {
            return;
        }
        ctx.save();
        this._setupCompositeOperation(ctx);
        this.drawSelectionBackground(ctx);
        this.transform(ctx);
        this._setOpacity(ctx);
        this._setShadow(ctx, this);
        if (this.shouldCache()) {
            this.renderCache();
            this.drawCacheOnCanvas(ctx);
        }
        else {
            this._removeCacheCanvas();
            this.dirty = false;
            this.drawObject(ctx, false, renderConfig?.isRenderingForHitDetection);
            if (this.objectCaching && this.statefullCache) {
                this.saveState({ propertySet: 'cacheProperties' });
            }
        }
        ctx.restore();
    },
    /**
     * Execute the drawing operation for an object on a specified context
     * We are overriding this method to use isRenderingForHitDetection flag.
     * @param {CanvasRenderingContext2D} ctx - Context to render on.
     * @param forClipping
     * @param isRenderingForHitDetection
     */
    drawObject: function(ctx, forClipping, isRenderingForHitDetection = false) {
        var originalFill = this.fill, originalStroke = this.stroke;
        if (forClipping) {
            this.fill = 'black';
            this.stroke = '';
            this._setClippingProperties(ctx);
        }
        else {
            this._renderBackground(ctx);
        }
        this._render(ctx, isRenderingForHitDetection);
        this._drawClipPath(ctx, this.clipPath);
        this.fill = originalFill;
        this.stroke = originalStroke;
    },
    /**
     * Render the curve. This appears to be a direct operation on the canvas itself.
     * @param ctx
     * @param isRenderingForHitDetection
     */
    _render(ctx, isRenderingForHitDetection = false) {
        if (this.group && !this.isMockLine) {
            if (this.group.angle !== 0) {
                ctx.rotate(fabric.util.degreesToRadians(-this.group.angle));
                if (!this.originalPointsWhileRotating) {
                    this.originalPointsWhileRotating = JSON.parse(JSON.stringify(this.points));
                }
                if (this.originalPointsWhileRotating) {
                    for (const pointIndex in this.originalPointsWhileRotating) {
                        const originalPoint = this.originalPointsWhileRotating[pointIndex];
                        const centerPoint = {
                            x: this.getCenterPoint().x + this.group.getCenterPoint().x,
                            y: this.getCenterPoint().y + this.group.getCenterPoint().y,
                        }
                        const rotated = rotatePoint(
                            originalPoint.x,
                            originalPoint.y,
                            centerPoint.x,
                            centerPoint.y,
                            this.group.angle,
                        );
                        this.points[pointIndex].x = rotated.x;
                        this.points[pointIndex].y = rotated.y;
                    }
                }
                this.reattachAfterModify = true;
            }
        } else if (this.originalPointsWhileRotating) {
            this.originalPointsWhileRotating = null;
        }
        if (this.mockPoints) {
            for (const mockPoint of this.mockPoints) {
                const pointMatrix = mockPoint.calcTransformMatrix();
                const pointTransformedMatrix = fabric.util.qrDecompose(pointMatrix);
                this.points[mockPoint.mockPointIndex].x = pointTransformedMatrix.translateX;
                this.points[mockPoint.mockPointIndex].y = pointTransformedMatrix.translateY;
            }
        }
        this.drawLine(ctx, isRenderingForHitDetection);
    },
    initializePath(pathString) {
        if (!this.svgPathItem) {
            const svgNS = 'http://www.w3.org/2000/svg';
            const path = document.createElementNS(svgNS, 'path');
    
            // Set the path data (d attribute)
            path.setAttribute('d', pathString);
    
            // Create a temporary SVG element to attach the path to (also not in the DOM)
            const svg = document.createElementNS(svgNS, 'svg');
            svg.appendChild(path);

            this.svgPathItem = path;
            this.svgItem = svg;
        }
    },
    changePath(pathString) {
        this.svgPathItem.setAttribute('d', pathString);
    },
    drawLine(ctx, isRenderingForHitDetection = false) {
        ctx.lineWidth = this.strokeWidth;
        if (isRenderingForHitDetection) {
            ctx.strokeStyle = '#000';
        } else {
            ctx.strokeStyle = this.shouldShowHiglight ? '#536dff' : this.stroke;
        }
        ctx.setLineDash((this.strokeDashArray && !isRenderingForHitDetection) ? this.strokeDashArray : []);
        
        const startPoints = this._getStartPoints();
        const endPoints = this._getEndPoints();

        if (isCurvedLine(this)) {
            const actualPoints = this.points.map(point => this._getActualPoint(point));
            const curvedLineHelper = new CurvedLineHelper(actualPoints);
            const path = curvedLineHelper.generatePath(this);
            const p = new Path2D(path);

            if (!this.svgPathItem) {
                this.initializePath(path); 
            }
            if (this.pathString !== path) {
                this.pathString = path;
                this.changePath(path);
            }

            ctx.stroke(p);
        } else {
            // start drawing the line
            ctx.beginPath();
            ctx.moveTo(...startPoints);

            if (this.points.length > 2) {
                const otherPoints = this.points.slice(1, this.points.length - 1);
                for (const point of otherPoints) {
                    const actualPoint = this._getActualPoint(point);
                    ctx.lineTo(...actualPoint);
                }
                ctx.lineTo(...endPoints);
            } else {
                ctx.lineTo(...endPoints);
            }

            ctx.stroke();
        }
      
        // if arrow is enabled, draw the arrow
        if (this.arrowEnabled) {
            if (this.arrowRight) this._drawArrow(ctx, 'end');
            if (this.arrowLeft) this._drawArrow(ctx, 'start');
        }

        if (this.text && this.text.trim().length > 0 && !this.tempHideText) {
            this.renderText(ctx, isRenderingForHitDetection);
        }
    },
    renderText(ctx, isRenderingForHitDetection = false) {
        // render the text for the lines
        const middlePoint = this._getActualPoint(this.getMiddlePointForText());
        ctx.save();
        ctx.font = `${this.textFontSize}px Rubik, sans-serif`;
        const textMeasurement = ctx.measureText(this.text);
        const textXPoint = middlePoint[0] - textMeasurement.width / 2;

        const textRect = {
            left: textXPoint,
            top: middlePoint[1] - (textMeasurement.actualBoundingBoxAscent + textMeasurement.actualBoundingBoxDescent),
            width: textMeasurement.width,
            height: textMeasurement.actualBoundingBoxAscent + textMeasurement.actualBoundingBoxDescent
        }

        ctx.clearRect(textRect.left, textRect.top, textRect.width, textRect.height)
        ctx.fillStyle = this.textColor;
        ctx.fillText(this.text, textXPoint, middlePoint[1]);

        if (isRenderingForHitDetection) {
            ctx.save();
            ctx.beginPath();
            ctx.fillStyle = '#000'
            ctx.fillRect(textRect.left, textRect.top, textRect.width, textRect.height)
            ctx.restore()
        }

        ctx.restore();
    },
    /**
     * Checks if point is inside the object.
     * @param {fabric.Point} point - Point to check against.
     * @param {object} [lines] - Object returned from @method _getImageLines.
     * @param _lines
     * @param {boolean} [absolute] - Use coordinates without viewportTransform.
     * @param {boolean} [calculate] - Use coordinates of current position instead of .oCoords.
     * @returns {boolean} True if point is inside the object.
     */
    containsPoint(point, _lines, absolute, calculate) {
        const coords = deepClone(this._getCoords(absolute, calculate));

        // @overrided content start
        const minHeight = 12;
        const minWidth = 12;

        // If above and below points' distance is small, then we need to increase it.
        if (coords.tl.y - coords.bl.y < (minHeight * 2)) {
            coords.tl = new fabric.Point(coords.tl.x, coords.tl.y - minHeight);
            coords.tr = new fabric.Point(coords.tr.x, coords.tr.y - minHeight);
            coords.bl = new fabric.Point(coords.bl.x, coords.bl.y + minHeight);
            coords.br = new fabric.Point(coords.br.x, coords.bl.y + minHeight);
        }

        // If left and right points' distance is small, then we need to increase it.
        if (coords.tr.x - coords.tl.x < (minWidth * 2)) {
            coords.tl = new fabric.Point(coords.tl.x - minWidth, coords.tl.y);
            coords.tr = new fabric.Point(coords.tr.x + minWidth, coords.tr.y);
            coords.bl = new fabric.Point(coords.bl.x - minWidth, coords.bl.y);
            coords.br = new fabric.Point(coords.br.x + minWidth, coords.bl.y);
        }
        // @overrided content end
        
        const lines = _lines || this._getImageLines(coords, point);

        const xPoints = this._findCrossPoints(point, lines);
        // if xPoints is odd then point is inside the object
        return (xPoints !== 0 && xPoints % 2 === 1);
    },
    _getTextPos(options) {
        if (!this.textMeasurementCanvas) {
            this.textMeasurementCanvas = document.createElement('canvas');
            this.textMeasurementContext = this.textMeasurementCanvas.getContext('2d');  
        }

        const middlePoints = this.getMiddlePointForText(true, options);
        this.textMeasurementContext.save();
        this.textMeasurementContext.font = `${this.textFontSize}px Rubik, sans-serif`;
        const textMeasurement = this.textMeasurementContext.measureText(this.text);
        const textHeight = textMeasurement.actualBoundingBoxAscent + textMeasurement.actualBoundingBoxDescent;
        this.textMeasurementContext.restore();

        return {
            tl: {
                x: middlePoints.x - (textMeasurement.width / 2),
                y: middlePoints.y - textHeight,
            },
            tr: {
                x: middlePoints.x + (textMeasurement.width / 2),
                y: middlePoints.y - textHeight, 
            },
            bl: {
                x: middlePoints.x - (textMeasurement.width / 2),
                y: middlePoints.y, 
            },
            br: {
                x: middlePoints.x + (textMeasurement.width / 2),
                y: middlePoints.y, 
            },
            width: textMeasurement.width,
            height: textHeight
        } 
    },
    getMiddlePointForText(useInitialPosition = false, options = {}) {
        const points = options?.coordPoints || this.points;

        if (isCurvedLine(this)) {
            let path = useInitialPosition ? this.dimensionPath : this.svgPathItem;
            const svgLength = path.getTotalLength();
            const point = path.getPointAtLength(svgLength / 2);
            
            if (!useInitialPosition) {
                point.x += this.pathOffset.x;
                point.y += this.pathOffset.y;
            }
            return point;
        }
        if (points?.length % 2 === 0) {
            const startingPointIndex = Math.floor(points.length / 2) - 1;
            const endingPointIndex = Math.floor(points.length / 2);
            const point = {
                x: (points[startingPointIndex].x + points[endingPointIndex].x) / 2,
                y: (points[startingPointIndex].y + points[endingPointIndex].y) / 2,
            }
            return point
        } else {
            const middlePointIndex = Math.floor(points.length / 2);
            return {
                x: points[middlePointIndex].x,
                y: points[middlePointIndex].y,
            }
        }
    },
    _drawArrow(ctx, position) {
        const _a = this.pathOffset, x = _a.x, y = _a.y;  // path offset
        const _b = this.points[this.points.length - 1], x2 = _b.x, y2 = _b.y;  // x2, y2 end point
        const _c = this.points[0], x1 = _c.x, y1 = _c.y;  // x1, y1 start point
        
        ctx.save();
        ctx.strokeStyle = this.shouldShowHiglight ? '#536dff' : this.stroke;
        ctx.fillStyle = this.stroke;
        ctx.setLineDash([]);  // reset line dash

        // for curved arrows
        ctx.lineCap = 'round';
        ctx.lineJoin = 'round';

        if (position === 'start') {
            ctx.translate(x1 - x, y1 - y);
        } else {
            ctx.translate(x2 - x, y2 - y);
        }
        ctx.beginPath();
        ctx.rotate(this._getAngleOfLine({position}));
        ctx.moveTo(-this.arrowSize, 0);
        ctx.lineTo(-this.arrowSize, this.arrowSize);
        ctx.lineTo(0, 0);
        ctx.lineTo(-this.arrowSize, -this.arrowSize);
        ctx.lineTo(-this.arrowSize, 0);
        ctx.fill();
        ctx.stroke();
        ctx.closePath();
        ctx.restore();
    },
    /**
     * Calculates the points of the path.
     * @override
     * @param {Array} pathArray 
     */
    calcPointsOfPath(pathArray) {
        const points = [];
        let current, // current instruction
            subpathStartX = 0,
            subpathStartY = 0,
            x = 0, // current x
            y = 0, // current y
            bounds;

        for (var i = 0, len = pathArray.length; i < len; ++i) {
            current = pathArray[i];

            switch (current[0]) { // first letter

                case 'L': // lineto, absolute
                    x = current[1];
                    y = current[2];
                    bounds = [];
                    break;

                case 'M': // moveTo, absolute
                    x = current[1];
                    y = current[2];
                    subpathStartX = x;
                    subpathStartY = y;
                    bounds = [];
                    break;

                case 'C': // bezierCurveTo, absolute
                    bounds = fabric.util.getBoundsOfCurve(x, y,
                        current[1],
                        current[2],
                        current[3],
                        current[4],
                        current[5],
                        current[6]
                    );
                    x = current[5];
                    y = current[6];
                    break;

                case 'Q': // quadraticCurveTo, absolute
                    bounds = fabric.util.getBoundsOfCurve(x, y,
                        current[1],
                        current[2],
                        current[1],
                        current[2],
                        current[3],
                        current[4]
                    );
                    x = current[3];
                    y = current[4];
                    break;

                case 'z':
                case 'Z':
                    x = subpathStartX;
                    y = subpathStartY;
                    break;
            }
            bounds.forEach(function (point) {
                points.push({ x: point.x, y: point.y });
            });

            points.push({ x: x, y: y });
        }

        return points;
    },    
    _calcDimensions(options) {
        const coordPoints = options?.coordPoints ?? this.points;
        // we don't need to check exactBoundingBox because we are not using it
        // for curved lines, we get curve bounds from this.calcPointsOfPath so
        // initial points needs to be empty
        let points = isCurvedLine(this) ? [] : [...coordPoints];

        if (isCurvedLine(this)) {
            const curvedLineHelper = new CurvedLineHelper(coordPoints);
            const pathString = curvedLineHelper.generatePath(this);
            
            const svgNS = 'http://www.w3.org/2000/svg';
            const path = document.createElementNS(svgNS, 'path');
    
            // Set the path data (d attribute)
            path.setAttribute('d', pathString);
            // Create a temporary SVG element to attach the path to (also not in the DOM)
            const svg = document.createElementNS(svgNS, 'svg');
            svg.appendChild(path);
            this.dimensionPath = path;

            // get bounds from path
            const parsedPath = fabric.util.parsePath(pathString);
            const dimensionsOfPath = this.calcPointsOfPath(parsedPath);
            points.push(...dimensionsOfPath);
        }


        if (this.isTextEnabled()) {
            const textPos = this._getTextPos(options);
            points.push(
                { x: textPos.tl.x, y: textPos.tl.y}
            )
            points.push(
                { x: textPos.tr.x, y: textPos.tr.y}
            )
            points.push(
                { x: textPos.br.x, y: textPos.br.y}
            )
            points.push(
                { x: textPos.bl.x, y: textPos.bl.y}
            )
        }

        const minX = fabric.util.array.min(points, 'x') || 0,
            minY = fabric.util.array.min(points, 'y') || 0,
            maxX = fabric.util.array.max(points, 'x') || 0,
            maxY = fabric.util.array.max(points, 'y') || 0,
            width = (maxX - minX),
            height = (maxY - minY);

        return {
            left: minX,
            top: minY,
            width: width,
            height: height,
        }

    },
    // getCompleteBoundingRect() {
    //     const coordPoints = this.getPointsCoords();
    //     const options = { coordPoints };
    //
    //     const coords = this._calcDimensions(options);
    //     coords.height = Math.max(coords.height, this.strokeWidth);
    //     coords.width = Math.max(coords.width, this.strokeWidth);
    //     return coords;
    // },
    /**
     * @param {number} t - Time.
     * @param {number} p1 - Start point.
     * @param {number} p2 - Control point.
     * @param {number} p3 - End point.
     * @returns {number} The value of the quadratic bezier curve at time t.
     */
    _getQBezierValue(t, p1, p2, p3) {
        const iT = 1 - t;
        return iT * iT * p1 + 2 * iT * t * p2 + t * t * p3;
    },
    _getActualPoint(point) {
        return [point.x - this.pathOffset.x, point.y - this.pathOffset.y];
    },
    _getStartPoints() {
        return [this.points[0].x - this.pathOffset.x, this.points[0].y - this.pathOffset.y];
    },
    _getEndPoints() {
        return [this.points[this.points.length - 1].x - this.pathOffset.x, this.points[this.points.length - 1].y - this.pathOffset.y];
    },
    _getControlPoints() {
        return [this.points[1].x - this.pathOffset.x, this.points[1].y - this.pathOffset.y];
    },
    /**
     * Generates a fake control point for the quadratic bezier curve. This is used to draw the curve that goes through
     * the control points.
     * @returns 
     */
    _generateFakeControlPoint() {
        const controlPoints = this._getControlPoints();
        const startPoints = this._getStartPoints();
        const endPoints = this._getEndPoints();
        const cpX = 2*controlPoints[0] -startPoints[0]/2 - endPoints[0]/2;
        const cpY = 2*controlPoints[1] -startPoints[1]/2 -endPoints[1]/2;
        return [cpX, cpY];
    },
    _getAngleOfLine(options) {
        const startPoints = this._getStartPoints();
        const endPoints = this._getEndPoints();

        if (this.lineType === LINE_TYPES.STRAIGHT || isCurvedLineDrawingAsStraight(this)) {  // if there are less than 3 points, get angle of line using start and end points

            if (options.position === 'start') {
                const controlPointForStart = this._getActualPoint(this.points[1]);
                const dx = startPoints[0] - controlPointForStart[0];
                const dy = startPoints[1] - controlPointForStart[1];
                return Math.atan2(dy, dx);
            }
            const controlPointForEnd = this._getActualPoint(this.points[this.points.length - 2]);
            const dx = endPoints[0] - controlPointForEnd[0];
            const dy = endPoints[1] - controlPointForEnd[1];
            return Math.atan2(dy, dx);
        } else if (isCurvedLine(this) && this.svgPathItem) {
            const itemLength = this.svgPathItem.getTotalLength();
            const point = this.svgPathItem.getPointAtLength(options.position === 'start' ? 3 : itemLength - 3);
            if (options.position === 'start') {
                const dx = startPoints[0] - point.x;
                const dy = startPoints[1] - point.y;
                return Math.atan2(dy, dx);
            }
            const dx = endPoints[0] - point.x;
            const dy = endPoints[1] - point.y;
            return Math.atan2(dy, dx); 
        }
        else {  // if there are more than 3 points, get angle of line using start and fake control point
            const [cpX, cpY] = this._generateFakeControlPoint();
            const u = 1;
            const uc = 1 - u;
            if (options.position === 'start') {
                const dx = (uc * cpX + u * startPoints[0]) - (uc * endPoints[0] + u * cpX);
                const dy = (uc * cpY + u * startPoints[1]) - (uc * endPoints[1] + u * cpY);
                return Math.atan2(dy, dx);
            }

            const dx = (uc * cpX + u * endPoints[0]) - (uc * startPoints[0] + u * cpX);
            const dy = (uc * cpY + u * endPoints[1]) - (uc * startPoints[1] + u * cpY);
            return Math.atan2(dy, dx);
        }
    },
    organizeControls(shouldGeneratePath = false) {
        // generate the control points from line's points
        this.cornerSize = 14;
        this.hypoPoints = [];
        const zoom = this.canvas ? (this.canvas?.getZoom() || 1) : 1;
        const hypoPointMinDistance = ((this.cornerSize + 2) / zoom) * 2; // minimum distance should be between start and end points -> 2 is borderLeft + borderRight (1px + 1px)

        if (!this.isLineDrawing) {
            if (this.lineType === 'straight' || isCurvedLineDrawingAsStraight(this)) {
                this.points.forEach((point, index) => {
                    if (index !== this.points.length - 1) {
                        const pointerStart = new fabric.Point(this.points[index]?.x, this.points[index]?.y);
                        const pointerEnd = new fabric.Point(this.points[index + 1]?.x, this.points[index + 1]?.y);

                        // Dont add hypo points if pointerStart and pointerEnd is in the same point on canvas.
                        if (
                            Math.abs(pointerEnd.x - pointerStart.x) < hypoPointMinDistance
                            && Math.abs(pointerEnd.y - pointerStart.y) < hypoPointMinDistance
                        ) {
                            return;
                        }

                        const hypoPoint = pointerStart.midPointFrom(pointerEnd);
                        hypoPoint.actualIndex = index + 1; // We will use this actualIndex to determine hypo point exact location once mouse down event is triggered.
                        this.hypoPoints.push({ ...hypoPoint });
                    }
                });
            } else if (this.pathString || shouldGeneratePath) { // For curvedlines.
                if (shouldGeneratePath) {
                    this.generatePathString()
                }

                const pathStr = shouldGeneratePath ? this.cachedPathString : this.pathString;

                const curves = fabric.util.parsePath(pathStr);
                const newPoints = [];

                for (let i = 1; i < curves.length; i++) {
                    const curve = curves[i]
                    const startPoints = [];

                    if (i === 1) {
                        startPoints.push(...curves[0].slice(1));
                    } else {
                        const curveBefore = curves[i - 1];
                        startPoints.push(...curveBefore.slice(-2))
                    }

                    const thisPathString = `M${startPoints[0]},${startPoints[1]}C${curve.slice(1)}`;
                    const { newPoint, length } = calculateMiddlePointsOfCurvedLines(thisPathString, this);

                    if (length < hypoPointMinDistance) {
                        continue;
                    }

                    newPoint.actualIndex = i; // We will use the actualIndex to determine hypo point exact location once mouse down event is triggered.
                    newPoints.push(newPoint);
                }

                this.points.forEach((point, index) => {
                    if (index !== this.points.length - 1) {
                        this.hypoPoints.push(...newPoints);
                    }
                });
            }
        } else {
            this.hypoPoints = [];
        }

        this.setControls();
    },
    setControls() {
        const lastControl = this.points.length - 1;

        this.controls = [...this.points, ...this.hypoPoints].reduce((acc, point, index) => {
            const isHypoPoint = index > this.points.length - 1;
            const anchorIndex = index > 0 ? index - 1 : lastControl;

            acc['p' + index] = new fabric.Control({
                positionHandler: linePointPosiitonHandler,
                actionHandler: lineAnchorWrapper(anchorIndex, lineActionHandler),
                mouseUpHandler: lineControlMouseUpHandler(this),
                actionName: 'modifypolygon',
                pointIndex: index,
                hypoPointIndex: isHypoPoint ? index - this.points.length : -1,
                actualIndex: isHypoPoint ? point.actualIndex : index,
                isHypoPoint,
                render: renderControl
            });

            return acc;
        }, {});
    },
    canAddCurvePoint() {
        if ((this.arrowRight || this.arrowLeft) && this.points.length === 2) {
            return true;
        }
        return this.points.length < 3;
    },
    transformPoints(points) {
        const pathOffset = this.pathOffset;
        const matrix = this.calcTransformMatrix();
        return points
            .map(function(p){
                return new fabric.Point(
                    p.x - pathOffset.x,
                    p.y - pathOffset.y
                );
            }).map(function(p){
                return fabric.util.transformPoint(p, matrix);
            });
    },
    getPointsCoords() {
        return this.transformPoints(this.get('points'));
    },
    getHeadPoints() {
        return this.transformPoints([this.points[0], this.points[this.points.length - 1]]);
    },
    isTextEnabled() {
        return this.text && this.text.trim().length > 0
    },
    getActualCenterPoint() {
        if (this.points.length > 2) return this.points[1];

        return {
            x: (this.points[0].x + this.points[1].x) / 2,
            y: (this.points[0].y + this.points[1].y) / 2,
        }
    },
    _toSVG: function() {
        const ctx = new C2S(500, 500);

        ctx.setLineDash = function () {

        };

        this.drawLine(ctx);
        const svg = ctx.getSerializedSvg();
        const pattern = /(<svg.*?><defs\/><g>)(.*)/g;
        const fullSvgArray = pattern.exec(svg);
        const fullPath = fullSvgArray[2].replace(/<\/g><\/svg>/g, '');

        return [
            fullPath
        ];
    },
    onMouseOver() {
        if (this.selected) return;
        this.shouldShowHiglight = true;
        this.addToDirtyList(false)
        this?.canvas?.renderAll();
    },
    onMouseOut() {
        if (this.selected) return;
        if (this.shouldShowHiglight) {
            this.removeFromDirtyList()
            this.shouldShowHiglight = false;
            this?.canvas?.renderAll();
        }
    },
    onSelect() {
        if (this.shouldShowHiglight) {
            this.removeFromDirtyList()
            this.shouldShowHiglight = false;
        }
        this.selected = true;
        this.organizeControls(true);
    },
    onDeselect() {
        if (this.shouldShowHiglight) {
            this.removeFromDirtyList()
            this.shouldShowHiglight = false;
        }
        this.shouldShowHiglight = false;
        this.selected = false;
    },
    /**
     * Sets position and the pathOffset of the line.
     * Override for calling onTransformChanged if it is desired.
     * @override
     * @param {object} options The options that affects new position.
     */
    _setPositionDimensions: function(options) {
        let calcDim = this._calcDimensions(options), correctLeftTop,
            correctSize = this.exactBoundingBox ? this.strokeWidth : 0;
        this.width = calcDim.width - correctSize;
        this.height = calcDim.height - correctSize;
        if (!options.fromSVG) {
            correctLeftTop = this.translateToGivenOrigin(
                {
                    // this looks bad, but is one way to keep it optional for now.
                    x: calcDim.left - this.strokeWidth / 2 + correctSize / 2,
                    y: calcDim.top - this.strokeWidth / 2 + correctSize / 2
                },
                'left',
                'top',
                this.originX,
                this.originY
            );
        }

        if (typeof options.left === 'undefined') {
            this.left = options.fromSVG ? calcDim.left : correctLeftTop.x;
        }
        if (typeof options.top === 'undefined') {
            this.top = options.fromSVG ? calcDim.top : correctLeftTop.y;
        }
        this.pathOffset = {
            x: calcDim.left + this.width / 2 + correctSize / 2,
            y: calcDim.top + this.height / 2 + correctSize / 2
        };
        
        // if we need to fire on transform changed
        if (options?.shouldFireChanged) {
            this.onTransformChanged();
        }
    },
    /**
     * Generates a path string from the points, since we used to need the drawLine method to be called to get the latest
     * path string. With this method, that dependency is not necessary anymore.
     */
    generatePathString() {
        // this method can be called multiple times and because we don't want to calculate the path string again and again
        // we simply use the cache.
        const key = this.points.reduce((accum, curr, idx) => {
            accum += `${curr.x}:${curr.y}`
            if (idx !== this.points.length - 1) {
                accum += '-'
            }
            return accum 
        }, '')
        
        if (this.cachedCurvedPathMap.has(key)) {
            return;
        }
        // we don't need the old generated path strings
        this.cachedCurvedPathMap.clear();
        
        const actualPoints = this.points.map(point => this._getActualPoint(point));
        const curvedLineHelper = new CurvedLineHelper(actualPoints);
        const pathString = curvedLineHelper.generatePath(this);

        // save the path string
        this.cachedPathString = pathString;
        this.cachedCurvedPathMap.set(key, pathString);
    },
    convertCurvedLineVersionToV2() {
        if (this.curvedLineVersion !== 'v2') {
            convertCurvedLineVersionToV2([this]);
        }
    },
    /**
     * After performing some tasks; we need to calculate bounding box again due to seamless alignment.
     */
    calculateBoundingBoxForCurvedLine() {
        if (this.curvedLineVersion === 'v2' && this.lineType === LINE_TYPES.CURVED) {
            this._setPositionDimensions({});
            this.setCoords();
        }
    },
    attachPolygonFromUuid(instance) {
        const leftPolygon = getPolygonUuid(this, 'left');
        const rightPolygon = getPolygonUuid(this, 'right');
        if (leftPolygon === instance?.uuid) {
            this.leftPolygon = instance
        } else if (rightPolygon === instance?.uuid) {
            this.rightPolygon = instance
        }
    }
});

export const curvedLineFromObject = function (object, callback) {
    /**
     * @param instance
     */
    function _callback(instance) {
        callback && callback(instance);
    }
    let options = JSON.parse(JSON.stringify(object));
    fabric.Object._fromObject('CurvedLine', options, _callback, 'points');
};
