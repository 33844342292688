import React, {useRef, useState} from 'react';
import clsx from 'clsx';
import { BOARD_ITEM_DROPDOWN_MENU } from '../../helpers/Constant';
import { formatDate } from '../../helpers/DateHelper';
import Members from '../members/Members';
import builderLogo from '../../assets/images/Builder_logo2.svg';
import TrashIcon from '../../assets/images/trash3.svg';
import CopyIcon from '../../assets/images/copy2.svg';
import Restore from '../svgIcons/Restore';
import './BoardListingSingleContent.scss'
import useOnClickOutside from '../../hooks/UseOutsideClick';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const DROPDOWN_ICONS = { 'copy': CopyIcon, 'delete': TrashIcon };

const BoardListingSingleContent = ({ board, activePage, onBoardSelected, handleDropdownItemClick, handleRestoreBoard, userName }) => {
    const dropdownRef = useRef();
    
    const [showMoreMenu, setShowMoreMenu] = useState(false);

    const { id : userId } = useSelector(state => state?.user);

    useOnClickOutside(dropdownRef, () => setShowMoreMenu(false));

    return (
        <div className="boardCardWrapper" key={board.wbId} data-testid="boardCardWrapper">
            <div
                className={clsx('boardCard', activePage === 'TRASH' && 'trash-list')}
                onClick={() => activePage !== 'TRASH' && onBoardSelected(board.wbId)}
            >
                <div className="cardThumbnail">
                    <img src={builderLogo} alt={board.wbId} />
                </div>

                <div className="cardDetails">
                    <div className="cardDetailsLeft">
                        <strong>{board.name}</strong>
                        <span className='boardNameTooltip'>{board.name}</span>
                        <span className="boardDescription" style={{ display: 'none' }}>Project Name</span>
                    </div>
                    <div style={{ marginTop: '16px' }}>
                        <span className='sub-title'>Created on: </span>
                        <span className='sub-value'>{formatDate(new Date(board.createdAt))}</span>
                    </div>
                    <div className="owner-div">
                        <span className='sub-title'>Owner: </span>
                        <span className='sub-value'>{board.users.find(e => e.id === board.ownerId)?.name === userName ? 'You' : board.users.find(e => e.id === board.ownerId)?.name}</span>
                    </div>

                    {/* <span className="star star-filled">
                        <em className="icon-star-fill">
                        </em>
                    </span> */}

                </div>
                <div className='line'></div>
                <div className="cardBottom">
                    <Members
                        users={board.users}
                        useProfileList={false}
                        useTooltip={false}
                    />
                </div>
                {
                    activePage !== 'TRASH' ?
                        <div
                            ref={dropdownRef}
                            className={clsx('more-menu-button', { active: showMoreMenu })}
                            onClick={(e) => {
                                e.stopPropagation()
                                setShowMoreMenu(prevState => !prevState)
                            }}
                        >
                            <div className="more-icon" title="More"><em className="icon-canvas-redesign-menu"></em></div>
                            <div className={clsx('more-menu-list', { active: showMoreMenu, 'right-to-left': window.innerWidth - dropdownRef?.current?.getBoundingClientRect()?.right < 250 })}>
                                {
                                    BOARD_ITEM_DROPDOWN_MENU.map((item) => {
                                        if ((userId !== board.ownerId || board.studio_build_card_id) && !item.public) return;
                                        return (
                                            <div key={item.id} onClick={() => handleDropdownItemClick({ id: item.id, board })} className="more-menu-list-item">
                                                <img className="more-menu-list-item-icon" src={DROPDOWN_ICONS[item.id]} alt={item.label} />
                                                <span className="more-menu-list-item-label">{item.label}</span>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div> :
                        <div onClick={e => {
                            e.stopPropagation();
                            handleRestoreBoard({board})
                        }} className="restore-wrapper">
                            <Restore/>
                            <span>Restore</span>
                        </div>
                }
            </div>
        </div>
    );
}

BoardListingSingleContent.propTypes = {
    board: PropTypes.shape({
        id: PropTypes.number,
        wbId: PropTypes.string,
        name: PropTypes.string,
        ownerId: PropTypes.number,
        studio_build_card_id: PropTypes.string,
        enterpriseId: PropTypes.number,
        nimaWbId: PropTypes.string,
        createdByService: PropTypes.string,
        properties: PropTypes.any,
        publicSharePermission: PropTypes.any,
        publicShareKey: PropTypes.any,
        isDeleted: PropTypes.bool,
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
        users: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            info: PropTypes.shape({
                colorCode: PropTypes.string
            })
        })),
        pages: PropTypes.arrayOf(PropTypes.shape({
            wbPageId: PropTypes.string,
            pageName: PropTypes.string
        })),
        whiteboardActions: PropTypes.arrayOf(PropTypes.shape({
            whiteboardId: PropTypes.number,
            userId: PropTypes.number,
            updatedAt: PropTypes.string
        }))
    }),
    activePage: PropTypes.oneOf(['ALL_CANVASES', 'MY_CANVASES', 'SHARED_WITH_ME', 'TRASH']),
    onBoardSelected: PropTypes.func.isRequired,
    handleDropdownItemClick: PropTypes.func.isRequired,
    handleRestoreBoard: PropTypes.func.isRequired,
    userName: PropTypes.string
}

export default BoardListingSingleContent;