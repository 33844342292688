import axios from 'axios';
import { apiEndpoints } from '../helpers/Constant';
import { environment } from '../environment';
import { setSeenTutorialInCookie } from './AuthService';
import store from '../redux/Store';
import { isAppRunningInIframe } from '../helpers/CommonUtils';
import { handleDecodingOfGetUsersEndpoint, handleDecodingOfUserRecommendationEndpoint } from '../helpers/DecodeHelper';

/**
 * @param wbId
 */
export async function getUsers(wbId) {
    try {
        const response = await axios.get(environment.REACT_APP_API_ENDPOINT + apiEndpoints.getUsers.replace('{wbId}', wbId));
        return handleDecodingOfGetUsersEndpoint(response);
    } catch (error) {
        return [];
    }
}

/**
 * Updates user properties to set seen tutorial with the given value.
 * @param {number} userId - The user that will be updated.
 * @param {boolean} seen - If the user has seen the tutorial.
 * @default
 */
export async function setSeenTutorial(userId, seen = true) {
    try {
        let properties = {};
        // if the app is not running in an iframe, we need to update the cookie
        if (!isAppRunningInIframe()) {
            const newCookieData = setSeenTutorialInCookie(seen);
            if (!newCookieData) {
                return;
            }
            properties = newCookieData.properties;
        } else {
            // else get the data from store
            const state = store.getState();
            properties = {...state?.user?.properties}
            if (properties)
                properties.seenTutorial = seen;
        }
        
        // if no properties, do nothing
        if (!properties) {
            return;
        }
        const response = await axios.put(
            environment.REACT_APP_API_ENDPOINT + apiEndpoints.userPut.replace('{userId}', userId),
            {
                properties
            }
        );
        return response;
    } catch (error) {
        console.error('an error has occurred while resetting onboard', error);
        return error;
    }
}

/**
 * Get user mail recommendations for sharing link auto complete input.
 * @typedef {{id: number, name: string, email: string }} UserRecommendation
 * @param {string} wbId - Sharing whiteboard ID.
 * @param {string} value - Query value.
 * @returns {UserRecommendation[]} A list of User Recommendations.
 */
export async function getUserRecommendations(wbId, value) {
    try {
        const response = await axios.post(
            environment.REACT_APP_API_ENDPOINT +
        apiEndpoints.getUserRecommendations.replace('{wbId}', wbId),
            { value }
        );

        return handleDecodingOfUserRecommendationEndpoint(response.users) || [];
    } catch (error) {
        return error.message;
    }
}
