import { Utils } from './Utils';

export class Point {
    type = 1; // Point

    constructor(x, y) {
        if (!(this instanceof Point)) {
            return new Point(x, y);
        }
    
        if (typeof x === 'string') {
            let xy = x.split(x.indexOf('@') === -1 ? ' ' : '@');
            x = parseFloat(xy[0]);
            y = parseFloat(xy[1]);
    
        } else if (Object(x) === x) {
            y = x.y;
            x = x.x;
        }
    
        this.x = x === undefined ? 0 : x;
        this.y = y === undefined ? 0 : y;
    }

    clone() {
        return new Point(this);
    }

    difference(dx, dy) {
        if ((Object(dx) === dx)) {
            dy = dx.y;
            dx = dx.x;
        }

        return new Point(this.x - (dx || 0), this.y - (dy || 0));
    }

    // Returns distance between me and point `p`.
    distance(p) {
        return Utils.lengthFn(this, p);
    }

    // Returns the dot product of this point with given other point
    dot(p) {
        return p ? (this.x * p.x + this.y * p.y) : NaN;
    }

    equals(p) {
        return !!p &&
            this.x === p.x &&
            this.y === p.y;
    }

    magnitude() {
        return Math.sqrt((this.x * this.x) + (this.y * this.y)) || 0.01;
    }

    // Scales x and y such that the distance between the point and the origin (0,0) is equal to the given length.
    normalize(length) {
        let scale = (length || 1) / this.magnitude();
        return this.scale(scale, scale);
    }

    // Offset me by the specified amount.
    offset(dx, dy) {
        if ((Object(dx) === dx)) {
            dy = dx.y;
            dx = dx.x;
        }

        this.x += dx || 0;
        this.y += dy || 0;
        return this;
    }

    round(precision) {
        let f = 1; // case 0
        if (precision) {
            switch (precision) {
                case 1: f = 10; break;
                case 2: f = 100; break;
                case 3: f = 1000; break;
                default: f = Math.pow(10, precision); break;
            }
        }

        this.x = Math.round(this.x * f) / f;
        this.y = Math.round(this.y * f) / f;
        return this;
    }

    // Scale point with origin.
    scale(sx, sy, origin) {
        origin = (origin && new Point(origin)) || new Point(0, 0);
        this.x = origin.x + sx * (this.x - origin.x);
        this.y = origin.y + sy * (this.y - origin.y);
        return this;
    }
}