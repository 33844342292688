/* This file contains event handlers for object transformations,
* specifically rotating and scaling actions.
* It handles adjustments to connected lines, during
* these transformations for both shapes, images and stick notes.
*/

import { fabric } from 'fabric';
import { changeLineHeadPos } from './LineControls';
import { getAttachedPointOfLine } from './LineMethods';

/**
 * Handles rotating for a givent point of a line.
 * @param {object} targetCenterPoint 
 * @param {fabric.CurvedLine} line 
 * @param {'start'|'end'} pointPos 
 * @param {float} angle 
 * @param {object} originalLineDelta 
 */
function handleRotatingForPoint(targetCenterPoint, line, pointPos, angle, originalLineDelta) {
    let deltaXKey = 'leftDeltaX';
    let deltaYKey = 'leftDeltaY';
    if (pointPos === 'end') {
        deltaXKey = 'rightDeltaX';
        deltaYKey = 'rightDeltaY';
    }
  
    const actualPoint = {
        x: targetCenterPoint.x + originalLineDelta[deltaXKey],
        y: targetCenterPoint.y + originalLineDelta[deltaYKey],
    };
    const relativeActualPoint = {
        x: actualPoint.x - targetCenterPoint.x,
        y: actualPoint.y - targetCenterPoint.y,
    };

    const rotatedRelativeActualX = relativeActualPoint.x * Math.cos(angle) - relativeActualPoint.y * Math.sin(angle);
    const rotatedRelativeActualY = relativeActualPoint.x * Math.sin(angle) + relativeActualPoint.y * Math.cos(angle);

    const rotatedXPoint = targetCenterPoint.x + rotatedRelativeActualX;
    const rotatedYPoint = targetCenterPoint.y + rotatedRelativeActualY;

    line[deltaXKey] = rotatedXPoint - targetCenterPoint.x;
    line[deltaYKey] = rotatedYPoint - targetCenterPoint.y;


    changeLineHeadPos(
        line,
        pointPos,
        {
            x: rotatedXPoint,
            y: rotatedYPoint,
        },
    );

}

/**
 * @param e
 * @param canvas
 */
export function handleObjectRotating (e, canvas)  {
    const target = e.target;
 
    if (target.lines?.length) {
        const original = e.transform.original;

        const rectCenter = target.getCenterPoint();
        try {
            target.lines.forEach(lineUuid => {
                const lineInCanvas = canvas.getObjects().find(o => o.uuid === lineUuid);
                if (!lineInCanvas || !Object.keys(original).includes(lineUuid)) return;
                const attachedPoint = getAttachedPointOfLine(lineInCanvas, target);
                // if line is not attached to this target, skip
                if (!attachedPoint) return;
                const originalLineDelta = original[lineUuid];
  
                const rotationDiff = target.angle - original.angle;
        
  
                const angleRad = rotationDiff * (Math.PI / 180);
                handleRotatingForPoint(rectCenter, lineInCanvas, attachedPoint, angleRad, originalLineDelta);
            }
            );
        } catch (error) {
            console.error(error);
        }
    }
}


/**
 * @param originalCenter
 * @param newCenterPoint
 * @param angle
 * @param line
 * @param pointPos
 * @param originalLineDelta
 * @param widthTransform
 */
function handleObjectScalingAndResizingForPoint(
    originalCenter,
    newCenterPoint,
    angle,
    line,
    pointPos,
    originalLineDelta,
    widthTransform,
) {
    let deltaXKey = 'leftDeltaX';
    let deltaYKey = 'leftDeltaY';
    if (pointPos === 'end') {
        deltaXKey = 'rightDeltaX';
        deltaYKey = 'rightDeltaY';
    }
    const originalPlace = {
        x: originalCenter.x + originalLineDelta[deltaXKey],
        y: originalCenter.y + originalLineDelta[deltaYKey]
    }

    // find unrotated place
    const unrotatedValue = rotatePointBack(
        originalPlace.x,
        originalPlace.y,
        originalCenter.x,
        originalCenter.y,
        angle
    )
    // find original delta values for unrotated place
    const originalUnRotatedLineDelta = {
        [deltaXKey]: unrotatedValue.x - originalCenter.x,
        [deltaYKey]: unrotatedValue.y - originalCenter.y
    }

    // calculate new delta values for unrotated place
    const newDeltaValuesForUnRotated = {
        x: widthTransform.newScaledWidth * originalUnRotatedLineDelta[deltaXKey] / widthTransform.originalScaledWidth,
        y: widthTransform.newScaledHeight * originalUnRotatedLineDelta[deltaYKey] / widthTransform.originalScaledHeight
    }

    // get unrotated new position
    const newPos = {
        x: newCenterPoint.x + newDeltaValuesForUnRotated.x,
        y: newCenterPoint.y + newDeltaValuesForUnRotated.y
    }
    // rotate new position using new center point
    const newPosRotated = rotatePointBack(
        newPos.x,
        newPos.y,
        newCenterPoint.x,
        newCenterPoint.y,
        -angle
    )

    line[deltaXKey] = newPosRotated.x - newCenterPoint.x;
    line[deltaYKey] = newPosRotated.y - newCenterPoint.y;

    changeLineHeadPos(
        line,
        pointPos,
        {
            x: newCenterPoint.x + line[deltaXKey],
            y: newCenterPoint.y + line[deltaYKey]
        },
    );
}

/**
 * Rotates a point back to its original position .
 * @param {float} x - Rotated x.
 * @param {float} y - Rotated y.
 * @param {float} cx - Center of rotation x.
 * @param {float} cy - Center of rotation y.
 * @param {float} angleInDegrees - Rotation angle in degrees.
 * @returns 
 */
export function rotatePointBack(x, y, cx, cy, angleInDegrees) {
    // use radians
    const angleInRadians = -angleInDegrees * (Math.PI / 180);

    // find the original position relative to the center
    let offsetX = x - cx;
    let offsetY = y - cy;

    // rotate back the point
    let rotatedX = offsetX * Math.cos(angleInRadians) - offsetY * Math.sin(angleInRadians);
    let rotatedY = offsetX * Math.sin(angleInRadians) + offsetY * Math.cos(angleInRadians);

    // move the point back to its original position
    let originalX = rotatedX + cx;
    let originalY = rotatedY + cy;

    return { x: originalX, y: originalY };
}

export const handleObjectScalingAndResizing = (e, canvas) => {
    const target = e.target;
    if (target.lines?.length) {
        try {
            const original = e.transform.original,
                widthTransform = {
                    newScaledWidth: target.getScaledWidth(),
                    newScaledHeight: target.getScaledHeight(),
                    originalScaledWidth: original.width * original.scaleX,
                    originalScaledHeight: original.height * original.scaleY,
                },
                center = target.getCenterPoint();
  
  
            for (const lineUuid of target.lines) {
                const lineInCanvas = canvas.getObjects().find(o => o.uuid === lineUuid);
                if (!lineInCanvas || !Object.keys(original).includes(lineUuid)) continue;
                const attachedPoint = getAttachedPointOfLine(lineInCanvas, target);
                // if line is not attached to this target, skip
        
                if (!attachedPoint) continue;
  
                const originalLineDelta = original[lineUuid];
                handleObjectScalingAndResizingForPoint(
                    original.center,
                    center,
                    target.angle,
                    lineInCanvas,
                    attachedPoint,
                    originalLineDelta,
                    widthTransform,
                )
            }
        } catch(error) {
            console.error(error);
        }
    } 
}