import {cloneFabricObjectHelper} from '../FabricMethods';
import {fabric} from 'fabric';

/**
 * Renders controls and borders for the object.
 * @override
 * @param {CanvasRenderingContext2D} ctx Context to render on.
 * @param {object} [styleOverride] Properties to override the object style.
 * @param {object} [childrenOverride] Properties to override the children overrides.
 */
export function _renderControls(ctx, styleOverride, childrenOverride) {
    ctx.save();
    ctx.globalAlpha = this.isMoving ? this.borderOpacityWhenMoving : 1;
    this.callSuper('_renderControls', ctx, styleOverride);
    childrenOverride = childrenOverride || { };
    if (typeof childrenOverride.hasControls === 'undefined') {
        childrenOverride.hasControls = false;
    }
    childrenOverride.forActiveSelection = true;
    // override

    // If active selection created with lasso, then child objects shouldn't have controls and borders.
    if (this.createdWithLasso) {
        childrenOverride.hasBorders = false;
        childrenOverride.hasControls = false;
    }

    // check if this is created by lasso, if it is, do not render the controls of the child objects.
    if (!this.createdWithLasso) {  // if the active selection is created by lasso, do not render controls of child objects
        for (let i = 0, len = this._objects.length; i < len; i++) {
            this._objects[i]._renderControls(ctx, childrenOverride);
        }
    }
    ctx.restore();
}

/**
 * @override 
 */
export function toObject(propertiesToInclude) {
    var _includeDefaultValues = this.includeDefaultValues;
    var objsToObject = this._objects
        .filter(function (obj) {
            return !obj.excludeFromExport;
        })
        .map(function (obj) {
            var originalDefaults = obj.includeDefaultValues;
            obj.includeDefaultValues = _includeDefaultValues;
            // override
            // assign toObject to support our source
            obj.toObject = cloneFabricObjectHelper(obj)
            var _obj = obj.toObject(propertiesToInclude);
            obj.includeDefaultValues = originalDefaults;
            return _obj;
        });
    var obj = fabric.Object.prototype.toObject.call(this, propertiesToInclude);
    obj.objects = objsToObject;
    return obj;
}