import { fabric } from 'fabric';
import generateFrameText from './GenerateFrameText';
import { Frame } from '../../hooks/UseFrame';
import { frameMouseUpHandler } from './FrameMethods';
import { SHAPE_DEFAULTS_AS_SHAPE_PROPS } from '../Constant';

/**
 * Creates a frame with options.
 * @param {fabric.Canvas} canvas
 * @param {options} canvas
 * @param options
 */
const createFrameWithOptions = (canvas, options = {}) => {
    const shadow = new fabric.Shadow({ 
        color: '#b388ff70', 
        blur: 3,
        // affectStroke: true // if true, shadow also will be applied to stroke
    });

    const frame = new Frame({
        left: options.left,
        top: options.top,
        width: options.width,
        height: options.height,
        stroke: SHAPE_DEFAULTS_AS_SHAPE_PROPS.stroke,
        strokeWidth: 1,
        fill: options?.fill ? options.fill : 'rgba(255, 255, 255, 0.5)',
        objectCaching: false,
        shadow,
        text: options?.title || generateFrameText(canvas)
    });
    if (options.uuid) {
        frame.uuid = options.uuid;
    }

    frame.on('mouseup', frameMouseUpHandler);
    // disable rotate for frames
    frame.setControlsVisibility({
        rotate: false
    });
    canvas.add(frame);
    canvas.renderAll();
    if (frame.controls) {
        delete frame.controls.expanseRight;
        delete frame.controls.expanseLeft;
        delete frame.controls.expanseTop;
        delete frame.controls.expanseBottom;
    }
    frame.newlyCreated = true;
    return frame;
}


export default createFrameWithOptions;