import { useEffect, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

/**
 * @param {object} props
 * @param props.textAlign
 * @param props.onClickHandler
 * @param props.alignmentTooltipRef
 */
const AlignTextMenu = ({textAlign, onClickHandler, alignmentTooltipRef}) => {
    const menuRef = useRef();
    useEffect(() => {
        const menuItem = menuRef.current;
        if (!menuItem) return;
        const clientRect = menuItem.getBoundingClientRect();
        if (Math.sign(clientRect.top) === -1) {
            menuItem.classList.add('onBottom');
            alignmentTooltipRef?.current && alignmentTooltipRef.current.classList.remove('onBottom');
        } else {
            menuItem.classList.remove('onBottom');
        }
    })

    return <div className='alignmentListItems' ref={menuRef}>
        <div className={clsx('alignLeft', {
            'active': textAlign === 'left'
        })}><em className="icon-canvas-redesign-text-align-left" onClick={() => onClickHandler('left')} /></div>
        <div className={clsx('alignCenter', {
            'active': textAlign === 'center'
        })}><em className="icon-canvas-redesign-text-align-center" onClick={() => onClickHandler('center')} /></div>
        <div className={clsx('alignRight', {
            'active': textAlign === 'right'
        })}><em className="icon-canvas-redesign-text-align-right" onClick={() => onClickHandler('right')} /></div>
    </div>
}

AlignTextMenu.propTypes = {
    onClickHandler: PropTypes.func.isRequired,
    textAlign: PropTypes.oneOf(['left', 'center', 'right']),
    alignmentTooltipRef: PropTypes.shape({
        current: PropTypes.instanceOf(HTMLElement)
    })
}

export default AlignTextMenu;