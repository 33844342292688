import { fabric } from 'fabric';
import { useEffect, useRef, useState } from 'react';

import AddMessageCursor from '../assets/images/comments/add_message.svg';
import { getOrCreateCommentRefObject } from '../helpers/comments/GetOrAddCommentRef';
import { lockComments } from '../helpers/comments/LockComments';
import { removeCanvasListener } from '../helpers/CommonFunctions';
import { makeObjectUnselectable } from '../helpers/FabricMethods';
import getCommentIcon, {
    extendedColorOptions,
} from '../helpers/comments/GetCommentIcon';
import { InputFormClosingClassnames } from '../helpers/Constant';

/**
 * Comment object for comments.
 * With this specific class it is easy to handle comment actions,
 * validations etc...
 */
export const Comment = fabric.util.createClass(fabric.Group, {
    type: 'Comment',

    initialize(element, options) {
        if (!options || !options.hasOwnProperty('commentID')) {
            throw new Error('Comment object should have commentID');
        }
        this.callSuper('initialize', element, options);
    },
    colorCode: 'purple',
    padding: 0,
    hasControls: false,
    hasBorders: false,
    hoverCursor: 'pointer',
    ignoreZoom: true,
    strokeWidth: 0,
    unreadCommentCount: 0,
    getChip() {
        return this._objects[0];
    },
    getIcon() {
        return this._objects[1];
    },
    getText() {
        return this._objects[2];
    },
    getPointer() {
        return this._objects[3];
    },
    /**
     * Hides the text of comment obj.
     * After this rendering canvas is required
     * `canvas.renderAll`.
     * @param obj
     * @param canvas
     */
    hideCount(obj, canvas, readAll) {
        const scaleX = this.getChip()?.scaleX;
        const scaleY = this.getChip()?.scaleY;
        const width = obj?.width;
        const height = obj?.height;
        const left = obj?.left;
        this.getText().set('visible', false);

        if (this.getChip().width > 32)
        {
            //set position of pointer and width of comment icon when unreaded comment decreases
            this.getChip().set('width', 32);
            this.getPointer().set('left', this.getPointer().left - 6);
            this.getText().set('left', 0);
            this.getText().set('width', 0);
            //necessary to keep comment icon's original size
            obj?.set({ scaleX, scaleY, width, height, left } );
            obj?.addWithUpdate();
            //have to render to make the object clickable after changing width of it
            if(!readAll) canvas.renderAll();
            //to keep comment item's coordinate as it is
            obj?.set({ left });
            obj?.setCoords();
        }
    },
    showCount(obj, canvas, readAll) {
        let scaleX = this.getChip()?.scaleX;
        let scaleY = this.getChip()?.scaleY;
        const width = obj?.width;
        const height = obj?.height;
        const left = obj?.left;
        if (this.getChip().width === 32) {
            //set position of pointer and width of comment icon when unreaded comment increases
            this.getChip().set('width', this.getChip().width + 12);
            this.getPointer().set('left', this.getPointer().left + 6);
            this.getText().set('left', this.getChip().left + this.getChip().width - (this.unreadMessagesCount > 9 ? 18 : 13));
            this.getText().set('width', this.unreadMessagesCount > 9 ? 15 : 8);
            //necessary to keep comment icon's original size
            obj?.set({ scaleX, scaleY, width, height, left });
            obj?.addWithUpdate();
            //have to render to make the object clickable after changing width of it
            !readAll && canvas.renderAll();
            //to keep comment item's coordinate as it is
            obj?.set({ left });
            obj?.setCoords();
        }
        //when another user add comments and it's more than 9
        if (this.unreadMessagesCount > 9) {
            this.getText().set({'width': 15, text: '+9'});
        }
        this.getText().set('visible', true);
    },
    hideIcon() {
        this.getIcon().set('visible', false);
    },
    showIcon() {
        this.getIcon().set('visible', true);
    },
    changeCommentIcon(newIcon, canvas) {
        let commentIconObj = this.getIcon();
        let img = new Image();

        img.src = newIcon;

        commentIconObj.setSrc(img.src, () => {
            commentIconObj.set('dirty', true);

            if (canvas) canvas.renderAll();
            else this.canvas.renderAll();
        });
    },
    changeCountText(newText, obj, canvas, readAll) {
        this.unreadCommentCount = newText;
        if (newText === 0) {
            this.hideCount(obj, canvas, readAll);
        } else {
            this.showCount(obj, canvas, readAll);
        }

        this.getText().set('text', newText > 9 ? '+9' : newText.toString());
        this.getText().set('width', newText > 9 ? 15 : 8);
        this.getText().set('left', this.getChip().left + this.getChip().width - (newText > 9 ? 18 : 13));
    },
    // Sets the comment as resolved
    resolveComment(resolved, shouldHideResolvedComment = true, canvas, colorCode) {
    // standard "resolved" chip style
        this.getChip().set('fill', extendedColorOptions['black'].soft);
        this.getPointer().set('fill', extendedColorOptions['black'].soft);
        this.getText().set('fill', '#3C3E49');
        this.changeCommentIcon(getCommentIcon('black'));

        this.resolved = resolved;
        if (shouldHideResolvedComment) this.set('visible', !resolved);
        if (!resolved && colorCode) this.updateColor(colorCode, canvas);
    },
    updateColor(colorCode, canvas) {
        if (this.resolved) return;

        this.colorCode = colorCode;

        if (this.unreadCommentCount > 0) {
            // colored "unread" chip style
            this.getChip().set('fill', extendedColorOptions[colorCode]?.hard);
            this.getPointer().set('fill', extendedColorOptions[colorCode]?.hard);
            this.getText().set('fill', '#fff');
            this.changeCommentIcon(extendedColorOptions['white']?.image, canvas);
        } else {
            // colored "read" chip style
            this.getChip().set('fill', extendedColorOptions[colorCode]?.soft);
            this.getPointer().set('fill', extendedColorOptions[colorCode]?.soft);
            this.getText().set('fill', extendedColorOptions[colorCode]?.hard);
            this.changeCommentIcon(extendedColorOptions[colorCode]?.image, canvas);
        }
    },
    hideComment() {
        this.set('visible', false);
    },
    showComment() {
        this.set('visible', true);
    },
    decreaseCount(commentObj,canvas) {
        this.changeCountText(
            this.unreadCommentCount > 0 ? this.unreadCommentCount - 1 : 0, commentObj, canvas
        );
    },
    increaseCount(commentObj,canvas) {
        this.changeCountText(this.unreadCommentCount + 1, commentObj, canvas);
    },
});

/**
 * Changes the position of html element that given by it's reference object.
 * @param fabricObject
 * @param htmlElement
 */
export const setPositionOfHTMLElement = (fabricObject, htmlElement) => {
    if (!fabricObject || !htmlElement) return;

    const {
        lineCoords: {
            br: { x, y },
        },
    } = fabricObject;
    let yPos = y,
        xPos = x;

    if (htmlElement.dataset.for === 'wrapper') {
        yPos += 6;
        xPos -= htmlElement.offsetWidth + 6;

        if (yPos < 0) {
            yPos = 0;
        } else if (yPos + htmlElement.offsetHeight > window.innerHeight) {
            yPos = window.innerHeight - htmlElement.offsetHeight;
        }

        if (xPos < 0) {
            xPos = 0;
        } else if (xPos + htmlElement.offsetWidth > window.innerWidth) {
            xPos = window.innerWidth - htmlElement.offsetWidth;
        }
    }

    htmlElement.style.transform = `translate(${xPos}px, ${yPos}px)`; // this way is more performant
    // htmlElement.style.top = y + 'px';
    // htmlElement.style.left = x + 'px';
};

/**
 * Changes position of obj.wiredTo element in zoom and pan mode.
 * @param { fabric.Canvas } canvas
 */
export const changeCommentRefPositions = (canvas) => {
    canvas.forEachObject((obj) => {
        if (obj.type === 'commentRefWrapper') {
            setPositionOfHTMLElement(obj, obj.wiredTo.current);
        }
    });
};

/**
 * Opens comment form in the given position.
 * @param {{...MouseEvent, pointer}} e
 * @param {React.Ref} formWrapperRef
 * @param {fabric.Canvas} canvas
 * @param {{x: number, y: number}} commentInputClickedPos
 */
export const handleOpenCommentForm = (
    e,
    formWrapperRef,
    canvas,
    commentInputClickedPos
) => {
    formWrapperRef.current.style.display = 'block';
    const { x, y } = canvas.getPointer(e),
        { x: abX, y: abY } = e.pointer,
        commentFormWrapper = formWrapperRef.current,
        commentFormWrapperWidth = commentFormWrapper.offsetWidth,
        commentFormWrapperHeight = commentFormWrapper.offsetHeight,
        commentFormWrapperMargin = 20,
        zoom = canvas.getZoom();
    let xPos;
    let yPos = y,
        canvasWidth = document.querySelector('.whiteboard').offsetWidth,
        commentRefObj = getOrCreateCommentRefObject(canvas);

    if (
        abX + commentFormWrapperWidth + 50 >
        canvasWidth
    ) {
        xPos = x - commentFormWrapperWidth / zoom - commentFormWrapperMargin / zoom;
    } else {
        xPos = x + (commentFormWrapperMargin + 10) / zoom;
    }

    if (abY - (commentFormWrapperHeight + commentFormWrapperMargin) < 10) {
        yPos = y + commentFormWrapperMargin / zoom;
    } else if (
        abY + (commentFormWrapperHeight + commentFormWrapperMargin) >
    window.innerHeight
    ) {
        yPos =
      y - commentFormWrapperMargin / zoom - commentFormWrapperHeight / 2 / zoom;
    }

    commentRefObj.wiredTo = formWrapperRef;
    commentRefObj.left = xPos;
    commentRefObj.top = yPos;
    commentRefObj.setCoords();
    setPositionOfHTMLElement(commentRefObj, commentFormWrapper);
    canvas.renderAll();
    commentInputClickedPos.current = { x, y };
    const commentInput = formWrapperRef.current?.querySelector(
        '.commentInput--input'
    );
    if (commentInput) commentInput.focus();
};

/**
 * Custom hook for adding comments and controlling necessary form and inputs while adding it.
 * @param canvas
 * @param actionMode
 * @param formWrapper
 * @param commentWrapper
 * @param commentInputClickedPos
 * @param selectedComment
 * @param setSelectedComment
 */
export default function useComment(
    canvas,
    actionMode,
    formWrapper,
    commentWrapper,
    commentInputClickedPos,
    selectedComment,
    setSelectedComment,
    activeWindowState
) {
    const [commentState, setCommentState] = useState('initial');
    const prevActionMode = useRef(null);

    useEffect(() => {
        if (!canvas) return;

        if (prevActionMode.current === 'erase') {
            lockComments(canvas, false, actionMode.dragMode);
        }

        if (actionMode.commentMode) {
            removeCanvasListener(canvas);
            canvas.on('mouse:down', (e) => {
                let comments = canvas.getObjects().filter(item => item.type === 'comment');
                if (comments.filter(item => item.isOpened)[0]?.commentID !== e?.transform?.target?.commentID) {
                    setSelectedComment(null);
                }
            })
            canvas.on('mouse:up', (e) => {
                let showCommentForm = true;
                if (e.target && e.target.type === 'comment') {
                    setSelectedComment(e.target);
                    showCommentForm = false;
                    setCommentState('formOpened');
                }
                if (commentState === 'initial' && showCommentForm) {
                    handleOpenCommentForm(e, formWrapper, canvas, commentInputClickedPos, activeWindowState);
                    setCommentState('formOpened');
                } else if (commentState === 'formOpened') {
                    formWrapper.current.style.display = 'none';
                    setCommentState('initial');
                }
                if (selectedComment && e?.transform?.target?.type !== 'comment') {
                    commentWrapper.current.style.display = 'none';
                    setSelectedComment(null);
                }
            });
        } else if (actionMode.selectMode) {
            prevActionMode.current = 'select';
        } else if (actionMode.dragMode) {
            prevActionMode.current = 'pan';
        } else if (actionMode.eraseMode) {
            prevActionMode.current = 'erase';
        }

        const clickOutOfForm = (e) => {
            const clickedFormsClasslist = e?.target?.classList?.value.toString().trim();
            if (commentState === 'formOpened') {
                if (!InputFormClosingClassnames.includes(clickedFormsClasslist)) {
                    setTimeout(() => {
                        formWrapper.current.style.display = 'none';
                        setCommentState('initial');
                    }, 100)
                }
            }
        }

        window.addEventListener('mousedown', clickOutOfForm);

        return () => {
            window.removeEventListener('mousedown', clickOutOfForm);
        };
    }, [
        canvas,
        actionMode,
        formWrapper,
        commentWrapper,
        commentState,
        selectedComment,
        setSelectedComment,
        commentInputClickedPos,
        activeWindowState
    ]);

    // listen for open-comment-form event to open comment form
    useEffect(() => {
        if (canvas) {
            const openCommentFormListener = (e) => {
                handleOpenCommentForm(e, formWrapper, canvas, commentInputClickedPos, activeWindowState);
            };
            canvas.on('open-comment-form', openCommentFormListener);

            return () => {
                canvas.off('open-comment-form', openCommentFormListener);
            };
        }
    }, [canvas, commentInputClickedPos, formWrapper, activeWindowState]);

    // listen for commentCreated event to change action mode to previous one
    useEffect(() => {
        if (canvas) {
            const commentCreatedListener = () => {
                if (prevActionMode.current) {
                    canvas.fire('change-action-mode', prevActionMode.current);
                    prevActionMode.current = null;
                }
            };
            document.addEventListener('commentCreated', commentCreatedListener);

            return () => {
                document.removeEventListener('commentCreated', commentCreatedListener);
            };
        }
    }, [canvas]);
    return [commentState, setCommentState];
}

/**
 * Change the cursor to comment icon.
 * @param canvas
 */
export const changeCursorInCommentMode = (canvas) => {
    const cursorImage = new Image();

    cursorImage.src = AddMessageCursor;

    cursorImage.onload = () => {
        canvas.defaultCursor = `url(${cursorImage.src}), auto`;
        canvas.selection = false;
        canvas.forEachObject(function (object) {
            // disable selection for objects but comments
            if (object.type !== 'comment') {
                makeObjectUnselectable(object);
            }
        });
    };
};
