import axios from 'axios';
import { apiEndpoints } from '../helpers/Constant';
import { environment } from '../environment';
import {  getCookie, deleteCookie, setCookie } from '../services/CookieService';
import store from '../redux/Store';
import { parseDataToObject } from '../helpers/CommonUtils';
let authToken;
let wbID;
/**
 * @param values
 */
export async function register(values) {
    try {
        let payLoad = {
            'name': values.name.trim(),
            'phoneNumber': values.phoneNumber,
            'email': values.email.trim(),
            'password': values.password,
        };
        const response = await axios.post(environment.REACT_APP_API_ENDPOINT + apiEndpoints.registerUser, payLoad);
        return response;
    } catch (error) {
        return {
            'errorCode': error.code,
            'error': error.response.data.message
        };
    }
}
/**
 * @param email
 */
export async function requestSendEmailToResetPassword(email) {
    try {
        let payLoad = {
            email: email,
        };
        const response = await axios.post(
            environment.REACT_APP_API_ENDPOINT + apiEndpoints.forgotPassword,
            payLoad
        );
        return response;
    } catch (error) {
        return {
            errorCode: error.code,
            error: error.response.data.message,
        };
    }
}

/**
 * @param email
 * @param token
 */
export async function checkIfTheResetPasswordLinkIsValidate(email, token) {
    try {
        const response = await axios.get(
            environment.REACT_APP_API_ENDPOINT +
        apiEndpoints.emailPasswordChange +
        '?token=' +
        token +
        '&email=' +
        email
        );
        return response;
    } catch (error) {
        return {
            errorCode: error.code,
            error: error.response.data.message,
        };
    }
}
/**
 * @param email
 * @param password
 * @param token
 */
export async function resetPassword(email, password, token) {
    try {
        let payLoad = {
            email: email,
            password: password,
            token: token,
        };
        const response = await axios.post(
            environment.REACT_APP_API_ENDPOINT + apiEndpoints.resetPassword,
            payLoad
        );
        return response;
    } catch (error) {
        return {
            errorCode: error.code,
            error: error.response.data.message,
        };
    }
}

/**
 * @param values
 */
export async function loginViaEmailPassword(values) {
    try {
        let payLoad = {
            'email': values.email,
            'password': values.password,
        };
        const response = await axios.post(environment.REACT_APP_API_ENDPOINT + apiEndpoints.loginWithEmailPassword, payLoad);
        return response;
    } catch (error) {
        return {
            'errorCode': error.code,
            'error': error.response.data.message
        };
    }
}

/**
 * @param token
 */
export async function loginWithGoogle(token) {
    try {
        let payLoad = {
            'accessToken': token,
        };
        const response = await axios.post(environment.REACT_APP_API_ENDPOINT + apiEndpoints.loginWithGoogle, payLoad);
        return response;
    } catch (error) {
        return {
            'errorCode': error.code,
            'error': error.response.data.message
        };
    }
}

export const loggedInUser = () => {
    const userCookieData = getCookie(environment.USER_COOKIE);
    if (userCookieData) {
        return JSON.parse(userCookieData[1]);
    }
    return null;
}
export const logout = () => {
    deleteCookie(environment.USER_COOKIE);
    store.dispatch({ type: 'user/setNull', payload: {}});
}

/**
 * @param values
 */
export async function builderLoginAutomated(values) {
    try {
        let payLoad = {
            'email': values.email,
            'name': values.name
        };
        const response = await axios.post(environment.REACT_APP_API_ENDPOINT + apiEndpoints.automatedLogin, payLoad);
        return response;
    } catch (error) {
        return {
            'errorCode': error.code,
            'error': error.response.data.message
        };
    }

}

/**
 * @param values
 */
export async function enterpriseLoginAutomated(values) {
    try {
        let payLoad = {
            'email': values.email,
            'name': values.name,
            'permission': values.permission
        };
        const response = await axios.post(environment.REACT_APP_API_ENDPOINT + apiEndpoints.automatedEnterpriseLogin, payLoad);
        return response;
    } catch (error) {
        return {
            'errorCode': error.code,
            'error': error.response.data.message
        };
    }

}

export const setAuthToken = (value) => {
    authToken = value;
}

export const getAuthToken = () => {
    return  authToken;
}

export const setWbId = (value) => {
    wbID = value;
}

export const getWbId = () => {
    return  wbID;
}

/**
 * Set seen tutorial in cookie.
 * @param {boolean} seen - If the user has seen the tutorial.
 * @returns 
 */
export const setSeenTutorialInCookie = (seen = true) => {
    try {
        const loggedUser = loggedInUser();
        if (loggedUser) {
            if (loggedUser.properties) {
                loggedUser.properties = parseDataToObject(loggedUser?.properties);
                loggedUser.properties.seenTutorial = seen; 
            }
            const propertiesPayload = {
                ...loggedUser.properties,
                seenTutorial: seen
            }
            const stringfiedProperties = JSON.stringify(propertiesPayload);
            const newCookieData = {
                ...loggedUser,
                properties: stringfiedProperties
            }
            setCookie(environment.USER_COOKIE, JSON.stringify(newCookieData));
            return newCookieData;
        }
    } catch (err) {
        return null;
    }

}

