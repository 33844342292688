import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { SUBTOOLBAR_FILTER_ITEMS } from '../../../helpers/Constant';

const FiltersMenu = ({
    onClickHandler,
    filters,
    tooltipRef
}) => {
    const menuRef = useRef();

    useEffect(() => {
        const menuItem = menuRef.current;
        if (!menuItem) return;
        const clientRect = menuItem.getBoundingClientRect();
        if (Math.sign(clientRect.top) === -1) {
            menuItem.classList.add('onBottom');
            tooltipRef?.current && tooltipRef.current.classList.remove('onBottom');
        } else {
            menuItem.classList.remove('onBottom');
        }
    });

    return (
        <div className="filters-menu" ref={menuRef}>
            {[...filters.items].map(([type, { uuids, count }]) => (
                <div
                    key={uuids.join(', ')}
                    className="menu-item"
                    onClick={() => onClickHandler({ type, uuids, count })}
                >
                    <div className="item-left">
                        {SUBTOOLBAR_FILTER_ITEMS[type].icon}
                        <span>{SUBTOOLBAR_FILTER_ITEMS[type].text}</span>
                    </div>

                    <small>{count}</small>
                </div>
            ))}
        </div>
    )
}

FiltersMenu.propTypes = {
    onClickHandler: PropTypes.func.isRequired,
    tooltipRef: PropTypes.shape({
        current: PropTypes.instanceOf(HTMLElement)
    }),
    filters: PropTypes.shape({
        selectedObjectsCount: PropTypes.number,
        items: PropTypes.object
    })
}

export default FiltersMenu;