/**
 * Edits the text box object from a textarea.
 * Overridden because we need to call onShapeChanged after the process completed. 
 */
export function updateFromTextArea() {
    if (!this.hiddenTextarea) {
        return;
    }
    this.cursorOffsetCache = { };
    this.text = this.hiddenTextarea.value;
    if (this._shouldClearDimensionCache()) {
        this.initDimensions();
        this.setCoords();
    }
    var newSelection = this.fromStringToGraphemeSelection(
        this.hiddenTextarea.selectionStart, this.hiddenTextarea.selectionEnd, this.hiddenTextarea.value);
    this.selectionEnd = this.selectionStart = newSelection.selectionEnd;
    if (!this.inCompositionMode) {
        this.selectionStart = newSelection.selectionStart;
    }
    this.updateTextareaPosition();
    
    // override
    if (this.tableObject) {
        this.tableObject.onShapeChanged()
    } else {
        this.onShapeChanged();
    }
}