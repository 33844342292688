/**
 *
 */
const DeleteIcon = () => {
    return (
        <svg
            fill="none"
            height="20"
            viewBox="0 0 20 20"
            width="20"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.25 3.75h17.5"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                clipRule="evenodd"
                d="M11.875 1.25h-3.75c-.69 0-1.25.56-1.25 1.25v1.25h6.25V2.5c0-.69-.56-1.25-1.25-1.25z"
                fillRule="evenodd"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="M8.125 14.375v-6.25M11.875 14.375v-6.25"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                clipRule="evenodd"
                d="M15.72 17.603a1.25 1.25 0 01-1.245 1.147h-8.95a1.25 1.25 0 01-1.245-1.147L3.125 3.75h13.75l-1.154 13.853z"
                fillRule="evenodd"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </svg>
    );
}

export default DeleteIcon;