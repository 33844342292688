import { useEffect, useState } from 'react'
import store from '../redux/Store';
import { getLocalStorage, setLocalStorage } from '../services/CookieService';

/**
 * Custom hook for hiding comments on left drawer's comment tab.
 * @param canvas
 * @param hideResolvedOption
 * @param defaultValue
 */
const useHideComments = (canvas, hideResolvedOption, boardOptions, whiteBoardSlugId) => {
    const [hideComments, setHideComments] = useState(false);

    useEffect(() => {
        if (canvas) {
            const comments = canvas.getObjects().filter(o => o.type === 'comment');
            for (const comment of comments) {
                if (hideComments) {
                    comment.hideComment();
                    canvas.fire('comment-hide', {comment: comment});
                } else if (comment.resolved) {
                    if (!hideResolvedOption) comment.showComment();
                } else {
                    comment.showComment();
                }
            }
            if (getLocalStorage('bboard_options')) {
                const options = JSON.parse(getLocalStorage('bboard_options'));
                options.hideComments = { [whiteBoardSlugId]: boardOptions.current.hideComments[whiteBoardSlugId] ?? false }
                setLocalStorage('bboard_options', JSON.stringify(options));
            }
            store.dispatch({
                type: 'board/changeHideComments',
                payload: hideComments
            });
            canvas.renderAll();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canvas, hideComments, whiteBoardSlugId]);

    useEffect(() => {
        if (boardOptions?.current?.hideComments[whiteBoardSlugId]) setHideComments(boardOptions.current.hideComments[whiteBoardSlugId] ?? false);
    }, [whiteBoardSlugId, boardOptions])

    return [
        hideComments,
        (value) => {
            setHideComments(value)
            boardOptions.current.hideComments[whiteBoardSlugId] = value
        }
    ]
}

export default useHideComments;