import {useEffect, useState} from 'react';
import eventEmitter from '../helpers/EventEmitter';
import {useSelector} from 'react-redux';
import {FILTER_OPTIONS_REQUESTS} from '../helpers/Constant';

const useCatchComment = (socketRef,comments,setComments,setUnreadCommentCount,filter,searchKeyword,getCommentsAsync, setCurrentMarkIndex) => {
    const [unreadCommentCountList, setUnreadCommentCountList] = useState(false);
    
    const activePageId = useSelector(state => state?.rightDrawer?.activePage?.id);
    const activeWindowState = useSelector((state) => state?.modal?.activeWindow);
    const { id } = useSelector(state => state?.user);
    
    const handleUpdateUnreadCount = () => {
        let params = { pageId: activePageId }
        if (searchKeyword !== '') params = { ...params, search: searchKeyword }
        if (filter !== 'Show all') params = { ...params, filter: FILTER_OPTIONS_REQUESTS[filter] }
        
        socketRef.current.emit('unreadCommentStatus', { ...params }, (data) => {
            if (!data?.emitData) return;
            setUnreadCommentCountList(data.emitData.unreadCount > 0);
            setUnreadCommentCount(data.emitData.canvasTotalUnreadCount);
        })
    }
    
    const handleUpdateExistingComment = (oldComments, updatedComment) => {
        const mutableComments = oldComments?.map(item => {
            return item.uuid === updatedComment.uuid ? updatedComment : item
        })
        return [...mutableComments]
    }
    
    const checkSearchIncludingComment = (data, isMention) => {
        if(searchKeyword === '' && !isMention) return true;
        let isCommentIncluding = data?.content?.includes(isMention ? '@' : searchKeyword);
        if(isCommentIncluding) return true
        
        let repliesContent = data.replies.map(item => item.content).filter(f => f.includes(isMention ? '@' : searchKeyword));
        isCommentIncluding = repliesContent.length > 0;
        return isCommentIncluding;
    }
    
    const checkFilterIncludingComment = (data) => {
        if(filter === 'Show all') return true;
        else if(filter === 'Show resolved') return data.resolved
        else if(filter === 'Show unread') return checkHasUnread(data);
        else return filter === 'Mentions' && checkSearchIncludingComment(data, true);
    }
    
    const checkHasUnread = (data) => {
        return (data?.userComment?.find(item => item.userId === id)?.readAt === null || data?.replies?.map(a => a.userComment)?.map(c => c.find(f => f.userId === id))?.map(i => i?.readAt)?.includes(null));
    }
    
    const catchAddComment = (data) => {
        const newComment = data.updatedThread[0];
        
        if(checkSearchIncludingComment(newComment, false) && checkFilterIncludingComment(newComment)) {
            setComments(oldComments => {
                const mutableComments = oldComments?.filter(item => item.uuid !== data.updatedThread[0].uuid) || [];
                return [{...data.updatedThread[0]}, ...mutableComments]
            });
        }
        handleUpdateUnreadCount();
    }
    
    const catchUpdateComment = (data) => {
        const updatedComment = data.updatedThread[0];
        
        if(checkSearchIncludingComment(updatedComment, false) && checkFilterIncludingComment(updatedComment)) {
            
            setComments(oldComments => {
                let isCommentExisted = oldComments.find(item => item.uuid === updatedComment.uuid);
                const mutableComments = oldComments.map(item => {
                    return item.uuid === updatedComment.uuid ? updatedComment : item
                })
                if(isCommentExisted) return handleUpdateExistingComment(oldComments, updatedComment)
                else return [...mutableComments, updatedComment].sort((a, b) => {
                    return new Date(b.createdAt) - new Date(a.createdAt)
                })
            })
        }
        else{
            setCurrentMarkIndex(0);
            setComments(oldComments => {
                return oldComments.filter(item => item.uuid !== updatedComment.uuid);
            })
        }
    }
    
    const catchResolveComment = (data) => {
        const updatedComment = data.updatedThread[0];
        
        //if there is no search, if search
        setComments(oldComments => {
            if(filter === 'Show resolved'){
                const isCommentExisted = oldComments.find(f => f.uuid === updatedComment.uuid);
                const mutableComments = oldComments.map(item => {
                    return item.uuid === updatedComment.uuid ? updatedComment : item
                })
                //if comments includes response then filter it, if not add it
                //need to check if it is included on searched ones
                if(checkSearchIncludingComment(updatedComment)){
                    
                    //if comments doesn't include response then add it, if it is then filter it and sort it with date
                    if(!isCommentExisted) return [updatedComment, ...mutableComments].sort((a,b) => {
                        return new Date(b.createdAt) - new Date(a.createdAt)
                    })
                    else if(!updatedComment.resolved) {
                        setCurrentMarkIndex(0);
                        return mutableComments.filter(item => item.uuid !== updatedComment.uuid);
                    }
                    return [...mutableComments];
                }
                //there is no need to this line?
                return [...mutableComments]
            }
            else return handleUpdateExistingComment(oldComments,updatedComment);
        })
    }
    
    const catchReadUnreadComment = (data) => {
        const updatedComment = data.updatedThread[0];
        
        setComments(oldComments => {
            if(filter === 'Show unread'){
                const isCommentExisted = oldComments.find(f => f.uuid === updatedComment.uuid);
                const mutableComments = oldComments.map(item => {
                    return item.uuid === updatedComment.uuid ? updatedComment : item
                })
                //need to check if it is included on searched ones
                if(checkSearchIncludingComment(updatedComment)){
                    //if comments doesn't include response then add it, if it is then filter it
                    if(!isCommentExisted && checkHasUnread(updatedComment)) return [updatedComment, ...mutableComments].sort((a,b) => {
                        return new Date(b.createdAt) - new Date(a.createdAt)
                    })
                    else if(isCommentExisted && !checkHasUnread(updatedComment)) {
                        setCurrentMarkIndex(0);
                        return mutableComments.filter(item => item.uuid !== updatedComment.uuid);
                    }
                    return [...mutableComments]
                }
                return [...mutableComments]
            }
            else return handleUpdateExistingComment(oldComments,updatedComment);
        });
        handleUpdateUnreadCount();
    }
    
    const handleDeleteComment = (data) => {
        if(data.parentUuid){
            setComments(oldComments => (oldComments.map(comment => {
                if (comment.uuid === data.parentUuid) {
                    comment.replies = comment.replies.filter(reply => reply.uuid !== data.uuid);
                }
                return comment;
            })));
        }
        else{
            setCurrentMarkIndex(0);
            setComments(oldComments => oldComments.filter(item => item.uuid !== data.uuid))
        }
    }
    
    useEffect(()=>{
        
        const addCommentListener = (data) => {
            if(!data?.updatedThread) return;
            catchAddComment(data);
        }
        
        const updateCommentListener = (data) => {
            if(!data.updatedThread) return;
            catchUpdateComment(data);
        }
        
        const resolveListener = (data) => {
            if(!data?.updatedThread) return;
            catchResolveComment(data);
        }
        
        const readUnreadListener = (data) => {
            if(!data?.updatedThread) return;
            catchReadUnreadComment(data);
        }
        
        const markAllReadListener = () => getCommentsAsync();
        
        const deleteListener = (data) => handleDeleteComment(data);
        
        eventEmitter.on('addComment', addCommentListener);
        eventEmitter.on('replyComment', addCommentListener);
        eventEmitter.on('addCommentOwner', addCommentListener);
        eventEmitter.on('updateComment', updateCommentListener);
        eventEmitter.on('resolveComment', resolveListener);
        eventEmitter.on('readComment', readUnreadListener);
        eventEmitter.on('markAllRead', markAllReadListener);
        eventEmitter.on('deleteComment', deleteListener);

        return () => {
            eventEmitter.off('addComment', addCommentListener);
            eventEmitter.off('replyComment', addCommentListener);
            eventEmitter.off('addCommentOwner', addCommentListener);
            eventEmitter.off('updateComment', updateCommentListener);
            eventEmitter.off('resolveComment', resolveListener);
            eventEmitter.off('readComment', readUnreadListener);
            eventEmitter.off('markAllRead', markAllReadListener);
            eventEmitter.off('deleteComment', deleteListener);
        }
    },[activeWindowState, searchKeyword, filter])
    
    return [
        unreadCommentCountList,
        setUnreadCommentCountList
    ]
}

export default useCatchComment;