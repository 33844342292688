import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import './HeaderSearch.scss';
import { centerToObjectWithAnimation } from '../../../helpers/FabricMethods';
import {
    createTheHighlight,
    clearAllTheHighlight,
    renderAllTheHighlight,
} from '../../../helpers/CreateTextBorder';
import {
    MdKeyboardArrowDown,
    MdKeyboardArrowUp,
    MdClear,
} from 'react-icons/md';
import { ClipLoader } from 'react-spinners';
import { KEYS } from '../../../helpers/shortcuts/Keys';
import eventEmitter from '../../../helpers/EventEmitter';
import {EMITTER_TYPES, MODAL_WINDOWS} from '../../../helpers/Constant';
import SearchIcon from '../../svgIcons/canvasRedesign/IconSearch';
import {useDispatch, useSelector} from 'react-redux';
import useOutsidePopupClick from '../../../hooks/UseOutsidePopupClick';

const HeaderSearch = ({
    canvas,
    handleToggleCommentDrawer
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [currentFocus, setCurrentFocus] = useState(null);
    const [result, setResult] = useState([]);
    const inputRef = useRef(null);
    const componentRef = useRef(null);
    const highlightObjects = useRef(new Map());
    const currentKeyword = useRef('');
    const expandedState = useSelector(state => state?.modal?.activeWindow)
    const dispatch = useDispatch()

    const toggleExpanded = (expand = undefined) => {
        handleToggleCommentDrawer({ shouldShow: false });
        let shouldActivate = false;
        if (expand) {
            shouldActivate = true;
        }
        dispatch({
            type: 'modal/toggleSearch',
            payload: {
                shouldShow: shouldActivate
            }
        })
    };

    const onChangeKeyword = (event) => {
        const { value: newValue } = event.target;
        setKeyword(newValue);
    };

    useEffect(() => {
        const openSearchTextHandler = () => {
            toggleExpanded(true);
        }
        if (eventEmitter) {
            eventEmitter.on(EMITTER_TYPES.OPEN_SEARCH_TEXT, openSearchTextHandler);
        }

    }, []);

    const centerToObjectAndHighlightTheBorder = (canvas, currentObject) => {
        highlightObjects.current.forEach((value, key) => {
            if (key === currentObject.uuid) {
                if (value.highlightNatively) {
                    value.instance.highlightTextBorderStroke = 'red';
                    value.instance.highlightTextBorderWidth = 1;
                } else {
                    value.set({
                        stroke: 'red',
                        strokeWidth: 1,
                    });

                    if (currentObject.type === 'table' && Array.isArray(value.cellIds)) {
                        currentObject.setSearchedCells(value.cellIds);
                    }
                }
            } else {
                if (value.highlightNatively) {
                    value.instance.highlightTextBorderStroke = 'black';
                    value.instance.highlightTextBorderWidth = 0.2;
                } else {
                    value.set({ stroke: 'black', strokeWidth: 0.2 });

                    if (Array.isArray(value.cellIds) && value.cellIds.length > 0) {
                        const tableObject = canvas.getObjects().find((obj) => obj.uuid === value.tableUuid);
                        if (tableObject) {
                            tableObject.clearSearchedCells();
                        }
                    }
                }
            }
        });
        canvas.renderAll();
        centerToObjectWithAnimation(canvas, currentObject);
    };

    const changeFocusResult = (isNext = true) => {
        if (result.length === 0) return;
        //if we have only one result, so we just need to re-focus on it
        if (result.length === 1) {
            setCurrentFocus(0);
            centerToObjectAndHighlightTheBorder(canvas, result[0]);
            return;
        }
        //otherwise, move to the next one
        if (isNext) {
            if (currentFocus === null || currentFocus === result.length - 1) {
                setCurrentFocus(0);
            } else {
                setCurrentFocus(currentFocus + 1);
            }
            return;
        }
        if (currentFocus === null || currentFocus === 0)
            setCurrentFocus(result.length - 1);
        else {
            setCurrentFocus(currentFocus - 1);
        }
        return;
    };

    useEffect(() => {
        if (currentFocus === null || result.length === 0) return;
        const currentObject = result[currentFocus];
        centerToObjectAndHighlightTheBorder(canvas, currentObject);
    }, [currentFocus]);

    const checkIfTheObjectMatchTheKeyword = (object, keyword) => {
        if (
            (object.text?.toLowerCase()?.includes(keyword.toLowerCase().trim())) ||
      (object.title?.toLowerCase()?.includes(keyword.toLowerCase().trim()))
        ) {
            return true;
        }
        let isContain = false;
        if (typeof object.getObjects === 'function') {
            object.getObjects().forEach((childObject) => {
                if (
                    childObject.text &&
          childObject.text.toLowerCase().includes(keyword.toLowerCase().trim())
                ) {
                    isContain = true;
                    return;
                }
            });
        } else if (object.type === 'table') {
            isContain = object.cells.some((cell) => {
                return cell.text?.toLowerCase()?.includes(keyword.toLowerCase().trim())
            })
        }
        return isContain;
    };

    const performSearch = (keyword) => {
        setIsLoading(true);
        currentKeyword.current = keyword;
        clearAllTheHighlight(canvas, highlightObjects.current);
        highlightObjects.current = new Map();
        setCurrentFocus(null);
        if (keyword.trim().length === 0) {
            setIsLoading(false);
            setResult([]);
            return;
        }
        let result = [];
        canvas.getObjects().forEach((object) => {
            if (checkIfTheObjectMatchTheKeyword(object, keyword)) {
                result.push(object);
            }
            highlightObjects.current = new Map([
                ...highlightObjects.current,
                ...createTheHighlight(object, keyword),
            ]);
        });

        setIsLoading(false);
        setResult(result);
        renderAllTheHighlight(canvas, highlightObjects.current);
    };

    useOutsidePopupClick(componentRef, expandedState, MODAL_WINDOWS.SEARCH, () => toggleExpanded(false));

    useEffect(() => {
        if (expandedState === MODAL_WINDOWS.SEARCH) {
            inputRef.current.focus();
            performSearch(keyword);
        } else {
            canvas && clearAllTheHighlight(canvas, highlightObjects.current);
        }
    }, [expandedState]);

    useEffect(() => {
        if (expandedState === MODAL_WINDOWS.SEARCH) {
            const keyDownListener = async (event) => {
                if (KEYS.ENTER === event.key) {
                    if (keyword === currentKeyword.current) {
                        changeFocusResult();
                    } else performSearch(keyword);
                }
                if (event[KEYS.SHIFT]) {
                    if (KEYS.ENTER === event.key) {
                        changeFocusResult(false);
                    }
                }
                if (event[KEYS.CTRL_OR_CMD]) {
                    event.preventDefault();
                    if (KEYS.F.includes(event.key)) {
                        toggleExpanded();
                    }
                }
            };
            document.addEventListener('keydown', keyDownListener);
            return () => {
                document.removeEventListener('keydown', keyDownListener);
            };
        }
    }, [expandedState, keyword, currentFocus]);

    useEffect(() => {
        if (result.length > 0) {
            centerToObjectAndHighlightTheBorder(canvas, result[0]);
            setCurrentFocus(0);
        }
    }, [result]);

    return (
        <div className="container" data-testid="header-search" ref={componentRef}>
            <div className={clsx('expandable-container', { 'minimized': expandedState !== MODAL_WINDOWS.SEARCH })}>
                {expandedState !== MODAL_WINDOWS.SEARCH ? <div
                    className="icon-container"
                    data-testid="search-icon"
                    onClick={toggleExpanded}
                >
                    <SearchIcon />
                </div> : null}
                {expandedState === MODAL_WINDOWS.SEARCH ? <div className="control-result">
                    {isLoading ? (
                        <div className="text-result">
                            <ClipLoader color="#bfbfbf" loading={isLoading} size={10} />
                        </div>
                    ) : (
                        <span className="text-result" data-testid="number-result">
                            {`${currentFocus === null ? '0' : `${currentFocus + 1}`}`}/
                            {result.length}
                        </span>
                    )}

                    <div className="divider" />
                    <div className="icon-up" onClick={() => changeFocusResult(false)}>
                        <MdKeyboardArrowUp />
                    </div>
                    <div className="icon-down">
                        <MdKeyboardArrowDown onClick={changeFocusResult} />
                    </div>
                    <div className="icon-close" onClick={() => toggleExpanded(false)}>
                        <MdClear />
                    </div>
                </div> : null}
                {expandedState === MODAL_WINDOWS.SEARCH ? <input
                    data-testid="search-input"
                    onChange={onChangeKeyword}
                    placeholder="Search"
                    ref={inputRef}
                    type="text"
                    value={keyword}
                /> : null}
            </div>
        </div>
    );
};

HeaderSearch.propTypes = {
    canvas: PropTypes.object,
    handleToggleCommentDrawer: PropTypes.func.isRequired,
}

export default HeaderSearch;
