
import axios from 'axios';
import { apiEndpoints } from '../helpers/Constant';
import { environment } from '../environment';


/**
 * @param wbId
 * @param text
 * @param rating
 * @param images
 */
export async function submitFeedback(wbId, text, rating, images) {
    try {
        const formData = new FormData();
        formData.append('rating', rating);
        formData.append('text', text);
        for (const image of images) {
            formData.append(`images`, image);
        } 
        const response = await axios.post(
            environment.REACT_APP_API_ENDPOINT + apiEndpoints.submitFeedback.replace('{wbId}', wbId),
            formData 
        );
        return response;
    } catch (error) {
        let message = '';
        if (error.response.data) message = error?.response?.data?.message;
        return {
            message: message?.length > 0 ? message : 'An error has occurred',
            error: true
        }
    }
}
