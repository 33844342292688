/**
 * Divides objects in two groups, one to render immediately
 * We override this function to show highlighted objects on top of the canvas
 * and one to render as activeGroup.
 * @override
 * @param {object?} options Options of choosing objects.
 * @param {boolean} options.excludeDynamics If true, the dynamic objects will not be added to the list.
 * @returns {Array} Objects to render immediately and pushes the other in the activeGroup.
 */
export default function _chooseObjectsToRender (options = {}) {
    var activeObjects = this.getActiveObjects(),
        object, objsToRender, activeGroupObjects;

    if (activeObjects.length > 0 && !this.preserveObjectStacking) {
        objsToRender = [];
        activeGroupObjects = [];
        for (var i = 0, length = this._objects.length; i < length; i++) {
            object = this._objects[i];
            if (options?.excludeDynamics && object.isDynamic) {
                continue
            }
            if (activeObjects.indexOf(object) === -1 ) {
                objsToRender.push(object);
            }
            else {
                activeGroupObjects.push(object);
            }
        }
        if (activeObjects.length > 1) {
            this._activeObject._objects = activeGroupObjects;
        }
        objsToRender.push.apply(objsToRender, activeGroupObjects);
    }
    else {
        try {
            const notHighlightedObjects = this._objects.filter(o => !o.shouldShowHiglight && (options?.excludeDynamics ? !o.isDynamic : true));
            const highlightedObjects = this._objects.filter(o => o.shouldShowHiglight && (options?.excludeDynamics ? !o.isDynamic : true))

            // first render not highlighted objects, then highlighted objects
            objsToRender = [...notHighlightedObjects, ...highlightedObjects];
        } catch {
            objsToRender = this._objects.filter(o => options?.excludeDynamics ? !o.isDynamic : true);
        }
    }
    return objsToRender;
}