/**
 *
 */
const IconArrowBottom = () => {
    return (
        <svg className='reverse' fill="none" height="6" viewBox="0 0 10 6" width="10" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L5 5L9 1" stroke="#3C3E49"/>
        </svg>
    )
}

export default IconArrowBottom;