import React from 'react';
import PropTypes from 'prop-types';
import LockIcon from '../../svgIcons/canvasRedesign/IconLock';
import UnlockIcon from '../../svgIcons/canvasRedesign/IconUnlock';

const LockItem = ({
    isLocked,
    lockTarget
}) => {

    return (
        <div className="button" onClick={lockTarget}>
            {isLocked ? <LockIcon /> : <UnlockIcon />}
            <span className="tooltipText">{isLocked ? 'Unlock' : 'Lock'}</span>
        </div>
    )
}

LockItem.propTypes = {
    isLocked: PropTypes.bool,
    lockTarget: PropTypes.func.isRequired
}

export default LockItem;