import { CurvedLine } from '../../hooks/UseCurvedLine';
import store from '../../redux/Store';
import { removeCanvasListener, stopDrawing } from '../CommonFunctions';
import { MAGNET_CIRCLE_TYPE, SHAPE_DEFAULTS, modes } from '../Constant';
import { changeObjectsSelectableProp } from '../FabricMethods';
import { lineAlignmentHandler } from '../alignment/GenerateAlignmentLines';
import { clearAlignmentLines } from '../alignment/Utils';
import { getDefaultLineType } from './GetDefaultLineType';
import magnetEffectForPolygon from './MagnetEffect';

let drawInstance = null,
    isDrawInstanceAdded = false,
    mouseDown = false,
    firstPoint = {};

const createCurvedLine = (options, handleChangeSelectMode) => {
    // instead of parameter hell, we can send the parameters with an object
    const {
        canvas,
        isArrow,
        emitOnMouseDown, 
        onMouseDownLineHandler,
        isArrowRightEnabled,
        isInitiallyConnector
    } = options;

    // set the mode to line
    store.dispatch({
        type: 'board/changeCurrentMode',
        payload: modes.CURVED_LINE
    });

    removeCanvasListener(canvas);
    canvas.toggleDragMode(canvas, false);
    canvas.on('mouse:down', startAddCurvedLine(canvas));
    canvas.on('mouse:move', startDrawingCurvedLine(canvas, isArrow, isArrowRightEnabled, isInitiallyConnector));
    canvas.on('mouse:up', () => {
        mouseDown = false;
        if (drawInstance) stopDrawing(canvas, drawInstance, emitOnMouseDown, onMouseDownLineHandler);
        
        // if there is a magnet circle, remove it
        canvas.getObjects().filter(obj => obj.shapeType === MAGNET_CIRCLE_TYPE).forEach(obj => canvas.remove(obj));
        handleChangeSelectMode('select');

        // remove the alignment lines if there is any
        clearAlignmentLines(canvas);
    });
    canvas.selection = false;
    canvas.defaultCursor = 'default';
    canvas.hoverCursor = 'auto';
    canvas.isDrawingMode = false;
    changeObjectsSelectableProp(canvas, false);
    canvas.discardActiveObject().requestRenderAll();
}

const startAddCurvedLine = (canvas) => (event) => {
    // save the first point data and set mouseDown to true
    const { e } = event;
    mouseDown = true;
    const pointer = canvas.getPointer(e);
    firstPoint = {x: pointer.x, y: pointer.y};
    // make sure drawnInstance and isDrawInstanceAdded are reset
    drawInstance = null;
    isDrawInstanceAdded = false;
}

const startDrawingCurvedLine = (canvas, isArrow, isArrowRightEnabled, isInitiallyConnector = true) => (event) => {
    if (!mouseDown) return;

    const mouse = canvas.getPointer(event.e);

    // if not instance is created, create one
    // with this user's can't draw a line without moving the mouse
    if (!isDrawInstanceAdded) {
        const boardOptions = store.getState()?.board;
        drawInstance = new CurvedLine([{
            ...firstPoint
        },
        {
            x: mouse.x,
            y: mouse.y,
        }], {
            strokeWidth: boardOptions?.currentWidth ? boardOptions?.currentWidth : 1,
            stroke: boardOptions?.currentColor ? boardOptions?.currentColor : SHAPE_DEFAULTS.STROKE,
            originY: 'center',
            originX: 'center',
            strokeUniform: true,
            arrowEnabled: isArrow,
            arrowRight: isArrowRightEnabled,
            arrowLeft: false,
            lineType: getDefaultLineType(),
            isInitiallyConnector,
            isDynamic: true,  // tells that we will use this instance only for mocking,
            forceAddZIndex: true,
            curvedLineVersion: 'v2'
        });
        canvas.add(drawInstance);
        drawInstance.isLineDrawing = true;
        drawInstance.movingPointIndex = 1;
        drawInstance.showToolbarAfterCreate = true;
        drawInstance.isDrawingStop = false;
        canvas.setActiveObject(drawInstance).requestRenderAll();
        isDrawInstanceAdded = true;
        drawInstance.isLineDrawing = false;
    }
    if (drawInstance) {
        const position = { x: mouse.x, y: mouse.y };
        lineAlignmentHandler(drawInstance, 1, position);
        drawInstance.points[1] = position;
        // for the object that has pathOffset, we need to
        // call setPositionDimensions to update the pathOffset
        drawInstance._setPositionDimensions({});
        magnetEffectForPolygon(drawInstance, 1);
    }

    canvas.renderAll();
}


export default createCurvedLine;