import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { FEEDBACK_TIMEOUT } from '../helpers/Constant';
import store from '../redux/Store';


const toastCustomId = 'feedback-custom-toast';


/**
 * @param whiteBoardSlugId
 */
export default function useFeedback(
    whiteBoardSlugId
) {
    const isPerformed = useRef(false);
    const timeout = useRef(null);
    const dispatch = useDispatch();
    const feedbackToastId = useRef(null);

    useEffect(() => {
        const getSavedFeedbackDataInSession = () => {
            try {
                const sessionData = sessionStorage.getItem('feedback');
                if (!sessionData) {
                    sessionStorage.setItem('feedback', JSON.stringify({}));
                }
                const savedData = JSON.parse(sessionStorage.getItem('feedback'));
                return savedData;
            } catch (err) {
                console.log('sessionStorage not accessible while reading', err);
                return {
                    error: true
                };
            }
        }
        const setNewTimestamp = () => {
            try {
                const savedData = getSavedFeedbackDataInSession();
                const whiteboardId = whiteBoardSlugId.toString();
                savedData[whiteboardId].timestamp = new Date().getTime();
                sessionStorage.setItem('feedback', JSON.stringify(savedData));
            } catch (err) {
                console.log('localStorage not accessible while saving', err);
            }
        }
        const showFeedbackToast = () => {
            const updatedSavedData = getSavedFeedbackDataInSession();
            const whiteboardId = whiteBoardSlugId.toString();
            const isFeedbackModalActive = store.getState()?.modal?.feedbackModal;
            if ((!updatedSavedData[whiteboardId]?.isFeedbackShown || updatedSavedData.error) && !isFeedbackModalActive) {
                feedbackToastId.current = toast.info(<span className="feedback_toast__text"><span className="feedback_toast__text--main">Please share your feedback</span><span className="feedback_toast__button toast_btn">Give feedback</span></span>, {
                    icon: false,
                    autoClose: false,
                    className: 'wb_toast wb_toast__feedback',
                    draggable: false,
                    toastId: toastCustomId,
                    onClick: (e) => {
                        if (e?.target?.classList?.contains('toast_btn')) {
                            if (feedbackToastId?.current) {
                                toast.dismiss(feedbackToastId.current);
                            }
                            dispatch({type: 'modal/toggleFeedbackModal', payload: {
                                shouldShow: true,
                            }});
                        }
                    }
                });
            }
        }
            
        const createTimer = (diff = 0) => {
            try {
                timeout.current = setTimeout(() => {
                    showFeedbackToast();
                    setNewTimestamp();
                    // create new timer in case of the user won't give feedback
                    clearTimeout(timeout.current);
                    createTimer();
                }, FEEDBACK_TIMEOUT * 60 * 1000 - diff);
            } catch (err) {
                console.log('error while creating timer', err);
            }
        }

        if (whiteBoardSlugId && !isPerformed.current) {
            try {
                if (typeof window?.sessionStorage === 'undefined') {
                    console.log('session storage not accessible, but created timer')
                    createTimer();
                    return;
                }
                const whiteboardId = whiteBoardSlugId.toString();
                const savedData = getSavedFeedbackDataInSession();
                if (!savedData[whiteboardId]) {
                    savedData[whiteboardId] = {
                        isFeedbackShown: false,
                        timestamp: new Date().getTime(),
                    };
                    sessionStorage.setItem('feedback', JSON.stringify(savedData));
                }
                const now = new Date().getTime();
                const diff = now - savedData[whiteboardId].timestamp;
    
                if (!savedData[whiteboardId].isFeedbackShown) {
                    createTimer(diff);
                } else {
                    console.log('feedback has shown for this board');
                }
            } catch (err) {
                console.error('error while creating feedback timer: ', err);
                return;
            }
        }

        return () => {
            clearTimeout(timeout.current);
        }
    }, [whiteBoardSlugId, dispatch]);
}