import { calculateObjectPosition } from '../FabricMethods';
import findPossibleTarget from './FindPossibleTarget';


/**
 * Handles attachment for comment objects.
 * @param {fabric.Object} commentObj 
 * @param {fabric.Canvas} canvas 
 * @param {any} attachedShape 
 */
export const handleAttachComment = (commentObj, canvas, attachedShape) => {
    let attachedEl = findPossibleTarget(canvas, {x: commentObj.left, y: commentObj.top});
    if (attachedEl) {
        const positionOfObject = calculateObjectPosition(attachedEl);
        let pointDifs = {
            left: positionOfObject.tl.x - commentObj.left,
            top: positionOfObject.tl.y - commentObj.top,
        }
        attachCommentToTarget(commentObj, attachedEl, pointDifs);
    } else if (attachedShape) {
        detachCommentFromTarget(commentObj, attachedShape);
    }
    
}

/**
 * Attaches comment to target object.
 * @param {Fabric.Object} comment Comment that will be attached to target 
 * @param {Fabric.Object} target target object that comment will be attached to
 * @param {Object} pointDifs difference between comment.left and target.left
 * @param {number} pointDifs.left difference between comment.left and target.left
 * @param {number} pointDifs.top difference between comment.top and target.top
 */
export const attachCommentToTarget = (comment, target, pointDifs) => {
    const currentAttachedComments = Array.isArray(target.attachedComments) 
        ? target.attachedComments.filter(attachedComment => attachedComment.commentID !== comment.commentID) 
        : [];

    target.attachedComments = [...currentAttachedComments, comment];
    target.attachedCommentDifs = {...target.attachedCommentDifs, [comment.commentID]: pointDifs};
    comment.attachedShape = target;
}

/**
 * Handles detachment of comment from target object.
 * @param {fabric.Object} comment Comment that will be detached from target 
 * @param {fabric.Object} target target object that comment will be detached from
 */
export const detachCommentFromTarget = (comment, target) => {
    if (Array.isArray(target.attachedComments)) {
        target.attachedComments = target.attachedComments.filter(elem => elem.commentID !== comment.commentID);
    } else {
        target.attachedComments = [];
    }
    if (typeof target.pointDifs === 'object') {
        delete target.pointDifs[comment.commentID];
    }
    comment.attachedShape = null;
}