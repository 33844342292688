import Store from '../../redux/Store';
import { LINE_TYPES } from '../Constant';

/**
 * Returning the stored line type from redux state if its setted. Otherwise returning the straight type as default.
 * @returns {'straight' | 'curved'}
 */
export const getDefaultLineType = () => {
    const state = Store.getState();
    let defaultLineType = LINE_TYPES.STRAIGHT;

    if ([LINE_TYPES.STRAIGHT, LINE_TYPES.CURVED].includes(state?.board?.defaultLineType)) {
        defaultLineType = state.board.defaultLineType;
    }

    return defaultLineType;
}