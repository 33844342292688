import {fabric} from 'fabric';
import {Engine} from '../../../core/engine/Engine';
import {CachedLinkedList} from '../../../core/renderers/dataStructures/LinkedList';
import {Indexer} from '../../../core/engine/Indexer';
import {ObjectsMap} from '../../../core/engine/ObjectsMap';

/**
 * Gets called when we initiate the canvas instance.
 * @override
 * @param {HTMLCanvasElement} el HTML Canvas element.
 * @param {object} options Options for initialize.
 * @param {boolean} options.initializeWithEngine Determines if the canvas should use Engine.
 */
export function initialize(el, options) {
    options || (options = { });
    this.renderAndResetBound = this.renderAndReset.bind(this);
    this.requestRenderAllBound = this.requestRenderAll.bind(this);
    this._initStatic(el, options);
    this._initInteractive();
    this._createCacheCanvas();
    this.objectsMap = new ObjectsMap();  // to store objects in map. <uuid, object>
    
    // override
    // init the engine
    if (options?.initializeWithEngine) {
        this._initEngine(this, options);
    } 
}

/**
 * Creates a canvas engine.
 * @param {fabric.Canvas} canvas The root canvas.
 */
export function _initEngine(canvas) {
    this.indexer = new Indexer(canvas);
    this.engine = new Engine(canvas);
    this.engine.run();
    this.isEngineEnabled = true;
    
    if (!this.dirtyObjects) {
        this.dirtyObjects = new CachedLinkedList();
    }
}

/**
 * Renders the canvas.
 * @override
 */
export function renderAll() {
    // override
    // request a render with the engine
    if (this.isEngineEnabled && this.engine) {
        this.engine.requestRender();
    } else {
        this.renderDefault();
    }
}

/**
 * Renders both the top canvas and the secondary container canvas.
 * @returns {fabric.Canvas} Instance.
 */
export function renderDefault() {
    if (this.contextTopDirty && !this._groupSelector && !this.isDrawingMode) {
        this.clearContext(this.contextTop);
        this.contextTopDirty = false;
    }
    if (this.hasLostContext) {
        this.renderTopLayer(this.contextTop);
        this.hasLostContext = false;
    }
    var canvasToDrawOn = this.contextContainer;
    this.renderCanvas(canvasToDrawOn, this._chooseObjectsToRender(), { defaultRender: true });
    return this;
}


/**
 * @override
 */
export function requestRenderAll() {
    // we don't need to request an animation frame anymore since we do that in Engine.
    // so now we can just call render all method.
    this.renderAll();
}