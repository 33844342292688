import {fabric} from 'fabric';


/**
 * Sets zoom transform of the canvas.
 * @override
 * @param {fabric.Point} point The point that canvas will zoom to.
 * @param {number} value New zoom value.
 * @returns {fabric.Canvas} Returns this canvas instance.
 */
export default function zoomToPoint(point, value) {
    let before = point, vpt = this.viewportTransform.slice(0);
    point = fabric.util.transformPoint(point, fabric.util.invertTransform(this.viewportTransform));
    vpt[0] = value;
    vpt[3] = value;
    let after = fabric.util.transformPoint(point, vpt);
    vpt[4] += before.x - after.x;
    vpt[5] += before.y - after.y;
    
    // override
    if (this.engine) {
        this.engine.setZoom(value);
    }
    
    return this.setViewportTransform(vpt);
}