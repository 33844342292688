import ErrorIcon from '../../assets/images/canvasRedesignImages/toast_icons/error.png';
import InfoIcon from '../../assets/images/toast_icons/info.png';
import SuccessIcon from '../../assets/images/canvasRedesignImages/toast_icons/success.png';
import WarningIcon from '../../assets/images/toast_icons/warning.png';
import FeedbackIcon from '../../assets/images/toast_icons/feedback.png'
/**
 * Returns the icon for toast based on the type.
 * @param {'info'|'success'|'error'|'warning'|'feedback'} type 
 */
const getToastIcon = (type) => {
    switch (type) {
        case 'info':
            return function() {
                return <img alt='info' src={InfoIcon}/>
            };
        case 'success':
            return function() {
                return <img alt='success' src={SuccessIcon}/>
            };
        case 'error':
            return function() {
                return <img alt='error' src={ErrorIcon}/>
            };
        case 'warning':
            return function() {
                return <img alt='warning' src={WarningIcon}/>
            };
        case 'feedback':
            return function() {
                return <img alt='give a feedback' src={FeedbackIcon}/>
            };
        case 'flowchart-success':
            return function() {
                return <img alt='flowchart success' src={SuccessIcon}/>
            };
        default:
            return function() {
                return <img alt='info' src={InfoIcon}/>
            };
    }
}

export default getToastIcon;