import {Viewport, isViewportValid, setupCanvasViewport, pixelRatio} from '../../viewPort/Viewport';
import {SpatialIndex} from '../dataStructures/SpatialIndex';
import { preciseRoundDown, preciseRoundUp, normalizeFractionalPart } from '../../utils/MathUtils';
import {TileMutations} from './TileMutations';
import {ResourcePool} from '../../resourcePool/ResourcePool';
import {EMITTER_TYPES} from '../../../helpers/Constant';

class TileCamera {
    tx;
    ty;
    scale;
    zoom;

    constructor() {
    }

    updateWithCustomPixelRatio(x, y, zoom, pixelRatio) {
        this.tx = x * pixelRatio;
        this.ty = y * pixelRatio;
        this.scale = zoom;
        this.zoom = zoom;
    }

    translateWithoutPixelRatio(x, y) {
        this.tx += x;
        this.ty += y;
    }
}

export class TileRenderer {
    _tileWidth = 512;
    _tileHeight = 512;

    viewport = {
        rect: {}
    }

    get tileWidth() {
        return this._tileWidth;
    }

    get tileHeight() {
        return this._tileHeight;
    }

    constructor(canvas, emitter, options = {}) {
        this.canvas = canvas;
        this.tileKeys = new Set();
        this.tileCache = new Map();
        this.tileCamera = new TileCamera();
        this.renderViewport = {
            rect: new Viewport(),
        }

        this.tilePoolDOMRoot = document.createElement('div');
        this.tilePoolDOMRoot.id = 'tile-pool';
        document.body.appendChild(this.tilePoolDOMRoot)

        this.tilePool = new ResourcePool({
            factory: {
                create: ()=>({
                    canvas: this.createTileCanvas(this.tileWidth * pixelRatio.dpr, this.tileHeight * pixelRatio.dpr, this.tilePoolDOMRoot),
                    viewport: new Viewport,
                    modified: !1,
                    count: 0
                }),
                reset: (tile) => {
                    tile.viewport.empty();
                    tile.modified = false;
                    tile.count = 0;
                },
                destroy: (tile) => {
                    this.destroyTileCanvas(tile);
                }
            }
        })
        this.mutationsViewport = new Viewport();
        this.dirtyObjects = new Set();

        this.handleMutation = (object)=>{
            if (object.type === 'activeSelection') {
                for (const childObject of object.getObjects()) {
                    this.dirtyObjects.add(childObject);
                    this.mutations.handlePreMutation(childObject);
                }
            } else {
                this.dirtyObjects.add(object);
                this.mutations.handlePreMutation(object);
            }
        }

        this.mutations = new TileMutations({
            useObjectPool: true
        })

        this.spatialIndex = new SpatialIndex();
        
        this.debug = options?.debug;

        this.updateRenderViewport = () =>{
            const viewport = this.canvas.viewportTransform;
            const canvasX = viewport[4];
            const canvasY = viewport[5];
            this.renderViewport = setupCanvasViewport(this.canvas.width, this.canvas.height, this.tileWidth, this.tileHeight, canvasX * pixelRatio.dpr, canvasY * pixelRatio.dpr, this.canvas.getZoom(), pixelRatio.dpr, pixelRatio.inverseDPR, this.renderViewport)
        }
        emitter.on(EMITTER_TYPES.OBJECT_MUTATED, this.handleObjectMutated.bind(this));
    }

    createTileCanvas(width, height, doom) {
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        canvas.style.display = 'none';
        if (doom) {
            doom.appendChild(canvas);
        }
        return canvas;
    }

    destroyTileCanvas(canvas) {
        canvas.width = 0;
        canvas.height = 0;
        canvas.remove();
    }
    
    forceInvalidate() {
        this._forceInvalidate = true     
    }
    
    invalidate() {
        this._forceInvalidate = false;
        this.tilePool.releaseAll();
        this.tileCache.clear();
        this.tileKeys.clear();
        this.mutations.clear();
    }
    
    reset() {
        this.tilePool.dispose();
        this.invalidate();
    }
    
    hardReset() {
        this.reset();
        this.spatialIndex.clear();
        this.dirtyObjects.clear();
    }

    dispose() {
        this.hardReset()
        this.tilePoolDOMRoot.remove()
    }

    render() {
        this.updateRenderViewport()

        const mainCtx = this.canvas.getContext();
        mainCtx.save();
        mainCtx.scale(1 / pixelRatio.dpr, 1 / pixelRatio.dpr);

        const viewport = this.canvas.viewportTransform;
        const canvasX = viewport[4] * pixelRatio.dpr;
        const canvasY = viewport[5] * pixelRatio.dpr;
        const {
            tileScreenWidth,
            tileScreenHeight,
            tileScreenWidthWithDPR,
            tileScreenHeightWithDPR,
            tileScreenOffsetXWithDPR,
            tileScreenOffsetYWithDPR,
            tileScale,
            zoomLevel,
            leftIndex,
            topIndex,
            rightIndex,
            bottomIndex,
            truncatedPixelRatio
        } = setupCanvasViewport(this.canvas.width, this.canvas.height, this.tileWidth, this.tileHeight, canvasX, canvasY, this.canvas.getZoom(), pixelRatio.dpr, pixelRatio.inverseDPR, this.renderViewport)

        this._forceInvalidate ? this.forceInvalidate() : this.invalidateMutations(tileScreenWidth, tileScreenHeight);
        this.updateSpatialIndex();

        for (let left = leftIndex; left <= rightIndex; left++) {
            for (let top = topIndex; top <= bottomIndex; top++) {
                const tileKey = `${left}:${top}:${zoomLevel}`;
                this.tileKeys.add(tileKey);
                let tile = this.tileCache.get(tileKey);
                if (!tile || !tile.canvas) {
                    tile = this.tilePool.acquire();
                    tile.viewport.width = tileScreenWidth;
                    tile.viewport.height = tileScreenHeight;
                    tile.viewport.x = tileScreenWidth * left;
                    tile.viewport.y = tileScreenHeight * top;

                    tile.modified = true;
                    let intersectedObjects = this.spatialIndex.getIntersectedObjects(tile.viewport)
                    intersectedObjects.sort((a, b) => {
                        if (a.zIndex > b.zIndex) {
                            return 1
                        } else {
                            return -1
                        }
                    });
                    tile.count = intersectedObjects.length;

                    if (tile.count > 0) {
                        const canvasWidthWithDPR = this.tileWidth * pixelRatio.dpr;
                        const canvasHeightWidthDPR = this.tileHeight * pixelRatio.dpr;
                        if (tile.canvas.width !== canvasWidthWithDPR || tile.canvas.height !== canvasHeightWidthDPR) {
                            tile.canvas.width = canvasWidthWithDPR;
                            tile.canvas.height = canvasHeightWidthDPR;
                        }
                        this.tileCamera.updateWithCustomPixelRatio(-this._tileWidth * left, -this._tileHeight * top, tileScale, truncatedPixelRatio)
                        this.tileCamera.translateWithoutPixelRatio(
                            normalizeFractionalPart(canvasX),
                            normalizeFractionalPart(canvasY),
                        )

                        const tileCtx = tile.canvas.getContext('2d');
                        tileCtx.resetTransform();
                        tileCtx.clearRect(0, 0, canvasHeightWidthDPR, canvasHeightWidthDPR);
                        tileCtx.transform(this.tileCamera.zoom, 0, 0, this.tileCamera.zoom, this.tileCamera.tx, this.tileCamera.ty)
                        tileCtx.scale(pixelRatio.dpr, pixelRatio.dpr)

                        for (const object of intersectedObjects) {
                            if (!object || object.isDynamic) {
                                continue;
                            }
                            object.render(tileCtx, {
                                isRenderingDynamic: false,
                                isRenderingWithTiles: true
                            });
                            if (object.collabLocked) {
                                object.renderGrayOverlay(tileCtx);
                            }
                        }
                    }
                    this.tileCache.set(tileKey, tile);
                }
            }
        }

        for (let left = leftIndex; left <= rightIndex; left++) {
            for (let top = topIndex; top <= bottomIndex; top++) {
                const tileKey = `${left}:${top}:${zoomLevel}`
                const tile = this.tileCache.get(tileKey);
                if (tile && tile.count > 0) {
                    let actualTileX = tileScreenOffsetXWithDPR + tileScreenWidthWithDPR * left,
                        actualTileY = tileScreenOffsetYWithDPR + tileScreenHeightWithDPR * top;
                    mainCtx.drawImage(tile.canvas, actualTileX, actualTileY, tileScreenWidthWithDPR, tileScreenHeightWithDPR)
                    this.debug && this.debugTile(actualTileX, actualTileY, tileScreenWidthWithDPR, tileScreenHeightWithDPR, 1, tileKey, tile.count);
                }
            }
        }
        mainCtx.fillStyle = 'green'
        this.canvas.needRender = false;
        mainCtx.restore();
        this.invalidateStaleTiles();
    }
    debugTile(x, y, w, h, scale, tileKey, count) {
        const ctx = this.canvas.getContext('2d');
        ctx.resetTransform()
        ctx.save();
        ctx.rect(x, y, w, h);
        ctx.lineWidth = 0.5 * pixelRatio.dpr;
        ctx.font = `${16 * pixelRatio.dpr}px Arial`
        ctx.fillText(`Tile ${tileKey} (${count})`, x + 5, y + 20 * pixelRatio.dpr)
        ctx.font = `${13 * pixelRatio.dpr}px Arial`;
        ctx.fillText(`x: ${x * pixelRatio.inverseDPR}, y: ${y * pixelRatio.inverseDPR}`, x + 5, y + 35 * pixelRatio.dpr)
        ctx.strokeStyle = 'rgba(0, 0, 0, 0.2)';
        ctx.stroke()
        ctx.restore();
    }
    updateSpatialIndex() {
        const viewPort = new Viewport();
        this.dirtyObjects.forEach(dirtyObject => {
            const coords = dirtyObject.getCompleteBoundingRect();
            const bbox = new Viewport(coords.left, coords.top, coords.width, coords.height);
            (isViewportValid(bbox) && !dirtyObject.isDeleted) ? this.spatialIndex.upsert(dirtyObject, viewPort.copy(bbox).dpr(1)) : this.spatialIndex.remove(dirtyObject)
        });
        this.dirtyObjects.clear();
    }
    invalidateTile(tileKey, tile) {
        this.tilePool.release(tile)
        this.tileCache.delete(tileKey)
    }
    invalidateMutations(width, height) {
        if (this.mutations.size < 1) {
            return;
        }
        const copiedMutationsViewPort = this.mutationsViewport.copy(this.renderViewport.rect)
        this.mutations.forEachOnce((viewport, object) => {
            if (viewport !== null && copiedMutationsViewPort.intersects(viewport)) {
                this.invalidateByWorldBounds(viewport, width, height);
            }
            const objectCoords = object.getCompleteBoundingRect();
            const objectViewport = new Viewport(objectCoords.left, objectCoords.top, objectCoords.width, objectCoords.height)
            if (isViewportValid(objectViewport) && copiedMutationsViewPort.intersects(objectViewport)) {
                this.invalidateByWorldBounds(objectViewport, width, height);
            }
        });
    }
    invalidateByWorldBounds(viewport, width, height) {
        this.forEachTileKeyByWorldBounds(viewport, width, height, tileKey=>{
            const tile = this.tileCache.get(tileKey);
            if (tile !== null) {
                this.invalidateTile(tileKey, tile)
            }
        })
    }
    forEachTileKeyByWorldBounds(viewport, width, height, callbackFn) {
        let left = viewport.left,
            right = viewport.right,
            top = viewport.top,
            bottom = viewport.bottom,
            {leftIndex: leftIdx, topIndex: topIdx, rightIndex: rightIdx, bottomIndex: botIdx, zoomLevel} = this.renderViewport;
        leftIdx = Math.max(leftIdx, preciseRoundDown(left / width)),
        rightIdx = Math.min(rightIdx, preciseRoundUp(right / width) - 1),
        topIdx = Math.max(topIdx, preciseRoundDown(top / height)),
        botIdx = Math.min(botIdx, preciseRoundUp(bottom / height) - 1);
        for (let left = leftIdx; left <= rightIdx; left++) {
            for (let top = topIdx; top <= botIdx; top++) {
                callbackFn(`${left}:${top}:${zoomLevel}`)
            }
        }
    }
    invalidateStaleTiles() {
        this.tileCache.forEach((tile, tileKey)=> {
            if (!this.tileKeys.has(tileKey)) {
                this.invalidateTile(tileKey, tile)
            }
        });
        this.tileKeys.clear()
    }
    addObjectsToDirtyList() {
        for (const obj of this.canvas.getObjects()) {
            if (obj.isDynamic || !obj.uuid) {
                continue
            }
            this.dirtyObjects.add(obj)
        }
    }
    
    handleObjectMutated(event) {
        const { target } = event;
        this.handleMutation(target)
    }
}


