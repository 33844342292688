import { getFabricObject } from '../FabricMethods';
import calculateUnreadMessagesCount from './CalculateUnreadMessages';

/**
 * Updates comment badge depends on unread comment.
 * @param updateCommentItem
 * @param canvas
 * @param {number} userId - Required because of calculating unread messages count according to mentions.
 */
const updateCommentBadge = (updateCommentItem, canvas, userId) => {
    const updatedCommentObj = getFabricObject(
        canvas,
        'commentID',
        updateCommentItem?.uuid
    );

    if (!updatedCommentObj) {
        return;
    }

    const totalUnreadMessages = calculateUnreadMessagesCount(
        updateCommentItem,
        userId
    );

    //send comment obj and canvas to set width of comment icon dynamically
    updatedCommentObj.changeCountText(totalUnreadMessages, updatedCommentObj, canvas);
    updatedCommentObj.updateColor(updatedCommentObj.colorCode);
    canvas.renderAll();
};

/**
 * Directly updates comment badge without any calculation.
 * @param canvas
 * @param commentUuid
 * @param badgeText
 */
export const setDirectlyCommentBadge = (canvas, commentUuid, badgeText, readAll) => {
    const commentObj = getFabricObject(canvas, 'commentID', commentUuid);

    if (!commentObj) {
        return;
    }

    //send comment obj and canvas to set width of comment icon dynamically
    commentObj.changeCountText(badgeText, commentObj, canvas, readAll);
    commentObj.updateColor(commentObj.colorCode);
    if(!readAll) canvas.renderAll();
};

/**
 * Updates comment badge with given action.
 * @param canvas
 * @param commentUuid
 * @param userId
 * @param taggedUserIds
 * @param action
 * @param colorCode
 */
export const changeCommentCount = (canvas, commentUuid, userId, taggedUserIds = [], action = 'decrease', colorCode) => {
    const commentObj = getFabricObject(canvas, 'commentID', commentUuid);
    if (!commentObj) {
        return;
    }
    let isTagEnabled = false;
    let isTaggedCurrentUser = false;

    if (taggedUserIds && taggedUserIds.length > 0) {
        isTagEnabled = true;
        isTaggedCurrentUser = taggedUserIds.map(tag => tag.id).includes(userId);
    }
    if (isTagEnabled && !isTaggedCurrentUser) {
        // if tag is enabled and current user is not tagged, do nothing
        return;
    }
    
    try {
        if (action === 'decrease') {
            commentObj?.decreaseCount(commentObj,canvas);
        } else {
            commentObj?.increaseCount(commentObj,canvas);
        }
        
        commentObj?.updateColor(colorCode ?? commentObj?.colorCode);
        canvas.renderAll();
    } catch (error) {
        console.error('Error while changing comment count: ', error);
    }
}

export default updateCommentBadge;
