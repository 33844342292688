import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { fabric } from 'fabric';
import * as pdfjsLib from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { connectToGroup } from '../../hooks/UseLine';
import { createRect } from '../../hooks/UseRect';
import { createEllipse } from '../../hooks/UseEllipse';
import { createTriangle } from '../../hooks/UseTriangle';
import { createRhombus } from '../../hooks/UseRhombus';
import { createParallelogram } from '../../hooks/UseParallelogram';
import { createSticky, editSticky } from '../../hooks/UseSticky';
import { createFrame } from '../../hooks/UseFrame';
import { changeToErasingMode, createText, draw, onSelectMode, removeTheUnusedExpansionDot } from '../../hooks/UseDraw';
import { createTable, tableArrowMovementHandler } from '../../helpers/table/TableMethods';
import { onShapeDrawnEvent, onShapeModifiedEvent, onShapeRemovedEvent, AddedShapeCounter } from '../../hooks/EventCatcher';
import { onWheelZoomInOut, onZoomIn, onZoomOut, setZoomLevel } from '../../hooks/PanZoom';
import { setPositionOfHTMLElement, changeCursorInCommentMode } from '../../hooks/UseComment';
import { getOrCreateCommentRefObject } from '../../helpers/comments/GetOrAddCommentRef';
import {
    attachObjectsDuringResizing,
    detachOrChangePositionOfObject,
    getFrameAttachedShapeUuids,
    isLinkedFrameLocked,
    handleAttachingObjectOnModified,
    attachToFrame
} from '../../helpers/frame/FrameMethods';

import editCurvedLineText from '../../helpers/lines/EditCurvedLineText';
import { arrayEquals } from '../../helpers/Validation';

import '../../helpers/Interceptor';

import { onShapeDoubleClick, createObjectToBeEmitted, removeCanvasListener, shapeLineMoveHandler, isUserHasAccessToFeature } from '../../helpers/CommonFunctions';
import {
    BOARD_MESSAGES,
    CANVASES_CTX_ACTIONS,
    COMMENT_DRAWER_WIDTH,
    DISABLED_OBJECTS_FOR_HIDING_SUBTOOLBAR,
    EMITTER_TYPES,
    FLOWCHART_STATUS,
    MODAL_WINDOWS,
    modes,
    PLACEHOLDER_TEXT,
    RIGHT_DRAWER_WIDTH,
    SHAPE_DEFAULTS,
    SOCKET_EVENT,
    SOCKET_EVENT_TO_HISTORY_ACTION,
    SOCKET_STATUS_MODS,
    USER_ROLES
} from '../../helpers/Constant';
import { createSelectionForObjects, customToObject, getFabricObject, isObjectValid, isTargetLocked, arrangeControlsForLockedObject, isObjectInsideOfObject, makeObjectUnselectable, adjustTextBoxWhileTyping, centerToObjectWithAnimation } from '../../helpers/FabricMethods';

import renderAllComments from '../../helpers/comments/RenderAllComments';
import attachedShapeMoveHandler from '../../helpers/comments/AttachedShapeMoveHandler';
import createCurvedLine from '../../helpers/lines/CreateCurvedLine';
import { handleLineMovementInActiveSelection, setLinePointsForCurrentPosition } from '../../helpers/lines/LineMethods';
import {setSingleObjectStack, setObjectsStackWithZIndex} from '../../helpers/StackOrder';
import moveHandlerForFrame from '../../helpers/frame/MoveHandler';
import { handleObjectRotating, handleObjectScalingAndResizing } from '../../helpers/lines/AttachedObjectTransformHandlers';
import store from '../../redux/Store';
import { getLocalStorage, setLocalStorage } from '../../services/CookieService';

import './Board.scss';
import customizeControlForShape from '../../helpers/customControls/CustomizeControlsForShape';
import loadShapes from '../../helpers/LoadShapes';
import detachControl from '../../helpers/lines/DetachControl';

import { createLasso, makeLassoClosedShape, checkAndSelectTheObjectCoveredByLasso, checkIfClickOutSideTheLasso } from '../../hooks/UseLasso';
import { compressData, debounce, decompressData } from '../../helpers/OptimizationUtils';
import { CanvasesDispatchContext } from '../../context/canvases/CanvasesContext';
import eventEmitter from '../../helpers/EventEmitter';
import useHistory from '../../hooks/UseHistory';
import {dblClickHandlerForNativeTexts} from '../../helpers/CommonUtils';
import { tableTitleDoubleClickHandler } from '../../helpers/table/TableEventHandlers';
import useVisibilityReset from '../../hooks/UseVisibilityReset'
import ObjectStore from '../../helpers/ObjectStore';
import {removeFlowchartItemsInCanvas} from '../../helpers/flowchart/FlowchartUtils';
import getToastIcon from '../../helpers/media/GetToastIcon';
import {CanvasLockManager} from '../../core/collaboration/CanvasLockManager';

// imports must be the first statements
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

/**
 * @param {object} props
 * @param props.pageId
 * @param props.isPageRendering
 * @param props.getPageCanvas
 * @param props.handleToolbarItemVisibility
 * @param props.shouldLoadData
 * @param props.whiteBoardSlugId
 * @param props.pageData
 * @param props.userAccessRef
 * @param props.socketRef
 * @param props.isLassoRef
 * @param props.userId
 * @param props.isJoinedToSocketRoom
 * @param props.whiteBoardId
 * @param props.mapFieldsToDrawInstance
 * @param props.uuidGenerator
 * @param props.comments
 * @param props.selectedComment
 * @param props.setSelectedComment
 * @param props.selectedCommentIcon
 * @param props.setSelectedCommentIcon
 * @param props.commentFormWrapperRef
 * @param props.commentWrapperRef
 * @param props.queueSetRef
 * @param props.emitData
 * @param props.emitQueue
 * @param props.actionSelected
 * @param props.selectedArrowType
 * @param props.hideComments
 * @param props.hideResolvedComments
 * @param props.setCommentState
 * @param props.eventQueueRef
 * @param props.canvasRef
 * @param props.forceUpdate
 * @param props.setShowLoader
 */
const Board = ({
    pageId,
    isPageRendering,
    getPageCanvas,
    handleToolbarItemVisibility,
    shouldLoadData,
    whiteBoardSlugId,
    pageData,
    userAccessRef,
    socketRef,
    isLassoRef,
    userId,
    isJoinedToSocketRoom,
    whiteBoardId,
    mapFieldsToDrawInstance,
    uuidGenerator,
    selectedComment,
    setSelectedComment,
    selectedCommentIcon,
    setSelectedCommentIcon,
    commentFormWrapperRef,
    commentWrapperRef,
    queueSetRef,
    emitData,
    emitQueue,
    actionSelected,
    selectedArrowType,
    hideComments,
    hideResolvedComments,
    setCommentState,
    eventQueueRef,
    canvasRef,
    forceUpdate,
    setShowLoader,
    isFrameNotFoundFlowRunOnce,
    lockManager
}) => {
    let options;

    const [canvas, setCanvas] = useState(null),
        fileReaderInfo = (0, useState)({
            file: '',
            totalPages: null,
            currentPageNumber: 1,
            currentPage: ''
        })[0],
        [attachedCommentsUpdatePos, setAttachedCommentsUpdatePos] = useState([]),
        // [isLoading, setIsLoading] = useState(shouldLoadData ? true : false),
        isMouseDown = useRef(false),
        needToAddToUndoRedo = useRef(true),
        isShapeOrStickyEditing = useRef(false),
        prevSelectedCommentIcon = useRef(),
        isTryingToReconnect = useRef(false),
        stackOrderRef = useRef(),
        mouseWheelAnimRef = useRef({}),
        // listen mouse clicks for comment mode
        location = useLocation(),
        dispatch = useDispatch(),
        shouldRefreshCommentsAndUsers = useSelector(state => state.socket.shouldRefreshCommentsAndUsers),
        socketStatus = useSelector(state => state.socket.status),
        selectedPageSlugId = useSelector(state => state.rightDrawer.activePage?.slugId),
        selectedPageId = useSelector(state => state.rightDrawer.activePage?.id),
        isCommentsRendered = useRef(false),
        isCommentDrawerVisible = useSelector((state) => state.modal?.activeWindow === MODAL_WINDOWS.COMMENT_DRAWER),
        isRightDrawerVisible = useSelector((state) => state.modal?.activeWindow === MODAL_WINDOWS.RIGHT_DRAWER),
        stackOrderUpdated = useSelector(state => state?.board?.stackOrderUpdated),
        activityHistory = useSelector(state => state?.history),
        [triggerStack, setTriggerStack] = useState(false),
        [stackOrderUpdateFlow, setStackOrderUpdateFlow] = useState(0); // Temporarily added (#1703475)
    
    const users = useSelector((state) => state?.board?.users);

    const dispatchCanvasesContext = useContext(CanvasesDispatchContext);
    const {enabledHistory} = useHistory(canvas, userAccessRef, pageId, userId, whiteBoardId, emitData);
    useVisibilityReset(canvas);

    useEffect(() => {
        if (canvas) {
            canvas.renderAll();
            let canvasObjects = canvas.getObjects() || [];
            canvasObjects.filter(item => item.shapeType === 'sticky').forEach((item) => {
                item._objects.forEach((object) => {
                    object.set({visible:true})
                })
            })
        }
    }, [canvas]);

    (0, useEffect)(() => {
        const loadPageData = async () => {
            if (!shouldLoadData) { // means we already got the page data
                initShapes(pageData, whiteBoardSlugId, userAccessRef.current);
            } else {
                setShowLoader(true);

                if(isJoinedToSocketRoom === true) {
                    socketRef.current.emit('fetchPageData', {
                        pageId
                    }, (compressedData) => {
                        let fetchedPageData = decompressData(compressedData.emitData);
                        setShowLoader(false);

                        dispatch({
                            type: 'board/updateStackOrderCheck',
                            payload: fetchedPageData.stackOrderUpdated
                        });
                        
                        if (!Array.isArray(fetchedPageData?.shapes)) return;                            

                        dispatch({
                            type: 'rightDrawer/setPage',
                            payload: {
                                shapes: fetchedPageData.shapes,
                                stackOrder: fetchedPageData.stackOrder,
                                pageId: fetchedPageData.id,
                                properties: fetchedPageData?.properties,
                                lockedShapes: fetchedPageData?.lockedShapes,
                            }
                        });

                        // ADDED TEMPORARILY (#1703475)
                        const isStackOrderExist = Array.isArray(fetchedPageData.stackOrder) && fetchedPageData.stackOrder.length > 0;
                        const isShapesExist = Array.isArray(fetchedPageData.shapes) && fetchedPageData.shapes.length > 0;

                        if (isShapesExist && !isStackOrderExist) { // 1.st Point
                            setStackOrderUpdateFlow(1);
                        } else if (isShapesExist && isStackOrderExist && fetchedPageData.shapes.length > fetchedPageData.stackOrder.length) { // 2.st Point
                            setStackOrderUpdateFlow(2);
                        }
                    })
                }
            }
        }
        const initShapes = (data, wbId, access) => {
            if (!canvas || isTryingToReconnect.current) {
                ObjectStore.init(data.shapes);
                const localCanvas = initCanvas(data.id, data.name);

                if (data.shapes.length > 0) {
                    loadShapes(data.shapes, localCanvas, data.stackOrder, stackOrderUpdated, setTriggerStack, activityHistory[selectedPageId]?.shapes, data?.users, data?.ownerId, () => {
                        localCanvas.isCanvasInitalized = true;
                        forceUpdate(Math.random());
                    }, () => { // After loads and rendered images
                        localCanvas.isImagesLoaded = true;
                        forceUpdate(Math.random());
                        localCanvas?.engine?.shapesLoaded();
                    }, (lines) => {
                        if (!data.properties?.connectorsUpdated) {
                            const lineUpdateData = {
                                pageId: data.id,
                                shapes: lines,
                            }
                            const compressed = compressData(lineUpdateData)
                            socketRef.current.emit('updateLineConnectors', compressed)
                            
                        }
                    }, socketRef, pageId, data.lockedShapes);

                    if (data.shapes.length > 0) {
                        dispatch({
                            type: 'history/initHistory',
                            payload: { shapes: data.shapes, pageId: data.id }
                        });
                    }
                } else {
                    localCanvas.isCanvasInitalized = true;
                    localCanvas.isImagesLoaded = true;
                    localCanvas?.engine?.shapesLoaded();
                }

                setCanvas(() => {
                    isTryingToReconnect.current = false;
                    if (access === USER_ROLES.notAllowed.id) return localCanvas;
                    if (data.stackOrder?.length > 0) {
                        dispatch({
                            type: 'history/initStackOrder',
                            payload: { stackOrder: data.stackOrder, pageId: data.id }
                        });
                    }

                    ObjectStore.clear(); // Clearing the object store for heavy boards. Storing all of the object properties in browser might decrease the performance. So clearing it after canvas initalized..
                    return localCanvas;    
                })
            }
        }
        if (typeof pageData === 'object' && Object.keys(pageData).length > 0 && whiteBoardSlugId) { 
            loadPageData();
        }
    }, [pageData, whiteBoardSlugId, dispatch, isJoinedToSocketRoom, selectedPageId]);

    // ADDED TEMPORARILY DUE TO MISSING BOARD DATA ISSUE (#1703475)
    useEffect(() => {
        const isSocketReady = socketStatus === SOCKET_STATUS_MODS.CONNECTED && isJoinedToSocketRoom;
        if (!isSocketReady || !canvas) return;
        if (canvas?.isCanvasInitalized !== true) return;
        if (canvas?.isImagesLoaded !== true) return;

        if (stackOrderUpdateFlow === 1 || stackOrderUpdateFlow === 2) {
            setStackOrderUpdateFlow(0);
        }

    }, [canvas, canvas?.isCanvasInitalized, canvas?.isImagesLoaded, stackOrderUpdateFlow, socketStatus, isJoinedToSocketRoom]);

    const loadComments = useCallback((isInitialRequest) => {
        if (
            isUserHasAccessToFeature('comments', userAccessRef.current) &&
        pageData?.id &&
        pageData?.id === selectedPageId
        ) {
            socketRef.current.emit('fetchCommentInitData', {
                pageId: pageData?.id
            }, (data) => {
                if (!data.emitData) return;
  
                isCommentsRendered.current = true;

                const unreadComments = data?.emitData?.filter(comment => comment?.unreadCommentCount > 0)

                if (unreadComments?.length) {
                    eventEmitter.fire(EMITTER_TYPES.SET_UNREAD_COMMENT_EXISTS);
                }

                renderAllComments({
                    canvas,
                    comments: data.emitData,
                    setSelectedCommentIcon,
                    userId,
                    shouldHideComments: hideComments,
                    shouldHideResolvedComments: hideResolvedComments
                });
         
                if (!isInitialRequest) { return; }
    
                setTimeout(() => {
                    const urlParams = new URLSearchParams(location.search);
    
                    if (canvas && urlParams.has('commentId') && urlParams.has('pageId')) {
                        const urlCommentId = urlParams.get('commentId');
                        const activeCommentItem = canvas.getObjects().find((o) => o.commentID === urlCommentId);

                        if (activeCommentItem) {
                
                            centerToObjectWithAnimation(canvas, activeCommentItem, {
                                adaptZoomLevel: true,
                                selectAfterAnimation: isUserHasAccessToFeature('objectAnimation', true),
                                showToolbar: isUserHasAccessToFeature('subToolbar', true)
                            });
                            setTimeout(() => {
                                setSelectedCommentIcon(activeCommentItem);
                            },500)
                            canvas.setActiveObject(activeCommentItem);
                            canvas.renderAll();
                        }
                    }
                }, 500);
            })
        }
    }, [canvas, hideComments, hideResolvedComments, userId, whiteBoardId, selectedPageId, pageData?.id]);

    const windowResizeHandler = useCallback(() => {
        if (!canvas) return;

        // set canvas width and height to window width and height
        let newWidth = window.innerWidth;

        if (isCommentDrawerVisible) {
            newWidth -= COMMENT_DRAWER_WIDTH;
        } else if (isRightDrawerVisible) {
            newWidth -= RIGHT_DRAWER_WIDTH;
        }

        canvas.setWidth(newWidth);
        canvas.setHeight(window.innerHeight);
        canvas.renderAll();
    }, [canvas, isRightDrawerVisible, isCommentDrawerVisible]);

    (0, useEffect)(() => {
        if (
            canvas &&
        isCommentsRendered.current !== true &&
        socketStatus === SOCKET_STATUS_MODS.CONNECTED &&
        isJoinedToSocketRoom
        ) {
            loadComments(true);
        }
    }, [location,canvas, whiteBoardId, userId, socketStatus, isJoinedToSocketRoom]);

    (0, useEffect)(() => {
        const isViewAccess = USER_ROLES.view.id === userAccessRef.current;
        const isCommentAccess = USER_ROLES.comment.id === userAccessRef.current;

        if (canvas && (isViewAccess || isCommentAccess)) {
            handleChangeSelectMode('pan');

            canvas.forEachObject(function (object) {

                if (isViewAccess || (isCommentAccess && object.type !== 'comment')) {
                    makeObjectUnselectable(object);
                }
            });
        }
    }, [canvas]);

    useEffect(() => {
        if(socketStatus === SOCKET_STATUS_MODS.RECONNECT_ATTEMPT) {
            isTryingToReconnect.current = true;
        } else if (socketStatus === SOCKET_STATUS_MODS.DISCONNECTED) {
            setCanvas(null);
        }
    }, [socketStatus, canvas, setCanvas]);

    useEffect(() => {
    // contextContainer code added because this code part works before canvas initialized
        if (shouldRefreshCommentsAndUsers && socketStatus === SOCKET_STATUS_MODS.CONNECTED && canvas?.contextContainer) {
            loadComments();
            dispatch({
                type: 'socket/setShouldRefreshCommentsAndUsers',
                payload: false
            });
        }
    
    }, [shouldRefreshCommentsAndUsers, loadComments, dispatch, socketStatus, canvas?.contextContainer]);

    useEffect(() => {
    // handle responsive canvas while resizing
        if (canvas) {      
            const browserWheelHandler = (e) => {
                if (e.ctrlKey || e.metaKey) e.preventDefault();
            };

            const handleKeyDown = (event) => {
                const { keyCode, ctrlKey } = event;
                if (ctrlKey) {
                    if (keyCode === 109) {
                        event.preventDefault();
                        onZoomOut(canvas);
                    } else if (keyCode === 107) {
                        event.preventDefault();
                        onZoomIn(canvas);
                    } else if (keyCode === 96) {
                        event.preventDefault();
                        setZoomLevel(100, canvas, canvasRef);
                    }
                } else {
                    const activeObject = canvas.getActiveObject();
          
                    // If the active object is table and if the editing mode is not active.
                    if (
                        activeObject &&
            activeObject.type === 'table' &&
            activeObject.isCellSelected &&
            !activeObject.editingTextbox
                    ) {
                        switch (keyCode) {
                            case 37:
                                tableArrowMovementHandler(activeObject, 'LEFT');               
                                break;
                            case 38:
                                tableArrowMovementHandler(activeObject, 'UP'); 
                                break;
                            case 39:
                                tableArrowMovementHandler(activeObject, 'RIGHT');
                                break;
                            case 40:
                                tableArrowMovementHandler(activeObject, 'DOWN');
                                break;
                        }
                    }
                }
            };

            // add event listeners for resizing
            window.addEventListener('resize', windowResizeHandler);
            window.addEventListener('orientationchange', windowResizeHandler);
            window.addEventListener('wheel', browserWheelHandler, { passive: false });
            window.addEventListener('keydown', handleKeyDown);

            return () => {
                window.removeEventListener('resize', windowResizeHandler);
                window.removeEventListener('orientationchange', windowResizeHandler);
                window.removeEventListener('wheel', browserWheelHandler);
                window.removeEventListener('keydown', handleKeyDown);
            }
        }
    }, [canvas, isRightDrawerVisible, isCommentDrawerVisible, windowResizeHandler]);

    useEffect(() => {
        eventEmitter.fire(EMITTER_TYPES.HISTORY_ENABLED, { pageId, enabledHistory });
    }, [enabledHistory, pageId]);

    const arrangeMultipleSelection = (activeGroup) => {
        if (activeGroup.shapeType && activeGroup.shapeType === 'loadingMockImagesGroup') return;
        let isThereFrame = false, isThereTable = false, shouldLockSelection = false;
        const activeGroupObjects = activeGroup.getObjects();
        const lockedStates = [];

        const addedWithFrames = [];
        activeGroupObjects.forEach(groupedObject => {
            // if the object is comment, remove it from the group
            if (groupedObject.type === 'comment') {
                activeGroup.removeWithUpdate(groupedObject);
            } else {
                if (groupedObject.type === 'frame') {
                    isThereFrame = true;
        
                    // frame object arrange handler
                    const frameObjectArrangeHandler = (frameObj) => {
                        // if frame obj is locked, lock the selection
                        if (isTargetLocked(frameObj)) {
                            shouldLockSelection = true;
                            lockedStates.push(true);
                        } else {
                            lockedStates.push(false);
                        }
                        // if frame obj is not in the group, add it to the group
                        if (!addedWithFrames.includes(frameObj) && !activeGroupObjects.find(obj => obj.uuid === frameObj.uuid)) {
                            activeGroup.addWithUpdate(frameObj);
                            addedWithFrames.push(frameObj);
                        }
                    }
  
                    const frameObjects = canvas.getObjects().filter(obj => obj.attachedFrameId === groupedObject.uuid);
                    frameObjects.forEach(frameObj => {
                        frameObjectArrangeHandler(frameObj);
                        // if this is nested frame, arrange its nested frame objects
                        if (frameObj.type === 'frame') {
                            const nestedFrameObjects = canvas.getObjects().filter(obj => obj.attachedFrameId === frameObj.uuid);
                            nestedFrameObjects.forEach(frameObj => {
                                frameObjectArrangeHandler(frameObj);
                            });
                        }
                    });
                } else if (groupedObject.type === 'table') {
                    isThereTable = true;
                }

                if (groupedObject?.flowchartProps) {
                    activeGroup.flowchartSelected = true;
                }
                // if the object is locked, lock the selection
                if (isTargetLocked(groupedObject)) {
                    shouldLockSelection = true;
                    lockedStates.push(true);
                } else {
                    lockedStates.push(false);
                }
            }
        });
        // if there is a frame in the group, disable the rotate control
        if (isThereFrame || isThereTable) {
            activeGroup.setControlsVisibility({
                rotate: false
            });
            activeGroup.set({
                hasControls: false,
            });
        }
        // disable flip and skewing for the multiple selection
        activeGroup.set({
            // lockScalingFlip: true,
            lockSkewingX: true,
            lockSkewingY: true,
            padding: 0
        });
        // if there is a locked object in the group, lock the selection
        if (shouldLockSelection) {
            activeGroup.set({
                lockMovementX: true,
                lockMovementY: true,
            });
            arrangeControlsForLockedObject(activeGroup, true);
        }

        const isAllLocked = lockedStates.every(locked => locked);
        const isAllUnlocked = lockedStates.every(locked => !locked);
        if (isAllLocked) {
            activeGroup.isAllLocked = true;
        }
        if (!isAllLocked && !isAllUnlocked) {
            activeGroup.isMixedLockState = true;
        }
    }

    const _checkIfSelectedGroupIsAllComments = (selectedGroup) => {
        if(selectedGroup.length <= 1) return false;
        for (let i = 0; i < selectedGroup.length; i++) {
            if (selectedGroup[i].type !== 'comment') {
                return false;
            }
        }
        return true;
    };

    (0, useEffect)(() => {
        if (whiteBoardId && canvas) {
            /**
             * Handles socket events that returns compressed data.
             * Using for CREATED, MODIFIED, DELETED events for shapes.
             * @param {io} socket 
             * @param {SOCKET_EVENT.CREATED|SOCKET_EVENT.MODIFIED|SOCKET_EVENT.DELETED} event 
             * @param {Function} handler 
             */
            const registerSocketEvent = async (socket, event, handler) => {
                socket.on(event, async (bufferData) => {
                    let dataList = decompressData(bufferData);
                    if (!dataList) return;

                    dataList = dataList.filter((item) => isPageRendering(item.pageId, pageData?.id));
                    if (dataList.length === 0) return;

                    const canvases = {}
                    const objectProperties = {};
                    let isFlowchartCreatedEvent = false;
                    dataList.forEach((obj) => {
                        if (event === SOCKET_EVENT.CREATED && obj?.properties?.flowchartProps) {
                            isFlowchartCreatedEvent = true
                        }
                        if (!(obj.pageId in canvases)) {
                            canvases[obj.pageId] = getPageCanvas(obj.pageId);

                            objectProperties[obj.pageId] = [{
                                properties: obj.properties,
                                uuid: obj.uuid,
                                action: SOCKET_EVENT_TO_HISTORY_ACTION[event]
                            }]
                        } else {
                            objectProperties[obj.pageId].push({
                                properties: obj.properties,
                                uuid: obj.uuid,
                                action: SOCKET_EVENT_TO_HISTORY_ACTION[event]
                            })
                        }
                    });

                    if (event === SOCKET_EVENT.CREATED) {
                        eventQueueRef.current.add({ handler: () => handler(dataList, canvases[dataList[0].pageId])});
                    } else {
                        for (const data of dataList) {
                            eventQueueRef.current.add({ handler: () => handler(data, canvases[data.pageId]), data });
                        }
                    }
                    if (isFlowchartCreatedEvent) {
                        // flowchart modal is not getting closed with ufd_status socket event 'completed' anymore
                        // other users need to wait for all the flowchart shapes to be drawn on the canvas.
                        eventQueueRef.current.add({ handler: () => {
                            dispatch({
                                type: 'board/changeBoardDetails',
                                payload: {
                                    userFlowDiagram_status: FLOWCHART_STATUS.COMPLETED,
                                }
                            });
                            dispatch({
                                type: 'modal/setFlowchartModalDetails',
                                payload: {
                                    showModal: false,
                                    disableCancelInLoading: true
                                }
                            })
                        }});
                    }

                    // add to history
                    for (const [pageId, data] of Object.entries(objectProperties)) {
                        canvas.fire('add-to-history', {
                            action: 'stateChange',
                            affectedObjects: data,
                            canvas: canvases[pageId]
                        });
                    }
                });
            }

            registerSocketEvent(
                socketRef.current,
                SOCKET_EVENT.CREATED,
                async (dataList, canvas) => {
                    // use promise since await will not work in forEach, but eventually we need to wait all the elements
                    // to be drawn in the canvas, and we don't want each event to wait another event to complete
                    // so, the same logic in loadShape function is used here.
                    const addedShapeCounter = new AddedShapeCounter(dataList.length);
                    await new Promise((resolve) => {
                        dataList.forEach(async el => {
                            try {
                                await onShapeDrawnEvent(el, canvas, {}, activityHistory[selectedPageId]?.shapes)
                                stackOrderRef.current = canvas.getObjects().map(i => {
                                    return { zIndex: i.zIndex , uuid: i.uuid}
                                })
                            } catch (err) {
                                console.error(err)
                            } finally {
                                addedShapeCounter.add();
                                if (addedShapeCounter.totalShapeCount >= dataList?.length) {
                                    resolve()
                                }
                            }
                        }) 
                    })
                } 
            );
      
            registerSocketEvent(
                socketRef.current,
                SOCKET_EVENT.MODIFIED,
                async (data, canvas) => {
                    if (data.actionTaken === 'modified' && data.properties.attachedFrameId) {
                        const frame = canvas.getObjects().find(i => i.uuid === data.properties.attachedFrameId);
                        if (frame && !frame?.attachments) {
                            frame.attachments = [];
                        }
                        if (frame && !frame.attachments.find(f => f === data.uuid)) {
                            frame.attachments = [...frame.attachments, data.uuid];
                            attachToFrame(data.properties, frame);
                            eventEmitter.fire(EMITTER_TYPES.TOOLBAR_SHOW);
                        }
                    }
                    const objectBeforeModified = getFabricObject(canvas, 'uuid', data.uuid)?.toObject();
                    //to update attachments on subtoolbar need to rerender subtoolbar
                    if (data.shapeType === 'frame') eventEmitter.fire(EMITTER_TYPES.TOOLBAR_SHOW);
                    await onShapeModifiedEvent(data, canvas);
                    // if updated object has attached comments, move them
                    let fabricObject = getFabricObject(canvas, 'uuid', data.uuid);
                    if (fabricObject && fabricObject.attachedComments && Array.isArray(fabricObject.attachedComments)) {
                        attachedShapeMoveHandler(fabricObject);
                    } 

                    // If frame object is locked / unlocked, then update attached objects of the frame.
                    if (data.properties.type === 'frame' && isTargetLocked(data.properties) !== isTargetLocked(objectBeforeModified)) {
                        setTimeout(() => {
                            const objects = canvas.getObjects().filter((o) => data.properties.attachments.includes(o.uuid));

                            for (const obj of objects) {
                                obj.set({ selectable: !isTargetLocked(data.properties) });
                            }
                        }, 0);
                    }
                }
            )

            registerSocketEvent(
                socketRef.current,
                SOCKET_EVENT.DELETED,
                (data, canvas) => {
                    onShapeRemovedEvent(data, canvas)
                    canvas.fire('add-to-history', {
                        action: 'stackOrder',
                        affectedObjects: [{action:'modified',properties:data.properties,uuid:data.uuid}],
                        canvas
                    });
                }

                
            );

            socketRef.current.on('ufd_status', (data) => {
                if (data?.status === FLOWCHART_STATUS.COMPLETED) {
                    return
                }
                dispatch({
                    type: 'board/changeBoardDetails',
                    payload: {
                        userFlowDiagram_status: data?.status
                    }
                });
                if (data?.status === FLOWCHART_STATUS.IN_PROGRESS) {
                    dispatch({
                        type: 'modal/setFlowchartModalDetails',
                        payload: {
                            showModal: true,
                            disableCancelInLoading: true
                        }
                    })
                } else {
                    if (data?.status === FLOWCHART_STATUS.REMOVED) {
                        // since all the flowchart items are removed,
                        // remove them from the canvas immediately without waiting shapeDeleted event
                        removeFlowchartItemsInCanvas(canvas);
                    }
                    dispatch({
                        type: 'modal/setFlowchartModalDetails',
                        payload: {
                            showModal: false,
                            disableCancelInLoading: false
                        }
                    })
                }
            })

            canvas.on('unlock-object-when-remove-subToolbar', ()=>{
                needToAddToUndoRedo.current = false;
            });
            canvas.on('object:modified',  (e)=> {
                const { target } = e;
                // if the object is modified in order to duplicate, do not send update to server
                // unnecessary update will be sent when the object is not modified
                if (target.duplicateShadowObj) {
                    return;
                }
                if (target.type === 'curvedLine') {
                    const { isDeltaChanged } = connectToGroup(canvas, target, socketRef, whiteBoardId, userId);

                    if (!isDeltaChanged) {
                        target.onShapeChanged();
                    }
                }
                if (target.type === 'activeSelection') {
                    const updatedObjects = target.getObjects();
                    canvas.discardActiveObject().requestRenderAll();  // discarding is necessary to avoid falss position calculations of the object
                    for (const obj of updatedObjects) {
                        // if object is attached to any frame, do not send update. It will be updated with frame.
                        // However, if multiple objects are attached to the same frame, need to update one by one.
                        if (obj.attachedFrameId && obj.oneOfMultipleAttachedItem !== true)
                            continue;
                        if (isObjectValid(obj) || obj.type === 'comment') handleSingleObjectModification(e, obj);
                    }
                    createSelectionForObjects(canvas, updatedObjects, target.createdWithLasso);
                } else {
                    if (target && target.type !== 'comment') {
                        // just in case the fact that the position of the target is changed
                        handleAttachingObjectOnModified(canvas, target);
                    }
                    if (isObjectValid(target) || target.type === 'comment') handleSingleObjectModification(e, e.target);
                }
                emitQueue();
                if(needToAddToUndoRedo.current){
                    canvas.fire('object:modified-after', e);
                } else {
                    needToAddToUndoRedo.current = true;
                }
            });

            canvas.on('object:removed', function (e) {
                if (!(DISABLED_OBJECTS_FOR_HIDING_SUBTOOLBAR.includes(e.target?.shapeType)) && (e.target?.avoidDiscard !== true)) {
                    canvas.discardActiveObject().requestRenderAll();
                }
                // if we don't want to emit removing for once
                if (e.target.avoidEmittingOnRemove) {
                    e.target.avoidEmittingOnRemove = true;
                    return;
                }
                if (e?.target?.flowchartProps && !e.target?.avoidEmittingFlowchartRemove) {
                    eventEmitter.fire(EMITTER_TYPES.FLOWCHART_ITEM_REMOVED);
                }
            });

            canvas.on('path:created', e => {
                if (isLassoRef.current) {
                    const lasso = makeLassoClosedShape(canvas, e.path, uuidGenerator);
                    const isLassoCoverAnything = checkAndSelectTheObjectCoveredByLasso(canvas, lasso);
                    if(!isLassoCoverAnything && isLassoRef.current) canvas.isDrawingMode = true;
                } else {
                    let path = e.path
                    removeTheUnusedExpansionDot(path);
                    mapFieldsToDrawInstance(canvas, path, whiteBoardId, userId);
                    e.path.on('mousedown', removeOrSelectObject(canvas));
                }
            });

            canvas.on('selection:created', e => {
                // if all the selected object are comment, nothing happen
                if(_checkIfSelectedGroupIsAllComments(e.selected)){
                    canvas.discardActiveObject().requestRenderAll(); 
                    return;
                }
                // if there is a comment in selected group, remove that comment from the group
                if (e.selected.length > 1) {
                    const activeGroup = canvas.getActiveObject();
                    if (activeGroup.selectionCreatedBy !== 'SUBTOOLBAR_FILTER') {
                        arrangeMultipleSelection(activeGroup);
                    }
                    if (!isTargetLocked(activeGroup)) {
                        customizeControlForShape(activeGroup);
                    }
                } else {
                    customizeControlForShape(e.selected[0]);
                }
                if (e && e.selected[0] && e.selected[0].shapeType !== 'sticky' && e.selected[0].shapeType !== 'frame') {
                    onShapeDoubleClick(e.selected[0], canvas, emitOnMouseDown, userId);
                } else if (e && e.selected[0] && e.selected[0].shapeType === 'sticky') {
                    editSticky(e.selected[0], canvas, emitOnMouseDown, userId);
                }
                if (e.selected[0].shapeType && e.selected[0].shapeType === 'frame') {
                    e.selected[0].off('mousedblclick', dblClickHandlerForNativeTexts);
                    e.selected[0].on('mousedblclick', dblClickHandlerForNativeTexts);        }
                if (e.selected[0].shapeType && e.selected[0].shapeType === 'curvedLine') {
                    e.selected[0].off('mousedblclick', editCurvedLineText);
                    e.selected[0].on('mousedblclick', editCurvedLineText);
                }
                if (e.selected[0].shapeType && e.selected[0].shapeType === 'optimizedImage') {
                    e.selected[0].off('mousedblclick', dblClickHandlerForNativeTexts);
                    e.selected[0].on('mousedblclick', dblClickHandlerForNativeTexts);
                }
                if (e.selected[0].type === 'table') {
                    e.selected[0].off('mousedblclick', tableTitleDoubleClickHandler);
                    e.selected[0].on('mousedblclick', tableTitleDoubleClickHandler);
                }

                eventEmitter.fire(EMITTER_TYPES.TOOLBAR_SHOW);
            });

            canvas.on('selection:updated', e => {
                //if all the selected object are comment, nothing happen
                if(_checkIfSelectedGroupIsAllComments(e.selected)){
                    canvas.discardActiveObject().requestRenderAll(); 
                    return;
                }
                const activeGroup = canvas.getActiveObject();
                if (activeGroup.type === 'activeSelection') {
                    if (activeGroup.selectionCreatedBy !== 'SUBTOOLBAR_FILTER') {
                        arrangeMultipleSelection(activeGroup);
                    }
                    eventEmitter.fire(EMITTER_TYPES.TOOLBAR_SHOW);
                    return;
                }
                if (e && e.selected[0] && e.selected[0].shapeType !== 'sticky' && e.selected[0].shapeType !== 'frame') {
                    onShapeDoubleClick(e.selected[0], canvas, emitOnMouseDown, userId)
                } else if (e && e.selected[0] && e.selected[0].shapeType === 'sticky') {
                    editSticky(e.selected[0], canvas, emitOnMouseDown, userId);
                }
                if (e.selected[0].shapeType && e.selected[0].shapeType === 'frame') {
                    e.selected[0].off('mousedblclick', dblClickHandlerForNativeTexts);
                    e.selected[0].on('mousedblclick', dblClickHandlerForNativeTexts);
                }
                if (e.selected[0].shapeType && e.selected[0].shapeType === 'curvedLine') {
                    e.selected[0].off('mousedblclick', editCurvedLineText);
                    e.selected[0].on('mousedblclick', editCurvedLineText);
                }
                if (e.selected[0].shapeType && e.selected[0].shapeType === 'optimizedImage') {
                    e.selected[0].off('mousedblclick', dblClickHandlerForNativeTexts);
                    e.selected[0].on('mousedblclick', dblClickHandlerForNativeTexts);
                }
                if (e.selected[0].type === 'table') {
                    e.selected[0].off('mousedblclick', tableTitleDoubleClickHandler);
                    e.selected[0].on('mousedblclick', tableTitleDoubleClickHandler);
                }
                customizeControlForShape(e.selected[0]);

                eventEmitter.fire(EMITTER_TYPES.TOOLBAR_SHOW);
            });

            canvas.on('selection:cleared', e => {
                eventEmitter.fire(EMITTER_TYPES.TOOLBAR_REMOVE, e);
            });

            canvas.on('frame-text-updated', frameUuid => {
                const frame = canvas.getObjects().find(obj => obj.uuid === frameUuid);
                if (frame) {
                    let obj = createObjectToBeEmitted(whiteBoardId, userId, customToObject(frame), false, frame.shapeType);
                    obj.actionTaken = 'modified';
                    frame.modifiedBy = userId;
                    emitData([obj]);
                    canvas.fire('update-frame', frameUuid);  // for FrameList
                }
            });
            canvas.on('history-emit-data', data => {
                const { action, customToObjectOptions = {}, createObjectOptions = {} } = data;
                if (action === 'modified') {
                    const dataArray = [];
                    for (const object of data.objects) {
                        if(object.type !== 'lasso'){
                            // if using queue is requested, add object to the queue
                            if (data.useQueue) {
                                queueSetRef.current.add(object.uuid);
                            } else {
                                // if not using queue, get object data and add it to the list
                                const objData = createObjectToBeEmitted(
                                    whiteBoardId, 
                                    userId, 
                                    customToObject(object, {...customToObjectOptions, narrowDefinitions: true }), 
                                    false, 
                                    object.shapeType
                                );
                                objData.actionTaken = 'modified';
                                objData.isRestored = object.isRestored;
                                objData.modifiedBy = userId;

                                // Keep the previous stack order if exists
                                if (object.isRestored === true && parseInt(object.stackOrder) > -1) {
                                    // setSingleObjectStack(canvas, object, object.stackOrder);
                                }

                                // If object is attached to the frame, and if frame is locked then locked the object.
                                if (object.isRestored === true && object.attachedFrameId) {
                                    const [isLinked, frame] = isLinkedFrameLocked(object, canvas);

                                    if (isLinked && isObjectInsideOfObject(frame, object, { manualCheck: true })) {
                                        canvas.fire('lock-object', { object, force: true });
                                    }
                                }

                                dataArray.push(objData);
                            }
                        }
                    }
                    // if using queue is requested, request emit queue
                    if (data.useQueue) {
                        emitQueue();
                        // if no need to queue, emit data right away
                    } else {
                        emitData(dataArray);
                    }
                } else if (action === 'modified-the-comment') {
                    for (const object of data.objects) {
                        socketRef.current.emit('updateComment', {
                            uuid: object.commentID,
                            position: {
                                x: object.left,
                                y: object.top,
                            },
                        });
                    }
                } else if (action === 'created') {
                    const dataArray = [];
                    for (const object of data.objects) {
                        const objData = createObjectToBeEmitted(
                            whiteBoardId,
                            userId,
                            customToObject(object, {...customToObjectOptions, narrowDefinitions: true }),
                            false,
                            object.shapeType,
                            createObjectOptions
                        );
                        objData.actionTaken = 'created';
                        objData.modifiedBy = userId;
                        objData.createdBy = userId;

                        // Keep the previous stack order if exists
                        if (parseInt(object.stackOrder) > -1) {
                            setSingleObjectStack(canvas, object, object.stackOrder);
                        }

                        dataArray.push(objData);
                    }
                    emitData(dataArray, SOCKET_EVENT.CREATED);
                } else if (action === 'deleted') {
                    const dataArray = [];
                    const modifiedFrames = [];
                    for (const object of data.objects) {
                        const frame = canvas.getObjects().find(item => item.uuid === object.attachedFrameId);
                        if(object.type !== 'lasso'){
                            const objData = createObjectToBeEmitted(
                                whiteBoardId,
                                userId,
                                customToObject(object, {...customToObjectOptions, narrowDefinitions: true }),
                                true,
                                object.shapeType
                            );
                            objData.actionTaken = 'deleted';
                            objData.modifiedBy = userId;
                            dataArray.push(objData);
                        }
                        if (object.attachedFrameId && frame) {
                            frame.attachments = frame?.attachments?.filter(item => item !== object.uuid);
                            canvas.fire('frame-modified', { frame, updatedAttachments: [], detachedObjects: [] });
                            const frameData = createObjectToBeEmitted(
                                whiteBoardId,
                                userId,
                                customToObject(frame, customToObjectOptions),
                                false,
                                frame.shapeType
                            );
                            frameData.actionTaken = 'modified';
                            frameData.modifiedBy = userId;
                            modifiedFrames.push(frameData);
                        }
                    }
                    // modify part
                    emitData(modifiedFrames, SOCKET_EVENT.MODIFIED);
                    // delete part 
                    emitData(dataArray, SOCKET_EVENT.DELETED);
                } else if (action === 'stackOrder') {
                    canvas.fire('stack-updated', { updatedStacks: data.updatedStacks });
                }
            });
            canvas.on('emit-drawn-connector', data => {
                const { connector, dontEmit, callback } = data;
                if (!connector) return;

                const uuid = uuidGenerator(canvas);
                connector.uuid = uuid;

                // first attach the connector to the group
                connectToGroup(canvas, connector, socketRef, whiteBoardId, userId, { dontEmitAttachedLine: true, dontEmitTarget: dontEmit });

                // Below functions are fired in order to avoid position miscalculation of curved lines. We have changed arrow directions in some scenarios. Please check the story(#2317421) for more details
                connector._setPositionDimensions({});
                connector.organizeControls(true);
                
                if (dontEmit !== true) {
                    // then emit shapeCraeted event
                    mapFieldsToDrawInstance(canvas, connector, whiteBoardId, userId, { dontAttachLine: true, processId: data?.transform?.processId });
                }

                if (typeof callback === 'function') {
                    callback({ connector });
                }
            });
            canvas.on('text-editor-text-updated', target => {
                const objData = createObjectToBeEmitted(whiteBoardId, userId, customToObject(target), false, target.shapeType);
                objData.actionTaken = 'modified';
                objData.modifiedBy = userId;
                emitData([objData]);
            });

            // Checking the stack orders, then if there are differences, emitting the socket.
            canvas.on('stack-updated', ({ callback, updatedStacks }) => {
                let canvasObjects = canvas.getObjects() || [];
                const newStackOrder = canvasObjects.map(i => {
                    return { zIndex: i.zIndex , uuid: i.uuid}
                });
                canvasObjects.filter(item => item.shapeType === 'sticky').forEach((item) => {
                    item._objects.forEach((object) => {
                        object.set({ visible: true })
                    })
                })
                if (!arrayEquals(stackOrderRef.current.map(i => i.zIndex), newStackOrder.map(i => i.zIndex))) {
                    // updating stack here when we send to back bring to front
                    socketRef.current.emit('sendBackFront', compressData({
                        stackOrder: updatedStacks,
                        pageId: selectedPageId
                    }));

                    // Callback function should be fired only when the stack order changed.
                    if (typeof callback === 'function') {
                        callback({ oldStackOrder: stackOrderRef.current, newStackOrder });
                    }

                    stackOrderRef.current = newStackOrder;
                }
            });

            // opens text editor for the frame title
            canvas.on('frame-title:dbl-click', target => {
                target.set('visible', false);
                target.hideTemporarily = true;
                target.shapeType = 'frameText'; // (#1443144) After undo/redo operations, shapeType of frame title is received as undefined.
                canvas.renderAll();
                canvas.fire('open_text_editor', target);
            })
            document.addEventListener('contextmenu', event => {
                if (event.target && event.target.localName === 'canvas' && event.target.className?.indexOf('minimap') === -1) {
                    event.preventDefault();
                    canvas.fire('open-context-menu', event);
                }
            });
        }
    }, [whiteBoardId, canvas, pageData?.id]);

    useEffect(() => {
        if (canvas) {
            const togglePanModeHandler = (option) => {
                const { shouldActivate } = option;
                if (shouldActivate) {
                    handleChangeSelectMode('pan');
                } else {
                    handleChangeSelectMode('select');
                }
            }
            canvas.on('toggle-pan-mode', togglePanModeHandler);

            return () => {
                canvas.off('toggle-pan-mode', togglePanModeHandler);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionSelected, canvas]);

    const handleInitializeStackOrder = () => {
        if (canvas) {
            stackOrderRef.current = canvas.getObjects().map(i => {
                return { zIndex: i.zIndex, uuid: i.uuid }
            }) || [];
        }
    }

    const handleFrameDetachmentUpdate = (data) => {
        if (canvas) {
            const frame = canvas.getObjects().find(item => item.uuid === data.shape.attachedFrameId)
            
            if (!frame.attachments.find(f => f === data.uuid)) {
                const frameData = createObjectToBeEmitted(
                    whiteBoardId,
                    userId,
                    customToObject(frame),
                    false,
                    frame.shapeType
                );
                frameData.actionTaken = 'modified';
                frameData.modifiedBy = userId;
                frameData.properties.attachments = frame.attachments.filter(a => a !== data.shape.uuid);

                emitData([frameData], SOCKET_EVENT.MODIFIED);
            }

            
        }
    }

    const checkIfWeNeedToFocusOnFrame = () =>{
        let zoomToFrameUuid = null;
        try {
            let url_params = new URLSearchParams(window.location.search);
            if (window.self !== window.top) {
                const newUrl = new URL(url_params.get('url'));
                url_params = new URLSearchParams(newUrl.search);
            }
            const frameUuid = url_params.get('shape');
            zoomToFrameUuid = frameUuid;
        } catch (e) {
            console.error('Error happened', e);
        }
        const isFrameExist = canvas.getObjects().some(e => e.uuid === zoomToFrameUuid);

        return {
            isFrameExist,
            isFrameUuidExist: !!zoomToFrameUuid
        };
    }
    useEffect(() => {
    // We need to fit to screen in default after the canvas initialized
        if (canvas?.isCanvasInitalized) {
            const { isFrameExist, isFrameUuidExist } = checkIfWeNeedToFocusOnFrame();

            if(!isFrameExist) {
                // If frame is not exist but if frame uuid exist in the url, then we need to show a message.
                if (isFrameUuidExist && isFrameNotFoundFlowRunOnce.current !== true) {
                    toast.info(BOARD_MESSAGES.FRAME_NOT_FOUND, {
                        icon: getToastIcon('info'),
                        autoClose: false,
                        className: 'wb_toast',
                        draggable: false
                    });
                }
                eventEmitter.fire(EMITTER_TYPES.FIT_TO_SCREEN);
            }

            isFrameNotFoundFlowRunOnce.current = true;
        }
    }, [canvas?.isCanvasInitalized]);

    useEffect(() => {
    // if comment hided via comment tab, then hide comment card (wrapper) if its selected
        if (canvas) {
            const commentHideListener = ({comment}) => {
                if (selectedComment && selectedComment.uuid === comment.commentID) {
                    setSelectedComment({});
                    commentWrapperRef.current.style.display = 'none';
                }
            }
            canvas.on('comment-hide', commentHideListener);

            return () => {
                canvas.off('comment-hide', commentHideListener);
            }
        }
    }, [selectedComment, canvas, commentWrapperRef]);

    useEffect(() => {
        if (canvas) {
            const boardPanMouseDownListener = (e) => {
                const target = e.target;
                if (target && target.type === 'comment') {
                    //remove borders and controls for comment on clicking in case
                    target?.set({ hasBorders: false, hasControls: false })
                    // if new comment icon is clicked, then deselect previous comment icon
                    if (selectedCommentIcon?.commentID !== target.commentID) {
                        setSelectedCommentIcon(null);
                    }
                } else if (!target && selectedCommentIcon) {
                    // if target is null and comment icon is selected, then deselect comment icon
                    setSelectedCommentIcon(null);
                }
                else if (target?.type !== 'comment') {
                    setSelectedCommentIcon(null);
                    commentFormWrapperRef.current.style.display = 'none';
                    setCommentState('initial');
                }
            };
            // comment moving listener for deselecting comment icon if its moving on pan mode
            const commentMovingListener = () => {
                let comments = canvas.getObjects().filter(item => item.type === 'comment');
                if (selectedCommentIcon) setSelectedCommentIcon(null);
                comments.forEach(c=> c.set({hasBorders:false,hasControls:false}))
            };
            canvas.on('board:pan-mousedown', boardPanMouseDownListener);
            canvas.on('mouse:down', boardPanMouseDownListener);
            canvas.on('comment-moving', commentMovingListener);
            return () => {
                canvas.off('board:pan-mousedown', boardPanMouseDownListener);
                canvas.off('mouse:down', boardPanMouseDownListener);
                canvas.off('comment-moving', commentMovingListener);
            }
        }
    }, [canvas, selectedCommentIcon]);

    (0, useEffect)(() => {
        if (canvas) {
            const center = canvas.getCenter();

            fabric.Image.fromURL(fileReaderInfo.currentPage, img => {
                img.scaleToHeight(canvas.height);
                canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
                    top: center.top,
                    left: center.left,
                    originX: 'center',
                    originY: 'center'
                });
                canvas.renderAll();
            });
        }
    }, [fileReaderInfo.currentPage]);

    // if comment selected, show comment wrapper for selected comment
    (0, useEffect)(() => {
        if (selectedCommentIcon && canvas) {
            let comments = canvas.getObjects().filter(item => item.type === 'comment');
            let targetComment = comments?.filter(item => item?.commentID === selectedCommentIcon?.commentID)[0];
            targetComment?.set({ hasBorders: false, hasControls: false })
            //checking if comment dialog is opened or not
            if (targetComment && !targetComment.isOpened) {
                let refObj = getOrCreateCommentRefObject(canvas);
                commentWrapperRef.current.style.display = 'block';
                refObj.wiredTo = commentWrapperRef;
                refObj.left = selectedCommentIcon.left;
                refObj.top = selectedCommentIcon.top;
                refObj.setCoords();
                setPositionOfHTMLElement(refObj, commentWrapperRef.current, true);
                canvas.renderAll();
                targetComment?.set({ isOpened: true })
                canvas.renderAll();
            }
        } else {
            //clear all dialogs with isOpened key on canvas objects when closing dialogs
            if (canvas) {
                let comments = canvas.getObjects().filter(item => item.type === 'comment');
                comments.forEach(item => item?.set({ isOpened: false }));
                canvas.renderAll();
            }
            commentFormWrapperRef.current.style.display = 'none';
        }

        prevSelectedCommentIcon.current = selectedCommentIcon;
    }, [selectedCommentIcon, canvas]);


    // if any element that has attached comments moved, update attached comments position 
    (0, useEffect)(() => {
        if (attachedCommentsUpdatePos.length > 0) {
            for (const updatedComment of attachedCommentsUpdatePos) {
                socketRef.current.emit('updateComment', {
                    uuid: updatedComment.commentID,
                    position: {x: updatedComment.left, y: updatedComment.top}
                })
            }
            setAttachedCommentsUpdatePos([]);
        }
    }, [attachedCommentsUpdatePos])

    // changes canvas.selectMode based on actionSelected
    // because we need objects to be unselectable in different select mode
    useEffect(() => {
        if (canvas) {
            if (actionSelected?.commentMode)  {
                canvas.selectMode = 'comment';
            } else if (actionSelected?.dragMode) {
                canvas.selectMode = 'pan';
            } else {
                canvas.selectMode = 'select';
            }
        }
    }, [canvas, actionSelected])

    // set canvas to canvases context
    useEffect(() => {
        if (canvas && pageData?.wbPageId && canvas.isCanvasInitalized && canvas?.contextContainer) {
            dispatchCanvasesContext({type: CANVASES_CTX_ACTIONS.SET_CANVAS, payload: { canvas, pageId: pageData.wbPageId } })
            if (pageData?.forNima) {
                eventEmitter.fire(EMITTER_TYPES.NIMA_CANVAS_INITIALIZED, canvas)
            }
        }
    }, [canvas, dispatchCanvasesContext, pageData?.wbPageId, canvas?.contextContainer])

    useEffect(() => {
        const changeSelectModeHandler = (mode) => {
            canvas.isDrawingMode = false;

            if (mode === 'pan') {
                removeCanvasListener(canvas);
                canvas.isDrawingMode = false;
                canvas.toggleDragMode(canvas, true);
            } else if (mode === 'comment')  {
                canvas.toggleDragMode(canvas, false);
                changeCursorInCommentMode(canvas);
            } else { // normal select mode
                canvas.toggleDragMode(canvas, false);
                onSelectMode(canvas, onMouseDownLineHandler);
            }
        }
        /**
         * @param {'frame'|'ARROW'|'Sticky'|'text'|'table'} type 
         */
        const createShapeHandler = ({ type, options }) => {
            if (type !== 'ARROW') { canvas.toggleDragMode(canvas, false); }
            if (type === 'frame') {
                createFrame(canvas, emitOnMouseDown, onMouseDownLineHandler, uuidGenerator, handleChangeSelectMode);
            } else if (type === 'ARROW') {
                genericShapeButton(selectedArrowType);
            } else if (type === 'Sticky') {
                createSticky(canvas, emitOnMouseDown, onMouseDownLineHandler, handleChangeSelectMode, userId);
            } else if (type === 'text') {
                createText(canvas, emitOnMouseDown, onMouseDownLineHandler, handleChangeSelectMode);
            } else if (type === 'table') {
                createTable(canvas, emitOnMouseDown, onMouseDownLineHandler, handleChangeSelectMode, options);
            }
        }
        /**
         * @param {object} data 
         * @param {fabric.Object} data.e
         * @param {fabric.Object} data.drawInstance
         */
        const emitOnMouseDownListener = (data) => {
            // Emit only if rendered page is also the selected page 
            if (data?.e && data?.drawInstance && pageData.id === selectedPageId) {
                emitOnMouseDown(data.e, data.drawInstance);
            }
        }
        if (eventEmitter && canvas) {
            eventEmitter.on(EMITTER_TYPES.CHANGE_SELECT_MODE, changeSelectModeHandler);
            eventEmitter.on(EMITTER_TYPES.CREATE_GENERIC_SHAPE, genericShapeButton);
            eventEmitter.on(EMITTER_TYPES.CREATE_SHAPE, createShapeHandler);
            eventEmitter.on(EMITTER_TYPES.EMIT_ON_MOUSE_DOWN, emitOnMouseDownListener)
        }

        return () => {
            if (eventEmitter && canvas) {
                eventEmitter.off(EMITTER_TYPES.CHANGE_SELECT_MODE, changeSelectModeHandler);
                eventEmitter.off(EMITTER_TYPES.CREATE_GENERIC_SHAPE, genericShapeButton);
                eventEmitter.off(EMITTER_TYPES.CREATE_SHAPE, createShapeHandler)
                eventEmitter.off(EMITTER_TYPES.EMIT_ON_MOUSE_DOWN, emitOnMouseDownListener)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventEmitter, canvas, pageData, selectedPageId])

    useEffect(() => {
        if (eventEmitter && canvas) {
            eventEmitter.on('initializeStackOrder', handleInitializeStackOrder);
            eventEmitter.on('frameDetachmentUpdate', handleFrameDetachmentUpdate);
        }
        return () => { 
            if (eventEmitter && canvas) {
                eventEmitter.off('initializeStackOrder', handleInitializeStackOrder);
                eventEmitter.off('frameDetachmentUpdate', handleFrameDetachmentUpdate)
            }
        }
    }, [eventEmitter, canvas])
    
    useEffect(() => {
        const collabLockHandler = (data) => {
            const user = users.find(u => u?.id === data?.userId);
            if (!user?.id) {
                return
            }
            
            const object = data.object;
            object.onShapeChanged();
            object.collabLocked = true;
            object.collabLockedBy = {
                id: user.id,
                name: user.name
            }
        }
        eventEmitter.on(EMITTER_TYPES.COLLAB_LOCK, collabLockHandler)
        return () => {
            eventEmitter.off(EMITTER_TYPES.COLLAB_LOCK, collabLockHandler)
        }
    }, [users, canvas])
    
    useEffect(() => {
        handleInitializeStackOrder();
    },[canvas,triggerStack])
    
    useEffect(() => {
        const handleUpdateZIndexes = (data) => {
            let objects = canvas.getObjects();
            let modifiedObjects = [];
            const decompressedData = decompressData(data);
            if (decompressedData.stackOrder && decompressedData.stackOrder.length) {
                const stackOrderObject = decompressedData.stackOrder.reduce((obj, item) => {
                    obj[item.uuid] = item
                    return obj
                }, {})
                objects.forEach(item => {
                    if (stackOrderObject[item.uuid] !== undefined && stackOrderObject[item.uuid]?.zIndex && (item?.zIndex !== stackOrderObject[item.uuid]?.zIndex)) {
                        item.zIndex = stackOrderObject[item.uuid]?.zIndex
                        item.onOrderChanged();
                        modifiedObjects.push({ action: 'modified', properties: { ...item, zIndex: stackOrderObject[item.uuid]?.zIndex },uuid:item.uuid})
                    }
                })
                setObjectsStackWithZIndex(canvas);

                canvas.fire('add-to-history', {
                    action: 'stackOrder',
                    affectedObjects: modifiedObjects,
                    canvas
                });

                stackOrderRef.current = objects.map(i => {
                    return {zIndex: i.zIndex, uuid: i.uuid}
                })
                canvas.renderAll();
            }
        }
        if(eventEmitter && canvas) {
            eventEmitter.on('sendBackFront', handleUpdateZIndexes)
        }
        return () => {
            if(eventEmitter && canvas) {
                eventEmitter.off('sendBackFront', handleUpdateZIndexes)
            }
        };
    }, [eventEmitter, canvas]);
    

    const initCanvas = (whiteBoardId, boardName) => {
        // initalize bboard_options
        options = {
            currentMode: '',
            currentColor: '#000000',
            currentWidth: 1,
            fill: false,
            group: {},
            boardName: boardName,
            hideComments: { [whiteBoardSlugId]: false },
            hideResolvedComments: { [whiteBoardSlugId]: false }
        };

        const bboardOptions = getLocalStorage('bboard_options')

        setLocalStorage('bboard_options', bboardOptions ?? JSON.stringify(options));

        const canvas = new fabric.Canvas(`canvas-${pageId}`, {
            height: window.innerHeight,
            width: window.innerWidth,
            preserveObjectStacking: true, // if it sets to the true, objects hold their position while other object dragging
            targetFindTolerance: 5,
            initializeWithEngine: true,
            pageId
        });
        
        canvas.lockManager = lockManager;

        canvas.on('object:added', e => {
            if (e.target.type === 'commentRefWrapper') return;
            e.target.on('mousedown', removeOrSelectObject(canvas));
      
            // hold comments in top of all other elements  
            if (e.target.type !== 'comment') { // if added object is not a comment, then bring all comments to front
                canvas.forEachObject(obj => {
                    if (obj.type === 'comment') {
                        obj.bringToFront();
                    }
                })
            }
            // add preventSelectForFirstClick property to textbox for avoiding selecting text when first double click on textbox
            if (e.target.type === 'textbox') {
                e.target.preventSelectForFirstClick = true;
                e.target.on('editing:exited', () => {
                    e.target.preventSelectForFirstClick = true;
                });
            }
            if (e?.target?.flowchartProps) {
                eventEmitter.fire(EMITTER_TYPES.FLOWCHART_ITEM_ADDED);
            }
        });

        canvas.on('object:moving', e => {
            moveHandlerForFrame(e, canvas);
            if (e.target.type !== 'activeSelection') {
                canvas.fire('single-object:moving', {target: e.target, withEvent: e});
            }
        });

        canvas.on('frame-scaling', target =>{
            detachOrChangePositionOfObject(canvas, target);
            attachObjectsDuringResizing(canvas, target);
        })
        canvas.on('object:scaling', e => {
            // change width and height of frame when scaling
            if(e.target.type ==='textbox' || e.transform) {
                canvas.fire('new_text_scale', e.transform?.target.scaleX);
            }
            const target = e.target;
            if (target.type && target.type === 'frame') {
                detachOrChangePositionOfObject(canvas, target);
                attachObjectsDuringResizing(canvas, target);
            }
            if (target.type === 'table' && target.editingTextbox) {
                target.editingTextbox.exitEditing();
            }
            handleObjectScalingAndResizing(e, canvas);
        });
        canvas.on('object:rotating', (e) => handleObjectRotating(e, canvas))
        canvas.on('object:resizing', (e) => handleObjectScalingAndResizing(e, canvas));
        canvas.on('text:changed', (text) => {
            if (isShapeOrStickyEditing.current === false) { adjustTextBoxWhileTyping(text, canvas); }
        });
    
        canvas.on('shapeText-editing', ()=>{
            isShapeOrStickyEditing.current = true;
        });
        canvas.on('textbox-editing', ()=>{
            isShapeOrStickyEditing.current = false;
        });
        canvas.on('exit-shape-text-editing',()=>{
            isShapeOrStickyEditing.current = false;
        });
        canvas.on('text:editing:entered', e => {
            const { target } = e;
            // if textbox has placeholder, remove it.
            if (target && target.enablePlaceholder) {
                target.onShapeChanged();
                target.text = ''
                target.hiddenTextarea.value = '';
                target.fill = SHAPE_DEFAULTS.TEXT_COLOR;
                target.enablePlaceholder = false;
            }
        });
        canvas.on('text:editing:exited', e => {
            const { target } = e;
            if(!target){                
                return;
            }
            if (target.text.trim() === '') {
                target.onShapeChanged();
                target.text = PLACEHOLDER_TEXT;
                target.fill = SHAPE_DEFAULTS.PLACEHOLDER_COLOR;
                target.enablePlaceholder = true;
            }
        });

        canvas.on('mouse:move', (opt) => {
            canvas.fire('hyperlink:mousemove', opt);
        });

        const clearCachingAfterRender = debounce(() => {
            delete canvas.cacheOperation;
            delete canvas.cacheInitialValues;
        }, 200);

        const clearCachingAfterTextChanges = ({ target }) => {
            canvas.cacheOperation = 'DISABLED';
            delete target.cannotForceToCache;
            canvas.requestRenderAll();
            delete canvas.cacheOperation;
        }

        const afterMouseWheel = debounce(() => {
            canvas.cacheOperation = 'DISABLED';
            canvas.requestRenderAll();
            canvas.off('after:render', clearCachingAfterRender);
            canvas.on('after:render', clearCachingAfterRender);
        }, 300);

        canvas.on('mouse:wheel', (opt) => onWheelZoomInOut(opt, canvas));
        canvas.on('mouse:wheel', (...args) => {
            fabric.util.cancelAnimFrame(mouseWheelAnimRef.current.afterMouseWheel);
            mouseWheelAnimRef.current.afterMouseWheel = fabric.util.requestAnimFrame(() => afterMouseWheel(...args));

            const activeObj = canvas.getActiveObject();
            if (activeObj && activeObj.type === 'curvedLine') {
                activeObj.organizeControls();
            }
        });
        canvas.on('mouse:wheel_from_minimap', (...args) => {
            fabric.util.cancelAnimFrame(mouseWheelAnimRef.current.afterMouseWheel);
            mouseWheelAnimRef.current.afterMouseWheel = fabric.util.requestAnimFrame(() => afterMouseWheel(...args))
        });
        canvas.on('text:editing:exited', (...args) => {
            fabric.util.cancelAnimFrame(mouseWheelAnimRef.current.clearCachingAfterTextChanges);
            mouseWheelAnimRef.current.clearCachingAfterTextChanges = fabric.util.requestAnimFrame(() => clearCachingAfterTextChanges(...args))
        });

        canvas.on('mouse:dblclick', e => {
            const { target } = e;
            // if target is textbox, select word
            if (target && target.shapeType && target.shapeType === 'textbox') {
                // if prevent select for first click is true, then do not select word
                if (target.preventSelectForFirstClick) {
                    target.preventSelectForFirstClick = false;
                } else {
                    target.selectWord(target.getSelectionStartFromPointer(e));
                }
            }
        })
        canvas.on('single-object:moving', ({target, withEvent: e}) => {
            let useOriginalTransform = false;
            if (target.duplicateShadowObj && e?.transform?.original) {
                useOriginalTransform = true;
            }
            shapeLineMoveHandler(
                target, 
                canvas, 
                {
                    useOriginalTransform,
                }, 
                e
            );
        });

        onMouseDownLineHandler(canvas);

        // clear canvas
        canvas.clear();

        return canvas;
    };



    const removeOrSelectObject = (canvas) => {
        return e => {
            // since this function is called in an event, we need to get the latest state from the store instead of using selector
            const storeData = store.getState();
            const currentMode = storeData?.board?.currentMode;

            if (e.target.type === 'comment' || e.target.type === 'mockFrame') return;  // disable subtoolbar for comments
            if (e.target.shapeType && e.target.shapeType === 'frameText') return;  // disable subtoolbar for frameText
            if (currentMode === modes.ERASER && isUserHasAccessToFeature('remove_object', userAccessRef.current)) {
                if (isTargetLocked(e.target)) return;
                canvas.fire('remove-object', { activeObject: e.target });
            } 
        };
    };

    const onMouseDownLineHandler = (canvas) => {
        canvas.on('mouse:down', (e) => {
            let comments = canvas.getObjects().filter(item => item.type === 'comment');
            canvas.renderAll();
            isMouseDown.current = true;
            // checking if clicked comment item is equal to opened one
            if (comments.filter(item => item.isOpened)[0]?.commentID !== e?.transform?.target?.commentID) {
                setSelectedCommentIcon(null);
            }
            commentFormWrapperRef.current.style.display = 'none';
            setCommentState('initial');
            checkIfClickOutSideTheLasso(canvas, isLassoRef.current);

            // Implemented for updating hypothetical points positions.
            if (e?.target?.type === 'curvedLine') {
                e.target.organizeControls();
            }
        });
    
        canvas.on('object:moving', e => {
            if (!canvas.attachedObjectMoveInfo) canvas.attachedObjectMoveInfo = {affactedObjectUuids: []};
            if (e.target.attachedComments) {  // if any comment attached to this element, move the comment with this element
                attachedShapeMoveHandler(e.transform.target);
                canvas.attachedObjectMoveInfo = {
                    isMoved: true,
                    affactedObjectUuids: [
                        e.target.uuid
                    ]
                }
            }
            if (e.target.type === 'frame') {
                const frameShapes = canvas.getObjects().filter(el => el.attachedFrameId === e.target.uuid && el.type !== 'comment');
                const affectedObjUuids = [...canvas.attachedObjectMoveInfo?.affactedObjectUuids || []];
                frameShapes.forEach(shape => {
                    if (shape.attachedComments) {
                        attachedShapeMoveHandler(shape);
                        if (!affectedObjUuids.includes(shape.uuid)) affectedObjUuids.push(shape.uuid);
                        canvas.attachedObjectMoveInfo.isMoved = true;
                    }

                    // handle comment moving on nested frame
                    if (shape.type === 'frame') {
                        const nestedFrameShapes = canvas.getObjects().filter(el => el.attachedFrameId === shape.uuid && el.type !== 'comment');

                        nestedFrameShapes.forEach(nestedAttachedShape => {
                            if (nestedAttachedShape.attachedComments) {
                                attachedShapeMoveHandler(nestedAttachedShape);
                                if (!affectedObjUuids.includes(nestedAttachedShape.uuid)) affectedObjUuids.push(nestedAttachedShape.uuid);
                            }
                            canvas.attachedObjectMoveInfo.isMoved = true;
                        });
                    }

                    canvas.attachedObjectMoveInfo.affactedObjectUuids = affectedObjUuids;
                });
            }
            if (e.target.type === 'activeSelection') {
                e.target.forEachObject(shape => {
                    try {
                        if (!e?.target?.duplicateShadowObj) {
                            if (shape.attachedComments) {
                                attachedShapeMoveHandler(shape);
                                canvas.attachedObjectMoveInfo.isMoved = true;
                                if (!canvas.attachedObjectMoveInfo.affactedObjectUuids.includes(shape.uuid)) canvas.attachedObjectMoveInfo.affactedObjectUuids.push(shape.uuid);
                            }
                            if (shape?.type === 'curvedLine') {
                                if (shape.leftPolygon) {
                                    handleLineMovementInActiveSelection(shape, 'left', canvas, e.target);
                                }
                                if (shape.rightPolygon) {
                                    handleLineMovementInActiveSelection(shape, 'right', canvas, e.target);
                                }
                            }
                        }

                    } catch (err) {
                        console.error('Error in object:moving event', err);
                    }

                    if (!e?.target?.duplicateShadowObj) {
                        if(shape.type !== 'lasso')  canvas.fire('single-object:moving', {target: shape, withEvent: e});
                    } else if (shape?.lines?.length) {
                        shapeLineMoveHandler(
                            shape,
                            canvas,
                            {
                                useOriginalTransformForGroup: true,
                            },
                            e
                        )
                    }
                })
            }
        });
        canvas.on('mouse:up', (e) => {
            isMouseDown.current = false;
            try {
                if (canvas.attachedObjectMoveInfo && canvas.attachedObjectMoveInfo.isMoved) {
                    if (canvas.attachedObjectMoveInfo.affactedObjectUuids && canvas.attachedObjectMoveInfo.affactedObjectUuids.length > 0) {
                        canvas.attachedObjectMoveInfo.affactedObjectUuids.forEach(uuid => {
                            const element = canvas.getObjects().find(el => el.uuid === uuid);
                            if (element && element.attachedComments) {
                                for (const comment of element.attachedComments) {
                                    socketRef.current.emit('updateComment', {
                                        uuid: comment.commentID,
                                        position: {x: comment.left, y: comment.top}
                                    })
                                }
                            }
                        });
                    }
                }

                // Implemented for updating hypothetical points positions.
                if (e?.target?.type === 'curvedLine') {
                    if (!e?.target?.mouseUpHandledWithControl) {
                        e.target.organizeControls();
                    }
                    e.target.mouseUpHandledWithControl = false;
                }
            } catch (err) {
                console.error('Error in mouse:up event', err);
            }
            // clear attachedObjectMoveInfo
            canvas.attachedObjectMoveInfo = {
                isMoved: false,
                affactedObjectUuids: []
            }
        });

        canvas.on('mouse:move', (opt) => {
            canvas.fire('hyperlink:mousemove', opt);
        });
    }

    const emitOnMouseDown = useCallback((e, drawInstance, isModified) => {
        if (isModified) {
            let obj = createObjectToBeEmitted(whiteBoardId, userId, customToObject(e));
            obj = {...obj, actionTaken: 'modified'};
            emitData([obj]);
        }
        else if (e.type !== 'rect' && e.type !== 'ellipse' && e.type !== 'triangle') {
            mapFieldsToDrawInstance(canvas, drawInstance, whiteBoardId, userId);
        }
    }, [canvas, emitData, mapFieldsToDrawInstance, userId, whiteBoardId]);

    const genericShapeButton = (shape) => {
        switch (shape.shapeButton) {
            case 'TRIANGLE':
                createTriangle(canvas, emitOnMouseDown, onMouseDownLineHandler, handleChangeSelectMode);
                break;
            case 'RHOMBUS':
                createRhombus(canvas, emitOnMouseDown, onMouseDownLineHandler, handleChangeSelectMode);
                break;
            case 'PARALLELOGRAM':
                createParallelogram(canvas, emitOnMouseDown, onMouseDownLineHandler, handleChangeSelectMode);
                break;
            case 'RECTANGLE':
                createRect(canvas, emitOnMouseDown, onMouseDownLineHandler, handleChangeSelectMode);
                break;
            case 'ELLIPSE':
                createEllipse(canvas, emitOnMouseDown, onMouseDownLineHandler, handleChangeSelectMode);
                break;
            case 'LINE' :
                createCurvedLine({canvas, emitOnMouseDown, onMouseDownLineHandler, isArrow: true, isArrowRightEnabled: false, isInitiallyConnector: false}, handleChangeSelectMode);
                break;
            case 'PENCIL':
                draw(canvas, onMouseDownLineHandler);
                break;
            case 'ERASER':
                changeToErasingMode(canvas);
                break;
            case 'ARROW' :
                createCurvedLine({canvas, emitOnMouseDown, onMouseDownLineHandler, isArrow: true, isArrowRightEnabled: true, isInitiallyConnector: false}, handleChangeSelectMode);
                break;
            case 'LASSO' :
                createLasso(canvas, onMouseDownLineHandler);
                break;
            default:
        }
    };

    const handleChangeSelectMode = (selectMode) => {
        const mode = selectMode === 'comment' ? 'comment_modification' : selectMode;
        if (handleToolbarItemVisibility(mode)) { return; }

        canvas.isDrawingMode = false;

        if (selectMode === 'pan') {
            removeCanvasListener(canvas);
            canvas.isDrawingMode = false;
            canvas.toggleDragMode(canvas, true);
        } else if (selectMode === 'comment')  {
            canvas.toggleDragMode(canvas, false);
            changeCursorInCommentMode(canvas);
        } else { // normal select mode
            canvas.toggleDragMode(canvas, false);
            onSelectMode(canvas, onMouseDownLineHandler);
        }

        eventEmitter.fire(EMITTER_TYPES.CHANGE_ACTION_MODE, selectMode);
    }

    const handleSingleObjectModification = (event, target) => {
        if (target.type === 'commentRefWrapper' || target.type ==='lasso') return;  // prevent ref wrapper's update
        if (target.type === 'comment') {
            socketRef.current.emit('updateComment', {
                uuid: target.commentID,
                position: {x: target.left, y: target.top}
            })
            return;
        }
        queueSetRef.current.add(target.uuid);
        let oldAttachedShapes = [];
        if (target.type === 'frame') {
            oldAttachedShapes = target.attachments ? [...target.attachments] : [];
            target.attachments = getFrameAttachedShapeUuids(target);
        }
        if (target.type === 'curvedLine') {
            target.onShapeChanged();
            setLinePointsForCurrentPosition(target);
            detachControl(canvas, target);
            target.calculateBoundingBoxForCurvedLine();
        }

        if (target.type !== 'activeSelection') {
            target.modifiedBy = userId;
            if (target?.lines?.length && Array.isArray(target?.lines)) {
                target?.lines.forEach(lineUuid => {
                    let line = canvas.getObjects().filter(e => e.uuid === lineUuid)[0];
                    if (line) {
                        queueSetRef.current.add(line.uuid);
                    }
                });
            }
        }

        const handleFrameAttachmentUpdate = (attachedObj) => {
            attachedObj.modifiedBy = userId;
            queueSetRef.current.add(attachedObj.uuid);
            // update attached shape line positions (emitting)
            if (attachedObj.lines) {
                attachedObj.lines.forEach(lineUuid => {
                    let line = canvas.getObjects().find(obj => obj.uuid === lineUuid && obj.shapeType === 'curvedLine');
                    if (line) {
                        line.modifiedBy = userId;
                        queueSetRef.current.add(line.uuid);
                    }
                });
            }
        }
        if (target.type === 'frame') {
            // add frame attachments or objects that are detached from frame to the queue
            target.modifiedBy = userId;
            const updatedAttachments = [];
            const detachedObjects = [];

            const frameObjects = canvas.getObjects().filter(
                obj => obj.uuid && obj.attachedFrameId === target.uuid
            );
            const frameAttachments = frameObjects.map(obj => obj.uuid);
            for (const attachedObj of frameObjects) {
                handleFrameAttachmentUpdate(attachedObj);
                updatedAttachments.push(attachedObj);

                if (attachedObj.type === 'frame') {
                    const nestedFrameObjects = canvas.getObjects().filter(
                        obj => obj.uuid && obj.attachedFrameId === attachedObj.uuid
                    ); 

                    for (const nestedAttachedObj of nestedFrameObjects) {
                        handleFrameAttachmentUpdate(nestedAttachedObj);
                        updatedAttachments.push(nestedAttachedObj);
                    }
                }
            }
            for (const attachmentUuid of oldAttachedShapes) {
                if (!frameAttachments.includes(attachmentUuid)) {
                    const detachedObj = canvas.getObjects().filter(obj => obj.uuid === attachmentUuid)[0];
                    if (detachedObj) {
                        detachedObj.modifiedBy = userId;
                        queueSetRef.current.add(detachedObj.uuid);
                        detachedObjects.push(detachedObj);
                    }
                }
            }
            canvas.fire('frame-modified', { frame: target, updatedAttachments, detachedObjects });  // for activity logs
        }
    }

    return (
        <div className={clsx('pageWrapper', {
            'hidePage': selectedPageSlugId !== pageData?.wbPageId,
        })}>
            <canvas className="lower-canvas" height="787.5" id={`canvas-${pageId}`} ref={canvasRef} style={{ position: 'absolute', width: '1400px', height: '630px', left: '0px', top: '0px' }} width="1750" />
            {/* {
        (isLoading && shouldLoadData) && <div className="pageWrapper__loading">
          <LoadingScreen/>
        </div>
      } */}
        </div>
    );
}

Board.propTypes = {
    pageId: PropTypes.number,
    isPageRendering: PropTypes.func,
    getPageCanvas: PropTypes.func,
    handleToolbarItemVisibility: PropTypes.func,
    shouldLoadData: PropTypes.bool,
    whiteBoardSlugId: PropTypes.string,
    pageData: PropTypes.shape({
        id: PropTypes.number,
        wbPageId: PropTypes.string,
        pageName: PropTypes.string,
        pageNo: PropTypes.number,
        properties: PropTypes.shape({
            createdBy: PropTypes.number,
            modifiedBy: PropTypes.number
        }),
        shapes: PropTypes.array,
        stackOrder: PropTypes.array,
        active: PropTypes.bool,
        isFetched: PropTypes.bool,
        forNima: PropTypes.bool
    }),
    userAccessRef: PropTypes.shape({
        current: PropTypes.oneOf(['view', 'comment', 'edit', 'removeAccess', 'NOT_ALLOWED'])
    }),
    socketRef: PropTypes.shape({
        current: PropTypes.object
    }),
    isLassoRef: PropTypes.shape({
        current: PropTypes.bool
    }),
    userId: PropTypes.number,
    isJoinedToSocketRoom: PropTypes.bool,
    whiteBoardId: PropTypes.number,
    mapFieldsToDrawInstance: PropTypes.func,
    uuidGenerator: PropTypes.func,
    selectedComment: PropTypes.shape({
        id: PropTypes.number,
        whiteboardId: PropTypes.number,
        pageId: PropTypes.number,
        userId: PropTypes.number,
        parentId: PropTypes.number,
        content: PropTypes.string,
        contentSearch: PropTypes.string,
        taggedUserIds: PropTypes.any,
        resolved: PropTypes.any,
        position: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number
        }),
        colorCode: PropTypes.string,
        uuid: PropTypes.string,
        parentUuid: PropTypes.any,
        isDeleted: PropTypes.bool,
        isEdited: PropTypes.bool,
        threadLink: PropTypes.any,
        resolvedBy: PropTypes.object,
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
        replies: PropTypes.arrayOf(PropTypes.shape({
            userId: PropTypes.number,
            uuid: PropTypes.string,
            content: PropTypes.string,
            parentUuid: PropTypes.string,
            isDeleted: PropTypes.bool,
            user: PropTypes.shape({
                name: PropTypes.string,
            }),
            createdAt: PropTypes.string,
            userComment: PropTypes.arrayOf(PropTypes.shape({
                readAt: PropTypes.string
            }))
        })),
        userComment: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            userId: PropTypes.number,
            readAt: PropTypes.any
        })),
        user: PropTypes.shape({
            commentUsername: PropTypes.string,
            name: PropTypes.string
        })
    }),
    setSelectedComment: PropTypes.func,
    selectedCommentIcon: PropTypes.object,
    setSelectedCommentIcon: PropTypes.func,
    commentFormWrapperRef: PropTypes.shape({
        current: PropTypes.instanceOf(HTMLElement)
    }),
    commentWrapperRef: PropTypes.shape({
        current: PropTypes.instanceOf(HTMLElement)
    }),
    queueSetRef: PropTypes.shape({
        current: PropTypes.object
    }),
    emitData: PropTypes.func,
    emitQueue: PropTypes.func,
    actionSelected: PropTypes.shape({
        selectMode: PropTypes.bool,
        lassoMode: PropTypes.bool,
        dragMode: PropTypes.bool,
        commentMode: PropTypes.bool,
        pencilMode: PropTypes.bool,
        eraseMode: PropTypes.bool,
        deleteMode: PropTypes.bool,
        rectMode: PropTypes.bool,
        ellipseMode: PropTypes.bool,
        triangleMode: PropTypes.bool,
        arrowMode: PropTypes.bool,
        lineMode: PropTypes.bool,
        stickyMode: PropTypes.bool,
        frameMode: PropTypes.bool,
        textMode: PropTypes.bool,
        undoMode: PropTypes.bool,
        redoMode: PropTypes.bool,
        tableMode: PropTypes.bool
    }),
    selectedArrowType: PropTypes.shape({
        shapeName: PropTypes.string,
        shapeMode: PropTypes.string,
        shapeButton: PropTypes.string,
        shapeType: PropTypes.string,
        shapeSvg: PropTypes.func
    }),
    hideComments: PropTypes.bool,
    hideResolvedComments: PropTypes.bool,       
    setCommentState: PropTypes.func,
    eventQueueRef: PropTypes.shape({
        current: PropTypes.shape({
            queue: PropTypes.array,
            isProcessing: PropTypes.bool,
            add: PropTypes.func
        })
    }),
    canvasRef: PropTypes.shape({
        current: PropTypes.instanceOf(HTMLCanvasElement)
    }),
    forceUpdate: PropTypes.func,
    setShowLoader: PropTypes.func,
    isFrameNotFoundFlowRunOnce: PropTypes.shape({
        current: PropTypes.bool
    }),
    lockManager: CanvasLockManager
}

export default Board;
