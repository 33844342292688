import { fabric } from 'fabric';
import { arrangeTextInsideShape, cloneFabricObjectHelper, createFabricInstance, isTargetIncludeText, makeObjectUnselectable } from './FabricMethods';
import onFrameDrawn from './frame/OnFrameDrawn';
import generateFrameText from './frame/GenerateFrameText';
import { attachObjectToFrameDuringCreation } from './frame/FrameMethods';
import { onTableDrawn } from './table/TableMethods';
import { generateTableTitle } from './table/TableEventHandlers';

/**
 * @param {object} properties - The object properties to be cloned.
 * @param {fabric.Canvas} canvas - The canvas instance.
 * @param {object} options
 * @param {number=} options.modifiedBy - The user id of the user who modified the object.
 * @param {number=} options.createdBy - The user id of the user who created the object.
 * @param {boolean=} options.isRestored - If true, the object will be marked as restored.
 * @param {boolean=} options.hideFromActivityLog - If true, the object will not be logged in activity log.
 * @param {number=} options.x - New position x.
 * @param {number=} options.y - New position y.
 * @param {boolean=} useObjOrigin - flag that tells to use object origin instead of hardcode origin `center`
 * @returns 
 */
export default async function cloneTargetWithProperties(properties, canvas, options = {}, useObjOrigin = false) {
    return new Promise((resolve) => {
        createFabricInstance(properties, function (objects) {
            objects.forEach(function (o) {
                let lines = [], leftPolygon, rightPolygon;
                let isAttachedToFrame = false;

                if (properties.leftPolygon)
                    createFabricInstance(properties.leftPolygon, function (objects) {
                        objects.forEach(function (o1) {
                            o1.toObject = cloneFabricObjectHelper(o1);
                            leftPolygon = o1;
                        });
                    });
                if (properties.rightPolygon)
                    createFabricInstance(properties.rightPolygon, function (objects) {
                        objects.forEach(function (o1) {
                            o1.toObject = cloneFabricObjectHelper(o1);
                            rightPolygon = o1;
                        });
                    });
                o.toObject = cloneFabricObjectHelper(o, {lines, leftPolygon, rightPolygon});
                // if object is a frame, objectCaching should be disabled
                if (o.type === 'frame') {
                    onFrameDrawn(o, canvas);

                    // if the frame is a flowchart name, no need to assign a new title
                    // otherwise, assign a new title
                    if (!properties.flowchartDuplicated) {
                        o.text =  generateFrameText(canvas);
                    }
                    o.attachments = [];
                } else if (o.type === 'table') {
                    onTableDrawn(o);
                    o.title = generateTableTitle(canvas);
                }
                if (o.type === 'textbox') {
                    o.objectCaching = false;
                }
                o.lockScalingFlip = true;
                o.isDuplicated = true;

                if (isTargetIncludeText(o)) {
                    const textObj = o.getObjects().find(o => o.type === 'textbox');
                    if (textObj) {
                        textObj.visible = true;
                        textObj.breakWords = true;
                        textObj.splitByGrapheme = false;
                        arrangeTextInsideShape(o);
                    }
                }

                if (options) {
                    if (options.modifiedBy) {
                        o.modifiedBy = options.modifiedBy;
                    }
                    if (options.createdBy) {
                        o.createdBy = options.createdBy;
                    }
                    if (options.isRestored) {
                        o.isRestored = options.isRestored;
                    }
                    if (options.hideFromActivityLog) {
                        o.hideFromActivityLog = true;
                    }
                    if (options.userId) {
                        o.createdBy = options.userId;
                        o.modifiedBy = options.userId;
                    }
                    if (options.isDynamic) {
                        o.isDynamic = true;
                    }
                }
                if (options.x && options.y) {
                    if(useObjOrigin){
                        o.setPositionByOrigin(new fabric.Point(options.x, options.y), o.originX, o.originY);
                    }else{
                        o.setPositionByOrigin(new fabric.Point(options.x, options.y), 'center', 'center');
                    }
                }
                
                isAttachedToFrame = attachObjectToFrameDuringCreation(canvas, o, true);

                // reset the options
                if (!isAttachedToFrame) {
                    o.calculatedPos = null;
                    o.wiredFrame = null;
                    o.attachedFrameId = null;
                }
                o.lines = [];

                // if select mode is comment or pan, make object unselectable
                if (canvas.selectMode === 'comment' || canvas.selectMode === 'pan') {
                    makeObjectUnselectable(o);
                }

                o.flowchartProps = null;
                o.constantFlowchartProps = null;

                if (!options?.avoidAdding) {
                    canvas.add(o);
                }
                
                resolve(o);
            });
        });
    });
}