/**
 *
 */
const IconClose = () => {
    return (
        <svg
            fill="none"
            height="18"
            viewBox="0 0 18 18"
            width="18"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.083 16.915L16.917 1.082M16.917 16.915L1.083 1.082"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.267"
            />
        </svg>
    )
}

export default IconClose;