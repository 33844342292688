import { getTextFromShape } from '../FabricMethods';
import { isObjectFlowchartFrame } from '../flowchart/CustomFlowchartShapeUtils';

/**
 * Transforms log list (user footsteps) to usable format.
 * @param logList
 */
const transformLogList = (logList) => {
    // uniq log item by shape uuid
    const seenList = [];
    const orderedLogList = [];
    for (const logItem of logList) {
        // if the log item is a flowchart item and its not a flowchart main frame, skip it
        if (
            logItem?.shape?.properties?.flowchartProps
            && logItem.actionTaken === 'created'
            && !isObjectFlowchartFrame(logItem?.shape?.properties)) {
            continue;
        }
        if (logItem.shape && !seenList.includes(logItem.shape.uuid)) {
            seenList.push(logItem.shape.uuid);
            orderedLogList.push(logItem);
        }

        if (logItem?.actionTaken === 'import' && logItem?.itemType === 'nima' && !seenList.includes(('importNima'))) {
            seenList.push('importNima');
            orderedLogList.push(logItem)
        }
        
        if (logItem?.actionTaken === 'whiteboardDuplicate') {
            seenList.push('whiteboardDuplicate');
            orderedLogList.push(logItem)
        }
    }

    // seperate log item day by day
    const logListWithDates = {}
    for (const logItem of orderedLogList) {
        const date = new Date(logItem.activityTime);
        const dateKey = date.toLocaleDateString();
        if (!logListWithDates[dateKey]) {
            logListWithDates[dateKey] = [];
        }
        logListWithDates[dateKey].push(logItem);
    }

    for (const dateKey in logListWithDates) {
        const collectedData = [];
        let prevUserId = null;

        for (const logItem of logListWithDates[dateKey]) {
            let modifiedLogItem = logItem;
            let itemText = null;
            try {
                if (logItem?.shape?.properties?.flowchartProps && isObjectFlowchartFrame(logItem?.shape?.properties)) { 
                    itemText = logItem?.shape?.properties?.flowchartProps?.flowchartName || null;
                } else if (logItem?.actionTaken !== 'whiteboardDuplicate' && logItem?.actionTaken !== 'import') {
                    itemText = getTextFromShape(logItem?.shape?.properties) || null;
                } 
            } catch (err) {
                console.error('Error while getting text from shape: ', err);
            }
            const thisUserId = logItem.userId;
            if (thisUserId === prevUserId) {
                collectedData[collectedData.length - 1].logs.push({...modifiedLogItem, itemText });
            } else {
                collectedData.push({
                    lastUpdatedAt: logItem.activityTime,
                    user: logItem.user,
                    logs: [modifiedLogItem]
                });
            }
            prevUserId = thisUserId;
        }

        logListWithDates[dateKey] = collectedData;
    }
    return logListWithDates;
}
export default transformLogList;