import React from 'react';
import clsx from 'clsx';
import Tick from '../svgIcons/Tick';
import {_hasLowerCase, _hasNumber, _hasUpperCase} from '../../helpers/Validation';
import {AUTH_VALIDATION_MESSAGE} from '../../helpers/Constant';
import PropTypes from 'prop-types';

function PasswordConfirmationBox({password, isActivated}) {
    return (
        <div className="container-warning">
            <div className={clsx('list-warning', {active: isActivated})}>
                <div
                    className={clsx('list-item', {confirmed: (password.length >= 8 && isActivated)})}>
                    {/*{(password.length < 8 && isPasswordConfirmStatementsActivated) ? <Cross /> : <Tick />}*/}
                    <Tick/>
                    <span className="text-warning">
                        {AUTH_VALIDATION_MESSAGE.PASSWORD_MIN_EIGHT_CHARS}
                    </span>
                </div>
                <div className="spacer"/>
                <div
                    className={clsx('list-item', {confirmed: ((_hasLowerCase(password) && _hasUpperCase(password)) && isActivated)})}>
                    {/*{((!_hasLowerCase(password) || !_hasUpperCase(password)) && isPasswordConfirmStatementsActivated) ? <Cross /> : <Tick />}*/}
                    <Tick/>
                    <span className="text-warning">
                        {AUTH_VALIDATION_MESSAGE.PASSWORD_CONTAIN_LOWERCASE_UPPERCASE}
                    </span>
                </div>
                <div className="spacer"/>
                <div
                    className={clsx('list-item', {confirmed: (_hasNumber(password) && isActivated)})}>
                    {/*{(!_hasNumber(password) && isPasswordConfirmStatementsActivated) ? <Cross /> : <Tick />}*/}
                    <Tick/>
                    <span className="text-warning">
                        {AUTH_VALIDATION_MESSAGE.PASSWORD_MIN_NUMBER}
                    </span>
                </div>
            </div>
        </div>
    );
}

PasswordConfirmationBox.propTypes = {
    password: PropTypes.string,
    isActivated: PropTypes.bool
}

export default PasswordConfirmationBox;