import clsx from 'clsx';
import LassoIcon from '../svgIcons/Lasso';
import {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {isUserHasAccessToFeature, removeCanvasListener} from '../../helpers/CommonFunctions';
import {arrowShape, EMITTER_TYPES} from '../../helpers/Constant';
import eventEmitter from '../../helpers/EventEmitter';

import './MoreTools.scss';
import useCanvas from '../../hooks/UseCanvas';
import changeCursor from '../../helpers/ChangeCursor';
import {removeAllExistedLasso} from '../../hooks/UseLasso';
import TableBox from '../tablebox/TableBox';
import ConnectorIcon from '../svgIcons/canvasRedesign/IconConnector';

const MoreTools = ({
    handleToolbarItemVisibility,
    actionSelected,
    userAccessRef,
    isLassoRef,
    handleAddObject,
}) => {
    const [selectedCustomTool, setSelectedCustomTool] = useState(arrowShape.shapeName);
    const [customToolList] = useState([
        {
            shapeName: 'Lasso',
            shapeSelector: 'lasso',  // shape ID
            shapeMode: 'lassoMode',
            shapeSvg: <LassoIcon color = {handleToolbarItemVisibility('lasso') ? '#6b6b6b' : '#3C3E49'} height="16" width="16"/>,
        },
        {
            shapeName: 'Table',
            shapeSelector: 'table',  // shape ID
            shapeMode: 'tableMode',
            shapeIcon: 'icon-table'
        },
        {
            ...arrowShape,
            shapeSvg: <ConnectorIcon color = {handleToolbarItemVisibility('arrow') ? '#6b6b6b' : '#3C3E49'} height="16" width="16"/>,
            shapeSelector: arrowShape.shapeName,
            innerSelectors: ['Line']
        }
    ])
    const [isOpenMoreTool, setIsOpenMoreTool] = useState(false);


    const moreToolsRef = useRef(null);
    const moreToolCloseTimeout = useRef(null);

    const canvas = useCanvas();

    const toggleMoreTool = useCallback((wantedVal)=>{
        if (wantedVal !== undefined && typeof wantedVal === 'boolean') {
            setIsOpenMoreTool(wantedVal);
            return;
        }
        setIsOpenMoreTool(!isOpenMoreTool);

    }, [isOpenMoreTool]);

    const openMoreTool = () => {
        clearTimeout(moreToolCloseTimeout.current);
        toggleMoreTool(true);
    }

    const closeMoreTool = () => {
        moreToolCloseTimeout.current = setTimeout(() => {
            toggleMoreTool(false);
        }, 200);
    }

    const addDefaultTable = () => handleAddObject('table', { totalRows: 3, totalCols: 3 })

    const handleToolSelect = (toolName) => {
        if (!isUserHasAccessToFeature(toolName, userAccessRef?.current)) {
            toggleMoreTool(false)
            return;
        }
        if (isLassoRef?.current) {
            isLassoRef.current = false;
        }
        if (canvas) {
            canvas.isDrawingMode = false;
            removeAllExistedLasso(canvas);
            changeCursor('select', canvas);
            removeCanvasListener(canvas)
        }

        // we are firing this event instead of selecting it directly
        // since we need to support selecting more tool items with shortcut keys
        eventEmitter.fire(EMITTER_TYPES.CHANGE_ACTION_MODE, toolName)

        if (toolName === 'table') {
            addDefaultTable()
        }

        // close more tool popup
        toggleMoreTool(false)
    }

    useEffect(() => {
        const changeActionModeHandler = (toolName) => {
            const validTools = customToolList?.map(customTool => customTool?.shapeSelector)
            if (!validTools?.length) {
                return;
            }
            validTools.push('Line')
            if (validTools.includes(toolName)) {
                setSelectedCustomTool(toolName)
                setIsOpenMoreTool(false)
            }
        }
        eventEmitter.on(EMITTER_TYPES.CHANGE_ACTION_MODE, changeActionModeHandler)

        return () => {
            eventEmitter.off(EMITTER_TYPES.CHANGE_ACTION_MODE, changeActionModeHandler)
        }
    }, []);

    const visibleCustomTools = customToolList.filter(customTool => {
        let isThisSelectedTool = customTool?.shapeSelector === selectedCustomTool

        // if there is innerSelectors, search them also
        if (!isThisSelectedTool && customTool?.innerSelectors && customTool?.innerSelectors?.includes(selectedCustomTool)) {
            return false
        }

        return !isThisSelectedTool
    });

    return (
        <div className="moreTools" ref={moreToolsRef}>
            {
                selectedCustomTool === 'lasso' ? <div
                    className={clsx('button', { active: actionSelected.lassoMode, disabled: handleToolbarItemVisibility('lasso') })}
                    onClick={() => {
                        handleToolSelect('lasso')
                    }}>
                    <div className="button__iconWrapper">
                        <LassoIcon color="#3C3E49" height={20} width={20}/>
                    </div>
                    <span className="tooltiptext">Lasso</span>
                </div> : null
            }
            {
                selectedCustomTool === 'table' ? <div className={clsx('addTableBtn button', { disabled: handleToolbarItemVisibility('table') })}>
                    <div
                        className={clsx('button haveSubToolbar disable-mb', { active: actionSelected.tableMode, disabled: handleToolbarItemVisibility('table')  })}
                        onClick={() => addDefaultTable()}
                    >
                        <div className="button__iconWrapper" style={{width: '100%', height: '100%'}}>
                            <em className="icon-table" />
                        </div>
                        <span className="tooltiptext">Table</span>
                    </div>

                    <TableBox handleAddObject={handleAddObject} />
                </div> : null
            }
            {
                (selectedCustomTool === 'Connector') ? <div className={clsx('button', { active: actionSelected[`${arrowShape.shapeMode}`], disabled: handleToolbarItemVisibility(arrowShape.shapeButton) })}>
                    <div
                        className={clsx('button__inner', { active: actionSelected[`${arrowShape.shapeMode}`], disabled: handleToolbarItemVisibility(arrowShape.shapeButton) })}
                        onClick={() => handleToolSelect(arrowShape.shapeName)}>
                        <div className="button__iconWrapper">
                            {
                                arrowShape.shapeClass ? <em className={arrowShape.shapeClass} /> : <arrowShape.shapeSvg />
                            }
                        </div>
                    </div>
                    {/* <div className="subToolbar tooltipTop">
                           {constShapes.filter(shape => shape.shapeType === "arrow").map((shape, index) => (
                               (shape.shapeName !== selectedArrowType.shapeName) ? <div
                                       key={shape.shapeName}
                                       className={clsx('button', {
                                           active: actionSelected[`${shape.shapeMode}`],
                                           disabled: handleToolbarItemVisibility(shape.shapeButton)
                                       })}
                                       onClick={() => handleToolSelect(shape.shapeName)}>
                                       {
                                           shape.shapeClass ? <em className={shape.shapeClass}></em> : <shape.shapeSvg />
                                       }
                                       <span className="tooltiptext">{shape.shapeName}</span>
                                   </div>
                                   : null
                           ))}
                       </div> */}
                    <span className="tooltiptext">{arrowShape.shapeName}</span>
                </div> : null
            }
            <div
                className={clsx('button haveMorePopup tooltipTop', {
                    disabled: handleToolbarItemVisibility('more_popup'),
                    active: isOpenMoreTool
                })}
                style={{marginTop: 0}}>
                <div
                    className='button__inner'
                    onMouseEnter={openMoreTool}
                    onMouseLeave={closeMoreTool}>
                    <div className="button__iconWrapper">
                        <em className="icon-canvas-redesign-menu" style={{fontSize: '20px'}} />
                    </div>
                </div>
                <div
                    className={clsx('popup', {open: isOpenMoreTool })}
                    onMouseEnter={openMoreTool}
                    onMouseLeave={closeMoreTool}
                >
                    <div className = "popupTitle">MORE TOOLS</div>
                    {
                        visibleCustomTools.map(customTool => (
                            <div
                                className={clsx('item', {
                                    disabled: handleToolbarItemVisibility(customTool?.shapeSelector),
                                })}
                                key={customTool?.shapeSelector}
                                onClick={() => handleToolSelect(customTool?.shapeSelector)}
                            >
                                {customTool.shapeSvg ? customTool.shapeSvg : (
                                    <em className={customTool.shapeIcon} />
                                )}
                                <div className='moreTitle'>{customTool?.shapeName}</div>
                            </div>
                        ))
                    }
                    {
                        !visibleCustomTools.length ? <span className="popupInfo">Tools are not available</span> : null
                    }
                </div>
            </div>
        </div>
    )
}

MoreTools.propTypes = {
    handleToolbarItemVisibility: PropTypes.func.isRequired,
    actionSelected: PropTypes.shape({
        selectMode: PropTypes.bool,
        lassoMode: PropTypes.bool,
        dragMode: PropTypes.bool,
        commentMode: PropTypes.bool,
        pencilMode: PropTypes.bool,
        eraseMode: PropTypes.bool,
        deleteMode: PropTypes.bool,
        rectMode: PropTypes.bool,
        ellipseMode: PropTypes.bool,
        triangleMode: PropTypes.bool,
        arrowMode: PropTypes.bool,
        lineMode: PropTypes.bool,
        stickyMode: PropTypes.bool,
        frameMode: PropTypes.bool,
        textMode: PropTypes.bool,
        undoMode: PropTypes.bool,
        redoMode: PropTypes.bool,
        tableMode: PropTypes.bool
    }),
    userAccessRef: PropTypes.shape({
        current: PropTypes.oneOf(['view', 'comment', 'edit', 'removeAccess', 'NOT_ALLOWED'])
    }),
    isLassoRef: PropTypes.shape({
        current: PropTypes.bool
    }),
    handleAddObject: PropTypes.func.isRequired,
    selectedArrowType: PropTypes.shape({
        shapeName : PropTypes.string,
        shapeMode : PropTypes.string,
        shapeButton: PropTypes.string,
        shapeSvg: PropTypes.any,
        shapeType : PropTypes.string,
        shapeClass : PropTypes.string
    })
}

export default MoreTools;