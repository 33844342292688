import React from 'react';
import PropTypes from 'prop-types';

const CheckCircle = ({
    width,
    height,
    filling,
    outline
}) => (
    <svg
        fill="none"
        height={height}
        viewBox="0 0 14 14"
        width={width}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            clipRule="evenodd"
            d="M7 12.6245C10.1066 12.6245 12.625 10.1061 12.625 6.99951C12.625 3.89291 10.1066 1.37451 7 1.37451C3.8934 1.37451 1.375 3.89291 1.375 6.99951C1.375 10.1061 3.8934 12.6245 7 12.6245Z"
            fill={filling}
            fillRule="evenodd"
            stroke={outline}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M4 7.61156L5.225 9.35006C5.31928 9.49089 5.47579 9.57749 5.64518 9.58255C5.81458 9.58761 5.97598 9.51051 6.0785 9.37556L10 4.41406"
            stroke={outline}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

CheckCircle.propTypes = {
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    filling: PropTypes.string,
    outline: PropTypes.string,
}

CheckCircle.defaultProps = {
    width: 24,
    height: 24,
    filling: 'white',
    outline: 'black'
}

export default CheckCircle;
