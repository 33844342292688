import { addCommentIcon } from './AddComment';

/**
 * Renders comment icons with given comment objects.
 * @param {object} props
 * @param props.canvas
 * @param props.comments
 * @param props.setSelectedCommentIcon
 * @param props.userId
 * @param props.shouldHideComments
 * @param props.shouldHideResolvedComments
 */
export default function renderAllComments({canvas, comments, setSelectedCommentIcon, userId, shouldHideComments, shouldHideResolvedComments}) {
    for (const comment of comments) {
        renderComment({canvas, comment, setSelectedCommentIcon, userId, shouldHideComment: shouldHideComments, shouldHideResolvedComment: shouldHideResolvedComments});
    }
}


/**
 * Renders single comment.
 * @param {object} props
 * @param props.canvas
 * @param props.comment
 * @param props.setSelectedCommentIcon
 * @param props.shouldHideComment
 * @param props.shouldHideResolvedComment
 * @param props.autoSelect
 */
export function renderComment({canvas, comment, setSelectedCommentIcon, shouldHideComment, shouldHideResolvedComment, autoSelect = false}) {
    if (comment.position) {
        const positionOfComment = typeof comment.position === 'string' ?  JSON.parse(comment.position) : comment.position;

        addCommentIcon({
            canvas, 
            left: positionOfComment.x,
            top: positionOfComment.y,
            colorCode: (comment.resolved ? 'black' : (comment.colorCode === 'black' ? 'purple' : comment.colorCode)) ?? 'purple',
            unreadMessagesCount: comment.unreadCommentCount,
            commentID: comment.uuid,
            autoSelect,
            resolved: comment.resolved,
            setSelectedCommentIcon,
            shouldHideComment,
            shouldHideResolvedComment
        })
    }
}
