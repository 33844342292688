import { calculateObjectPosition } from '../FabricMethods';

const attachedShapeMoveHandler = (object) => {
    if (object.attachedComments && object.attachedCommentDifs) {
        let movedComments = [];
        const objCoordinates = calculateObjectPosition(object);

        for (const comment of object.attachedComments) {
            const pointDifs = object.attachedCommentDifs[comment.commentID];

            comment.left = objCoordinates.tl.x - pointDifs.left;
            comment.top = objCoordinates.tl.y - pointDifs.top;
            comment.setCoords();
            movedComments.push(comment);
        }
        return movedComments;
    }
    return [];
};

export default attachedShapeMoveHandler;
