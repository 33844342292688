import React from 'react';
import PropTypes from 'prop-types';

import ZoomCard from '../../zoomCard/ZoomCard';

import { onZoomIn, onZoomOut } from '../../../hooks/PanZoom';

const ZoomAdjuster = ({
    canvas,
    canvasRef
}) => {
    const handleZoomBtnClick = (action = 'in') => {
        canvas.fire('stop-movement-of-object')
        if (action === 'in') {
            onZoomIn(canvas)
        } else {
            onZoomOut(canvas)
        }
    }
    
    return (
        <div className="zoomIn-zoomOut bottomBarButton">
            <button
                type="button"
                className="zoomOut"
                data-ignoreeventkeys
                onClick={() => handleZoomBtnClick('out')}
            >
                <em className="icon-canvas-redesign-zoom-out" />
            </button>

            <ZoomCard canvas={canvas} canvasRef={canvasRef} />

            <button
                type="button"
                className="zoomIn"
                data-ignoreeventkeys
                onClick={() => handleZoomBtnClick('in')}
            >
                <em className="icon-canvas-redesign-zoom-in" />
            </button>
        </div>
    )
}

ZoomAdjuster.propTypes = {
    canvas: PropTypes.object,
    canvasRef: PropTypes.shape({
        current: PropTypes.instanceOf(HTMLCanvasElement)
    })
}

export default ZoomAdjuster;
