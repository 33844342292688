class LinkedList {
    constructor() {
        this.head = null;
        this.tail = null;
        this.length = 0;
    }

    [Symbol.iterator]() {
        this.current = this.head;
        return this;
    }
    
    next() {
        if (this.current) {
            const value = this.current.value;
            this.current = this.current.next;
            return { done: false, value };
        } else {
            return { done: true };
        }
    }

    get size() {
        return this.length;
    }

    getHead() {
        return this.head?.value;
    }

    getTail() {
        return this.tail?.value;
    }

    append(value) {
        const newNode = this.createNode(value);
        this.appendNode(newNode);
    }

    prepend(value) {
        const newNode = this.createNode(value);
        this.prependNode(newNode);
    }

    removeHead() {
        const removedNode = this.removeHeadNode();
        return removedNode?.value;
    }

    removeTail() {
        const removedNode = this.removeTailNode();
        return removedNode?.value;
    }

    forEach(callback) {
        let current = this.head;
        while (current) {
            const next = current.next;
            if (callback(current.value) === false) return;
            current = next;
        }
    }

    forEachReverse(callback) {
        let current = this.tail;
        while (current) {
            const prev = current.prev;
            if (callback(current.value) === false) return;
            current = prev;
        }
    }

    clear() {
        this.head = null;
        this.tail = null;
        this.length = 0;
    }

    createNode(value) {
        return {
            prev: null,
            next: null,
            value
        };
    }

    appendNode(node) {
        if (!this.tail) {
            this.head = node;
        } else {
            this.tail.next = node;
            node.prev = this.tail;
        }
        this.tail = node;
        this.length++;
    }

    prependNode(node) {
        if (!this.head) {
            this.head = node;
            this.tail = node;
        } else {
            node.next = this.head;
            this.head.prev = node;
            this.head = node;
        }
        this.length++;
    }

    removeHeadNode() {
        if (this.head) {
            return this.unmountNode(this.head);
        }
    }

    removeTailNode() {
        if (this.tail) {
            return this.unmountNode(this.tail);
        }
    }

    unmountNode(node) {
        if (node.prev) node.prev.next = node.next;
        if (node.next) node.next.prev = node.prev;
        if (this.head === node) this.head = node.next;
        if (this.tail === node) this.tail = node.prev;
        node.next = null;
        node.prev = null;
        this.length--;
        return node;
    }
}

export class CachedLinkedList extends LinkedList {
    constructor(...args) {
        super(...args);
        this.map = new Map();
    }

    has(value) {
        return this.map.has(value);
    }

    insertAfter(newItem, targetItem) {
        if (newItem === targetItem) return true;
        let targetNode = this.map.get(targetItem);
        if (targetNode) {
            this.insertNodeAfter(this.createNode(newItem), targetNode);
            return true;
        }
        return false;
    }

    insertBefore(newItem, targetItem) {
        if (newItem === targetItem) return true;
        let targetNode = this.map.get(targetItem);
        if (targetNode) {
            this.insertNodeBefore(this.createNode(newItem), targetNode);
            return true;
        }
        return false;
    }

    remove(item) {
        let node = this.map.get(item);
        if (node) {
            this.unmountNode(node);
            this.map.delete(node.value);
            return true;
        }
        return false;
    }

    clear() {
        super.clear();
        this.map.clear();
    }

    createNode(value) {
        let node = this.map.get(value);
        if (node) {
            this.unmountNode(node);
        } else {
            node = super.createNode(value);
            this.map.set(node.value, node);
        }
        return node;
    }

    removeHeadNode() {
        let node = super.removeHeadNode();
        if (node) this.map.delete(node.value);
        return node;
    }

    removeTailNode() {
        let node = super.removeTailNode();
        if (node) this.map.delete(node.value);
        return node;
    }

    insertNodeAfter(newNode, targetNode) {
        newNode.next = targetNode.next;
        newNode.prev = targetNode;
        targetNode.next = newNode;
        if (newNode.next) newNode.next.prev = newNode;
        if (this.tail === targetNode) this.tail = newNode;
        this.length += 1;
    }

    insertNodeBefore(newNode, targetNode) {
        newNode.next = targetNode;
        newNode.prev = targetNode.prev;
        targetNode.prev = newNode;
        if (newNode.prev) newNode.prev.next = newNode;
        if (this.head === targetNode) this.head = newNode;
        this.length += 1;
    }
}