/**
 *
 */
const CursorIcon = () => {
    return (
        <svg
            fill="none"
            height="20"
            viewBox="0 0 20 20"
            width="20"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                clipRule="evenodd"
                d="M1.513 2.334l7.023 15.893a.625.625 0 001.176-.093l1.57-5.965a1.25 1.25 0 01.89-.891l5.965-1.57a.625.625 0 00.094-1.176L2.338 1.512a.625.625 0 00-.825.822z"
                fillRule="evenodd"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </svg>
    );
}

export default CursorIcon;