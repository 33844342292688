import { fabric } from 'fabric';
import { generateMockPointForLine, handleGeneratingLineMockPointWhileScaling } from '../../lines/LineMethods';
import { getApproxMinLineWidth } from '../../TextWrapHelpers';
import { getLocalPoint, isTransformCentered, scaleObject, wrapWithFireEvent, wrapWithFixedAnchor } from '../CommonControlHelpers';
import { getShapeTextareaDimensions } from '../../shapes/Common';
import { TEXTBOX_LINE_HEIGHT } from '../../Constant';

/**
 * @param e
 * @param transform
 * @param x
 * @param y
 */
function changeHeightOfSticky(e, transform, x, y) {
    const target = transform.target, localPoint = getLocalPoint(transform, transform.originX, transform.originY, x, y),
        strokePadding = target.strokeWidth / (target.strokeUniform ? target.scaleY : 1),
        multiplier = isTransformCentered(transform) ? 2 : 1,
        oldHeight = target.height,
        newHeight = Math.abs(localPoint.y * multiplier / target.scaleY) - strokePadding;

    const height = Math.max(newHeight, 0);
    const objects = target.getObjects();
    const textbox = objects[1];
    const image = objects[0];
  

    const textHeight = (textbox.__lineHeights.reduce((a, b) => a + b, 0)) * textbox.scaleY;

    if (textHeight + 30 < height) {
        image.set({
            height: height / image.scaleY,
        });
        target.set({
            height
        });
    }

    return oldHeight !== newHeight;
}


/**
 * @param e
 * @param transform
 * @param x
 * @param y
 */
function changeWidthOfSticky(e, transform, x, y) {
    const target = transform.target,
        targetObjects = target.getObjects(),
        localPoint = getLocalPoint(transform, transform.originX, transform.originY, x, y),
        strokePadding = target.strokeWidth / (target.strokeUniform ? target.scaleX : 1),
        multiplier = isTransformCentered(transform) ? 2 : 1,
        oldWidth = target.width,
        newWidth = Math.abs(localPoint.x * multiplier / target.scaleX) - strokePadding;

    const width = Math.max(newWidth, 0);
    const textbox = targetObjects[1];
    const targetRect = targetObjects[0];

    if (newWidth < 100) {
        return false;
    }

    targetRect.set({
        width: width / targetRect.scaleX,
    });
    target.set({
        width: width,
    });

    const { width: textWidth, padding} = getShapeTextareaDimensions(target);

    textbox.set({
        width: textWidth
    });

    if (textbox.height + padding > targetRect.height) {
        targetRect.set({
            height: ((textbox.height + 30) * textbox.scaleY) / targetRect.scaleY,
        });
        target.set({
            height: (textbox.height + 30) * textbox.scaleY,
        });
    }

    return (oldWidth !== newWidth);
}

/**
 * @param e
 * @param transform
 * @param x
 * @param y
 */
function changeHeightOfFrame(e, transform, x, y) {
    const target = transform.target, localPoint = getLocalPoint(transform, transform.originX, transform.originY, x, y),
        strokePadding = target.strokeWidth / (target.strokeUniform ? target.scaleY : 1),
        multiplier = isTransformCentered(transform) ? 2 : 1,
        oldHeight = target.height,
        newHeight = Math.abs(localPoint.y * multiplier / target.scaleY) - strokePadding;

    const height = Math.max(newHeight, 0);
    target.set({
        height: height,
    });
    target.canvas.fire('frame-scaling', target);
    return oldHeight !== newHeight;
}

/**
 * @param e
 * @param transform
 * @param x
 * @param y
 */
function changeWidthOfFrame(e, transform, x, y) {
    const target = transform.target,
        localPoint = getLocalPoint(transform, transform.originX, transform.originY, x, y),
        strokePadding = target.strokeWidth / (target.strokeUniform ? target.scaleX : 1),
        multiplier = isTransformCentered(transform) ? 2 : 1,
        oldWidth = target.width,
        newWidth = Math.abs(localPoint.x * multiplier / target.scaleX) - strokePadding;
    const width = Math.max(newWidth, 0);
    if (newWidth < 100) {
        return false;
    }
    target.set({
        width: width,
    });
    target.canvas.fire('frame-scaling', target);
    return (oldWidth !== newWidth);
}

const changeHeightOfRect = (e, transform, x, y) => {
    const target = transform.target, localPoint = getLocalPoint(transform, transform.originX, transform.originY, x, y),
        strokePadding = target.strokeWidth / (target.strokeUniform ? target.scaleY : 1),
        multiplier = isTransformCentered(transform) ? 2 : 1,
        oldHeight = target.height,
        newHeight = Math.abs(localPoint.y * multiplier / target.scaleY) - strokePadding;

    const height = Math.max(newHeight, 0);
    const objects = target.getObjects();
    const textbox = objects[1];
    const image = objects[0];


    const textHeight = (textbox.__lineHeights.reduce((a, b) => a + b, 0)) * textbox.scaleY;

    if (textHeight + 30 < height || textbox.text === '') {
        image.set({
            height: height / image.scaleY,
        });
        target.set({
            height,
        });
    }

    return oldHeight !== newHeight;
}

const changeWidthOfShape = (e, transform, x, y) => {
    const target = transform.target,
        targetObjects = target.getObjects(),
        localPoint = getLocalPoint(transform, transform.originX, transform.originY, x, y),
        strokePadding = target.strokeWidth / (target.strokeUniform ? target.scaleX : 1),
        multiplier = isTransformCentered(transform) ? 2 : 1,
        oldWidth = target.width,
        newWidth = Math.abs(localPoint.x * multiplier / target.scaleX) - strokePadding;
    const width = Math.max(newWidth, 0);
    const targetRect = targetObjects[0];
    const targetText = targetObjects[1];

    let defaultFont = `${targetText.fontSize}px / ${TEXTBOX_LINE_HEIGHT} ${targetText.fontFamily}`;
    const minWidth = getApproxMinLineWidth(defaultFont, TEXTBOX_LINE_HEIGHT);

    if (newWidth < minWidth * 2 && targetText.text !== '') {
        return false;
    }

    targetRect.set({ width: width / targetRect.scaleX, });
    target.set({ width: width });

    const { width: textWidth } = getShapeTextareaDimensions(target);
    targetText.set({ width: textWidth });

    if (targetText.text !== '' && (targetText.height + 30) * targetText.scaleY > targetRect.height * targetRect.scaleY) {
        targetRect.set({
            height: ((targetText.height + 30) * targetText.scaleY) / targetRect.scaleY,
        });
        target.set({
            height: (targetText.height + 30) * targetText.scaleY,
        });
    }

    return (oldWidth !== newWidth);
}
const changeHeightOfRhombus = (e, transform, x, y) => {
    const target = transform.target, localPoint = getLocalPoint(transform, transform.originX, transform.originY, x, y),
        strokePadding = target.strokeWidth / (target.strokeUniform ? target.scaleY : 1),
        multiplier = isTransformCentered(transform) ? 2 : 1,
        oldHeight = target.height,
        newHeight = Math.abs(localPoint.y * multiplier / target.scaleY) - strokePadding;

    const height = Math.max(newHeight, 0);
    const objects = target.getObjects();
    const textbox = objects[1];
    const image = objects[0];


    const textHeight = (textbox.__lineHeights.reduce((a, b) => a + b, 0)) * textbox.scaleY;
    
    if (textHeight + 20 < (height)/2  || textbox.text === '') {
        image.set({
            height : height / image.scaleY,
        });
        target.set({
            height,
        });
    }

    return oldHeight !== newHeight;
}

const changeWidthOfRhombus = (e, transform, x, y) => {
    const target = transform.target,
        targetObjects = target.getObjects(),
        localPoint = getLocalPoint(transform, transform.originX, transform.originY, x, y),
        strokePadding = target.strokeWidth / (target.strokeUniform ? target.scaleX : 1),
        multiplier = isTransformCentered(transform) ? 2 : 1,
        oldWidth = target.width,
        newWidth = Math.abs(localPoint.x * multiplier / target.scaleX) - strokePadding;
    const width = Math.max(newWidth, 0);
    const targetRect = targetObjects[0];
    const targetText = targetObjects[1];

    let defaultFont = `${targetText.fontSize}px / ${TEXTBOX_LINE_HEIGHT} ${targetText.fontFamily}`;
    const minWidth = getApproxMinLineWidth(defaultFont, TEXTBOX_LINE_HEIGHT);

    if (newWidth < minWidth * 2 && targetText.text !== '') {
        return false;
    }

    targetRect.set({ width: width / targetRect.scaleX, });
    target.set({ width: width });

    const { width: textWidth, padding } = getShapeTextareaDimensions(target);
    targetText.set({ width: textWidth });

    if (targetText.text !== '' && (targetText.height + padding) * targetText.scaleY > (targetRect.height * targetRect.scaleY) / 2) {
        targetRect.set({
            height: (targetText.height + padding) * 2,
        });

        target.set({
            height: (targetText.height + padding) * targetText.scaleY * 2,
        });
    }

    return (oldWidth !== newWidth);
}
const changeHeightOfParallelogram = (e, transform, x, y) => {
    const target = transform.target, localPoint = getLocalPoint(transform, transform.originX, transform.originY, x, y),
        strokePadding = target.strokeWidth / (target.strokeUniform ? target.scaleY : 1),
        multiplier = isTransformCentered(transform) ? 2 : 1,
        oldHeight = target.height,
        newHeight = Math.abs(localPoint.y * multiplier / target.scaleY) - strokePadding;

    const height = Math.max(newHeight, 0);
    const objects = target.getObjects();
    const textbox = objects[1];
    const image = objects[0];


    const textHeight = (textbox.__lineHeights.reduce((a, b) => a + b, 0)) * textbox.scaleY;

    if (textHeight + 30 < height || textbox.text === '') {
        image.set({
            height: height / image.scaleY,
        });
        target.set({
            height,
        });
    }

    return oldHeight !== newHeight;
}

const changeWidthOfParallelogram = (e, transform, x, y) => {
    const target = transform.target,
        targetObjects = target.getObjects(),
        localPoint = getLocalPoint(transform, transform.originX, transform.originY, x, y),
        strokePadding = target.strokeWidth / (target.strokeUniform ? target.scaleX : 1),
        multiplier = isTransformCentered(transform) ? 2 : 1,
        oldWidth = target.width,
        newWidth = Math.abs(localPoint.x * multiplier / target.scaleX) - strokePadding;
    const width = Math.max(newWidth, 0);
    const targetRect = targetObjects[0];
    const targetText = targetObjects[1];

    let defaultFont = `${targetText.fontSize}px / ${TEXTBOX_LINE_HEIGHT} ${targetText.fontFamily}`;
    const minWidth = getApproxMinLineWidth(defaultFont, TEXTBOX_LINE_HEIGHT);

    if (newWidth < minWidth * 2 && targetText.text !== '') {
        return false;
    }

    targetRect.set({ width: width / targetRect.scaleX, });
    target.set({ width: width, });

    const { width: textWidth, padding } = getShapeTextareaDimensions(target);
    targetText.set({ width: textWidth });

    if (targetText.text !== '' && (targetText.height + padding) * targetText.scaleY > targetRect.height * targetRect.scaleY) {
        targetRect.set({
            height: targetText.height + (padding * 2),
        });
        target.set({
            height: (targetText.height + (padding * 2)) * targetText.scaleY,
        });
    }

    return (oldWidth !== newWidth);
}
const changeHeightOfEllipse = (e, transform, x, y) => {
    const target = transform.target, localPoint = getLocalPoint(transform, transform.originX, transform.originY, x, y),
        strokePadding = target.strokeWidth / (target.strokeUniform ? target.scaleY : 1),
        multiplier = isTransformCentered(transform) ? 2 : 1,
        newHeight = Math.abs(localPoint.y * multiplier / target.scaleY) - strokePadding;

    const height = Math.max(newHeight, 0);
    const objects = target.getObjects();
    const textbox = objects[1];
    const image = objects[0];
    const oldHeight = image.ry;
    const textHeight = textbox.__lineHeights.reduce((a, b) => a + b, 0);

    if (textHeight * 1.5 < height || textbox.text === '') {
        image.set({
            ry: (height / 2) / image.scaleY,
        });
        target.set({
            height,
        });
    }

    return oldHeight !== newHeight;
}
const changeWidthOfEllipse = (e, transform, x, y) => {
    const target = transform.target,
        targetObjects = target.getObjects(),
        localPoint = getLocalPoint(transform, transform.originX, transform.originY, x, y),
        strokePadding = target.strokeWidth / (target.strokeUniform ? target.scaleX : 1),
        multiplier = isTransformCentered(transform) ? 2 : 1,
        newWidth = Math.abs(localPoint.x * multiplier / target.scaleX) - strokePadding;
    const width = Math.max(newWidth, 0);
    const targetRect = targetObjects[0];
    const targetText = targetObjects[1];
    const oldWidth = targetRect.rx * 2;

    let defaultFont = `${targetText.fontSize}px / ${TEXTBOX_LINE_HEIGHT} ${targetText.fontFamily}`;
    const minWidth = getApproxMinLineWidth(defaultFont, TEXTBOX_LINE_HEIGHT);

    if (width < minWidth * 2 && targetText.text !== '') {
        return false;
    }

    targetRect.set({ rx: (width / 2) / targetRect.scaleX, });
    target.set({ width: width });

    const { width: textWidth, height: textInstHeight } = getShapeTextareaDimensions(target);
    targetText.set({ width: textWidth });

    const textHeight = targetText.__lineHeights.reduce((a, b) => a + b, 0);
    if ((textInstHeight < textHeight) && newWidth < oldWidth && targetText.text !== '') {
        const newHeight = textHeight;

        targetRect.set({ ry: newHeight });
        target.set({ height: newHeight * 2 });
    }

    return (oldWidth !== newWidth);
}

const changeHeightOfTriangle = (e, transform, x, y) => {
    const target = transform.target, localPoint = getLocalPoint(transform, transform.originX, transform.originY, x, y),
        strokePadding = target.strokeWidth / (target.strokeUniform ? target.scaleY : 1),
        multiplier = isTransformCentered(transform) ? 2 : 1,
        oldHeight = target.height,
        newHeight = Math.abs(localPoint.y * multiplier / target.scaleY) - strokePadding;

    const topPoint = target.getPointByOrigin('center', 'top').y;
    const height = Math.max(newHeight, 0);
    const objects = target.getObjects();
    const textbox = objects[1];
    const rect = objects[0];

    // Getting textarea dimensions.
    const textHeight = (textbox.__lineHeights.reduce((a, b) => a + b, 0)) * textbox.scaleY;

    let textInstObj = getShapeTextareaDimensions(target);
    let isTriangleHasSpace = topPoint + (height * target.scaleY) - textInstObj.top - (textHeight * target.scaleY / 2) > (textInstObj.padding * target.scaleY / 2);

    if (textbox.text === ''|| (textbox.text !== '' && isTriangleHasSpace)) {
        rect.set({ height: height / rect.scaleY });
        target.set({ height });
    }

    if (textbox.text !== '') {
        textInstObj = getShapeTextareaDimensions(target);
    
        // Get exact point the target inside the group object.
        const pointOnObject = target.toLocalPoint(
            new fabric.Point(textInstObj.left, textInstObj.top),
            textbox.originX,
            textbox.originY
        );

        textbox.set({ top: pointOnObject.y / target.scaleY });
    }


    return oldHeight !== newHeight;
}
const changeWidthOfTriangle = (e, transform, x, y) => {
    const target = transform.target,
        targetObjects = target.getObjects(),
        localPoint = getLocalPoint(transform, transform.originX, transform.originY, x, y),
        strokePadding = target.strokeWidth / (target.strokeUniform ? target.scaleX : 1),
        multiplier = isTransformCentered(transform) ? 2 : 1,
        oldWidth = target.width,
        newWidth = Math.abs(localPoint.x * multiplier / target.scaleX) - strokePadding;
    const width = Math.max(newWidth, 0);
    const targetRect = targetObjects[0];
    const targetText = targetObjects[1];
    let defaultFont = `${targetText.fontSize}px / ${TEXTBOX_LINE_HEIGHT} ${targetText.fontFamily}`;
    const minWidth = getApproxMinLineWidth(defaultFont, TEXTBOX_LINE_HEIGHT);

    if (newWidth < minWidth * targetText.scaleX * 4 && targetText.text !== '') {
        return false;
    }

    targetRect.set({ width: width / targetRect.scaleX });

    // Getting textarea dimensions.
    const textInstObj = getShapeTextareaDimensions(target);

    // Get exact point the target inside the group object.
    const pointOnObject = target.toLocalPoint(
        new fabric.Point(textInstObj.left, textInstObj.top),
        targetText.originX,
        targetText.originY
    );

    targetText.set({
        width: textInstObj.width,
        top: pointOnObject.y / target.scaleY
    });

    target.set({
        width: width,
    });

    const topOfTriangle = target.top + (targetRect.top - targetRect.height * targetRect.scaleY / 2);
    const topOfText = target.top + (targetText.top - targetText.height * targetText.scaleX / 2);
    const originalTopOfText = target.top + (targetText.top - targetText.height / 2);
    const originalTopOfTriangle = target.top + (targetRect.top - targetRect.height / 2);
    
    if (topOfText + (targetText.height + textInstObj.padding) * targetText.scaleY > topOfTriangle + targetRect.height * targetRect.scaleY && targetText.text !== '') {
        targetRect.set({
            height: targetRect.height + ((originalTopOfText + targetText.height + (textInstObj.padding * 2)) - ( originalTopOfTriangle + targetRect.height))
        });
        target.set({
            height: targetRect.height * targetRect.scaleY,
        });
    }

    return (oldWidth !== newWidth); 
}

const changeWidthOfFrameHandler = wrapWithFireEvent('resizing', wrapWithFixedAnchor(changeWidthOfFrame));
const changeHeightOfFrameHandler = wrapWithFireEvent('resizing', wrapWithFixedAnchor(changeHeightOfFrame));
const changeWidthActionHandler = wrapWithFireEvent('resizing', wrapWithFixedAnchor(changeWidthOfSticky));
const changeHeightActionHandler = wrapWithFireEvent('resizing', wrapWithFixedAnchor(changeHeightOfSticky));
const changeWidthOfShapeActionHandler = wrapWithFireEvent('resizing', wrapWithFixedAnchor(changeWidthOfShape));
const changeHeightOfRectActionHandler = wrapWithFireEvent('resizing', wrapWithFixedAnchor(changeHeightOfRect));
const changeWidthOfEllipseActionHandler = wrapWithFireEvent('resizing', wrapWithFixedAnchor(changeWidthOfEllipse));
const changeHeightOfEllipseActionHandler = wrapWithFireEvent('resizing', wrapWithFixedAnchor(changeHeightOfEllipse));
const changeWidthOfTriangleActionHandler = wrapWithFireEvent('resizing', wrapWithFixedAnchor(changeWidthOfTriangle));
const changeHeightOfTriangleActionHandler = wrapWithFireEvent('resizing', wrapWithFixedAnchor(changeHeightOfTriangle));
const changeWidthOfRhombusActionHandler = wrapWithFireEvent('resizing', wrapWithFixedAnchor(changeWidthOfRhombus));
const changeHeightOfRhombusActionHandler = wrapWithFireEvent('resizing', wrapWithFixedAnchor(changeHeightOfRhombus));
const changeWidthOfParallelogramActionHandler = wrapWithFireEvent('resizing', wrapWithFixedAnchor(changeWidthOfParallelogram));
const changeHeightOfParallelogramActionHandler = wrapWithFireEvent('resizing', wrapWithFixedAnchor(changeHeightOfParallelogram));

const scaleXHandler = (e, transform, x, y) => {
    return scaleObject(e, transform, x, y, { by: 'x'});
}

const scaleYHandler = (e, transform, x, y) => {
    return scaleObject(e, transform, x, y, { by: 'y'});
}
const scaleBothHandler = (e, transform, x, y) => {
    const target = transform.target;
    if (target && target.type === 'activeSelection') {
        const objects = target.getObjects()?.filter(o => o?.type === 'curvedLine');
        const objectsUuids = target.getObjects()?.filter(o => o?.type !== 'curvedLine')?.map(o => o.uuid);
    
        for (const obj of objects) {
            try {
                if (!obj?.mockPoints) {
                    for (const pointIndex in obj.points) {
                        // if the line has outside polygons, add mock points for them
                        let isUpdated = handleGeneratingLineMockPointWhileScaling(
                            obj,
                            pointIndex,
                            target.canvas,
                            objectsUuids
                        );
    
                        // if the point has outside polygons, skip adding mock points to the selection
                        if (isUpdated) continue;
                
                        // else add mock points for the line that should represent the line in the selection
                        const point = obj.points[pointIndex];
                        const mockPoint = generateMockPointForLine(pointIndex, obj, {
                            left: point.x,
                            top: point.y,
                        });
                        target.addWithUpdate(mockPoint);
                        if (!obj.mockPoints) {
                            obj.mockPoints = [];
                        }
                        obj.mockPoints.push(mockPoint);
                    }
                }
                if (!target.removedLinesDuringScaling) {
                    target.removedLinesDuringScaling = [];
                }
                if (!target.removedLinesDuringScaling.includes(obj)) {
                    target.removedLinesDuringScaling.push(obj);
                }
                // attach with new position
                obj.reattachAfterModify = true;
      
                // add bound rect to preserve dimensions of line in the selection
                const bound = obj._calcDimensions({});
                const boundRect = new fabric.Rect({
                    left: bound.left,
                    top: bound.top,
                    width: bound.width,
                    height: bound.height,
                    fill: 'transparent',
                    stroke: 'transparent',
                    strokeWidth: obj.strokeWidth,
                });
      
                target.removeWithUpdate(obj);
                target.addWithUpdate(boundRect);
                target.remove(boundRect)
            } catch (error) {
                console.error('error while adding representing points for lines in selection', error);
            }
    
        }
    }
    return scaleObject(e, transform, x, y);
}


const scaleXWithEvent = wrapWithFireEvent('scaling', wrapWithFixedAnchor(scaleXHandler));
const scaleYWithEvent = wrapWithFireEvent('scaling', wrapWithFixedAnchor(scaleYHandler));
const scaleEquallyWithEvent = wrapWithFireEvent('scaling', wrapWithFixedAnchor(scaleBothHandler));

// action handler for ML and MR contol
export const scaleXActionHandler = (e, transform, x, y) => {
    const { target } = transform;
    if (target.type === 'optimizedImage') {
        return;
    }

    arrangeTargetForCacheAndResize(target);
    if (target.shapeType && target.shapeType === 'sticky') {
        return changeWidthActionHandler(e, transform, x, y);
    } else if (target.shapeType && target.shapeType === 'frame') {
        return changeWidthOfFrameHandler(e, transform, x, y);
    } else if (target.shapeType) {
        if (target.shapeType === 'rect') {
            return changeWidthOfShapeActionHandler(e, transform, x, y);
        } else if (target.shapeType === 'ellipse') {
            return changeWidthOfEllipseActionHandler(e, transform, x, y);
        } else if (target.shapeType === 'triangle') {
            return changeWidthOfTriangleActionHandler(e, transform, x, y);
        }else if (target.shapeType === 'rhombus') {
            return changeWidthOfRhombusActionHandler(e, transform, x, y);
        }
        else if (target.shapeType === 'parallelogram') {
            return changeWidthOfParallelogramActionHandler(e, transform, x, y);
        }
    }
    return scaleXWithEvent(e, transform, x, y);
}


// action handler for MT and MB contol
export const scaleYActionHandler = (e, transform, x, y) => {
    const { target } = transform;
    if (target.type === 'optimizedImage') {
        return;
    }

    arrangeTargetForCacheAndResize(target);
    if (target.shapeType && target.shapeType === 'sticky') {
        return changeHeightActionHandler(e, transform, x, y);
    } else if (target.shapeType && target.shapeType === 'frame') {
        return changeHeightOfFrameHandler(e, transform, x, y);
    } else if (target.shapeType && target.shapeType === 'rect') {
        return changeHeightOfRectActionHandler(e, transform, x, y);
    } else if (target.shapeType && target.shapeType === 'ellipse') {
        return changeHeightOfEllipseActionHandler(e, transform, x, y);
    } else if (target.shapeType && target.shapeType === 'triangle') {
        return changeHeightOfTriangleActionHandler(e, transform, x, y);
    }
    else if (target.shapeType && target.shapeType === 'rhombus') {
        return changeHeightOfRhombusActionHandler(e, transform, x, y);
    }
    else if (target.shapeType && target.shapeType === 'parallelogram') {
        return changeHeightOfParallelogramActionHandler(e, transform, x, y);
    }
    return scaleYWithEvent(e, transform, x, y);
}

export const scaleEquallyHandler = (e, transform, x, y) => {
    return scaleEquallyWithEvent(e, transform, x, y);
}

const arrangeTargetForCacheAndResize = (target) => {
    if (target.shapeType && (
        target.shapeType === 'rect' || 
        target.shapeType === 'ellipse' || 
        target.shapeType === 'triangle'
    )) {
        const actualShape = target.getObjects()[0];
        actualShape.set({
            objectCaching: false,
        })
        target.set({
            objectCaching: false,
        })
        // for backward compatibility
        if (actualShape.originX !== 'center' || actualShape.originY !== 'center') {
            const coordinates = actualShape.getCenterPoint();
            actualShape.set({
                originX: 'center',
                originY: 'center',
                left: coordinates.x,
                top: coordinates.y
            });
        }
    }
}
