/**
 *
 */
const DiamondIcon = () => {
    return (
        <svg
            fill="none"
            height="20"
            viewBox="0 0 20 20"
            width="20"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.823 1.237a.25.25 0 01.354 0L10.7.714l-.524.523 8.586 8.586a.25.25 0 010 .354l-8.586 8.586a.25.25 0 01-.354 0l-8.586-8.586-.53.53.53-.53a.25.25 0 010-.354L.71 9.296l.527.527 8.586-8.586L9.296.71l.527.527z"
                stroke="#3C3E49"
                strokeWidth="1.5"
            />
        </svg>
    );
}

export default DiamondIcon;