export function preciseRoundDown(t) {
    let e = Math.round(t);
    return 1e-5 > Math.abs(t - e) ? e : Math.floor(t)
}

export function preciseRoundUp(t) {
    let e = Math.round(t);
    return 1e-5 > Math.abs(t - e) ? e : Math.ceil(t)
}

export function normalizeFractionalPart(t) {
    let e = 1e-5 > Math.abs(t - Math.round(t)) ? 0 : t % 1;
    return e < 0 ? 1 + e : e
}