import { createSlice } from '@reduxjs/toolkit';
import { FLOWCHART_MODAL_TYPES, MODAL_WINDOWS } from '../../../helpers/Constant';
import {getCookie} from '../../../services/CookieService';
import {environment} from '../../../environment';
import {parseDataToObject} from '../../../helpers/CommonUtils';

const initialModalState = {
    feedbackModal: false,
    requestAccessModal: false,
    flowchartModal: {
        showModal: false,
        modalType: FLOWCHART_MODAL_TYPES.IMPORT,
        disableCancelInLoading: false
    },
    activeWindow: null
}

const modalSlice = createSlice({
    name: 'modal',
    initialState: initialModalState,
    reducers: {
        toggleFeedbackModal: (state, action) => {
            if (action?.payload?.shouldShow) {
                state.activeWindow = MODAL_WINDOWS.FEEDBACK;
            } else {
                state.activeWindow = null;
            }
        },
        toggleFlowchartModal: (state, action) => {
            if (action?.payload?.shouldShow) {
                state.activeWindow = MODAL_WINDOWS.FLOWCHART;
                state.flowchartModal.showModal = true;
            } else {
                state.activeWindow = null;
                state.flowchartModal.showModal = false;
            }
        },
        setFlowchartModalType: (state, action) => {
            state.flowchartModal.modalType = action.payload.modalType;
        },
        setFlowchartModalDetails: (state, action) => {
            state.flowchartModal = {
                ...state.flowchartModal,
                ...action.payload
            }
        },
        toggleShortcutList: (state, action) => {
            if (action?.payload?.shouldShow) {
                state.activeWindow = MODAL_WINDOWS.SHORTCUT_LIST;
            } else {
                state.activeWindow = null;
            } 
        },
        toggleMinimap: (state, action) => {
            if (action?.payload?.shouldShow) {
                state.activeWindow = MODAL_WINDOWS.MINI_MAP;
            } else {
                state.activeWindow = null;
            } 
        },
        toggleLeftDrawer: (state, action) => {
            if (action?.payload?.shouldShow) {
                state.activeWindow = MODAL_WINDOWS.ACTIVITY_LOGS;
            } else {
                state.activeWindow = null;
            } 
        },
        toggleRightDrawer: (state, action) => {
            if (action?.payload?.shouldShow) {
                state.activeWindow = MODAL_WINDOWS.RIGHT_DRAWER;
            } else {
                state.activeWindow = null;
            } 
        },
        toggleInvite: (state, action) => {
            if (action?.payload?.shouldShow) {
                state.activeWindow = MODAL_WINDOWS.INVITE;
            } else {
                state.activeWindow = null;
            } 
        },
        toggleDenyAccess: (state, action) => {
            if (action?.payload?.shouldShow) {
                state.activeWindow = MODAL_WINDOWS.DENY_ACCESS;
            } else {
                state.activeWindow = null;
            }
        },
        toggleTutorial: (state, action) => {
            if (action?.payload?.shouldShow) {
                state.activeWindow = MODAL_WINDOWS.TUTORIAL;
            } else {
                state.activeWindow = null;
            } 
        },
        toggleSearch: (state, action) => {
            if (action?.payload?.shouldShow) {
                state.activeWindow = MODAL_WINDOWS.SEARCH;
            } else {
                state.activeWindow = null;
            }
        },
        toggleCommentDrawer: (state, action) => {
            if (action?.payload?.shouldShow) {
                state.activeWindow = MODAL_WINDOWS.COMMENT_DRAWER;
            } else {
                state.activeWindow = null;
            }
        },
        toggleRequestAccessModal: (state, action) => {
            state.requestAccessModal = action.payload
        },
        resetWindowState: (state) => {
            try {
                const userCookieData = getCookie(environment.USER_COOKIE);
                if (userCookieData) {
                    const user = JSON.parse(userCookieData[1]);
                    const properties = parseDataToObject(user?.properties);
                    if (properties?.seenTutorial === false) {
                        state.activeWindow = MODAL_WINDOWS.TUTORIAL;
                    }
                    else state.activeWindow = null;
                }
            } catch (err) {
                console.error('Error happened during reading user cookie', err);
            }
        }
    }
});

export default modalSlice.reducer;