
/**
 * Checks if object is contained within the canvas with current viewportTransform
 * the check is done stopping at first point that appears on screen.
 * @override
 * @param {boolean} [calculate] - Use coordinates of current position instead of .aCoords.
 * @returns {boolean} True if object is fully or partially contained within canvas.
 */
export default function isOnScreen(calculate) {
    if (!this.canvas) {
        return false;
    }
    let pointTL = this.canvas.vptCoords.tl, pointBR = this.canvas.vptCoords.br;
    let points = this.getCoords(true, calculate);

    // We include the neared objects to render in order to decrease blurry issue of objects.
    const zoom = this.canvas.getZoom();
    const distance = 1500 / zoom;

    // if some point is on screen, the object is on screen.
    if (points.some(function (point) {
        return (
            point.x <= pointBR.x + distance &&
            point.x >= pointTL.x - distance &&
            point.y <= pointBR.y + distance &&
            point.y >= pointTL.y - distance
        )
    })) {
        return true;
    }
    // no points on screen, check intersection with absolute coordinates
    if (this.intersectsWithRect(pointTL, pointBR, true, calculate)) {
        return true;
    }
    return this._containsCenterOfCanvas(pointTL, pointBR, calculate);
}


/**
 * Default isOnScreen function for fabric objects.
 * @param {boolean} [calculate] - Use coordinates of current position instead of .aCoords.
 * @returns {boolean} True if object is fully or partially contained within canvas.
 */
export function defaultIsOnScreen(calculate) {
    if (!this.canvas) {
        return false;
    }
    var pointTL = this.canvas.vptCoords.tl, pointBR = this.canvas.vptCoords.br;
    var points = this.getCoords(true, calculate);
    // if some point is on screen, the object is on screen.
    if (points.some(function(point) {
        return point.x <= pointBR.x && point.x >= pointTL.x &&
            point.y <= pointBR.y && point.y >= pointTL.y;
    })) {
        return true;
    }
    // no points on screen, check intersection with absolute coordinates
    if (this.intersectsWithRect(pointTL, pointBR, true, calculate)) {
        return true;
    }
    return this._containsCenterOfCanvas(pointTL, pointBR, calculate);
}
