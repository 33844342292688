import {fabric} from 'fabric';
import {Viewport} from '../core/viewPort/Viewport'
import {toast} from 'react-toastify';
import getToastIcon from './media/GetToastIcon';
import {centerToObjectWithAnimation, getCanvasObjectsBbox} from './FabricMethods';
import {fitToScreen} from '../hooks/PanZoom';

const EDGES = {
    LEFT: 'left',
    RIGHT: 'right',
    TOP: 'top',
    BOTTOM: 'bottom',
}

// EDGES_NEXT defines the next edge to check after the current edge.
const EDGES_NEXT = {
    'horizontal': {
        [EDGES.LEFT]: EDGES.RIGHT,
        [EDGES.TOP]: EDGES.LEFT, // on reset, go to left
        [EDGES.RIGHT]: EDGES.LEFT,
        [EDGES.BOTTOM]: EDGES.LEFT, // on reset, go to left
    },
    'vertical': {
        [EDGES.LEFT]: EDGES.TOP, // on reset, go to top
        [EDGES.TOP]: EDGES.BOTTOM,
        [EDGES.RIGHT]: EDGES.TOP, // on reset, go to top
        [EDGES.BOTTOM]: EDGES.TOP,
    },
    'both': {
        [EDGES.LEFT]: EDGES.TOP,
        [EDGES.TOP]: EDGES.RIGHT,
        [EDGES.RIGHT]: EDGES.BOTTOM,
        [EDGES.BOTTOM]: EDGES.LEFT, 
    }
}

const filterRangeFunc = (x, y) => {
    return Math.abs(x - y) < 2;
}

const FILTER_FUNCTIONS_FOR_EDGE = {
    [EDGES.LEFT]: (point, viewport) => filterRangeFunc(point.tl.x, viewport.left),
    [EDGES.TOP]: (point, viewport) => filterRangeFunc(point.tl.y, viewport.top),
    [EDGES.RIGHT]: (point, viewport) => filterRangeFunc(point.br.x, viewport.right),
    [EDGES.BOTTOM]: (point, viewport) => filterRangeFunc(point.br.y, viewport.bottom)
}

const TOAST_ID = {
    NEED_HELP: 'needHelpToast',
    ASSISTANCE: 'assistanceToast',
    WARNING: 'assistanceWarningToast',
    SUCCESS: 'assistanceSuccessToast',
}

// TOAST_ORDER defines the order of the toasts to be shown (DESC order)
const TOAST_ORDER = {
    [TOAST_ID.WARNING]: 1,
    [TOAST_ID.SUCCESS]: 1,
    [TOAST_ID.ASSISTANCE]: 2,
    [TOAST_ID.NEED_HELP]: 2,
}

/**
 * A class to assist the user to fit the canvas to the screen by suggesting to delete far-placed objects.
 */
export class FitToScreenAssistance {
    constructor() {
        this.needHelpToast = null;
        this.assistanceToast = null;
        this.warningToast = null;
        this.checkedTimes = 0;
        this.lastDeletedAt = 0;
        this.lastCheckedEdge = null;
        this.waitingList = new Set();
        this.isInitialized = false;
        this.isDestroyed = false;
        this.nextClickLimitToShowWarningMessage = 10;
        this.overflowingDirection = null; // horizontal, vertical, both
        this.boundRemoveObjectListener = this.removeObjectListener.bind(this);
        this.openedToastIds = new Set();
    }

    initialize(canvas) {
        if (this.isInitialized) {
            return
        }
        this.isInitialized = true;
        this.isDestroyed = false;
 
        this.canvas = canvas;
    }

    /**
     * Resets the instance.
     */
    reset() {
        if (this.canvas) {
            this.canvas.off('object:removed', this.boundRemoveObjectListener)
        }
        this.checkedTimes = 0;
        this.lastDeletedAt = 0;
        this.lastCheckedEdge = null;
        this.isInitialized = false;
        this.waitingList.clear()
    }

    /**
     * Destroys the instance.
     * @param {boolean} closeToasts If true, closes the toasts.
     */
    destroy(closeToasts = false) {
        if (this.isDestroyed) {
            return
        }
        this.reset()
        this.isInitialized = false;
        this.isDestroyed = true;
        this.isNavigateStarted = false;
        if (closeToasts) {
            this.dismissAllToasts()
        }
        if (this.checkAbortController) {
            this.checkAbortController.abort();
            this.checkAbortController = null;
        }
    }
    
    /**
     * Checks if the bounding box is bigger than canvas's bounding box.
     * @param {Viewport} canvasBbox Canvas viewport to check.
     * @param {Viewport} boundingBox Viewport to check.
     */
    async check(canvasBbox, boundingBox) {
        if (!this.isNavigateStarted) {
            if (this.checkAbortController) {
                this.checkAbortController.abort();
            }
            this.checkAbortController = new AbortController();

            // wait for 1.5 seconds to check
            // since toastify doesn't inform us immediately when the toast is closed.
            try {
                await new Promise((resolve, reject) => {
                    const timeoutId = setTimeout(() => {
                        resolve(true)
                    }, 1500)

                    // If aborted, clear the timeout and reject the promise
                    this.checkAbortController.signal.addEventListener('abort', () => {
                        clearTimeout(timeoutId);
                        reject(new Error('Check aborted'));
                    });
                })
            } catch (err) {
                console.error('check for fit to screen assitance failed: ', err)
                return
            }
        }

        let isObjectsBboxOverflow = true;
        let overflowingDirection;
        
        if (boundingBox.width > canvasBbox.width && boundingBox.height > canvasBbox.height) {
            overflowingDirection = 'both';
        } else if (boundingBox.width > canvasBbox.width) {
            overflowingDirection = 'horizontal';
        } else if (boundingBox.height > canvasBbox.height) {
            overflowingDirection = 'vertical';
        } else {
            isObjectsBboxOverflow = false;
        }
        
        if (!isObjectsBboxOverflow) {
            if (this.checkedTimes === 0) {
                this.destroy()
                return
            }
            this.handleSuccessfulFit()
            return
        } else if (this.checkedTimes === 0) {
            this.initializeListeners()
        }
        
        this.overflowingDirection = overflowingDirection;
        
        if (this.checkedTimes - this.lastDeletedAt >= this.nextClickLimitToShowWarningMessage) {
            this.showWarningToast()
            this.lastDeletedAt = this.checkedTimes;
        }
        
        // if did not check any edge yet, show the need help toast
        if (this.checkedTimes === 0) {
            try {
                await this.showNeedHelpToast();
            } catch (err) {
                console.error(err)
            }
        } else {
            this.checkEdge(this.getNewCheckingEdge()) 
        }
    }

    /**
     * Returns the next edge to check.
     * @returns {EDGES.LEFT|EDGES.TOP|EDGES.RIGHT|EDGES.BOTTOM} The next edge to check.
     */
    getNewCheckingEdge() {
        if (!this.lastCheckedEdge || !this.overflowingDirection) {
            if (this.overflowingDirection === 'vertical') {
                return EDGES.TOP;
            }
            return EDGES.LEFT;
        }
        return EDGES_NEXT[this.overflowingDirection][this.lastCheckedEdge]
    }

    /**
     * Returns the bounding box of the all objects with Viewport instance and all objects.
     * @returns {{viewportOfObjects: Viewport, objects: fabric.Object[]}|null} The bounding box of the all objects with Viewport instance and all objects.
     */
    getBoundingBoxOfObjects() {
        const bbBox = getCanvasObjectsBbox(this.canvas);
        if (!bbBox) {
            return null;
        }
        
        const {
            objects,
            oLeft,
            oTop,
            shapesObjWidth,
            shapesObjHeight
        } = bbBox;
        

        const viewportOfObjects = new Viewport(oLeft, oTop, shapesObjWidth, shapesObjHeight)
        return {
            viewportOfObjects,
            objects,
        }
    }

    /**
     * Checks the given edge.
     * @param {EDGES.LEFT|EDGES.TOP|EDGES.RIGHT|EDGES.BOTTOM} edge The edge that will be checked.
     */
    checkEdge(edge) {
        this.canvas.discardActiveObject()
        if (Object.values(EDGES).indexOf(edge) === -1) {
            return;
        }

        this.lastCheckedEdge = edge;

        const { objects, viewportOfObjects } = this.getBoundingBoxOfObjects()
        const points = objects.map(o => {
            const pointTL = o.getPointByOrigin('left', 'top');
            const pointBR = o.getPointByOrigin('right', 'bottom');
            return {
                tl: pointTL,
                br: pointBR,
                object: o
            }
        })
        
        this.checkedTimes++;

        const filterFunction = FILTER_FUNCTIONS_FOR_EDGE[edge]
        const edgePoints = points.filter(point => filterFunction(point, viewportOfObjects))
        
        if (edgePoints.length) {
            this.addToWaitingList(edgePoints.map(p => p.object))
            const obj = edgePoints[0].object
            centerToObjectWithAnimation(this.canvas, obj, { useAdvanced: true })
        }
    }

    /**
     * Adds the objects to the waiting list to calculate last taken action to show the warning message.
     * @param {fabric.Object[]} objects Objects to be added to the list.
     */
    addToWaitingList(objects) {
        this.waitingList.add(...objects.map(obj => obj.uuid))
    }

    /**
     * Initializes the listeners.
     */
    initializeListeners() {
        this.canvas.on('object:removed', this.boundRemoveObjectListener)
    }

    /**
     * Listens the object removed event.
     * @param {{target: fabric.Object}} event The event object that contains the removed object.
     */
    removeObjectListener(event) {
        if (!event) {
            return
        }
        
        const object = event.target;
        if (!object || !object.uuid) {
            return
        }
        
        if (this.waitingList.has(object.uuid)) {
            this.lastDeletedAt = this.checkedTimes;
            this.waitingList.delete(object.uuid)
        }
    }

    /**
     * Shows the need help inital toast message.
     */
    async showNeedHelpToast() {
        if (this.isToastActive(this.needHelpToast)) {
            return
        }
        if (this.openedToastIds.has(TOAST_ID.NEED_HELP)) {
            await this.waitUntilToastIsClosed(TOAST_ID.NEED_HELP)
        }
        this.needHelpToast = toast.info(
            <span className="interactive_toast">
                <span className="interactive_toast__txt">Can't see anything on the board?</span>
                <span className="interactive_toast__btn toast_btn">Need help</span>
            </span>, 
            {
                className: 'wb_toast interactive',
                autoClose: false,
                icon: false,
                toastId: TOAST_ID.NEED_HELP,
                style: {order: TOAST_ORDER[TOAST_ID.SUCCESS]},
                onClick: (e) => {
                    if (!e?.target?.classList?.contains('toast_btn')) {
                        return 
                    }
                    if (this.needHelpToast) {
                        toast.dismiss(this.needHelpToast);
                    }

                    this.checkEdge(this.getNewCheckingEdge())
                    this.showAssistanceToast()
                    this.isNavigateStarted = true;
                },
                onClose: () => {
                    this.unregisterOpenToast(TOAST_ID.NEED_HELP)
                },
                onOpen: () => {
                    this.registerOpenToast(TOAST_ID.NEED_HELP)
                }
            }
        )
    }

    /**
     * Shows the interactive assistance toast message to assist the user to delete far-placed items.
     */
    showAssistanceToast() {
        if (this.isToastActive(this.assistanceToast)) {
            return
        }

        this.assistanceToast = toast.info(
            <span className="interactive_toast">
                <span className="interactive_toast__txt">These items are placed far from the main area. Please backup if needed & delete them.</span>
                <span className="interactive_toast__btn toast_btn">Next</span>
            </span>,
            {
                toastId: TOAST_ID.ASSISTANCE,
                className: 'wb_toast interactive',
                autoClose: false,
                icon: false,
                closeButton: false,
                style: {order: TOAST_ORDER[TOAST_ID.ASSISTANCE]},
                onClick: (e) => {
                    if (!e?.target?.classList?.contains('toast_btn')) {
                        return
                    }
                    fitToScreen(this.canvas, this, { fromFitToScreenAssistance: true })
                }
            }
        )
    }

    /**
     * Shows the warning toast message to inform the user to take an action for the suggested elements for optimal board clarity.
     */
    showWarningToast() {
        if (this.isToastActive(this.warningToast)) {
            return
        }
        this.warningToast = toast.warn('Kindly delete or move the suggested elements for optimal board clarity.', {
            className: 'wb_toast',
            toastId: TOAST_ID.WARNING,
            style: {order: TOAST_ORDER[TOAST_ID.WARNING]},
        })
    }

    /**
     * Shows the success toast message to inform the user that the action is completed.
     */
    showSuccessToast() {
        if (this.isToastActive(this.successToast)) {
            return
        } 
        
        this.successToast = toast.success('Awesome! You’re all set to continue.', {
            className: 'wb_toast',
            icon: getToastIcon('success'),
            toastId: TOAST_ID.SUCCESS,
            style: {order: TOAST_ORDER[TOAST_ID.SUCCESS]},
        })
    }

    /**
     * Dismisses all toasts.
     */
    dismissAllToasts() {
        this.closeToasts([this.needHelpToast, this.assistanceToast, this.warningToast, this.successToast]);
    }

    /**
     * Closes the toasts with the given ids.
     * @param {string[]} toastIds Toasts ids to be dismissed.
     */
    closeToasts(toastIds) {
        for (const toastId of toastIds) {
            if (!toast.isActive(toastId)) {
                continue;
            }
            toast.dismiss(toastId)
        }
    }
    
    handleSuccessfulFit() {
        this.destroy()
        this.closeToasts([this.needHelpToast, this.assistanceToast, this.warningToast])
        this.showSuccessToast()
    }

    /**
     * Checks if the given toast is active or not.
     * @param {string} toastId The id of the toast to check.
     * @returns {boolean} The status of the toast.
     */
    isToastActive(toastId) {
        if (!toastId) {
            return false
        }
        if (toast.isActive(toastId)) {
            const toastContainer = document.querySelector('.Toastify__toast-container')
            if (!toastContainer) {
                return false
            }
            const toastElement = toastContainer.querySelector(`#${toastId}`)
            if (toastElement) {
                return true
            }
        }
        return false
    }

    registerOpenToast(id) {
        this.openedToastIds.add(id)
    }
    unregisterOpenToast(id) {
        this.openedToastIds.delete(id)
    }

    waitUntilToastIsClosed(id) {
        const TIMEOUT_THRESHOLD = 3000
        const QUERY_INTERVAL = 50

        return new Promise((resolve, reject) => {
            const initialTimestamp = Date.now()

            const intervalId = setInterval(() => {
                if (Date.now() - initialTimestamp > TIMEOUT_THRESHOLD) {
                    clearInterval(intervalId)
                    reject()
                }

                if (!this.openedToastIds.has(id)) {
                    clearInterval(intervalId)
                    resolve()
                }
            }, QUERY_INTERVAL)
        })
    }
}

export const fitToScreenAssistance = new FitToScreenAssistance();
