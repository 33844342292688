import React from 'react';

const Cross = () => {
    return (
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 11.4167L11.4167 1" stroke="#D50000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.4167 11.4167L1 1" stroke="#D50000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    );
}

export default Cross;