/**
 *
 */
const ChevronRightIcon = () => {
    return (
        <svg
            fill="none"
            height="10"
            viewBox="0 0 6 10"
            width="6"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.333 1.334l3.266 3.494A.253.253 0 014.666 5a.253.253 0 01-.067.172L1.333 8.667"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </svg>
    );
}

export default ChevronRightIcon;