import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types'
import frontIcon from '../../../assets/images/send-front.svg';
import backIcon from '../../../assets/images/send-back.svg';

const MoreItem = ({
    isDisabled,
    onClick,
}) => {
    return (
        <div className={clsx('moreOption', { disabled: isDisabled })}>
            <em className="icon-canvas-redesign-menu" />
            <div className="moreOptionsBlock">
                <div className="moreOptionsInner">
                    <div
                        className="front"
                        onClick={() => onClick('front')}><span>Bring to front</span><img alt="Send front" src={frontIcon} /></div>
                    <div
                        className="back"
                        onClick={() => onClick('back')}><span>Send to back</span><img alt="Send back" src={backIcon} /></div>
                </div>
            </div>
        </div>
    )
};

MoreItem.propTypes = {
    isDisabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired
}

export default MoreItem;