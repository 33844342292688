import {Viewport} from '../../viewPort/Viewport';
import {ResourcePool} from '../../resourcePool/ResourcePool';

export class TileMutations {
    constructor(options) {
        this.mutations = new Map();
        this.releaseBounds = bounds => {
            if (bounds) {
                this.worldBoundsPool?.release(bounds);
            }
        };

        if (options?.useObjectPool) {
            this.worldBoundsPool = options.objectPool || new ResourcePool({
                factory: { create: () => new Viewport() },
                maxSize: 1000,
                garbageCollectionInterval: 10000,
                maxItemsPerGarbageCollection: 100,
                idleTimeToLive: 10000,
                useIdleCallback: true,
                idleCallbackTimeout: 10000
            });
        }
    }

    get size() {
        return this.mutations.size;
    }

    handlePreMutation(object) {
        this.add(object);
    }

    has(object) {
        return this.mutations.has(object);
    }

    add(object) {
        if (!this.mutations.has(object)) {
            const boundingRect = object.getCompleteBoundingRect();
            const coords = {
                x: boundingRect.left,
                y: boundingRect.top,
                width: boundingRect.width,
                height: boundingRect.height
            };
            this.mutations.set(object, this.acquireBounds(coords));
        }
    }

    get(object) {
        return this.mutations.get(object);
    }

    forEach(callback) {
        this.mutations.forEach(callback);
    }

    forEachOnce(callback) {
        this.mutations.forEach((value, key) => {
            callback(value, key);
            if (value) {
                this.releaseBounds(value);
            }
        });
        this.mutations.clear();
    }

    delete(object) {
        const bounds = this.mutations.get(object);
        if (this.worldBoundsPool && bounds) {
            this.releaseBounds(bounds);
        }
        return this.mutations.delete(object);
    }

    clear() {
        if (this.worldBoundsPool) {
            this.mutations.forEach(this.releaseBounds);
        }
        this.mutations.clear();
    }

    acquireBounds(coords) {
        const bounds = this.worldBoundsPool?.acquire() || new Viewport();
        return bounds.copy(coords);
    }
}