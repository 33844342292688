import { fabric } from 'fabric';
import store from '../redux/Store';
import { SHAPE_DEFAULTS_AS_SHAPE_PROPS, modes } from '../helpers/Constant';
import { removeCanvasListener, stopDrawing } from '../helpers/CommonFunctions'
import { changeObjectsSelectableProp } from '../helpers/FabricMethods';

let drawInstance = null,
    mouseDown = false,
    origX,
    origY;

export const createEllipse = (canvas, emitOnMouseDown, onMouseDownLineHandler, handleChangeSelectMode) => {
    store.dispatch({
        type: 'board/changeCurrentMode',
        payload: modes.ELLIPSE
    });

    removeCanvasListener(canvas);
    canvas.toggleDragMode(canvas, false);
    canvas.on('mouse:down', startAddEllipse(canvas));
    canvas.on('mouse:move', startDrawingEllipse(canvas));
    canvas.on('mouse:up', () => {
        mouseDown = false;
        stopDrawing(canvas, drawInstance, emitOnMouseDown, onMouseDownLineHandler);
        handleChangeSelectMode('select');
    });
    canvas.selection = false;
    canvas.defaultCursor = 'default';
    canvas.hoverCursor = 'auto';
    canvas.isDrawingMode = false;
    changeObjectsSelectableProp(canvas, false);
    canvas.discardActiveObject().requestRenderAll();
}

const startAddEllipse = (canvas) => {
    return _ref5 => {
        let {
            e
        } = _ref5;
        mouseDown = true;
        const pointer = canvas.getPointer(e);
        origX = pointer.x;
        origY = pointer.y;

        //Old
        drawInstance = new fabric.Ellipse({
            ...SHAPE_DEFAULTS_AS_SHAPE_PROPS,
            left: origX,
            top: origY,
            rx: 100,
            ry: 100,
            cornerSize: 7,
            objectCaching: false,
            selectable: false,
            strokeUniform: true,
            isDynamic: true  // tells that we will use this instance only for mocking
        });
        canvas.add(drawInstance);
    };
}

const startDrawingEllipse = (canvas) => {
    return _ref6 => {
        let {
            e
        } = _ref6;

        if (mouseDown) {
            const pointer = canvas.getPointer(e);

            if (pointer.x < origX) {
                drawInstance.set('left', pointer.x);
            }

            if (pointer.y < origY) {
                drawInstance.set('top', pointer.y);
            }

            drawInstance.set({
                rx: Math.abs(pointer.x - origX) / 2,
                ry: Math.abs(pointer.y - origY) / 2
            });
            drawInstance.setCoords();
            canvas.renderAll();
        }
    };
}