import { v4 as uuidv4} from 'uuid';


const mapFilesWithUuid = (files) => {
    const mappedFiles = [];
    for (const file of files) {
        mappedFiles.push({
            uuid: uuidv4(),
            file
        });
    }
    return mappedFiles;
}


export default mapFilesWithUuid;