/**
 * Override of fabric.Textbox.get2DCursorLocation
 * Since we changed text wrapping algorithm, 
 * we need to set the cursor location correctly.
 * @override
 */
export default function get2DCursorLocation(selectionStart, skipWrapping) {
    if (typeof selectionStart === 'undefined') {
        selectionStart = this.selectionStart;
    }
    var lines = skipWrapping ? this._unwrappedTextLines : this._textLines,
        len = lines.length;
    for (var i = 0; i < len; i++) {
        if (selectionStart <= lines[i].length) {
            return {
                lineIndex: i,
                charIndex: selectionStart
            };

        }
        selectionStart -= lines[i].length + this.missingNewlineOffset(i);
    }
    return {
        lineIndex: i - 1,
        charIndex: lines[i - 1].length < selectionStart ? lines[i - 1].length : selectionStart
    };
}