/**
 * Locks and unlocks comment.
 * @param {fabric.Canvas} canvas 
 * @param {boolean} isLock - Should comments be locked or not.
 * @param {boolean} [isDragModeActivated] - Is drag mode activated. If it is, then comments should be locked for movement.
 */
export const lockComments = (canvas, isLock, isDragModeActivated = false) => {
    try {
        canvas.getObjects().filter(item => item.type === 'comment').forEach(comment => {
            comment.set('selectable', !isLock);
            if (!isDragModeActivated) {
                comment.set('lockMovementX', isLock);
                comment.set('lockMovementY', isLock);
            }
            comment.set('evented', !isLock);
        })
    } catch (err) {
        console.error(err);
    }
}