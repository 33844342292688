import './Maintenance.scss';
import maintenance from '../../assets/images/404.png';

const Maintenance = () => {
    return (
        <div className="maintenancePage">
            <div className="contentBox">
                <img alt="" src={maintenance} />
                <h3>Uh Oh....It's us.</h3>
                <p>We are in the process of upgrading our platform to make it better for you. We'll be up and running shortly. Thank you for your patience.</p>
            </div>
        </div>
    )
};

export default Maintenance;