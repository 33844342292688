import { useContext } from 'react';
import { CanvasesContext } from '../context/canvases/CanvasesContext';
import { useSelector } from 'react-redux';

/**
 *
 */
export default function useCanvas() {
    const selectedCanvasID = useSelector(state => state?.rightDrawer?.activePage?.slugId)
    const canvasDetails = useContext(CanvasesContext);
    const canvas = canvasDetails.canvases[selectedCanvasID];
    return canvas;
}