import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './SearchBoard.scss';
import searchIcon from '../../../assets/images/search_new.svg';
import clsx from 'clsx';
import { KEYS } from '../../../helpers/shortcuts/Keys';
import { MdClear } from 'react-icons/md';

const SearchBoard = ({performSearch}) => {
    const [expanded, setExpanded] = useState(false);
    const [keyword, setKeyword] = useState('');
    const inputRef = useRef(null);
    const componentRef = useRef(null);

    const toggleExpanded = (expand = undefined) => {
        if (expand) setExpanded(!expanded);
        else setExpanded(expand);
    };

    const onChangeKeyword = (event) => {
        const { value: newValue } = event.target;
        setKeyword(newValue);
        performSearch(newValue);
    };
    useEffect(() => {
        /**
         * @param event
         */
        function handleClickOutside(event) {
            if (
                componentRef.current &&
        !componentRef.current.contains(event.target)
            ) {
                toggleExpanded(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (expanded) {
            inputRef.current.focus();
        }
        const keyDownListener = async (event) => {
            if (event[KEYS.CTRL_OR_CMD]) {
                event.preventDefault();
                if (KEYS.F.includes(event.key)) {
                    toggleExpanded(true);
                }
            }
        };
        document.addEventListener('keydown', keyDownListener);
        return () => {
            document.removeEventListener('keydown', keyDownListener);
        };
      
    }, [expanded, keyword]);
    return (
        <div className="container-search" data-testid="header-search" ref={componentRef}>
            <div className={clsx('expandable-container', { 'minimized': !expanded, 'expanded': expanded })}>
                {!expanded ? <div
                    className="icon-container"
                    data-testid="search-icon"
                    onClick={toggleExpanded}
                >
                    <img alt="search-icon" src={searchIcon} />
                </div> : null}
                {expanded ? <div className="control-result">
                    <div className="icon-close" onClick={toggleExpanded}>
                        <MdClear />
                    </div>
                </div> : null}
                {expanded ? <div className = "input-wrapper">
                    <img alt="search-icon" className = "icon-expanded" src={searchIcon} />
                    <input
                        data-testid="search-input"
                        onChange={onChangeKeyword}
                        placeholder="Search"
                        ref={inputRef}
                        type="text"
                        value={keyword}
                    />
                </div> : null}
            </div>
        </div>
    );
};

SearchBoard.propTypes = {
    performSearch: PropTypes.func
}

export default SearchBoard;
