// import { Lineto } from './Lineto';
import { Curveto } from './Curveto';
import { Moveto } from './Moveto';
import { Curve } from './Curve';
import { Utils } from './Utils';

export class Path {
    segmentTypes = {
        // L: Lineto,
        C: Curveto,
        M: Moveto,
    }

    type = 8

    // Default precision
    PRECISION = 3

    constructor(arg) {
        if (!(this instanceof Path)) {
            return new Path(arg);
        }

        if (typeof arg === 'string') { // create from a path data string
            return new this.parse(arg);
        }

        this.segments = [];

        let i;
        let n;

        if (!arg) {
            // don't do anything
        } else if (Array.isArray(arg) && arg.length !== 0) { // if arg is a non-empty array
            // flatten one level deep
            // so we can chain arbitrary Path.createSegment results
            arg = arg.reduce(function (acc, val) {
                return acc.concat(val);
            }, []);

            n = arg.length;
            if (arg[0].isSegment) { // create from an array of segments
                for (i = 0; i < n; i++) {

                    let segment = arg[i];

                    this.appendSegment(segment);
                }

            } else { // create from an array of Curves and/or Lines
                let previousObj = null;
                for (i = 0; i < n; i++) {

                    let obj = arg[i];

                    if (!((obj instanceof Curve))) {
                        throw new Error('Cannot construct a path segment from the provided object.');
                    }

                    if (i === 0) { this.appendSegment(this.createSegment('M', obj.start)); }

                    // if objects do not link up, moveto segments are inserted to cover the gaps
                    if (previousObj && !previousObj.end.equals(obj.start)) { this.appendSegment(this.createSegment('M', obj.start)); }

                    if (obj instanceof Curve) {
                        this.appendSegment(this.createSegment('C', obj.controlPoint1, obj.controlPoint2, obj.end));
                    }

                    previousObj = obj;
                }
            }

        } else if (arg.isSegment) { // create from a single segment
            this.appendSegment(arg);

        } else if (arg instanceof Curve) { // create from a single Curve
            this.appendSegment(this.createSegment('M', arg.start));
            this.appendSegment(this.createSegment('C', arg.controlPoint1, arg.controlPoint2, arg.end));
        } else { // unknown object
            throw new Error('Cannot construct a path from the provided object.');
        }
    }

    // More permissive than V.normalizePathData and this.prototype.serialize.
    // Allows path data strings that do not start with a Moveto command (unlike SVG specification).
    // Does not require spaces between elements; commas are allowed, separators may be omitted when unambiguous (e.g. 'ZM10,10', 'L1.6.8', 'M100-200').
    // Allows for command argument chaining.
    // Throws an error if wrong number of arguments is provided with a command.
    // Throws an error if an unrecognized path command is provided (according to this.segmentTypes). Only a subset of SVG commands is currently supported (L, C, M, Z).
    parse(pathData) {
        if (!pathData) { return new Path(); }

        let path = new Path();

        let commandRe = /(?:[a-zA-Z] *)(?:(?:-?\d+(?:\.\d+)?(?:e[-+]?\d+)? *,? *)|(?:-?\.\d+ *,? *))+|(?:[a-zA-Z] *)(?! |\d|-|\.)/g;
        let commands = pathData.match(commandRe);

        let numCommands = commands.length;
        for (let i = 0; i < numCommands; i++) {

            let command = commands[i];
            let argRe = /(?:[a-zA-Z])|(?:(?:-?\d+(?:\.\d+)?(?:e[-+]?\d+)?))|(?:(?:-?\.\d+))/g;
            let args = command.match(argRe);

            let segment = this.createSegment.apply(this, args); // args = [type, coordinate1, coordinate2...]
            path.appendSegment(segment);
        }

        return path;
    }

    // Create a segment or an array of segments.
    // Accepts unlimited points/coords arguments after `type`.
    createSegment(type) {
        let arguments$1 = arguments;

        if (!type) { throw new Error('Type must be provided.'); }

        let segmentConstructor = this.segmentTypes[type];
        if (!segmentConstructor) { throw new Error(type + ' is not a recognized path segment type.'); }

        let args = [];
        let n = arguments.length;
        for (let i = 1; i < n; i++) { // do not add first element (`type`) to args array
            args.push(arguments$1[i]);
        }

        return Utils.applyToNew(segmentConstructor, args);
    }

    // Accepts one segment or an array of segments as argument.
    // Throws an error if argument is not a segment or an array of segments.
    appendSegment(arg) {
        let segments = this.segments;
        let numSegments = segments.length;
        // works even if path has no segments

        let currentSegment;

        let previousSegment = ((numSegments !== 0) ? segments[numSegments - 1] : null); // if we are appending to an empty path, previousSegment is null
        let nextSegment = null;

        if (!Array.isArray(arg)) { // arg is a segment
            if (!arg || !arg.isSegment) { throw new Error('Segment required.'); }

            currentSegment = this.prepareSegment(arg, previousSegment, nextSegment);
            segments.push(currentSegment);

        } else { // arg is an array of segments
            // flatten one level deep
            // so we can chain arbitrary this.createSegment results
            arg = arg.reduce(function(acc, val) {
                return acc.concat(val);
            }, []);

            if (!arg[0].isSegment) { throw new Error('Segments required.'); }

            let n = arg.length;
            for (let i = 0; i < n; i++) {

                let currentArg = arg[i];
                currentSegment = this.prepareSegment(currentArg, previousSegment, nextSegment);
                segments.push(currentSegment);
                previousSegment = currentSegment;
            }
        }
    }

    // Checks whether current path segments are valid.
    // Note that d is allowed to be empty - should disable rendering of the path.
    isValid() {
        let segments = this.segments;
        let isValid = (segments.length === 0) || (segments[0].type === 'M'); // either empty or first segment is a Moveto
        return isValid;
    }

    // Helper method for adding segments.
    prepareSegment(segment, previousSegment, nextSegment) {

        // insert after previous segment and before previous segment's next segment
        segment.previousSegment = previousSegment;
        segment.nextSegment = nextSegment;
        if (previousSegment) { previousSegment.nextSegment = segment; }
        if (nextSegment) { nextSegment.previousSegment = segment; }

        let updateSubpathStart = segment;
        if (segment.isSubpathStart) {
            segment.subpathStartSegment = segment; // assign self as subpath start segment
            updateSubpathStart = nextSegment; // start updating from next segment
        }

        // assign previous segment's subpath start (or self if it is a subpath start) to subsequent segments
        if (updateSubpathStart) { this.updateSubpathStartSegment(updateSubpathStart); }

        return segment;
    }

    round(precision) {

        let segments = this.segments;
        let numSegments = segments.length;

        for (let i = 0; i < numSegments; i++) {

            let segment = segments[i];
            segment.round(precision);
        }

        return this;
    }

    // Returns a string that can be used to reconstruct the path.
    // Additional error checking compared to toString (must start with M segment).
    serialize() {
        if (!this.isValid()) { throw new Error('Invalid path segments.'); }
        return this.toString();
    }

    toString() {
        let segments = this.segments;
        let numSegments = segments.length;

        let pathData = '';
        for (let i = 0; i < numSegments; i++) {

            let segment = segments[i];
            pathData += segment.serialize() + ' ';
        }

        return pathData.trim();
    }

    // Helper method for updating subpath start of segments, starting with the one provided.
    updateSubpathStartSegment(segment) {
        let previousSegment = segment.previousSegment; // may be null
        while (segment && !segment.isSubpathStart) {

            // assign previous segment's subpath start segment to this segment
            if (previousSegment) { segment.subpathStartSegment = previousSegment.subpathStartSegment; } // may be null
            else { segment.subpathStartSegment = null; } // if segment had no previous segment, assign null - creates an invalid path!

            previousSegment = segment;
            segment = segment.nextSegment; // move on to the segment after etc.
        }
    }
}