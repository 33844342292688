/* eslint-disable react/jsx-max-depth  */
import React, {useEffect, useMemo, useRef, useState} from 'react';
import PasswordInput from '../inputs/passwordInput/PasswordInput';
import ButtonLoading from '../inputs/button/ButtonLoading';
import useAuth from '../../hooks/UseAuth';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {handleLabelPosition} from '../../helpers/InputLabelHelper';
import LoginSignupHeader from './LoginSignupHeader';
import Cross from '../svgIcons/Cross';
import {_hasLowerCase, _hasNumber, _hasUpperCase} from '../../helpers/Validation';
import PasswordConfirmationBox from './PasswordConfirmationBox';
import {NAME_SPECIAL_CHAR} from '../../helpers/Regexes';
import {AUTH_VALIDATION_MESSAGE} from '../../helpers/Constant';
import Scrollbar from 'react-scrollbars-custom';

const Register = ({setLoginPage}) => {
    const {
        loginGoogle,
        handleSubmit,
        handleChange,
        handleResetValues,
        loading,
        values,
        touched,
        errorMsg,
        errors,
        agree,
        handleCheckBox
    } = useAuth(false, setLoginPage);
    
    const emailLengthCounterLabel = useRef();
    const nameLengthCounterLabel = useRef();
    const passwordInput = useRef();
    
    const [showPasswordConfirmStatements, setShowPasswordConfirmStatements] = useState(false);
    const [isPasswordConfirmStatementsActivated, setIsPasswordConfirmStatementsActivated] = useState(false);

    const [isNameConfirmStatementsActivated, setIsNameConfirmStatementsActivated] = useState(false);
    const [isEmailConfirmStatementsActivated, setIsEmailConfirmStatementsActivated] = useState(false);
    
    /**
     * @param {Event} event
     */
    const handleEmailLabelPosition = (event) => {
        const eventType = event?.type;
        handleLabelPosition(event);
        
        if(isEmailConfirmStatementsActivated && eventType === 'focus') handleToggleLengthCounterLabel('show', emailLengthCounterLabel);
        if(eventType === 'blur') handleToggleLengthCounterLabel('remove', emailLengthCounterLabel);
    }
    
    /**
     * @param {Event} event
     */
    const handleEmailChange = (event) => {
        handleChange(event);
        
        setIsEmailConfirmStatementsActivated(true);
        
        if(!isEmailConfirmStatementsActivated) handleToggleLengthCounterLabel('show', emailLengthCounterLabel);
    }
    
    useEffect(() => {
        const handler = setTimeout(() => {
            if (values.email !== '' && passwordInput?.current?.value !== '') {
                passwordInput?.current?.parentElement?.previousElementSibling?.classList.add('active');
            }
        }, 100); // Debounce delay set to 300ms
        
        return () => {
            clearTimeout(handler); // Clear the timeout if email changes before the delay is over
        };
    }, [values.email]);
    
    /**
     * @param {Event} event
     */
    const handleNameChange = (event) => {
        handleChange(event)
        
        setIsNameConfirmStatementsActivated(true);
        
        if(!isNameConfirmStatementsActivated) handleToggleLengthCounterLabel('show', nameLengthCounterLabel);
    }
    
    /**
     * @param {Event} event
     */
    const handlePasswordChange = (event) => {
        setShowPasswordConfirmStatements(true);
        setIsPasswordConfirmStatementsActivated(true);
        
        handleChange(event);
    }
    
    const checkPasswordValidation = () => {
        return values.password.length < 8 || !_hasLowerCase(values.password) || !_hasUpperCase(values.password) || !_hasNumber(values.password)
    }
    
    /**
     * @param {Event} event
     */
    const handlePasswordConfirmStatementVisual = (event) => {
        const eventType = event?.type;
        handleLabelPosition(event);
        if ((!isPasswordConfirmStatementsActivated || !checkPasswordValidation()) && eventType === 'blur'){
            setShowPasswordConfirmStatements(false);
        }
    }
    
    /**
     * @param {'show'|'hide'} value
     * @param { emailLengthCounterLabel | nameLengthCounterLabel} type
     * @param lengthCounterLabel
     */
    const handleToggleLengthCounterLabel = (value, lengthCounterLabel) => {
        if(value === 'show') lengthCounterLabel?.current?.classList.add('active');
        else lengthCounterLabel?.current?.classList.remove('active');
    }
    
    const handleNameConfirmStatementVisual = (event) => {
        const eventType = event?.type;
        handleLabelPosition(event);
        
        if(isNameConfirmStatementsActivated && eventType === 'focus') handleToggleLengthCounterLabel('show', nameLengthCounterLabel);
        if(eventType === 'blur') handleToggleLengthCounterLabel('remove', nameLengthCounterLabel);
    }
    
    const checkButtonDisableStatus = useMemo(() => {
        return checkPasswordValidation() || values.password === '' || values.email === '' || errors.email || values.name === '' || Boolean(errors.name)
    },[values, errors]);
    
    return (
        <Scrollbar trackYVisible={true} style={{position:''}}>
            <form className="formElements signupBlock" data-testid="signup-screen">
                <LoginSignupHeader
                    onClick={loginGoogle}
                    page='signup'
                />
                <ul className="signupInputContainer">
                    <li>
                        <div>
                            <label>Company email</label>
                            <input autoComplete="email"
                                title=''
                                className={clsx({'error': errors.email})}
                                data-testid="email-signup-input"
                                id="loginEmailInput"
                                name="email"
                                onChange={handleEmailChange}
                                onFocus={handleEmailLabelPosition}
                                onBlur={handleEmailLabelPosition}
                                type="email"
                                value={values.email}
                            />
                        </div>
                        <span ref={emailLengthCounterLabel}
                            className='email-length-counter'> {values.email.trim().length} / 100 </span>
                        {(touched.email && (typeof errors.email !== 'boolean' && errors.email !== '')) ?
                            <div className={clsx('errorMsgBox', {'showError': errors.email})}>
                                <div className="errorMsg">{errors.email}</div>
                            </div> : null
                        }
                    </li>
                    <li>
                        <div>
                            <label>Username</label>
                            <input autoComplete="name"
                                className={clsx('name-input-signup', {'error': errors.name})}
                                data-testid="name-input-signup"
                                name="name"
                                onChange={handleNameChange}
                                onFocus={handleNameConfirmStatementVisual}
                                onBlur={handleNameConfirmStatementVisual}
                                pattern="[a-zA-Z ]*"
                                required=""
                                type="text"
                                value={values.name}
                            />
                        </div>
                        <span ref={nameLengthCounterLabel} className='name-length-counter'> 
                            {values.name.trim().length} / 50 
                        </span>
                        {touched.name && values.name.trim() === '' ?
                            <div className={clsx('errorMsgBox', {'showError': values.name.trim() === ''})}>
                                <div className="errorMsg">{AUTH_VALIDATION_MESSAGE.NAME_REQUIRED}</div>
                            </div> : null
                        }
                        {(NAME_SPECIAL_CHAR.test(values.name)) || (_hasNumber(values.name)) || (errors.name && values.name.trim() !== '') ?
                            <div className="container-warning">
                                <div className='list-warning'>
                                    {(NAME_SPECIAL_CHAR.test(values.name)) ?
                                        <div
                                            className={clsx('list-item', {error: (NAME_SPECIAL_CHAR.test(values.name))})}>
                                            <Cross/>
                                            <span className="text-warning">
                                                {AUTH_VALIDATION_MESSAGE.NO_SPECIAL_CHARACTER}
                                            </span>
                                        </div> : null
                                    }
                                    {(_hasNumber(values.name)) ?
                                        <>
                                            {(NAME_SPECIAL_CHAR.test(values.name)) ?
                                                <div className="spacer"/> : null}
                                            <div
                                                className={clsx('list-item', {error: _hasNumber(values.name)})}>
                                                <Cross/>
                                                <span className="text-warning">
                                                    {AUTH_VALIDATION_MESSAGE.NO_NUMBERS}
                                                </span>
                                            </div>
                                        </> : null
                                    }
                                    {errors.name && values.name.trim().length < 3 ?
                                        <>
                                            {(NAME_SPECIAL_CHAR.test(values.name)) || (_hasNumber(values.name)) ?
                                                <div className="spacer"/> : null}
                                            <div
                                                className={clsx('list-item', {error: errors.name && values.name})}>
                                                <Cross/>
                                                <span className="text-warning">
                                                    {AUTH_VALIDATION_MESSAGE.NEED_THREE_CHARACTER}
                                                </span>
                                            </div>
                                        </> : null
                                    }
                                </div>
                            </div> : null
                        }
                    </li>
                    <li>
                        <div>
                            <label>Password</label>
                            <PasswordInput
                                ref={passwordInput}
                                className={clsx('input', {'error': errors.password})}
                                minLength="8"
                                name="password"
                                onChange={handlePasswordChange}
                                onFocus={handlePasswordConfirmStatementVisual}
                                onBlur={handlePasswordConfirmStatementVisual}
                                value={values.password}
                            />
                        </div>
                        {touched.password && values.password === '' ?
                            <div className={clsx('errorMsgBox', {'showError': values.password === ''})}>
                                <div className="errorMsg">{AUTH_VALIDATION_MESSAGE.PASSWORD_REQUIRED}</div>
                            </div> : null
                        }
                        {
                            (showPasswordConfirmStatements && values.password !== '') ?
                                <PasswordConfirmationBox
                                    password={values.password}
                                    isActivated={isPasswordConfirmStatementsActivated}
                                /> : null
                        }
                    </li>
                </ul>
                <div className="footersection">
                    <div className="termsCondition">
                        <div>
                            <input className="styled-checkbox" data-testid="checkbox-signup" id="styled-checkbox-1"
                                name="terms" onChange={handleCheckBox} type="checkbox" value={agree}/>
                            <label className={clsx({error: !agree && errors.terms})} htmlFor="styled-checkbox-1"/>
                            <p>
                                I agree to
                                <a
                                    className="linkcolor"
                                    href="https://www.builder.ai/privacy"
                                    target="blank"> Privacy Policy
                                </a> and <a className="linkcolor"
                                    href="https://www.builder.ai/terms"
                                    target="blank"> Terms & Conditions
                                </a>
                            </p>
                        </div>
                        {errors.terms ?
                            <div className={clsx('errorMsgBox', {'showError': errors.terms})}
                                data-testid="error-checkbox">
                                <span className="errorMsg show"> {errors.terms} </span>
                            </div> : null
                        }
                    </div>
                    <div className="actionbtn">
                        <div className={clsx('sign-in-button-wrapper', {disabled: checkButtonDisableStatus})}>
                            <ButtonLoading
                                data-testid='signup-button'
                                disabled={checkButtonDisableStatus}
                                className={clsx('doneButton', {loading: loading})}
                                isLoading={loading}
                                loadingClassName="loading"
                                onClick={handleSubmit}
                                text="Sign up"
                                type="submit"
                            />
                            {
                                checkButtonDisableStatus ? <span className='warning-tooltip signup'>
                                    Please fill in details to sign up
                                </span> : null
                            }
                        </div>
                    </div>
                    <div className="newUser backToLogin">Already have an account? <span
                        data-testid='backto-login-button' className="linkcolor"
                        onClick={() => handleResetValues(setLoginPage)}>Log in</span>
                    </div>
                    {errorMsg ? <div className="errorMsgBox showError">
                        <span className="error-icon"/>
                        <div className="errorMsg">{errorMsg}</div>
                    </div> : null
                    }
                </div>
            </form>
        </Scrollbar>
    )
}

Register.propTypes = {
    setLoginPage: PropTypes.func
}

export default Register;