/**
 *
 */
const UnlockIcon = () => {
    return (
        <svg
            fill="none"
            height="20"
            viewBox="0 0 22 22"
            width="20"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.625 9.125V6a4.375 4.375 0 118.75 0v3.125"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                clipRule="evenodd"
                d="M6.625 10.625a1.5 1.5 0 011.5-1.5h10.75a1.5 1.5 0 011.5 1.5v8.25a1.5 1.5 0 01-1.5 1.5H8.125a1.5 1.5 0 01-1.5-1.5v-8.25z"
                fillRule="evenodd"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="M13.5 14.125a.312.312 0 100 .625.312.312 0 000-.625v0"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </svg>
    );
}

export default UnlockIcon;