import React, { useCallback, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import MiniMap from './MiniMap';
import MinimapIcon from '../../assets/images/minimap.svg';

import './MiniMap.scss';
import { useDispatch, useSelector } from 'react-redux';
import { MODAL_WINDOWS } from '../../helpers/Constant';

const MiniMapWrapper = ({
    canvas,
    handleToggleCommentDrawer
}) => {
    const activeWindowState = useSelector((state) => state?.modal?.activeWindow);
    const dispatch = useDispatch();

    const displayMinimap = activeWindowState === MODAL_WINDOWS.MINI_MAP;

    const toggleMinimapVisibility = useCallback(() => {
        handleToggleCommentDrawer({ shouldShow: false })
        dispatch({
            type: 'modal/toggleMinimap',
            payload: {
                shouldShow: !displayMinimap
            }
        })
    }, [dispatch, displayMinimap]);

    useEffect(() => {
        if (!canvas) { return; }

        canvas.on('toggle-minimap-visibility', toggleMinimapVisibility);

        return () => {
            canvas.off('toggle-minimap-visibility');
        }
    }, [toggleMinimapVisibility]);

    return (
        <div className="miniMapItem">
            <div
                className={clsx('miniMapButton', 'bottomBarButton', { active: displayMinimap })}
                data-testid="miniMapButton"
                onClick={toggleMinimapVisibility}
                title="Mini Map"
            >
                <span className="tooltiptext tooltip__miniMap">Minimap</span>
                <img alt='Show minimap' height={16} src={MinimapIcon} width={16} />
            </div>

            {displayMinimap ? <MiniMap canvas={canvas} /> : null}
        </div>
    )
}

MiniMapWrapper.propTypes = {
    canvas: PropTypes.object,
    handleToggleCommentDrawer: PropTypes.func.isRequired,
}

export default MiniMapWrapper;