import { uuidPattern } from './FabricMethods';

/**
 * @param param
 */
function getQueryParam(param) {
    try {
        if (window.self === window.top) {
            const urlParams = new URLSearchParams(window.location.search);
            if (urlParams.has(param)) {
                return urlParams.get(param);
            }
        }
        return false;
    } catch (e) {
        console.error(e);
        return false;
    }
}

/**
 * @param {string} paramVal - Param name to get from url.
 * @param {*} options
 * @returns Boolean|string.
 */
export default function getParamFromURL(paramVal, options = {}) {
    const param = getQueryParam(paramVal);
    if (!param) return false;
    if (options.testWithUuid) {
        if (uuidPattern.test(param)) {
            return param;
        }
        return false;
    }
    return param;
}

/**
 * Get the board slug id if the board is not using in the iframe.
 */
export function getBoardSlugIdInURL() {
    try {
        if (window.self === window.top) {
            const url = new URL(window.location.href);
            const pathname = url.pathname;
            if (url.pathname.startsWith('/board/')) {
                const boardId = pathname.replace('/board/', '');
                return boardId;
            }
        }
        return false;
    } catch (e) {
        console.error(e);
        return false;
    }
}