import React, { useState } from 'react'
import clsx from 'clsx';
import PropTypes from 'prop-types';
import SearhInput from '../../inputs/searchInput/SearchInput';
import FrameList from './framesList/FrameList';

import './FramesTab.scss'

const FramesTab = ({
    canvas,
    closeDrawer,
    userAccess
}) => {
    const [layoutStyle, setLayoutStyle] = useState('grid');
    const [searchKeyword, setSearchKeyword] = useState(null);
    const changeSearchEnabled = (value) => {
        if (!value) {
            setSearchKeyword(null);
        }
    }

    return (
        <div className='framesTab'>
            <div className='framesTab__header'>
                <div className='framesTab__header--left'>
                    <SearhInput 
                        changeIsSearchEnabled={(val) => changeSearchEnabled(val)}
                        onSearchClicked={(value) => {setSearchKeyword(value)}}
                        placeholder='Search frame'
                    />
                    <button
                        type="button"
                        className={clsx('framesTab__header--layoutBtn layoutBtn_first', { active: layoutStyle === 'grid' })}
                        data-ignoreeventkeys
                        onClick={() => setLayoutStyle('grid')}>
                        <em className="icon-element3" />
                    </button>
                    <button
                        type="button"
                        className={clsx('framesTab__header--layoutBtn', { active: layoutStyle === 'column' })}
                        data-ignoreeventkeys
                        onClick={() => setLayoutStyle('column')}>
                        <em className="icon-menu-icon" />
                    </button>
                </div>
                <div className='framesTab__header--right'>
                    <button type="button" className="leftDrawer__closeBtn" onClick={closeDrawer}>
                        <em className="icon-close-circle" />
                    </button> 
                </div>
            </div>
            <FrameList canvas={canvas} layoutStyle={layoutStyle} searchKeyword={searchKeyword} userAccess={userAccess} />
        </div>
    )
}

FramesTab.propTypes = {
    canvas: PropTypes.object.isRequired,
    closeDrawer: PropTypes.func.isRequired,
    userAccess: PropTypes.oneOf(['view', 'comment', 'edit', 'removeAccess', 'NOT_ALLOWED']).isRequired
}

export default FramesTab;