
/**
 * Collecting some properties of the group and group objects before addWithUpdate run.
 * @param {fabric.Object} object 
 * @returns 
 */
export const getOriginalStateOfGroup = (object) => {
    const values = [];
    const groupObjects = object.getObjects();
    [object, ...groupObjects].forEach(groupObject => {
        values.push({
            scaleX: groupObject.scaleX,
            scaleY: groupObject.scaleY,
            width: groupObject.width,
            height: groupObject.height,
            left: groupObject.left,
            top: groupObject.top,
            angle: groupObject.angle,
        })
    });

    return values;
}

/**
 * Arranges the group state to the original state.
 * @param {fabric.Object} object 
 * @param {[{ scaleX: number, scaleY: number, width: number, height: number, left: number, top: number, angle: number}]} originalObjects - To take reference.
 */
export const setOriginalStateForGroup = (object, originalObjects) => {
    try {
        const groupObjects = object.getObjects();
        for (const [index, groupObject] of Object.entries([object, ...groupObjects])) {
            groupObject.set({
                scaleX: originalObjects[index].scaleX,
                scaleY: originalObjects[index].scaleY,
                width: originalObjects[index].width,
                height: originalObjects[index].height,
                left: originalObjects[index].left,
                top: originalObjects[index].top,
                angle: originalObjects[index].angle,
            })
        }
    } catch (err) {
        console.error('error while updating group', err);
    }
}