import { makeObjectUnselectable } from '../helpers/FabricMethods';

// Note: Use base64 format instead of importing image. Because importing image is taking time a lot and it cause some problems.
const mapModeAndCursor = {
    pencilMode: { cursor: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMiAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xOS40OTE3IDIuNTA4MzhDMTguOTE4MyAxLjkzNzQ2IDE4LjE0MDUgMS42MTkzNSAxNy4zMzE0IDEuNjI0ODNDMTYuNTIyMyAxLjYzMDMgMTUuNzQ4OSAxLjk1ODkxIDE1LjE4MzMgMi41Mzc1NUwzLjEgMTQuNjIwOUwxLjYyNSAyMC4zNzVMNy4zNzkxNyAxOC44OTkyTDE5LjQ2MjUgNi44MTU4OEMyMC4wNDEzIDYuMjUwNTUgMjAuMzcgNS40NzcyNyAyMC4zNzU1IDQuNjY4MjFDMjAuMzgxIDMuODU5MTYgMjAuMDYyOCAzLjA4MTUgMTkuNDkxNyAyLjUwODM4WiIgc3Ryb2tlPSIjM0MzRTQ5IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0xNC44Mzg0IDIuODgzM0wxOS4xMTY3IDcuMTYxNjMiIHN0cm9rZT0iIzNDM0U0OSIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMy4xMDA4MyAxNC42MjAxTDcuMzgzMzMgMTguODk1MSIgc3Ryb2tlPSIjM0MzRTQ5IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=', x: 0, y: 20 }, // x and y is used to indicate the click position, for some cursors, the click position is the top left of the icon, but for some other cursors, the click position might be the center, or bottom left
    eraseMode: { cursor: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMiAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuNjI1IDE5LjU5ODRIMjAuMzc1IiBzdHJva2U9IiMzQzNFNDkiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNC44NDY3IDIuMzAwODNMMTkuNjI5MiA2Ljg5MTIzQzIwLjA5ODEgNy4zNDEzMyAyMC4zNjE2IDcuOTUxODQgMjAuMzYxNiA4LjU4ODQzQzIwLjM2MTYgOS4yMjUwMiAyMC4wOTgxIDkuODM1NTMgMTkuNjI5MiAxMC4yODU2TDE0LjIyNDIgMTUuNDc0NEMxMi44MTc3IDE2LjgyNDcgMTAuOTEgMTcuNTgzMyA4LjkyMDg2IDE3LjU4MzNDNi45MzE3MiAxNy41ODMzIDUuMDI0MDUgMTYuODI0NyAzLjYxNzUzIDE1LjQ3NDRMMi4zNjc1MyAxNC4yNzQ0QzEuMzkxNTcgMTMuMzM3MiAxLjM5MTU3IDExLjgxOCAyLjM2NzUzIDEwLjg4MDhMMTEuMzExNyAyLjMwMDgzQzEyLjI4NzkgMS4zNjM5MiAxMy44NzA0IDEuMzYzOTIgMTQuODQ2NyAyLjMwMDgzWiIgc3Ryb2tlPSIjM0MzRTQ5IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik03Ljc3MTY3IDUuNjk5MjJMMTYuMDg5MiAxMy42ODQiIHN0cm9rZT0iIzNDM0U0OSIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K', x: 0, y: 20 },
    rhombusMode: { cursor: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMuMzMzMzcgMTAuMDAwMkgxNi42NjY3IiBzdHJva2U9IiMzQzNFNDkiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTEwIDMuMzMzNzRWMTYuNjY2NCIgc3Ryb2tlPSIjM0MzRTQ5IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=', x: 8, y: 8 },
    ellipseMode: { cursor: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMuMzMzMzcgMTAuMDAwMkgxNi42NjY3IiBzdHJva2U9IiMzQzNFNDkiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTEwIDMuMzMzNzRWMTYuNjY2NCIgc3Ryb2tlPSIjM0MzRTQ5IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=', x: 8, y: 8 },
    rectMode: { cursor: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMuMzMzMzcgMTAuMDAwMkgxNi42NjY3IiBzdHJva2U9IiMzQzNFNDkiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTEwIDMuMzMzNzRWMTYuNjY2NCIgc3Ryb2tlPSIjM0MzRTQ5IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=', x: 8, y: 8 },
    triangleMode: { cursor: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMuMzMzMzcgMTAuMDAwMkgxNi42NjY3IiBzdHJva2U9IiMzQzNFNDkiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTEwIDMuMzMzNzRWMTYuNjY2NCIgc3Ryb2tlPSIjM0MzRTQ5IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=', x: 8, y: 8 },
    parallelogramMode: { cursor: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMuMzMzMzcgMTAuMDAwMkgxNi42NjY3IiBzdHJva2U9IiMzQzNFNDkiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTEwIDMuMzMzNzRWMTYuNjY2NCIgc3Ryb2tlPSIjM0MzRTQ5IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=', x: 8, y: 8 },
    lineMode: { cursor: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMuMzMzMzcgMTAuMDAwMkgxNi42NjY3IiBzdHJva2U9IiMzQzNFNDkiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTEwIDMuMzMzNzRWMTYuNjY2NCIgc3Ryb2tlPSIjM0MzRTQ5IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=', x: 8, y: 8 },
    ARROW: { cursor: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMuMzMzMzcgMTAuMDAwMkgxNi42NjY3IiBzdHJva2U9IiMzQzNFNDkiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTEwIDMuMzMzNzRWMTYuNjY2NCIgc3Ryb2tlPSIjM0MzRTQ5IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=', x: 8, y: 8 },
    Sticky: { cursor: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMiAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMi4zNTc1IDIwLjM3NUgyLjg3NUMyLjE4NDY0IDIwLjM3NSAxLjYyNSAxOS44MTU0IDEuNjI1IDE5LjEyNVYyLjg3NUMxLjYyNSAyLjE4NDY0IDIuMTg0NjQgMS42MjUgMi44NzUgMS42MjVIMTkuMTI1QzE5LjgxNTQgMS42MjUgMjAuMzc1IDIuMTg0NjQgMjAuMzc1IDIuODc1VjEyLjM1NzVDMjAuMzc0OSAxMi42ODg4IDIwLjI0MzMgMTMuMDA2NSAyMC4wMDkyIDEzLjI0MDhMMTMuMjQwOCAyMC4wMDkyQzEzLjAwNjUgMjAuMjQzMyAxMi42ODg4IDIwLjM3NDkgMTIuMzU3NSAyMC4zNzVaIiBzdHJva2U9IiMzQzNFNDkiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTEyLjg3NSAyMC4yNjI1VjE0LjEyNUMxMi44NzUgMTMuNDM0NiAxMy40MzQ2IDEyLjg3NSAxNC4xMjUgMTIuODc1SDIwLjI2MjUiIHN0cm9rZT0iIzNDM0U0OSIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNNi42MjUgNi42MjVIMTYuNjI1IiBzdHJva2U9IiMzQzNFNDkiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTYuNjI1IDEwLjM3NUgxMSIgc3Ryb2tlPSIjM0MzRTQ5IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=', x: 0, y: 0 },
    frame: { cursor: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUgMS4yNVYxOC43NSIgc3Ryb2tlPSIjM0MzRTQ5IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0xNSAxLjI1VjE4Ljc1IiBzdHJva2U9IiMzQzNFNDkiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTEuMjUgNUgxOC43NSIgc3Ryb2tlPSIjM0MzRTQ5IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0xLjI1IDE1SDE4Ljc1IiBzdHJva2U9IiMzQzNFNDkiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==', x: 0, y: 0 },
    text: { cursor: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNCAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyIDE5Ljc0OFY2LjI0ODA1IiBzdHJva2U9IiMzQzNFNDkiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTcuNSAyNC4yNDhDOS45ODUyOCAyNC4yNDggMTIgMjIuMjMzMyAxMiAxOS43NDhDMTIgMjIuMjMzMyAxNC4wMTQ3IDI0LjI0OCAxNi41IDI0LjI0OCIgc3Ryb2tlPSIjM0MzRTQ5IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik03LjUgMS43NDgwNUM5Ljk4NTI4IDEuNzQ4MDUgMTIgMy43NjI3NyAxMiA2LjI0ODA1QzEyIDMuNzYyNzcgMTQuMDE0NyAxLjc0ODA1IDE2LjUgMS43NDgwNSIgc3Ryb2tlPSIjM0MzRTQ5IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik05IDE1LjI0OEgxNSIgc3Ryb2tlPSIjM0MzRTQ5IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=', x: 12, y: 12 },
};

const changeCursor = (mode, canvas) => {
    if (!canvas) return;
    if(mode ==='lasso'){
        canvas.freeDrawingCursor = 'crosshair';
        return;
    }
    if(mode ==='select') {
        canvas.defaultCursor = 'default';
        return;
    }
    if (!mapModeAndCursor[mode]) return;
    const cursorImage = new Image();
    cursorImage.src = mapModeAndCursor[mode].cursor;
    cursorImage.onload = () => {
        if (canvas.isDrawingMode) {
            canvas.freeDrawingCursor = `url(${cursorImage.src}) ${mapModeAndCursor[mode].x} ${mapModeAndCursor[mode].y}, auto`;
        } else {
            canvas.defaultCursor = `url(${cursorImage.src}) ${mapModeAndCursor[mode].x} ${mapModeAndCursor[mode].y}, auto`;
            canvas.hoverCursor = `url(${cursorImage.src}) ${mapModeAndCursor[mode].x} ${mapModeAndCursor[mode].y}, auto`;
        }
        canvas.selection = false;

        if (mode !== 'eraseMode')
            canvas.forEachObject(function (object) {
                // disable selection for objects but comments
                if (object.type !== 'comment') {
                    makeObjectUnselectable(object);
                }
            });
    };
};

export default changeCursor;
