import { v4 as uuidv4} from 'uuid';


const sizeLimit = {
    limit: 10 * 1024 * 1024,
    limitText: '10 MB',
    totalSizeLimit: 50 * 1024 * 1024,
    totalSizeLimitText: '50 MB',
    totalImageLimit: 50,
    attachTotalImageLimit: 10
}

export const filterImageSizes = (files, options = {}) => {
    if (options.filterForAttach && files.length > sizeLimit.attachTotalImageLimit) {
        return {
            limitText: sizeLimit.attachTotalImageLimit,
            isBiggerThanTotalCountLimit: true
        }
    }
    if (files.length > sizeLimit.totalImageLimit) {
        return {
            limitText: sizeLimit.totalImageLimit,
            isBiggerThanTotalCountLimit: true
        }
    }
    const filteredFiles = [];
    let unallowedFileCount = 0;
    for (const file of files) {
        if (file.size > sizeLimit.limit) {
            unallowedFileCount++;
        } else {
            filteredFiles.push(file);
        }
    }

    let size = 0;
    for (const file of filteredFiles) {
        size += file.size;
    }
    if (size > sizeLimit.totalSizeLimit) {
        return {
            limitText: sizeLimit.totalSizeLimitText,
            isBiggerThanTotalLimit: true
        }
    }
    return {
        unallowedFileCount,
        limitText: sizeLimit.limitText,
        filteredFiles
    }
}


// separate files to multiple arrays if total size of each array is bigger than limit
export const separateFilesForSizeLimit = (files) => {
    const SIZE_LIMIT = 20 * 1024 * 1024;
    const TOTAL_COUNT_LIMIT_PER_ROW = 20;
    const separatedFiles = {};

    let size = 0,
        rowCount = 0,
        fileCount = 0;
    for (const fileWrap of files) {
        fileCount += 1;
        size += fileWrap.file.size;
        if (size > SIZE_LIMIT || fileCount % TOTAL_COUNT_LIMIT_PER_ROW === 0) {
            rowCount++;
            size = 0;
        }
        if (!separatedFiles[rowCount]) {
            separatedFiles[rowCount] = {
                files: [],
                rowUuid: uuidv4()
            }
        }
        separatedFiles[rowCount].files.push(fileWrap);
    }
    return separatedFiles;
}
export const filterByImageType = (files) => {
    const baseFiles = [];
    for (const file of files) {
        if (file.type.includes('image')) {
            baseFiles.push(file);
        }
    }
    return baseFiles;
}