/**
 *
 */
const IconLineDrawingTypeSolid = () => {
    return (
        <svg
            fill="none"
            height="18"
            viewBox="0 0 39 18"
            width="39"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M12 9h15" stroke="#3C3E49" strokeLinecap="round" />
        </svg>
    )
}

export default IconLineDrawingTypeSolid;