import { fabric } from 'fabric';
import { getShapeTextareaDimensions } from './shapes/Common';
const _generateTheHighlightForTextBox = (object) => {
    const topPosition = object.top;
    const leftPosition = object.left;
    const width = object.getScaledWidth();
    const height = object.getScaledHeight();
    const highlightObject = new fabric.Rect({
        stroke: 'black',
        strokeWidth: 0.5,
        fill: '#B388FF40',
        left: leftPosition - 10,
        top: topPosition - 10,
        width: width + 10,
        height: height + 10,
        selectable: false,
        strokeUniform: true,
        rx: 5,
        ry: 5,
    });
    let map = new Map();
    map.set(object.uuid, highlightObject);
    return map;
};

const _generateTheHighlightForStickyAndShape = (object, keyword) => {
    let result = new Map();
    const parentTopCenter = object.top;
    const parentLeftCenter = object.left;
    const parentWidthReal = object.getScaledWidth();
    const parentHeightReal = object.getScaledHeight();
    const parentTop = parentTopCenter - parentHeightReal / 2;
    const parentLeft = parentLeftCenter - parentWidthReal / 2;
    let textInstObj;
    if (object.shapeType === 'triangle') {
        textInstObj = getShapeTextareaDimensions(object);
    }
    object.getObjects().map((childObject) => {
        if (
            childObject.text &&
      childObject.text.toLowerCase().includes(keyword.toLowerCase().trim())
        ) {
            const childWidth =
        childObject.getScaledWidth() * object.scaleX > parentWidthReal
            ? parentWidthReal
            : childObject.getScaledWidth() * object.scaleX;
            const childHeight = childObject.getScaledHeight() * object.scaleY;
            const borderTop =
        object.shapeType === 'triangle'
            ? parentTop + (parentHeightReal - (textInstObj.height * object.scaleY))
            : parentTop + (parentHeightReal - childHeight) / 2;
            const borderLeft =
        object.shapeType === 'triangle'
            ? parentLeftCenter - childWidth / 2
            : parentLeft + (parentWidthReal - childWidth) / 2;
            const highlightObject = new fabric.Rect({
                stroke: 'red',
                strokeWidth: 0.5,
                fill: '#B388FF40',
                left: borderLeft,
                top: borderTop,
                width: childWidth,
                height: childHeight,
                selectable: false,
                strokeUniform: true,
                rx: 5,
                ry: 5,
            });
            result.set(object.uuid, highlightObject);
        }
    });
    return result;
};

const _generateTheHighlightForCurvedLine = (object) => {
    const textPos = object._getTextPos();
    const topPosition = object.top - textPos.height / 2 - 20;
    const leftPosition = object.left - textPos.width / 2 - 10;
    const highlightObject = new fabric.Rect({
        stroke: 'red',
        strokeWidth: 0.5,
        fill: '#B388FF40',
        left: leftPosition,
        top: topPosition,
        width: textPos.width + 20,
        height: textPos.height + 20,
        selectable: false,
        strokeUniform: true,
        rx: 5,
        ry: 5,
    });
    let map = new Map();
    map.set(object.uuid, highlightObject);
    return map;
};

const _generateTheHighlightForFrameName = (object, keyword) => {
    let result = new Map();
    if (object.text &&
      object.text.toLowerCase().includes(keyword.toLowerCase().trim())) {
        object.highlightText = true;
        result.set(object.uuid, {instance: object, highlightNatively: true});
    }

    return result;
};

const _generateTheHighlightForTableTitle = (object) => {
    const { textTL, textBR } = object.aCoords;

    const leftPosition = textTL.x;
    const topPosition = textTL.y;
    const width = textBR.x - textTL.x;
    const height = textBR.y - textTL.y;


    const highlightObject = new fabric.Rect({
        stroke: 'black',
        strokeWidth: 0.5,
        fill: '#B388FF40',
        left: leftPosition - (10 * object.scaleX),
        top: topPosition - (10 * object.scaleY),
        width: width + (20 * object.scaleX),
        height: height + (20 * object.scaleY),
        selectable: false,
        strokeUniform: true,
        rx: 5,
        ry: 5,
    });
    let map = new Map();
    map.set(object.uuid, highlightObject);
    return map;
};

const _generateTheHighlightForTableContent = (object, cellIds) => {
    const highlightObject = new fabric.Rect({
        stroke: 'black',
        strokeWidth: 0.5,
        fill: '#B388FF40',
        left: object.left - (10 * object.scaleX),
        top: object.top - (10 * object.scaleY),
        width: (object.width * object.scaleX) + (20 * object.scaleX),
        height: (object.height * object.scaleY) + (20 * object.scaleY),
        selectable: false,
        strokeUniform: true,
        rx: 5,
        ry: 5,
        cellIds,
        tableUuid: object.uuid
    });

    let map = new Map();
    map.set(object.uuid, highlightObject);
    return map;
};

export const createTheHighlight = (object, keyword) => {
    let result = new Map();
    if (
        object.text &&
    object.text.toLowerCase().includes(keyword.toLowerCase().trim())
    ) {
        if (object.type === 'textbox') {
            result = new Map([...result, ..._generateTheHighlightForTextBox(object)]);
        }
        if (object.type === 'curvedLine') {
            result = new Map([
                ...result,
                ..._generateTheHighlightForCurvedLine(object),
            ]);
        }
        if (object.shapeType === 'frame') {
            result = new Map([
                ...result,
                ..._generateTheHighlightForFrameName(object, keyword),
            ]);
        }
    } else if (object.type === 'table') {
    // Is table title matched
        if (object.title && object.title.toLowerCase().includes(keyword.toLowerCase('en-US').trim())) {
            result = new Map([...result, ..._generateTheHighlightForTableTitle(object)]);
        } else if (Array.isArray(object.cells)) {
            const cells = object.cells.filter((cell) => {
                return cell.text?.toLowerCase('en-US')?.includes(keyword.toLowerCase('en-US').trim())
            });

            if (cells.length > 0) {
                const cellIds = cells.map((cell) => cell.id);
                result = new Map([...result, ..._generateTheHighlightForTableContent(object, cellIds)]);
            }
        }
    } else if (typeof object.getObjects === 'function') {
        if (
            object.shapeType === 'sticky' ||
      object.shapeType === 'rect' ||
      object.shapeType === 'triangle' ||
      object.shapeType === 'ellipse'
        ) {
            result = new Map([
                ...result,
                ..._generateTheHighlightForStickyAndShape(object, keyword),
            ]);
        }
    }
    return result;
};

export const clearAllTheHighlight = (canvas, borderRects) => {
    const objects = canvas.getObjects();
    const tables = objects.filter((obj) => obj.type === 'table');

    borderRects.forEach(function (value, key) {
        try {
            if (value.highlightNatively) {
                value.instance.highlightText = false;
            } else {
                canvas.remove(value);

                if (value.tableUuid) {
                    const table = tables.find((t) => t.uuid === value.tableUuid);
                    if (table) {
                        table.clearSearchedCells();
                    }
                }
            }

        } catch (err) {
            console.error(`error while removing text highlight for (${key}):`, err)
        }
    });
    canvas.renderAll();
};
export const renderAllTheHighlight = (canvas, borderRects) => {
    borderRects.forEach(function (value) {
    // if we don't highlighting the text natively
    // add value (fabric.Rect) for highlighting
        if (!value.highlightNatively) {
            canvas.add(value);
        }
    });
    canvas.renderAll();
};
