/**
 *
 */
const EraserIcon = () => {
    return (
        <svg
            fill="none"
            height="20"
            viewBox="0 0 22 21"
            width="20"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.625 19.598h18.75"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                clipRule="evenodd"
                d="M14.847 2.3l4.782 4.59c.47.45.733 1.061.733 1.698s-.264 1.247-.733 1.697l-5.405 5.189a7.662 7.662 0 01-5.303 2.109c-1.99 0-3.897-.759-5.303-2.11l-1.25-1.2a2.333 2.333 0 010-3.393l8.944-8.58a2.574 2.574 0 013.535 0z"
                fillRule="evenodd"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="M7.771 5.7l8.318 7.984"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </svg>
    );
}

export default EraserIcon;