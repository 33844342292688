/**
 *
 */
const PanIcon = () => {
    return (
        <svg
            fill="none"
            height="20"
            viewBox="0 0 17 20"
            width="17"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                clipRule="evenodd"
                d="M13 4.747v-1.5a1.5 1.5 0 00-3 0V2.5a1.5 1.5 0 10-3 0v.75a1.5 1.5 0 10-3 0V8.5a3 3 0 00-3 3c0 1.463.457 2.89 1.308 4.081l1.1 1.535A4.5 4.5 0 007.066 19h3.813A5.12 5.12 0 0016 13.876V4.747a1.5 1.5 0 00-3 0z"
                fillRule="evenodd"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="M4.001 8.496v2.25M6.999 6.998v-3.75M10 6.998v-3.75M13.001 6.996v-2.25"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </svg>
    );
}

export default PanIcon;