import { fabric } from 'fabric';

import store from '../redux/Store';
import { DEFAULT_TEXT_SIZE, PLACEHOLDER_TEXT, SHAPE_DEFAULTS, modes } from '../helpers/Constant';
import { removeCanvasListener, stopDrawing } from '../helpers/CommonFunctions';
import { lockComments } from '../helpers/comments/LockComments';
import { changeObjectsSelectableProp } from '../helpers/FabricMethods';


let drawInstance = null,
    origX,
    origY;

export const draw = (canvas, onMouseDownLineHandler) => {
    store.dispatch({
        type: 'board/changeCurrentMode',
        payload: modes.PENCIL
    });
    const state = store.getState();
    const currentWidth = state?.board.currentWidth ? state.board.currentWidth : 1;
    removeCanvasListener(canvas);
    canvas.toggleDragMode(canvas, false);
    onMouseDownLineHandler(canvas);
    canvas.freeDrawingBrush = new fabric.PencilBrush(canvas);
    canvas.freeDrawingBrush.width = parseInt(currentWidth, 10) || 1;
    canvas.freeDrawingBrush.color = SHAPE_DEFAULTS.STROKE;
    canvas.freeDrawingBrush.strokeDashArray = null;
    canvas.isDrawingMode = true;
    canvas.discardActiveObject().requestRenderAll();
};

export const createText = (canvas, emitOnMouseDown, onMouseDownLineHandler, handleChangeSelectMode) => {
    store.dispatch({
        type: 'board/changeCurrentMode',
        payload: modes.TEXT
    });

    removeCanvasListener(canvas);
    canvas.toggleDragMode(canvas, false);
    canvas.on('mouse:down', startAddText(canvas));
    canvas.on('mouse:up', () => {
        stopDrawing(canvas, drawInstance, emitOnMouseDown, onMouseDownLineHandler);
        handleChangeSelectMode('select');
    });
    canvas.selection = false;
    canvas.isDrawingMode = false;
    changeObjectsSelectableProp(canvas, false);
    canvas.discardActiveObject().requestRenderAll();
};

const startAddText = (canvas) => {
    return _ref3 => {
        let {
            e
        } = _ref3;
        const pointer = canvas.getPointer(e);
        console.log('startAddRect', pointer);
        console.log(e.clientX, ' ', e.clientY);
        origX = pointer.x;
        origY = pointer.y;

        drawInstance = new fabric.Textbox(PLACEHOLDER_TEXT, {
            left: origX,
            top: origY,
            editable: true,
            objectCaching: false,
            fontSize: DEFAULT_TEXT_SIZE,
            fill: SHAPE_DEFAULTS.PLACEHOLDER_COLOR,
            fontWeight: '400',
            fontFamily: SHAPE_DEFAULTS.FONT_FAMILY,
            width: 158,
            backgroundColor: SHAPE_DEFAULTS.BACKGROUND_COLOR,
        });

        drawInstance.setControlsVisibility({
            ml: true,
            mr: true,
            bl: true,
            br: true,
            tl: true,
            tr: true,
            connectorLeft: true,
            connectorTop: true,
            connectorRight: true,
            connectorBottom: true,
        })

        // first initialization text should act like a placeholder
        drawInstance.enablePlaceholder = true;

        drawInstance.borderScaleFactor = 2;

        canvas.add(drawInstance);
    };
}

export const onSelectMode = (canvas, onMouseDownLineHandler) => {
    canvas.isDrawingMode = false;
    removeCanvasListener(canvas);
    onMouseDownLineHandler(canvas);
    changeObjectsSelectableProp(canvas, true);
    canvas.hoverCursor = 'all-scroll';
};

export const clearCanvas = (canvas) => {
    canvas.getObjects().forEach(item => {
        if (item !== canvas.backgroundImage && item.pointType !== 'startPoint' && item.pointType !== 'endPoint') {
            canvas.remove(item);
        }
    });
};

export const changeToErasingMode = (canvas) => {
    store.dispatch({
        type: 'board/changeCurrentMode',
        payload: modes.ERASER
    });

    removeCanvasListener(canvas);
    canvas.isDrawingMode = false;
    lockComments(canvas, true);
    canvas.toggleDragMode(canvas, false);
    canvas.selection = false;
    canvas.discardActiveObject().requestRenderAll();
};

export const removeTheUnusedExpansionDot = (object) =>{
    object.setControlsVisibility({
        ml: false,
        mr: false,
        mt: false,
        mb: false,
        bl: true,
        br: true,
        tl: true,
        tr: true,
    })
}
