import ToastErrorIcon from '../../assets/images/toast_icons/error.png'

/**
 * Error fallback component for LeftDrawer tabs
 * This component activates when an error occurs in any of the tab in the LeftDrawer
 * @component
 */
export default function CommentDrawerErrorFallBack() {
    return (
        <div className="commentDrawer__content">
            <div className="commentDrawer__content--error">
                <div className='errorCard'>
                    <div className='error__icon'>
                        <img src={ToastErrorIcon} alt='Error icon'></img>
                    </div>
                    <div className='error__message'>
                        <h3>Something went wrong</h3>
                        <p>Please try again later</p>
                    </div>
                </div>
            </div>
        </div>
    )
} 