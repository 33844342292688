import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { TUTORIAL_STEPS } from '../../helpers/Constant';
import clsx from 'clsx';
import ArrowSquareLeftSVG from '../../assets/images/tutorial_card/arrow-square-left.svg';
import ArrowSquareRightSVG from '../../assets/images/tutorial_card/arrow-square-right.svg';
import WhiteBg from '../../assets/images/tutorial_card/white-bg.jpg';
import './TutorialCard.scss';
import store from '../../redux/Store';
import { setSeenTutorial } from '../../services/UserService';
import LoadingScreen from '../loader/Loading';

const PADDING = 28; // Padding between the tutorial card and the selector element

const TutorialCard = ({ slug }) => {
    const [position, setPosition] = useState({ 
        top: 0, 
        left: 0,
        arrowPosition: {
            where: 'left'
        }
    }); // State to keep track of tutorial card position
    const [isLoading, setIsLoading] = useState(true);
    const tutorialCardRef = useRef(null);
    const arrowRef = useRef(null);
    const tutorialCardContentRef = useRef(null);
    const tutorialStepRef = useRef(null);
    const gifImageRef = useRef(null);

    const handleLoaderImageLoad = (selector) => {
        if (selector !== tutorialStepRef.current && gifImageRef?.current) {
            setIsLoading(true);
        }
    }

    const handleStep = async (step, selector) => {
        setIsLoading(true);
        const currentStep = TUTORIAL_STEPS.findIndex(step => step.selector === selector);
        if (step === 'next') {
            if (currentStep < TUTORIAL_STEPS.length - 1) {
                handleTutorialStep(TUTORIAL_STEPS[currentStep + 1]);
            } else {
                await handleSkip()
            }
        } else if (step === 'prev' && currentStep > 0) {
            handleTutorialStep(TUTORIAL_STEPS[currentStep - 1]);
        }
    }

    const handleSkip = async () => {
        setIsLoading(true);
        tutorialCardRef.current.classList.add('hidden');
        const userId = store.getState()?.user?.id;
        if (userId) {
            const data = await setSeenTutorial(userId, true);
            if (data) {
                store.dispatch({ type: 'user/setSeenTutorial', payload: true });
                store.dispatch({
                    type: 'modal/toggleTutorial',
                    payload: {
                        shouldShow: false,
                    }
                })
            }
        }
    }

    const handleTutorialStep = useCallback((step) => {
        handleLoaderImageLoad(step.selector);
        tutorialStepRef.current = step.selector;
        const selectorElement = document.querySelector(`[data-tutorial=${step.selector}]`); // Get the selector element based on the data-tutorial attribute value
        const selectorRect = selectorElement.getBoundingClientRect(); // Get the bounding rect of the selector element
        const tutorialCardRect = tutorialCardRef.current.getBoundingClientRect(); // Get the bounding rect of the TutorialCard component
        const tutorialCardContentRect = tutorialCardContentRef.current.getBoundingClientRect(); // Get the bounding rect of the TutorialCardContent component

        let arrowPosition = {
            top: 0,
            left: 0,
            where: 'left'
        }

        // Calculate the position of the tutorial card relative to the hovered selector
        let top = selectorRect.top + window.pageYOffset - PADDING;
        let left = selectorRect.left + window.pageXOffset + selectorRect.width;

        // Check if the step has a custom position property
        if (step.position) {
            switch (step.position) {
                case 'y':
                    // Set position of the tutorial card to horizontally centered according to the selector
                    left = selectorRect.left + window.pageXOffset + (selectorRect.width / 2) - (tutorialCardContentRect.width / 2);
                    arrowPosition.where = 'bottom';
                    break;
                    // Add more custom position cases here if needed
                default:
                    // Default to right of the selector
                    left = selectorRect.left + window.pageXOffset + selectorRect.width + PADDING;
                    arrowPosition.where = 'left';
                    break;
            }
        }

        // If the selector element is on the left of the page, position the tutorial card on the right of the element
        if (selectorRect.left >= tutorialCardRect.left + tutorialCardRect.width && step.position !== 'y') {
            left = selectorRect.left + window.pageXOffset - tutorialCardRect.width;
        }
        if (top + selectorRect.height > window.innerHeight && step.position !== 'y') {
            top = selectorRect.top + window.pageYOffset - tutorialCardRect.height;
        }

        // Check if the tutorial card overflows in y-axis
        if (step.position === 'y' && top + tutorialCardRect.height > selectorRect.top) {
            top = selectorRect.top - tutorialCardContentRect.height - PADDING;
        } else if (step.position !== 'y' && top + tutorialCardRect.height > window.innerHeight) {
            top = window.innerHeight - tutorialCardRect.height;
        }

        if (step.position === 'y' && left + tutorialCardRect.width > window.innerWidth) {
            left = selectorRect.left + selectorRect.width - tutorialCardRect.width + PADDING / 2;
        }
        if (step.position === 'y' && left < 0) {
            left = selectorRect.left;
        }
        if (step.position === 'y' && top < 0) {
            top = selectorRect.top + selectorRect.height + PADDING;
            arrowPosition.where = 'top';
        }

        // we calculated the position of the tutorial card relative to the selector element
        // now its time to calculate the position of the arrow relative to the tutorial card and the selector element

        if (arrowPosition.where === 'left') {
            // calculation is like this: selectorRect.top - Arrowtop + arrow.height / 2
            const position = selectorRect.top - top + (selectorRect.height / 2) - (arrowRef.current.offsetHeight / 2); 
            arrowRef.current.style.transform = `translateY(${position}px)`;
        } else if (arrowPosition.where === 'bottom' || arrowPosition.where === 'top') {
            const position = selectorRect.left - left + (selectorRect.width / 2) - (arrowRef.current.offsetWidth / 2);
            arrowRef.current.style.transform = `translateX(${position}px)`;
        }

        setPosition(prev => ({ ...prev, top, left, arrowPosition }));
    }, []);

    useLayoutEffect(() => {
        if (!slug) return; // Return if the slugId is not available means the app isn't loaded yet
        const handleStepHover = (e) => {
            const hoveredSelector = e.target.dataset.tutorial; // Get the hovered selector from the dataset
            const step = TUTORIAL_STEPS.find(step => step.selector === hoveredSelector); // Find the step object based on the hovered selector
            if (step) {
                handleTutorialStep(step);
            }
        };
        // Loop through the steps and add mouseover event listener for each selector
        TUTORIAL_STEPS.forEach(step => {
            const selectorElement = document.querySelector(`[data-tutorial=${step.selector}]`);
            if (selectorElement) {
                selectorElement.addEventListener('mouseover', handleStepHover);
            }
        });
        try {
            const firstStep = TUTORIAL_STEPS[0]
            const selectorElement = document.querySelector(`[data-tutorial=${firstStep.selector}]`);
            if (selectorElement) {
                selectorElement.dispatchEvent(new MouseEvent('mouseover'));
            }
        } catch (err) {
            console.error('error while initializing tutorial', err)
        }

        return () => {
            // Clean up the event listeners on component unmount
            TUTORIAL_STEPS.forEach(step => {
                const selectorElement = document.querySelector(`[data-tutorial=${step.selector}]`);
                if (selectorElement) {
                    selectorElement.removeEventListener('mouseover', handleStepHover);
                }
            });
        };
    }, [slug, handleTutorialStep]);

    const tutorial = TUTORIAL_STEPS.find(step => step.selector === tutorialStepRef.current);
    const beforeTutorials = TUTORIAL_STEPS.filter((step, index) => index < TUTORIAL_STEPS.indexOf(tutorial));
    const nextTutorials = TUTORIAL_STEPS.filter((step, index) => index > TUTORIAL_STEPS.indexOf(tutorial));

    if (tutorial?.gifUrl && gifImageRef.current) {
        gifImageRef.current.src = tutorial.gifUrl;
    }
    
    return (
        <div
            className={clsx('tutorial-card', {
                'column': position.arrowPosition?.where === 'top' || position.arrowPosition?.where === 'bottom',
                'reverse': position.arrowPosition?.where === 'bottom',
                'hidden': !tutorialStepRef.current
            })}
            data-testid="tutorial-card" 
            ref={tutorialCardRef}
            style={{
                top: position.top,
                left: position.left
            }}
        >
            <div className='tutorial-card__arrow'>
                <div 
                    className={clsx('tutorial-card__arrow--inner', {
                        'left': position.arrowPosition?.where === 'left',
                        'top': position.arrowPosition?.where === 'top',
                        'bottom': position.arrowPosition?.where === 'bottom',
                    })}
                    ref={arrowRef} />
            </div>
            <div className='tutorial-card__content' ref={tutorialCardContentRef}>
                <div className='tutorial-card__header'>
                    <div className="tutorial-card__image">
                        {isLoading ? <div className="tutorial-loading-screen"><LoadingScreen /></div> : null}
                        <img alt="tutorial" height={243} onLoad={()=> setIsLoading(false)} ref={gifImageRef} src={WhiteBg} style={{ display: isLoading ? 'none' : 'block' }} width={365} />
                    </div>
                    <div className="tutorial-card__header--content">
                        <h3 className="tutorial-card__title">{tutorial?.title}</h3>
                        {/* <p className="tutorial-card__description">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        </p> */}
                    </div>
                </div>
                <div className="tutorial-card__footer">
                    <div className='tutorial-card__footer--left'>
                        <button 
                            type="button"
                            aria-label='Go to previous tutorial' 
                            className="tutorial-card__btn"
                            data-testid='tutorial-card-prev'
                            disabled={!beforeTutorials?.length}
                            onClick={() => handleStep('prev', tutorial?.selector)}
                        >
                            <img alt="Previous tutorial" src={ArrowSquareLeftSVG} />
                        </button>
                        <button
                            type="button"
                            className="tutorial-card__skip" 
                            data-testid='tutorial-card-skip'
                            onClick={() => handleSkip()}
                        >Skip</button>
                    </div>
                    <button
                        type="button"
                        aria-label='Go to next tutorial' 
                        className="tutorial-card__btn"
                        data-testid='tutorial-card-next'
                        onClick={() => handleStep('next', tutorial?.selector)}
                    >
                        <span>{nextTutorials.length ? 'Next' : 'Finish'}</span>
                        <img alt="Next tutorial" src={ArrowSquareRightSVG} />
                    </button>
                </div>
            </div>
        </div>
    )
}

TutorialCard.propTypes = {
    slug: PropTypes.string
}

export default TutorialCard;