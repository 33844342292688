import axios from 'axios';
import { apiEndpoints } from '../helpers/Constant';
import { environment } from '../environment';
import { showUnauthorizedToastMessage } from '../helpers/CommonFunctions';
import { handleDecodingOfBmeetAndEnterpriseEndpoint, handleDecodingOfGetWbDetailsEndpoint } from '../helpers/DecodeHelper';

/**
 *
 */
export async function createWhiteBoard() {
    try {
        const response = await axios.post(environment.REACT_APP_API_ENDPOINT + apiEndpoints.createWhiteBoard, {});
        return response;
    } catch (error) {
        return [];
    }
}

/**
 *
 */
export async function getBoardListing() {
    try {
        const response = await axios.get(environment.REACT_APP_API_ENDPOINT + apiEndpoints.createWhiteBoard);
        return response;
    } catch (error) {
        return [];
    }
}

export async function deletedWhiteBoardList() {
    try {
        const response = await axios.get(environment.REACT_APP_API_ENDPOINT + apiEndpoints.deletedWhiteBoardList);
        return response;
    } catch (error) {
        if (error?.response?.status === 404 && error?.response?.data?.message) {
            showUnauthorizedToastMessage(error.response.data.message)
        }
        return {};
    }
}

/**
 * @param wbId
 */
export async function duplicateBoard(wbId) {
    try {
        const response = await axios.post(`${environment.REACT_APP_API_ENDPOINT}${apiEndpoints.duplicateWhiteboard.replace('{wbId}', wbId) }`);
        return response;
    } catch (error) {
        return [];
    }
}

/**
 * @param wbId
 */
export async function restoreBoard(wbId) {
    try {
        const response = await axios.post(`${environment.REACT_APP_API_ENDPOINT}${apiEndpoints.restoreWhiteboard.replace('{wbId}', wbId)}`);
        return response;
    } catch (error) {
        return [];
    }
}

/**
 * @param wbId
 */
export async function deleteBoard(wbId) {
    try {
        const response = await axios.delete(`${environment.REACT_APP_API_ENDPOINT}${apiEndpoints.deleteWhiteboard.replace('{wbId}', wbId)}`);
        return response;
    } catch (error) {
        return [];
    }
}

/**
 * @param wbId
 * @param {string=} pageId
 */
export async function getWhiteBoardDetails(wbId, pageId) {
    try {
        let url = `${environment.REACT_APP_API_ENDPOINT}${apiEndpoints.getWhiteBoardDetails.replace('{wbId}', wbId)}`;
        if (pageId) {
            url += `?wbPageId=${pageId}`
        }

        const response = await axios.post(url, {});
        return handleDecodingOfGetWbDetailsEndpoint(response);
    } catch (error) {
        if (error?.response?.status === 404 && error?.response?.data?.message) {
            showUnauthorizedToastMessage(error.response.data.message)
        }
        else if ([401, 403].includes(error?.response?.status) && (error?.response?.data?.error || error?.response?.data?.message)) {
            return error
        }
        return {};
    }
}

/**
 * @param files
 * @param wbId
 * @param pageId
 */
export async function uploadImage(files, wbId, pageId) {
    try {
        let formData = new FormData();
        for  (const file of files)  {  
            formData.append(`images`, file.file);
        } 
        const url = `${environment.REACT_APP_API_ENDPOINT}${apiEndpoints.uploadImageUrl.replace('{wbId}', wbId)}?wbPageId=${pageId}`;
        const response = await axios.post(url, formData);
        return response;
    } catch (error) {
        return [];
    }
}

/**
 * @param id
 * @param build_card_name
 * @param pageId
 */
export async function automatedBoard(id, build_card_name, pageId, externalId) {
    try {
        let url = `${
            environment.REACT_APP_API_ENDPOINT
        }${apiEndpoints.getAutomatedBoard.replace('{id}', id)}`;

        if (pageId) {
            url += `?wbPageId=${pageId}`
        }
        const postData = {
            build_card_name,
        }

        if (externalId) {
            postData.nimaWbId = externalId
        }

        const response = await axios.post(url, postData);
        return handleDecodingOfGetWbDetailsEndpoint(response);
    } catch (error) {
        if (error?.response?.status === 404 && error?.response?.data?.message) {
            showUnauthorizedToastMessage(error.response.data.message)
        }
        return {};
    }
}

/**
 * @param id
 * @param email
 */
export async function automatedBMeetBoard(id, email) {
    try {
        const response = await axios.post(environment.REACT_APP_API_ENDPOINT + apiEndpoints.getAutomatedBMeetBoard.replace('{id}', id), {
            email
        });
        return handleDecodingOfBmeetAndEnterpriseEndpoint(response);
    } catch (error) {
        if (error?.response?.status === 404 && error?.response?.data?.message) {
            showUnauthorizedToastMessage(error.response.data.message)
        }

        return {};
    }
}

/**
 * @param id
 * @param permissionType
 */
export async function automatedEnterpriseBoard(id, permissionType) {
    try {
        const response = await axios.get(environment.REACT_APP_API_ENDPOINT + apiEndpoints.getAutomatedEnterpriseBoard.replace('{id}', id).replace('{permissionType}', permissionType));
        return handleDecodingOfBmeetAndEnterpriseEndpoint(response);
    } catch (error) {
        return [];
    }
}

/**
 * @param studio_build_card_id
 */
export async function checkForAperios(studio_build_card_id) {
    try {
        const response = await axios.get(environment.NOW_API_ENDPOINT + apiEndpoints.checkForAperios.replace('{studio_build_card_id}', studio_build_card_id));
        return response;
    } catch (error) {
        return [];
    }
}
