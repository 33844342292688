import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

/**
 * @param {object} props
 * @param props.onClickHandler
 * @param props.activeFontStyle
 * @param props.tooltipRef
 */
const FontStyleMenu = ({ onClickHandler, activeFontStyle, tooltipRef }) => {
    const menuRef = useRef();
    useEffect(() => {
        const menuItem = menuRef.current;
        if (!menuItem) return;
        const clientRect = menuItem.getBoundingClientRect();
        if (Math.sign(clientRect.top) === -1) {
            menuItem.classList.add('onBottom');
            tooltipRef?.current && tooltipRef.current.classList.remove('onBottom');
        } else {
            menuItem.classList.remove('onBottom');
        }
    });

    return  <div className="fontStyleListItems" ref={menuRef}>
        <div className={clsx('underLineBlock', {
            'active': activeFontStyle.underline
        })}><em className="icon-underline" onClick={() => onClickHandler('underline', true)} /></div>
        <div className={clsx('italicBlock', {
            'active': activeFontStyle.italic
        })}><em className="icon-italic" onClick={() => onClickHandler('fontStyle', 'italic')} /></div>
        <div className={clsx('boldBlock', {
            'active': activeFontStyle.bold
        })}><em className="icon-bold" onClick={() => onClickHandler('fontWeight', 'bold')} /></div>
    </div>
}

FontStyleMenu.propTypes = {
    onClickHandler: PropTypes.func.isRequired,
    activeFontStyle: PropTypes.shape({
        underline: PropTypes.bool,
        italic: PropTypes.bool,
        bold: PropTypes.bool
    }),
    tooltipRef: PropTypes.shape({
        current: PropTypes.instanceOf(HTMLElement)
    })
}

export default FontStyleMenu;