import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { setSeenTutorial } from '../../services/UserService';
import eventEmitter from '../../helpers/EventEmitter';
import {EMITTER_TYPES, MODAL_WINDOWS} from '../../helpers/Constant';

// ASSETS
import clsx from 'clsx';
import KeyboardIconSvg from '../svgIcons/KeyboardIcon';

const HelpButton = ({
    userId,
    activeWindowState,
    handleToggleCommentDrawer,
    canvas
}) => {
    const dispatch = useDispatch();

    const showFeedbackModalHandler = () => {
        handleToggleCommentDrawer({ shouldShow: false });
        discardActiveObject()
        dispatch({
            type: 'modal/toggleFeedbackModal',
            payload: { shouldShow: true }
        });
    }

    const showTutorialHandler = async () => {
        handleToggleCommentDrawer({ shouldShow: false });
        discardActiveObject()
        dispatch({ type: 'user/setSeenTutorial', payload: false });
        dispatch({
            type: 'modal/toggleTutorial',
            payload: {
                shouldShow: true,
            }
        })

        try {
            await setSeenTutorial(userId, false);

            setTimeout(() => {
                const element = document.querySelector('[data-tutorial=select]');
                element.dispatchEvent(new MouseEvent('mouseover'));
            }, 0)
        } catch (err) {
            console.error('Error in resetting onboard', err);
        }
    }
    
    const discardActiveObject = () => {
        if (!canvas) {
            return
        }
        try {
            canvas.discardActiveObject().requestRenderAll();
        } catch (err) {
            console.error(err)
        }
    }

    return (
        <div className={clsx('helpWrapper helpButtonWrapper', {
            active: activeWindowState === MODAL_WINDOWS.SHORTCUT_LIST
        })}>
            <div
                className="topHelpButton helpButtonWrapper helpButtonMain"
                data-tutorial='shortcuts'
            >
                <em className="icon-canvas-redesign-help"  />
            </div>

            <div className='helpInnerWrapper'>
                <div className="topFeedbackButton__wrapper">
                    <div
                        className="topHelpButton"
                        onClick={() => {
                            handleToggleCommentDrawer({ shouldShow: false })
                            eventEmitter.fire(EMITTER_TYPES.TOGGLE_SHORTCUT_LIST, true)
                            discardActiveObject();
                        }}
                    >
                        <KeyboardIconSvg className="icon-canvas-redesign-help" />
                        <span>Keyboard shortcuts</span>
                    </div>
                </div>
                <div className="topFeedbackButton__wrapper">
                    <div
                        className="topFeedbackButton "
                        onClick={showFeedbackModalHandler}
                    >
                        <em className="icon-canvas-redesign-feedback" />
                        <span>Feedback</span>
                    </div>
                </div>
                <div className="topTutorialButton__wrapper">
                    <div
                        className="topTutorialButton "
                        onClick={showTutorialHandler}
                    >
                        <em className="icon-canvas-redesign-reset-onboarding" />
                        <span>Reset Onboarding</span>
                    </div>
                </div>
                
            </div>
        </div>
    )
};

HelpButton.propTypes = {
    userId: PropTypes.number.isRequired,
    activeWindowState: PropTypes.string,
    handleToggleCommentDrawer: PropTypes.func.isRequired,
    canvas: PropTypes.object,
}

export default HelpButton;
