import { fabric } from 'fabric';

/**
 * Returns true if object is transparent at a certain location.
 * @override
 * @param {fabric.Object} target - Object to check.
 * @param {number} x - Left coordinate.
 * @param {number} y - Top coordinate.
 * @returns {boolean}
 */
export default function isTargetTransparent(target, x, y) {

    // in case the target is the activeObject, we cannot execute this optimization
    // because we need to draw controls too.
    if (target.shouldCache() && target._cacheCanvas && target !== this._activeObject) {
        let normalizedPointer = this._normalizePointer(target, {x: x, y: y}),
            targetRelativeX = Math.max(target.cacheTranslationX + (normalizedPointer.x * target.zoomX), 0),
            targetRelativeY = Math.max(target.cacheTranslationY + (normalizedPointer.y * target.zoomY), 0);

        let isTransparent = fabric.util.isTransparent(
            target._cacheContext, Math.round(targetRelativeX), Math.round(targetRelativeY), this.targetFindTolerance);

        return isTransparent;
    }

    var ctx = this.contextCache,
        originalColor = target.selectionBackgroundColor, v = this.viewportTransform;

    target.selectionBackgroundColor = '';

    this.clearContext(ctx);

    ctx.save();
    ctx.transform(v[0], v[1], v[2], v[3], v[4], v[5]);
    
    // override starts here
    // if the target is a curved line, we need to render it differently
    // in order to get the correct hit detection
    let isRenderingForHitDetection = target && target.type === 'curvedLine';
    
    const renderingProps = {
        isRenderingForHitDetection: isRenderingForHitDetection
    }

    // pass the isRenderingForHitDetection flag to get the line knows
    // this render is for hit detection 
    target.render(ctx, renderingProps);
    ctx.restore();

    target.selectionBackgroundColor = originalColor;

    let isTransparent = fabric.util.isTransparent(
        ctx, x, y, this.targetFindTolerance);

    return isTransparent;
}