import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { EMITTER_TYPES, MODAL_WINDOWS, constShapes } from '../../helpers/Constant';
import MoreTools from '../moreTools/MoreTools';
import useCanvas from '../../hooks/UseCanvas';
import eventEmitter from '../../helpers/EventEmitter';

import StickyNoteIcon from '../svgIcons/canvasRedesign/IconStickyNote';
import CursorIcon from '../svgIcons/canvasRedesign/IconCursor';
import PanIcon from '../svgIcons/canvasRedesign/IconPan';
import FrameIcon from '../svgIcons/canvasRedesign/IconFrame';
import UndoIcon from '../svgIcons/canvasRedesign/IconUndo';
import RedoIcon from '../svgIcons/canvasRedesign/IconRedo';
import './Toolbar.scss';
import ToolbarItem from './ToolbarItem';

const Toolbar = ({
    actionSelected,
    selectedShape,
    selectedPencilType,
    handleChangeSelectMode,
    handleToolbarItemVisibility,
    handlePencilAndShapesMode,
    handleAddObject,
    isLassoRef,
    userAccessRef,
}) => {
    // Refs
    const toolbarScrollContainerRef = useRef(null);

    // Hooks
    const canvas = useCanvas();

    // States
    const activeWindowState = useSelector(state => state?.modal?.activeWindow);
    const activePageId = useSelector(state => state?.rightDrawer?.activePage?.id);

    const [enabledHistory, setEnabledHistory] = useState({})

    // Vars
    const isLeftDrawerOpen = activeWindowState === MODAL_WINDOWS.ACTIVITY_LOGS;

    const handleUndoRedo = (operation) => {
        if (handleToolbarItemVisibility(operation)) { return; }

        if (enabledHistory[activePageId][operation]) {
            canvas.fire('undo-redo', operation);  // todo: this
        }
    }

    const onEnabledHistoryChange = useCallback(({ pageId, enabledHistory: enabledHistoryForPage }) => {
        setEnabledHistory((state) => ({
            ...state,
            [pageId]: enabledHistoryForPage
        }))
    }, [setEnabledHistory]);

    useEffect(() => {
        eventEmitter.on(EMITTER_TYPES.HISTORY_ENABLED, onEnabledHistoryChange);

        return () => {
            eventEmitter.off(EMITTER_TYPES.HISTORY_ENABLED, onEnabledHistoryChange);
        }
    }, [onEnabledHistoryChange]);

    return (
        <div
            className={clsx('toolbar leftToolbarMain', {
                leftDrawerActive: isLeftDrawerOpen,
            })}
            id="toolbar"
        >
            {/* ************ SELECT ************ */}
            <ToolbarItem
                type="button"
                className={clsx({ active: actionSelected.selectMode, disabled: handleToolbarItemVisibility('select') })}
                tutorial="select"
                onClick={() => handleChangeSelectMode('select', activeWindowState)}
            >
                <div className="button__iconWrapper"><CursorIcon /></div>
                <span className="tooltiptext">Select</span>
            </ToolbarItem>

            {/* ************ PAN ************ */}
            <ToolbarItem
                type="button"
                className={clsx('disable-mb', { active: actionSelected.dragMode, disabled: handleToolbarItemVisibility('pan') })}
                tutorial="pan"
                onClick={() => handleChangeSelectMode('pan', activeWindowState)}
            >
                <div className="button__iconWrapper"><PanIcon /></div>
                <span className="tooltiptext">Pan</span>
            </ToolbarItem>

            <ToolbarItem type="seperator" />

            <div className="scrollContainer" ref={toolbarScrollContainerRef}>
                {/* ************ TEXT ************ */}
                <ToolbarItem
                    type="button"
                    className={clsx({ active: actionSelected.textMode, disabled: handleToolbarItemVisibility('text') })}
                    onClick={() => handleAddObject('text', {}, activeWindowState)}
                >
                    <div className="button__iconWrapper"><em className="icon-canvas-redesign-text" style={{ fontSize: '20px' }} /></div>
                    <span className="tooltiptext">Text</span>
                </ToolbarItem>


                {/* ************ FRAME ************ */}
                <ToolbarItem
                    type="button"
                    className={clsx({ active: actionSelected.frameMode, disabled: handleToolbarItemVisibility('frame') })}
                    tutorial="frame"
                    onClick={() => handleAddObject('frame', {}, activeWindowState)}
                >
                    <div className="button__iconWrapper">
                        <FrameIcon />
                    </div>
                    <span className="tooltiptext">Frame</span>
                </ToolbarItem>


                {/* ************ STICKY NOTE ************ */}
                <ToolbarItem
                    type="button"
                    className={clsx({ active: actionSelected.stickyMode, disabled: handleToolbarItemVisibility('Sticky') })}
                    tutorial="sticky-note"
                    onClick={() => handleAddObject('Sticky', {}, activeWindowState)}
                >
                    <div className="button__iconWrapper">
                        <StickyNoteIcon />
                    </div>
                    <span className="tooltiptext">Sticky Note</span>
                </ToolbarItem>


                {/* ************ SHAPES ************ */}
                <ToolbarItem
                    type="subToolbarWrapper"
                    className={clsx({ active: actionSelected[`${selectedShape.shapeMode}`], disabled: handleToolbarItemVisibility(selectedShape.shapeButton) })}
                >
                    <ToolbarItem
                        type="button"
                        isInnerButton={true}
                        className={clsx({ active: actionSelected[`${selectedShape.shapeMode}`], disabled: handleToolbarItemVisibility(selectedShape.shapeButton) })}
                        onClick={() => handlePencilAndShapesMode(selectedShape, activeWindowState)}
                    >
                        <div className="button__iconWrapper">
                            {selectedShape.shapeClass ? <em className={selectedShape.shapeClass} /> : <selectedShape.shapeSvg />}
                        </div>
                    </ToolbarItem>

                    <>
                        <ToolbarItem
                            type="subToolbarItem"
                            className="tooltipTop"
                        >
                            {constShapes.map((shape) => (
                                (shape.shapeName !== selectedShape.shapeName && (shape.shapeType === 'primeShapes' || shape.shapeName === 'Line')) ? <div
                                    className={clsx('button', {
                                        active: actionSelected[`${shape.shapeMode}`],
                                        disabled: handleToolbarItemVisibility(shape.shapeButton)
                                    })}
                                    key={shape.shapeName}
                                    onClick={() => handlePencilAndShapesMode(shape, activeWindowState)}>
                                    {
                                        shape.shapeClass ? <em className={shape.shapeClass} /> : <shape.shapeSvg />
                                    }
                                    <span className="tooltiptext">{shape.shapeName}</span>
                                </div>
                                    : null
                            ))}
                        </ToolbarItem>
                        <span className="tooltiptext">{selectedShape.shapeName}</span>
                    </>
                </ToolbarItem>


                {/* ************ PENCIL MODES ************ */}
                <ToolbarItem
                    type="subToolbarWrapper"
                    className={clsx({ active: actionSelected[`${selectedPencilType.shapeMode}`], disabled: handleToolbarItemVisibility(selectedPencilType.shapeButton) })}
                >
                    <ToolbarItem
                        type="button"
                        isInnerButton={true}
                        className={clsx({ active: actionSelected[`${selectedPencilType.shapeMode}`], disabled: handleToolbarItemVisibility(selectedPencilType.shapeButton) })}
                        onClick={() => handlePencilAndShapesMode(selectedPencilType, activeWindowState)}
                    >
                        <div className="button__iconWrapper">
                            {selectedPencilType.shapeClass ? <em className={selectedPencilType.shapeClass} /> : <selectedPencilType.shapeSvg />}
                        </div>
                    </ToolbarItem>

                    <>
                        <ToolbarItem
                            type="subToolbarItem"
                            className="tooltipTop"
                        >
                            {constShapes.map((shape) => (
                                (shape.shapeName !== selectedPencilType.shapeName && shape.shapeType === 'pencil') ? <div
                                    className={clsx('button', {
                                        active: actionSelected[`${shape.shapeMode}`],
                                        disabled: handleToolbarItemVisibility(shape.shapeButton)
                                    })}
                                    key={shape.shapeName}
                                    onClick={() => handlePencilAndShapesMode(shape, activeWindowState)}>
                                    {
                                        shape.shapeClass ? <em className={shape.shapeClass} /> : <shape.shapeSvg />
                                    }
                                    <span className="tooltiptext">{shape.shapeName}</span>
                                </div>
                                    : null
                            ))}
                        </ToolbarItem>
                        <span className="tooltiptext">{selectedPencilType.shapeName}</span>
                    </>
                </ToolbarItem>

                <MoreTools
                    actionSelected={actionSelected}
                    handleAddObject={(objectType, options) => handleAddObject(objectType, options, activeWindowState)}
                    handleToolbarItemVisibility={handleToolbarItemVisibility}
                    isLassoRef={isLassoRef}
                    userAccessRef={userAccessRef}
                />
            </div>

            <ToolbarItem type="seperator" />

            <div data-tutorial='undo-redo'> {/* undo redo wrapper */}
                <ToolbarItem
                    type="button"
                    className={clsx({ active: actionSelected.undoMode, disabled: !enabledHistory[activePageId]?.undo || handleToolbarItemVisibility('undo') })}
                    tutorial="undo-redo"
                    disabled={!enabledHistory[activePageId]?.undo || handleToolbarItemVisibility('undo')}
                    onClick={() => handleUndoRedo('undo')}
                >
                    <div className="button__iconWrapper">
                        <UndoIcon />
                    </div>
                    <span className="tooltiptext">Undo</span>
                </ToolbarItem>

                <ToolbarItem
                    type="button"
                    className={clsx({ active: actionSelected.redoMode, disabled: !enabledHistory[activePageId]?.redo || handleToolbarItemVisibility('redo') })}
                    tutorial="undo-redo"
                    disabled={!enabledHistory[activePageId]?.redo || handleToolbarItemVisibility('redo')}
                    onClick={() => handleUndoRedo('redo')}
                >
                    <div className="button__iconWrapper">
                        <RedoIcon />
                    </div>
                    <span className="tooltiptext">Redo</span>
                </ToolbarItem>
            </div>
        </div>
    )
};

Toolbar.propTypes = {
    actionSelected: PropTypes.shape({
        selectMode: PropTypes.bool,
        lassoMode: PropTypes.bool,
        dragMode: PropTypes.bool,
        commentMode: PropTypes.bool,
        pencilMode: PropTypes.bool,
        eraseMode: PropTypes.bool,
        deleteMode: PropTypes.bool,
        rectMode: PropTypes.bool,
        ellipseMode: PropTypes.bool,
        triangleMode: PropTypes.bool,
        arrowMode: PropTypes.bool,
        lineMode: PropTypes.bool,
        stickyMode: PropTypes.bool,
        frameMode: PropTypes.bool,
        textMode: PropTypes.bool,
        undoMode: PropTypes.bool,
        redoMode: PropTypes.bool,
        tableMode: PropTypes.bool
    }),
    selectedShape: PropTypes.shape({
        shapeName: PropTypes.string,
        shapeMode: PropTypes.string,
        shapeButton: PropTypes.string,
        shapeSvg: PropTypes.any,
        shapeType: PropTypes.string,
        shapeClass: PropTypes.string,
    }),
    selectedPencilType: PropTypes.shape({
        shapeName: PropTypes.string,
        shapeMode: PropTypes.string,
        shapeButton: PropTypes.string,
        shapeSvg: PropTypes.any,
        shapeType: PropTypes.string,
        shapeClass: PropTypes.string,
    }),
    handleChangeSelectMode: PropTypes.func.isRequired,
    handleToolbarItemVisibility: PropTypes.func.isRequired,
    handlePencilAndShapesMode: PropTypes.func.isRequired,
    handleAddObject: PropTypes.func.isRequired,
    isLassoRef: PropTypes.shape({
        current: PropTypes.bool
    }),
    userAccessRef: PropTypes.shape({
        current: PropTypes.oneOf(['view', 'comment', 'edit', 'removeAccess', 'NOT_ALLOWED'])
    }),
}

export default Toolbar;