import { decodeTextFromBase64 } from './CommonUtils';

/**
 * Decoding encoded emails of whiteboard details endpoint response.
 * @param {object} response HTTP request's response reference.
 * @returns {object} // Same reference is returning by updating emails.
 */
export const handleDecodingOfGetWbDetailsEndpoint = (response) => {
    if (response?.owner?.encodedEmail) {
        response.owner.email = decodeTextFromBase64(response.owner.encodedEmail);
    }

    if (Array.isArray(response?.users) && response.users.length > 0) {
        response.users = response.users.map((user) => {
            if (user.encodedEmail) {
                user.email = decodeTextFromBase64(user.encodedEmail);
            }

            return user;
        });
    }

    if (Array.isArray(response?.onlineUsers) && response.onlineUsers.length > 0) {
        response.onlineUsers = response.onlineUsers.map((user) => {
            if (user.encodedEmail) {
                user.email = decodeTextFromBase64(user.encodedEmail);
            }

            return user;
        });
    }

    return response;
}

/**
 * Decoding encoded emails of bmeet and enterprise whiteboard details endpoint response.
 * @param {object} response HTTP request's response reference.
 * @returns {object} // Same reference is returning by updating emails.
 */
export const handleDecodingOfBmeetAndEnterpriseEndpoint = (response) => {
    if (response.whiteboardData) {
        response.whiteboardData = handleDecodingOfGetWbDetailsEndpoint(response.whiteboardData);
    }

    if (response.userData?.encodedEmail) {
        response.userData.email = decodeTextFromBase64(response.userData.encodedEmail);
    }

    return response;
}

/**
 * Decoding encoded emails of get users endpoint response.
 * @param {object} response HTTP request's response reference.
 * @returns {object} // Same reference is returning by updating emails.
 */
export const handleDecodingOfGetUsersEndpoint = (response) => {
    if (Array.isArray(response)) {
        response.forEach((item, idx) => {
            if (item?.encodedCommentEmail) {
                response[idx].commentEmail = decodeTextFromBase64(item.encodedCommentEmail);
            }
        })
    }

    return response;
}

/**
 * Decoding encoded email of invite endpoint response.
 * @param {object} response HTTP request's response reference.
 * @returns {object} // Same reference is returning by updating emails.
 */
export const handleDecodingOfInviteEndpoint = (response) => {
    if (response?.encodedCommentEmail) {
        response.email = decodeTextFromBase64(response.encodedCommentEmail);
    }

    return response;
}

/**
 * Decoding encoded email of invite socket event.
 * @param {object} response Socket event response reference.
 * @returns {object} // Same reference is returning by updating emails.
 */
export const handleDecodingOfInviteSocketEvent = (response) => {
    if (response?.encodedCommentEmail) {
        response.email = decodeTextFromBase64(response.encodedCommentEmail);
    }

    return response;
}

/**
 * Decoding encoded email of user recommendation endpoint response.
 * @param {object} users HTTP request's response reference.
 * @returns {[object]} // Same reference is returning by updating emails.
 */
export const handleDecodingOfUserRecommendationEndpoint = (users) => {
    if (!Array.isArray(users)) return [];

    return users.map((user) => {
        if (user.encodedEmail) {
            user.email = decodeTextFromBase64(user.encodedEmail);
        }

        return user;
    });
}