/**
 * @param src
 */
export function loadImgAsync(src) {
    return new Promise((resolve, reject) => {
        let img = new Image()
        img.onload = () => resolve(img.src);
        img.onerror = reject
        img.src = src
    })
}


/**
 * @param files
 */
export default async function loadImagesAsync(files) {
    const loadedImages = [];
    for (const fileRow of Object.values(files)) {
        for (const fileID in fileRow.files) {
            const file = fileRow.files[fileID];
            try {
                const imageUrl = await loadImgAsync(URL.createObjectURL(file.file));
                loadedImages.push({image: imageUrl, uuid: file.uuid, rowUuid: fileRow.rowUuid, imageIndex: fileID});
            } catch (err) {
                console.error('Error happened while loading image', err);
            }
        }
    }
    return loadedImages;
}


/**
 * Loads an image from a URL. Fabric js 6 uses this function for loading images.
 * @param {string} url 
 * @param {object} options
 * @param options.signal
 * @param options.crossOrigin
 * @returns 
 */
export const loadImage = (
    url,
    { signal, crossOrigin = null } = {}
) =>
    new Promise(function (resolve, reject) {
        if (signal && signal.aborted) {
            return reject(new Error('`options.signal` is in `aborted` state'));
        }
        const img = document.createElement('img');

        let abort;
        if (signal) {
            abort = function (err) {
                img.src = '';
                reject(err);
            };
            signal.addEventListener('abort', abort, { once: true });
        }
        const done = function () {
            img.onload = img.onerror = null;
            abort && signal?.removeEventListener('abort', abort);
            resolve(img);
        };
        if (!url) {
            done();
            return;
        }
        img.onload = done;
        img.onerror = function () {
            abort && signal?.removeEventListener('abort', abort);
            reject(new Error('Error loading ' + img.src));
        };
        crossOrigin && (img.crossOrigin = crossOrigin);
        img.src = url;
    });