/**
 *
 */
const IconLineTypeStraight = () => {
    return (
        <svg
            fill="none"
            height="18"
            viewBox="0 0 19 18"
            width="19"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17 2L9 9.5M1 17l8-7.5"
                stroke="#3C3E49"
                strokeLinecap="round"
            />
        </svg>
    )
}

export default IconLineTypeStraight;