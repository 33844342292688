import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import AddIcon from '../../assets/images/add.svg'
import MENU_ITEMS from './constants/MenuItems';
import SearchBoard from './search/SearchBoard';
import BoardListingSingleContent from './BoardListingSingleContent';
import Modal from '../modal/Modal';
import { toast } from 'react-toastify';
import { deleteBoard, duplicateBoard, restoreBoard } from '../../services/DataService';
import getToastIcon from '../../helpers/media/GetToastIcon';
import {
    CircularProgressbar,
    buildStyles
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import ChangingProgressProvider from './ChangingProgressProvider';
import InfoCircle from '../svgIcons/InfoCircle';


const BoardListingContent = ({
    activePage,
    boards,
    onBoardSelected,
    createNewBoard,
    userName,
    handleGetBoardListing
}) => {
    const modalRef = useRef();
    // const deletedBoardInfo = useRef({});
    const [currentBoards, setCurrentBoards] = useState([]);
    const [deletedBoardInfo, setDeletedBoardInfo] = useState({});
    useEffect(()=>{
        setCurrentBoards(boards);
    }, [boards?.length]);
    
    const handleDropdownItemClick = async ({ id, board }) => {
        await toast.dismiss();
        let isError = false;
        if (id === 'copy') {
            toast.info(
                <span className="duplicate-board_toast__text">
                    <div className="duplicate-board_toast__loader">
                        <ChangingProgressProvider values={[0, 100]}>
                            {(percentage) => (
                                <CircularProgressbar
                                    value={percentage}
                                    styles={buildStyles({
                                        pathTransition: percentage === 0 ? 'none' : 'stroke-dashoffset 1.2s ease 0s',
                                        rotation: 0.5 + (1 - percentage / 100) / 2,
                                        pathColor: '#6200EA',
                                        trailColor: '#B388FF4D',
                                        strokeWidth: 20
                                    })}
                                />
                            )}
                        </ChangingProgressProvider>
                    </div>
                    <span className="duplicate-board_toast__text--main">Duplicating whiteboard</span>
                </span>, {
                    icon: false,
                    autoClose: false,
                    className: 'wb_toast wb_toast__duplicate-board',
                    draggable: false,
                    toastId: `duplicate-loading-${board.id}`
                })
            try {
                const response = await duplicateBoard(board.id)

                if (response?.success) {
                    toast.dismiss(`duplicate-loading-${board.id}`);
                    handleGetBoardListing();
                    toast.info(
                        <span className="duplicate-board_toast__text">
                            <span className="duplicate-board_toast__text--main">Whiteboard duplicated successfully</span>
                            <span onClick={() => onBoardSelected(response.data.wbId)} className="duplicate-board_toast__button toast_btn">Open whiteboard</span>
                        </span>,
                        {
                            icon: false,
                            autoClose: false,
                            className: 'wb_toast wb_toast__duplicate-board',
                            draggable: false,
                            toastId: `duplicate-success-${board.id}`
                        })
                }
            }
            catch (err) {
                isError = true;
            }
            finally {
                if (isError) {
                    toast.error('An error has occurred while duplicating this board. Please try again later!', {
                        icon: getToastIcon('error'),
                        className: 'wb_toast wb_toast__duplicate-board',
                        toastId: `duplicate-error-${board.id}`
                    });
                }
            }
        }
        else {
            // deletedBoardInfo.current = board;
            setDeletedBoardInfo(board);
            modalRef.current?.openModal();
        }
    }

    const handleRestoreBoard = async ({ board }) => {
        await toast.dismiss();
        let isError = false;
        toast.info(
            <span className="duplicate-board_toast__text">
                <div className="duplicate-board_toast__loader">
                    <ChangingProgressProvider values={[0, 100]}>
                        {(percentage) => (
                            <CircularProgressbar
                                value={percentage}
                                styles={buildStyles({
                                    pathTransition: percentage === 0 ? 'none' : 'stroke-dashoffset 1.2s ease 0s',
                                    rotation: 0.5 + (1 - percentage / 100) / 2,
                                    pathColor: '#6200EA',
                                    trailColor: '#B388FF4D',
                                    strokeWidth: 20
                                })}
                            />
                        )}
                    </ChangingProgressProvider>
                </div>
                <span className="duplicate-board_toast__text--main">Restoring whiteboard</span>
            </span>, {
                icon: false,
                autoClose: false,
                className: 'wb_toast wb_toast__duplicate-board',
                draggable: false,
                toastId: `duplicate-loading-${board.id}`
            })
        try {
            const response = await restoreBoard(board.id)

            if (response?.success) {
                toast.dismiss(`duplicate-loading-${board.id}`);
                handleGetBoardListing();
                toast.info(
                    <span className="duplicate-board_toast__text">
                        <span className="duplicate-board_toast__text--main">Whiteboard restored successfully</span>
                        <span onClick={() => onBoardSelected(board.wbId)} className="duplicate-board_toast__button toast_btn">Open whiteboard</span>
                    </span>,
                    {
                        icon: false,
                        autoClose: false,
                        className: 'wb_toast wb_toast__duplicate-board',
                        draggable: false,
                        toastId: `duplicate-success-${board.id}`
                    })
            }
        }
        catch (err) {
            isError = true;
        }
        finally {
            if (isError) {
                toast.error('An error has occurred while restoring this board. Please try again later!', {
                    icon: getToastIcon('error'),
                    className: 'wb_toast wb_toast__duplicate-board',
                    toastId: `duplicate-error-${board.id}`
                });
            }
        }
    }

    const handleDeleteWhiteboard = async () => {
        await toast.dismiss();
        let isError = false;
        modalRef.current?.closeModal();
        try {
            const response = await deleteBoard(deletedBoardInfo.id)

            if (response?.success) {
                handleGetBoardListing();
                toast.success(`"${deletedBoardInfo.name}" deleted.`, {
                    icon: getToastIcon('success'),
                    className: 'wb_toast wb_toast__delete-board',
                    autoClose: true
                });
            }
        }
        catch (err) {
            isError = true;
        }
        finally {
            if (isError) {
                toast.error('An error has occurred while deleting this board. Please try again later!', {
                    icon: getToastIcon('error'),
                    className: 'wb_toast wb_toast__duplicate-board',
                    toastId: `delete-error-${deletedBoardInfo.id}`
                });
                setDeletedBoardInfo({});
            }
        }
    }

    const performSearch = (keyword) =>{
        const newBoards = boards.filter(board => board.name.toLowerCase().includes(keyword.toLowerCase().trim()));
        setCurrentBoards(newBoards);
    }

    return (
        <div className="whiteBoardContent">
            <div className="contentHeader" data-testid="whiteboardContent">
                <h3 />
                <div className="contentHeaderRight">
                    <SearchBoard performSearch = {performSearch}/>
                    <div className = "top-left-container">
                        <button className="addNewBoardBtn" data-testid="createNewBoard" onClick={createNewBoard} type="button">
                            <img src={AddIcon} />
                            <div className = "button title">New board</div>
                        </button>
                    </div>
                </div>  
            </div>
            <div className="contentArea">
                <h3 className="typeOfBoard" data-testid="pageTitle">{MENU_ITEMS[activePage]?.title || MENU_ITEMS.ALL_CANVASES.name}</h3>
                {
                    activePage === 'TRASH' ? <div className="info-text-wrapper">
                        <InfoCircle />
                        <span>Whiteboard from the trash will be permanently deleted after 90 days</span>
                    </div> : null
                }
                {['ALL_CANVASES', 'MY_CANVASES', 'SHARED_WITH_ME', 'TRASH'].includes(activePage) ? <div className="boardList">
                    {Array.isArray(currentBoards) ? currentBoards.map((board) => (
                        <BoardListingSingleContent
                            key={board.id}
                            board={board}
                            activePage={activePage}
                            onBoardSelected={onBoardSelected}
                            userName={userName}
                            handleDropdownItemClick={handleDropdownItemClick}
                            handleRestoreBoard={handleRestoreBoard}
                        />
                    )) : null}
                </div> : null}
            </div>
            <Modal
                ref={modalRef}
                title={`Are you sure you want to delete ${deletedBoardInfo?.name}?`}
                description={<span>This whiteboard will be moved to <span>Trash</span> section</span>}
                className="delete-board-modal"
                actions={[
                    <button
                        key="cancel"
                        type="button"
                        id="boardsCancelBtn"
                        onClick={() => {
                            modalRef?.current?.closeModal()
                            setTimeout(() => setDeletedBoardInfo({}), 300)
                        }}
                    >
                        Cancel
                    </button>,
                    <button key="delete" type="button" id="boardsDeleteBtn" onClick={handleDeleteWhiteboard}>
                        Delete
                    </button>,
                ]}
            />
        </div>
    )
}

BoardListingContent.propTypes = {
    activePage: PropTypes.oneOf(['ALL_CANVASES', 'MY_CANVASES', 'SHARED_WITH_ME', 'TRASH']),
    boards: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        wbId: PropTypes.string,
        name: PropTypes.string,
        ownerId: PropTypes.number,
        studio_build_card_id: PropTypes.string,
        enterpriseId: PropTypes.number,
        nimaWbId: PropTypes.string,
        createdByService: PropTypes.string,
        properties: PropTypes.any,
        publicSharePermission: PropTypes.any,
        publicShareKey: PropTypes.any,
        isDeleted: PropTypes.bool,
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
        users: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            info: PropTypes.shape({
                colorCode: PropTypes.string
            })
        })),
        pages: PropTypes.arrayOf(PropTypes.shape({
            wbPageId: PropTypes.string,
            pageName: PropTypes.string
        })),
        whiteboardActions: PropTypes.arrayOf(PropTypes.shape({
            whiteboardId: PropTypes.number,
            userId: PropTypes.number,
            updatedAt: PropTypes.string
        }))
    })),
    onBoardSelected: PropTypes.func.isRequired,
    createNewBoard: PropTypes.func.isRequired,
    userName: PropTypes.string,
    handleGetBoardListing: PropTypes.func.isRequired
}

BoardListingContent.defaultProps = {
    activePage: 'ALL_CANVASES',
    boards: []
}

export default BoardListingContent;