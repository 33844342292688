import { isObjectValid } from './FabricMethods';

/**
 * Returns an array of uuids in the order they are in the canvas.
 * @param canvas
 */
export const getStackOrderFromCanvas = (canvas) => {
    const orderStack = [];

    for (const object of canvas.getObjects().filter(o => o.uuid && typeof o.uuid === 'string')) {
        if (isObjectValid(object)) {
            orderStack.push(object.uuid);
        }
    }
    return orderStack;
}

/**
 * Returns object's index from stack list.
 * @param {fabric.Canvas} canvas 
 * @param {string} objUuid 
 * @param {[string]|undefined} orders 
 * @returns Number.
 */
export const getSingleObjectStackOrder = (canvas, objUuid, orders) => {
    try {
        const stackOrder = Array.isArray(orders) ? orders : getStackOrderFromCanvas(canvas);
        const idx = stackOrder.findIndex((uuid) => uuid === objUuid);
        return idx;
    } catch (error) {
        console.error('getSingleObjectStackOrder error: ', error);
    }
}

/**
 * Adjusting all objects' orders in canvas.
 * @param {fabric.Canvas} canvas 
 * @param {[string]} stackOrder 
 * @param {boolean} shouldFireEvent If true, object's onOrderChanged event is called.
 */
export const setObjectsStack = (canvas, stackOrder, shouldFireEvent = false) => {
    try {
        for (const [index, stackUuid] of Object.entries(stackOrder)) {
            const object = canvas.getObjects().find(obj => obj.uuid === stackUuid)
            if (object) {
                if (shouldFireEvent) {
                    object.onOrderChanged();
                }
                canvas.moveTo(object, index);
            }
        }
    } catch (error) {
        console.error('setObjectsStack error: ', error, stackOrder);
    }
}

/**
 * Adjusting all objects' orders in canvas with z indexes.
 * @param {fabric.Canvas} canvas
 */
export const setObjectsStackWithZIndex = (canvas) => {
    try {
        let objects = canvas.getObjects();
        
        let sortedObjects = objects.sort((a, b) => {
            if (a.zIndex > b.zIndex) {
                return 1;
            }
            if (a.zIndex < b.zIndex) {
                return -1;
            }
            return 0;
        });
        
        for (const sObject of sortedObjects) {
            const object = objects.find(obj => obj.zIndex === sObject.zIndex)
            if (object) {
                const sObjectIndex = sortedObjects.indexOf(sObject);
                canvas.moveTo(object, sObjectIndex);
                object.onOrderChanged();
            }
        }
    } catch (error) {
        console.error('setObjectsStack error: ', error);
    }
}

/**
 * Adjusting single object order in canvas.
 * @param {fabric.Canvas} canvas 
 * @param {fabric.Object} object 
 * @param {number} index 
 */
export const setSingleObjectStack = (canvas, object, index) => {
    try {
        canvas.moveTo(object, parseInt(index, 10));
    } catch (error) {
        console.error('setSingleObjectStack error: ', error);
    }
}

/**
 * Attaching stack order to object.
 * @param {fabric.Canvas} canvas 
 * @param {fabric.Object} obj 
 * @param {[string]} stackOrders 
 * @returns 
 */
export const attachStackOrderToObject = (canvas, obj, stackOrders) => {
    try {
        const stackOrder = getSingleObjectStackOrder(canvas, obj.uuid, stackOrders);
    
        if (parseInt(stackOrder, 10) > -1) {
            obj.stackOrder = parseInt(stackOrder, 10);
        }
    } catch (error) {
        console.error('attachStackOrderToObject error: ', error);
    }
}

/**
 * Its sorting objects from up to down
 * @param {[fabric.Object]} objects 
 * @returns {[fabric.Object]}
 */
export const sortObjectsByZIndexes = (objects) => {
    if (!Array.isArray(objects)) return objects;

    return objects.sort((a, b) => {
        if (a.zIndex > b.zIndex) return -1;
        if (a.zIndex < b.zIndex) return 1;
        return 0;
    });
}