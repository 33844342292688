import Pako from 'pako';

export const debounce = (
    fn,
    timeout
) => {
    let handle = 0;
    let lastArgs = null;
    const ret = (...args) => {
        lastArgs = args;
        clearTimeout(handle);
        handle = window.setTimeout(() => {
            lastArgs = null;
            fn(...args);
        }, timeout);
    };
    ret.flush = () => {
        clearTimeout(handle);
        if (lastArgs) {
            const _lastArgs = lastArgs;
            lastArgs = null;
            fn(..._lastArgs);
        }
    };
    ret.cancel = () => {
        lastArgs = null;
        clearTimeout(handle);
    };
    return ret;
};

// The difference from above debounce function is that we don't loose context (this) object in case using this fn.
/**
 * @param func
 * @param delay
 */
export function debounceWithContext(func, delay) {
    // A timer variable to track the delay period
    let timer;
    // Return a function that takes arguments
    return function (...args) {
    // Clear the previous timer if any
        clearTimeout(timer);
        // Set a new timer that will execute the function after the delay period
        timer = setTimeout(() => {
            // Apply the function with arguments
            func.apply(this, args);
        }, delay);
    };
}

/**
 * @param func
 * @param interval
 */
export function throttle(func, interval) {
    // A flag variable to track whether the function is running or not
    let isRunning = false;
    let result;

    // Return a function that takes arguments
    return function(...args) {
        // If the function is not running
        if (!isRunning) {
            // Set the flag to true
            isRunning = true;
            // Apply the function with arguments
            result = func.apply(this, args);
            // Set a timer that will reset the flag after the interval
            setTimeout(() => {
                // Set the flag to false
                isRunning = false;
            }, interval);

            return result;
        } else {
            return result;
        }
    };
}

export const divideListToChunks = (list, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < list.length; i += chunkSize) {
        chunks.push(list.slice(i, i + chunkSize));
    }
    return chunks;
}

export const compressData = (data) => {
    try {
        return Pako.deflate(JSON.stringify(data));
    } catch (e) {
        console.log(e);
        return null;
    }
}

export const decompressData = (data) => {
    try {
        return JSON.parse(Pako.inflate(data, { to: 'string' }));
    } catch (e) {
        console.log(e);
        return null;
    }
}


/**
 * @param uint8Array
 */
export function uint8ArrayToBase64(uint8Array) {
    let binaryString = '';
    const chunkSize = 1024;

    for (let i = 0; i < uint8Array.length; i += chunkSize) {
        const chunk = uint8Array.subarray(i, i + chunkSize);
        binaryString += String.fromCharCode.apply(null, chunk);
    }

    return window.btoa(binaryString);
}


/**
 * @param base64
 */
export function base64ToUint8Array(base64) {
    // Decode Base64 string to a binary string
    const binaryString = window.atob(base64);

    // Create a new Uint8Array with the same length as the binary string
    const bytes = new Uint8Array(binaryString.length);

    // Convert each character to a byte
    for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }

    return bytes;
}