import { fabric } from 'fabric';
import { advancedCenterToObjectWithAnimation } from '../FabricMethods';
import LoadingIndicator from './LoadingIndicator';


/**
 * @param canvas
 * @param zoom
 */
function getCenterCoord(canvas, zoom) {
    return {
        x: fabric.util.invertTransform(canvas.viewportTransform)[4] + (canvas.width / zoom) / 2,
        y: fabric.util.invertTransform(canvas.viewportTransform)[5] + (canvas.height / zoom) / 2
    }
}


const handleImagePosition = (images, options, {
    index,
    MAX_IMAGE_ON_ROW, 
    addedLoadedImages, 
    GAP_BETWEEN_IMAGE, 
    biggestImageInRow, 
    rowNumber, 
    center
}) => {
    if (images.length > 1) {
        if (index > 0 && index % MAX_IMAGE_ON_ROW !== 0) {
            const lastImage = addedLoadedImages[addedLoadedImages.length - 1];
            options.left = lastImage.left + lastImage.width + GAP_BETWEEN_IMAGE;
            options.top = lastImage.top;
        } else if (index % MAX_IMAGE_ON_ROW === 0 && rowNumber > 0) {
            const lastRow = biggestImageInRow[rowNumber - 1];
            options.left = center.x;
            options.top = lastRow.top + lastRow.getScaledHeight() + GAP_BETWEEN_IMAGE;
        }
    }
}

/**
 * Creates loading images.
 * @param {fabric.Canvas} canvas 
 * @param {File[]} images - Image list.
 * @param {object} uploadingOptions - Options.
 * @param {boolean} uploadingOptions.useMousePointer - Use mouse pointer as the center of the loading images.
 * @param {string} uploadingOptions.processId - Process id to identify the uploading process.
 * @returns
 */
export const addLoadingMockImages = async (canvas, images, uploadingOptions = {}) => {
    if (!images.length) return;
    const center = getCenterCoord(canvas, canvas.getZoom());
    if (uploadingOptions.useMousePointer && canvas.mousePosition) {
        center.x = canvas.mousePosition.x;
        center.y = canvas.mousePosition.y;
    }
    const addedLoadedImages = [];

    // max number of images on a row
    const MAX_IMAGE_ON_ROW = 10;
    const biggestImageInRow = {}
    const GAP_BETWEEN_IMAGE = 160;

    const options = {
        left: center.x,
        top: center.y,
        processId: uploadingOptions.processId,
    }
    for (const [index, image] of Object.entries(images)) {
        options.imageUuid = image.uuid;
        options.rowUuid = image.rowUuid;
        options.index = image.imageIndex;
    
        // get this row number
        const rowNumber = Math.floor(index / MAX_IMAGE_ON_ROW);
    
        // add image to the right of the previous image
        handleImagePosition(images, options, {
            index,
            MAX_IMAGE_ON_ROW,
            addedLoadedImages,
            GAP_BETWEEN_IMAGE,
            biggestImageInRow,
            rowNumber,
            center
        }) 

        const addedLoadedImage = await createLoadingImage(image.image, canvas, options);
        addedLoadedImages.push(addedLoadedImage);

        const thisRow = biggestImageInRow[rowNumber];
        if (thisRow) {
            biggestImageInRow[rowNumber] = thisRow.getScaledHeight() > addedLoadedImage.getScaledHeight() ? thisRow : addedLoadedImage;
        } else {
            biggestImageInRow[rowNumber] = addedLoadedImage;
        }
    }

    canvas.discardActiveObject();
    const loadingImagesGroup = new fabric.ActiveSelection(addedLoadedImages, {
        canvas,
        hasControls: false,
        lockMovementX: true,
        lockMovementY: true,
        lockRotation: true,
        lockScalingX: true,
        lockScalingY: true,
    });
    loadingImagesGroup.hideSubtoolBar = true;
    loadingImagesGroup.shapeType = 'loadingMockImagesGroup';
    canvas.setActiveObject(loadingImagesGroup);
    canvas.requestRenderAll();
    advancedCenterToObjectWithAnimation(canvas, loadingImagesGroup)

    const intervalID = setInterval(() => {
        fabric.util.animate({
            startValue: 0,
            endValue: 360,
            duration: 1000,
            onChange: (value) => {
                for (const addedLoadedImage of addedLoadedImages) {
                    addedLoadedImage.item(2).set({ angle: value });
                }

                if (!canvas || !canvas.contextContainer) {
                    clearInterval(intervalID);
                    return;
                }

                canvas.renderAll();
            },
        });
    }, 1000);
}

export const createLoadingImage = async (image, canvas, options) => {
    const localImg = await addImageAsync(image, options);
    const rect = new fabric.Rect({
        fill: 'rgba(0,0,0,0.5)',
        width: localImg.getScaledWidth(),
        height: localImg.getScaledHeight(),
        left: localImg.left,
        top: localImg.top,
    });
    const indicator = new LoadingIndicator({
        left: rect.left + rect.getScaledWidth() / 2,
        top: rect.top + rect.getScaledHeight() / 2,
    });

    const group = new fabric.Group([
        localImg,
        rect,
        indicator
    ], {
        hasControls: false
    });


    group.shapeType = 'loadingMockImage';
    group.imageUuid = options.imageUuid;
    group.rowUuid = options.rowUuid;
    group.mockIndex = parseInt(options.index);  // we find this mock object by index
    group.processId = options.processId;
    group.isDynamic = true;
    canvas.add(group);
    canvas.renderAll();
    return group;
}


const addImageAsync = async (image, options) => {
    return new Promise((resolve) => {
        fabric.Image.fromURL(image, (img) => {
            img.left = options.left;
            img.top = options.top;
            resolve(img);
        }, { crossOrigin: 'anonymous' })
    });
}