/**
 *
 */
const IconMenuArrowPosition = () => {
    return (
        <svg
            fill="none"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20 19h-4a3 3 0 01-3-3v-4"
                stroke="#3C3E49"
                strokeLinecap="round"
            />
            <path
                d="M6.177 6.887L4.142 5.271A.349.349 0 014 4.999c0-.102.052-.2.142-.27L6.177 3.11a.58.58 0 01.683 0c.187.149.187.394 0 .543L5.166 4.999 6.86 6.345c.187.148.187.394 0 .542a.58.58 0 01-.683 0zM18.823 17.113l2.035 1.616c.09.072.142.17.142.271 0 .103-.052.2-.142.272l-2.035 1.617a.58.58 0 01-.683 0c-.187-.149-.187-.394 0-.543L19.834 19l-1.694-1.345c-.187-.149-.187-.394 0-.543a.58.58 0 01.683 0z"
                fill="#3C3E49"
            />
            <path
                d="M5 5h5a3 3 0 013 3v4"
                stroke="#3C3E49"
                strokeLinecap="round"
            />
        </svg>
    )
}

export default IconMenuArrowPosition;