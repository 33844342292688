import {generateKeyBetween} from 'fractional-indexing';

/**
 * changes z index of an item within it's type
 * @param {fabric.Canvas} canvas
 * @param {string} value
 * @param {array} items
 */
export const changeZIndex = (canvas, value, items, history) => {
    let objects = history?.filter(item => item.isDeleted).map(item => {
        return { ...item.properties }
    }) || [];
    const objectUuids = items.map(item => {
        if(item.type !== 'frame') return item.uuid
    });
    //remove the objects that we are changing
    let activeObjects = canvas.getObjects().filter(o => !objectUuids.includes(o.uuid));
    // let activeObjects = canvas.getObjects();
    objects = [...objects, ...activeObjects].filter(o => o.uuid);

    //return that to biggest frame index
    const lastFrameIndex = objects.filter(o => o.type === 'frame').sort((a, b) => {
        if (a.zIndex > b.zIndex) {
            return 1;
        }
        if (a.zIndex < b.zIndex) {
            return -1;
        }
        return 0;
    }).slice(-1)[0]?.zIndex ?? null;

    let firstShapeIndex = objects.filter(o => o.type !== 'frame').sort((a, b) => {
        if (a.zIndex > b.zIndex) {
            return 1;
        }
        if (a.zIndex < b.zIndex) {
            return -1;
        }
        return 0;
    })[0]?.zIndex ?? null;

    let lastShapeIndex = objects.filter(o => (o.type !== 'frame' && o.type !== 'comment')).sort((a, b) => {
        if (a.zIndex > b.zIndex) {
            return 1;
        }
        if (a.zIndex < b.zIndex) {
            return -1;
        }
        return 0;
    }).slice(-1)[0]?.zIndex ?? null;

    let firstIndex = value === 'front' ? lastShapeIndex : lastFrameIndex
    let lastIndex = value === 'front' ? null : firstShapeIndex;

    const sortedItems = items.sort((a, b) => {
        if (a.zIndex > b.zIndex) {
            return value === 'front' ? 1 : -1;
        }
        if (a.zIndex < b.zIndex) {
            return value === 'front' ? -1 : 1;
        }
        return 0;
    })

    for (const object of sortedItems) {
        let generatedZIndex = generateKeyBetween(firstIndex, lastIndex)
        if (object.shapeType !== 'frame') object.zIndex = generatedZIndex;

        if (value !== 'front') lastIndex = generatedZIndex;
        else firstIndex = generatedZIndex;
    }
}

/**
 * finds biggest z index on history including deleted objects
 * @param {fabric.Canvas} canvas
 * @param {array} history
 * @param {string} shapeType
 * @returns String || null.
 */
export const findBiggestZIndexOnHistory = (canvas, shapeType, history) => {
    let objects = history || [];
    let activeObjects = canvas.getObjects().filter(item => !item.isDynamic);
    objects = [...objects, ...activeObjects].filter(item => item.uuid);
    
    let frames = objects.filter(item => item.shapeType === 'frame' && !item.isDynamic) || [];
    let otherShapesExceptFrames = objects.filter(item => item.shapeType !== 'frame' && !item.isDynamic) || [];
    
    let starterIndex = null;
    let enderIndex = null;
    
    if((shapeType === 'frame' && frames.length > 0)) {
        starterIndex = frames[0].zIndex;
    }
    else if(shapeType !== 'frame'){
        //if object have same type of objects on canvas get the first object on canvas as reference
        if(otherShapesExceptFrames.length > 0) starterIndex = otherShapesExceptFrames[0].zIndex;
        //if shape is not frame and have no shapes of same kind on whole canvas then get the latest frame as reference
        else if(frames.length > 0 && otherShapesExceptFrames.length === 0) starterIndex = frames.slice(-1)[0]?.zIndex;
    }

    //searching for latest zIndex on object's own kind, that will be our starting index when generating a new one
    if(starterIndex !== null){
        const _objects = shapeType === 'frame' ? frames : otherShapesExceptFrames;
        //need to change this logic. we can't check if object's belong to its own kind or not. If we have group object this is creating issue
        _objects.forEach(item => {
            if(item?.zIndex > starterIndex) starterIndex = item?.zIndex;
        })
    }
    
    //for frames second option cannot be null
    //need to spot second option for every scenario
    if(shapeType === 'frame' && otherShapesExceptFrames.length > 0) {
        let smallestZIndex = otherShapesExceptFrames[0].zIndex;
        otherShapesExceptFrames.forEach(item => {
            if(item?.zIndex < smallestZIndex) smallestZIndex = item?.zIndex;
        })
        enderIndex = smallestZIndex;
    }
    
    let generatedZIndex;
    
    //need to check duplicate zIndex - for old boards
    const checkDuplicated = checkIsZIndexDuplicated(objects, generatedZIndex);
    if(checkDuplicated.isDuplicated){
        generatedZIndex = generateKeyBetween(checkDuplicated.biggestZIndex, null);
    }
    //starterIndex cannot be greater than or equals to ender index
    if(starterIndex >= enderIndex) generatedZIndex = generateKeyBetween(checkDuplicated.biggestZIndex, null);
    else generatedZIndex = generateKeyBetween(starterIndex , enderIndex);
    
    return generatedZIndex;
}

/**
 * checks if there is duplicated z index among the all objects
 * @param {array} canvasObjects
 * @param {string} generatedZIndex
 */
export const checkIsZIndexDuplicated = (canvasObjects, generatedZIndex) => {
    let isDuplicated = false;
    let biggestZIndex = null;

    let allZIndexes = canvasObjects.map((item) => {
        return item.zIndex
    })

    isDuplicated = allZIndexes.includes(generatedZIndex);
    biggestZIndex = allZIndexes.sort((a, b) => {
        if (a.zIndex > b.zIndex) {
            return -1;
        }
        if (a.zIndex < b.zIndex) {
            return 1;
        }
        return 0;
    }).slice(-1)[0];

    return {isDuplicated, biggestZIndex}
}