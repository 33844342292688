import { useEffect, useRef } from 'react'

/**
 * Hook for automatically scrolling on new message.
 * @param listRef
 * @param replies
 */
const useScrollOnMessage = (listRef, replies) => {
    const previousMessages = useRef();

    useEffect(() => {
        const scrollToBottom = () => {
            if (listRef?.current)
                listRef.current.scrollTop = listRef?.current?.scrollHeight;
        }

        if ((!previousMessages.current) || (Array.isArray(replies) && replies.length > previousMessages.current.length)) scrollToBottom();
        previousMessages.current = replies;
    }, [replies, listRef])
}


export default useScrollOnMessage;