/**
 * Transform itemType and actionTaken to readable string.
 * @param itemType
 * @param actionTaken
 * @param itemText
 * @param userName
 */
const transformItemText = (itemType, actionTaken, itemText, userName) => {
    if(actionTaken === 'whiteboardDuplicate')
        return `${userName} created the board using ${itemText}`
    if((itemType === 'frame' || actionTaken !== 'deleted'))
        return itemText
}

export default transformItemText;