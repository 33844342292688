import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import DrawerSearchIcon from '../../../assets/images/search-drawer.svg'
import ChevronDownIcon from '../../../assets/images/chevron-down.svg'
import ChevronUpIcon from '../../../assets/images/chevron-up.svg'
import SearchCloseIcon from '../../../assets/images/search-close.svg'
import clsx from 'clsx';
import PropTypes from 'prop-types';

import './SearchInput.scss';

/**
 * SearchInput component.
 */
const SearchInput = forwardRef(({
    placeholder,
    onSearchClicked,
    changeIsSearchEnabled,
    handleNavigateBetweenMarks,
    currentMarkIndex,
    setCurrentMarkIndex,
    totalMarks,
    drawerSearch
}, ref) => {
    const [value, setValue] = useState('');
    const [isSearchEnabled, setIsSearchEnabled] = useState(false);
    const [isMovingAroundMarkedOnes, setIsMovingAroundMarkedOnes] = useState(false);

    const markRef = useRef();

    const handleSearch = (isSearched) => {
        setCurrentMarkIndex && setCurrentMarkIndex(0);
        const val = value.trim();
        if (!val) return;
        onSearchClicked(val, isSearched);
        changeIsSearchEnabled(true);
        setIsSearchEnabled(true);
    }

    useImperativeHandle(ref, () => ({
        resetValue() {
            setValue('');
            changeIsSearchEnabled(false);
            setIsSearchEnabled(false);
        }
    }), [setValue]);

    const handleResetSearch = () => {
        setValue('');
        onSearchClicked('', { searchEmpty: true })
        changeIsSearchEnabled(false);
        setIsSearchEnabled(false);
        setCurrentMarkIndex(0);
    }

    return (
        <div className="searchInput--wrapper">
            <button
                type="button"
                aria-label="search" 
                className="searchInput__btn"
                onClick={() => {
                    const isSearched = document.querySelector('.searchInput__clearBtn');
                    if (value !== '') {
                        setIsMovingAroundMarkedOnes(true);
                        handleSearch()
                    }
                    if (value === '' && isSearched !== null) handleResetSearch();
                }}
                title="Search">
                {
                    placeholder === 'Search comments' ? <img src={DrawerSearchIcon} alt="Search" /> :
                    <em className="icon-search-normal" aria-hidden></em>
                }
            </button>
            <input 
                style={{ paddingRight: (isSearchEnabled) ? `${markRef?.current?.clientWidth + 33}px` : '10px' }}
                className={clsx('searchInput__input', { searchActive: isSearchEnabled, markActive: isSearchEnabled && totalMarks})}
                onChange={(e) => {
                    setValue(e.target.value);
                    setIsMovingAroundMarkedOnes(false);
                }}
                onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                        e.target.setSelectionRange(e.target.value.length, e.target.value.length, 0);
                        const isSearched = document.querySelector('.searchInput__clearBtn');
                        if (value === '' && isSearched !== null) handleResetSearch();
                        if ((!isMovingAroundMarkedOnes && value !== '')) {
                            setIsMovingAroundMarkedOnes(true);
                            handleSearch(isSearched !== null);
                        }
                        else if (isMovingAroundMarkedOnes && totalMarks !== 0) {
                            if (e.shiftKey) handleNavigateBetweenMarks('up')
                            else handleNavigateBetweenMarks('down')
                        }
                    }
                }}
                placeholder={placeholder}
                value={value} />
            {
                isSearchEnabled && drawerSearch ? <div ref={markRef} className="searchInput__markActions">
                    <div className="searchInput__markActions--count">{(currentMarkIndex + (totalMarks === 0 ? 0 : + 1)) + '/' + (totalMarks)}</div>
                    <button type="button" disabled={totalMarks === 0} onClick={() => handleNavigateBetweenMarks('up')}><img src={ChevronUpIcon} alt="Up" /></button>
                    <button type="button" disabled={totalMarks === 0} onClick={() => handleNavigateBetweenMarks('down')}><img src={ChevronDownIcon} alt="Up" /></button>
                </div> : null
            }
            {
                isSearchEnabled ? <button
                    type="button"
                    aria-label="Clear search" 
                    className="searchInput__clearBtn"
                    onClick={handleResetSearch}
                    title="Clear search"
                >
                    {
                        drawerSearch ? <img src={SearchCloseIcon} alt="Close" /> : <em className="icon-close-circle" aria-hidden></em>
                    }
                </button> : null
            }
        </div>
    )
});

SearchInput.displayName = 'SearchInput';

SearchInput.propTypes = {
    placeholder: PropTypes.string,
    onSearchClicked: PropTypes.func.isRequired,
    changeIsSearchEnabled: PropTypes.func.isRequired,
    handleNavigateBetweenMarks: PropTypes.func,
    currentMarkIndex: PropTypes.number,
    setCurrentMarkIndex: PropTypes.func,
    totalMarks: PropTypes.number,
    drawerSearch: PropTypes.bool
}

export default SearchInput;