
export const classNames = (classes) => {
    if (!classes || typeof classes !== 'object') { return classes; }

    let className = '';
    
    Object.entries(classes).forEach(([key, value]) => {
        if (value === true) {
            className += `${key} `;
        }
    });

    return className;
}