import {fabric} from 'fabric';

export default function _collectObjects(e) {
    var group = [],
        currentObject,
        x1 = this._groupSelector.ex,
        y1 = this._groupSelector.ey,
        x2 = x1 + this._groupSelector.left,
        y2 = y1 + this._groupSelector.top,
        selectionX1Y1 = new fabric.Point(Math.min(x1, x2), Math.min(y1, y2)),
        selectionX2Y2 = new fabric.Point(Math.max(x1, x2), Math.max(y1, y2)),
        allowIntersect = !this.selectionFullyContained,
        isClick = x1 === x2 && y1 === y2;
    // we iterate reverse order to collect top first in case of click.
    for (var i = this._objects.length; i--; ) {
        currentObject = this._objects[i];

        if (!currentObject || !currentObject.selectable || !currentObject.visible || currentObject.collabLocked) {
            continue;
        }

        if ((allowIntersect && currentObject.intersectsWithRect(selectionX1Y1, selectionX2Y2, true)) ||
            currentObject.isContainedWithinRect(selectionX1Y1, selectionX2Y2, true) ||
            (allowIntersect && currentObject.containsPoint(selectionX1Y1, null, true)) ||
            (allowIntersect && currentObject.containsPoint(selectionX2Y2, null, true))
        ) {
            group.push(currentObject);
            // only add one object if it's a click
            if (isClick) {
                break;
            }
        }
    }

    if (group.length > 1) {
        group = group.filter(function(object) {
            return !object.onSelect({ e: e });
        });
    }

    return group;
}