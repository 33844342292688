import React, {useEffect, useRef} from 'react';
import Modal from '../modal/Modal';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
export default function ThirdPartyMigrationModal() {
    const modalRef = useRef();

    useEffect(() => {
        modalRef?.current?.openModal()
    }, []);

    return (
        <Modal
            ref={modalRef}
            description={(
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: '20px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <LoadingSpinner />
                    </div>
                    <p style={{ textAlign: 'center' }}>
                        Please wait. We are importing data from your old whiteboard. It will be added to new page with name <strong>Old Whiteboard Data</strong>
                    </p>
                </div>
            )}
            disableClosing
        />
            
    )
}