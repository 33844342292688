import React, { useCallback, useEffect, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import eventEmitter from '../../helpers/EventEmitter';

import './ShortcutList.scss';
import { EMITTER_TYPES, MODAL_WINDOWS } from '../../helpers/Constant';
import { useDispatch } from 'react-redux';
import IconClose from '../svgIcons/canvasRedesign/IconClose';
import useOutsidePopupClick from '../../hooks/UseOutsidePopupClick';

const ShortcutsList = ({ activeWindowState }) => {
    const shortcutListRef = useRef();
    const dispatch = useDispatch();

    const closeShortcutList = useCallback(() => {
        dispatch({
            type: 'modal/toggleShortcutList',
            payload: {
                shouldShow: false
            }
        })  
    }, [dispatch]);

    useOutsidePopupClick(shortcutListRef, activeWindowState, MODAL_WINDOWS.SHORTCUT_LIST, closeShortcutList);

    useEffect(() => {
        if (eventEmitter) {
            const handleToggleShortcutList = (val) => {
                if (val) {
                    dispatch({
                        type: 'modal/toggleShortcutList',
                        payload: {
                            shouldShow: true
                        }
                    }) 
                } else {
                    dispatch({
                        type: 'modal/toggleShortcutList',
                        payload: {
                            shouldShow: false
                        }
                    })
                }
            }
            eventEmitter.on(EMITTER_TYPES.TOGGLE_SHORTCUT_LIST, handleToggleShortcutList);
            return () => {
                eventEmitter.off(EMITTER_TYPES.TOGGLE_SHORTCUT_LIST, handleToggleShortcutList);
            }
        }
    }, [closeShortcutList, dispatch])

    const showShortCut = activeWindowState === MODAL_WINDOWS.SHORTCUT_LIST

    return (
        <div  
            className={clsx('shortcutList', { active: showShortCut })}
            ref={shortcutListRef}>
            <div className="shortcutList__header">
                <h2 className='shortcutList__header--title'>Shortcuts</h2>
                <button
                    type="button"
                    className="shortcutList__header--closeBtn"
                    onClick={() => closeShortcutList()}>
                    <IconClose />
                </button>
            </div>
            <div className='shortcutList__seperator' />
            <div className='shortcutList__list'>
                <div className='shortcutList__content'>
                    <div className='shortcutList__content--item'>
                        <ul>
                            <li>CTRL/Cmd + C : Copy</li>
                            <li>CTRL/Cmd + V : Paste</li>
                            <li>CTRL/Cmd + X : Cut	</li>
                            <li>CTRL/Cmd + D : Duplicate</li>
                            <li>CTRL/Cmd + Z : Undo	</li>
                            <li>CTRL/Cmd + Y : Redo	</li>
                            <li>CTRL/Cmd + F : Search</li>
                            <li>CTRL/Cmd + A : Select all within the Frame</li>
                            <li>CTRL/Cmd + B : Bold	</li>
                            <li>CTRL/Cmd + U : Underline</li>
                            <li>CTRL/Cmd + I : Italics</li>
                            <li>CTRL/Cmd + 1 : Fit to Screen.</li>
                            <li>E : Eraser</li>
                            <li>L : Lasso</li>
                            <li>H : Pan</li>
                            <li>V : Select</li>
                            <li>P : Pen	</li>
                            <li>M : Mini map</li>
                            <li>CTRL/Cmd + L : Laser Pointer</li>
                            <li>N : Sticky Note</li>
                            <li>R : Rectangle</li>
                            <li>C : Comment</li>
                            <li>T : Text</li>
                            <li>Highlight element, hold Alt/Option key and drag to duplicate.</li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

ShortcutsList.propTypes = {
    activeWindowState: PropTypes.string 
}

export default ShortcutsList;