import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import IconClose from '../../svgIcons/IconClose';
import { FLOWCHART_MODAL_TEXTS, FLOWCHART_MODAL_TYPES, FLOWCHART_STATUS } from '../../../helpers/Constant';
import { generateFlowChart, handleRemoveFlowchartItemsInCanvas } from '../../../helpers/flowchart/FlowchartUtils';
import { useEffect, useRef, useState } from 'react';
import './FlowchartModal.scss'
import { isUserHasAccessToFeature } from '../../../helpers/CommonFunctions';
import { toast } from 'react-toastify';
import getToastIcon from '../../../helpers/media/GetToastIcon';
import { isMainPage } from '../../../helpers/pages/CommonPagesMethods';
import LoadingSpinner from '../../loadingSpinner/LoadingSpinner';


const FlowChartModal = ({
    canvas,
    userAccessRef,
    socketRef
}) => {
    const flowchartModalProps = useSelector(state => state?.modal?.flowchartModal);
    const { status: flowchartStatus } = useSelector(state => state?.board?.flowchartData);
    const userId = useSelector(state => state?.user.id);
    const activePageId = useSelector(state => state?.rightDrawer?.activePage?.id);
    const pages = useSelector(state => state?.rightDrawer?.pages);
    const buildCardID = useSelector(state => state?.board?.studioBuildCardID);

    const [isFlowchartImporting, setIsFlowchartImporting] = useState(false);
    const dispatch = useDispatch();
    const importAbortController = useRef(new AbortController());
    const undoToastId = useRef(null);

    const handleModalClose = (forceClosing = true) => {
        if (!forceClosing && flowchartStatus === FLOWCHART_STATUS.IN_PROGRESS) {
            return
        }
        importAbortController.current.abort();
        dispatch({
            type: 'modal/toggleFlowchartModal',
            payload: {
                shouldShow: false
            }
        })
    }

    const handleFlowchartStatusChange = (status) => {
        if (!Object.values(FLOWCHART_STATUS).includes(status)) {
            console.error('Invalid flowchart status');
            return;
        }

        socketRef.current.emit('ufd_status', {
            status,
        });

        dispatch({
            type: 'board/changeBoardDetails',
            payload: {
                userFlowDiagram_status: status,
            }
        });

        dispatch({
            type: 'board/setFlowchartModalDetails',
            payload: {
                disableCancelInLoading: false
            }
        });
    }

    const handleConfirmBtnClick = async () => {
        if (modalType === FLOWCHART_MODAL_TYPES.REMOVE) {
            if (!isUserHasAccessToFeature('remove_flowchart', userAccessRef.current)) {
                toast.error('You are not authorized to perform removing flowcharts', {
                    icon: getToastIcon('error'),
                    className: 'wb_toast',
                });
                handleModalClose();
                return;
            }
            handleRemoveFlowchartItemsInCanvas(canvas);
            handleFlowchartStatusChange(FLOWCHART_STATUS.REMOVED);
            handleModalClose();
            undoToastId.current = toast.info(<span className="flowchart-toast-msg">User flows removed <button type="button" className="flowchart_toast_action_btn">Undo</button></span>, {
                position: toast.POSITION.BOTTOM_CENTER,
                icon: false,
                autoClose: 3000,
                className: 'flowchart-toast removed wb_toast',
                onClick: (e) => {
                    if (e?.target?.tagName === 'BUTTON' && e?.target?.className?.includes('flowchart_toast_action_btn')) {
                        if (!isMainPage(pages, activePageId)) return;  // if not main page, then do nothing
                        if (undoToastId.current) {
                            toast.dismiss(undoToastId.current);
                        }
                        dispatch({
                            type: 'modal/setFlowchartModalDetails',
                            payload: {
                                showModal: true,
                                modalType: FLOWCHART_MODAL_TYPES.IMPORT
                            }
                        })
                    }
                }
            });
            return;
        }
        if (!isUserHasAccessToFeature('import_flowchart', userAccessRef.current)) {
            toast.error('You are not authorized to perform importing flowcharts', {
                icon: getToastIcon('error'),
                className: 'wb_toast',
            });
            handleModalClose();
            return;
        }
        if (!buildCardID) {
            handleModalClose();
            return;
        }

        setIsFlowchartImporting(true);
        handleFlowchartStatusChange(FLOWCHART_STATUS.IN_PROGRESS);

        try {
            const isGenerated = await generateFlowChart(canvas, buildCardID, { userId, activePageId });
            if (isGenerated) {
                handleFlowchartStatusChange(FLOWCHART_STATUS.COMPLETED);
                toast.success('User flows imported', {
                    position: toast.POSITION.BOTTOM_CENTER,
                    icon: getToastIcon('flowchart-success'),
                    autoClose: 3000,
                    className: 'flowchart-toast success',
                });
            } else {
                handleFlowchartStatusChange(FLOWCHART_STATUS.PENDING);
                toast.error('Error while importing user flows', {
                    icon: getToastIcon('error'),
                    className: 'wb_toast',
                    autoClose: 2000
                })
            }
        } catch (err) {
            // if the fetching flowchart data is failed, then we need to set the status to pending
            handleFlowchartStatusChange(FLOWCHART_STATUS.PENDING);
            console.error('Error while generating flowchart', err);
            toast.error('Error while importing user flows', {
                icon: getToastIcon('error'),
                className: 'wb_toast',
            })
        } finally {
            dispatch({
                type: 'modal/setFlowchartModalDetails',
                payload: {
                    showModal: false,
                    disableCancelInLoading: false
                }
            });
            setIsFlowchartImporting(false);
        }
    }

    useEffect(() => {
        const exitHandler = (e) => {
            // show confirmation message only when importing flowchart
            if (isFlowchartImporting) {
                const confirmationMessage = '\\o/';

                (e || window.event).returnValue = confirmationMessage;
              
                return confirmationMessage;
            }
            return null;
        }
        window.addEventListener('beforeunload', exitHandler);
        return () => {
            window.removeEventListener('beforeunload', exitHandler);
        }
    }, [isFlowchartImporting])

    if (!flowchartModalProps || !flowchartModalProps?.showModal) return null;

    let modalType = '';

    if (flowchartStatus === FLOWCHART_STATUS.IN_PROGRESS) {
        modalType = FLOWCHART_MODAL_TYPES.LOADING;
    } else {
        modalType = flowchartModalProps?.modalType;
    }


    if (!modalType || (
        modalType !== FLOWCHART_MODAL_TYPES.IMPORT &&
            modalType !== FLOWCHART_MODAL_TYPES.REMOVE &&
            modalType !== FLOWCHART_MODAL_TYPES.LOADING
    )
    ) return null;

    if (!isMainPage(pages, activePageId)) {
        return null;
    }
    return (
        <>
            <div className='flowchart--overlay' data-testid='flowchart-overlay' onClick={() => handleModalClose(false)} />
            {
                (modalType === FLOWCHART_MODAL_TYPES.IMPORT || modalType === FLOWCHART_MODAL_TYPES.REMOVE) ? <div className='flowchart--modal'>
                    <div className='flowchart--modal__header'>
                        <h2 data-testid='flowchart-title'>
                            {FLOWCHART_MODAL_TEXTS[modalType].title}
                        </h2>
                        <button
                            type="button"
                            aria-label="Close flowchart modal"
                            className="flowchart--modal__btn flowchart_closeBtn"
                            data-testid='flowchart-closeBtn'
                            onClick={handleModalClose}
                        >
                            <IconClose />
                        </button>
                    </div>
                    <p className="flowchart--modal__info" data-testid='flowchart-info'>
                        {FLOWCHART_MODAL_TEXTS[modalType].info}
                    </p>
                    <div className="flowchart--modal__footer">
                        <button
                            type="button"
                            className="flowchart--modal__btn mainBtn"
                            data-testid='flowchart-cancelBtn'
                            onClick={handleModalClose}>
                            {FLOWCHART_MODAL_TEXTS[modalType].cancelBtn}
                        </button>
                        <button
                            type="button"
                            className={clsx(
                                'flowchart--modal__btn mainBtn',
                                `${modalType}Btn`
                            )}
                            data-testid='flowchart-confirmBtn'
                            onClick={handleConfirmBtnClick}>
                            {FLOWCHART_MODAL_TEXTS[modalType].confirmBtn}
                        </button>
                    </div>
                </div> : null
            }
            {
                modalType === FLOWCHART_MODAL_TYPES.LOADING ? <div className='flowchart--modal loadingState'>
                    <div className='flowchart--modal__loader'>
                        <div className='flowchart--modal__loader__spinner' data-testid='flowchart-loader-spinner'>
                            <LoadingSpinner />
                        </div>
                        <p className="flowchart--modal__info" data-testid='flowchart-loader-info'>
                            {FLOWCHART_MODAL_TEXTS[modalType].info}
                        </p>
                    </div>
                    {/* {
                            flowchartModalProps?.disableCancelInLoading ? null : (
                                <button 
                                    className="flowchart--modal__btn flowchart_closeBtn"
                                    aria-label="Close flowchart modal"
                                    data-testid='flowchart-closeBtn'
                                    onClick={() => handleModalClose()}
                                    >
                                    <IconClose />
                                </button>
                            )
                        } */}
                </div> : null
            }
        </>
    )
}

FlowChartModal.propTypes = {
    canvas: PropTypes.object,
    userAccessRef: PropTypes.shape({
        current: PropTypes.oneOf(['view', 'comment', 'edit', 'removeAccess', 'NOT_ALLOWED'])
    }),
    socketRef: PropTypes.shape({
        current: PropTypes.object
    })
}

export default FlowChartModal;