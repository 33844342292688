/**
 * If new line is missing, return 1, else return 0.
 * @override
 * @override
 * @param {number} lineIndex - Line that will be checked for missing new line.
 * @returns {number} 0 if new line is not missing, 1 if new line is missing.
 * @memberOf fabric.Textbox
 */
export default function newLineMissing(lineIndex) {
    try {
        return this.lineIndices[lineIndex] ? 0 : 1;
    } catch (e) {
        return 0;
    }
}