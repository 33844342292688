/**
 *
 */
const IconLineTypeCurved = () => {
    return (
        <svg
            fill="none"
            height="18"
            viewBox="0 0 19 18"
            width="19"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.5 3.031s3.657-2.624 6 .5c3 4-4.5 6.5-1.5 11 1.95 2.925 6.5.5 6.5.5"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeWidth="0.9"
            />
        </svg>
    )
}

export default IconLineTypeCurved;