import { createObjectToBeEmitted } from '../CommonFunctions';
import { customToObject } from '../FabricMethods';
import {v4} from 'uuid';

/**
 * Creates a log object from an event.
 * @param {"created"|"modified"|"deleted"} event 
 * @param {fabric.Object} context 
 * @param users
 * @param whiteBoardId
 * @param userId
 * @returns
 */
const createLogFromEvent = (event, context, users, whiteBoardId, userId) => {
    const thisDate = new Date().toISOString();
    return {
        actionTaken: event,
        createdAt: thisDate,
        updatedAt: thisDate,
        activityTime: thisDate,
        itemType: context.shapeType,
        shape: createObjectToBeEmitted(
            whiteBoardId,
            userId,
            customToObject(context),
            event === 'deleted',
            context.shapeType
        ),
        user: users.find(user => user.id === context.modifiedBy),
        userId: context.modifiedBy,
        whiteBoardId
    }
}

export const createLogForThirdPartyImport = ({time, whiteBoardId, userId, users}) => {
    return {
        id: v4(),
        whiteboardId: whiteBoardId,
        actionTaken: 'import',
        itemType: 'nima',
        createdAt: time,
        activityTime: time,
        shape: null,
        user: users.find(user => user.id === userId),
        userId: userId,
    }
}

export default createLogFromEvent;