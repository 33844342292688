import { SHAPE_DEFAULTS_AS_SHAPE_PROPS, modes } from '../helpers/Constant';
import { removeCanvasListener, stopDrawing } from '../helpers/CommonFunctions';
import store from '../redux/Store';

import { Parallelogram } from '../customClasses/shapes/Parallelogram';

let drawInstance = null,
    mouseDown = false,
    origX,
    origY;

export const createParallelogram = (
    canvas,
    emitOnMouseDown,
    onMouseDownLineHandler,
    handleChangeSelectMode
) => {
    store.dispatch({
        type: 'board/changeCurrentMode',
        payload: modes.PARALLELOGRAM,
    });

    removeCanvasListener(canvas);
    canvas.toggleDragMode(canvas, false);
    canvas.on('mouse:down', startAddParallelogram(canvas));
    canvas.on('mouse:move', startDrawingParallelogram(canvas));
    canvas.on('mouse:up', () => {
        mouseDown = false;
        stopDrawing(canvas, drawInstance, emitOnMouseDown, onMouseDownLineHandler);
        handleChangeSelectMode('select');
    });
    canvas.selection = false;
    canvas.defaultCursor = 'default';
    canvas.hoverCursor = 'auto';
    canvas.isDrawingMode = false;
    canvas.getObjects().map((item) =>
        item.set({
            selectable: false,
        })
    );
    canvas.discardActiveObject().requestRenderAll();
};

const startAddParallelogram = (canvas) => {
    return (_ref) => {
        let { e } = _ref;
        mouseDown = true;
        const pointer = canvas.getPointer(e);
        origX = pointer.x;
        origY = pointer.y;

        drawInstance = new Parallelogram({
            ...SHAPE_DEFAULTS_AS_SHAPE_PROPS,
            left: origX,
            top: origY,
            width: 400,
            height: 200,
            selectable: false,
            strokeUniform: true,
            strokeWidth: 2,
            isDynamic: true  // tells that we will use this instance only for mocking
        });
        canvas.add(drawInstance);
    };
};

const startDrawingParallelogram = (canvas) => {
    return (_ref) => {
        let { e } = _ref;

        if (mouseDown) {
            const pointer = canvas.getPointer(e);

            if (pointer.x < origX) {
                drawInstance.set('left', pointer.x);
            }

            if (pointer.y < origY) {
                drawInstance.set('top', pointer.y);
            }

            drawInstance.set({
                width: Math.abs(pointer.x - origX),
                height: Math.abs(pointer.y - origY),
            });
            drawInstance.setCoords();
            canvas.renderAll();
        }
    };
};
