import React from 'react';
import PropTypes from 'prop-types';

const DoubleTickIcon = ({ color }) => (
    <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.4999 0.833008L7.87188 11.0037C7.69798 11.2357 7.43194 11.3808 7.14272 11.4014C6.85349 11.422 6.56957 11.316 6.36455 11.111L4.37988 9.12634" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3.99993 11.1735C3.81316 11.3645 3.55773 11.4728 3.2906 11.4743C3.02347 11.4758 2.76684 11.3704 2.57793 11.1815L0.593262 9.2002" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.62 0.90332L6.19336 8.13932" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

DoubleTickIcon.propTypes = {
    color: PropTypes.string,
}

export default DoubleTickIcon;