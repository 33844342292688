import { addCommentIcon } from './AddComment';
import { setDirectlyCommentBadge } from './UpdateCommentBadge';
import eventEmitter from '../EventEmitter';

/**
 * Adds comment Icon, emits the added comment, adds added comment to the comments state.
 * @param {object} props
 * @param props.canvas
 * @param props.userId
 * @param props.activePageId
 * @param props.value
 * @param props.oldValue
 * @param props.commentInputClickedPos
 * @param props.whiteBoardId
 * @param props.commentFormWrapperRef
 * @param props.uuidGenerator
 * @param props.currentUser
 * @param props.taggedUsers
 * @param props.socketRef
 * @param props.setSelectedCommentIcon
 * @param props.hideComments
 */
export function handleAddCommentCallback({
    canvas,
    userId,
    activePageId,
    value,
    oldValue,
    commentInputClickedPos,
    whiteBoardId,
    commentFormWrapperRef,
    uuidGenerator,
    currentUser,
    taggedUsers,
    socketRef,
    setSelectedCommentIcon,
    hideComments,
    setComments
}) {
    commentFormWrapperRef.current.style.display = 'none';

    const commentUUID = uuidGenerator(canvas);
    let payload = {
        roomId: whiteBoardId,
        userId,
        uuid: commentUUID,
        content: oldValue,
        comment_content: value,
        position: {
            x: commentInputClickedPos.current.x,
            y: commentInputClickedPos.current.y
        },
        colorCode: 'purple',
        pageId: activePageId,
    };
    let addingComment = {
        uuid: commentUUID,
        createdAt: new Date().toISOString(),
        colorCode: 'purple',
        replies: [],
        userId,
        content: oldValue,
        comment_content: value,
        user: { name: currentUser.name},
        userComment: [{readAt: new Date().toISOString()}]
    }
    if (Array.isArray(taggedUsers) && taggedUsers.length > 0) {
        payload.taggedUserIds = taggedUsers;
        payload.tagged_user_ids = taggedUsers;
        addingComment.taggedUserIds = taggedUsers;
        addingComment.tagged_user_ids = taggedUsers;
    }
    socketRef.current.emit('addComment', payload, (data) => {
        if(!data?.emitData) return;
        eventEmitter.fire('addCommentOwner', data.emitData)
    });
    addCommentIcon({
        canvas, 
        left: commentInputClickedPos.current?.x,
        top: commentInputClickedPos.current?.y,
        colorCode: 'purple',
        unreadMessagesCount: 0, 
        commentID: commentUUID, 
        autoSelect: true, 
        resolved: false, 
        setSelectedCommentIcon, 
        shouldHideComment: hideComments ? true : false  // if 
    });
    
    setComments(oldComments => [
        ...oldComments,
        addingComment
    ])
}


/**
 * Adds reply, emits it and update comments state for added reply.
 * @param props
 * @param props.replyUuid
 * @param props.value
 * @param props.oldValue
 * @param props.userId
 * @param props.activePageId
 * @param props.parentUuid
 * @param props.whiteBoardId
 * @param props.currentUser
 * @param props.taggedUserIds
 * @param props.socketRef
 * @param props.setComments
 * @param props.setSelectedComment
 */
export function handleAddReplyCallback({
    replyUuid,
    value,
    oldValue,
    userId,
    activePageId,
    parentUuid,
    whiteBoardId,
    currentUser,
    taggedUserIds,
    socketRef,
    setComments,
    setSelectedComment,
    parentId
}) {
    const payload = {
        roomId: whiteBoardId,
        userId: userId,
        content: oldValue,
        comment_content: value,
        parentCommentUuid: parentUuid,
        uuid: replyUuid,
        pageId: activePageId,
        parentId
    }
    const addingReply = {
        userId,
        uuid: replyUuid,
        content: oldValue,
        comment_content: value,
        parentUuid,
        isDeleted: false,
        user: { name: currentUser.name },
        createdAt: new Date().toISOString(),
        userComment: [{ readAt: new Date().toISOString() }],
    }
    
    if (Array.isArray(taggedUserIds) && taggedUserIds.length > 0) {
        payload.taggedUserIds = taggedUserIds;
        payload.tagged_user_ids = taggedUserIds;
        addingReply.taggedUserIds = taggedUserIds;
        addingReply.tagged_user_ids = taggedUserIds;
    }
    
    //replies socket
    socketRef.current.emit('addComment', payload, (data)=> {
        if(!data?.emitData) return;
        eventEmitter.fire('replyComment', data.emitData)
    })

    setComments(oldComments => {
        let mutableComments = [...oldComments];
        const parentComment = mutableComments?.find(({ uuid }) => uuid === parentUuid);
        
        if (parentComment) {
            if (Array.isArray(parentComment.replies)) {
                parentComment.replies = parentComment.replies.concat(addingReply)
            } else {
                parentComment.replies = [addingReply]
            }
        }
        return mutableComments
    });

    setSelectedComment(oldComment => {
        oldComment.replies = [...oldComment.replies, addingReply];
        return oldComment;
    });
}


/**
 * Mark as read given comment and emits is as read.
 * @param commentUuid
 * @param socketRef
 * @param canvas
 */
export const handleReadCommentCallback = (
    commentUuid,
    socketRef,
    canvas,
) => {
    socketRef.current.emit('readComments', {uuid: commentUuid}, (data) => {
        if(!data.emitData) return;
        eventEmitter.fire('readComment', data.emitData)
    })
    setDirectlyCommentBadge(canvas, commentUuid, 0, false);
}

/**
 * Updates all comments as read and emits them as read.
 * @param socketRef
 * @param canvas
 * @param activePageId
 * @param searchValue
 * @param filter
 */
export const handleReadAllCommentsCallback = (
    socketRef,
    canvas,
    activePageId,
    searchValue,
    filter
) => {
    let params = { pageId: activePageId, filter }
    if (searchValue !== '') params = { ...params, search: searchValue }
    socketRef.current.emit('readAllCommentsWithFilters', { ...params }, (data) => {
        if (!data?.emitData) return;
        eventEmitter.fire('markAllRead', data.emitData);
        
        //change this logic it is wrong
        let affectedObjects = []
        let comments = canvas.getObjects().filter(item => item.type === 'comment' && item.unreadCommentCount > 0);
        comments.forEach(item => {
            if(data.emitData.markedComments.includes(item.commentID)) affectedObjects.push(item);
        })
        affectedObjects.some(item => setDirectlyCommentBadge(canvas, item.commentID, 0, true))
        
        canvas.renderAll();
        affectedObjects.forEach(item => item.setCoords());
        
    })
    canvas.renderAll();
}

/**
 * Mark as unread given comment and emits it as unread.
 * @param commentUuid
 * @param badgeText
 * @param socketRef
 * @param canvas
 */
export const handleMarkAsUnreadCommentCallback = (
    commentUuid,
    badgeText,
    socketRef,
    canvas,
) => {
    socketRef.current.emit('unreadComment', { uuid: commentUuid }, (data) => {
        if(!data.emitData) return;
        eventEmitter.fire('readComment', data.emitData);
    });
    setDirectlyCommentBadge(canvas, commentUuid, parseInt(badgeText), false);
}


/**
 * Updates comment, emits it and update comments state for updated comment.
 * @param data
 * @param socketRef
 * @param setUpdatedComment
 */
export const handleUpdateCommentContentCallback = (
    data, 
    socketRef,
    setUpdatedComment,
) => {
    console.log('update with: ', data);
    let socketPayload = {
        uuid: data.commentUuid,
        content: data.oldValue,
        comment_content: data.value
    }
    if (data.taggedUserIds) {
        socketPayload.taggedUserIds = data.taggedUserIds
        socketPayload.tagged_user_ids = data.taggedUserIds
    }
    socketRef.current.emit('updateComment', socketPayload, (data) => {
        if(!data.emitData) return;
        eventEmitter.fire('updateComment', data.emitData)
    });
    const updatingComment = {
        ...socketPayload
    }
    if (data.isReply) updatingComment.parentUuid = data.parentUuid;
    setUpdatedComment(updatingComment);
}


/**
 * Emits comment as resolved, updates comments state via setResolvedComment
 * method that comes from useUpdateComment hook.
 * @param commentUuid
 * @param resolved
 * @param socketRef
 * @param setResolvedComment
 * @param colorCode
 * @param canvas
 */
export const handleResolveCommentCallback = (commentUuid, resolved, socketRef, setResolvedComment, colorCode, canvas) => {
    if (!resolved && canvas) {
        let currentCommentOnCanvas = canvas.getObjects().find(item => item.commentID === commentUuid);
        currentCommentOnCanvas.updateColor(colorCode);
    }
    socketRef.current.emit('resolveComment', {
        uuid: commentUuid,
        resolved
    }, (data) => {
        if(!data?.emitData) return;
        eventEmitter.fire('resolveComment', data.emitData)
    });
    setResolvedComment({ uuid: commentUuid, resolved});
}


/**
 * Emits comment as deleted, updates comments state via setDeletedComment
 * method that comes from useUpdateComment hook.
 * @param commentUuid
 * @param parentUuid
 * @param socketRef
 * @param setDeletedComment
 */
export const handleDeleteCommentCallback = (
    commentUuid, 
    parentUuid, 
    socketRef, 
    setDeletedComment, 
) => {
    socketRef.current.emit('deleteComment', {
        uuid: commentUuid,
        isDeleted: true
    });
    
  
    let deletedComment = {
        uuid: commentUuid,
        isDeleted: true
    }
    if (parentUuid) deletedComment.parentUuid = parentUuid
    
    setDeletedComment(deletedComment);
}