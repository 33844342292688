import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types'
import NumberInput from '../../inputs/number/NumberInput';

const FontSizeItem = ({
    isDisabled,
    onChange,
    textFontSize,
    maximumSize
}) => {
    return (
        <div className={clsx('textSizeBtn', { disabled: isDisabled })}>
            <NumberInput
                defaultValue={textFontSize}
                disabled={isDisabled}
                handleChange={onChange}
                maximumSize={maximumSize}
            />

            <span className="tooltipText tooltipText--colorPicker">Text size</span>
        </div>
    )
};

FontSizeItem.propTypes = {
    isDisabled: PropTypes.bool,
    textFontSize: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    maximumSize: PropTypes.number
}

export default FontSizeItem;