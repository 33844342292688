export class ObjectsMap {
    constructor() {
        this.objects = new Map(); // <uuid, instance>
    }
    
    has(uuid) {
        return this.objects.has(uuid)
    }
    
    add(object) {
        if (!object.uuid) {
            return
        }
        
        this.objects.set(object.uuid, object)
    }
    
    get(uuid) {
        return this.objects.get(uuid)
    }
    
    addWithUuid(uuid, object) {
        this.objects.set(uuid, object)
    }
    
    remove(uuid) {
        this.objects.delete(uuid)
    }
}