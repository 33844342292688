
// Path segment interface:
export const SegmentPrototype = {

    // virtual
    bbox: function() {
        throw new Error('Declaration missing for virtual function.');
    },

    // virtual
    clone: function() {
        throw new Error('Declaration missing for virtual function.');
    },

    // virtual
    closestPoint: function() {
        throw new Error('Declaration missing for virtual function.');
    },

    // virtual
    closestPointLength: function() {
        throw new Error('Declaration missing for virtual function.');
    },

    // virtual
    closestPointNormalizedLength: function() {
        throw new Error('Declaration missing for virtual function.');
    },

    // Redirect calls to closestPointNormalizedLength() function if closestPointT() is not defined for segment.
    closestPointT: function(p) {
        if (this.closestPointNormalizedLength) { return this.closestPointNormalizedLength(p); }

        throw new Error('Neither closestPointT() nor closestPointNormalizedLength() function is implemented.');
    },

    // virtual
    closestPointTangent: function() {
        throw new Error('Declaration missing for virtual function.');
    },

    // virtual
    divideAt: function() {
        throw new Error('Declaration missing for virtual function.');
    },

    // virtual
    divideAtLength: function() {
        throw new Error('Declaration missing for virtual function.');
    },

    // Redirect calls to divideAt() function if divideAtT() is not defined for segment.
    divideAtT: function(t) {
        if (this.divideAt) { return this.divideAt(t); }

        throw new Error('Neither divideAtT() nor divideAt() function is implemented.');
    },

    // virtual
    equals: function() {
        throw new Error('Declaration missing for virtual function.');
    },

    // virtual
    getSubdivisions: function() {
        throw new Error('Declaration missing for virtual function.');
    },

    // virtual
    isDifferentiable: function() {
        throw new Error('Declaration missing for virtual function.');
    },

    isSegment: true,
    isSubpathStart: false, // true for Moveto segments
    isVisible: true, // false for Moveto segments

    // virtual
    length: function() {
        throw new Error('Declaration missing for virtual function.');
    },

    // Return a fraction of result of length() function if lengthAtT() is not defined for segment.
    lengthAtT: function(t) {
        if (t <= 0) { return 0; }

        var length = this.length();

        if (t >= 1) { return length; }

        return length * t;
    },

    nextSegment: null, // needed for subpath start segment updating

    // virtual
    pointAt: function() {
        throw new Error('Declaration missing for virtual function.');
    },

    // virtual
    pointAtLength: function() {
        throw new Error('Declaration missing for virtual function.');
    },

    // Redirect calls to pointAt() function if pointAtT() is not defined for segment.
    pointAtT: function(t) {
        if (this.pointAt) { return this.pointAt(t); }

        throw new Error('Neither pointAtT() nor pointAt() function is implemented.');
    },

    previousSegment: null, // needed to get segment start property

    // virtual
    round: function() {
        throw new Error('Declaration missing for virtual function.');
    },

    subpathStartSegment: null, // needed to get Closepath segment end property

    // virtual
    scale: function() {
        throw new Error('Declaration missing for virtual function.');
    },

    // virtual
    serialize: function() {
        throw new Error('Declaration missing for virtual function.');
    },

    // virtual
    tangentAt: function() {
        throw new Error('Declaration missing for virtual function.');
    },

    // virtual
    tangentAtLength: function() {
        throw new Error('Declaration missing for virtual function.');
    },

    // Redirect calls to tangentAt() function if tangentAtT() is not defined for segment.
    tangentAtT: function(t) {
        if (this.tangentAt) { return this.tangentAt(t); }

        throw new Error('Neither tangentAtT() nor tangentAt() function is implemented.');
    },

    // virtual
    toString: function() {
        throw new Error('Declaration missing for virtual function.');
    },

    // virtual
    translate: function() {
        throw new Error('Declaration missing for virtual function.');
    }
};

// usually directly assigned
// getter for Closepath
Object.defineProperty(SegmentPrototype, 'end', {
    configurable: true,
    enumerable: true,
    writable: true
});

// always a getter
// always throws error for Moveto
Object.defineProperty(SegmentPrototype, 'start', {
    // get a reference to the end point of previous segment
    configurable: true,
    enumerable: true,
    get: function() {
        if (!this.previousSegment) { throw new Error('Missing previous segment. (This segment cannot be the first segment of a path; OR segment has not yet been added to a path.)'); }
        return this.previousSegment.end;
    }
});

// virtual
Object.defineProperty(SegmentPrototype, 'type', {
    configurable: true,
    enumerable: true,
    get: function() {
        throw new Error('Bad segment declaration. No type specified.');
    }
});