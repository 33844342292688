import * as rdd from 'react-device-detect';
import { SEGMENT_ANALYTICS_CONSTANTS } from './Constant';
export const getBrowserName = () => {
    return rdd.browserName;
};
export const getDeviceName = () => {
    if (rdd.isDesktop) return SEGMENT_ANALYTICS_CONSTANTS.DEVICE_TYPE_DESKTOP;
    if (rdd.isMobile) return SEGMENT_ANALYTICS_CONSTANTS.DEVICE_TYPE_MOBILE;
    if (rdd.isTablet) return SEGMENT_ANALYTICS_CONSTANTS.DEVICE_TYPE_TABLET;
};
