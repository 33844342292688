import React from 'react';
import PropTypes from 'prop-types';

const KeyboardIconSvg = ({ className }) => (
    <svg width="16" height="10" className={className} viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.5 2C0.5 1.44772 0.947715 1 1.5 1H14.5C15.0523 1 15.5 1.44772 15.5 2V8C15.5 8.55228 15.0523 9 14.5 9H1.5C0.947715 9 0.5 8.55228 0.5 8V2Z" stroke="#3C3E49" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.5 3H5.5" stroke="#3C3E49" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.5 3H11.5" stroke="#3C3E49" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.5 3H8.5" stroke="#3C3E49" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3 5H4" stroke="#3C3E49" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6 5H7" stroke="#3C3E49" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9 5H10" stroke="#3C3E49" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12 5H13" stroke="#3C3E49" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.5 7H11.5" stroke="#3C3E49" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

KeyboardIconSvg.propTypes = {
    className: PropTypes.string
}

KeyboardIconSvg.defaultProps = {
    width: 24,
    height: 24
}

export default KeyboardIconSvg;
