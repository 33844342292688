import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import './ProfileList.scss';

const ProfileList = ({ users, closeProfileList, clickedTarget }) => {
    console.log(users);
    const profileListRef = useRef();

    useEffect(() => {
        // add outside click listener to close the profile list
        const handleOutsideClick = (e) => {
            if(e.target.contains(clickedTarget)) return;
            if (profileListRef.current && !profileListRef.current.contains(e.target)) {
                closeProfileList();
            }
        }
        window.addEventListener('click', handleOutsideClick);
        return () => {
            window.removeEventListener('click', handleOutsideClick);
        }
    }, [closeProfileList, clickedTarget]);

    return (
        <div className='profileList' data-testid="profileList" ref={profileListRef}>
            <h2 className="profileList__title">People</h2>
            <ul className={clsx('profileList__list', { 'gradient-active': users.length >= 4 })} data-testid="profileListWrapper">
                {users.map((user) => (
                    <li className="profileList__item" data-testid="profileListItem" key={user.id}>
                        <span className='profileList__item--name'>
                            {user.name}

                            <span className="tooltiptext">{user.name}</span>
                        </span>
                        <div className='oval oval_not_hover' style={{ backgroundColor: user.info?.colorCode }}>
                            <span className='oval__name'>{user.name[0].toLocaleUpperCase()}</span>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
}

ProfileList.propTypes = {
    users: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        info: PropTypes.shape({
            colorCode: PropTypes.string
        })
    })),
    closeProfileList: PropTypes.func.isRequired,
    clickedTarget: PropTypes.shape({
        current: PropTypes.instanceOf(HTMLElement)
    })
}

export default ProfileList;