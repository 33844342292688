import {fabric} from 'fabric';
import {getEditingShapes} from '../../FabricMethods';
import { v4 as uuidv4 } from 'uuid';
import {calculateObjPos} from '../../frame/CalculatePositions';

/**
 * Method that defines the actions when mouse is hovering the canvas.
 * The currentTransform parameter will define whether the user is rotating/scaling/translating
 * an image or neither of them (only hovering). A group selection is also possible and would cancel
 * all any other type of action.
 * In case of an image transformation only the top canvas will be rendered.
 * @override
 * @param {Event} e Event object fired on mousemove.
 */
export function __onMouseMove(e) {
    this._handleEvent(e, 'move:before');
    this._cacheTransformEventData(e);
    var target, pointer;

    if (this.isDrawingMode) {
        this._onMouseMoveInDrawingMode(e);
        return;
    }

    if (!this._isMainEvent(e)) {
        return;
    }

    var groupSelector = this._groupSelector;

    // We initially clicked in an empty area, so we draw a box for multiple selection
    if (groupSelector) {
        pointer = this._absolutePointer;

        groupSelector.left = pointer.x - groupSelector.ex;
        groupSelector.top = pointer.y - groupSelector.ey;

        this.renderTop();
    }
    else if (!this._currentTransform) {
        target = this.findTarget(e) || null;
        this._setCursorFromEvent(e, target);
        this._fireOverOutEvents(target, e);
    }
    else {
        // override
        const { target } = this._currentTransform;
        if (target) {
            target.onTransformChanged();
        }
        this._transformObject(e);
    }
    this._handleEvent(e, 'move');
    this._resetTransformEventData();
}

const dragAbortionHandler = (canvas, transform) => {
    if (transform.target.type === 'activeSelection') {
        for (const obj of transform.target.getObjects()) {
            const instanceOriginal = transform.original.objects.get(obj)
            obj.onShapeChanged();
            obj.setPositionByOrigin({
                x: instanceOriginal.center.x + transform.original.center.x,
                y: instanceOriginal.center.y + transform.original.center.y,
            }, 'center', 'center')
            obj.setCoords();
        }
    }  else if (transform.target.type === 'frame') {
        transform.target.onShapeChanged();
        transform.target.set({
            left: transform.original.left,
            top: transform.original.top
        });
        const handleFrameObjAbortion = (object, frame) => {
            object.onShapeChanged();
            // get original attached position
            const objectAttachedPos = calculateObjPos(frame, object.calculatedPos);
            object.left = objectAttachedPos.x;
            object.top = objectAttachedPos.y;
            object.setCoords(); 
        }
        const canvasObjects = canvas.getObjects()

        canvasObjects.filter(o => o.wiredFrame === transform.target).forEach(object => {
            handleFrameObjAbortion(object, transform.target);
            if (object.type === 'frame') {
                canvasObjects.filter(nestedObject => nestedObject.wiredFrame === object).forEach(nestedObj => {
                    handleFrameObjAbortion(nestedObj, object);
                })
            }
        })
    } else {
        transform.target.onShapeChanged();
        transform.target.set({
            left: transform.original.left,
            top: transform.original.top
        });
    }
}

export function _performTransformAction(e, transform, pointer) {
    var x = pointer.x,
        y = pointer.y,
        action = transform.action,
        actionPerformed = false,
        actionHandler = transform.actionHandler;
    
    // if target is locked, do not allow transformation
    if (transform?.target?.isLocked || transform?.target?.lockMovementX || transform?.target?.lockMovementY) {
        return
    }
    
    
    // this object could be created from the function in the control handlers
    if (actionHandler) {
        if (!transform.aborted) {
            actionPerformed = actionHandler(e, transform, x, y);
        }
        if (
            action !== 'connector' || 
            (action === 'connector' && !transform.lockingHandled)
        ) {
            if (!transform.target.isSentEditing) {
                transform.processId = uuidv4();
                
                const abortionHandler = (processId) => {
                   
                    this.lockManager.stopEditing(editingShapes, this.pageId, processId);
                    transform.aborted = true;

                    if (action === 'drag') {
                        this.discardActiveObject().requestRenderAll();
                        dragAbortionHandler(this, transform);
                        this.__onMouseUp(e)
                        this.fire('remove-from-undo-stack', {processId: transform.processId});
                    } else if (action === 'rotate') {
                        this.discardActiveObject().requestRenderAll();
                        if (transform.target.type !== 'activeSelection') {
                            transform.target.angle = transform.original.angle;
                        } else {
                            for (const shape of editingShapes) {
                                if (shape.type === 'frame') {
                                    continue;
                                }
                                const originalObjects = transform.original.objects;
                                const originalInstance = originalObjects.get(shape);

                                if (shape.type === 'curvedLine') {
                                    shape.points = originalInstance.points;
                                }
                                shape.angle = originalInstance.angle;
                            }
                            dragAbortionHandler(this, transform);
                            this.fire('remove-from-undo-stack', {processId: transform.processId});
                        }
                    } else if (action === 'scale' || action === 'expanse' || action === 'connector' || action === 'modifypolygon') {
                        // use history to revert the state
                        this.__onMouseUp(e)
                        this.fire('undo-one-step', {processId: transform.processId});
                    }

                    transform.target.setCoords();
                    transform.target.isSentEditing = false;
                    this._currentTransform = null;
                }
                
                const editingShapes = getEditingShapes(this, transform.target);
                const { shapeUuids, processId } = this.lockManager.startEditing(
                    editingShapes,
                    this.pageId,
                    (data) => {
                        if (!data.failedShapes || !data.failedShapes.length) {
                            return
                        }
                        abortionHandler(data.processId)
                    }
                );
                transform.target.editedObjects = editingShapes;
                transform.target.lockingProcessId = transform.processId;
                transform.target.isSentEditing = true;

                
                if (!shapeUuids?.length) {
                    this.__onMouseUp(e)
                    abortionHandler(processId);
                    return;
                }
            }
        }
        else {
            // connectors transform action is handled in the connector control mouse down and up events
            if (transform.aborted) {
                this.__onMouseUp(e);
            }
        }
    }
    if (action === 'drag' && actionPerformed && !transform.aborted) {
        transform.target.isMoving = true;
        this.setCursor(transform.target.moveCursor || this.moveCursor);
    }
    transform.actionPerformed = transform.actionPerformed || actionPerformed;
}


function checkClick(e, value) {
    return e.button && (e.button === value - 1);
}

const RIGHT_CLICK = 3, MIDDLE_CLICK = 2, LEFT_CLICK = 1;

export function __onMouseUp (e) {
    var target, transform = this._currentTransform,
        groupSelector = this._groupSelector, shouldRender = false,
        isClick = (!groupSelector || (groupSelector.left === 0 && groupSelector.top === 0));
    this._cacheTransformEventData(e);
    target = this._target;
    this._handleEvent(e, 'up:before');
    // if right/middle click just fire events and return
    // target undefined will make the _handleEvent search the target
    if (checkClick(e, RIGHT_CLICK)) {
        if (this.fireRightClick) {
            this._handleEvent(e, 'up', RIGHT_CLICK, isClick);
        }
        return;
    }

    if (checkClick(e, MIDDLE_CLICK)) {
        if (this.fireMiddleClick) {
            this._handleEvent(e, 'up', MIDDLE_CLICK, isClick);
        }
        this._resetTransformEventData();
        return;
    }

    if (this.isDrawingMode && this._isCurrentlyDrawing) {
        this._onMouseUpInDrawingMode(e);
        return;
    }

    if (!this._isMainEvent(e)) {
        return;
    }
    if (transform) {
        this._finalizeCurrentTransform(e);
        shouldRender = transform.actionPerformed;
    }
    if (!isClick) {
        var targetWasActive = target === this._activeObject;
        this._maybeGroupObjects(e);
        if (!shouldRender) {
            shouldRender = (
                this._shouldRender(target) ||
                (!targetWasActive && target === this._activeObject)
            );
        }
    }
    var corner, pointer;
    if (target) {
        corner = target._findTargetCorner(
            this.getPointer(e, true),
            fabric.util.isTouchEvent(e)
        );
        if (target.selectable && target !== this._activeObject && target.activeOn === 'up') {
            this.setActiveObject(target, e);
            shouldRender = true;
        }
        else {
            var control = target.controls[corner],
                mouseUpHandler = control && control.getMouseUpHandler(e, target, control);
            if (mouseUpHandler) {
                pointer = this.getPointer(e);
                mouseUpHandler(e, transform, pointer.x, pointer.y);
            }
        }
        if (target.isSentEditing) {
            this.lockManager.stopEditing(target.editedObjects, this.pageId, target.lockingProcessId);
            target.isSentEditing = false;
        }
        target.isMoving = false;
    }
    // if we are ending up a transform on a different control or a new object
    // fire the original mouse up from the corner that started the transform
    if (transform && (transform.target !== target || transform.corner !== corner)) {
        var originalControl = transform.target && transform.target.controls[transform.corner],
            originalMouseUpHandler = originalControl && originalControl.getMouseUpHandler(e, target, control);
        pointer = pointer || this.getPointer(e);
        originalMouseUpHandler && originalMouseUpHandler(e, transform, pointer.x, pointer.y);
    }
    this._setCursorFromEvent(e, target);
    this._handleEvent(e, 'up', LEFT_CLICK, isClick);
    this._groupSelector = null;
    this._currentTransform = null;
    // reset the target information about which corner is selected
    target && (target.__corner = 0);
    if (shouldRender) {
        this.requestRenderAll();
    }
    else if (!isClick) {
        this.renderTop();
    }
}