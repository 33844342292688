import React from 'react';
import { createInitialFromUsername } from '../../../helpers/CommonFunctions';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const AutoCompleteOption = ({
    active = false,
    option,
    handleClick,
    handleHover,
}) => {
    const { avatar = null, label, value } = option;

    return (
        <li
            className={clsx('option', { 'active': active })}
            onClick={() => handleClick(value)}
            onMouseEnter={handleHover}
        >
            <article>
                <aside className="avatar-section">
                    {avatar ? (
                        <img alt={`${label} Avatar`} src={avatar} />
                    ) : (
                        <span>{createInitialFromUsername(label)}</span>
                    )}
                </aside>
                <main>
                    <p className="title">{label}</p>
                    <p className="subtitle">{value}</p>
                </main>
            </article>
        </li>
    );
};

AutoCompleteOption.propTypes = {
    active: PropTypes.bool,
    option: PropTypes.shape({
        avatar: PropTypes.string,
        label: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node,
            PropTypes.string,
            PropTypes.number
        ]),
        value: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node,
            PropTypes.string,
            PropTypes.number
        ])
    }).isRequired,
    handleClick: PropTypes.func.isRequired,
    handleHover: PropTypes.func.isRequired,
}

export default AutoCompleteOption;
