import React from 'react';

/**
 *
 */
const AngleRight = () => {
    return (
        <svg
            fill="none"
            height="18"
            viewBox="0 0 9 18"
            width="9"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M.91 16.92l6.52-6.52c.77-.77.77-2.03 0-2.8L.91 1.08"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="1.5"
            />
        </svg>
    );
}

export default AngleRight;