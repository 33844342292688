import { CompositeRenderer } from '../renderers/compositeRenderer/CompositeRenderer';
import { TileRenderer } from '../renderers/tileRenderer/TileRenderer'
import {EventEmitter} from '../../helpers/EventEmitter';

export class Engine {
    constructor(fabricCanvas) {
        this.fabricCanvas = fabricCanvas;
        this.emitter = new EventEmitter() ;
        this.compositeRenderer = new CompositeRenderer(fabricCanvas)
        this.tileRenderer = new TileRenderer(fabricCanvas, this.emitter, {
            debug: false
        });
        this._isScaling = false;
        this._prevScale = -1;
        this._prevScalingTime = 0;
        this.canvasScale = 1;
        this.isShapesLoaded = false;
        this.needsRender = false;
    }

    set isScaling(val) {
        this._prevScalingTime = Date.now();
        this._isScaling = val;
        this._prevScale = this.canvasScale;
    }

    get isScaling() {
        return this._isScaling;
    }

    get shouldUseDefaultRender() {
        return this.isScaling || !this.isShapesLoaded;
    }

    updateScalingState() {
        if (this.isScaling && Date.now() - this._prevScalingTime > 350) {
            this.isScaling = false;
            this.needsRender = true;
        }
    }

    /**
     * Calls default render method of fabric js.
     * This is the initial render strategy.
     */
    renderDefault() {
        this.compositeRenderer.beginRender();
        this.compositeRenderer.renderBackground();
        this.compositeRenderer.renderDefault()
        this.compositeRenderer.renderDynamicObjects()
        this.compositeRenderer.renderControlsUI()
        this.compositeRenderer.endRender()
        this.compositeRenderer.renderLockingUI();

    }
    render() {
        if (this.shouldUseDefaultRender) {
            this.renderDefault()
            return;
        }
        this.compositeRenderer.beginRender();
        this.compositeRenderer.renderBackground();
        this.tileRenderer.render();
        this.compositeRenderer.renderDynamicObjects();
        this.compositeRenderer.renderControlsUI();
        this.compositeRenderer.endRender();
        this.compositeRenderer.renderLockingUI();
    }

    run() {
        this.updateScalingState();
        if (this.needsRender) {
            this.render();
            this.needsRender = false;
        }
        window.requestAnimationFrame(this.run.bind(this));
    }

    requestRender() {
        this.needsRender = true;
    }

    setZoom(value) {
        this.canvasScale = value;
        this.isScaling = true;
    }
    
    shapesLoaded() {
        this.isShapesLoaded = true;
    }
    
    dispose() {
        this.tileRenderer.dispose();
        this._isScaling = false;
        this._prevScale = -1;
        this._prevScalingTime = 0;
        this.canvasScale = 1;
        this.isShapesLoaded = false;
        this.needsRender = false;
    }
}
