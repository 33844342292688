import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types'
import { LINE_TYPES, TOOLBAR_MENU } from '../../../helpers/Constant';
import IconMenuLineType from '../../svgIcons/lines/MenuLineType';
import IconArrowUp from '../../svgIcons/arrows/ArrowUp';
import LineTypeMenu from '../menus/lineTypeMenu/LineTypeMenu';

const LineTypeItem = ({
    isDisabled,
    showMenu,
    toggleMenu,
    changeLineType,
    changeThickness,
    toggleDashed,
    strokeWidth,
    strokeDashArray,
    lineType
}) => {
    const menuOnClickHandler = () => {
        if (isDisabled) return;
        toggleMenu(TOOLBAR_MENU.LINE_TYPE);
    }

    return (
        <div className="lineOption__wrapper">
            <div className={clsx('button svg_stroke', { disabled: isDisabled })} onClick={menuOnClickHandler}>
                <IconMenuLineType />
                <div className="arrow_wrapper"><IconArrowUp /></div>

                <span className={clsx('tooltipText maxContent', { 'onBottom': showMenu })}>
                    Line type
                </span>
            </div>

            {showMenu ? (
                <LineTypeMenu
                    changeLineType={(type) => changeLineType(type)}
                    changeThickness={(val) => changeThickness(val)}
                    onClickHandler={(type) => toggleDashed(type)}
                    toggleDashed={() => toggleDashed()}
                    strokeWidth={strokeWidth}
                    strokeDashArray={strokeDashArray}
                    lineType={lineType}
                />
            ) : null}
        </div>
    )
};

LineTypeItem.propTypes = {
    isDisabled: PropTypes.bool,
    showMenu: PropTypes.bool,
    toggleMenu: PropTypes.func.isRequired,
    changeLineType: PropTypes.func.isRequired,
    changeThickness: PropTypes.func.isRequired,
    toggleDashed: PropTypes.func.isRequired,
    strokeWidth: PropTypes.number,
    strokeDashArray: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.number
    ]),
    lineType: PropTypes.oneOf([
        LINE_TYPES.STRAIGHT,
        LINE_TYPES.CURVED,
        LINE_TYPES.STEPPER
    ])
}

export default LineTypeItem;