import { fabric } from 'fabric';
import { TEXT_SIZE_OPTIONS } from '../Constant';

/**
 * Using this function to saving table.
 * @param {fabric.Canvas} canvas 
 * @param {fabric.Object} target 
 */
export function sendUpdates(canvas, target) {
    canvas.fire('history-emit-data', {
        objects: [target],
        action: 'modified',
    });

    canvas.fire('modify-to-undo-stack', { target });
    // update activity logs
    canvas.fire('modified-with-event', { target });
}

/**
 * Returns the cell which we can find it via pointer.
 * @param {{ x: number, y:number }} pointer 
 * @param {[object]} cells 
 * @returns {object}
 */
export function getSelectedCellByPointer(pointer, cells) {
    const selectedCell = cells.find((cell) => {
        const { tl, tr, bl } = cell.cellCoords;
        const isInside = (
            pointer.x > tl.x &&
            pointer.x < tr.x &&
            pointer.y > tl.y &&
            pointer.y < bl.y
        );

        return isInside;
    });

    return selectedCell;
}

/**
 * Returns the font size at minimum 10 and maximum 80.
 * @param {number} fontSize 
 * @returns {number}
 */
export function getExactFontSize(fontSize) {
    const size = Math.min(fontSize, Math.max(...TEXT_SIZE_OPTIONS));
    return Math.max(size, TEXT_SIZE_OPTIONS[0]);
}