import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { copyToClipboard } from '../../helpers/Clipboard';
import { isObjectValid } from '../../helpers/FabricMethods';
import getToastIcon from '../../helpers/media/GetToastIcon';

import './ContextMenu.scss';
import useOnClickOutside from '../../hooks/UseOutsideClick';
import { isUserHasAccessToFeature } from '../../helpers/CommonFunctions';

const ContextMenu = ({ canvas, userAccess, handleToggleCommentDrawer, activePageId }) => {
    const [properties, setProperties] = useState({
        left: 0,
        top: 0,
        isOpen: false,
    });
    const clickRef = React.useRef(null);
    const contextMenuRef = React.useRef(null);

    const isFrameUrlCanBeGenerated = useMemo(() => {
        try {
            const mainUrlObject = new URL(window.location.href);

            // If the board is loading from a product
            if (window.self !== window.top) {
                const urlQs = mainUrlObject.searchParams.get('url');
                if (!urlQs) return false;

                const url = new URL(urlQs);
                if (!url) return false;
            }
        } catch (err) {
            console.error(err);
            return false;
        }

        return true;
    }, []);

    const handleClickOutside = useCallback(() => {
        setProperties(data => ({...data, isOpen: false}));
    }, []);
    useOnClickOutside(contextMenuRef, handleClickOutside);

    const generateURLWithUUID = (uuid) => {
        try {
            const urlObject = new URL(window.location.href);

            if (window.self !== window.top && urlObject.searchParams.get('url')) {
                const parentURL = new URL(urlObject.searchParams.get('url'));
                parentURL.searchParams.set('shape', uuid);

                if (Number.isInteger(activePageId)) {
                    parentURL.searchParams.set('pageId', activePageId);
                }

                return parentURL.href;
            } else if (window.self === window.top) {
                // If url has comment, remove it from url first in order to avoid conflict.
                if (urlObject.searchParams.get('commentId')) {
                    urlObject.searchParams.delete('commentId');
                }

                urlObject.searchParams.set('shape', uuid);

                if (Number.isInteger(activePageId)) {
                    urlObject.searchParams.set('pageId', activePageId);
                }

                // Why: Email is encoded in bmeetBoards if we don't decode it.
                return decodeURIComponent(urlObject.href);
            }
            return null;
        } catch (e) {
            console.error('Failed to generate URL with UUID', e);
            return null;
        }
    }

    const handleCopyURL = async () => {
        const activeObject = canvas.getActiveObject();
        if (activeObject) {
            try {
                const frameURL = generateURLWithUUID(activeObject.uuid);
                if (frameURL) {
                    const isCopied = await copyToClipboard(frameURL);
                    if (isCopied) {
                        toast.success('Copied to clipboard', {
                            icon: getToastIcon('success'),
                            className: 'wb_toast',
                        });
                    } else {
                        toast.error('Failed to copy url', {
                            icon: getToastIcon('error'),
                            className: 'wb_toast',
                        });
                    }
                } else {
                    toast.error('Failed to generate URL', {
                        icon: getToastIcon('error'),
                        className: 'wb_toast',
                    });
                }
            } catch (err) {
                toast.error('Failed to copy url', {
                    icon: getToastIcon('error'),
                    className: 'wb_toast',
                });
                console.error(err);
            }
        } else {
            toast.error('Select a frame to copy', {
                icon: getToastIcon('error'),
                className: 'wb_toast',
            });
        }
        setProperties(data => ({...data, isOpen: false}));
    }

    const handleAddComment = () => {
        try {
            if (!isUserHasAccessToFeature('comment_modification', userAccess)) { return; }
            const e = clickRef.current;
            e.pointer = {
                x: e.clientX,
                y: e.clientY
            }
            handleToggleCommentDrawer({ shouldShow: true });
            canvas.fire('open-comment-form', e);
            setProperties(data => ({...data, isOpen: false}));
        } catch (err) {
            console.error('Failed to open comment form', err);
        }
    }

    useEffect(() => {
        const openContextMenuListener = (event) => {
            clickRef.current = event;
            let left = event.clientX,
                top = event.clientY,
                isFrameSelected = false;
            // if there is an object in the clicked position, select it
            const target = canvas.findTarget(event);
            if (target && isObjectValid(target)) {
                // if the object is a frame, set şsFrameSelected to true to show the copy frame url button
                if (target.shapeType === 'frame') {
                    isFrameSelected = true;
                }
                canvas.setActiveObject(target);
                canvas.requestRenderAll();
            } else {
                canvas.discardActiveObject();
            }
    
            const windowWidth = window.innerWidth;
            if (event.clientX + 200 > windowWidth) {
                left = windowWidth - 200;
            }
            setProperties(data => ({
                ...data,
                left,
                top,
                isOpen: true,
                isFrameSelected
            }));
        }
        const closeContextMenuListener = () => {
            setProperties(data => {
                if (data && data.isOpen) {
                    return {
                        ...data,
                        isOpen: false,
                    }
                }
                return data;
            });
        }
        if (canvas) {
            canvas.on('open-context-menu', openContextMenuListener);
            canvas.on('close-context-menu', closeContextMenuListener);
            canvas.on('selection:created', closeContextMenuListener);
            canvas.on('selection:updated', closeContextMenuListener);
            canvas.on('selection:cleared', closeContextMenuListener);

            return () => {
                canvas.off('open-context-menu', openContextMenuListener);
                canvas.off('close-context-menu', closeContextMenuListener);
                canvas.off('selection:created', closeContextMenuListener);
                canvas.off('selection:updated', closeContextMenuListener);
                canvas.off('selection:cleared', closeContextMenuListener);
            }
        }
    }, [canvas]);

    if (properties.isOpen) {
        return (
            <div 
                className="contextMenu"
                ref={contextMenuRef}
                style={{transform: `translate(${properties.left}px, ${properties.top}px)`}}
            >
                {/* <div className="contextMenu__item">
                    <button 
                        onClick={() => handleCopyImage()}
                        className="contextMenu__button">
                        <span className="contextMenu__button--text">Copy Image</span>
                    </button>
                </div> */}
                {
                    (properties.isFrameSelected && isFrameUrlCanBeGenerated) ? <div className="contextMenu__item">
                        <button
                            type="button"
                            className="contextMenu__button"
                            onClick={() => handleCopyURL()}>
                            <span className="contextMenu__button--text">Copy URL for Frame</span>
                        </button>
                    </div> : null
                }
                <div className="contextMenu__item">
                    <button
                        type="button"
                        className="contextMenu__button"
                        disabled={!isUserHasAccessToFeature('comment_modification', userAccess)}
                        onClick={(e) => handleAddComment(e)}
                    >
                        <span className="contextMenu__button--text">Add Comment</span>
                    </button>
                </div>
            </div>
        )
    }
}

ContextMenu.propTypes = {
    canvas: PropTypes.object,
    userAccess: PropTypes.oneOf(['view', 'comment', 'edit', 'removeAccess', 'NOT_ALLOWED']),
    handleToggleCommentDrawer: PropTypes.func.isRequired,
    activePageId: PropTypes.number
}

export default ContextMenu;
