import { fabric } from 'fabric';
import {findBiggestZIndexOnHistory} from '../../zIndex/ManageZIndex';
import store from '../../../redux/Store';

/**
 * Adds an object to canvas.
 * @override
 * @returns {fabric.Canvas} Returns the canvas instance.
 */
export function add() {
    let argumentObject = arguments[0];
    const activityHistory = store.getState()?.activityHistory?.activityHistory.map(item => {
        return {...item.shape}
    });
    let history = activityHistory?.filter(item => item.isDeleted).map(item => {
        return {...item.properties}
    }) || [];
    let firstTime = arguments[1]?.firstTime;
    // adding z-index to the new added objects
    // checks if it's not the first time and a real object
    if((!argumentObject.isDynamic || argumentObject.forceAddZIndex) && !firstTime){
        if(!argumentObject.zIndex) argumentObject.zIndex = findBiggestZIndexOnHistory(this, argumentObject.shapeType, history);
    }
    
    for (const object of arguments) {
        // we don't want to loop around new arguments that we added for firstTime and history
        if(object.firstTime === true || object.history) return this; 
        if (object.type === 'frame') {
            // handle frame order
            let lastIdx = this._objects.findLastIndex(o => o.type === 'frame');
            if (lastIdx === -1) {
                this._objects.unshift(object)
            } else {
                this._objects.splice(lastIdx + 1, 0, object);
            }
        } else {
            this._objects.push(object);
        }
        if (this._onObjectAdded) {
            this._onObjectAdded(object);
        }
        if (this.dirtyObjects && (object.isDynamic || object.type === 'frame')) {
            this.dirtyObjects.append(object)
            // we also need to enable tile rendering for frames
            if (object.type === 'frame') {
                object.onShapeChanged();
            }
        } else {
            object.onShapeChanged();
        }
        
        if (object.uuid) {
            this.objectsMap.add(object)
        }
    }
    this.renderOnAddRemove && this.requestRenderAll();
    return this;
}


/**
 * Removes an object from canvas.
 * @override
 * @returns {fabric.Canvas} Returns the canvas instance.
 */
export function remove() {
    let objects = this._objects,
        index, somethingRemoved = false;

    for (let i = 0, length = arguments.length; i < length; i++) {
        index = objects.indexOf(arguments[i]);

        // only call onObjectRemoved if an object was actually removed
        if (index !== -1) {
            const object = arguments[i];
            if (object.uuid) {
                this.objectsMap.remove(object.uuid)
            }
            object.isDeleted = true;
            if (!object.isDynamic) {
                object.onVisibilityChanged();
                this.engine?.tileRenderer?.spatialIndex?.remove(object);
            }
            this.dirtyObjects?.remove(object);
            somethingRemoved = true;
            objects.splice(index, 1);
            this._onObjectRemoved && this._onObjectRemoved(object);
            this?.indexer?.objectRemoved();
        }
    }

    this.renderOnAddRemove && somethingRemoved && this.requestRenderAll();
    return this;
}


/**
 * Moves object to the desired index in the object list.
 * @override
 * @param {fabric.Object} object The object instance.
 * @param {number }index The index that object will be moved.
 */
export function moveTo (object, index) {
    fabric.util.removeFromArray(this._objects, object);
    this._objects.splice(index, 0, object);
    return this.renderOnAddRemove && this.requestRenderAll();
}