import { createSlice } from '@reduxjs/toolkit';
import { LEFT_DRAWER_TABS } from '../../../helpers/Constant';

const initialState = {
    activeTab: LEFT_DRAWER_TABS.ACTIVITIES
}

const leftDrawerReducer = createSlice({
    name: 'leftDrawer',
    initialState,
    reducers: {
        setActiveTab: (state, action) => {
            state.activeTab = action.payload;
        }
    }
});


export default leftDrawerReducer.reducer;