import React from 'react';

const Restore = () => (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.5 9.49902V12.499H1.5" stroke="#6200EA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.5 6.49902V3.49902H16.5" stroke="#6200EA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.688 3.49658C15.8202 5.71799 16.1017 9.13051 14.3623 11.6713C12.6229 14.212 9.33962 15.1841 6.49731 13.9999" stroke="#6200EA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.31137 12.5008C2.18387 10.2784 1.90522 6.86906 3.64362 4.33069C5.38201 1.79231 8.66156 0.819791 11.5027 2.00014" stroke="#6200EA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

export default Restore;