import { wrapText } from '../TextWrapHelpers';

const _wrapLine  = function(_line, lineIndex, desiredWidth) {
    if (lineIndex === 0) {
        this.lineLength = {};
        this.lineIndices = {};
    }

    // Calculating the total actual lines by now.
    const totalLines = 
        Object.entries(this.lineLength)
            .filter((o) => (parseInt(o[0]) || 0) < lineIndex)
            .reduce((prev, curr) => {
                return prev + curr[1];
            }, 0);

    const { lines, lineIndices } = wrapText(_line, this.fontSize, desiredWidth, lineIndex, totalLines, this);
    if (!lines) return;
    
    const mapped = lines.map(i => i.split(''));
    this.wrapppedText = lines.join('\n');
    this.lineIndices = {...this.lineIndices, ...lineIndices}
    this.lineLength[lineIndex] = lines.length;
    return mapped;
};

export default _wrapLine;