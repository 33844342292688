import { fabric } from 'fabric';
import { expanseXHandler, expanseYHandler } from './ActionHandler';

const mouseDownHandler = (eventData, transform) => {
    const { target, corner } = transform;
    if (corner === 'expanseLeft') {
        target.set({
            left: target.left + target.width,
            originX: 'right',
        });
        target.setCoords();
    } else if (corner === 'expanseTop') {
        target.set({
            top: target.top + target.height,
            originY: 'bottom',
        });
        target.setCoords();
    }
    target.valuesBeforeChange = {
        left: target.left,
        top: target.top,
        width: target.width,
        height: target.height,
    }

    const objects = target.getObjects();
    for (const obj of objects) {
        if (obj.type === 'curvedLine' && (obj.leftPolygon || obj.rightPolygon)) {
            target.removeWithUpdate(obj);
            if (!target.removedLinesDuringExpanding?.length) {
                target.removedLinesDuringExpanding = [];
            }
            target.removedLinesDuringExpanding.push(obj);
            continue;
        }
        obj.valuesBeforeChange = {
            left: obj.left,
            top: obj.top,
            width: obj.getScaledWidth(),
            height: obj.getScaledHeight(),
            aCoords: obj.aCoords
        }
    }
}

const mouseUpHandler = (eventData, transform) => {
    const { target } = transform;
    const { originX, originY } = target;
    
    // if originX or originY is changed, change the left or top of the target
    // originX and originY should be left, top for the activeSelection
    let isChanged = false;
    if (originX === 'right') {
        target.set({
            left: target.left - target.width,
            originX: 'left',
        });
        isChanged = true;
    }
    if (originY === 'bottom') {
        target.set({
            top: target.top - target.height,
            originY: 'top',
        });
        isChanged = true;
    }
    if (isChanged) {
        target.setCoords();
    }
}

export const expanseRight = new fabric.Control({
    x: 0.5,
    y: 0,
    render: renderExpanseControl,    
    mouseDownHandler,
    mouseUpHandler,
    cursorStyleHandler: fabric.controlsUtils.scaleCursorStyleHandler,
    actionHandler: expanseXHandler,
    actionName: 'expanse'
});


export const expanseLeft = new fabric.Control({
    x: -0.5,
    y: 0,
    render: renderExpanseControl,    
    actionHandler: expanseXHandler,
    mouseDownHandler,
    mouseUpHandler,
    cursorStyleHandler: fabric.controlsUtils.scaleCursorStyleHandler,
    actionName: 'expanse'
});

export const expanseTop = new fabric.Control({
    x: 0,
    y: -0.5,
    render: renderExpanseControl,    
    mouseDownHandler,
    mouseUpHandler,
    cursorStyleHandler: fabric.controlsUtils.scaleCursorStyleHandler,
    actionHandler: expanseYHandler,
    actionName: 'expanse'
});

export const expanseBottom = new fabric.Control({
    x: 0,
    y: 0.5,
    render: renderExpanseControl,    
    mouseDownHandler,
    mouseUpHandler,
    cursorStyleHandler: fabric.controlsUtils.scaleCursorStyleHandler,
    actionHandler: expanseYHandler,
    actionName: 'expanse'
});


/**
 * @param ctx
 * @param left
 * @param top
 * @param styleOverride
 * @param fabricObject
 */
function renderExpanseControl(ctx, left, top, styleOverride, fabricObject) {
    styleOverride = styleOverride || {};
    let xSize = this.sizeX || styleOverride.cornerSize || fabricObject.cornerSize,
        ySize = this.sizeY || styleOverride.cornerSize || fabricObject.cornerSize,
        transparentCorners = typeof styleOverride.transparentCorners !== 'undefined' ?
            styleOverride.transparentCorners : fabricObject.transparentCorners,
        methodName = transparentCorners ? 'stroke' : 'fill',
        stroke = !transparentCorners && (styleOverride.cornerStrokeColor || fabricObject.cornerStrokeColor),
        myLeft = left,
        myTop = top, size;
    ctx.save();
    ctx.fillStyle = styleOverride.cornerColor || fabricObject.cornerColor;
    ctx.strokeStyle = styleOverride.cornerStrokeColor || fabricObject.cornerStrokeColor;

    // for debugging:
    // ctx.fillStyle = 'red';
    // ctx.strokeStyle = 'pink';

    // as soon as fabric react v5, remove ie11, use proper ellipse code.
    if (xSize > ySize) {
        size = xSize;
        ctx.scale(1.0, ySize / xSize);
        myTop = top * xSize / ySize;
    }
    else if (ySize > xSize) {
        size = ySize;
        ctx.scale(xSize / ySize, 1.0);
        myLeft = left * ySize / xSize;
    }
    else {
        size = xSize;
    }
    // this is still wrong
    ctx.lineWidth = 1;
    ctx.beginPath();
    ctx.arc(myLeft, myTop, size / 2, 0, 2 * Math.PI, false);
    ctx[methodName]();
    if (stroke) {
        ctx.stroke();
    }
    ctx.restore();
}