import {fabric} from 'fabric';

/**
 * @private
 * @param {Object} target
 */
export function _createGroup(target) {
    let groupObjects = [this._activeObject, target];
    this._activeObject.isEditing && this._activeObject.exitEditing();
    return new fabric.ActiveSelection(groupObjects, {
        canvas: this
    });
}