import axios  from 'axios';
import {environment} from '../environment.js';
import {  loggedInUser, getAuthToken } from '../services/AuthService';
import { showUnauthorizedToastMessage } from './CommonFunctions.js';
import { AUTH_VALIDATION_MESSAGE } from './Constant.js';

axios.interceptors.request.use(
    request => {
        let apiController = request?.url?.split(`${environment.REACT_APP_API_ENDPOINT}/`)?.[1];

        if (apiController?.startsWith('enterprise')) {
            request.headers['auth-token'] = environment.ENTERPRISE_AUTH_TOKEN;
        }
        else {
            request.headers['auth-token'] = environment.REACT_APP_AUTH_TOKEN;
        }

        if (/whiteboard|teamBoard|bmeetBoard|enterprise/.test(window.location.pathname) && getAuthToken()) {
            request.headers['uuid'] = getAuthToken()
        }
        else{
            if (loggedInUser()) {
                request.headers['uuid'] = loggedInUser().authToken;
            }
        }
        return request;
    },
    error => Promise.reject(error)
);

axios.interceptors.response.use(
    response => {
        return response.data;
    },
    error => {
        const errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if ([401, 403].includes(error?.response?.status) && errorMessage) {
            if (error?.response?.status === 403) {
                const shouldDisplayCTA = errorMessage !== AUTH_VALIDATION_MESSAGE.LOGIN_IS_REQUIRED;
                showUnauthorizedToastMessage(errorMessage, true, shouldDisplayCTA);
            }
        }
        return Promise.reject(error);
    }
);