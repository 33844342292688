/**
 * Manage the synthetic in/out events for the fabric objects on the canvas
 * We override this function to handle the mouseOver and mouseOut events on the curved lines.
 * @override
 * @param {Fabric.Object} target - The target where the target from the supported events.
 * @param {Event} e - Event object fired.
 * @param {object} config - Configuration for the function to work.
 * @param {string} config.targetName - Property on the canvas where the old target is stored.
 * @param {string} [config.canvasEvtOut] - Name of the event to fire at canvas level for out.
 * @param {string} config.evtOut - Name of the event to fire for out.
 * @param {string} [config.canvasEvtIn] Name of the event to fire at canvas level for in.
 * @param {string} config.evtIn Name of the event to fire for in.
 */
export default function fireSyntheticInOutEvents(target, e, config) {
    var inOpt, outOpt, oldTarget = config.oldTarget, outFires, inFires,
        targetChanged = oldTarget !== target, canvasEvtIn = config.canvasEvtIn, canvasEvtOut = config.canvasEvtOut;
    if (targetChanged) {
        inOpt = { e: e, target: target, previousTarget: oldTarget };
        outOpt = { e: e, target: oldTarget, nextTarget: target };
    }
    inFires = target && targetChanged;
    outFires = oldTarget && targetChanged;
    // override starts here
    if (outFires) {
        canvasEvtOut && this.fire(canvasEvtOut, outOpt);
        oldTarget.fire(config.evtOut, outOpt);

        // if the old target is a curved line, fire the mouseOut event
        if (oldTarget?.type === 'curvedLine' && oldTarget.flowchartProps && typeof oldTarget?.onMouseOut === 'function') {
            oldTarget.onMouseOut(e);
        }
    }
    if (inFires) {
        canvasEvtIn && this.fire(canvasEvtIn, inOpt);
        target.fire(config.evtIn, inOpt);

        // if the target is a curved line, fire the mouseOut event
        if (target?.type === 'curvedLine' && target.flowchartProps && typeof target?.onMouseOver === 'function') {
            target.onMouseOver(e);
        }
    }
}