import {EMITTER_TYPES, OBJECT_EVENTS} from '../Constant';

/**
 * Fires an event when the object is changed.
 * @param {OBJECT_EVENTS.ORDER_CHANGED | OBJECT_EVENTS.SHAPE_CHANGED | OBJECT_EVENTS.TRANSFORM_CHANGED} eventType The event that caused the change.
 */
export function onObjectChanged(eventType= OBJECT_EVENTS.SHAPE_CHANGED) {
    if (this.isDynamic) {
        return;
    }
    this?.canvas?.engine?.emitter?.fire(EMITTER_TYPES.OBJECT_MUTATED, {
        target: this,
        event: eventType
    })
}

/**
 * Fires shape changed event.
 */
export function onShapeChanged() {
    this.onObjectChanged(OBJECT_EVENTS.SHAPE_CHANGED);
}

/**
 * Fires transform changed event.
 */
export function onTransformChanged() {
    this.onObjectChanged(OBJECT_EVENTS.TRANSFORM_CHANGED);
}

/**
 * Fires order changed event.
 */
export function onOrderChanged() {
    this.onObjectChanged(OBJECT_EVENTS.ORDER_CHANGED);
}

/**
 * Fires visibility changed event.
 */
export function onVisibilityChanged() {
    this.onObjectChanged(OBJECT_EVENTS.VISIBILITY_CHANGED);
}
