import { fabric } from 'fabric';


/**
 * @param transform
 * @param originX
 * @param originY
 * @param x
 * @param y
 */
export function getLocalPoint(transform, originX, originY, x, y) {
    const target = transform.target,
        control = target.controls[transform.corner],
        zoom = target.canvas.getZoom(),
        padding = target.padding / zoom,
        localPoint = target.toLocalPoint(new fabric.Point(x, y), originX, originY);
    if (localPoint.x >= padding) {
        localPoint.x -= padding;
    }
    if (localPoint.x <= -padding) {
        localPoint.x += padding;
    }
    if (localPoint.y >= padding) {
        localPoint.y -= padding;
    }
    if (localPoint.y <= padding) {
        localPoint.y += padding;
    }
    localPoint.x -= control.offsetX;
    localPoint.y -= control.offsetY;
    return localPoint;
}


/**
 * @param transform
 */
export function isTransformCentered(transform) {
    return transform.originX === 'center' && transform.originY === 'center';
}

/**
 * @param actionHandler
 */
export function wrapWithFixedAnchor(actionHandler) {
    return function(eventData, transform, x, y) {
        const target = transform.target, centerPoint = target.getCenterPoint(),
            constraint = target.translateToOriginPoint(centerPoint, transform.originX, transform.originY),
            actionPerformed = actionHandler(eventData, transform, x, y);
        target.setPositionByOrigin(constraint, transform.originX, transform.originY);
        return actionPerformed;
    };
}

/**
 * @param eventName
 * @param options
 */
function fireEvent(eventName, options) {
    const target = options.transform.target,
        canvas = target.canvas,
        canvasOptions = fabric.util.object.clone(options);
    canvasOptions.target = target;
    canvas && canvas.fire('object:' + eventName, canvasOptions);
    target.fire(eventName, options);
}
  
/**
 * @param eventData
 * @param transform
 * @param x
 * @param y
 */
function commonEventInfo(eventData, transform, x, y) {
    return {
        e: eventData,
        transform: transform,
        pointer: {
            x: x,
            y: y,
        }
    };
}

/**
 * @param eventName
 * @param actionHandler
 */
export function wrapWithFireEvent(eventName, actionHandler) {
    return function(eventData, transform, x, y) {
        const actionPerformed = actionHandler(eventData, transform, x, y);
        if (actionPerformed) {
            fireEvent(eventName, commonEventInfo(eventData, transform, x, y));
        }
        return actionPerformed;
    };
}

const LEFT = 'left', TOP = 'top', RIGHT = 'right', BOTTOM = 'bottom', CENTER = 'center',
    opposite = {
        top: BOTTOM,
        bottom: TOP,
        left: RIGHT,
        right: LEFT,
        center: CENTER,
    },
    sign = (Math.sign || function(x) { return ((x > 0) - (x < 0)) || +x; });


/**
 * @param eventData
 * @param fabricObject
 */
function scaleIsProportional(eventData, fabricObject) {
    const canvas = fabricObject.canvas, uniScaleKey = canvas.uniScaleKey,
        uniformIsToggled = eventData[uniScaleKey];
    return (canvas.uniformScaling && !uniformIsToggled) ||
    (!canvas.uniformScaling && uniformIsToggled);
} 
/**
 * @param fabricObject
 * @param by
 * @param scaleProportionally
 */
function scalingIsForbidden(fabricObject, by, scaleProportionally) {
    const lockX = fabricObject.lockScalingX, lockY = fabricObject.lockScalingY;
    let isForbidden = false;
    if (lockX && lockY) {
        isForbidden = true;
    }
    if (!by && (lockX || lockY) && scaleProportionally) {
        isForbidden = true;
    }
    if ((lockX && by === 'x') || (lockY && by === 'y')) {
        isForbidden = true;
    }
    return isForbidden;
}

/**
 * @param eventData
 * @param transform
 * @param x
 * @param y
 * @param options
 */
export function scaleObject(eventData, transform, x, y, options) {
    options = options || {};
    let target = transform.target,
        lockScalingX = target.lockScalingX, lockScalingY = target.lockScalingY,
        by = options.by, newPoint, scaleX, scaleY, dim,
        scaleProportionally = scaleIsProportional(eventData, target),
        forbidScaling = scalingIsForbidden(target, by, scaleProportionally),
        signX, signY, gestureScale = transform.gestureScale;

    if (forbidScaling) {
        return false;
    }
    if (gestureScale) {
        scaleX = transform.scaleX * gestureScale;
        scaleY = transform.scaleY * gestureScale;
    }
    else {
        newPoint = getLocalPoint(transform, transform.originX, transform.originY, x, y);
        // use of sign: We use sign to detect change of direction of an action. sign usually change when
        // we cross the origin point with the mouse. So a scale flip for example. There is an issue when scaling
        // by center and scaling using one middle control ( default: mr, mt, ml, mb), the mouse movement can easily
        // cross many time the origin point and flip the object. so we need a way to filter out the noise.
        // This ternary here should be ok to filter out X scaling when we want Y only and vice versa.
        signX = by !== 'y' ? sign(newPoint.x) : 1;
        signY = by !== 'x' ? sign(newPoint.y) : 1;
        if (!transform.signX) {
            transform.signX = signX;
        }
        if (!transform.signY) {
            transform.signY = signY;
        }

        if (target.lockScalingFlip &&
        (transform.signX !== signX || transform.signY !== signY)
        ) {
            return false;
        }

        dim = target._getTransformedDimensions();
        // missing detection of flip and logic to switch the origin
        if (scaleProportionally && !by) {
        // uniform scaling
            const distance = Math.abs(newPoint.x) + Math.abs(newPoint.y),
                original = transform.original,
                originalDistance = Math.abs(dim.x * original.scaleX / target.scaleX) +
              Math.abs(dim.y * original.scaleY / target.scaleY),
                scale = distance / originalDistance;
            scaleX = original.scaleX * scale;
            scaleY = original.scaleY * scale;
        }
        else {
            scaleX = Math.abs(newPoint.x * target.scaleX / dim.x);
            scaleY = Math.abs(newPoint.y * target.scaleY / dim.y);
        }
        // if we are scaling by center, we need to double the scale
        if (isTransformCentered(transform)) {
            scaleX *= 2;
            scaleY *= 2;
        }
        if (transform.signX !== signX && by !== 'y') {
            transform.originX = opposite[transform.originX];
            scaleX *= -1;
            transform.signX = signX;
        }
        if (transform.signY !== signY && by !== 'x') {
            transform.originY = opposite[transform.originY];
            scaleY *= -1;
            transform.signY = signY;
        }
    }
    // minScale is taken are in the setter.
    const oldScaleX = target.scaleX, oldScaleY = target.scaleY;
    if (!by) {
        !lockScalingX && target.set('scaleX', scaleX);
        !lockScalingY && target.set('scaleY', scaleY);
    }
    else {
        // forbidden cases already handled on top here.
        by === 'x' && target.set('scaleX', scaleX);
        by === 'y' && target.set('scaleY', scaleY);
    }
    return oldScaleX !== target.scaleX || oldScaleY !== target.scaleY;
}

/**
 * Renders square control (filled with white and stroked with original control color).
 * @override
 */
export function renderSquareWhiteControl(ctx, left, top, styleOverride, fabricObject) {
    styleOverride = styleOverride || {};
    var xSize = this.sizeX || styleOverride.cornerSize || fabricObject.cornerSize,
        ySize = this.sizeY || styleOverride.cornerSize || fabricObject.cornerSize,
        transparentCorners = typeof styleOverride.transparentCorners !== 'undefined' ?
            styleOverride.transparentCorners : fabricObject.transparentCorners,
        methodName = transparentCorners ? 'stroke' : 'fill',
        stroke = !transparentCorners && (
            styleOverride.cornerStrokeColor || fabricObject.cornerStrokeColor
        ), xSizeBy2 = xSize / 2, ySizeBy2 = ySize / 2;
    ctx.save();
    ctx.fillStyle = styleOverride.cornerColor || fabricObject.cornerColor;
    ctx.strokeStyle = styleOverride.cornerStrokeColor || fabricObject.cornerStrokeColor;
    // this is still wrong
    ctx.lineWidth = 1;
    ctx.translate(left, top);
    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
    // this does not work, and fixed with ( && ) does not make sense.
    // to have real transparent corners we need the controls on upperCanvas
    // transparentCorners || ctx.clearRect(-xSizeBy2, -ySizeBy2, xSize, ySize);
    ctx[methodName + 'Rect'](-xSizeBy2, -ySizeBy2, xSize, ySize);
    if (stroke) {
        ctx.strokeRect(-xSizeBy2, -ySizeBy2, xSize, ySize);
    }

    ctx.fillStyle = 'white';
    ctx.fillRect(-xSizeBy2 + xSizeBy2 / 2, -ySizeBy2  + ySizeBy2 / 2, xSizeBy2, ySizeBy2);
    ctx.restore();
}