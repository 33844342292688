/**
 *
 */
const DownloadIcon = () => {
    return (
        <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.0007 1.125V11.125" stroke="#3C3E49" strokeWidth="1.5" strokeLinecap="round"
                strokeLinejoin="round"/>
            <path d="M7.25073 7.375L11.0007 11.125L14.7507 7.375" stroke="#3C3E49" strokeWidth="1.5"
                strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M20.3757 11.125V12.375C20.3757 13.7557 19.2564 14.875 17.8757 14.875H4.12573C2.74502 14.875 1.62573 13.7557 1.62573 12.375V11.125"
                stroke="#3C3E49" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    
    );
}

export default DownloadIcon;