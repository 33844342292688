import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { centerToObjectWithAnimation, clearHighlightForObject } from '../../../../helpers/FabricMethods';
import { isUserHasAccessToFeature } from '../../../../helpers/CommonFunctions';
import builderLogo from '../../../../assets/images/Builder_logo2.svg';

const FrameList = ({
    canvas,
    layoutStyle,
    searchKeyword,
    userAccess
}) => {
    const [frames, setFrames] = useState([]);
    const handleFrameClick = (frame) => {
        centerToObjectWithAnimation(frame.canvas, frame, {
            selectAfterAnimation: isUserHasAccessToFeature('objectanimation', userAccess),
            showToolbar: isUserHasAccessToFeature('subtoolbar', userAccess)
        });
    }
    const getSrc = useCallback(() => {
        return null;
    }, [canvas]);

    const objectAddedHandler = useCallback((e) => {
        const target = e.target;
        if (target.type === 'frame' && target.uuid) {
            target.srcImg = getSrc(target.uuid);
            setFrames(prevFrames => [...prevFrames, target]);
        } else if (target.attachedFrameId) {
            setFrames(prevFrames => prevFrames.map(frame => {
                if (frame.uuid === target.attachedFrameId) {
                    frame.srcImg = getSrc(frame.uuid);
                }
                return frame;
            }));
        }
    }, [getSrc]);

    const objectModifiedHandler = useCallback((e) => {
        const target = e.target;
        if (target.type === 'frame') {
            setFrames(prevFrames => prevFrames.map(frame => {
                if (frame.uuid === target.uuid) {
                    target.srcImg = getSrc(target.uuid);
                    return target;
                }
                return frame;
            }));
        } else if (target.attachedFrameId) {
            setFrames(prevFrames => prevFrames.map(frame => {
                if (frame.uuid === target.attachedFrameId) {
                    if (target.highlightActivated) clearHighlightForObject(target.canvas, target)
                    frame.srcImg = getSrc(target.attachedFrameId);
                    return frame
                }
                return frame;
            }));
        }
    }, [getSrc]);

    const objectDeletedHandler = useCallback((e) => {
        const target = e.target;
        if (target.type === 'frame') {
            setFrames(prevFrames => prevFrames.filter(frame => frame.uuid !== target.uuid));
        } else if (target.attachedFrameId) {
            setFrames(prevFrames => prevFrames.map(frame => {
                if (frame.uuid === target.attachedFrameId) {
                    frame.srcImg = getSrc(frame.uuid);
                    frame.attachments = frame.attachments.filter(attachment => attachment !== target.uuid);
                }
                return frame;
            }));
        }
    }, [getSrc]);

    const objDetachHandler = useCallback((e) => {
        const { target, from } = e;
        if (target && from) {
            setFrames(prevFrames => prevFrames.map(frame => {
                if (frame.uuid === from) {
                    frame.srcImg = getSrc(frame.uuid);
                    frame.attachments = frame.attachments.filter(attachment => attachment !== target.uuid);
                }
                return frame;
            }));
        }
    }, [getSrc]);

    const objectModifiedWithEvent = useCallback((e) => {
        const target = e.target;
        if (target.type === 'frame') {
            setFrames(prevFrames => prevFrames.map(frame => {
                if (frame.uuid === target.uuid) {
                    target.srcImg = getSrc(target.uuid);
                    return target;
                }
                return frame;
            }));
        }
    }, [getSrc]);

    const updateFrameHandler = useCallback((frameUuid) => {
        setFrames(prevFrames => prevFrames.map(frame => {
            if (frame.uuid === frameUuid) {
                frame.srcImg = getSrc(frame.uuid);
            }
            return frame;
        }));
    }, [getSrc]);


    useEffect(() => {
        if (canvas) {
            const localFrames = canvas.getObjects().filter(obj => obj.type === 'frame');
            setFrames(() => localFrames.map(frame => {
                frame.srcImg = getSrc(frame.uuid);
                return frame;
            }));

            canvas.on('object:added', objectAddedHandler);
            canvas.on('object:modified', objectModifiedHandler);
            canvas.on('object:removed', objectDeletedHandler);
            canvas.on('detached_obj', objDetachHandler);
            canvas.on('modified-with-event', objectModifiedWithEvent);
            canvas.on('update-frame', updateFrameHandler);
        }

        return () => {
            if (canvas) {
                // clean listeners
                canvas.off('object:added', objectAddedHandler);
                canvas.off('object:modified', objectModifiedHandler);
                canvas.off('object:removed', objectDeletedHandler);
                canvas.off('detached_obj', objDetachHandler);
                canvas.off('modified-with-event', objectModifiedWithEvent);
                canvas.off('update-frame', updateFrameHandler);
            }
        }
    }, [
        canvas,
        objectAddedHandler,
        objectModifiedHandler,
        objectDeletedHandler,
        objDetachHandler,
        objectModifiedWithEvent,
        updateFrameHandler,
        getSrc
    ]);
    let filteredFrames = frames;
    try {
        if (searchKeyword && typeof searchKeyword === 'string' && searchKeyword.length > 0) {
            filteredFrames = filteredFrames.filter(frame => {
                if (frame?.getText()?.toLowerCase()?.includes(searchKeyword.toLowerCase())) {
                    return true;
                }
                return false;
            });
        }
    } catch (e) {
        console.error(e);
    }

    const renderImage = (name, src, position) => {
        if (layoutStyle === 'grid') {
            if (src && src.includes('image'))
                return <img alt={name} className="frameItem__img" src={src} />;
            else
                return (
                    <div className="frameItem__logo">
                        <img alt={name} src={builderLogo} />
                    </div>
                );
        } else return <div className="frameItem__counter">{position + 1}.</div>;
    };

    return (
        <div className='framesTab__list'>
            <ul className={`framesTab__list--list layout-${layoutStyle}`}>
                {
                    filteredFrames.map((frame, index) => (
                        <li 
                            className='frameItem' 
                            key={frame.uuid} 
                            onClick={() => handleFrameClick(frame)}>
                            {renderImage(frame.name, frame.srcImg, index)}
                            <div className='frameItem__title'>{frame.getText()}</div>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

FrameList.propTypes = {
    canvas: PropTypes.object.isRequired,
    layoutStyle: PropTypes.oneOf(['grid', 'column']).isRequired,
    searchKeyword: PropTypes.string,
    userAccess: PropTypes.oneOf(['view', 'comment', 'edit', 'removeAccess', 'NOT_ALLOWED']).isRequired
}

export default FrameList;