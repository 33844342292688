/**
 * Loading skeleton for the comment wrapper.
 */
const CommentWrapperSkeleton = () => {
    return (
        <>
            <header className="commentToolbar__skeleton">
                <div className="commentToolbar__skeleton--colors">
                    <div className="skeleton" />
                    <div className="skeleton" />
                    <div className="skeleton" />
                    <div className="skeleton" />
                    <div className="skeleton" />
                    <div className="skeleton" />
                </div>
                <div className="commentToolbar__skeleton--options">
                    <div className="skeleton" />
                </div>
            </header>
            <hr className="divider" />
            <main className="commentDetail__skeleton">
                <div className="commentItem__skeleton">
                    <div className="commentItem__skeleton--header">
                        <div className="skeleton" style={{ width: '45%', height: '10px' }} />
                        <div className="skeleton" style={{ width: '25%', height: '10px' }} />
                    </div>
                    <div className="commentItem__skeleton--comment">
                        <div
                            className="skeleton"
                            style={{ width: '100%', height: '10px', marginBottom: '6px' }}
                        />
                        <div
                            className="skeleton"
                            style={{ width: '70%', height: '10px', marginBottom: '6px' }}
                        />
                        <div className="skeleton" style={{ width: '55%', height: '10px' }} />
                    </div>
                </div>
                <div className="commentAddForm__skeleton">
                    <div className="commentInput__skeleton">
                        <div className="skeleton" style={{ width: '100%', height: '50px' }} />
                    </div>
                </div>
            </main>
        </>
    )
}

export default CommentWrapperSkeleton;