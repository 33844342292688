import React from 'react';
import PropTypes from 'prop-types';

const Earth3 = ({ width, height }) => (
    <svg
        fill="none"
        height={height}
        viewBox="0 0 24 24"
        width={width}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            clipRule="evenodd"
            d="M12 23.25C18.2132 23.25 23.25 18.2132 23.25 12C23.25 5.7868 18.2132 0.75 12 0.75C5.7868 0.75 0.75 5.7868 0.75 12C0.75 18.2132 5.7868 23.25 12 23.25Z"
            fillRule="evenodd"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
        />
        <path
            d="MstrokeWidthL9.289 22.9211C7.767 20.6891 6.75 16.6331 6.75 12.0001C6.75 7.3671 7.767 3.3111 9.289 1.0791"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
        />
        <path
            d="M0.75 12H23.25"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
        />
        <path
            d="M2.48242 18H21.5184"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
        />
        <path
            d="M2.48242 6H21.5184"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
        />
        <path
            d="M14.7109 1.0791C16.2329 3.3111 17.2499 7.3671 17.2499 12.0001C17.2499 16.6331 16.2329 20.6891 14.7109 22.9211"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
        />
    </svg>
);

Earth3.propTypes = {
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

Earth3.defaultProps = {
    width: 24,
    height: 24
}

export default Earth3;
