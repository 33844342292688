import {compressData, decompressData} from '../../helpers/OptimizationUtils';
import { v4 as uuidv4 } from 'uuid';
import {toast} from 'react-toastify';

export class CanvasLockManager {
    constructor() {
        this.currentlyEditingShapes = new Set();
        this.toastId = null;
    }
    
    setSocket(socket) {
        this.socket = socket;
    }
    
    lockObjects(objects, lockedBy) {
        for (const object of objects) {
            if (!object) {
                continue
            }
            object.onShapeChanged();
            object.collabLocked = true;
            object.collabLockedBy = lockedBy;
        }
    }
    
    lockObjectsByUuid(canvas, uuids, lockedBy) {
        const canvasObjects = canvas.getObjects();
        const objects = uuids.map(uuid => {
            const object = canvas.objectsMap.get(uuid)
            if (object) {
                return object
            }
            return canvasObjects.find(obj => obj?.uuid === uuid)
        });
        this.lockObjects(objects, lockedBy);
    }

    unlockObjects(objects) {
        for (const object of objects) {
            if (!object) {
                continue
            }
            object.onShapeChanged();
            object.collabLocked = false;
            object.collabLockedBy = null;
        }
    }
    
    unlockObjectsByUuid(canvas, uuids) {
        const canvasObjects = canvas.getObjects();
        const objects = uuids.map(uuid => {
            const object = canvas.objectsMap.get(uuid)
            if (object) {
                return object
            }
            
            return canvasObjects.find(obj => obj?.uuid === uuid)
        });
        this.unlockObjects(objects);
    }
    
    canEditShape(shapeUuid) {
        return !this.currentlyEditingShapes.has(shapeUuid);
    }
    
    startEditing(shapes, pageId, callback) {
        const processId = uuidv4();
        const shapeUuids = []

        for (const shape of shapes) {
            if (!shape.uuid) {
                continue
            }
            if (shape.type === 'lasso') {
                continue
            }
            if (!this.canEditShape(shape.uuid)) {
                continue
            }
            this.currentlyEditingShapes.add(shape.uuid);
            shapeUuids.push(shape.uuid);
        }
        if (!shapeUuids.length) {
            console.error('No shapes to edit')
            return {
                shapeUuids,
                processId
            }
        }

        const currentTime = Date.now()
        const sendingData = {
            uuid: processId,
            status: 'freeze',
            shapes: shapeUuids,
            pageId,
            timestamp: currentTime,
            hexTimestamp: `0x${currentTime.toString(16)}`
        }
        console.log(sendingData)
        const compressed = compressData(sendingData)
        
        
        this.socket.emit('collabLock', compressed, (data) => {
            const failedShapes = []
            const successShapes = []
            const emitData = decompressData(data.emitData)

            for (const emitShape of emitData.shapes) {
                if (emitShape.success) {
                    successShapes.push(emitShape.shapeUUID)
                } else {
                    failedShapes.push(emitShape.shapeUUID)
                }
            }

            if (failedShapes.length > 0) {
                if (!toast.isActive(this.toastId)) {
                    this.toastId = toast.warn('Some shapes are already being edited by someone else', {
                        className: 'wb_toast'
                    })
                }
            }

            callback({
                successShapes,
                failedShapes,
                processId
            })
        })
        
        return {
            shapeUuids,
            processId
        };
    }
    
    stopEditing(shapes, pageId, processId, options = {}) {
        const shapeUuids = []
        for (const shape of shapes) {
            this.currentlyEditingShapes.delete(shape.uuid);
            shapeUuids.push(shape.uuid);
        }

        if (!options.avoidEmitting) {
            const currentTime = Date.now()
            const sendingData = {
                uuid: processId,
                status: 'unfreeze',
                shapes: shapeUuids,
                pageId,
                timestamp: currentTime,
                hexTimestamp: `0x${currentTime.toString(16)}`
            }
            console.log(sendingData)
            const compressed = compressData(sendingData)
            this.socket.emit('collabLock', compressed)
        }
    }
}