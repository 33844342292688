import React from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import './Tooltip.scss';

const Tooltip = ({
    text,
    position,
    usePortal,
    dimensions = {}
}) => {

    if (usePortal) {
        return createPortal(
            <span
                className="customTooltip __hovered"
                data-position={position}
                style={{
                    left: dimensions?.left,
                    top: dimensions?.top,
                }}
            >
                {text}
            </span>,
            document.body
        );
    }

    return (
        <span className="customTooltip" data-position={position}>{text}</span>
    )
};

Tooltip.propTypes = {
    text: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    position: PropTypes.oneOf([
        'top',
        'bottom',
        'left',
        'right'
    ]),
    usePortal: PropTypes.bool,
    dimensions: PropTypes.shape({
        left: PropTypes.number,
        top: PropTypes.number
    })
}

Tooltip.defaultProps = {
    position: 'bottom',
    usePortal: false
}

export default Tooltip;