import {useEffect, useRef} from 'react';
import { fabric } from 'fabric';

/**
 * Listens visibility changes of the tab.
 * @param {fabric.Canvas} canvas The canvas instance to reset.
 */
export default function useVisibilityReset(canvas) {
    const hiddenRef = useRef();
    const visibilityChangeListener = (event) => {
        try {
            if (document.visibilityState === 'hidden') {
                hiddenRef.current = event
            } else {
                canvas.engine.tileRenderer.hardReset()  // reset tile caches, mutations and r tree data
                canvas.engine.tileRenderer.addObjectsToDirtyList();  // to add them to r tree again
                canvas.renderAll() // render the canvas
            }
        } catch (err) {
            console.error('error while listening visibility change', err)
        }
    }
    useEffect(() => {
        if (canvas) {
            document.addEventListener('visibilitychange', visibilityChangeListener)
            
            return () => {
                document.removeEventListener('visibilitychange', visibilityChangeListener)
            }
        }
    }, [canvas]);
}
