
/**
 * @override
 * @param {CanvasRenderingContext2D} ctx
 */
export default function _clearTextArea(ctx) {
    const input = this.tableObject ? this.tableObject : this;
    // we add 4 pixel, to be sure to do not leave any pixel out
    let width = input.width + 4, height = input.height + 4;
    ctx.clearRect(-width / 2, -height / 2, width, height);
}