export const expanseControls = [
    'expanseLeft',
    'expanseRight',
    'expanseTop',
    'expanseBottom',
];

export const middleControls = [
    'ml',
    'mr',
    'mt',
    'mb'
]

/**
 * Creates an object for object controls visibility with given keys, and a boolean as a values.
 * @param visibilityList - List of control keys.
 * @param {boolean} shouldShow - Value of visibility of controls.
 * @returns {*}
 */
const createControlsVisibility = (visibilityList, shouldShow) =>
    visibilityList.reduce(
        (acc, control) => ({ ...acc, [control]: shouldShow }),
        {}
    );


/**
 * Set visibilities of expanse controls.
 * @param {fabric.Object} target
 * @param {boolean} shouldShow
 */
export const setExpanseControlsVisibility = (target, shouldShow = false) => {
    target.setControlsVisibility(createControlsVisibility(expanseControls, shouldShow));
}

/**
 * Set visibilities of middle controls.
 * @param {fabric.Object} target
 * @param {boolean} shouldShow
 */
export const setMiddleControlsVisibility = (target, shouldShow = false) => {
    target.setControlsVisibility(createControlsVisibility(middleControls, shouldShow));
}


/**
 * @param target
 */
export default function customizeControlForShape(target) {
    if (target.type === 'activeSelection') {
        setExpanseControlsVisibility(target, true);
    } else {
        setExpanseControlsVisibility(target, false);
        if (target.type === 'optimizedImage') {
            setMiddleControlsVisibility(target, false)
        }
    }
}
