import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './Members.scss';
import ProfileList from '../profileList/ProfileList';
import clsx from 'clsx';
import { createInitialFromUsername } from '../../helpers/CommonFunctions';
const Members = ({
    users,
    useProfileList,
    useTooltip
}) => {
    const [showProfileList, setShowProfileList] = useState(false);
    const clickedProfileRef = useRef();

    if (!Array.isArray(users) || (Array.isArray(users) && users.length === 0)) {
        return null;
    }

    const onUserClick = (e) => {
        if (!useProfileList) { return; }
        clickedProfileRef.current = e.target;
        setShowProfileList(oldVal => !oldVal)
    }
    const colorsAvatar = ['#7C4DFF' , '#FFAB00' , '#00C853'];
    return (
        <div className="members" data-testid="members">
            {users.slice(0,3).map((user) => {
                const userName = createInitialFromUsername(user.name);
                const backgroundColor = colorsAvatar[users.indexOf(user)];
                return (
                    <div 
                        className={clsx('oval', { oval_not_hover: !useTooltip }) }
                        data-testid="memberItem"
                        key={`${user.info.userId}_${Math.random()}`}
                        onClick={onUserClick}
                        style={{ backgroundColor: backgroundColor }}
                    >
                        <span className="oval__name" style={{ color: 'white' }}>{userName}</span>
                        <span className="tooltiptext">{user.name}</span>
                    </div>
                )
            })}

            {users.length > 3 ? <div
                className={clsx('oval', 'more', { oval_not_hover: !useTooltip })}
                data-testid="usersCount"
                key="more"
                onClick={onUserClick}
            >
                +{users.length - 3}
            </div> : null}

            {(useProfileList && showProfileList) ? <ProfileList
                clickedTarget={clickedProfileRef.current}
                closeProfileList={() => setShowProfileList(false)}
                users={users}
            /> : null}
        </div>
    )
};

Members.propTypes = {
    users: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        info: PropTypes.shape({
            userId: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
            ]),
            colorCode: PropTypes.string
        }),
        id: PropTypes.number
    })),
    useProfileList: PropTypes.bool,
    useTooltip: PropTypes.bool
}

Members.defaultProps = {
    useProfileList: true,
    useTooltip: true
}

export default Members;