import React from 'react';
import { useLayer, useHover, Arrow } from 'react-laag';
import { motion, AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';

import './TooltipWrapper.scss';
import clsx from 'clsx';

const isReactText = (children) =>
    ['string', 'number'].includes(typeof children);

const Tooltip = ({
    text,
    component: Component,
    children,
    placement,
    className,
    triggerOffset,
    possiblePlacements
}) => {
    const [isOver, hoverProps, close] = useHover({
        hideOnScroll: true,
    });
    
    const { arrowProps, triggerProps, layerProps, renderLayer } = useLayer({
        isOpen: isOver,
        placement,
        possiblePlacements,
        triggerOffset: triggerOffset ?? 4,
        auto: true,
        onDisappear: () => close(),
    });
    
    let trigger;
    
    if (isReactText(children)) {
        trigger = (
            <Component {...triggerProps} {...hoverProps}>
                {children}
            </Component>
        );
    } else {
        // In case of an react-element, we need to clone it in order to attach our own props
        trigger = React.cloneElement(children, {
            ...triggerProps,
            ...hoverProps,
        });
    }
    
    return (
        <>
            {trigger}
            {renderLayer(
                <AnimatePresence>
                    {isOver ? <motion.span
                        animate={{ opacity: 1 }}
                        className={clsx('tooltip', className)}
                        exit={{ opacity: 0 }}
                        initial={{ opacity: 0 }}
                        transition={{ ease: [0.4, 0, 0.2, 1] }}
                        {...layerProps}
                    >
                        {text}
                        <Arrow {...arrowProps} roundness={0} size={8} />
                    </motion.span> : null}
                </AnimatePresence>
            )}
        </>
    );
};

Tooltip.propTypes = {
    text: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    component: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.number
    ]),
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    placement: PropTypes.oneOf([
        'bottom-start',
        'bottom-end',
        'bottom-center',
        'top-start',
        'top-center',
        'top-end',
        'left-end',
        'left-center',
        'left-start',
        'right-end',
        'right-center',
        'right-start',
        'center'
    ]),
    className: PropTypes.string,
    possiblePlacements:PropTypes.arrayOf(PropTypes.string),
    triggerOffset: PropTypes.number
}

Tooltip.defaultProps = {
    component: 'div',
};

export default Tooltip;
