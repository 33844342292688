import React from 'react';
import PropTypes from 'prop-types';


class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
  
    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }
  
    render() {
        const { hasError } = this.state;
        const { fallback, children } = this.props;

        if (hasError) {
        // You can render any custom fallback UI
            return fallback;
        }
  
        return children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    fallback: PropTypes.any
}

export default ErrorBoundary;