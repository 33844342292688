import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param {object} props
 * @param props.color
 * @param props.width
 * @param props.height
 */
const LassoIcon = ({ color, width, height }) => {
    return (
        <svg
            fill="none"
            height={height ?? '50'}
            viewBox="0 0 16 16"
            width={width ?? '50'}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.71195 14.5254C6.4468 14.5254 6.87994 13.2991 6.87994 12.6854C6.87994 12.0739 6.44566 10.8477 4.71195 10.8477C2.97709 10.8477 2.54395 12.0739 2.54395 12.6865C2.54395 13.2991 2.97823 14.5254 4.71195 14.5254Z"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.99986 11.2073C2.057 11.2073 0.571289 7.70899 0.571289 5.95927C0.571289 4.20956 2.057 0.708984 7.99986 0.708984C13.9427 0.708984 15.4284 4.20841 15.4284 5.95813C15.4284 7.7067 13.9427 11.2073 7.99986 11.2073Z"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.38379 11.4648C7.53807 12.7757 8.30722 13.65 8.30379 15.2877"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

LassoIcon.propTypes = {
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    color: PropTypes.string,
}

LassoIcon.defaultProps = {
    width: 24,
    height: 24
}

export default LassoIcon;
