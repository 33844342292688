export const calculateContentHeight = ( textarea, scanAmount ) => {
    let origHeight = textarea.style.height,
        height = textarea.offsetHeight,
        scrollHeight = textarea.scrollHeight;

    if ( height >= scrollHeight ) {
        textarea.style.height = (height + scanAmount) + 'px';
        if ( scrollHeight < textarea.scrollHeight ) {
            while (textarea.offsetHeight >= textarea.scrollHeight) {
                height -= scanAmount;
                textarea.style.height = height+'px';
            }
            while (textarea.offsetHeight < textarea.scrollHeight) {
                textarea.style.height = (height++)+'px';
            }
            textarea.style.height = origHeight;
            return height;
        }
    } else {
        return scrollHeight;
    }
}
export const getLineHeight = (textarea) => {
    let style = (window.getComputedStyle) ?
            window.getComputedStyle(textarea) : textarea.currentStyle,
        taLineHeight = parseInt(style.lineHeight, 10);
    return taLineHeight
};

export const changeTextareaHeight = (textarea) => {
    let lineHeight = getLineHeight(textarea);
    let numberLines = Math.ceil(calculateContentHeight(textarea, lineHeight) / lineHeight);
    textarea.style.height = `${(numberLines - 1) * lineHeight}px`
}

export const updateAllPropertiesOfTextboxObject = (o) => {
    let newObject = {};
    for (const [key, value] of Object.entries(o)) {
        if (!['mouseMoveHandler'].includes(key)) {
            newObject[key] = value;
        }
    }
    return newObject;
}