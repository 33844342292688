import React, { useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types'
import { TOOLBAR_MENU } from '../../../helpers/Constant';
import AlignTextMenu from '../menus/AlignItemMenu';

const AlignmentItem = ({
    isDisabled,
    onClick,
    showMenu,
    toggleMenu,
    getTextboxAlignIcon,
    getActiveTextAlign
}) => {
    const textAlignmentTooltipRef = useRef();

    const menuOnClickHandler = () => {
        if (isDisabled) return;
        toggleMenu(TOOLBAR_MENU.TEXT_ALIGN);
    }
    
    return (
        <div className={clsx('contentJustifyDDL', { disabled: isDisabled })} onClick={menuOnClickHandler}>
            <em className={getTextboxAlignIcon()} />
            <em className="icon-arrow-down" />

            {showMenu ? (
                <AlignTextMenu
                    alignmentTooltipRef={textAlignmentTooltipRef}
                    onClickHandler={(align) => onClick(align)}
                    textAlign={getActiveTextAlign()}
                />
            ) : ''}

            <span className={clsx('tooltipText tooltipText--longContent', {
                'onBottom': showMenu
            })} ref={textAlignmentTooltipRef}>Alignment</span>
        </div>
    )
};

AlignmentItem.propTypes = {
    isDisabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    showMenu: PropTypes.bool,
    toggleMenu: PropTypes.func.isRequired,
    getTextboxAlignIcon: PropTypes.func.isRequired,
    getActiveTextAlign: PropTypes.func.isRequired
}

export default AlignmentItem;