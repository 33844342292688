import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { classNames } from '../../helpers/Dom';
import builderLogo from '../../assets/images/Builder_logo2.svg';
import CollapseIcon from '../../assets/images/collapse-icon2.svg';
import LogoutIcon from '../../assets/images/btn_logout.svg';
import NewLoginLogo from '../../assets/images/new_builder_logo_login.svg';
import MENU_ITEMS from './constants/MenuItems';
import AngleRight from '../svgIcons/AngleRight';
import SidebarSVG from '../svgIcons/SidebarSVG';
import clsx from 'clsx';
import { createInitialFromUsername } from '../../helpers/CommonFunctions';
/**
 * @param {object} props
 * @param props.activePage
 * @param props.onActivePageChange
 * @param props.projects
 * @param props.activeProjectId
 * @param props.logout
 * @param props.userName
 */
const BoardListingSidebar = ({
    activePage,
    onActivePageChange,
    projects,
    activeProjectId,
    logout,
    userName,
}) => {
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

    const toggleSidebarStatus = () => {
        setIsSidebarCollapsed((state) => !state);
    }
    const [windowSize, setWindowSize] = useState(getWindowSize());
    /**
     *
     */
    function getWindowSize() {
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
    }
    useEffect(() => {
        /**
         *
         */
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    return (
        <div className={classNames({
            whiteboardSidebar: true,
            '__is-collapsed': isSidebarCollapsed
        })} data-testid="whiteboardSidebar">
            <div style={{ position: 'absolute', right: -47 }}>
                <SidebarSVG height={windowSize.innerHeight} />
            </div>
            <div className="sidebarInnerWrapper">
                <div className="logoWrapper">
                    {isSidebarCollapsed ? <img alt="Builder.ai Logo" src={builderLogo} style={{height: '40px', width: '28px', marginTop: '12px'}} /> : <img alt="Builder.ai Logo" src={NewLoginLogo} />}
                </div>
                <div className="navigationMenuWrapper">
                    <div className="navigationMenu">
                        {Object.entries(MENU_ITEMS).map(([id, item]) => (
                            <div className="navigationBtnWrapper" key={id}>
                                <button
                                    type="button"
                                    className={classNames({
                                        navigationBtn: true,
                                        active: activePage === id,
                                        hasSubmenu: id === 'PROJECTS',
                                        [id]: true
                                    })}
                                    data-testid="navigationItem"
                                    disabled={item.isDisabled === true}
                                    onClick={() => onActivePageChange(id)}
                                >
                                    <span className={clsx('icon', {isCollapse: isSidebarCollapsed})}>
                                        {item.icon()}
                                    </span>
                                    
                                    <span className={clsx({
                                        name: true,
                                        active: activePage === id,
                                    })}>{item.name}</span>
                                    
                                    {id === 'PROJECTS' ? <div className="upDownIcon">
                                        <AngleRight/>
                                    </div> : null}
                                </button>
                                
                                {(id === 'PROJECTS' && activePage === id) ?
                                    <div className="submenu" data-testid="projectsSubmenu">
                                        {Array.isArray(projects) ? projects.map((project) => (
                                            <button
                                                type="button"
                                                className={classNames({
                                                    submenuItem: true,
                                                    __activeProject: project.id === activeProjectId
                                                })}
                                                key={project.id}
                                            >
                                                <AngleRight/>
                                                <span>{project.name}</span>
                                            </button>
                                        )) : null}
                                        
                                        <button className="addProjectBtn" type="button">
                                            <em className="icon-plus"/>
                                            Add Project
                                        </button>
                                    </div> : null}
                            
                            </div>
                        ))}
                    </div>
                    <div className="logoutBtn">
                        <div className={clsx('avatar', {isCollapse: isSidebarCollapsed})}>
                            {createInitialFromUsername(userName)}
                        </div>
                        <div className='userName'>
                            {userName}
                        </div>
                        <img alt="Logout" className="iconLogout" data-testid="logoutBtn" onClick={logout}
                            src={LogoutIcon}/>
                    </div>
                </div>
            </div>
            
            
            <button
                type="button"
                className="sidebarCollapseExpandBtn"
                data-testid="sidebarCollapseExpandBtn"
                onClick={toggleSidebarStatus}
            >
                <img alt="collapse or expand sidebar" src={CollapseIcon}/>
            </button>
        </div>
    )
}

BoardListingSidebar.propTypes = {
    activePage: PropTypes.oneOf(['ALL_CANVASES', 'MY_CANVASES', 'SHARED_WITH_ME']),
    onActivePageChange: PropTypes.func,
    projects: PropTypes.array,
    activeProjectId: PropTypes.string,
    logout: PropTypes.func.isRequired,
    userName: PropTypes.string
}

BoardListingSidebar.defaultProps = {
    activePage: 'RECENT',
    projects: []
}

export default BoardListingSidebar;