/**
 * @param canvas
 */
export default function generateFrameText(canvas) {
    let maxNumber = 0;
    canvas.getObjects().forEach((obj) => {
        try {
            if (obj.shapeType === 'frame') {
                const frameName = obj.text;
                const match = frameName.match(/Frame (\d+)/);
                if (match) {
                    const number = parseInt(match[1], 10);
                    if (number > maxNumber) {
                        maxNumber = number;
                    }
                }
                else {
                    maxNumber += 1;
                }
            }
        } catch (err) {
            console.error('Error happened', err);
        }
    });

    if (maxNumber === 0) {
        return `Frame`;
    }
  
    return `Frame ${maxNumber + 1}`;
}