import { fabric } from 'fabric';
import { CurvedLine } from '../../../hooks/UseCurvedLine';
import magnetEffectForPolygon from '../../lines/MagnetEffect';
import { getObjectCoordinatesByOrigin, isObjectValid, isTargetAttachableToLine, rotatePoint } from '../../FabricMethods'
import { ALLOWED_CONNECTORS_DUPLICATION_SHAPE_TYPES, SHAPE_DEFAULTS, MAGNET_CIRCLE_TYPE, EMITTER_TYPES } from '../../Constant';
import { clearAlignmentLines } from '../../alignment/Utils';
import eventEmitter from '../../EventEmitter';
import store from '../../../redux/Store';
import icon from '../../../assets/images/plus.svg';
import { getDefaultLineType } from '../../lines/GetDefaultLineType';

const PlusIcon = document.createElement('img');
PlusIcon.src = icon;

/**
 * @param {fabric.Object} obj 
 * @param {'connectorLeft' | 'connectorRight' | 'connectorBottom' | 'connectorTop'} controlName 
 * @returns {{ x: number, y: number }}
 */
export const findPositionOfConnectorControl = (obj, controlName) => {
    const objCoordinates = getObjectCoordinatesByOrigin(obj);
    const point = {}
    if (controlName === 'connectorLeft') { // ml
        point.x = objCoordinates.tl.x;
        point.y = (objCoordinates.tl.y + objCoordinates.bl.y) / 2; 
    } else if (controlName === 'connectorTop') { // mt
        point.x = (objCoordinates.tl.x + objCoordinates.tr.x) / 2;
        point.y = objCoordinates.tl.y;
    } else if (controlName === 'connectorRight') { // mr
        point.x = objCoordinates.tr.x;
        point.y = (objCoordinates.tr.y + objCoordinates.br.y) / 2;
    } else if (controlName === 'connectorBottom') { // mb
        point.x = (objCoordinates.bl.x + objCoordinates.br.x) / 2;
        point.y = objCoordinates.bl.y;
    }

    if (obj.shapeType === 'triangle') {
        if (controlName === 'connectorLeft') {
            point.x += (obj.getScaledWidth() / 4);
        } else if (controlName === 'connectorRight') {
            point.x -= (obj.getScaledWidth() / 4);
        }
    }
    if (obj.shapeType === 'parallelogram') {
        if (controlName === 'connectorLeft') {
            point.x += (obj.getScaledWidth() / 8);
        } else if (controlName === 'connectorRight') {
            point.x -= (obj.getScaledWidth() / 8);
        } else if (controlName === 'connectorTop') {
            point.x += (obj.getScaledWidth() / 8);
        } else if (controlName === 'connectorBottom') {
            point.x -= (obj.getScaledWidth() / 8);
        }
    }

    // if the object is rotated, rotate the point back
    if (obj.angle !== 0) {
        const rotatedPoint = rotatePoint(point.x, point.y, obj.left, obj.top, obj.angle);
        return rotatedPoint;
    }
    return point;
}

/**
 * @param {fabric.Object} obj 
 * @returns {{ ml: number, mr: number, mt: number, mb: number }}
 */
const getMiddleCoordinates = (obj) => {
    const coordsForVertical = obj.calcACoords(true, true);
    const coordsForHorizontal = getObjectCoordinatesByOrigin(obj);

    let ml = {
        x: coordsForHorizontal.tl.x - ((coordsForHorizontal.tl.x - coordsForHorizontal.bl.x) / 2),
        y: (coordsForHorizontal.tl.y - ( coordsForHorizontal.tl.y - coordsForHorizontal.bl.y) / 2)
    }
    let mr = {
        x: coordsForHorizontal.tr.x - ((coordsForHorizontal.tr.x - coordsForHorizontal.br.x) / 2),
        y: (coordsForHorizontal.tr.y - ( coordsForHorizontal.tr.y - coordsForHorizontal.br.y) / 2)
    }
    let mt = {
        x: (coordsForVertical.tl.x - (coordsForVertical.tl.x - coordsForVertical.tr.x) / 2),
        y: coordsForVertical.tl.y - ((coordsForVertical.tl.y - coordsForVertical.tr.y) / 2)
    }
    let mb = {
        x: (coordsForVertical.bl.x - (coordsForVertical.bl.x - coordsForVertical.br.x) / 2),
        y: coordsForVertical.bl.y - ((coordsForVertical.bl.y - coordsForVertical.br.y) / 2)
    }

    if (obj.shapeType === 'triangle') {
        ml.x += (obj.getScaledWidth() / 4);
        mr.x -= (obj.getScaledWidth() / 4);
    }
    if (obj.shapeType === 'parallelogram') {
        ml.x += (obj.getScaledWidth() / 8);
        mr.x -= (obj.getScaledWidth() / 8);
        mt = {
            x: (coordsForVertical.tl.x - (coordsForVertical.tl.x - coordsForVertical.tr.x) / (8/5)),
            y: coordsForVertical.tl.y - (coordsForVertical.tl.y - coordsForVertical.tr.y) / (8/5)
        }
        mb = {
            x: (coordsForVertical.bl.x - (coordsForVertical.bl.x - coordsForVertical.br.x) / (8/3)),
            y: coordsForVertical.bl.y - (coordsForVertical.bl.y - coordsForVertical.br.y) / (8/3)
        }
    }

    if (obj.angle !== 0) {
        mr = rotatePoint(mr.x, mr.y, obj.left, obj.top, obj.angle);
        ml = rotatePoint(ml.x, ml.y, obj.left, obj.top, obj.angle);
    }

    return {
        ml,
        mr,
        mt,
        mb
    }
}

export const getAllPositionsOfConnectorControls = (obj) => {
    return getMiddleCoordinates(obj);
}

/**
 * Check if the given object supports plus connector control.
 * @param {fabric.Object} object 
 * @returns Boolean.
 */
export const isPlusConnectorSupported = (object) => {
    if (!object) { return false; }
    if (object.type !== 'group') { return false; }
    if (!ALLOWED_CONNECTORS_DUPLICATION_SHAPE_TYPES.includes(object.shapeType)) { return false; }
    return true;
}

/**
 * @param ctx
 * @param left
 * @param top
 * @param styleOverride
 * @param fabricObject
 */
export function renderConnectorControlDots (ctx, left, top, styleOverride, fabricObject) {
    // don't render if the objects cannot has connectors
    if (!isObjectValid(fabricObject) || !isTargetAttachableToLine(fabricObject)) return;
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));

    if (isPlusConnectorSupported(fabricObject)) {
        const size = this.cornerSize;
        ctx.drawImage(PlusIcon, -size / 2, -size / 2, size, size);
    } else { // Textbox objects cannot be duplicated via connectors
        ctx.beginPath();
        const radius = this.radius ? this.radius : 4;
        ctx.arc(0, 0, radius, 0, 2 * Math.PI);
        ctx.fillStyle = '#a2c1f8';
        ctx.fill();
    }

    ctx.restore();
}

/**
 * Find the positions of duplicated or dummy duplicated object.
 * @param {fabric.Object} target
 * @param {'connectorLeft' | 'connectorRight' | 'connectorTop' | 'connectorBottom'} actualCorner
 * @param {'connectorLeft' | 'connectorRight' | 'connectorTop' | 'connectorBottom'} corner
 * @returns {{ x:number, y: number }}
 */
export function calculateDuplicatedObjPosition(target, actualCorner, corner) {
    let left = target.left;
    let top = target.top;
    let width = target.getScaledWidth();
    let height = target.getScaledHeight();

    let distance = 200;
    if (target.angle !== 0) {
        const pos = rotatePoint(
            left,
            top,
            target.left,
            target.top,
            target.angle
        );

        left = pos.x;
        top = pos.y;
    }
  
    // If actual corner is left or right, and corner is bottom or top, we need to change the width and height to better positioning.
    if (['connectorLeft', 'connectorRight'].includes(actualCorner) && ['connectorTop', 'connectorBottom'].includes(corner)) {
        const temp = width;
        width = height;
        height = temp;
    } else if (['connectorLeft', 'connectorRight'].includes(corner) && ['connectorTop', 'connectorBottom'].includes(actualCorner)) {
        const temp = height;
        height = width;
        width = temp;
    }

    if (actualCorner === 'connectorLeft') {
        left = left - width - distance;
    } else if (actualCorner === 'connectorRight') {
        left = left + width + distance;
    } else if (actualCorner === 'connectorTop') {
        top = top - height - distance;
    } else if (actualCorner === 'connectorBottom') {
        top = top + height + distance;
    }

    return { x: left, y: top };
}

/**
 * The method finds real corner of clicked plus icon corner in case object rotates.
 * @param {number} angle 
 * @param {'connectorLeft' | 'connectorRight' | 'connectorTop' | 'connectorBottom'} corner
 * @returns {'connectorLeft' | 'connectorRight' | 'connectorTop' | 'connectorBottom'} 
 */
export const getActualCorner = (angle, corner) => {
    if (!angle || angle === 0 || angle === 360) return corner;

    // Angle always should be higher than zero.
    angle = (angle < 0) ? 360 - angle : angle;

    const corners = ['connectorLeft', 'connectorTop', 'connectorRight', 'connectorBottom'];
    const currentPosition = corners.findIndex((o) => o === corner);

    const count = Math.floor((angle + 45) / 90);
    const turn = count % corners.length;
  
    return corners[(currentPosition + turn) % corners.length];
}

/**
 * Calculating curved line position for dummy duplicated object and duplicated object.
 * @param {fabric.Object} target 
 * @param {'connectorLeft' | 'connectorRight' | 'connectorBottom' | 'connectorTop'} corner 
 * @returns {{ x1: number, y1: number, x2: number, y2: number }}
 */
export const calculateCurvedLinePosition = (target, corner) => {
    const shapePos = findPositionOfConnectorControl(target, corner);
    const secondObj = target?.dummyDuplicatedObject || target?.clonedObject;
    if (!secondObj) { return null; }

    const secondObjCoords = findPositionOfConnectorControl(
        secondObj,
        corner === 'connectorLeft' ? 'connectorRight' : 'connectorLeft'
    );

    let pos = { x: 0, y: 0 };

    if (corner === 'connectorLeft') {
        pos.x = secondObjCoords.x;
        pos.y = secondObjCoords.y;
    } else if (corner === 'connectorTop') {
        pos.x = secondObj.left;
        pos.y = secondObj.top + (secondObj.getScaledHeight() / 2);
        if(target.shapeType ==='parallelogram') {
            pos.x = pos.x - (secondObj.getScaledWidth() / 8)
        }
    } else if (corner === 'connectorRight') {
        pos.x = secondObjCoords.x
        pos.y = secondObjCoords.y;
    } else if (corner === 'connectorBottom') {
        pos.x = secondObj.left;
        pos.y = secondObj.top - (secondObj.getScaledHeight() / 2);
        if(target.shapeType ==='parallelogram') {
            pos.x = pos.x + (secondObj.getScaledWidth() / 8)
        }
    }

    if (target.angle !== 0 && !['connectorLeft', 'connectorRight'].includes(corner)) {
        pos = rotatePoint(pos.x, pos.y, secondObj.left, secondObj.top, target.angle);
    }

    return { x1: pos.x, y1: pos.y, x2: shapePos.x, y2: shapePos.y }
}

/**
 * Creating curved line when clicked to a plus icon or moving away from a connector.
 * @param {number} x1 
 * @param {number} y1 
 * @param {number} x2 
 * @param {number} y2 
 * @returns {fabric.Object}
 */
export const createCurvedLineByConnector = (x1, y1, x2, y2) => {
    let strokeWidth = 1;

    try {
        const boardState = store.getState();
        strokeWidth = boardState?.board?.currentWidth ? boardState.board.currentWidth : 1;
    } catch (err) {
        console.error('Error happened', err);
    }

    const curve = new CurvedLine([{ x: x1, y: y1, }, { x: x2, y: y2 }], {
        stroke: SHAPE_DEFAULTS.STROKE,
        strokeWidth,
        originY: 'center',
        originX: 'center',
        strokeUniform: true,
        objectCaching: false,
        arrowEnabled: true,
        arrowRight: true,
        lineType: getDefaultLineType(),
        isInitiallyConnector: true,
        curvedLineVersion: 'v2'
    });

    return curve;
}

/**
 * Drawing the line when user presses the plus icon.
 * @param {fabric.Object} target 
 * @param {'connectorLeft' | 'connectorRight' | 'connectorBottom' | 'connectorTop'} corner 
 * @param {'connectorLeft' | 'connectorRight' | 'connectorBottom' | 'connectorTop'} actualCorner 
 */
export const drawCornerLine = (target, corner, actualCorner) => {
    const pos = calculateCurvedLinePosition(target, corner);
    const curve = createCurvedLineByConnector(pos.x2, pos.y2, pos.x1, pos.y1);

    target[`${actualCorner}LineDrawing`] = curve;
    target.canvas.add(curve);

    curve.points[1] = { x: pos.x1, y: pos.y1 }; 
    magnetEffectForPolygon(curve, 1, {excludedObjects: [target.clonedObject.uuid, target.uuid]});
    curve._setPositionDimensions({});
    curve.canvas.renderAll();
}

const resetConnectorObjects = (target, corner, actualCorner) => {
    // remove the magnet circles
    const objects = target.canvas.getObjects().filter(obj => obj.shapeType === MAGNET_CIRCLE_TYPE);
    if (objects.length) objects.forEach(obj => target.canvas.remove(obj));
    // show the subtoolbar for this line
    if (target[`${actualCorner}LineDrawing`]) {
        target.canvas.setActiveObject(target[`${actualCorner}LineDrawing`]);
        target.canvas.requestRenderAll();
        eventEmitter.fire(EMITTER_TYPES.TOOLBAR_SHOW);
    }

    // reset the line drawing
    target[`${corner}LineDrawing`] = null;
    target[`${actualCorner}LineDrawing`] = null;

    // remove the alignment lines if there is any
    clearAlignmentLines(target.canvas);
    target.canvas.remove(target?.dummyDuplicatedObject);
    target.canvas.remove(target?.dummyDuplicatedObjectLine);
    delete target.dummyDuplicatedObject;
    delete target.clonedObject;  
}

/**
 * Emitting the line that created by moving from connector.
 * @param {fabric.Object} target 
 * @param {'connectorLeft' | 'connectorRight' | 'connectorBottom' | 'connectorTop'} corner 
 * @param {'connectorLeft' | 'connectorRight' | 'connectorBottom' | 'connectorTop'} actualCorner 
 */
export const emitLineDrawing = (target, corner, actualCorner, transform) => {
    // emit the drawn connector. it will also be used to attach the connector to the target object
    target.canvas.fire('emit-drawn-connector', { connector: target[`${actualCorner}LineDrawing`], targetShape: target, transform });
    target.canvas.fire('line-added', { target: target[`${actualCorner}LineDrawing`]});

    resetConnectorObjects(target, corner, actualCorner);
}

/**
 * Emitting the line and duplicated object that created when the user clicked to plus icon.
 * @param {fabric.Object} target 
 * @param {'connectorLeft' | 'connectorRight' | 'connectorBottom' | 'connectorTop'} corner 
 * @param {'connectorLeft' | 'connectorRight' | 'connectorBottom' | 'connectorTop'} actualCorner 
 */
export const emitObjectsCreatedByConnector = (target, corner, actualCorner, processId) => {
    target.canvas.fire('emit-drawn-connector', {
        connector: target[`${actualCorner}LineDrawing`],
        targetShape: target,
        dontEmit: true,
        callback: ({
            connector
        }) => {
            target.canvas.fire('add-to-undo-stack', {
                objects: [target.clonedObject, connector],
                processId,
            });

            target.canvas.fire('history-emit-data', {
                objects: [target.clonedObject, connector],
                action: 'created'
            });

            target.canvas.fire('history-emit-data', {
                objects: [target],
                action: 'modified'
            });

            resetConnectorObjects(target, corner, actualCorner); 
        }
    });
}