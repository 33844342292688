/**
 * Override of fabric.Canvas.prototype._finalizeCurrentTransform to put back removed lines from active selection before firing object:modified.
 * @param {Eevnt} e 
 */
export default function _finalizeCurrentTransform(e) {
    const transform = this._currentTransform,
        target = transform.target,
        options = {
            e: e,
            target: target,
            transform: transform,
            action: transform.action,
        };

    if (target._scaling) {
        target._scaling = false;
    }

    target.setCoords();

    if (transform.actionPerformed || (this.stateful && target.hasStateChanged())) {
        if (target.type === 'activeSelection') {
            if (target.removedLinesDuringScaling) {
                target.removedLinesDuringScaling.forEach(line => {
                    target.addWithUpdate(line);
                    if (line.mockPoints) {
                        line.mockPoints.forEach(point => {
                            target.removeWithUpdate(point);
                        });
                        line.mockPoints = null;
                    }
                });
            }
            if (target.removedLinesDuringMoving) {
                target.removedLinesDuringMoving.forEach(line => {
                    target.addWithUpdate(line);
                });
                target.removedLinesDuringMoving = null;
            }
            if (target.removedLinesDuringExpanding) {
                target.removedLinesDuringExpanding.forEach(line => {
                    target.addWithUpdate(line);
                });
                target.removedLinesDuringExpanding = null;
            }
        }

        this._fire('modified', options);
    }
}