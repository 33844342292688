import Slider from 'rc-slider';
import PropTypes from 'prop-types';
import './LineTypeMenu.scss'
import IconLineDrawingTypeSolid from '../../../svgIcons/lines/LineDrawingTypeSolid';
import IconLineDrawingTypeDashed from '../../../svgIcons/lines/LineDrawingTypeDashed';
import IconLineTypeCurved from '../../../svgIcons/lines/LineTypeCurved';
import IconLineTypeStraight from '../../../svgIcons/lines/LineTypeStraight';
import clsx from 'clsx';
import { LINE_TYPES } from '../../../../helpers/Constant';

const LineTypeMenu = ({
    changeThickness,
    onClickHandler,
    changeLineType,
    strokeWidth,
    strokeDashArray,
    lineType
}) => {
    return (
        <div className="lineOption__list">
            <div className='lineOption__item lineOption--thicknessSliderWrapper' data-testid='lineOption--thicknessSlider'>
                <Slider 
                    className='lineOption--thicknessSlider'
                    defaultValue={strokeWidth ? strokeWidth : 2}
                    dots
                    max={12}
                    min={2}
                    onChange={(val) => changeThickness(val)}
                    step={2}
                />
            </div>
            <div className='lineOption__item lineOption__buttonWrapper has_two_buttons'>
                <button
                    type="button"
                    aria-label="set line drawing as solid"
                    className={clsx('lineOption__button', {
                        'active': !strokeDashArray
                    })}
                    data-testid='lineOption__button--solid'
                    onClick={() => onClickHandler(false)}>
                    <IconLineDrawingTypeSolid />
                </button>
                <button
                    type="button"
                    aria-label="set line drawing as dashed"
                    className={clsx('lineOption__button', {
                        'active': strokeDashArray
                    })}
                    data-testid='lineOption__button--dashed'
                    onClick={() => onClickHandler(true)}>
                    <IconLineDrawingTypeDashed />
                </button>
            </div>
            <div className='lineOption__item lineOption__buttonWrapper'>
                <button
                    type="button"
                    aria-label="set line type as straight" 
                    className={clsx('lineOption__button', {
                        'active': lineType === LINE_TYPES.STRAIGHT
                    })}
                    data-testid='lineOption__button--straight'
                    onClick={() => changeLineType(LINE_TYPES.STRAIGHT)}>
                    <IconLineTypeStraight />
                </button>
                <button
                    type="button"
                    aria-label="set line type as curved"
                    className={clsx('lineOption__button', {
                        'active': !lineType || lineType === LINE_TYPES.CURVED
                    })}
                    data-testid='lineOption__button--curved'
                    onClick={() => changeLineType(LINE_TYPES.CURVED)}>
                    <IconLineTypeCurved />
                </button>
                {/* <button
                    className={clsx('lineOption__button', {
                        'active': lineType === 'stepper'
                    })}
                    onClick={() => changeLineType(LINE_TYPES.STEPPER)}
                    data-testid='lineOption__button--stepper'
                    aria-label="set line type as stepper"
                    disabled>
                        <IconLineTypeStepper />

                </button> */}
            </div>
        </div>
    )    
}

LineTypeMenu.propTypes = {
    changeThickness: PropTypes.func.isRequired,
    onClickHandler: PropTypes.func.isRequired,
    changeLineType: PropTypes.func.isRequired,
    strokeWidth: PropTypes.number,
    strokeDashArray: PropTypes.number,
    lineType: PropTypes.oneOf([
        LINE_TYPES.STRAIGHT,
        LINE_TYPES.CURVED,
        LINE_TYPES.STEPPER
    ])
}

export default LineTypeMenu;