import { fabric } from 'fabric';

/**
 * Renders controls and borders for the object.
 * The context here is not transformed.
 * @override
 * @param {CanvasRenderingContext2D} ctx Context to render on.
 * @param {fabric.Object} [styleOverride] Properties to override the object style.
 */
export function _renderControls(ctx, styleOverride) {
    if (this.collabLocked) {
        return
    }
    var vpt = this.getViewportTransform(),
        matrix = this.calcTransformMatrix(),
        options, drawBorders, drawControls;
    styleOverride = styleOverride || { };
    drawBorders = typeof styleOverride.hasBorders !== 'undefined' ? styleOverride.hasBorders : this.hasBorders;
    drawControls = typeof styleOverride.hasControls !== 'undefined' ? styleOverride.hasControls : this.hasControls;
    matrix = fabric.util.multiplyTransformMatrices(vpt, matrix);
    options = fabric.util.qrDecompose(matrix);
    ctx.save();
    ctx.translate(options.translateX, options.translateY);
    ctx.lineWidth = 1 * this.borderScaleFactor;
    if (!this.group) {
        ctx.globalAlpha = this.isMoving ? this.borderOpacityWhenMoving : 1;
    }
    if (this.flipX) {
        options.angle -= 180;
    }
    ctx.rotate(fabric.util.degreesToRadians(this.group ? options.angle : this.angle));
    if (styleOverride.forActiveSelection || this.group) {
        drawBorders && this.drawBordersInGroup(ctx, options, styleOverride);
    }
    else {
        drawBorders && this.drawBorders(ctx, styleOverride);
    }
    drawControls && this.drawControls(ctx, styleOverride);
    ctx.restore();
}