import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import eventEmitter from '../../../helpers/EventEmitter';
import { EMITTER_TYPES, MODAL_WINDOWS, RIGHT_DRAWER_WIDTH } from '../../../helpers/Constant';
import LayoutIcon from '../../svgIcons/Layout';
import ChevronRightIcon from '../../svgIcons/canvasRedesign/IconChevronRight';
import ChevronLeftIcon from '../../svgIcons/canvasRedesign/IconChevronLeft';

const PageSelector = ({
    canvas,
    handleToggleCommentDrawer
}) => {
    const dispatch = useDispatch();
    const isRightPanelInitializedRef = useRef();
    const activePageNo = useSelector((state) => state.rightDrawer.activePage?.pageNo);
    const pages = useSelector((state) => state.rightDrawer.pages);
    const isRightDrawerVisible = useSelector((state) => state.modal?.activeWindow === MODAL_WINDOWS.RIGHT_DRAWER);

    /**
     * @param {'prev' | 'next'} direction 
     */
    const changePage = (direction) => {
        const newPage = direction === 'next' ? activePageNo + 1 : activePageNo - 1;
        if (newPage > pages.length || newPage < 1) return;

        eventEmitter.fire(EMITTER_TYPES.PAGE_CHANGED, {});

        dispatch({
            type: 'rightDrawer/changePageByPageNo',
            payload: newPage
        })

        // close right drawer if open
        if (isRightDrawerVisible) {
            dispatch({
                type: 'modal/toggleRightDrawer',
                payload: {
                    shouldShow: false
                }
            });
        }
    }

    const goToPrevPage = () => changePage('prev');
    const goToNextPage = () => changePage('next');

    const toggleRightDrawer = () => {
        if (!isRightDrawerVisible) handleToggleCommentDrawer({ shouldShow: false });
        dispatch({
            type: 'modal/toggleRightDrawer',
            payload: {
                shouldShow: !isRightDrawerVisible
            }
        }); 
        dispatch({
            type: 'rightDrawer/toggleVisibility',
            payload: 'PAGES'
        });
    }

    useEffect(() => {
        setTimeout(() => {
            if (!canvas || !canvas.lowerCanvasEl) return;
            const newWidth = isRightDrawerVisible ? window.innerWidth - RIGHT_DRAWER_WIDTH : window.innerWidth;
            const vpt = [...canvas.viewportTransform];
            canvas.setWidth(newWidth);

            if (isRightDrawerVisible) {
                vpt[4] -= RIGHT_DRAWER_WIDTH;
                isRightPanelInitializedRef.current = true;
            } else if (isRightPanelInitializedRef.current === true) {
                vpt[4] += RIGHT_DRAWER_WIDTH;
            }

            canvas.setViewportTransform(vpt);
        }, 200);
    }, [isRightDrawerVisible]);

    return (
        <div className="pageSelector bottomBarButton">
            <div className="numbersArea">
                <button
                    disabled={activePageNo === 1}
                    onClick={goToPrevPage}
                    type="button"
                >
                    <ChevronLeftIcon />
                </button>
                <div className="numbers">
                    <span>{activePageNo}</span>
                    <i>/</i>
                    <span>{pages.length}</span>
                </div>

                <button
                    disabled={activePageNo === pages.length}
                    onClick={goToNextPage}
                    type="button"
                >
                    <ChevronRightIcon />
                </button>
            </div>

            <div className="divider" />

            <button
                className={clsx('layoutBtn', { __active: isRightDrawerVisible })}
                onClick={toggleRightDrawer}
                title="Pages"
                type="button"
            >
                <LayoutIcon />
                <span className="tooltiptext tooltip__page">Pages</span>
            </button>
        </div>
    )
};

PageSelector.propTypes = {
    canvas: PropTypes.object,
    handleToggleCommentDrawer: PropTypes.func.isRequired
}

export default PageSelector;
