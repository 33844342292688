import { Point } from './Point';

export const Curve = function(p1, p2, p3, p4) {
    if (!(this instanceof Curve)) {
        return new Curve(p1, p2, p3, p4);
    }

    if (p1 instanceof Curve) {
        return new Curve(p1.start, p1.controlPoint1, p1.controlPoint2, p1.end);
    }

    this.start = new Point(p1);
    this.controlPoint1 = new Point(p2);
    this.controlPoint2 = new Point(p3);
    this.end = new Point(p4);
};

Curve.prototype = {
    type: 7,

    // Default precision
    PRECISION: 3,

    round: function(precision) {
        this.start.round(precision);
        this.controlPoint1.round(precision);
        this.controlPoint2.round(precision);
        this.end.round(precision);
        return this;
    },

    // ! NOTE: All of the below functions are not using right now. I didn't remove the for future purposes.
    
    // // Returns a bbox that tightly envelops the curve.
    // bbox: function() {

    //     let start = this.start;
    //     let controlPoint1 = this.controlPoint1;
    //     let controlPoint2 = this.controlPoint2;
    //     let end = this.end;

    //     let x0 = start.x;
    //     let y0 = start.y;
    //     let x1 = controlPoint1.x;
    //     let y1 = controlPoint1.y;
    //     let x2 = controlPoint2.x;
    //     let y2 = controlPoint2.y;
    //     let x3 = end.x;
    //     let y3 = end.y;

    //     let points = new Array(); // local extremes
    //     let tvalues = new Array(); // t values of local extremes
    //     let bounds = [new Array(), new Array()];

    //     let a, b, c, t;
    //     let t1, t2;
    //     let b2ac, sqrtb2ac;

    //     for (let i = 0; i < 2; ++i) {

    //         if (i === 0) {
    //             b = 6 * x0 - 12 * x1 + 6 * x2;
    //             a = -3 * x0 + 9 * x1 - 9 * x2 + 3 * x3;
    //             c = 3 * x1 - 3 * x0;

    //         } else {
    //             b = 6 * y0 - 12 * y1 + 6 * y2;
    //             a = -3 * y0 + 9 * y1 - 9 * y2 + 3 * y3;
    //             c = 3 * y1 - 3 * y0;
    //         }

    //         if (Math.abs(a) < 1e-12) { // Numerical robustness
    //             if (Math.abs(b) < 1e-12) { // Numerical robustness
    //                 continue;
    //             }

    //             t = -c / b;
    //             if ((0 < t) && (t < 1)) { tvalues.push(t); }

    //             continue;
    //         }

    //         b2ac = b * b - 4 * c * a;
    //         sqrtb2ac = Math.sqrt(b2ac);

    //         if (b2ac < 0) { continue; }

    //         t1 = (-b + sqrtb2ac) / (2 * a);
    //         if ((0 < t1) && (t1 < 1)) { tvalues.push(t1); }

    //         t2 = (-b - sqrtb2ac) / (2 * a);
    //         if ((0 < t2) && (t2 < 1)) { tvalues.push(t2); }
    //     }

    //     let j = tvalues.length;
    //     let jlen = j;
    //     let mt;
    //     let x, y;

    //     while (j--) {
    //         t = tvalues[j];
    //         mt = 1 - t;

    //         x = (mt * mt * mt * x0) + (3 * mt * mt * t * x1) + (3 * mt * t * t * x2) + (t * t * t * x3);
    //         bounds[0][j] = x;

    //         y = (mt * mt * mt * y0) + (3 * mt * mt * t * y1) + (3 * mt * t * t * y2) + (t * t * t * y3);
    //         bounds[1][j] = y;

    //         points[j] = { X: x, Y: y };
    //     }

    //     tvalues[jlen] = 0;
    //     tvalues[jlen + 1] = 1;

    //     points[jlen] = { X: x0, Y: y0 };
    //     points[jlen + 1] = { X: x3, Y: y3 };

    //     bounds[0][jlen] = x0;
    //     bounds[1][jlen] = y0;

    //     bounds[0][jlen + 1] = x3;
    //     bounds[1][jlen + 1] = y3;

    //     tvalues.length = jlen + 2;
    //     bounds[0].length = jlen + 2;
    //     bounds[1].length = jlen + 2;
    //     points.length = jlen + 2;

    //     let left = Math.min.apply(null, bounds[0]);
    //     let top = Math.min.apply(null, bounds[1]);
    //     let right = Math.max.apply(null, bounds[0]);
    //     let bottom = Math.max.apply(null, bounds[1]);

    //     return new Rect(left, top, (right - left), (bottom - top));
    // },

    // clone: function() {
    //     return new Curve(this.start, this.controlPoint1, this.controlPoint2, this.end);
    // },

    // // Returns the point on the curve closest to point `p`
    // closestPoint: function(p, opt) {
    //     return this.pointAtT(this.closestPointT(p, opt));
    // },

    // closestPointLength: function(p, opt) {
    //     opt = opt || {};
    //     let precision = (opt.precision === undefined) ? this.PRECISION : opt.precision;
    //     let subdivisions = (opt.subdivisions === undefined) ? this.getSubdivisions({ precision: precision }) : opt.subdivisions;
    //     let localOpt = { precision: precision, subdivisions: subdivisions };

    //     return this.lengthAtT(this.closestPointT(p, localOpt), localOpt);
    // },

    // closestPointNormalizedLength: function(p, opt) {
    //     opt = opt || {};
    //     let precision = (opt.precision === undefined) ? this.PRECISION : opt.precision;
    //     let subdivisions = (opt.subdivisions === undefined) ? this.getSubdivisions({ precision: precision }) : opt.subdivisions;
    //     let localOpt = { precision: precision, subdivisions: subdivisions };

    //     let cpLength = this.closestPointLength(p, localOpt);
    //     if (!cpLength) { return 0; }

    //     let length = this.length(localOpt);
    //     if (length === 0) { return 0; }

    //     return cpLength / length;
    // },

    // Returns `t` of the point on the curve closest to point `p`
    // closestPointT: function(p, opt) {
    //     console.log('closestPointT');
    //     opt = opt || {};
    //     let precision = (opt.precision === undefined) ? this.PRECISION : opt.precision;
    //     let subdivisions = (opt.subdivisions === undefined) ? this.getSubdivisions({ precision: precision }) : opt.subdivisions;
    //     // does not use localOpt

    //     // identify the subdivision that contains the point:
    //     let investigatedSubdivision;
    //     let investigatedSubdivisionStartT; // assume that subdivisions are evenly spaced
    //     let investigatedSubdivisionEndT;
    //     let distFromStart; // distance of point from start of baseline
    //     let distFromEnd; // distance of point from end of baseline
    //     let chordLength; // distance between start and end of the subdivision
    //     let minSumDist; // lowest observed sum of the two distances
    //     let n = subdivisions.length;
    //     let subdivisionSize = (n ? (1 / n) : 0);
    //     for (let i = 0; i < n; i++) {

    //         let currentSubdivision = subdivisions[i];

    //         let startDist = currentSubdivision.start.distance(p);
    //         let endDist = currentSubdivision.end.distance(p);
    //         let sumDist = startDist + endDist;

    //         // check that the point is closest to current subdivision and not any other
    //         if (!minSumDist || (sumDist < minSumDist)) {
    //             investigatedSubdivision = currentSubdivision;

    //             investigatedSubdivisionStartT = i * subdivisionSize;
    //             investigatedSubdivisionEndT = (i + 1) * subdivisionSize;

    //             distFromStart = startDist;
    //             distFromEnd = endDist;

    //             chordLength = currentSubdivision.start.distance(currentSubdivision.end);

    //             minSumDist = sumDist;
    //         }
    //     }

    //     let precisionRatio = Math.pow(10, -precision);

    //     // recursively divide investigated subdivision:
    //     // until distance between baselinePoint and closest path endpoint is within 10^(-precision)
    //     // then return the closest endpoint of that final subdivision
    //     while (true) {
    //         // check if we have reached at least one required observed precision
    //         // - calculated as: the difference in distances from point to start and end divided by the distance
    //         // - note that this function is not monotonic = it doesn't converge stably but has "teeth"
    //         // - the function decreases while one of the endpoints is fixed but "jumps" whenever we switch
    //         // - this criterion works well for points lying far away from the curve
    //         let startPrecisionRatio = (distFromStart ? (Math.abs(distFromStart - distFromEnd) / distFromStart) : 0);
    //         let endPrecisionRatio = (distFromEnd ? (Math.abs(distFromStart - distFromEnd) / distFromEnd) : 0);
    //         let hasRequiredPrecision = ((startPrecisionRatio < precisionRatio) || (endPrecisionRatio < precisionRatio));

    //         // check if we have reached at least one required minimal distance
    //         // - calculated as: the subdivision chord length multiplied by precisionRatio
    //         // - calculation is relative so it will work for arbitrarily large/small curves and their subdivisions
    //         // - this is a backup criterion that works well for points lying "almost at" the curve
    //         let hasMinimalStartDistance = (distFromStart ? (distFromStart < (chordLength * precisionRatio)) : true);
    //         let hasMinimalEndDistance = (distFromEnd ? (distFromEnd < (chordLength * precisionRatio)) : true);
    //         let hasMinimalDistance = (hasMinimalStartDistance || hasMinimalEndDistance);

    //         // do we stop now?
    //         if (hasRequiredPrecision || hasMinimalDistance) {
    //             return ((distFromStart <= distFromEnd) ? investigatedSubdivisionStartT : investigatedSubdivisionEndT);
    //         }

    //         // otherwise, set up for next iteration
    //         let divided = investigatedSubdivision.divide(0.5);
    //         subdivisionSize /= 2;

    //         let startDist1 = divided[0].start.distance(p);
    //         let endDist1 = divided[0].end.distance(p);
    //         let sumDist1 = startDist1 + endDist1;

    //         let startDist2 = divided[1].start.distance(p);
    //         let endDist2 = divided[1].end.distance(p);
    //         let sumDist2 = startDist2 + endDist2;

    //         if (sumDist1 <= sumDist2) {
    //             investigatedSubdivision = divided[0];

    //             investigatedSubdivisionEndT -= subdivisionSize; // subdivisionSize was already halved

    //             distFromStart = startDist1;
    //             distFromEnd = endDist1;

    //         } else {
    //             investigatedSubdivision = divided[1];

    //             investigatedSubdivisionStartT += subdivisionSize; // subdivisionSize was already halved

    //             distFromStart = startDist2;
    //             distFromEnd = endDist2;
    //         }
    //     }
    // },

    // closestPointTangent: function(p, opt) {
    //     return this.tangentAtT(this.closestPointT(p, opt));
    // },

    // // Returns `true` if the area surrounded by the curve contains the point `p`.
    // // Implements the even-odd algorithm (self-intersections are "outside").
    // // Closes open curves (always imagines a closing segment).
    // // Precision may be adjusted by passing an `opt` object.
    // containsPoint: function(p, opt) {
    //     let polyline = this.toPolyline(opt);
    //     return polyline.containsPoint(p);
    // },

    // // Divides the curve into two at requested `ratio` between 0 and 1 with precision better than `opt.precision`; optionally using `opt.subdivisions` provided.
    // // For a function that uses `t`, use Curve.divideAtT().
    // divideAt: function(ratio, opt) {
    //     if (ratio <= 0) { return this.divideAtT(0); }
    //     if (ratio >= 1) { return this.divideAtT(1); }

    //     let t = this.tAt(ratio, opt);

    //     return this.divideAtT(t);
    // },

    // // Divides the curve into two at requested `length` with precision better than requested `opt.precision`; optionally using `opt.subdivisions` provided.
    // divideAtLength: function(length, opt) {
    //     let t = this.tAtLength(length, opt);

    //     return this.divideAtT(t);
    // },

    // // Divides the curve into two at point defined by `t` between 0 and 1.
    // // Using de Casteljau's algorithm (http://math.stackexchange.com/a/317867).
    // // Additional resource: https://pomax.github.io/bezierinfo/#decasteljau
    // divideAtT: function(t) {
    //     let start = this.start;
    //     let controlPoint1 = this.controlPoint1;
    //     let controlPoint2 = this.controlPoint2;
    //     let end = this.end;

    //     // shortcuts for `t` values that are out of range
    //     if (t <= 0) {
    //         return [
    //             new Curve(start, start, start, start),
    //             new Curve(start, controlPoint1, controlPoint2, end)
    //         ];
    //     }

    //     if (t >= 1) {
    //         return [
    //             new Curve(start, controlPoint1, controlPoint2, end),
    //             new Curve(end, end, end, end)
    //         ];
    //     }

    //     let dividerPoints = this.getSkeletonPoints(t);

    //     let startControl1 = dividerPoints.startControlPoint1;
    //     let startControl2 = dividerPoints.startControlPoint2;
    //     let divider = dividerPoints.divider;
    //     let dividerControl1 = dividerPoints.dividerControlPoint1;
    //     let dividerControl2 = dividerPoints.dividerControlPoint2;

    //     // return array with two new curves
    //     return [
    //         new Curve(start, startControl1, startControl2, divider),
    //         new Curve(divider, dividerControl1, dividerControl2, end)
    //     ];
    // },

    // // Returns the distance between the curve's start and end points.
    // endpointDistance: function() {
    //     return this.start.distance(this.end);
    // },

    // // Checks whether two curves are exactly the same.
    // equals: function(c) {
    //     return !!c &&
    //         this.start.x === c.start.x &&
    //         this.start.y === c.start.y &&
    //         this.controlPoint1.x === c.controlPoint1.x &&
    //         this.controlPoint1.y === c.controlPoint1.y &&
    //         this.controlPoint2.x === c.controlPoint2.x &&
    //         this.controlPoint2.y === c.controlPoint2.y &&
    //         this.end.x === c.end.x &&
    //         this.end.y === c.end.y;
    // },

    // Returns five helper points necessary for curve division.
    // getSkeletonPoints: function(t) {

    //     let start = this.start;
    //     let control1 = this.controlPoint1;
    //     let control2 = this.controlPoint2;
    //     let end = this.end;

    //     // shortcuts for `t` values that are out of range
    //     if (t <= 0) {
    //         return {
    //             startControlPoint1: start.clone(),
    //             startControlPoint2: start.clone(),
    //             divider: start.clone(),
    //             dividerControlPoint1: control1.clone(),
    //             dividerControlPoint2: control2.clone()
    //         };
    //     }

    //     if (t >= 1) {
    //         return {
    //             startControlPoint1: control1.clone(),
    //             startControlPoint2: control2.clone(),
    //             divider: end.clone(),
    //             dividerControlPoint1: end.clone(),
    //             dividerControlPoint2: end.clone()
    //         };
    //     }

    //     let midpoint1 = (new Line(start, control1)).pointAt(t);
    //     let midpoint2 = (new Line(control1, control2)).pointAt(t);
    //     let midpoint3 = (new Line(control2, end)).pointAt(t);

    //     let subControl1 = (new Line(midpoint1, midpoint2)).pointAt(t);
    //     let subControl2 = (new Line(midpoint2, midpoint3)).pointAt(t);

    //     let divider = (new Line(subControl1, subControl2)).pointAt(t);

    //     let output = {
    //         startControlPoint1: midpoint1,
    //         startControlPoint2: subControl1,
    //         divider: divider,
    //         dividerControlPoint1: subControl2,
    //         dividerControlPoint2: midpoint3
    //     };

    //     return output;
    // },

    // Returns a list of curves whose flattened length is better than `opt.precision`.
    // That is, observed difference in length between recursions is less than 10^(-3) = 0.001 = 0.1%
    // (Observed difference is not real precision, but close enough as long as special cases are covered)
    // As a rule of thumb, increasing `precision` by 1 requires 2 more iterations (= levels of division operations)
    // - Precision 0 (endpointDistance) - 0 iterations => total of 2^0 - 1 = 0 operations (1 subdivision)
    // - Precision 1 (<10% error) - 2 iterations => total of 2^2 - 1 = 3 operations (4 subdivisions)
    // - Precision 2 (<1% error) - 4 iterations => total of 2^4 - 1 = 15 operations requires 4 division operations on all elements (15 operations total) (16 subdivisions)
    // - Precision 3 (<0.1% error) - 6 iterations => total of 2^6 - 1 = 63 operations - acceptable when drawing (64 subdivisions)
    // - Precision 4 (<0.01% error) - 8 iterations => total of 2^8 - 1 = 255 operations - high resolution, can be used to interpolate `t` (256 subdivisions)
    // (Variation of 1 recursion worse or better is possible depending on the curve, doubling/halving the number of operations accordingly)
    // getSubdivisions: function(opt) {
    //     opt = opt || {};
    //     let precision = (opt.precision === undefined) ? this.PRECISION : opt.precision;
    //     // not using opt.subdivisions
    //     // not using localOpt

    //     let start = this.start;
    //     let control1 = this.controlPoint1;
    //     let control2 = this.controlPoint2;
    //     let end = this.end;

    //     let subdivisions = [new Curve(start, control1, control2, end)];
    //     if (precision === 0) { return subdivisions; }

    //     // special case #1: point-like curves
    //     // - no need to calculate subdivisions, they would all be identical
    //     let isPoint = !this.isDifferentiable();
    //     if (isPoint) { return subdivisions; }

    //     let previousLength = this.endpointDistance();

    //     let precisionRatio = Math.pow(10, -precision);

    //     // special case #2: sine-like curves may have the same observed length in iteration 0 and 1 - skip iteration 1
    //     // - not a problem for further iterations because cubic curves cannot have more than two local extrema
    //     // - (i.e. cubic curves cannot intersect the baseline more than once)
    //     // - therefore starting from iteration = 2 ensures that subsequent iterations do not produce sampling with equal length
    //     // - (unless it's a straight-line curve, see below)
    //     let minIterations = 2; // = 2*1

    //     // special case #3: straight-line curves have the same observed length in all iterations
    //     // - this causes observed precision ratio to always be 0 (= lower than `precisionRatio`, which is our exit condition)
    //     // - we enforce the expected number of iterations = 2 * precision
    //     let isLine = ((control1.cross(start, end) === 0) && (control2.cross(start, end) === 0));
    //     if (isLine) {
    //         minIterations = (2 * precision);
    //     }

    //     // recursively divide curve at `t = 0.5`
    //     // until we reach `minIterations`
    //     // and until the difference between observed length at subsequent iterations is lower than `precision`
    //     let iteration = 0;
    //     while (true) {
    //         iteration += 1;

    //         // divide all subdivisions
    //         let newSubdivisions = [];
    //         let numSubdivisions = subdivisions.length;
    //         for (let i = 0; i < numSubdivisions; i++) {

    //             let currentSubdivision = subdivisions[i];
    //             let divided = currentSubdivision.divide(0.5); // dividing at t = 0.5 (not at middle length!)
    //             newSubdivisions.push(divided[0], divided[1]);
    //         }

    //         // measure new length
    //         let length = 0;
    //         let numNewSubdivisions = newSubdivisions.length;
    //         for (let j = 0; j < numNewSubdivisions; j++) {

    //             let currentNewSubdivision = newSubdivisions[j];
    //             length += currentNewSubdivision.endpointDistance();
    //         }

    //         // check if we have reached minimum number of iterations
    //         if (iteration >= minIterations) {

    //             // check if we have reached required observed precision
    //             let observedPrecisionRatio = ((length !== 0) ? ((length - previousLength) / length) : 0);
    //             if (observedPrecisionRatio < precisionRatio) {
    //                 return newSubdivisions;
    //             }
    //         }

    //         // otherwise, set up for next iteration
    //         subdivisions = newSubdivisions;
    //         previousLength = length;
    //     }
    // },

    // isDifferentiable: function() {
    //     let start = this.start;
    //     let control1 = this.controlPoint1;
    //     let control2 = this.controlPoint2;
    //     let end = this.end;

    //     return !(start.equals(control1) && control1.equals(control2) && control2.equals(end));
    // },

    // // Returns flattened length of the curve with precision better than `opt.precision`; or using `opt.subdivisions` provided.
    // length: function(opt) {
    //     opt = opt || {};
    //     let precision = (opt.precision === undefined) ? this.PRECISION : opt.precision; // opt.precision only used in getSubdivisions() call
    //     let subdivisions = (opt.subdivisions === undefined) ? this.getSubdivisions({ precision: precision }) : opt.subdivisions;
    //     // not using localOpt

    //     let length = 0;
    //     let n = subdivisions.length;
    //     for (let i = 0; i < n; i++) {

    //         let currentSubdivision = subdivisions[i];
    //         length += currentSubdivision.endpointDistance();
    //     }

    //     return length;
    // },

    // // Returns distance along the curve up to `t` with precision better than requested `opt.precision`. (Not using `opt.subdivisions`.)
    // lengthAtT: function(t, opt) {
    //     if (t <= 0) { return 0; }

    //     opt = opt || {};
    //     let precision = (opt.precision === undefined) ? this.PRECISION : opt.precision;
    //     // not using opt.subdivisions
    //     // not using localOpt

    //     let subCurve = this.divide(t)[0];
    //     let subCurveLength = subCurve.length({ precision: precision });

    //     return subCurveLength;
    // },

    // Returns point at requested `ratio` between 0 and 1 with precision better than `opt.precision`; optionally using `opt.subdivisions` provided.
    // Mirrors Line.pointAt() function.
    // For a function that tracks `t`, use Curve.pointAtT().
    // pointAt: function(ratio, opt) {
    //     if (ratio <= 0) { return this.start.clone(); }
    //     if (ratio >= 1) { return this.end.clone(); }

    //     let t = this.tAt(ratio, opt);

    //     return this.pointAtT(t);
    // },

    // // Returns point at requested `length` with precision better than requested `opt.precision`; optionally using `opt.subdivisions` provided.
    // pointAtLength: function(length, opt) {
    //     let t = this.tAtLength(length, opt);

    //     return this.pointAtT(t);
    // },

    // // Returns the point at provided `t` between 0 and 1.
    // // `t` does not track distance along curve as it does in Line objects.
    // // Non-linear relationship, speeds up and slows down as curve warps!
    // // For linear length-based solution, use Curve.pointAt().
    // pointAtT: function(t) {
    //     if (t <= 0) { return this.start.clone(); }
    //     if (t >= 1) { return this.end.clone(); }

    //     return this.getSkeletonPoints(t).divider;
    // },

    // scale: function(sx, sy, origin) {
    //     this.start.scale(sx, sy, origin);
    //     this.controlPoint1.scale(sx, sy, origin);
    //     this.controlPoint2.scale(sx, sy, origin);
    //     this.end.scale(sx, sy, origin);
    //     return this;
    // },

    // Returns a tangent line at requested `ratio` with precision better than requested `opt.precision`; or using `opt.subdivisions` provided.
    // tangentAt: function(ratio, opt) {
    //     if (!this.isDifferentiable()) { return null; }

    //     if (ratio < 0) { ratio = 0; }
    //     else if (ratio > 1) { ratio = 1; }

    //     let t = this.tAt(ratio, opt);

    //     return this.tangentAtT(t);
    // },

    // // Returns a tangent line at requested `length` with precision better than requested `opt.precision`; or using `opt.subdivisions` provided.
    // tangentAtLength: function(length, opt) {
    //     if (!this.isDifferentiable()) { return null; }

    //     let t = this.tAtLength(length, opt);

    //     return this.tangentAtT(t);
    // },

    // Returns a tangent line at requested `t`.
    // tangentAtT: function(t) {

    //     if (!this.isDifferentiable()) { return null; }

    //     if (t < 0) { t = 0; }
    //     else if (t > 1) { t = 1; }

    //     let skeletonPoints = this.getSkeletonPoints(t);

    //     let p1 = skeletonPoints.startControlPoint2;
    //     let p2 = skeletonPoints.dividerControlPoint1;

    //     let tangentStart = skeletonPoints.divider;

    //     let tangentLine = new Line(p1, p2);
    //     tangentLine.translate(tangentStart.x - p1.x, tangentStart.y - p1.y); // move so that tangent line starts at the point requested

    //     return tangentLine;
    // },

    // Returns `t` at requested `ratio` with precision better than requested `opt.precision`; optionally using `opt.subdivisions` provided.
    // tAt: function(ratio, opt) {
    //     if (ratio <= 0) { return 0; }
    //     if (ratio >= 1) { return 1; }

    //     opt = opt || {};
    //     let precision = (opt.precision === undefined) ? this.PRECISION : opt.precision;
    //     let subdivisions = (opt.subdivisions === undefined) ? this.getSubdivisions({ precision: precision }) : opt.subdivisions;
    //     let localOpt = { precision: precision, subdivisions: subdivisions };

    //     let curveLength = this.length(localOpt);
    //     let length = curveLength * ratio;

    //     return this.tAtLength(length, localOpt);
    // },

    // Returns `t` at requested `length` with precision better than requested `opt.precision`; optionally using `opt.subdivisions` provided.
    // Uses `precision` to approximate length within `precision` (always underestimates)
    // Then uses a binary search to find the `t` of a subdivision endpoint that is close (within `precision`) to the `length`, if the curve was as long as approximated
    // As a rule of thumb, increasing `precision` by 1 causes the algorithm to go 2^(precision - 1) deeper
    // - Precision 0 (chooses one of the two endpoints) - 0 levels
    // - Precision 1 (chooses one of 5 points, <10% error) - 1 level
    // - Precision 2 (<1% error) - 3 levels
    // - Precision 3 (<0.1% error) - 7 levels
    // - Precision 4 (<0.01% error) - 15 levels
    // tAtLength: function(length, opt) {
    //     let fromStart = true;
    //     if (length < 0) {
    //         fromStart = false; // negative lengths mean start calculation from end point
    //         length = -length; // absolute value
    //     }

    //     opt = opt || {};
    //     let precision = (opt.precision === undefined) ? this.PRECISION : opt.precision;
    //     let subdivisions = (opt.subdivisions === undefined) ? this.getSubdivisions({ precision: precision }) : opt.subdivisions;
    //     let localOpt = { precision: precision, subdivisions: subdivisions };

    //     // identify the subdivision that contains the point at requested `length`:
    //     let investigatedSubdivision;
    //     let investigatedSubdivisionStartT; // assume that subdivisions are evenly spaced
    //     let investigatedSubdivisionEndT;
    //     //let baseline; // straightened version of subdivision to investigate
    //     //let baselinePoint; // point on the baseline that is the requested distance away from start
    //     let baselinePointDistFromStart; // distance of baselinePoint from start of baseline
    //     let baselinePointDistFromEnd; // distance of baselinePoint from end of baseline
    //     let l = 0; // length so far
    //     let n = subdivisions.length;
    //     let subdivisionSize = 1 / n;
    //     for (let i = 0; i < n; i++) {
    //         let index = (fromStart ? i : (n - 1 - i));

    //         let currentSubdivision = subdivisions[i];
    //         let d = currentSubdivision.endpointDistance(); // length of current subdivision

    //         if (length <= (l + d)) {
    //             investigatedSubdivision = currentSubdivision;

    //             investigatedSubdivisionStartT = index * subdivisionSize;
    //             investigatedSubdivisionEndT = (index + 1) * subdivisionSize;

    //             baselinePointDistFromStart = (fromStart ? (length - l) : ((d + l) - length));
    //             baselinePointDistFromEnd = (fromStart ? ((d + l) - length) : (length - l));

    //             break;
    //         }

    //         l += d;
    //     }

    //     if (!investigatedSubdivision) { return (fromStart ? 1 : 0); } // length requested is out of range - return maximum t
    //     // note that precision affects what length is recorded
    //     // (imprecise measurements underestimate length by up to 10^(-precision) of the precise length)
    //     // e.g. at precision 1, the length may be underestimated by up to 10% and cause this function to return 1

    //     let curveLength = this.length(localOpt);

    //     let precisionRatio = Math.pow(10, -precision);

    //     // recursively divide investigated subdivision:
    //     // until distance between baselinePoint and closest path endpoint is within 10^(-precision)
    //     // then return the closest endpoint of that final subdivision
    //     while (true) {

    //         // check if we have reached required observed precision
    //         let observedPrecisionRatio;

    //         observedPrecisionRatio = ((curveLength !== 0) ? (baselinePointDistFromStart / curveLength) : 0);
    //         if (observedPrecisionRatio < precisionRatio) { return investigatedSubdivisionStartT; }
    //         observedPrecisionRatio = ((curveLength !== 0) ? (baselinePointDistFromEnd / curveLength) : 0);
    //         if (observedPrecisionRatio < precisionRatio) { return investigatedSubdivisionEndT; }

    //         // otherwise, set up for next iteration
    //         let newBaselinePointDistFromStart;
    //         let newBaselinePointDistFromEnd;

    //         let divided = investigatedSubdivision.divide(0.5);
    //         subdivisionSize /= 2;

    //         let baseline1Length = divided[0].endpointDistance();
    //         let baseline2Length = divided[1].endpointDistance();

    //         if (baselinePointDistFromStart <= baseline1Length) { // point at requested length is inside divided[0]
    //             investigatedSubdivision = divided[0];

    //             investigatedSubdivisionEndT -= subdivisionSize; // sudivisionSize was already halved

    //             newBaselinePointDistFromStart = baselinePointDistFromStart;
    //             newBaselinePointDistFromEnd = baseline1Length - newBaselinePointDistFromStart;

    //         } else { // point at requested length is inside divided[1]
    //             investigatedSubdivision = divided[1];

    //             investigatedSubdivisionStartT += subdivisionSize; // subdivisionSize was already halved

    //             newBaselinePointDistFromStart = baselinePointDistFromStart - baseline1Length;
    //             newBaselinePointDistFromEnd = baseline2Length - newBaselinePointDistFromStart;
    //         }

    //         baselinePointDistFromStart = newBaselinePointDistFromStart;
    //         baselinePointDistFromEnd = newBaselinePointDistFromEnd;
    //     }
    // },

    // Returns an array of points that represents the curve when flattened, up to `opt.precision`; or using `opt.subdivisions` provided.
    // Flattened length is no more than 10^(-precision) away from real curve length.
    // toPoints: function(opt) {
    //     opt = opt || {};
    //     let precision = (opt.precision === undefined) ? this.PRECISION : opt.precision; // opt.precision only used in getSubdivisions() call
    //     let subdivisions = (opt.subdivisions === undefined) ? this.getSubdivisions({ precision: precision }) : opt.subdivisions;
    //     // not using localOpt

    //     let points = [subdivisions[0].start.clone()];
    //     let n = subdivisions.length;
    //     for (let i = 0; i < n; i++) {

    //         let currentSubdivision = subdivisions[i];
    //         points.push(currentSubdivision.end.clone());
    //     }

    //     return points;
    // },

    // Returns a polyline that represents the curve when flattened, up to `opt.precision`; or using `opt.subdivisions` provided.
    // Flattened length is no more than 10^(-precision) away from real curve length.
    // toPolyline: function(opt) {

    //     return new Polyline(this.toPoints(opt));
    // },

    // toString: function() {
    //     return this.start + ' ' + this.controlPoint1 + ' ' + this.controlPoint2 + ' ' + this.end;
    // },

    // translate: function(tx, ty) {
    //     this.start.translate(tx, ty);
    //     this.controlPoint1.translate(tx, ty);
    //     this.controlPoint2.translate(tx, ty);
    //     this.end.translate(tx, ty);
    //     return this;
    // }
};