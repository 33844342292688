/* eslint-disable react/jsx-max-depth  */
import React, {useEffect, useMemo, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import PasswordInput from '../inputs/passwordInput/PasswordInput';
import ButtonLoading from '../inputs/button/ButtonLoading';
import useAuth from '../../hooks/UseAuth';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { handleLabelPosition } from '../../helpers/InputLabelHelper';
import LoginSignupHeader from './LoginSignupHeader';
import Scrollbar from 'react-scrollbars-custom';

const Login = ({setLoginPage}) => {
    const passwordInput = useRef(null);
    const navigate = useNavigate();
    const {
        loginGoogle,
        handleSubmit,
        handleChange,
        handleResetValues,
        loading,
        values,
        touched,
        errorMsg,
        errors
    } = useAuth(true);
    
    const _navigateToForgotPasswordScreen = () => {
        navigate('/input_email_to_reset_password');
    };
    
    const checkButtonDisableStatus = useMemo(() => {
        return values.password === '' || values.email === '' || errors.email || ( typeof errorMsg === 'string' && errorMsg !== '');
    }, [values, errors, errorMsg]);
    
    /**
     * @param {Event} event
     */
    const handleEmailChange = (event) => {
        handleChange(event);
    };
    
    useEffect(() => {
        const handler = setTimeout(() => {
            if (values.email !== '' && passwordInput?.current?.value !== '') {
                passwordInput?.current?.parentElement?.previousElementSibling?.classList.add('active');
            }
        }, 100); // Debounce delay set to 300ms
        
        return () => {
            clearTimeout(handler); // Clear the timeout if email changes before the delay is over
        };
    }, [values.email]);
    
    useEffect(()=> {
        window.addEventListener('resize', () => {
            const viewportHeight = window.innerHeight;
            document.documentElement.style.setProperty('--vh', `${viewportHeight * 0.01}px`);
        });
    },[])
    
    return (
        <div className="loginContainer">
            <Scrollbar trackYVisible={true} style={{ position: '' }}>
                <form className="formElements loginBlock" data-testid="login-screen">
                    <LoginSignupHeader
                        onClick={loginGoogle}
                        page='login'
                    />
                    <ul>
                        <li>
                            <div>
                                <label>Enter email</label>
                                <input
                                    title=''
                                    autoComplete="email"
                                    className={clsx({'error': errors.email || errorMsg})}
                                    data-testid="email-login-input"
                                    id="email"
                                    maxLength="100"
                                    name="email"
                                    onChange={handleEmailChange}
                                    onFocus={handleLabelPosition}
                                    onBlur={handleLabelPosition}
                                    type="email"
                                    value={values.email}
                                />
                            </div>
                            {touched.email && errors.email ?
                                <div className={clsx('errorMsgBox', {'showError': errors.email})}
                                    data-testid="error-email-login">
                                    <div className="errorMsg">{errors.email}</div>
                                </div> : null
                            }
                        </li>
                        <li>
                            <div>
                                <label>Password</label>
                                <PasswordInput
                                    ref={passwordInput}
                                    className={clsx('input', {'error': errors.password || errorMsg})}
                                    minLength="1"
                                    name="password"
                                    onChange={handleChange}
                                    onFocus={handleLabelPosition}
                                    onBlur={handleLabelPosition}
                                    value={values.password}
                                />
                            </div>
                            {touched.password && errors.password ?
                                <div className={clsx('errorMsgBox', {'showError': errors.password})}
                                    data-testid="error-password-login">
                                    <div className="errorMsg">{errors.password}</div>
                                </div> : null
                            }
                            {errorMsg ? <div className="errorMsgBox showError">
                                <div className="errorMsg" data-testid="error-message-login">{errorMsg}</div>
                            </div> : null
                            }
                        </li>
                    </ul>
                    <div className="forgotPasswordContainer">
                        <a
                            className="forgotPasswordText"
                            data-testid="forgot-password-text"
                            onClick={_navigateToForgotPasswordScreen}
                        >
                            Forgot password?
                        </a>
                    </div>
                    <div className="footersection">
                        <div className="actionbtn">
                            <div className={clsx('sign-in-button-wrapper', {disabled: checkButtonDisableStatus})}>
                                <ButtonLoading
                                    disabled={checkButtonDisableStatus}
                                    className={clsx('doneButton', {loading: loading})}
                                    isLoading={loading}
                                    loadingClassName="loading"
                                    onClick={handleSubmit}
                                    text="Log in"
                                    type="submit"
                                />
                                {
                                    checkButtonDisableStatus ? <span className='warning-tooltip'>
                                        Please fill in details to login
                                    </span> : null
                                }
                            </div>
                        </div>
                        <div className="newUser">Are you a new user? <span data-testid="signup-span"
                            onClick={() => handleResetValues(setLoginPage)}>Sign up</span>
                        </div>
                    </div>
                </form>
            </Scrollbar>
        </div>
    );
}

Login.propTypes = {
    setLoginPage: PropTypes.func
}

export default Login;