import { environment } from '../environment';

export const setCookie = (name, value, expiryDate = '2050-01-19 04:14:07', path = '/') => {
    document.cookie = `${name}=${value};expires=${expiryDate};path=${path}`;
}

export const getCookie = (name) => {
    const regexp = new RegExp(name + '=([^;]+)');
    return regexp.exec(document.cookie);
}

export const deleteCookie = () => {
    document.cookie = `${environment.USER_COOKIE}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;`;
}

export const setLocalStorage = (name, value) => {
    try {
        if (typeof window?.localStorage !== 'undefined') {
            localStorage.setItem(name, value);
        } else {
            console.log('localStorage not accessible');
        }
    }
    catch (err) {
        console.log('localStorage not accessible', err);
    }
}

export const getLocalStorage = (name) => {
    try {
        if (typeof window?.localStorage !== 'undefined') {
            return localStorage.getItem(name);
        } else {
            console.log('localStorage not accessible');
            return null;
        }
    }
    catch (err) {
        console.log('localStorage not accessible', err);
    }
}