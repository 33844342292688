/**
 * @param imageUrl
 */
export async function convertImageUrlToDataUrl(imageUrl) {
    // Fetch the image from the URL
    const response = await fetch(imageUrl);
  
    // Check if the response is successful
    if (!response.ok) {
        throw new Error(`Failed to fetch the image: ${response.statusText}`);
    }
  
    // Create a Blob from the fetched image data
    const imageBlob = await response.blob();
  
    // Convert the Blob to a DataURL using the FileReader API
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
  
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = () => reject(new Error('Failed to convert the Blob to a DataURL'));
  
        reader.readAsDataURL(imageBlob);
    });
}