import { fabric } from 'fabric';
import { mouseUpHandlerForConnectors, connectorControlActionHandler, mouseDownHandlerForConnectors } from './ActionHandler';
import { renderConnectorControlDots } from './ConnectorControlMethods';

export const connectorControlLeft = (ableToDuplicate = false) => {
    const options = {
        x: -0.5,
        y: 0,
        offsetX: -16,
        cursorStyle: 'pointer',
        mouseUpHandler: mouseUpHandlerForConnectors,
        render: renderConnectorControlDots,
        cornerSize: 16,
        actionName: 'connector',
        actionHandler: connectorControlActionHandler,
    }

    if (ableToDuplicate) {
        options.mouseDownHandler = mouseDownHandlerForConnectors;
    }

    return new fabric.Control(options);
}

export const connectorControlTop = (ableToDuplicate = false) => {
    const options = {
        x: 0,
        y: -0.5,
        offsetY: -16,
        cursorStyle: 'pointer',
        mouseUpHandler: mouseUpHandlerForConnectors,
        render: renderConnectorControlDots,
        cornerSize: 16,
        actionName: 'connector',
        actionHandler: connectorControlActionHandler,
    }

    if (ableToDuplicate) {
        options.mouseDownHandler = mouseDownHandlerForConnectors;
    }

    return new fabric.Control(options);
}

export const connectorControlRight = (ableToDuplicate = false) => {
    const options = {
        x: 0.5,
        y: 0,
        offsetX: 16,
        cursorStyle: 'pointer',
        mouseUpHandler: mouseUpHandlerForConnectors,
        render: renderConnectorControlDots,
        cornerSize: 16,
        actionName: 'connector',
        actionHandler: connectorControlActionHandler,
    }

    if (ableToDuplicate) {
        options.mouseDownHandler = mouseDownHandlerForConnectors;
    }

    return new fabric.Control(options);
}

export const connectorControlBottom = (ableToDuplicate = false) => {
    const options = {
        x: 0,
        y: 0.5,
        offsetY: 16,
        cursorStyle: 'pointer',
        mouseDownHandler: mouseDownHandlerForConnectors,
        mouseUpHandler: mouseUpHandlerForConnectors,
        render: renderConnectorControlDots,
        cornerSize: 16,
        actionName: 'connector',
        actionHandler: connectorControlActionHandler,
    }

    if (ableToDuplicate) {
        options.mouseDownHandler = mouseDownHandlerForConnectors;
    }

    return new fabric.Control(options);
}