import { Point } from './Point';

export class Rect {
    constructor(x,y,w,h) {
        if (!(this instanceof Rect)) {
            return new Rect(x, y, w, h);
        }
    
        if ((Object(x) === x)) {
            y = x.y;
            w = x.width;
            h = x.height;
            x = x.x;
        }
    
        this.x = x === undefined ? 0 : x;
        this.y = y === undefined ? 0 : y;
        this.width = w === undefined ? 0 : w;
        this.height = h === undefined ? 0 : h;       
    }

    type = '4'

    center() {
        return new Point(this.x + this.width / 2, this.y + this.height / 2);
    }

    // @return {string} (left|right|top|bottom) side which is nearest to point
    // @see Squeak Smalltalk, Rectangle>>sideNearestTo:
    sideNearestToPoint(point) {
        point = new Point(point);

        let distToLeft = point.x - this.x;
        let distToRight = (this.x + this.width) - point.x;
        let distToTop = point.y - this.y;
        let distToBottom = (this.y + this.height) - point.y;
        let closest = distToLeft;
        let side = 'left';

        if (distToRight < closest) {
            closest = distToRight;
            side = 'right';
        }
        if (distToTop < closest) {
            closest = distToTop;
            side = 'top';
        }
        if (distToBottom < closest) {
            // closest = distToBottom;
            side = 'bottom';
        }

        return side;
    }
}