import './ColorPicker.scss';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { CustomPicker } from 'react-color';
import { Saturation, Hue, Alpha } from 'react-color/lib/components/common';
import clsx from 'clsx';
import { extractRGBAValues, getBrightnessFromRGBA, getRgbaAsString, getRgbAsString } from '../../helpers/ColorHelper';
import { hexCodeValidation } from '../../helpers/Validation';
import { debounce } from '../../helpers/OptimizationUtils';
import { HEX_CODE_NON_SUPPORTED_CHARS } from '../../helpers/Regexes';
import { getRgbaVal } from '../../helpers/toolbar/ActiveStyles';

const DEFAULT_COLORS = ['rgba(124, 77, 255, 1)', 'rgba(255, 0, 0, 1)', 'rgba(255, 153, 0, 1)', 'rgba(255, 214, 77, 1)', 'rgba(75, 180, 58, 1)', 'rgba(0, 168, 255, 1)', 'rgba(0, 0, 0, 1)', 'rgba(151, 151, 151, 1)', 'rgba(236, 237, 240, 1)'];

const CustomPointer = () => <div className="custom-pointer" />;

const ColorPicker = ({
    recentColors,
    updateRecentColor,
    isColorSelectedRef,
    ...props
}) => {
    const [hexInputVal, setHexInputVal] = useState(null);
    const hexInputRef = useRef();
    const isHexInputInFocus = useRef();
    const alphaInputRef = useRef();
    const isColorChangedOnce = useRef(false);
    const [isColorPickerVisible, setIsColorPickerVisible] = useState(false);

    const changeColor = (color, event) => {
        isColorChangedOnce.current = true;
        props.onChange(color, event);
    }

    const updateHexChanges = useCallback(debounce(changeColor, 300), []);

    const onAlphaChanged = (event) => {
        const strVal = event?.target?.value ?? '';
        let val = parseInt(strVal) ?? 0;

        if (val > 100) {
            return;
        } else if (val <= 0 || Number.isNaN(val)) {
            val = 0;
        }

        changeColor({
            ...props.hsl,
            source: 'rgb',
            a: val === 0 ? 0 : val / 100
        }, event);
    }

    const selectColor = (rgba) => {
        if (isColorPickerVisible) {
            setIsColorPickerVisible(false);
        }

        const [r,g,b,a] = extractRGBAValues(rgba);

        isColorSelectedRef.current = true;
        changeColor({ r, g, b, a, source: 'rgb' });
        isColorSelectedRef.current = false;
    }

    const onHexInputChanged = (event) => {
        let val = event?.target?.value;
        if (!val || typeof val !== 'string') {
            val = '';
        }

        if (val.startsWith('#')) {
            val = val.slice(1);
        }

        val = val.slice(0, 6);
        val = val.replace(HEX_CODE_NON_SUPPORTED_CHARS, '');

        setHexInputVal(val);

        if (hexCodeValidation(val)) {
            updateHexChanges({
                hex: val,
                source: 'hex'
            });
        }
    }

    const checkColor = (color) => {
        if (!color) return false;

        if (typeof color === 'object' && Number.isInteger(color.r) && Number.isInteger(color.g) && Number.isInteger(color.b)) {
            color = getRgbaAsString(color);
        }

        if (color.startsWith('rgba')) {
            return color === getRgbaAsString(props.rgb);
        } else if (color.startsWith('rgb')) {
            return color === getRgbAsString(props.rgb);
        }

        return color.toLowerCase() === props.hex.toLowerCase();
    }

    const checkContrast = (color) => {
        if (typeof color === 'object' && Number.isInteger(color.r) && Number.isInteger(color.g) && Number.isInteger(color.b)) {
            color = getRgbaAsString(color);
        }

        const colorPickerBackground = getBrightnessFromRGBA('rgba(255, 255, 255, 1)');
        const colorBg = getBrightnessFromRGBA(color);

        const rgb = getRgbaVal(color);

        const contrast = Math.abs(colorBg - colorPickerBackground)
        return contrast < 50 || rgb.a < 0.5;
    }

    const alphaInputValue = useMemo(() => {
        const alpha = Math.round(props.hsl.a * 100);
        
        if (alpha === 0 && document.activeElement === alphaInputRef.current) {
            return '';
        }

        return alpha.toString();
    }, [props.hsl.a]);

    useEffect(() => {
        setTimeout(() => {
            if (!hexInputRef.current || (hexInputRef.current && hexInputRef.current !== document.activeElement)) {
                const val = props.hex === 'transparent' ? '' : props.hex.slice(1);
                setHexInputVal(val);
            }
        }, 0);
    }, [props.hex]);

    useEffect(() => {
        if (isColorChangedOnce.current !== true) return;
        if (!props.color) return;

        const rgb = getRgbaVal(props.color);
        if (rgb.a === 0) return;

        updateRecentColor(props.color);
        // Update it as false. Otherwise, if selection is changed, we are updating recent colors again and again.
        isColorChangedOnce.current = false;
    }, [props.color]);

    return (
        <div className="color-picker">

            <div className="recent-colors-wrapper">
                <span className="title">Recents</span>

                <div className="colors-wrapper">
                    {recentColors.map((color, idx) => (
                        <div
                            key={`${color}_${idx}`}
                            className={clsx('color-item', { active: checkColor(color), bordered: checkContrast(color) })}
                            onClick={() => selectColor(color)}
                        >
                            <div className="color-box" style={{ backgroundColor: color }} />
                        </div>
                    ))}
                </div>
            </div>

            <div className="all-colors-wrapper">
                <span className="title">All Colors</span>

                <div className="colors-wrapper">
                    {DEFAULT_COLORS.map((color) => (
                        <div
                            key={color}
                            className={clsx('color-item', { active: checkColor(color) })}
                            onClick={() => selectColor(color)}
                        >
                            <div className="color-box" style={{ backgroundColor: color, bordered: checkContrast(color) }} />
                        </div>
                    ))}
                    
                    <div
                        className={clsx('color-item switcher', { active: isColorPickerVisible })}
                        onClick={() => setIsColorPickerVisible((state) => !state)}
                    >
                        <div className="color-box" />
                    </div>
                </div>
            </div>

            {isColorPickerVisible ? (
                <>
                    <div className="saturation-wrapper">
                        <Saturation
                            {...props}
                            onChange={changeColor}
                            pointer={CustomPointer}
                        />
                    </div>

                    <div className="hue-wrapper">
                        <Hue
                            {...props}
                            onChange={changeColor}
                            pointer={CustomPointer}
                            direction="horizontal"
                        />
                    </div>
                    
                    <div className="alpha-wrapper">
                        <Alpha
                            {...props}
                            pointer={CustomPointer}
                            onChange={changeColor}
                            direction="horizontal"
                            rgb={{
                                ...props.rgb,
                                a: props.hex === 'transparent' ? 1 : props.rgb.a
                            }}
                        />
                    </div>

                    <div className="hex-wrapper">
                        <div className="hex-input-wrapper">
                            <label htmlFor="rc-editable-input-1">Hex</label>

                            <input
                                type="text"
                                id="rc-editable-input-1"
                                value={hexInputVal}
                                defaultValue={props.hex === 'transparent' ? '' : props.hex.slice(1)}
                                onFocus={() => isHexInputInFocus.current = true}
                                onBlur={() => isHexInputInFocus.current = false}
                                ref={hexInputRef}
                                onChange={onHexInputChanged}
                            />
                        </div>

                        <div className="alpha-input-wrapper">
                            <input
                                type="number"
                                ref={alphaInputRef}
                                value={(props.hex === 'transparent' && alphaInputRef.current !== document.activeElement) ? 100 : alphaInputValue}
                                onChange={onAlphaChanged}
                            />

                            <span>%</span>
                        </div>
                    </div>
                </>
            ) : null}

        </div>
    )
}

ColorPicker.propTypes = {
    color: PropTypes.string,
    hsl: PropTypes.shape({
        h: PropTypes.number.isRequired,
        s: PropTypes.number.isRequired,
        l: PropTypes.number.isRequired,
        a: PropTypes.number.isRequired
    }).isRequired,
    hex: PropTypes.string.isRequired,
    rgb: PropTypes.shape({
        r: PropTypes.number.isRequired,
        g: PropTypes.number.isRequired,
        b: PropTypes.number.isRequired,
        a: PropTypes.number.isRequired
    }).isRequired,
    hsv: PropTypes.shape({
        h: PropTypes.number.isRequired,
        s: PropTypes.number.isRequired,
        v: PropTypes.number.isRequired,
        a: PropTypes.number.isRequired
    }).isRequired,
    oldHue: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    recentColors: PropTypes.arrayOf(PropTypes.string),
    updateRecentColor: PropTypes.func.isRequired,
    isColorSelectedRef: PropTypes.shape({
        current: PropTypes.bool
    }).isRequired
}

ColorPicker.defaultProps = {
    recentColors: []
}

export default CustomPicker(ColorPicker);