import { useEffect, useState } from 'react';


const useUser = (users, currentUserId) => {
    const [currentUser, setCurrenUser] = useState({});
    useEffect(() => {
        if (currentUserId && users.length > 0) {
            setCurrenUser(users.find(({ id }) => id === currentUserId));
        }
    }, [users, currentUserId]);
    return currentUser;
}


export default useUser;