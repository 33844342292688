import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    activityHistory: [],
};

export const activitySlice = createSlice({
    name: 'activityHistory',
    initialState,
    reducers: {
        getActivity: (state,action) => {
            state.activityHistory = action.payload;
        },
        modifyActivity: (state, action) => {
            const parsePayload = JSON.parse(JSON.stringify(action.payload));
            state.activityHistory = [parsePayload, ...state.activityHistory];
        }
    }
});

export default activitySlice.reducer;