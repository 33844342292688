
export const isClipboardApiSupported = async () => {
    if (!('clipboard' in navigator)) return false;
    if (!('permissions' in navigator)) return false;

    try {
        const queryOpts = { name: 'clipboard-read', allowWithoutGesture: false };
        const permissionStatus = await navigator.permissions.query(queryOpts);
        // Will be 'granted', 'denied' or 'prompt':
        console.log('Clipboard API -> Permission: ', permissionStatus.state);
        return permissionStatus.state !== 'denied';
    } catch (err) {
        console.error('Failed to check permissions for clipboard', err);
        return false;
    }
}

/**
 * Copying text to the clipboard via traditional way (via input).
 * @param {string} text 
 * @returns Boolean.
 */
export const copyToClipboardViaInput = (text) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.opacity = 0;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
        document.execCommand('copy');
        return true;
    } catch (err) {
        console.error('Failed to copy to clipboard', err);
        return false;
    } finally {
        document.body.removeChild(textArea);
    }
}

/**
 * Copy text to clipboard.
 * @param {string} text 
 * @returns Promise<boolean>.
 */
export const copyToClipboard = async (text) => {
    const isSupported = await isClipboardApiSupported();
    if (isSupported) {
        try {
            navigator.clipboard.writeText(text);
            return true;
        } catch (err) {
            console.error('Failed to copy to clipboard', err);
            console.log('Trying the old way...');
            return copyToClipboardViaInput(text);
        }
    }

    return copyToClipboardViaInput(text);
}

/**
 * Returns plain text from clipboard.
 * @param {ClipboardEvent} event - Clipboard event.
 * @param {'plain' | 'html'} type - Type of text to be returned.
 */
export const getTextFromClipboard = async (event, type = 'plain') => {
    const clipboardData = event.clipboardData || window.clipboardData;
    return clipboardData.getData(`text/${type}`);
}