import {useCallback, useEffect, useState} from 'react';
import {emailValidation, nameValidation, passwordValidation} from '../helpers/Validation';
import {AUTH_VALIDATION_MESSAGE} from '../helpers/Constant';
import store from '../redux/Store';
import {setCookie} from '../services/CookieService';
import {environment} from '../environment';
import {loggedInUser, loginViaEmailPassword, loginWithGoogle} from '../services/AuthService';
import getParamFromURL from '../helpers/IframeHelper';
import {useNavigate} from 'react-router-dom';
import {register} from '../services/AuthService';
import {toast} from 'react-toastify';
import {useGoogleLogin} from '@react-oauth/google';
import getToastIcon from '../helpers/media/GetToastIcon';

const useAuth = (isLoginPage = false, setLoginPage) => {
    const navigate = useNavigate();
    
    const navigateToBoards = useCallback(() => {
        // if the user is coming from a share link, then redirect to the board with the shape
        const savingShapeUuid = getParamFromURL('shape', { testWithUuid: false });
        const pageId = getParamFromURL('pageId');
        const boardId = getParamFromURL('board');
        const urlParams = new URLSearchParams(window.location.search);
        
        if (savingShapeUuid && boardId) {
            let url = `/board/${boardId}?shape=${savingShapeUuid}`;
            if (pageId) {
                url += `&pageId=${pageId}`;
            }

            navigate(url);
        } else if (urlParams.has('callback')) {
            const link = decodeURIComponent(urlParams.get('callback'));
            navigate(link || '/boards');
        } else {
            navigate('/boards');
        }
    }, [navigate]);
    
    const loginGoogle = useGoogleLogin({
        onSuccess: async (credentialResponse) => {
            const response = await loginWithGoogle(credentialResponse.access_token);
            if (response.errorCode) {
                toast.error(response.error, {
                    icon: getToastIcon('error'),
                    className: 'wb_toast',
                });
            } else {
                setCookie(environment.USER_COOKIE, JSON.stringify(response));
                store.dispatch({ type: 'user/setUser', payload: response });
                navigateToBoards();
            }
        },
        onError: () => {
            toast.error(AUTH_VALIDATION_MESSAGE.COULD_NOT_COMPLETE_ACTION, {
                icon: getToastIcon('error'),
                className: 'wb_toast',
            });
        },
    });
    
    const checkboxValidation = () => {
        if (agree) {
            return null;
        } else {
            return AUTH_VALIDATION_MESSAGE.PLEASE_SELECT_TERM;
        }
    }
    
    const validate = {
        email: emailValidation,
        name: name => nameValidation('Name', name),
        password: passwordValidation,
        terms: checkboxValidation
    };
    
    const initialValues = {
        email: '',
        name: '',
        password: '',
        terms: false
    };
    
    const [agree, setAgree] = useState(false),
        [errorMsg, setErrorMsg] = useState(''),
        [values, setValues] = useState(initialValues),
        [errors, setErrors] = useState({}),
        [touched, setTouched] = useState({}),
        [loading, setLoading] = useState(false);
    const handleResetValues = (setLoginPage) => {
        toast.dismiss();
        setErrorMsg('');
        setErrors({});
        const toastElement = document.querySelector('.auth-toast-container');
        const unauthorizedToast = document.querySelector('#unauthorized_user');
        
        const navigateToLoginWithReset = () => {
            setValues(initialValues);
            setLoginPage(prevState => !prevState);
        }
    
        if(toastElement || unauthorizedToast){
            setTimeout(()=> navigateToLoginWithReset(), 1000)
            return
        }
        navigateToLoginWithReset();
    }
    
    const handleCheckBox = (evt) => {
        setAgree(evt.target.checked);
        // eslint-disable-next-line
        const { terms, ...rest } = errors;
        const error = checkboxValidation;
        if (evt.target.checked) {
            setErrors({
                ...rest,
                ...(error && { }),
            });
            return
        }

        setErrors({
            ...rest,
            terms: AUTH_VALIDATION_MESSAGE.PLEASE_SELECT_TERM
        });
    }
    
    const handleChange = evt => {
        const { name, value: newValue, type } = evt.target;
        // eslint-disable-next-line
        const { [name]: removedError, ...rest } = errors;
        
        if(isLoginPage) setErrorMsg('');
        const value = type === 'number' ? +newValue : newValue;
        
        if(name === 'name' && newValue.trim().length > 50) setValues({
            ...values,
            [name]: newValue.trim().substring(0, 50)
        })
        
        else if(name === 'email' && newValue.trim().length > 100) setValues({
            ...values,
            email: newValue.trim().substring(0, 100)
        })
        
        else{
            setValues({
                ...values,
                [name]: value,
            });
        }
        
        setTouched({
            ...touched,
            [name]: true,
        });

        const error = name === 'password' && isLoginPage === true
            ? validate[name](value, false, true)
            : validate[name](value);

        setErrors({
            ...rest,
            ...(error && { [name]: error }),
        });
    };
    
    const handleSubmit = evt => {
        evt.preventDefault();
        const formValidation = Object.keys(values).reduce(
            (acc, key) => {
                const newError =
          key === 'password' && isLoginPage === true
              ? validate[key](values[key], false, true)
              : validate[key](values[key]);
                const newTouched = { [key]: true };
                return {
                    errors: {
                        ...acc.errors,
                        ...(newError && { [key]: newError }),
                    },
                    touched: {
                        ...acc.touched,
                        ...newTouched,
                    },
                };
            },
            {
                errors: { ...errors },
                touched: { ...touched },
            },
        );
        setErrors(formValidation.errors);
        setTouched(formValidation.touched);

        if (isLoginPage) {
            if(formValidation.errors.email === undefined && formValidation.errors.password === undefined) {
                setLoading(true);
                loginViaEmailPassword(values).then(data => {
                    setLoading(false);
                    if (data.success !== true) {
                        setErrorMsg(data.message);
                    } else {
                        setCookie(environment.USER_COOKIE, JSON.stringify(data));
                        store.dispatch({ type: 'user/setUser', payload: data });
                        navigateToBoards(isLoginPage);
                    }
                });
            }
        } else if (
            !Object.values(formValidation.errors).length &&
              Object.values(formValidation.touched).length ===
              Object.values(values).length &&
              Object.values(formValidation.touched).every(t => t === true)
        ) {
            setLoading(true);
            register(values).then(data => {
                setLoading(false);
                // if there is an error, then show the error
                if (data?.error) {
                    // find the related error name
                    let errorName = null, errorMsgLower = data?.error?.toLowerCase();
                    if (errorMsgLower?.includes('email')) {
                        errorName = 'email';
                    } else if (errorMsgLower?.includes('password')) {
                        errorName = 'password';
                    } else if (errorMsgLower?.includes('name')) {
                        errorName = 'name';
                    }
                    // if the related error name is found, then show the error
                    if (errorName === 'email') {
                        setErrors({
                            ...errors,
                            email: true
                        })
                        toast.info(
                            <span className="auth_toast__text">
                                <span className="auth_toast__text--main">This user already exists</span>
                                <span onClick={() => {
                                    toast.dismiss();
                                    setTimeout(()=>{
                                        setLoginPage(true);
                                    },1000)
                                }} className="auth_toast__button toast_btn">Login</span>
                            </span>,
                            {
                                icon: false,
                                hideProgressBar: true,
                                hideCancelButton: true,
                                autoClose: true,
                                closeButton: false,
                                className: 'wb_toast wb_toast__auth',
                                draggable: false,
                                toastId: `auth`
                            })
                    } else {
                        // otherwise show the generic error
                        setErrorMsg(data.error);
                    }
                } else {
                    setCookie(environment.USER_COOKIE, JSON.stringify(data));
                    store.dispatch({ type: 'user/setUser', payload: data });
                    navigateToBoards();
                }
            });
        }
    };
    
    (0, useEffect)(() => {
        if (loggedInUser()) {
            navigateToBoards();
        }
    }, [navigateToBoards]);
    
    return {
        loginGoogle,
        handleSubmit,
        handleChange,
        handleResetValues,
        loading,
        values,
        touched,
        errorMsg,
        errors,
        agree,
        handleCheckBox
    }
}

export default useAuth;