import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { isUserHasAccessToFeature } from '../../../helpers/CommonFunctions';

const ActivityLogButton = ({
    userAccessRef,
    isLeftDrawerOpen,
    onActivityLogButtonClicked,
    handleToggleCommentDrawer
}) => {
    const onButtonClick = () => {
        handleToggleCommentDrawer({ shouldShow: false });
        if (isUserHasAccessToFeature('activity_log_panel', userAccessRef.current)) {
            onActivityLogButtonClicked(!isLeftDrawerOpen)
        }
    }

    return (
        <div
            className={clsx('tabOpenerBottom', 'bottomBarButton', {
                disabled: !isUserHasAccessToFeature('activity_log_panel', userAccessRef.current),
                active: isLeftDrawerOpen
            })}
            data-tutorial='activity-logs'
            onClick={onButtonClick}
        >
            <em className="icon-canvas-redesign-history" />
            <span className="tooltiptext">Activity log</span>
        </div>
    )
};

ActivityLogButton.propTypes = {
    userAccessRef: PropTypes.shape({
        current: PropTypes.oneOf(['view', 'comment', 'edit', 'removeAccess', 'NOT_ALLOWED'])
    }),
    isLeftDrawerOpen: PropTypes.bool,
    onActivityLogButtonClicked: PropTypes.func,
    handleToggleCommentDrawer: PropTypes.func.isRequired
}

export default ActivityLogButton;
