import React, { useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types'
import { TOOLBAR_MENU } from '../../../helpers/Constant';
import FontStyleMenu from '../menus/FontStyleMenu';

const FontStyleItem = ({
    isDisabled,
    onClick,
    showMenu,
    toggleMenu,
    getActiveFontStyle
}) => {
    const textStyleTooltipRef = useRef();

    const menuOnClickHandler = () => {
        if (isDisabled) return;
        toggleMenu(TOOLBAR_MENU.FONT_STYLE);
    }

    return (
        <div className={clsx('fontStyleDDL', { disabled: isDisabled })} onClick={menuOnClickHandler}>
            <em className="icon-bold" /><em className="icon-arrow-down" />

            {showMenu ? (
                <FontStyleMenu
                    activeFontStyle={getActiveFontStyle()}
                    onClickHandler={(style, value) => onClick(style, value)}
                    tooltipRef={textStyleTooltipRef}
                />
            ) : ''}

            <span className={clsx('tooltipText tooltipText--longContent', {
                'onBottom': showMenu
            })} ref={textStyleTooltipRef}>Font Style</span>
        </div>
    )
};

FontStyleItem.propTypes = {
    isDisabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    showMenu: PropTypes.bool,
    toggleMenu: PropTypes.func.isRequired,
    getActiveFontStyle: PropTypes.func.isRequired,
}

export default FontStyleItem;