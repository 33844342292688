
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import CommentItem from '../item/CommentItem';

import './CommentItemWrapper.scss';


/**
 * Comment Item wrapper for the comment tab.
 * It renders the comment item and the comment item's replies.
 * @param {object} props
 * @param props.comment
 * @param props.currentUserId
 * @param props.wrapperRef
 * @param props.users
 * @param props.handleUpdateCommentContent
 * @param props.selectedCommentIcon
 * @param props.setSelectedCommentIcon
 * @param props.handleDeleteComment
 * @param props.handleResolveComment
 * @param props.handleReadComment
 * @param props.handleMarkAsUnreadComment
 * @param props.userAccess
 * @param props.markingReplies
 * @param props.setMarkingReplies
 * @param props.canvas
 */
const CommentItemWrapper = ({
    comment,
    currentUserId,
    wrapperRef,
    users,
    handleUpdateCommentContent,
    selectedCommentIcon,
    setSelectedCommentIcon,
    handleDeleteComment,
    handleResolveComment,
    handleReadComment,
    handleMarkAsUnreadComment,
    userAccess,
    markingReplies,
    setMarkingReplies,
    canvas
}) => {
    const [showReplies, setShowReplies] = useState(false);
    const [hideReplies, setHideReplies] = useState(false);
    const [triggerMarks, setTriggerMarks] = useState(false);

    useEffect(() => {
        const markCount = document.querySelectorAll(`.commentItemWrapper__replies.comment_${comment.id} mark`).length;
        if (markCount === 0) {
            setShowReplies(false);
        }

        if (markCount > 0) {
            setShowReplies(true);
            if (setMarkingReplies) setMarkingReplies(prevState => !prevState);
        }
    }, [triggerMarks, markingReplies])

    return (
        <div className="commentItemWrapper">
            <CommentItem
                canvas={canvas}
                handleDeleteComment={handleDeleteComment}
                handleResolveComment={handleResolveComment}
                handleReadComment={handleReadComment}
                handleMarkAsUnreadComment={handleMarkAsUnreadComment}
                handleUpdateContent={handleUpdateCommentContent}
                isOwner={comment.userId === currentUserId}
                isParent
                parentUuid={comment.uuid}
                selectedComment={comment}
                selectedCommentIcon={selectedCommentIcon}
                setSelectedCommentIcon={setSelectedCommentIcon}
                setShowReplies={(value) => {
                    setHideReplies(!value)
                    setShowReplies(value)
                }}
                setMarkingReplies={setMarkingReplies}
                showReplies={showReplies}
                userAccess={userAccess}
                users={users}
                wrapperRef={wrapperRef}
                isDrawer
                setTriggerMarks={setTriggerMarks}
            />
            {!hideReplies ? <div className={`commentItemWrapper__replies comment_${comment.id}`} style={{ display: showReplies ? 'block' : 'none' }}>
                {comment?.replies?.map((reply) => (
                    <CommentItem
                        canvas={canvas}
                        handleDeleteComment={handleDeleteComment}
                        handleUpdateContent={handleUpdateCommentContent}
                        handleResolveComment={handleResolveComment}
                        handleReadComment={handleReadComment}
                        handleMarkAsUnreadComment={handleMarkAsUnreadComment}
                        isOwner={reply.userId === currentUserId}
                        key={reply.uuid}
                        parentUuid={comment.uuid}
                        selectedComment={reply}
                        selectedCommentIcon={selectedCommentIcon}
                        setSelectedCommentIcon={setSelectedCommentIcon}
                        userAccess={userAccess}
                        users={users}
                        wrapperRef={wrapperRef}
                        isDrawer
                        isReply
                    />
                ))}
            </div> : null}
        </div>
    )
}

CommentItemWrapper.propTypes = {
    comment: {
        uuid: PropTypes.string,
        createdAt: PropTypes.string,
        colorCode: PropTypes.string,
        replies: PropTypes.arrayOf(PropTypes.shape({
            userId: PropTypes.number,
            uuid: PropTypes.string,
            content: PropTypes.string,
            parentUuid: PropTypes.string,
            isDeleted: PropTypes.bool,
            user: PropTypes.shape({
                name: PropTypes.string,
            }),
            createdAt: PropTypes.string,
            userComment: PropTypes.arrayOf(PropTypes.shape({
                readAt: PropTypes.string
            }))
        })),
        userId: PropTypes.number,
        content: PropTypes.string,
        user: {
            name: PropTypes.string
        },
        userComment: PropTypes.arrayOf(PropTypes.shape({
            readAt: PropTypes.string
        })),
        taggedUserIds: PropTypes.array,
        isEdited: PropTypes.bool
    },
    currentUserId: PropTypes.number,
    wrapperRef: PropTypes.shape({
        current: PropTypes.instanceOf(HTMLDivElement)
    }),
    users: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        email: PropTypes.string,
        permission: PropTypes.string,
        commentUsername: PropTypes.string,
        commentEmail: PropTypes.string
    })),
    handleUpdateCommentContent: PropTypes.func,
    selectedCommentIcon: PropTypes.object,
    setSelectedCommentIcon: PropTypes.func,
    handleDeleteComment: PropTypes.func,
    handleResolveComment: PropTypes.func,
    handleReadComment: PropTypes.func,
    handleMarkAsUnreadComment: PropTypes.func,
    userAccess: PropTypes.oneOf(['view', 'comment', 'edit', 'removeAccess', 'NOT_ALLOWED']),
    markingReplies: PropTypes.bool,
    setMarkingReplies: PropTypes.func,
    canvas: PropTypes.object
}

export default CommentItemWrapper;