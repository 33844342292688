import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { SUBTOOLBAR_FILTER_ITEMS, TOOLBAR_MENU } from '../../../helpers/Constant';
import FiltersMenu from '../menus/FiltersMenu';


const FiltersItem = ({
    onClick,
    isDisabled,
    showMenu,
    filters,
    toggleMenu,
    isFilterApplied
}) => {
    const tooltipRef = useRef();
    let filteredName = 'Objects';
    
    if (isFilterApplied && filters.items.size > 0) {
        const key = filters.items.keys().next().value;
        
        if (SUBTOOLBAR_FILTER_ITEMS[key]) {
            filteredName = SUBTOOLBAR_FILTER_ITEMS[key].text.toLowerCase();
        }
    }

    const menuOnClickHandler = () => {
        if (isDisabled || isFilterApplied) return;
        toggleMenu(TOOLBAR_MENU.FILTERS_MENU);
    }

    return (
        <div className={clsx('filtersDDL', { disabled: isDisabled, 'filter-applied': isFilterApplied })} onClick={menuOnClickHandler}>
            <div className="filters-button">
                <div className="left">
                    <small>{isFilterApplied ? 'Selected' : 'Filter'}</small>
                    <span>{filters.selectedObjectsCount} {filteredName}</span>
                </div>

                {!isFilterApplied ? (
                    <div className="right">
                        <em className="icon-arrow-down" />
                    </div>
                ) : null}
            </div>

            {showMenu ? (
                <FiltersMenu
                    filters={filters}
                    tooltipRef={tooltipRef}
                    onClickHandler={onClick}
                />
            ) : null}
        </div>
    )
}

FiltersItem.propTypes = {
    isDisabled: PropTypes.bool,
    toggleMenu: PropTypes.func.isRequired,
    showMenu: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    filters: PropTypes.shape({
        selectedObjectsCount: PropTypes.number,
        items: PropTypes.object
    }),
    isFilterApplied: PropTypes.bool
}

export default FiltersItem;