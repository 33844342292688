import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types'
import { TOOLBAR_MENU } from '../../../helpers/Constant';
import IconMenuArrowPosition from '../../svgIcons/lines/MenuArrowPosition';
import IconArrowBottom from '../../svgIcons/arrows/ArrowBottom';

const ArrowTypeItem = ({
    isDisabled,
    showMenu,
    toggleMenu,
    onClick,
    arrowRight,
    arrowLeft,
}) => {
    const menuOnClickHandler = () => {
        if (isDisabled) return;
        toggleMenu(TOOLBAR_MENU.ARROW_TYPE);
    }

    return (
        <div className="lineArrowOption__wrapper">
            <div className={clsx('button svg_stroke', { disabled: isDisabled })} onClick={menuOnClickHandler}>
                <IconMenuArrowPosition />
                <div className="arrow_wrapper"><IconArrowBottom /></div>

                <span className={clsx('tooltipText maxContent', { 'onBottom': showMenu })}>
                    Arrow type
                </span>
            </div>

            {showMenu ? (
                <div className="lineArrowOption__list">
                    <div className={clsx('lineArrowOption__item', { 'active': arrowRight && arrowLeft })}>
                        <em className="icon-arrow-double-point" onClick={() => onClick('both')} />
                    </div>

                    <div className={clsx('lineArrowOption__item', { 'active': arrowRight && !arrowLeft })}>
                        <em className="icon-arrow-right-point" onClick={() => onClick('right')} />
                    </div>

                    <div className={clsx('lineArrowOption__item', { 'active': !arrowRight && arrowLeft })}>
                        <em className="icon-arrow-left-point" onClick={() => onClick('left')} />
                    </div>
                    
                    <div className={clsx('lineArrowOption__item', { 'active': !arrowRight && !arrowLeft })}>
                        <em className="icon-straight-line-icon" onClick={() => onClick('none')} />
                    </div>
                </div>
            ) : null}
        </div>
    )
};

ArrowTypeItem.propTypes = {
    isDisabled: PropTypes.bool,
    showMenu: PropTypes.bool,
    toggleMenu: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    arrowRight: PropTypes.bool,
    arrowLeft: PropTypes.bool,
}

export default ArrowTypeItem;