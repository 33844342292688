/**
 *
 */
const IconClose = () => {
    return (
        <svg
            fill="none"
            height="14"
            viewBox="0 0 14 14"
            width="14"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M.667 13.333L13.333.666M13.333 13.333L.667.666"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.267"
            />
        </svg>
    );
}

export default IconClose;