/**
 * Return main page id.
 * @param {*} pages - Page list.
 */
export function getMainPageWbId(pages) {
    try {
        if (!pages?.length || !Array.isArray(pages)) return null;

        const mainPage = pages[0];
        if (!mainPage) return null;
    
        return mainPage?.wbPageId;
    } catch (err) {
        return null;
    }
}

/**
 * @param pages
 * @param activePageId
 */
export function isMainPage(pages, activePageId) {
    try {
        if (!pages?.length || !Array.isArray(pages)) return false;

        const mainPage = pages[0];
        if (!mainPage) return false;
    
        return mainPage.id === activePageId;
    } catch (err) {
        return false;
    }
}

/**
 * @param pages
 */
export function getActivePage(pages) {
    const link = new URL(window.location.href);
    if (link.searchParams.get('pageId')) {
        const currentPage = pages.map(item => item.id.toString()).indexOf(link.searchParams.get('pageId')) + 1; 
        return currentPage
    }
}

/**
 * 
 * @returns {{string?}}
 */
export function getUrlPageId() {
    const link = new URL(window.location.href);
    return link.searchParams.get('pageId') ?? null;
}