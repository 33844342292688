import {useEffect} from 'react';

/**
 * @param ref
 * @param activeWindowState
 * @param modalName
 * @param closeHandler
 */
export default function useOutsidePopupClick(ref, activeWindowState, modalName, closeHandler) {
    useEffect(() => {
        /**
         * @param event
         */
        function handleClickOutside(event) {
            if (activeWindowState === modalName && ref.current && !ref.current.contains(event.target)) {
                closeHandler();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, modalName, activeWindowState]);
}