import useEventListener from './UseEventListener';

const useOnClickOutside = (ref, handler, mouseEvent = 'mousedown') => {
    return useEventListener(mouseEvent, (event) => {
        const el = ref?.current;

        // Do nothing if clicking ref's element or descendent elements
        if (!el || el.contains(event.target)) {
            return;
        }

        handler(event);
    });
};

export default useOnClickOutside;
