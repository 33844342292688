import {Viewport} from '../../../core/viewPort/Viewport';

/**
 * Calculates and returns the bounding box.
 * @returns {Viewport} The bounding box object with Viewport instance.
 */
export default function getBoundingBox() {
    const vptCoords = this.vptCoords;
    return new Viewport(
        vptCoords.tl.x, 
        vptCoords.tl.y, 
        vptCoords.tr.x - vptCoords.tl.x, 
        vptCoords.bl.y - vptCoords.tl.y
    );
}