export const getZoomLevel = (zoom) => {
    return Math.round(zoom * 100);
}

export const ZOOM_LEVELS = {
    10: 'xs',
    20: 'sm',
    30: 'med',
    40: 'full'
}

export const getImageMode = (zoom) => {
    const zoomLevel = getZoomLevel(zoom);
    if (zoomLevel < 20) {
        return 'xs';
    } else if (zoomLevel < 30) {
        return 'sm';
    } else if (zoomLevel < 40) {
        return 'med';
    }
    return 'full';
}

/**
 * Checking that is the user zoom out the canvas.
 * @param {fabric.Canvas} canvas
 * @param {number} newZoomValue
 * @returns { boolean }
 */
export const isAbleToZoomOut = (canvas, newZoomValue) => {
    if (!canvas) return false;

    const isBoardEmpty = canvas.getObjects().length === 0
    if (!isBoardEmpty) return true;

    const zoom = canvas.getZoom();
    if (zoom === 0.3) return false;

    const zoomPercentageValue = Math.round(newZoomValue * 100);
    if (zoomPercentageValue <= 30) return false;

    return true;
}

/**
 * Checking that whether the zoom level should be disabled.
 * @param {fabric.Canvas} canvas 
 * @param {number} zoomValue 
 * @returns 
 */
export const isZoomLevelDisabled = (canvas, zoomValue) => {
    if (!canvas) return false;

    const isBoardEmpty = canvas.getObjects().length === 0;
    if (isBoardEmpty && zoomValue === 1) return true;

    return false;
}