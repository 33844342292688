import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import useMove from '../../../hooks/UseMove';
import CommentWrapper from './CommentWrapper';


/**
 * Wrapper for CommentWrapper component.
 */
const CommentWrapperWrap = forwardRef(({ 
    selectedCommentIcon,
    selectedComment,
    canvas,
    currentUserId,
    whiteBoardId,
    socketRef,
    userAccessRef,
    handleReadComment,
    handleMarkAsUnreadComment,
    handleAddReply,
    handleUpdateCommentContent,
    handleResolveComment,
    handleDeleteComment,
    changeSelectedComment,
    users
}, ref) => {
    
    useMove(ref);

    return (
        <div 
            className="commentWrapper--wrap"
            data-for="wrapper"
            ref={ref}
        >
            <div className="dragger"
                style={{
                    position: 'absolute',
                    width: '100%',
                    height: '40px',
                    zIndex: '1',
                }}
            />
            {selectedCommentIcon ? <CommentWrapper  
                canvas={canvas}
                changeSelectedComment={changeSelectedComment}
                currentUserId={currentUserId}
                handleAddReply={handleAddReply}
                handleDeleteComment={handleDeleteComment}
                handleMarkAsUnreadComment={handleMarkAsUnreadComment}
                handleReadComment={handleReadComment}
                handleResolveComment={handleResolveComment}
                handleUpdateContent={handleUpdateCommentContent}
                selectedComment={selectedComment}
                selectedCommentIcon={selectedCommentIcon}
                socket={socketRef.current}
                userAccess={userAccessRef.current}
                users={users}
                whiteBoardId={whiteBoardId}
            /> : null}
        </div>
    );
});

CommentWrapperWrap.displayName = 'CommentWrapperWrap';

CommentWrapperWrap.propTypes = {
    selectedCommentIcon: PropTypes.object,
    selectedComment: PropTypes.shape({
        id: PropTypes.number,
        whiteboardId: PropTypes.number,
        pageId: PropTypes.number,
        userId: PropTypes.number,
        parentId: PropTypes.number,
        content: PropTypes.string,
        contentSearch: PropTypes.string,
        taggedUserIds: PropTypes.any,
        resolved: PropTypes.any,
        position: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number
        }),
        colorCode: PropTypes.string,
        uuid: PropTypes.string,
        parentUuid: PropTypes.any,
        isDeleted: PropTypes.bool,
        isEdited: PropTypes.bool,
        threadLink: PropTypes.any,
        resolvedBy: PropTypes.object,
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
        replies: PropTypes.arrayOf(PropTypes.shape({
            userId: PropTypes.number,
            uuid: PropTypes.string,
            content: PropTypes.string,
            parentUuid: PropTypes.string,
            isDeleted: PropTypes.bool,
            user: PropTypes.shape({
                name: PropTypes.string,
            }),
            createdAt: PropTypes.string,
            userComment: PropTypes.arrayOf(PropTypes.shape({
                readAt: PropTypes.string
            }))
        })),
        userComment: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            userId: PropTypes.number,
            readAt: PropTypes.any
        })),
        user: PropTypes.shape({
            commentUsername: PropTypes.string,
            name: PropTypes.string
        })
    }),
    canvas: PropTypes.object,
    currentUserId: PropTypes.number,
    whiteBoardId: PropTypes.number,
    socketRef: PropTypes.shape({
        current: PropTypes.object
    }),
    userAccessRef: PropTypes.shape({
        current: PropTypes.oneOf(['view', 'comment', 'edit', 'removeAccess', 'NOT_ALLOWED'])
    }),
    handleReadComment: PropTypes.func,
    handleMarkAsUnreadComment: PropTypes.func,
    handleAddReply: PropTypes.func,
    handleUpdateCommentContent: PropTypes.func,
    handleResolveComment: PropTypes.func,
    handleDeleteComment: PropTypes.func,
    changeSelectedComment: PropTypes.func,
    users: PropTypes.arrayOf(PropTypes.shape({
        commentEmail: PropTypes.string,
        commentUsername: PropTypes.string,
        email: PropTypes.string,
        id: PropTypes.number,
        name: PropTypes.string,
        permission: PropTypes.string
    }))
}

export default CommentWrapperWrap;