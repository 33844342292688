import { fabric } from 'fabric';
import { v4 as uuid } from 'uuid';
import { uploadImage } from '../services/DataService';
import { saveAs } from 'file-saver';
import { createSelectionForObjects, isObjectValid } from '../helpers/FabricMethods';
import { filterImageSizes, filterByImageType, separateFilesForSizeLimit } from '../helpers/media/SizeFilter';
import getToastIcon from '../helpers/media/GetToastIcon';
import loadImagesAsync from '../helpers/media/LoadImageAsync';
import { addLoadingMockImages } from '../helpers/media/CreateLoadingMockImage';
import mapFilesWithUuid from '../helpers/media/MapFilesWithUuid';
import { isUserHasAccessToFeature } from '../helpers/CommonFunctions';
import OptimizedImage from '../customClasses/image/OptimizedImage';
import {getWbId} from '../services/AuthService';
import store from '../redux/Store';
import {getSVGDefsAndDesc, getSVGHead} from '../helpers/media/ExportSVGHelpers';
import { EMITTER_TYPES } from '../helpers/Constant';
import {fitToScreenAssistance} from '../helpers/FitToScreenAssistance';

export const onImageUpload = async (e, canvas, eventEmitter, location, toast, options = {}, userAccess, activePageId) => {
    // not allowed to add image if user doesn't have permission
    if (!isUserHasAccessToFeature('image_upload', userAccess)) {
        toast.error('User doesn\'t have permission', {
            icon: getToastIcon('error'),
            className: 'wb_toast',
        });

        return;
    }

    // not allow to upload files other than images
    const baseFiles = filterByImageType(e.target.files);
    if (baseFiles.length < e.target.files.length) {
        toast.error('Only image files are allowed to upload.', {
            icon: getToastIcon('error'),
            className: 'wb_toast',
        });
    }
    /// filter files
    const filteredImages = filterImageSizes(baseFiles);

    if (filteredImages.isBiggerThanTotalCountLimit) {
        toast.error(`Attention: Max ${filteredImages.limitText} images allowed per batch. Detected ${baseFiles.length} images!`, {
            icon: getToastIcon('error'),
            className: 'wb_toast',
        });
    }
    // check if total size is bigger than limit
    if (filteredImages.isBiggerThanTotalLimit) {
        toast.error(`Allowed total file sizes are bigger than ${filteredImages.limitText}. Please try to upload smaller sized files. `, {
            icon: getToastIcon('error'),
            className: 'wb_toast',
        });
        return;
    }
    // check if there is any file that is bigger than individual limit
    if (filteredImages.unallowedFileCount > 0) {
        toast.error(`Oops! ${filteredImages.unallowedFileCount} image(s) too big (10 MB limit)`, {
            icon: getToastIcon('error'),
            className: 'wb_toast',
        });
    }
    const files = mapFilesWithUuid(filteredImages.filteredFiles);

    // filter files and seperate them for size limit
    const separatedFiles = separateFilesForSizeLimit(files);
    // load files to app
    const loadedImages = await loadImagesAsync(separatedFiles);

    // generate random process id to differentiate mock objects for each uploading process
    const processId = uuid();
    options.processId = processId;

    await addLoadingMockImages(canvas, loadedImages, options);

    let wbId;
    const pattern = /(teamBoard|bmeetBoard|whiteboard|enterprise)/;
    if (pattern.test(location.pathname)) {
        wbId = getWbId();
    } else {
        wbId= location.pathname.split('/')[2];
    }

    const seperatedFileRows = Object.values(separatedFiles);
    if (!Array.isArray(seperatedFileRows) || !seperatedFileRows.length || !seperatedFileRows[0].files.length) return;
    if (wbId && seperatedFileRows) {
        const infoToast = toast.info(`Uploading ${files.length} image(s)...`, { autoClose: false, icon: getToastIcon('info'), className: 'wb_toast', })
        try {
            const uploadPromise = seperatedFileRows.reduce((previous, current) => {
                return [...previous, uploadImage(current.files, wbId, activePageId)];
            }, []);
            
            const data = await Promise.allSettled(uploadPromise);
            const addedImages = [];

            // get uploaded image count
            const promiseStatuses = data.reduce((previous, current, index) => {
                const currentPromise = data[index];
                if (currentPromise && currentPromise.status === 'fulfilled') {
                    return {...previous, fullfilledCount: previous.fullfilledCount + currentPromise.value.images.length};
                }
                return previous;
            }, {fullfilledCount: 0});

            // show error toast if any image failed to upload
            if (promiseStatuses.fullfilledCount < files.length) {
                toast.error(`${files.length - promiseStatuses.fullfilledCount} image(s) failed to upload.`, { autoClose: 3000, icon: getToastIcon('error'), className: 'wb_toast' });
            }

            // show success toast if any image uploaded successfully
            if (promiseStatuses.fullfilledCount > 0) {
                toast.update(infoToast, {type: 'success', render: `${promiseStatuses.fullfilledCount} image(s) uploaded successfully. Adding them to the board.`, autoClose: 3000, icon: getToastIcon('success'), className: 'wb_toast'});
            } else {
                // if no image uploaded successfully, remove info toast
                toast.dismiss(infoToast);
            }
            
            for (const [index, result] of data.entries()) {
                // if result is fulfilled, add image to canvas
                if (result && result.status === 'fulfilled') {
                    const rowData = result.value;
                    const seperatedFileRow = seperatedFileRows[index];
                    for (let i = 0; i < rowData.images.length; i++) {
                        const imageData = rowData.images[i];
        
                        try {
                            
                            const addedImageObj = await addOptimizedImage(
                                imageData, 
                                canvas, 
                                {
                                    imageIndex: i, 
                                    rowUuid: seperatedFileRow.rowUuid,
                                    processId
                                }
                            );
                            const {
                                width,
                                height,
                                left,
                                top,
                                imageElement
                            } = addedImageObj;
                            const image = new OptimizedImage(imageElement, {
                                left,
                                top,
                                width,
                                height,
                                imageData,
                                // imageElement
                            })
                            image.uploading = true;
                            canvas.add(image);
                            eventEmitter.fire(EMITTER_TYPES.EMIT_ON_MOUSE_DOWN, {
                                e: image,
                                drawInstance: image
                            });
                            addedImages.push(image);
                        } catch (error) {
                            console.log('error while adding image to canvas: ', error);
                        }
         
                    }
                }
            }
            canvas.requestRenderAll(); 
            createSelectionForObjects(canvas, addedImages);
        } catch (error) {
            toast.update(infoToast, {type: 'error', render: `Error while uploading image(s).`, autoClose: 3000, icon: getToastIcon('error'), className: 'wb_toast'});
            console.log('error while uploading image: ', error);
        } finally {
            // just in case remove all mock objects for this process only
            const mockObjects = canvas.getObjects().filter(obj => 
                obj.shapeType && 
                obj.shapeType === 'loadingMockImage' &&
                obj.processId === processId
            );
            for (let i = 0; i < mockObjects.length; i++) {
                const mockObj = mockObjects[i];
                canvas.remove(mockObj);
            }
            canvas.renderAll();
        }
        // make sure to clear the input field so that the same file can be uploaded again
        e.target.value = null;
    } 
}

const addOptimizedImage = async (imageData, canvas, options) => {
    return new Promise((resolve) => {
        fabric.util.loadImage(imageData.xs.url, (img) => {
            // find loading mock object by imageIndex, rowUuid and processId
            const mockObj = canvas.getObjects().find(
                obj => obj.shapeType && 
                    obj.shapeType === 'loadingMockImage' && 
                    obj.processId === options.processId &&
                    obj.mockIndex === options.imageIndex && 
                    obj.rowUuid === options.rowUuid
            )

            if (!mockObj) return;

            const mockObjCoordinates = {
                left: mockObj.left,
                top: mockObj.top
            }

            // set position of image to the position of mock object
            let mockGroup = mockObj.group;
            if (mockObj.group) {

                mockObjCoordinates.left = mockObj.left + mockObj.group.left + mockObj.group.width / 2;
                mockObjCoordinates.top = mockObj.top + mockObj.group.top + mockObj.group.height / 2;
            }

            // since we dont need mock object anymore, remove it
            canvas.remove(mockObj);
            if (mockGroup) {
                canvas.discardActiveObject()
                canvas.requestRenderAll();
            }

            resolve({
                imageElement: img,
                width: mockObj.getScaledWidth(),
                height: mockObj.getScaledHeight(),
                ...mockObjCoordinates
            });
        })
    });
}


/**
 * Hides comments before exporting canvas to image.
 * @param canvas
 * @returns {[fabric.Object]} Objects that were hidden.
 */
const hideCommentsBeforeSave = (canvas) => {
    const hidedComments = [];
    canvas.getObjects().forEach(obj => {
        if (obj.type === 'comment' && obj.visible) {
            hidedComments.push(obj);
            obj.hideComment();
        }
    });
    return hidedComments;
}

const getBoardName = () => {
    try {
        const state = store.getState();
        if (state?.board?.boardName) return state.board.boardName;
    } catch (err) {
        console.error('Error happened while getting board name from redux state', err);
    }
    return 'canvas';
}

export const onDownloadCanvas = (canvas, type) => {
    if (type === 'jpeg') {
        canvas.setBackgroundColor('#FFFFFF')
    }

    const hidedComments = hideCommentsBeforeSave(canvas);
    canvas.renderAll();
    let dt = canvas.toDataURL({ format: type, crossorigin: 'anonymous', allowTaint: true });
    hidedComments.forEach(obj => obj.showComment());
    canvas.renderAll();
    
    saveAs(dt, `${getBoardName()}.${type}`)
}


// ------------------ GENERATING SVG ------------------
const updateToastProgressBar = (toastID) => {
    try {
        const container = document.getElementById(toastID);
        container.querySelector(`.Toastify__progress-bar`).style.transform = 'scaleX(1)';
    } catch (err) {
        console.error('Error happened while transforming toast progress bar', err);
    }
}

let containerBlock = null;
const createBlockContainer = () => {
    try {
        containerBlock = document.createElement('div');
        containerBlock.style.width = '100%';
        containerBlock.style.height = '100%';
        containerBlock.style.position = 'fixed';
        containerBlock.style.left = '0';
        containerBlock.style.top = '0';
        containerBlock.style.backgroundColor = 'transparent';
        containerBlock.style.zIndex = 999;
        containerBlock.style.cursor = 'not-allowed';
        containerBlock.id = 'svgBlockContainer';
        document.body.appendChild(containerBlock)
    } catch (err) {
        console.error('Error happened while creating container block', err);
    }
}
const deleteContainerBlock = () => {
    try {
        if (containerBlock) {
            document.body.removeChild(containerBlock);
            containerBlock = null;
        } else {
            const container = document.getElementById('svgBlockContainer');
            if (container) document.body.removeChild(container);
        }
    } catch (err) {
        console.error('Error happened while removing container block', err);
    }

}

let isGenerating = false;

export const onDownloadSVG = async (canvas, toast) => {
    let toastMessage;
    if (isGenerating) {
        toast.error('SVG is already being generated. Please wait other process to finish or cancel it.', {autoClose: 3000, icon: getToastIcon('error'), className: 'wb_toast'});
        return;
    }
    try {
        isGenerating = true;
        let cancel = false;
        fitToScreenAssistance.destroy(true);
        toastMessage = toast.info('Exporting board as SVG... Click here to cancel it.', { 
            autoClose: false, 
            icon: getToastIcon('info'),
            className: 'wb_toast',
            onClick: () => {
                cancel = true;
            }
        });
        let toastPreventingAccessMessage = toast.info('Board cannot be edited when SVG export is running', {autoClose: 3000, icon: getToastIcon('info'), className: 'wb_toast'});

        // block user from interacting with canvas
        createBlockContainer(toastMessage);

        // create selection of all objects
        canvas.discardActiveObject();
        canvas.requestRenderAll();
        const allObjects = canvas.getObjects().filter(obj => obj.type !== 'comment' && isObjectValid(obj));
        const selection = new fabric.ActiveSelection(allObjects, {
            canvas: canvas,
        });
        canvas.setActiveObject(selection);
        canvas.requestRenderAll();

        // generate svg from the selection
        const svgBlobs = [];
        svgBlobs.push(new Blob([getSVGHead({width: selection.width, height: selection.height})], {type: 'text/plain'}))
        svgBlobs.push(new Blob([getSVGDefsAndDesc()], {type: 'text/plain'}));
        svgBlobs.push(new Blob([`<g transform="matrix(1 0 0 1 ${selection.width / 2} ${selection.height / 2})">\n`], {type: 'text/plain'}))  // main transform
        svgBlobs.push(new Blob(['<g>\n'], {type: 'text/plain'}));
        let progress = 0;
        const length = selection._objects.length;
        try {
        
            for (var i = 0; i < length; i++) {
                if (cancel) break;
                svgBlobs.push(new Blob(['\t\t'], { type: 'text/plain' }))
                const objectSVG = await selection._objects[i].toSVG(undefined);
                svgBlobs.push(new Blob([objectSVG], {type: 'text/plain'}))
                progress = (i + 1) / length;
                toast.update(toastMessage, {progress, hideProgressBar: false});
            }
            svgBlobs.push(new Blob(['</g>\n'], {type: 'text/plain'}));
        } catch (err) {
            console.error('Error happened', err);
        }
        // download svg
        if (!cancel) {
            svgBlobs.push(new Blob(['</g>'], {type: 'text/plain'}));
            svgBlobs.push(new Blob(['</svg>'], {type: 'text/plain'}));
            saveAs(new Blob(svgBlobs, { type: 'image/svg+xml' }), `${getBoardName()}.svg`)
        
            toast.update(toastMessage, {type: 'success', render: 'Board exported as SVG successfully.', autoClose: 3000, icon: getToastIcon('success')});
            toast.update(toastPreventingAccessMessage, {autoClose: 1000});
            // hacky way to hide progress bar. We need this because autoClose is not working with progress bar
            setTimeout(() => {
                toast.dismiss(toastMessage);
            }, 3000);
    
            updateToastProgressBar(toastMessage);
        }


        canvas.discardActiveObject();
        canvas.requestRenderAll(); 
    } catch (error) {
        if (toastMessage) {
            toast.update(toastMessage, {type: 'error', render: 'Error while exporting board as SVG.', autoClose: 3000, icon: getToastIcon('error'), hideProgressBar: true, className: 'wb_toast'});
            console.error(error);
        } else {
            toast.error('Error while exporting board as SVG.', {autoClose: 3000, icon: getToastIcon('error'), className: 'wb_toast'});
        }
    } finally {
        // unblock user from interacting with canvas
        isGenerating = false;
        deleteContainerBlock();
    }
}