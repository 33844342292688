import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { environment } from '../../environment';
import { getUserQueryParams } from '../../helpers/CommonFunctions';

import { toast, ToastContainer } from 'react-toastify';
import './nimaBoard.scss';
import getToastIcon from '../../helpers/media/GetToastIcon';
import { useDispatch } from 'react-redux';
/**
 *
 */
const NimaBoardComponent = () => {
    const [frameUrl, setUrl] = useState();

    const dispatch = useDispatch();

    const location = useLocation();

    const nowBaseUrl = environment.NOW_BASE_URL;

    (0, useEffect)(() => {
        const { email, name } = getUserQueryParams(location.search);
        const studio_build_card_id = location.pathname.split('/')[1];
        let url = `${nowBaseUrl}/${studio_build_card_id}/Board?email=${email}&username=${name}`;
        setUrl(url);
    }, []);

    useEffect(()=>{
        toast.info(
            <span className="feedback_toast__text">
                This Whiteboard works better at 80% browser level.
            </span>,
            {
                autoClose: false,
                icon: getToastIcon('feedback'),
                className: 'feedback_toast',
                draggable: false,
                toastId: 'nima-tooltip',
                onClick: () => {
                    dispatch({
                        type: 'modal/toggleFeedbackModal',
                        payload: {
                            shouldShow: true,
                        },
                    });
                },
            }
        );
    }, []);

    return (  
        <div className='mainDiv'>
            <iframe className="iframeCls"  frameBorder="0" height="100%" src={frameUrl} width="100%" />
            <ToastContainer closeOnClick={false} position='bottom-center' />
        </div>
    );
}

export default NimaBoardComponent;