import { fabric } from 'fabric';
import { useCallback, useEffect, useRef, useState, useMemo } from 'react';

import {
    arrangeTextInsideShape,
    cloneFabricObjectHelper,
    createFabricInstance,
    customToObject,
    isObjectInsideOfObject,
    isObjectValid,
    isTargetIncludeText,
    isTargetLocked,
    isTargetText,
} from '../helpers/FabricMethods';
import {attachToFrame, getFrameAttachedShapes, handleFrameStackOrder} from '../helpers/frame/FrameMethods';
import onFrameDrawn, { setFrameOptions } from '../helpers/frame/OnFrameDrawn';
import {
    attachStackOrderToObject,
    setSingleObjectStack,
    setObjectsStackWithZIndex
} from '../helpers/StackOrder';
import { useSelector } from 'react-redux';
import { LINE_POLYGON_POINTS, LINE_POLYGON_PREFIXES, NON_ROTATABLE_OBJECT_TYPES, SOCKET_EVENT, SOCKET_STATUS_MODS, TABLE_NOT_ALLOWED_CONTROL_TYPES } from '../helpers/Constant';
import {
    attachLinesInUuidList, attachPolygonsWithCanvasMap, getPolygonUuid,
    isLineAttachedToElement,
    updateLineLeftAndRightPoint
} from '../helpers/lines/LineMethods';
import { calculateAttachedPos } from '../helpers/frame/CalculatePositions';
import attachedShapeMoveHandler from '../helpers/comments/AttachedShapeMoveHandler';
import { createObjectToBeEmitted, isUserHasAccessToFeature } from '../helpers/CommonFunctions';
import {removeAllExistedLasso} from './UseLasso';
import { updateAllPropertiesOfTextboxObject } from '../helpers/Textarea';
import { onTableDrawn } from '../helpers/table/TableMethods';

const useHistory = (canvas, userAccessRef, pageId, userId, whiteBoardId, emitData) => {
    const [undoStack, setUndoStack] = useState([]);
    const [redoStack, setRedoStack] = useState([]);
    const [enabledHistory, setEnabledHistory] = useState({
        undo: false,
        redo: false
    });
    const [history, setHistory] = useState([]);
    const socketConnectionStatus = useSelector(state => state.socket.status);
    const allHistoryState = useSelector(state => state.history);
    const isProcessing = useRef(false);
    const redoStackRef = useRef(redoStack);

    const historyState = useMemo(() => {
        if (!allHistoryState || !allHistoryState[pageId]) return null;
        return allHistoryState[pageId];
    }, [pageId, allHistoryState]);

    const findShapeFromShapes = (uuid, canvas) => {
        let shapes = historyState?.shapes;
        if (shapes) {
            const foundShape = shapes.find(shape => shape.uuid === uuid)?.properties;
            if (foundShape) {
                if (foundShape.type === 'frame') {
                    // const attachedShapes = shapes.filter(shape => shape.properties.attachedFrameId === foundShape.uuid)?.map(obj => obj.properties);
                    // return { ...foundShape, thisAttachments: attachedShapes };
                    const attachments = canvas.getObjects().filter(o => o.attachedFrameId === foundShape.uuid).map(o => customToObject(o));
                    return { ...foundShape, thisAttachments: attachments };
                } else {
                    const objInCanvas = canvas.getObjects().find(obj => obj.uuid === uuid);
                    if (objInCanvas.attachedFrameId) {
                        return {
                            ...foundShape,
                            attachedFrameId: objInCanvas.attachedFrameId,
                            calculatedPos: objInCanvas.calculatedPos
                        }
                    }
                }
            }
            return foundShape
        }
        return null;
    }

    const _fireCanvasEventToModifyAllTheCommentLocations = (
        canvas,
        movedComments
    ) => {
        const emitData = {
            objects: movedComments,
            action: 'modified-the-comment',
        };
        canvas.fire('history-emit-data', emitData);
    };

    const moveTheCommentAndFireCanvasEvent = useCallback((canvas, objReflection) => {
        let movedComments = attachedShapeMoveHandler(objReflection);
        _fireCanvasEventToModifyAllTheCommentLocations(canvas, movedComments);
    }, []);

    const _addToHistory = useCallback((historyData) => {
        setHistory(currentState => {
            if (!currentState || !currentState.length) currentState = [];
            return [...currentState, historyData];
        });
    }, []);

    const addtoUndoStack = useCallback((undoData, shouldAddHistory = true) => {
        setUndoStack(data => {
            if (!data || !data.length) data = [];
            return [...data, undoData];
        });

        if (shouldAddHistory) {
            _addToHistory(JSON.parse(JSON.stringify(undoData)));
        }

        // when we add to undo stack, we should clear the redo stack
        setRedoStack([]);
    }, [_addToHistory]);

    useEffect(() => {
        if (canvas) {
            const dataToHistoryObject = (data, action = 'modified') => {
                return {action, properties: data, uuid: data.uuid};
            }
            // generates attached object data for the given frame
            const generateAttachedObjectDataForFrame = (frame, action, useCalculatedPosition) => {
                const frameObjects = getFrameAttachedShapes(frame);
                const frameObjectsData = [];
                for (const frameObj of frameObjects) {
                    const objData = customToObject(frameObj, { useCalculatedPosition });
                    frameObjectsData.push(dataToHistoryObject(objData, action));

                    // handle frame object adding in nested level
                    if (frameObj.type === 'frame') {
                        const nestedFrameObjects = getFrameAttachedShapes(frameObj);
                        for (const nestedFrameObj of nestedFrameObjects) {
                            const objData = customToObject(nestedFrameObj, { useCalculatedPosition });
                            frameObjectsData.push(dataToHistoryObject(objData, action));
                        }
                    }
                }
                return frameObjectsData;
            }
            // adds polygons of the line to the given list
            const addLinePolygonsToList = (line, list, useCalculatedPosition) => {
                try {
                    for (const side of LINE_POLYGON_POINTS) {
                        const polygonSide = line[LINE_POLYGON_PREFIXES[side].polygon];
                        if (!polygonSide) continue;
                        const polygonSideObj = canvas.getObjects().find(e => e.uuid === polygonSide?.uuid);

                        if (polygonSideObj && !list.find(o => o.uuid === polygonSideObj.uuid)) {
                            const polygonSideData = customToObject(polygonSideObj, { useCalculatedPosition });
                            list.push(dataToHistoryObject(polygonSideData, 'modified'));
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
                return list;
            }
            // generates and adds line data for the given target
            const generateLineData = (target, affectedObjects = [], action = 'modified') => {
                const lines = [];
                if (target.lines) {
                    for (const lineUuid of target.lines) {
                        const line = canvas.getObjects().find(obj => obj.uuid === lineUuid);
                        if (
                            line && 
                            isLineAttachedToElement(line, target) && 
                            lines.filter(obj => obj.uuid === line.uuid).length === 0 &&  // avoid duplicate
                            affectedObjects.filter(obj => obj.uuid === line.uuid).length === 0  // avoid duplicate
                        ) {
                            const lineData = customToObject(line, { useCalculatedPosition: true });
                            lines.push(dataToHistoryObject(lineData, action));
                        }
                    }
                }
                return lines;
            }
            const objectModifiedListener = (e) => {
                const { target } = e;
                if (target.type !== 'activeSelection' && !isObjectValid(target)) return;
                const affectedObjects = [];
                if (target.type === 'activeSelection') {
                    const selectionObjects = target.getObjects();
                    for (const selectionObject of selectionObjects) {
                        if (isObjectValid(selectionObject)) {
                            // if the curvedline is already added to the affectedObjects, we should not add it again
                            if (selectionObject.type === 'curvedLine' && affectedObjects.map(o => o?.uuid)?.includes(selectionObject.uuid)) {
                                continue;
                            }
                            const objectData = customToObject(selectionObject, { useCalculatedPosition: true });
                            // if any line is attached to target objects, we should add them to affectedObjects
                            const objectLines = generateLineData(selectionObject, affectedObjects); 
                            if (objectLines && objectLines.length) {
                                affectedObjects.push(...objectLines);
                            }

                            affectedObjects.push(dataToHistoryObject(objectData, 'modified'));
                        }
                    }
                } else {
                    const objectData = customToObject(target);
                    // if target has lines, we should add them to affectedObjects
                    const lines = generateLineData(target, affectedObjects, 'modified');
                    if (lines && lines.length) {
                        affectedObjects.push(...lines);
                    }

                    affectedObjects.push(dataToHistoryObject({...objectData}, 'modified'));

                    // handle adding frame attachments to the affectedObjects
                    const addFrameAttachmentsToTheList = (attachedObj) => {
                        const objData = customToObject(attachedObj);
                        if (objData?.type === 'curvedLine' && affectedObjects.map(o => o?.properties?.uuid).includes(objData.uuid)) {
                            return;
                        }
                        affectedObjects.push(dataToHistoryObject(objData, 'modified'));
                        const lines = generateLineData(attachedObj, affectedObjects, 'modified');
                        if (lines && lines.length)
                            affectedObjects.push(...lines);
                    }
                    // order of this attachments is important. First we should add the frame itself then the attached shapes
                    if (target.type === 'frame') {
                        const attachedObjs = getFrameAttachedShapes(target);
                        for (const attachedObj of attachedObjs) {
                            addFrameAttachmentsToTheList(attachedObj);

                            // if there is nested frame, we should add its' attachments too
                            if (attachedObj.type === 'frame') {
                                const attachedObjs2 = getFrameAttachedShapes(attachedObj);
                                for (const attachedObj2 of attachedObjs2) {
                                    addFrameAttachmentsToTheList(attachedObj2);
                                }
                            }
                        }
                    }
                }
                addtoUndoStack({ action: 'stateChange', affectedObjects, processId: e?.transform?.processId || e.processId, aborted: e?.transform?.aborted || e.aborted});
            }
            const addtoUndoStackListener = (e) => {
                if (!isUserHasAccessToFeature('history_create_or_delete', userAccessRef.current)) return;

                const affectedObjects = [];
                for (const obj of e.objects) {
                    // if this called from restore, then we don't need to convert to custom object.
                    // because the object is already a custom object
                    if (e.action === 'restore') affectedObjects.push(dataToHistoryObject(obj.properties, 'created'));
                    else {
                        const objectData = customToObject(obj, { useCalculatedPosition: e.useCalculatedPosition ?? false })
                        let thisAttachments = [];
                        if (obj.type === 'frame') {
                            let actionForAttachments = 'created';
                            if (obj.newlyCreated) {
                                objectData.newlyCreated = true;
                                actionForAttachments = 'modified';
                            }
                            thisAttachments = generateAttachedObjectDataForFrame(obj, actionForAttachments, e.useCalculatedPosition ?? false);
                        }
                        affectedObjects.push(dataToHistoryObject(objectData, 'created'), ...thisAttachments);
                        if (obj?.type === 'curvedLine') {
                            addLinePolygonsToList(obj, affectedObjects, e.useCalculatedPosition ?? false);
                        }
                    }
                }
                addtoUndoStack({ action: 'stateChange', affectedObjects, processId: e?.transform?.processId || e.processId, aborted: e?.transform?.aborted || e.aborted  });
            }
            const removeToUndoStackListener = (e) => {
                const affectedObjects = [];
                for (const obj of e.objects) {
                    if (obj.type !== 'frame') {
                        attachStackOrderToObject(canvas, obj, e.stackOrders);
                    } else if (Array.isArray(obj.thisAttachments) && obj.thisAttachments.length > 0) {
                        for (const item of obj.thisAttachments) {
                            attachStackOrderToObject(canvas, item, e.stackOrders);
                        }
                    }
                    const objectData = customToObject(obj);
                    let thisAttachments = [];
                    if (obj.type === 'frame' && Array.isArray(obj.thisAttachments)) {
                        if (!obj.deleteOnlyMainFrame) {
                            for (const attachedObj of obj.thisAttachments) {
                                const objData = customToObject(attachedObj);
                                thisAttachments.push(dataToHistoryObject(objData, 'deleted'));
                            }
                        }
                    }
                    affectedObjects.push(dataToHistoryObject(objectData, 'deleted'), ...thisAttachments);
                }
                addtoUndoStack({ action: 'stateChange', affectedObjects, stackOrders: e.stackOrders, processId: e.processId });
            }
            
            const stackOrderToUndoStackListener = (orderData) => {
                addtoUndoStack({ action: 'stackOrder', newStackOrder: orderData.stackOrder, oldStackOrder: orderData.oldStackOrder });
            }

            /**
             * Removes the affected objects from the given stack.
             * @param {object} data - Data that comes from socket handlers.
             * @param {{name:string}} stack - React state that we want to update.
             * @param {React.Dispatch<{name:string}>} stackStateUpdater - React state updater for the given stack state.
             */
            const removeFromTheStackOnHistoryUpdate = (data, stack, stackStateUpdater) => {
                const canvas = data?.canvas;
                let modifiedStack = stack;
                modifiedStack = modifiedStack.map(item => {
                    if (item.action === 'stackOrder') {
                        let affectedObjects = [];

                        item.newStackOrder.map((n) => {
                            const checkAffected = item.oldStackOrder.find(f => n.uuid === f.uuid)
                            if (n.zIndex !== checkAffected.zIndex) {
                                let changedObject = canvas.getObjects().find(c => c.uuid === n.uuid);
                                if (!changedObject) changedObject = data.affectedObjects.find(a => a.uuid === n.uuid);

                                affectedObjects.push(changedObject);
                            }
                        })

                        return { ...item, affectedObjects }
                    }
                    else return item
                })
                const allAffectedObjectsInUndoStack = modifiedStack.filter(
                    historyStack => (historyStack.action === 'stateChange' || historyStack?.action === 'stackOrder')
                ).map(
                    obj => obj.affectedObjects?.map(o => o.uuid)
                )?.flat();
                if (!allAffectedObjectsInUndoStack?.length) return;

                for (const obj of data.affectedObjects) {
                    if (allAffectedObjectsInUndoStack.includes(obj.uuid)) {
                        stackStateUpdater(data => {
                            const removedUuids = [];
                            for (let i = data.length - 1; i >= 0; i-- ) {
                                const singleStackData = data[i];
                                // get frame id if the object has frame that attached to it
                                const frameId = obj.properties?.attachedFrameId;
                                let shouldFilterFrameId = !!frameId;
                                let isThereAttachedFrameInCurrentStack = singleStackData?.affectedObjects?.filter(o => o.uuid === frameId)?.length ? true : false;

                                const filteredAffectedObjects = singleStackData?.affectedObjects?.filter(
                                    o => {
                                        // if the object is removed in the later stack, remove from this stack too
                                        if (removedUuids.includes(o.uuid)) return false;
                                        let filterResult = o.uuid !== obj.uuid && 
                                            // if the object has frame that attached to it, we should remove the frame from the stack
                                            (shouldFilterFrameId ? o.uuid !== frameId : true) &&
                                            // if the object has frame that attach to it and that frame isn't in the current stack, we should remove other objects
                                            ((shouldFilterFrameId && isThereAttachedFrameInCurrentStack) ? o?.properties?.attachedFrameId !== frameId : true)

                                        // if the object is newly created in this stack and reflection of it has a frame and that frame is somehow modified, we should remove the object from the stack
                                        if (o?.action === 'created' && shouldFilterFrameId && o?.properties?.type !== 'frame' && canvas) {
                                            const objInCanvas = canvas.getObjects().find(e => e.uuid === o?.uuid);
                                            if (objInCanvas?.attachedFrameId && objInCanvas.attachedFrameId === frameId) filterResult = filterResult && false;
                                        }

                                        // if we need to delete this object, add its' uuid to the removeduuids
                                        if (!filterResult) removedUuids.push(o.uuid);
                                        return filterResult;
                                    }
                                )
                                if (!filteredAffectedObjects?.length) {
                                    delete data[i];
                                } else {
                                    singleStackData.affectedObjects = filteredAffectedObjects;
                                }
                            }
                            // filter empty data
                            data = data.filter(d => d)
                            return data;
                        });
                    }
                }
            }
            /**
             * Adds the given history data to history stack.
             * @param {object} historyData - History data that we want to add to history.
             * @param canvas
             */
            const addToHistoryListener = (historyData, canvas) => {
                // remove affected objects from the stacks since last update made by another user
                removeFromTheStackOnHistoryUpdate(historyData, undoStack, setUndoStack, canvas);
                removeFromTheStackOnHistoryUpdate(historyData, redoStack, setRedoStack, canvas);

                _addToHistory(historyData, { updateFrames: true })
            }

            const checkTargetAndArrangeText = (target) => {
                if (isTargetIncludeText(target)) {
                    const textObj = target._objects[1];
                    textObj.visible = true;
                    textObj.breakWords = true;
                    textObj.splitByGrapheme = false;
                    textObj.objectCaching = false;
                    arrangeTextInsideShape(target);
                }
            }

            /**
             * Creates a fabric object from data.
             * @param {object} data - The object data that we want to create an fabric instance.
             * @returns 
             */
            const _createObjectFromData = async (data) => {
                return new Promise((resolve) => {
                    createFabricInstance(data, function (objects) {
                        objects.forEach(function (o) {
                            o.toObject = cloneFabricObjectHelper(o);
                            canvas.add(o);
                            
                            if (o.type === 'curvedLine') {
                                attachPolygonsWithCanvasMap(o, canvas);
                            } else if (o.lines) {
                                attachLinesInUuidList(o, canvas)
                            }

                            if (o.type === 'frame') {
                                onFrameDrawn(o, canvas);
                            } else if (o.type === 'table') {
                                onTableDrawn(o);
                            } else if (o.type === 'curvedLine') { // Needed to calculate dimensions, path offset etc for the seamless alignment.
                                o.calculateBoundingBoxForCurvedLine();
                            }

                            checkTargetAndArrangeText(o);
                            
                            resolve(o);
                        });
                    });
                });
            }

            /**
             * Creates a fabric object from data and returns the object.
             * @param {object} data - The object data that we want to create an fabric instance.
             * @returns 
             */
            const createObjectFromData = async (data) => {
                try {
                    const object = await _createObjectFromData(data);

                    if (object.stackOrder && object.stackOrder > -1) {
                        setSingleObjectStack(canvas, object, object.stackOrder);
                    }
                    return object;
                } catch (err) {
                    console.error('Error while creating object from data', err);
                    return null;
                }
            }

            /**
             * Handles frame attachments on mutation from history.
             * @param object
             * @private
             */
            const _handleFrameAttachmentsOnMutate = (object) => {
                try {
                    // if the object frame, find object attachments and attach them to the frame
                    if (object.type === 'frame') {
                        const objectList = [];
                        if (object.attachments) {
                            for (const attachmentUuid of object.attachments) {
                                const attachedObj = canvas?.getObjects()?.find(e => e.uuid === attachmentUuid);
                                if (attachedObj) {
                                    objectList.push(attachedObj)
                                }
                            }
                        }

                        if (object?.attachedFrameId) {
                            const frame = canvas?.getObjects()?.find(e => e.uuid === object.attachedFrameId);
                            if (isObjectInsideOfObject(frame, object, { manualCheck: true })) {
                                attachToFrame(object, frame, { allowAttachingToLockedFrame: true })
                            }
                        }

                        //need to reAttach the sub objects if they still inside of deleted frame second step
                        const allObjectsExceptTarget = canvas.getObjects().filter(o => o !== object);
                        if (allObjectsExceptTarget) {
                            allObjectsExceptTarget.forEach(item => {
                                if (isObjectInsideOfObject(object, item, { manualCheck: true }) && !item.attachedFrameId) {
                                    attachToFrame(item, object, { allowAttachingToLockedFrame: true });
                                }
                            })
                        }

                        const attachedObjects = canvas.getObjects().filter(o => o !== object && o.attachedFrameId === object.uuid)
                        if (attachedObjects) {
                            const otherAttachments = attachedObjects.filter(o => !objectList.includes(o));
                            if (otherAttachments) {
                                objectList.push(...otherAttachments)
                            }
                        }

                        for (const attachedObject of attachedObjects) {
                            if (isObjectInsideOfObject(object, attachedObject, { manualCheck: true})) {
                                attachToFrame(attachedObject, object, { allowAttachingToLockedFrame: true })
                            }
                        }

                        let formerStackOrders = [];

                        undoStack[undoStack.length - 1].stackOrders.forEach((s) => {
                            canvas.getObjects().forEach((f) => {
                                if (f.uuid === s) {
                                    formerStackOrders.push(f);
                                }
                            })
                        });

                        formerStackOrders.forEach(item => {
                            handleFrameStackOrder(canvas, item);
                        });
                    } else {
                        // if object has frame and if it is inside of that frame, then attach it
                        if (object?.attachedFrameId) {
                            const frame = canvas?.getObjects()?.find(e => e.uuid === object.attachedFrameId);
                            if (isObjectInsideOfObject(frame, object, { manualCheck: true})) {
                                attachToFrame(object, frame, { allowAttachingToLockedFrame: true })
                            }
                        }
                    }
                } catch (err) {
                    console.error('error while handling frame attachment', object, err)
                }
            }

            /**
             * Creates a fabric object from data and pushes the object to the given list.
             * @param {object} data - The object data that we want to create an fabric instance.
             * @param {fabric.Object[]} list - The list that we want to push the created object.
             */
            const createObjectAndPushToList = async (data, list) => {
                const objectInstance = await createObjectFromData(data);
                _handleFrameAttachmentsOnMutate(objectInstance)
                if (objectInstance) {
                    list.push(objectInstance); 
                }
            }

            // emits the data list with given action
            const _emitListData = (list, action, options = {}) => {
                if (list.length) {
                    const emitData = {
                        objects: list,
                        action: action,
                        ...options
                    }
                    canvas.fire('history-emit-data', emitData);
                }
            }

            const historyDeleteHandler = (data, addList) => {
                try {
                    const structuredObj = JSON.parse(JSON.stringify(data));
                    const localObj = canvas.getObjects().find(e => e.uuid === structuredObj.uuid);
                    if (localObj) {
                        addList.push(localObj);
                        canvas.remove(localObj);
                    }
                } catch (err) {
                    console.error('error while deleting object on undo redo', err);
                }

            }

            const historyCreateHandler = async (data, addList) => {
                try {
                    const structuredObj = JSON.parse(JSON.stringify(data));
                    await createObjectAndPushToList(structuredObj, addList); 
                } catch (error) {
                    console.error('error while creating object on undo redo', error);
                }

            }

            const handleObjectLineUpdating = (object, dataList) => {
                try {
                    const lineUuids = [...object.lines];
                    for (const attachedLineUuid of lineUuids) {
                        const attachedLine = canvas.getObjects().find(e => e.uuid === attachedLineUuid);
                        if (attachedLine) {
                            const isUpdatedLinePoint = updateLineLeftAndRightPoint(canvas, attachedLine);
                            if (isUpdatedLinePoint && !dataList.find(obj => obj?.uuid === attachedLineUuid)) {
                                dataList.push(attachedLine);
                            }
                        }
                    }
                } catch (err) {
                    console.error('error while updating line', err);
                }
            }

            const handleOptimizedImageModification = (objectData, dataList) => { 
                try {
                    const object = canvas.getObjects().find(e => e.uuid === objectData.uuid);
                    if (object) {
                        object.onShapeChanged();
                        object.set(objectData).setCoords();
                        moveTheCommentAndFireCanvasEvent(canvas, object);
                        if (object?.lines && object.lines.length) {
                            handleObjectLineUpdating(object, dataList);
                        }

                        if (object.attachedFrameId) {
                            const frame = canvas.getObjects().find(e => e.uuid === object.attachedFrameId);

                            if (frame && isTargetLocked(frame)) {
                                object.selectable = false;
                            } else if (isTargetLocked(object)) {
                                object.selectable = false;
                            } else {
                                object.selectable = true;
                            }
                        }

                        canvas.renderAll();
                        dataList.push(object);
                    }
                } catch (error) {
                    console.error('error while updating optimized image', error);
                }

            }

            /**
             * Arranges the group state to the original state.
             * @param {fabric.Object} object 
             * @param {fabric.Object} createdObject - To take reference.
             * @param {object} structuredData - Original state.
             */
            const handleOriginalStateForGroup = (object, createdObject, structuredData) => {
                try {
                    object.set({
                        scaleX: createdObject.scaleX,
                        scaleY: createdObject.scaleY,
                        width: createdObject.width,
                        height: createdObject.height,
                    })
                    const originalObjects = structuredData.objects;
                    const groupObjects = object.getObjects();
                    for (const [index, groupObject] of Object.entries(groupObjects)) {
                        groupObject.set({
                            scaleX: originalObjects[index].scaleX,
                            scaleY: originalObjects[index].scaleY,
                            width: originalObjects[index].width,
                            height: originalObjects[index].height,
                            left: originalObjects[index].left,
                            top: originalObjects[index].top,
                        })
                    }
                } catch (err) {
                    console.error('error while updating group', err);
                }

            }
            
            const handleLineStateOnModified = (objReflection, structuredData, modifiedObjects, allModifiedObjects) => {
                // check line and attach again
                if (objReflection.shapeType === 'curvedLine') {
                    const leftPolygon = getPolygonUuid(structuredData, 'left')
                    const rightPolygon = getPolygonUuid(structuredData, 'right')
                    if (leftPolygon) {
                        const leftPolygonObj = canvas.getObjects().find(e => e.uuid === leftPolygon);
                        if (leftPolygonObj) {
                            let lines = [objReflection.uuid];
                            if (Array.isArray(leftPolygonObj.lines)) {
                                lines = leftPolygonObj.lines.filter(line => line !== objReflection.uuid);
                                lines.push(objReflection.uuid);
                            }
                            objReflection.leftPolygon = leftPolygonObj;
                            leftPolygonObj.lines = lines;
                            modifiedObjects.push(leftPolygonObj);
                        }
                    }
                    if (rightPolygon) {
                        let rightPolygonObj = canvas.getObjects().find(e => e.uuid === rightPolygon);
                        if (rightPolygonObj) {
                            let lines = [objReflection.uuid];
                            if (Array.isArray(rightPolygonObj.lines)) {
                                lines = rightPolygonObj.lines.filter(line => line !== objReflection.uuid);
                                lines.push(objReflection.uuid);
                            }
                            objReflection.rightPolygon = rightPolygonObj;
                            rightPolygonObj.lines = lines;
                            modifiedObjects.push(rightPolygonObj);
                        }
                    }
                } else if (objReflection.lines && Array.isArray(objReflection.lines)) {
                    handleObjectLineUpdating(objReflection, allModifiedObjects);
                }
            }

            const undoRedoListener = async listenerData => {
                let type;
                let isOneStep = false;
                if (typeof listenerData === 'string') {
                    type = listenerData;
                } else {
                    type = listenerData.type;
                    // isOneStep determines if the process is about reverting the state (abortion) not normal undo redo.
                    isOneStep = listenerData.oneStep;
                    if (listenerData.index === -1) { 
                        return
                    }
                    // if the process is one step, we should check the stack length
                    if (type === 'undo' && listenerData.index >= undoStack.length) {
                        return
                    }
                    // if the process is one step, we should check the stack length
                    if (type === 'redo' && listenerData.index >= redoStack.length) {
                        return
                    }
                }
                removeAllExistedLasso(canvas);
                if (isProcessing.current) {
                    console.log('undo-redo disabled until the process is completed');
                    return;
                }
                try {
                    isProcessing.current = true;
                    if (type === 'undo') {
                        canvas.discardActiveObject().requestRenderAll();
                        if (!undoStack || !undoStack.length) {
                            setUndoStack([]);
                            return;
                        }
                        const lastData = isOneStep ? undoStack[listenerData.index] : undoStack.slice(-1)[0];
                        
                        const data = undoStack.slice(0, undoStack.length - 1);
                        let historyData = history.slice(0, history.length);
                        
                        let blockProcessId;

                        if (lastData?.action === 'stateChange') {
                            const affectedShapes = lastData.affectedObjects;
                            let modifiedFrames = [];

                            // abortion handler to revert the state
                            const abortStepHandler = (processId) => {
                                canvas.fire('redo-one-step', { processId });
                                canvas.lockManager.stopEditing(affectedShapes, canvas.pageId, processId);
                            }
                            
                            // if the process is not one step, we should lock the editing
                            if (!isOneStep) {
                                const { shapeUuids, processId } = canvas.lockManager.startEditing(
                                    affectedShapes,
                                    canvas.pageId,
                                    (data) => {
                                        if (!data?.failedShapes || !data?.failedShapes.length) {
                                            return
                                        }
                                        abortStepHandler(data.processId)
                                    }
                                )
                                blockProcessId = processId;
                                
                                if (!shapeUuids?.length || shapeUuids?.length !== affectedShapes.length) {
                                    abortStepHandler(blockProcessId)
                                    return
                                }
                                lastData.processId = processId;
                            }

                            for (const object of affectedShapes) {
                                const frame = canvas.getObjects().find(item => item.uuid === object.properties.attachedFrameId);

                                if (frame && object.properties.attachedFrameId) {
                                    if (object.action === 'created') frame.attachments = frame.attachments?.filter(item => item !== object.properties.uuid);
                                    if (object.action === 'deleted') frame.attachments = [...frame.attachments, object.properties.uuid];
                                    if (object.action !== 'modified') {
                                        canvas.fire('frame-modified', { frame, updatedAttachments: [object.properties], detachedObjects: [] });
                                        const frameData = createObjectToBeEmitted(
                                            whiteBoardId,
                                            userId,
                                            customToObject(frame),
                                            false,
                                            frame.shapeType
                                        );
                                        frameData.actionTaken = 'modified';
                                        frameData.modifiedBy = userId;
                                        modifiedFrames.push(frameData);
                                    }
                                }
                            }
                            // modify the history data to get previous state for the object
                            const lastDataUUidMap = lastData.affectedObjects.map(obj => obj.uuid);
                            for (const affectedObjectUuid of lastDataUUidMap) {
                                for (let i = historyData.length - 1; i >= 0; i--) {
                                    const history = historyData[i];
                                    // filter the stackOrder action
                                    if (history.action === 'stackOrder') continue;

                                    // if the affected object is in the history, we should remove it from the last history stack 
                                    // since we want to get the previous state of the object
                                    if (history.affectedObjects.map(o => o.uuid).includes(affectedObjectUuid)) {
                                        const affectedObjects = history.affectedObjects.filter(o => o.uuid !== affectedObjectUuid);
                                        if (!affectedObjects.length) {
                                            historyData[i] = null;
                                        } else {
                                            historyData[i].affectedObjects = affectedObjects;
                                        }
                                        
                                        historyData = historyData.filter(d => d); 
                                        break;
                                    }
                                }
                            }

                            const allModifiedObjects = [];
                            const allCreatedObjects = [];
                            const allDeletedObjects = [];

                            for (const objectHistoryData of lastData.affectedObjects) {
                                if (objectHistoryData?.action === 'modified') {
                                    try {
                                        const modifiedData = historyData.filter(undoStackData => {
                                            if (undoStackData.action === 'stateChange') {
                                                const foundObjectHistory = undoStackData.affectedObjects.find(obj => obj.uuid === objectHistoryData.uuid);
                                                if (foundObjectHistory) return true;
                                            }
                                            return false;
                                        });

                                        for (let obj of modifiedData) {
                                            for (let affectedObj of obj.affectedObjects) {
                                                if (affectedObj.uuid === objectHistoryData.uuid) {
                                                    affectedObj.properties.attachments = objectHistoryData.properties.attachments;
                                                }
                                            }
                                        }
                                    
                                        const reversedPrevData = [...modifiedData].reverse();
                                        let prevData = reversedPrevData.find(undoStackData => undoStackData.affectedObjects.find(obj => obj.uuid === objectHistoryData.uuid));
                                        if (prevData) prevData = prevData?.affectedObjects?.find(obj => obj.uuid === objectHistoryData.uuid)?.properties;
                                        if (!prevData) {
                                            prevData = findShapeFromShapes(objectHistoryData.uuid, canvas);
                                        }
            
                                        if (prevData) {
                                            const structuredData = JSON.parse(JSON.stringify(prevData));
                                            // if the object is image, we just need to update the basic props
                                            if (structuredData.type === 'optimizedImage') {
                                                handleOptimizedImageModification(structuredData, allModifiedObjects);
                                            } else {
                                                // if the object is not image, we need to create a object instance for complex scenarios
                                                await new Promise((resolve) => {
                                                    if (structuredData.type === 'frame') {
                                                        console.log(structuredData);
                                                    }
                                                    createFabricInstance(structuredData, function (objects) {
                                                        objects.forEach(function (o) {
                                                            o.toObject = cloneFabricObjectHelper(o);
                                                            checkTargetAndArrangeText(o);
                                                            const objReflection = canvas.getObjects().find(e => e.uuid === structuredData.uuid);
                                                            objReflection.onShapeChanged();
                                                            if (o.type === 'group') {
                                                                objReflection.set(o).setCoords().addWithUpdate();

                                                                // We need to override some properties of the group and group objects in order to prevent broken selection.
                                                                if (o.angle !== 0) {
                                                                    objReflection.set({ width: o.width, height: o.height, angle: o.angle });
                                                                    for (const [idx] of objReflection._objects.entries()) {
                                                                        objReflection._objects[idx].set({ angle: 0 })
                                                                    }
                                                                }
                                                                handleOriginalStateForGroup(objReflection, o, structuredData);
                                                            }
                                                            else if (o.type === 'frame') {
                                                                objReflection.set(o).setCoords();
                                                                setFrameOptions(objReflection);
                                                            }
                                                            else {
                                                                let newObj = {};
                                                                if (o.type === 'textbox') {
                                                                    delete o.__eventListeners;
                                                                    newObj = updateAllPropertiesOfTextboxObject(o);
                                                                } else if (o.type === 'curvedLine') {
                                                                    newObj = o;

                                                                    if (o.curvedLineVersion !== 'v2' && objReflection.curvedLineVersion === 'v2') {
                                                                        newObj.curvedLineVersion = null;
                                                                    }
                                                                } else {
                                                                    newObj = o;
                                                                }

                                                                objReflection.set(newObj).setCoords();
                                                            }
                
                                                            let controlData = { mtr: false };
                                                            if (!prevData.lockMovementX && !prevData.lockMovementY) {
                                                                controlData = {
                                                                    mt: isTargetText(objReflection) ? false : true,
                                                                    mb: isTargetText(objReflection) ? false : true,
                                                                    ml: true,
                                                                    mr: true,
                                                                    bl: true,
                                                                    br: true,
                                                                    tl: true,
                                                                    tr: true,
                                                                    rotate: true,
                                                                    connectorLeft: true,
                                                                    connectorTop: true,
                                                                    connectorRight: true,
                                                                    connectorBottom: true,
                                                                }
                                                            } else {
                                                                controlData = {
                                                                    mt: false,
                                                                    mb: false,
                                                                    ml: false,
                                                                    mr: false,
                                                                    bl: false,
                                                                    br: false,
                                                                    tl: false,
                                                                    tr: false,
                                                                    rotate: false,
                                                                    connectorLeft: false,
                                                                    connectorTop: false,
                                                                    connectorRight: false,
                                                                    connectorBottom: false,
                                                                }
                                                            }
                                                            // Frame shouldn't have to rotate.
                                                            if (NON_ROTATABLE_OBJECT_TYPES.includes(o.type)) {
                                                                controlData.rotate = false;

                                                                if (o.type === 'table') {
                                                                    TABLE_NOT_ALLOWED_CONTROL_TYPES.forEach((d) => controlData[d] = false);
                                                                }
                                                            }

                                                            objReflection.setControlsVisibility(controlData);

                                                            const modifiedObjects = [objReflection];

                                                            handleLineStateOnModified(objReflection, structuredData, modifiedObjects, allModifiedObjects);

                                                            if (objReflection.attachedFrameId) {
                                                                try {
                                                                    const frame = canvas.getObjects().find(e => e.uuid === objReflection.attachedFrameId);
                                                                    if (frame) {
                                                                        objReflection.calculatedPos = calculateAttachedPos(frame, objReflection);
                                                                        objReflection.wiredFrame = frame;
                                                                        
                                                                        if (isTargetLocked(frame)) {
                                                                            objReflection.selectable = false;
                                                                        }
                                                                    }
                                                                } catch (err) {
                                                                    console.log(err);
                                                                }
                
                                                            }
                                                            modifiedObjects.find(i => i.uuid === o.uuid).zIndex = objectHistoryData?.properties?.zIndex

                                                            moveTheCommentAndFireCanvasEvent(canvas, objReflection);
                                                            canvas.renderAll();
                                                            allModifiedObjects.push(...modifiedObjects);
                                                            resolve(o);
                                                        });
                                                    });
                                                })
                                            }
                                        }
                                    } catch(err) {
                                        console.error('Error while undo redo -- modify', err);
                                    }

                                } else if (objectHistoryData?.action === 'created' && isUserHasAccessToFeature('history_create_or_delete', userAccessRef.current)) {
                                    historyDeleteHandler({...objectHistoryData?.properties, zIndex: objectHistoryData?.properties?.zIndex}, allDeletedObjects);
                                } else if (objectHistoryData?.action === 'deleted' && isUserHasAccessToFeature('history_create_or_delete', userAccessRef.current)) {
                                    await historyCreateHandler({...objectHistoryData?.properties, zIndex: objectHistoryData?.properties?.zIndex}, allCreatedObjects);
                                }
                            }
                            
                            if (!isOneStep) {
                                if (allCreatedObjects?.length) _emitListData(allCreatedObjects, 'created');
                                if (allModifiedObjects?.length) _emitListData(allModifiedObjects, 'modified', { useQueue: true });
                                if (allDeletedObjects?.length) _emitListData(allDeletedObjects, 'deleted'); 
                            }
                            
                            emitData(modifiedFrames.filter((mf, i, arr) =>
                                arr.findIndex(c => (c.uuid === mf.uuid)) === i
                            ), SOCKET_EVENT.MODIFIED);
                            
                            canvas.lockManager.stopEditing(affectedShapes, canvas.pageId, blockProcessId);
                        } else if (lastData?.action === 'stackOrder') {
                            let prevStackorder = lastData.oldStackOrder;
                            if (prevStackorder) {
                                let changedObjects = [];

                                prevStackorder.forEach(p => {
                                    let matchedObj = canvas.getObjects().find(c => c.uuid === p.uuid);
                                    if (matchedObj && matchedObj.zIndex !== p.zIndex) {
                                        matchedObj.zIndex = p.zIndex;
                                        changedObjects.push(matchedObj);
                                    }
                                });

                                canvas.fire('history-emit-data', {
                                    action: 'stackOrder',
                                    stackOrder: prevStackorder,
                                    updatedStacks: changedObjects.map(item => {
                                        return {zIndex: item.zIndex, uuid: item.uuid}
                                    })
                                });
                            }
                            setObjectsStackWithZIndex(canvas);
                        }
                        // if the process is normal undo redo
                        if (!isOneStep) {
                            setUndoStack(Array.isArray(data) ? data : []);
                            setRedoStack(currentRedoData => {
                                const newRedoData = [...currentRedoData, { ...lastData }];
                                redoStackRef.current = newRedoData;
                                return newRedoData;
                            });
                            setHistory(Array.isArray(historyData) ? historyData : []);
                        } else {
                            // if the process is one step. Means, we only need to revert the state. and do not add it to the undo stack
                            setUndoStack((prevState) => {
                                return prevState.filter((_, i) => i !== listenerData.index);
                            })
                            setHistory(prevState => prevState.filter(state => state.processId !== listenerData.processId));
                        }
                    } else if (type === 'redo') {
                        canvas.discardActiveObject().requestRenderAll();
                        if (!redoStack || !redoStack.length) {
                            setRedoStack([])
                            return;
                        }
                        const lastData = isOneStep ? redoStackRef.current[listenerData.index] : redoStackRef.current.slice(-1)[0];
                        const data = redoStackRef.current.slice(0, redoStackRef.current.length - 1);

                        const affectedShapes = lastData.affectedObjects || [];
                        let modifiedFrames = [];

                        for (const object of affectedShapes) {
                            const frame = canvas.getObjects().find(item => item.uuid === object.properties.attachedFrameId);

                            if (frame && object.properties.attachedFrameId) {
                                if (object.action === 'deleted') frame.attachments = frame.attachments?.filter(item => item !== object.properties.uuid);
                                if (object.action === 'created') frame.attachments = [...frame.attachments, object.properties.uuid];
                                canvas.fire('frame-modified', { frame, updatedAttachments: [object.properties], detachedObjects: [] });
                                const frameData = createObjectToBeEmitted(
                                    whiteBoardId,
                                    userId,
                                    customToObject(frame),
                                    false,
                                    frame.shapeType
                                );
                                frameData.actionTaken = 'modified';
                                frameData.modifiedBy = userId;
                                modifiedFrames.push(frameData);
                            }
                        }
                        
                        let blockProcessId;

                        if (lastData?.action === 'stateChange') {
                            // abortion handler to revert the state
                            const abortStepHandler = (processId) => {
                                canvas.fire('undo-one-step', { processId });
                                canvas.lockManager.stopEditing(lastData.affectedObjects, canvas.pageId, processId);
                            }

                            // if the process is not one step, we should lock the editing
                            if (!isOneStep) {
                                const { shapeUuids, processId } = canvas.lockManager.startEditing(
                                    lastData.affectedObjects,
                                    canvas.pageId,
                                    (data) => {
                                        if (!data?.failedShapes || !data?.failedShapes.length) {
                                            return
                                        }
                                        abortStepHandler(data.processId)
                                    }
                                )
                                blockProcessId = processId;

                                if (!shapeUuids?.length || shapeUuids?.length !== lastData.affectedObjects.length) {
                                    abortStepHandler(blockProcessId)
                                    return
                                }
                                lastData.processId = processId;
                            }
                            
                            const allModifiedObjects = [];
                            const allCreatedObjects = [];
                            const allDeletedObjects = [];

                            for (const objectHistoryData of lastData.affectedObjects) {
                                if (objectHistoryData?.action === 'modified') {
                                    try {
                                        const structuredData = JSON.parse(JSON.stringify(objectHistoryData.properties));
                                        // if the object is image, we just need to update the basic props
                                        if (structuredData.type === 'optimizedImage') {
                                            handleOptimizedImageModification(structuredData, allModifiedObjects);
                                        } else {
                                            // if the object is not image, we need to create a object instance for complex scenarios
                                            await new Promise((resolve) => {
                                                createFabricInstance(structuredData, function (objects) {
                                                    objects.forEach(function (o) {
                                                        o.toObject = cloneFabricObjectHelper(o);
                                                        checkTargetAndArrangeText(o);
                                                        const objReflection = canvas.getObjects().find(e => e.uuid === structuredData.uuid);
                                                        objReflection.onShapeChanged();
                                                        if (o.type === 'group') {
                                                            objReflection.set(o).setCoords().addWithUpdate();

                                                            // We need to override some properties of the group and group objects in order to prevent broken selection.
                                                            if (o.angle !== 0) {
                                                                objReflection.set({ width: o.width, height: o.height, angle: o.angle });
                                                                for (const [idx] of objReflection._objects.entries()) {
                                                                    objReflection._objects[idx].set({ angle: 0 })
                                                                }
                                                            }
                                                            handleOriginalStateForGroup(objReflection, o, structuredData);
                                                        }
                                                        else if (o.type === 'frame') {
                                                            objReflection.set(o).setCoords();

                                                            setFrameOptions(objReflection);
                                                        }
                                                        else {
                                                            const newObj = o.type === 'textbox' ? updateAllPropertiesOfTextboxObject(o) : o;
                                                            objReflection.set(newObj).setCoords();
                                                        }
    
                                                        if (objReflection.type !== 'curvedLine' && objReflection.lines && Array.isArray(objReflection.lines)) {
                                                            handleObjectLineUpdating(objReflection, allModifiedObjects);
                                                        }
                                                        
                                                        let controlData = { mtr: false };
                                                        if (!structuredData.lockMovementX && !structuredData.lockMovementY) {
                                                            controlData = {
                                                                mt: isTargetText(objReflection) ? false : true,
                                                                mb: isTargetText(objReflection) ? false : true,
                                                                ml: true,
                                                                mr: true,
                                                                bl: true,
                                                                br: true,
                                                                tl: true,
                                                                tr: true,
                                                                rotate: true,
                                                            }
                                                        } else {
                                                            controlData = {
                                                                mt: false,
                                                                mb: false,
                                                                ml: false,
                                                                mr: false,
                                                                bl: false,
                                                                br: false,
                                                                tl: false,
                                                                tr: false,
                                                                rotate: false,
                                                            };
                                                        }

                                                        // Frame shouldn't have to rotate.
                                                        if (NON_ROTATABLE_OBJECT_TYPES.includes(o.type)) {
                                                            controlData.rotate = false;

                                                            if (o.type === 'table') {
                                                                TABLE_NOT_ALLOWED_CONTROL_TYPES.forEach((d) => controlData[d] = false);
                                                            }
                                                        }

                                                        objReflection.setControlsVisibility(controlData);

                                                        if (objReflection.attachedFrameId) {
                                                            try {
                                                                const frame = canvas.getObjects().find(e => e.uuid === objReflection.attachedFrameId);
                                                                if (frame && isTargetLocked(frame)) {
                                                                    objReflection.selectable = false;
                                                                }
                                                            } catch (err) {
                                                                console.log(err);
                                                            }
            
                                                        }
                
                                                        const modifiedObjects = [objReflection];
                                                        handleLineStateOnModified(objReflection, structuredData, modifiedObjects, allModifiedObjects);
                                                        
                                                        moveTheCommentAndFireCanvasEvent(canvas, objReflection);
                                                        canvas.renderAll();
                                                        allModifiedObjects.push(...modifiedObjects);
                                                        resolve(o);
                                                    });
                                                });
                                            })
                                        }
                                    } catch (err) {
                                        console.error('Error while undo redo -- modify', err);
                                    }
                                    
                                } else if (objectHistoryData?.action === 'created' && isUserHasAccessToFeature('history_create_or_delete', userAccessRef.current)) {
                                    await historyCreateHandler(objectHistoryData?.properties, allCreatedObjects);
                                } else if (objectHistoryData?.action === 'deleted' && isUserHasAccessToFeature('history_create_or_delete', userAccessRef.current)) {
                                    historyDeleteHandler(objectHistoryData?.properties, allDeletedObjects);
                                }
                            }

                            if (allCreatedObjects?.length) _emitListData(allCreatedObjects, 'created');
                            if (allModifiedObjects?.length) _emitListData(allModifiedObjects, 'modified', { useQueue: true });
                            if (allDeletedObjects?.length) _emitListData(allDeletedObjects, 'deleted');
                            setObjectsStackWithZIndex(canvas);
                            canvas.lockManager.stopEditing(lastData.affectedObjects, canvas.pageId, blockProcessId);
                        } else if (lastData?.action === 'stackOrder') {
                            let newStackOrder = lastData.newStackOrder;
                            if (newStackOrder) {
                                let changedObjects = [];

                                newStackOrder.forEach(p => {
                                    const matchedObj = canvas.getObjects().find(c => c.uuid === p.uuid);
                                    if (matchedObj && matchedObj.zIndex !== p.zIndex) {
                                        matchedObj.zIndex = p.zIndex;
                                        changedObjects.push(matchedObj);
                                    }
                                });

                                canvas.fire('history-emit-data', {
                                    action: 'stackOrder',
                                    stackOrder: newStackOrder,
                                    updatedStacks: changedObjects.map(item => {
                                        return {zIndex: item.zIndex, uuid: item.uuid}
                                    })
                                });
                            }
                            setObjectsStackWithZIndex(canvas);
                        }
                        emitData(modifiedFrames, SOCKET_EVENT.MODIFIED);
                        if (!isOneStep) {
                            setUndoStack(undoStack => [...undoStack, {...lastData }]);
                            setHistory(historyData => [...historyData, {...lastData }]);
                            setRedoStack(data);
                            redoStackRef.current = data;
                        } else {
                            setRedoStack((prevState) => {
                                return prevState.filter((_, i) => i !== listenerData.index);
                            })
                            setHistory(prevState => prevState.filter(state => state.processId !== listenerData.processId)); 
                        }
                        setObjectsStackWithZIndex(canvas);
                    }
                } catch (err) {
                    console.error('Error while undo redo', err);
                } finally {
                    isProcessing.current = false;
                    setObjectsStackWithZIndex(canvas);
                }
            }

            /**
             * Removes a specific undo stack with given processId.
             * @param {object} removeData The data that we want to remove from the undo stack.
             * @param {string} removeData.processId The processId that we want to remove from the undo stack.
             */
            const removeFromUndoStackListener = (removeData) => {
                if (!removeData || !removeData.processId) {
                    return
                }
                setUndoStack(data => {
                    return data.filter(d => d.processId !== removeData.processId);
                });
                
                setHistory(data => {
                    return data.filter(d => d.processId !== removeData.processId);
                })
            }

            /**
             * Undo one specific step with given processId. It does not add the removed undo stack to the redo stack like
             * regular undo redo process.
             * @param {object} data The data that we want to undo one step.
             * @param {string} data.processId The processId that we want to undo one step.
             */
            const undoOneStepListener = (data) => {
                if (!data?.processId) {
                    return
                }
                
                undoRedoListener({
                    type: 'undo',
                    oneStep: true,
                    index: undoStack.findIndex(d => d.processId === data.processId),
                    processId: data.processId,
                })
            }

            /**
             * Redo one specific step with given processId. It does not add the removed redo stack to the undo stack like
             * regular undo redo process.
             * @param {object} data The data that we want to redo one step.
             * @param {string} data.processId The processId that we want to redo one step.
             */
            const redoOneStepListener = (data) => {
                if (!data?.processId) {
                    return
                }

                undoRedoListener({
                    type: 'redo',
                    oneStep: true,
                    index: redoStack.findIndex(d => d.processId === data.processId),
                    processId: data.processId
                })
            }

            /**
             * Removes given shape from the undo and redo stacks so that user can't modify it with undo and redo.
             * @param {object} data The data that we want to remove from the undo and redo stacks.
             * @param {fabric.Object[]} data.shapes The shapes that we want to remove from the undo and redo stacks.
             */
            const removeFromUndoRedoStackListener = (data) => {
                const removeData = {
                    affectedObjects: data.shapes,
                    action: 'stateChange',
                    canvas
                }
                removeFromTheStackOnHistoryUpdate(removeData, undoStack, setUndoStack, canvas);
                removeFromTheStackOnHistoryUpdate(removeData, redoStack, setRedoStack, canvas);
            }

            canvas.on('object:modified-after', objectModifiedListener);
            canvas.on('add-to-undo-stack', addtoUndoStackListener);
            canvas.on('remove-to-undo-stack', removeToUndoStackListener);
            canvas.on('stackOrder-to-undo-stack', stackOrderToUndoStackListener);
            canvas.on('modify-to-undo-stack', objectModifiedListener);
            canvas.on('add-to-history', addToHistoryListener);
            canvas.on('undo-redo', undoRedoListener);
            canvas.on('remove-from-undo-stack', removeFromUndoStackListener);
            canvas.on('undo-one-step', undoOneStepListener);
            canvas.on('redo-one-step', redoOneStepListener);
            canvas.on('remove-from-undo-redo-stack', removeFromUndoRedoStackListener);

            return () => {
                canvas.off('object:modified-after', objectModifiedListener);
                canvas.off('add-to-undo-stack', addtoUndoStackListener);
                canvas.off('remove-to-undo-stack', removeToUndoStackListener);
                canvas.off('stackOrder-to-undo-stack', stackOrderToUndoStackListener);
                canvas.off('modify-to-undo-stack', objectModifiedListener);
                canvas.off('add-to-history', addToHistoryListener);
                canvas.off('undo-redo', undoRedoListener);
                canvas.off('remove-from-undo-stack', removeFromUndoStackListener);
                canvas.off('undo-one-step', undoOneStepListener);
                canvas.off('redo-one-step', redoOneStepListener);
                canvas.off('remove-from-undo-redo-stack', removeFromUndoRedoStackListener);
            }
        }
    }, [canvas, undoStack, redoStack, addtoUndoStack, history, _addToHistory, moveTheCommentAndFireCanvasEvent]);

    useEffect(() => {
        if (undoStack.length > 0) {
            const lastData = undoStack[undoStack.length - 1];
            if (lastData.aborted) {
                canvas.fire('undo-one-step', { processId: lastData.processId });
            }
            setEnabledHistory(data => {
                return {
                    ...data,
                    undo: true
                }
            });
        } else {
            setEnabledHistory(data => {
                return {
                    ...data,
                    undo: false
                }
            });
        }
    }, [undoStack]);

    useEffect(() => {
        if (redoStack.length > 0) {
            setEnabledHistory(data => {
                return {
                    ...data,
                    redo: true
                }
            });
        } else {
            setEnabledHistory(data => {
                return {
                    ...data,
                    redo: false
                }
            });
        }
    }, [redoStack]);

    // clear history data when socket disconnected
    useEffect(() => {
        if (socketConnectionStatus === SOCKET_STATUS_MODS.DISCONNECTED || socketConnectionStatus === SOCKET_STATUS_MODS.RECONNECT_ATTEMPT) {
            setUndoStack([]);
            setRedoStack([]);
            setHistory([]);
        }
    }, [socketConnectionStatus]);

    return { enabledHistory };
}

export default useHistory;