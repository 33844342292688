/**
 *
 */
const EllipseIcon = () => {
    return (
        <svg
            fill="none"
            height="20"
            viewBox="0 0 20 20"
            width="20"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="10"
                cy="10"
                r="9.25"
                stroke="#3C3E49"
                strokeWidth="1.5"
            />
        </svg>
    );
}

export default EllipseIcon;