import React from 'react';

/**
 *
 */
const ChangeShapeIcon = () => {
    return (
        <svg fill="none" height="22" viewBox="0 0 22 22" width="22" xmlns="http://www.w3.org/2000/svg">
            <path clipRule="evenodd" d="M1.625 2.625C1.625 2.07272 2.07272 1.625 2.625 1.625H8.125C8.67728 1.625 9.125 2.07272 9.125 2.625V8.125C9.125 8.67728 8.67728 9.125 8.125 9.125H2.625C2.07272 9.125 1.625 8.67728 1.625 8.125V2.625Z" fillRule="evenodd" stroke="#3C3E49" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            <path clipRule="evenodd" d="M16.625 9.125C18.6961 9.125 20.375 7.44607 20.375 5.375C20.375 3.30393 18.6961 1.625 16.625 1.625C14.5539 1.625 12.875 3.30393 12.875 5.375C12.875 7.44607 14.5539 9.125 16.625 9.125Z" fillRule="evenodd" stroke="#3C3E49" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            <path clipRule="evenodd" d="M16.6549 12.6476C16.5278 12.4033 16.2753 12.25 15.9999 12.25C15.7245 12.25 15.472 12.4033 15.3449 12.6476L11.7307 19.1534C11.5919 19.4062 11.5897 19.712 11.7249 19.9667C11.8527 20.2146 12.1069 20.3716 12.3857 20.3751H19.6141C19.8929 20.3716 20.1471 20.2146 20.2749 19.9667C20.4101 19.712 20.4079 19.4062 20.2691 19.1534L16.6549 12.6476Z" fillRule="evenodd" stroke="#3C3E49" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            <path clipRule="evenodd" d="M5.8761 11.8626C5.75294 11.7134 5.56957 11.627 5.3761 11.627C5.18263 11.627 4.99926 11.7134 4.8761 11.8626L1.78443 15.5601C1.57186 15.8155 1.57186 16.1863 1.78443 16.4417L4.87443 20.1392C4.9976 20.2884 5.18096 20.3749 5.37443 20.3749C5.56791 20.3749 5.75127 20.2884 5.87443 20.1392L8.96443 16.4417C9.17701 16.1863 9.17701 15.8155 8.96443 15.5601L5.8761 11.8626Z" fillRule="evenodd" stroke="#3C3E49" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        </svg>
    );
}

export default ChangeShapeIcon;
