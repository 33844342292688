import { fabric } from 'fabric';


/**
 * Loading indicator for image uploading.
 */
const LoadingIndicator = fabric.util.createClass(fabric.Circle, {
    type: 'loadingIndicator',
    initialize(options) {
        options = options || {};
        this.callSuper('initialize', options);
        this.set({
            radius: 20,
            fill: 'transparent',
            left: options.left,
            top: options.top,
            stroke: '#fff',
            strokeWidth: 5,
            startAngle: 40,
            endAngle: Math.PI,
            originX: 'center',
            originY: 'center'
        });
    },
    _render(ctx) {
        this.callSuper('_render', ctx);
    }
});


export default LoadingIndicator;