
/**
 * @param {Event} e - Event object.
 * @param {fabric.Object} target
 * @private
 */
export function _shouldClearSelection(e, target) {
    const activeObjects = this.getActiveObjects();
    const activeObject = this._activeObject;

    // If table textbox selected and clicked to different cell.
    if (target && target.type === 'textbox' && target.tableUuid === activeObject?.uuid) {
        return false;
    } else if (target && target.type === 'table' && target.uuid === activeObject?.uuid) { // Different cell selection
        return false;
    }

    return (
        !target
        ||
        (target &&
            activeObject &&
            activeObjects.length > 1 &&
            activeObjects.indexOf(target) === -1 &&
            activeObject !== target &&
            !this._isSelectionKeyPressed(e)
        ) ||
        (target && !target.evented) ||
        (target &&
            !target.selectable &&
            activeObject &&
            activeObject !== target
        )
    );
}