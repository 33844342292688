import { AUTH_VALIDATION_MESSAGE } from './Constant';
import { EMAIL_WITH_SPECIAL_CHARS_REGEX, EMAIL_REGEX_FOR_RECOMMENDATION, HEX_CODE_REGEX, NAME_SPECIAL_CHAR } from './Regexes';

export const userNameValidation = (username) => {
    const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@*/;

    return regex.test(username);
};

/**
 * Checking is the user is a member of the builder.ai.
 * @param {string} email
 * @returns Boolean.
 */
export const isBuilderAiUser = (email) =>
    email?.lastIndexOf('@builder.ai') > -1;

export const emailValidation = (email) => {
    if (EMAIL_WITH_SPECIAL_CHARS_REGEX.test(email)) {
        return null;
    }

    if (email.trim() === '') {
        return AUTH_VALIDATION_MESSAGE.EMAIL_REQUIRED;
    }

    return AUTH_VALIDATION_MESSAGE.ENTER_VALID_EMAIL;
};

export const emailWithSpecialCharsValidation = (email) => {
    return EMAIL_WITH_SPECIAL_CHARS_REGEX.test(email);
};

export const emailCharactersValidation = (email) => {
    if (email?.indexOf('@') > 1) {
        return emailWithSpecialCharsValidation(email);
    }

    return EMAIL_REGEX_FOR_RECOMMENDATION.test(email);
};

export const phoneValidation = (phone) => {
    if (!phone.match('[0-9]{10}')) {
        return AUTH_VALIDATION_MESSAGE.VALID_PHONE_NUMBER_REQUIRED;
    }
    return null;
};

export const _hasUpperCase = (str) => {
    return /[A-Z]/.test(str);
};
export const _hasLowerCase = (str) => {
    return /[a-z]/.test(str);
};
export const _hasNumber = (str) => {
    return /\d/.test(str);
};
export const passwordValidation = (
    password,
    isConfirmPassword = false,
    isLoginPage = false
) => {
    if (!password) {
        if (isConfirmPassword) return AUTH_VALIDATION_MESSAGE.FIELD_REQUIRED;
        return AUTH_VALIDATION_MESSAGE.PASSWORD_REQUIRED;
    }
    if(isLoginPage === true || isConfirmPassword === true) return null;
    if (password.length < 8) {
        return AUTH_VALIDATION_MESSAGE.PASSWORD_MINIMUM_LENGTH;
    }
    if (!_hasLowerCase(password)) {
        return AUTH_VALIDATION_MESSAGE.PASSWORD_CONTAIN_LOWERCASE;
    }
    if (!_hasUpperCase(password)) {
        return AUTH_VALIDATION_MESSAGE.PASSWORD_CONTAIN_UPPERCASE;
    }
    if (!_hasNumber(password)) {
        return AUTH_VALIDATION_MESSAGE.PASSWORD_CONTAIN_NUMBER;
    }
    return null;
};

export const nameValidation = (fieldName, fieldValue) => {
    if (fieldValue.trim() === '') {
        return fieldName + ' ' + AUTH_VALIDATION_MESSAGE.IS_REQUIRED;
    }
    if (NAME_SPECIAL_CHAR.test(fieldValue)) {
        return AUTH_VALIDATION_MESSAGE.INVALID_CHARACTER;
    }
    if (fieldValue.trim().length < 3) {
        return fieldName + ' ' + AUTH_VALIDATION_MESSAGE.NEED_THREE_CHARACTER;
    }
    return null;
};

/**
 * @param a
 * @param b
 */
export function arrayEquals(a, b) {
    return Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index]);
}

export const hexCodeValidation = (hexCode) => {
    if (!hexCode || typeof hexCode !== 'string') return false;
    return HEX_CODE_REGEX.test(hexCode);
}