import { createSlice } from '@reduxjs/toolkit';
import { SOCKET_STATUS_MODS } from '../../../helpers/Constant';

const initialState = {
    status: SOCKET_STATUS_MODS.IDLE,
    shouldActivityLogRefresh: false,
    shouldRefreshCommentsAndUsers: false,
    shouldRefetchComments: false,
}


const socketSlice = createSlice({
    name: 'socket',
    initialState,
    reducers: {
        changeMode: (state, action) => {
            if (state.status !== action.payload)
                state.status = action.payload;
        },
        setShouldActivityLogRefresh: (state, action) => {
            state.shouldActivityLogRefresh = action.payload;
        },
        setShouldRefreshCommentsAndUsers: (state, action) => {
            state.shouldRefreshCommentsAndUsers = action.payload;
        },
        setShouldRefetchComments: (state, action) => {
            state.shouldRefetchComments = action.payload;
        }
    }
});

export default socketSlice.reducer;