import React from 'react'

const Message = () => {
    return (
        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.37333 13.1666C7.03333 13.1066 2.04 14.9332 2.04 14.9332C3.26667 13.7799 3.9 12.6666 3.75333 11.5599C2.3712 10.5279 1.5403 8.9177 1.5 7.19324C1.5 3.81324 4.83333 1.07324 9 1.07324C13.1667 1.07324 16.5 3.81324 16.5 7.19324C16.5 10.5732 13.14 13.3332 9 13.3332C8.45389 13.3263 7.9095 13.2705 7.37333 13.1666V13.1666Z" stroke="#6200EA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.2734 3.19995C12.5401 3.66662 14.2201 5.33328 14.2201 7.32662C14.1785 8.61819 13.5155 9.8101 12.4401 10.5266" stroke="#651FFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default Message