import { fabric } from 'fabric';

/**
 * This can be used to get a snapshot of the given points on the canvas.
 * It's an override function for fabric.Canvas.toCanvasElement. 
 * It is overrided Because the original function affect by the canvas 
 * zoom level and the pan position.
 * @param multiplier
 * @param options
 */
export default function toCanvasEl(multiplier, options) {
    multiplier = multiplier || 1;
    options = options || {};
    const scaledWidth = (options.width || this.width) * multiplier,
        scaledHeight = (options.height || this.height) * multiplier,
        originalWidth = this.width,
        originalHeight = this.height,
        vp = this.viewportTransform,
        translateX = (0 - (options.left || 0)) * multiplier,
        translateY = (0 - (options.top || 0)) * multiplier,
        originalInteractive = this.interactive,
        newVp = [1, 0, 0, 1, translateX, translateY],
        originalRetina = this.enableRetinaScaling,
        canvasEl = fabric.util.createCanvasElement(),
        originalContextTop = this.contextTop;
    let objectsToRender = options.filter
        ? this._objects.filter(options.filter)
        : [...this._objects];

    // if request is for a specific frame, filter objects to render
    if (options.frame) {
        objectsToRender = objectsToRender.filter(obj => obj.uuid === options.frame || obj.attachedFrameId === options.frame);
    }
    canvasEl.width = scaledWidth;
    canvasEl.height = scaledHeight;
    this.contextTop = null;
    this.enableRetinaScaling = false;
    this.interactive = false;
    this.viewportTransform = newVp;
    this.width = scaledWidth;
    this.height = scaledHeight;
    this.calcViewportBoundaries();
    this.renderCanvas(canvasEl.getContext('2d'), objectsToRender, {shallowRender: true});
    this.viewportTransform = vp;
    this.width = originalWidth;
    this.height = originalHeight;
    this.calcViewportBoundaries();
    this.interactive = originalInteractive;
    this.enableRetinaScaling = originalRetina;
    this.contextTop = originalContextTop;
    return canvasEl;
}