import React from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import ExportSvgIcon from '../../assets/images/canvasRedesignImages/export_svg.svg'
import ExportImageIcon from '../../assets/images/canvasRedesignImages/export_image.svg'

const ExportOptionsBlock = ({
    trackEventDownloadBoard,
    onDownloadSVG,
    onDownloadCanvas,
    canvas,
}) => {

    return (
        <div className="exportOptionsBlock">
            <div className="exportOptionsInner">
                <div
                    className="vector"
                    onClick={() => { trackEventDownloadBoard('Vector'); onDownloadSVG(canvas, toast); }}
                >
                    <img
                        alt="export board as svg"
                        src={ExportSvgIcon}
                        style={{ marginLeft: '10px', width: '15px' }}
                    />

                    <span>Vector</span>
                </div>
                <div
                    className="jpeg"
                    onClick={() => { trackEventDownloadBoard('JPEG'); onDownloadCanvas(canvas, 'jpeg'); }}
                >
                    <img
                        alt="export board as JPG"
                        src={ExportImageIcon}
                        style={{ marginLeft: '10px', width: '15px' }}
                    />

                    <span>JPG</span>
                </div>
                <div
                    className="png"
                    onClick={() => { trackEventDownloadBoard('PNG'); onDownloadCanvas(canvas, 'png'); }}
                >
                    <img
                        alt="export board as PNG"
                        src={ExportImageIcon}
                        style={{ marginLeft: '10px', width: '15px' }}
                    />

                    <span>PNG</span>
                </div>
            </div>
        </div>
    )
};

ExportOptionsBlock.propTypes = {
    trackEventDownloadBoard: PropTypes.func.isRequired,
    onDownloadSVG: PropTypes.func.isRequired,
    onDownloadCanvas: PropTypes.func.isRequired,
    canvas: PropTypes.object,
}

export default ExportOptionsBlock;