import React, { useState, useRef } from 'react';
import clsx from 'clsx';
import { useLayer } from 'react-laag';
import { motion, AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';

import ChevronDownIcon from '../../svgIcons/ChevronDown.jsx';
import Spinner from '../../svgIcons/Spinner';

import './SelectInput.scss';

/**
 * Select input component for selecting an option from a list of options.
 * @param {() => void} onChange - Callback function for when the select input is changed.
 */
const SelectInput = ({
    options,
    onChangeItem,
    isLoading,
    disabled,
    defaultValue,
    disabledOptions,
    placement = 'bottom',
}) => {
    const selectRef = useRef();
    const [showOptions, setShowOptions] = useState(false);
    const [selected, setSelected] = useState(() => options.find((option) => option.id === defaultValue) || options[0]);

    const closeDetails = () => {
        setShowOptions(false);
        selectRef.current.removeAttribute('open');
    };

    const handleOptionClick = (option) => {
        setSelected(option);
        onChangeItem(option);
        closeDetails();
    };

    const { renderLayer, triggerProps, layerProps } = useLayer({
        isOpen: showOptions,
        placement,
        auto: true,
        triggerOffset: 12,
        arrowOffset: 4,
        onDisappear: (disappearType) => {
            if (disappearType === 'full') {
                closeDetails();
            }
        },
        onOutsideClick: () => closeDetails(),
    });

    return (
        <details className="selectBox" data-testid="selectBox" ref={selectRef}>
            <summary
                className={clsx(
                    placement.split('-')?.[1] === 'end' ? 'float-right' : 'float-left',
                    {
                        active: showOptions && !disabled,
                        disabled,
                    }
                )}
                {...triggerProps}
                data-testid="selectBox__selectedItem"
                onClick={() => !disabled && setShowOptions((prevState) => !prevState)}
            >
                {selected.display}
                {isLoading ? <Spinner height={16} width={16} /> : null}
                <ChevronDownIcon height={16} stroke="#6200ea" width={16} />
            </summary>

            {renderLayer(
                <AnimatePresence>
                    {showOptions ? <motion.ul
                        animate={{ opacity: 1, y: 0 }}
                        className={clsx('selectBox__options', {
                            active: showOptions && !disabled,
                        })}
                        data-testid="selectBox__options"
                        exit={{ opacity: 0, y: -10 }}
                        initial={{ opacity: 0, y: -10 }}
                        transition={{ ease: 'easeInOut', duration: 0.15 }}
                        {...layerProps}
                    >
                        {options.map((option) => (
                            <li
                                className={clsx({
                                    disabled: disabledOptions?.includes(option.id),
                                    'border-top': option.border === 'top',
                                })}
                                data-name={option.display}
                                data-testid="selectBox__option"
                                key={option.id}
                                onClick={() => handleOptionClick(option)}
                            >
                                {option.icon}
                                <p>{option.display}</p>
                                {selected.id === option.id ? <em className="icon-check-mark" /> : null}
                            </li>
                        ))}
                    </motion.ul> : null}
                </AnimatePresence>
            )}
        </details>
    );
};

SelectInput.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        display: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node
        ]),
        id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ])
    })).isRequired,
    onChangeItem: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    disabledOptions: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])),
    placement: PropTypes.oneOf([
        'top-center',
        'top-start',
        'top-end',
        'left-start',
        'left-center',
        'left-end',
        'right-start',
        'right-center',
        'right-end',
        'bottom-start',
        'bottom-center',
        'bottom-end',
        'bottom',
        'center'
    ])
}

export default React.memo(SelectInput);