import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import './dropdown.scss';

const Dropdown = ({
    width,
    children,
    menu = [],
    onMenuItemClicked,
    onVisibilityChange,
    disabled,
    usePortal
}) => {
    const dropdownRef = useRef();
    const [showMenu, setShowMenu] = useState(false);
    const [dimensions, setDimensions] = useState({});

    const toggleDropdownMenu = (event) => {
        event.stopPropagation();
        if (showMenu === false) {
            const dimensions = dropdownRef.current.getBoundingClientRect();
            const lastDimensions = {
                top: dimensions.top,
                left: dimensions.right, // I intentionally set the right position. We need display the options as right-alligned.
                height: dimensions.height
            };

            // Adjusted 48px for now. If the height of menu item will change, we can change this as well.
            const menuItemsLength = 48 * menu.length;

            if ((dimensions.bottom + menuItemsLength) > window.innerHeight) {
                lastDimensions.top -= (menuItemsLength + dimensions.height);
                lastDimensions.topAligned = true;
            } else {
                lastDimensions.topAligned = false;
            }

            setDimensions(lastDimensions);
        }

        setShowMenu((state) => !state);
    }

    const onItemClicked = useCallback((menuItem) => {
        onMenuItemClicked(menuItem);
        if (usePortal) { return;}
        setShowMenu(false);
    }, [setShowMenu, usePortal, onMenuItemClicked]);

    useEffect(() => {
        // close the options when the user clicks outside the select input
        const handleOutsideClick = (e) => {
            if (showMenu && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                setShowMenu(false);
            }
        }

        window.addEventListener('click', handleOutsideClick);

        return () => {
            window.removeEventListener('click', handleOutsideClick);
        }

    }, [showMenu])

    useEffect(() => {
        onVisibilityChange(showMenu);
    }, [showMenu]);

    const MenuDom = useMemo(() => (
        <ul
            className="dropdownMenu"
            style={usePortal ? {
                top: dimensions.top + 30,
                left: dimensions.left,
                width
            } : { width }}
        >
            {menu.map((menuItem, idx) => (
                <li
                    className={clsx('dropdownMenu__item', { '--disabled': menuItem.disabled === true })}
                    key={menuItem.id || idx}
                    onClick={() => onItemClicked(menuItem)}
                >
                    {menuItem.icon ? <span className="icon">{menuItem.icon}</span> : null}

                    <span className="menu-item-label">
                        {menuItem.label}
                    </span>
                </li>
            ))}
        </ul>
    ), [menu, dimensions, width, onItemClicked])

    return (
        <div className="dropdown" ref={dropdownRef}>
            <button
                className="dropdown__button"
                disabled={disabled}
                onClick={(event) => toggleDropdownMenu(event)}
                type="button"
            >
                {children}
            </button>

            {(showMenu && usePortal) ? createPortal(MenuDom, document.body) : null}
            {(showMenu && !usePortal) ? MenuDom : null}
        </div>
    )
};

Dropdown.propTypes = {
    width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    menu: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string
        ]),
        disabled: PropTypes.bool,
        icon: PropTypes.node,
        label: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node,
            PropTypes.string,
            PropTypes.number
        ])
    })),
    onMenuItemClicked: PropTypes.func,
    onVisibilityChange: PropTypes.func,
    disabled: PropTypes.bool,
    usePortal: PropTypes.bool,
}

Dropdown.defaultProps = {
    width: 180,
    disabled: false,
    usePortal: true    
}

export default Dropdown;
