import { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CANVASES_CTX_ACTIONS, EMITTER_TYPES, LEFT_DRAWER_TABS, SOCKET_STATUS_MODS } from '../helpers/Constant';
import getToastIcon from '../helpers/media/GetToastIcon';
import store from '../redux/Store';
import { builderLoginAutomated, enterpriseLoginAutomated } from '../services/AuthService';
import { automatedBoard, automatedBMeetBoard, automatedEnterpriseBoard, getWhiteBoardDetails } from '../services/DataService';
import { getUserQueryParams } from '../helpers/CommonFunctions';
import useCanvas from './UseCanvas';
import { CanvasesDispatchContext } from '../context/canvases/CanvasesContext';
import eventEmitter from '../helpers/EventEmitter';
import '../components/customToastCss/custom_toast.scss';
import { closeRequestAccessToast } from '../helpers/ToastHelper';
import {fitToScreenAssistance} from '../helpers/FitToScreenAssistance';


const useSocketConnection = ({
    setWbId,
    setWhiteBoardId,
    setWhiteBoardSlugId,
    setBoardName,
    activePageId,
    initializePagesRef,
    setShowOverlay
}) => {
    const dispatchCanvasesContext = useContext(CanvasesDispatchContext);

    const socketStatusState = useSelector(state => state.socket.status);
    const grayOverlay = useRef();
    const addedOverlay = useRef(false);
    const disconnectedBefore = useRef(false);
    const toastMessage = useRef();
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const canvas = useCanvas();

    useEffect(() => {
        const clearCanvas = () => {
            dispatchCanvasesContext({type: CANVASES_CTX_ACTIONS.RESET_CANVASES, payload: {}})
            dispatch({
                type: 'collaborators/clearCollaborators',
                payload: {}
            });

            canvas.clear();
            canvas.dispose();
            fitToScreenAssistance.destroy(true)
        }
        const overwriteBoardData = (data) => {
            dispatch({
                type: 'board/changeBoardDetails',
                payload: {
                    boardName: data.name,
                    whiteBoardId: data.id,
                    whiteBoardSlugId: data.wbId,
                }
            });

            if (Array.isArray(data.onlineUsers) && data.onlineUsers.length > 0) {
                dispatch({
                    type: 'collaborators/initCollaborators',
                    payload: {
                        collaborators: data.onlineUsers
                    }
                });
            }
        }
        if (socketStatusState === SOCKET_STATUS_MODS.DISCONNECTED) {
            closeRequestAccessToast();
            if (canvas && canvas.lowerCanvasEl) {
                clearCanvas();
            }
            if (!grayOverlay.current) {
                grayOverlay.current = document.createElement('div');
                grayOverlay.current.classList.add('grayOverlay');
            }
            setTimeout(() => {
                grayOverlay.current.classList.add('active');
            }, 0);
            if (!addedOverlay.current) {
                document.body.appendChild(grayOverlay.current);
                addedOverlay.current = true;
            }
            if (!toastMessage.current) {
                toastMessage.current = toast.info('Disconnected from the server', {
                    className: 'wb_toast',
                    position: 'bottom-center',
                    autoClose: false,
                    closeOnClick: false,
                    draggable: false,
                    closeButton: false,
                    icon: getToastIcon('error')
                });
            } else {
                toast.update(toastMessage.current, {
                    type: 'error',
                    render: 'Disconnected from the server',
                    autoClose: false,
                    className: 'wb_toast',
                    icon: getToastIcon('error'),
                })
            }
            disconnectedBefore.current = true;
        } else if (socketStatusState === SOCKET_STATUS_MODS.RECONNECT_ATTEMPT) {
            if (toastMessage.current) {
                dispatch({
                    type: 'modal/toggleRequestAccessModal',
                    payload: false
                })
                toast.update(toastMessage.current, {
                    type: 'error',
                    render: 'Disconnected from the server. Trying to reconnect.',
                    className: 'wb_toast',
                    autoClose: false,
                    icon: getToastIcon('error')
                });
            }
        } else if (socketStatusState === SOCKET_STATUS_MODS.CONNECTED) {
            if (grayOverlay.current) {
                grayOverlay.current.classList.remove('active');
                setTimeout(() => {
                    if (addedOverlay.current) {
                        document.body.removeChild(grayOverlay.current);
                        addedOverlay.current = false;
                    }
                }, 200);
            }
            if (toastMessage.current && disconnectedBefore.current) {
                toast.dismiss();
                toast.update(toastMessage.current, {
                    render: 'Connected to the server',
                    type: 'success',
                    className: 'wb_toast',
                    autoClose: 2000,
                    closeButton: true,
                    icon: getToastIcon('success')
                });
                toastMessage.current = null;
                disconnectedBefore.current = false;

                // force refetch active tab data
                const state = store.getState();
                if (state?.leftDrawer?.activeTab === LEFT_DRAWER_TABS.ACTIVITIES) {
                    dispatch({
                        type: 'socket/setShouldActivityLogRefresh',
                        payload: true
                    });
                } else if (state?.leftDrawer?.activeTab === LEFT_DRAWER_TABS.COMMENTS) {
                    dispatch({
                        type: 'socket/setShouldRefetchComments',
                        payload: true
                    });
                }

                // refetch users and comment icons
                dispatch({
                    type: 'socket/setShouldRefreshCommentsAndUsers',
                    payload: true
                });

                // refetch board data and shapes
                if(location.pathname.includes('teamBoard')){
                    const value = getUserQueryParams(location.search);

                    builderLoginAutomated(value).then(()=>{
                        let wbIdFrame = location.pathname.split('/')[1];
                        automatedBoard(wbIdFrame, activePageId).then((data) => {
                        // [#1460007] -> If the internet connection is lost again, data returned as an empty array.
                        // Therefore, below methods will be override with undefined values instead of correct data.
                        // So it shouldn't be overriden here
                            if (!data || !data?.id) { return; }

                            setWbId(data.wbId);
                            setWhiteBoardId(() => data.id);
                            setWhiteBoardSlugId(data.wbId);
                            setBoardName(data.name);
                            overwriteBoardData(data);

                            initializePagesRef.current(data);
                            eventEmitter.fire(EMITTER_TYPES.CHANGE_ACTION_MODE, 'select');
                        })
                    })
                } else if (location.pathname.includes('bmeetBoard')) {
                    const email = getUserQueryParams(location.search)?.email,
                        wbId = location.pathname.split('/')[1];

                    automatedBMeetBoard(wbId, email)
                        .then(data => {
                            if (data?.success) {
                                setWbId(data?.whiteboardData?.wbId);
                                setWhiteBoardId(() => data?.whiteboardData?.id);
                                setWhiteBoardSlugId(data?.whiteboardData?.wbId);
                                setBoardName(data?.whiteboardData?.name);
                                overwriteBoardData(data?.whiteboardData);
                                initializePagesRef.current(data?.whiteboardData);
                                eventEmitter.fire(EMITTER_TYPES.CHANGE_ACTION_MODE, 'select');
                            }
                            else {
                                setShowOverlay(true);
                                toast.error(data.message, {
                                    autoClose: false,
                                    draggable: false,
                                    hideProgressBar: true,
                                    position: 'bottom-right',
                                    icon: getToastIcon('error')
                                });
                            }
                        });
                } else if (location.pathname.includes('enterprise')) {
                    const value = getUserQueryParams(location.search);

                    enterpriseLoginAutomated(value).then(() => {
                        let wbIdFrame = location.pathname.split('/')[1];

                        automatedEnterpriseBoard(wbIdFrame, value?.permission).then(data => {
                            if (!data?.whiteboardData || !data?.whiteboardData?.id) { return; }
                            setWbId(data?.whiteboardData?.wbId);
                            setWhiteBoardId(() => data?.whiteboardData?.id);
                            setWhiteBoardSlugId(data?.whiteboardData?.wbId);
                            setBoardName(data?.whiteboardData?.name);
                            overwriteBoardData(data?.whiteboardData);
                            initializePagesRef.current(data?.whiteboardData);
                            eventEmitter.fire(EMITTER_TYPES.CHANGE_ACTION_MODE, 'select');
                        });
                    });
                } else {
                    let wbId = location.pathname.split('/')[2];
                    if (wbId) getWhiteBoardDetails(wbId, activePageId).then(data => {
                        // [#1460007] -> If the internet connection is lost again, data returned as an empty array.
                        // Therefore, below methods will be override with undefined values instead of correct data.
                        // So it shouldn't be overriden here

                        if (!data || !data?.id) { return; }

                        setWhiteBoardId(() => data.id);
                        setWhiteBoardSlugId(wbId);
                        setBoardName(data.name);
                        overwriteBoardData(data);

                        initializePagesRef.current(data, true);
                        eventEmitter.fire(EMITTER_TYPES.CHANGE_ACTION_MODE, 'select');
                    });
                    else navigate('/boards');
                }
            }
        }
    }, [socketStatusState, dispatch, navigate, location, setBoardName, setWhiteBoardId, setWhiteBoardSlugId, setWbId, canvas, activePageId]);
}


export default useSocketConnection;