import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import CommentInput from './CommentInput';


/**
 * Wrapper for CommentInput component.
 */
const CommentInputWrapper = forwardRef(({ handleAddComment, users, userAccessRef }, ref) => {
    return (
        <div 
            className="commentFormWrapper"
            data-for="input"
            ref={ref}
        >
            <CommentInput 
                emitCommentCreated
                handleAdd={handleAddComment}
                userAccess={userAccessRef.current}
                users={users} />
        </div>
    );
});

CommentInputWrapper.displayName = 'CommentInputWrapper';

CommentInputWrapper.propTypes = {
    handleAddComment: PropTypes.func.isRequired,
    users: PropTypes.arrayOf(PropTypes.shape({
        commentEmail: PropTypes.string,
        commentUsername: PropTypes.string,
        email: PropTypes.string,
        id: PropTypes.number,
        name: PropTypes.string,
        permission: PropTypes.oneOf(['view', 'comment', 'edit'])
    })).isRequired,
    userAccessRef: PropTypes.shape({
        current: PropTypes.oneOf(['view', 'comment', 'edit', 'removeAccess', 'NOT_ALLOWED'])
    }).isRequired
}

export default CommentInputWrapper;