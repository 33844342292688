import React from 'react';

const Tick = () => {
    return (
        <svg className='confirm-tick' width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.1665 7.00008L4.45817 11.7502C4.6153 11.9849 4.87615 12.1292 5.15848 12.1377C5.44081 12.1461 5.70981 12.0176 5.88067 11.7927L14.0832 1.16675" stroke="#AFB2C0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export default Tick;