import React, { useState } from 'react';
import clsx from 'clsx';
import { emailValidation } from '../../../helpers/Validation';
import { requestSendEmailToResetPassword } from '../../../services/AuthService';
import AuthBaseScreen from '../../authBaseScreen/AuthBaseScreen';
import '../Auth.scss';
import ButtonLoading from '../../inputs/button/ButtonLoading';
import { AUTH_VALIDATION_MESSAGE } from '../../../helpers/Constant';
import SentSuccesful from '../../../assets/images/done.png';
import { handleLabelPosition } from '../../../helpers/InputLabelHelper';
import BackToLogin from '../BackToLogin';
import Scrollbar from 'react-scrollbars-custom';
/**
 *
 */
const InputEmailToResetPassword = () => {
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(false);
    const [emailSendSuccess, setEmailSendSuccess] = useState(null);
    const handleChange = (evt) => {
        const { value: newValue } = evt.target;

        const error = emailValidation(newValue);

        if (error) {
            setErrors(error);
        } else {
            setErrors(null);
        }
        setEmail(newValue);
    };

    const handleSubmit = async (evt) => {
        evt.preventDefault();
        const newError = emailValidation(email);
        setErrors(newError);
        if (newError === null || newError === undefined) {
            setLoading(true);
            const response = await requestSendEmailToResetPassword(email);
            setLoading(false);
            if (response.status !== 'success') {
                setErrors(
                    response.message || AUTH_VALIDATION_MESSAGE.COULD_NOT_COMPLETE_ACTION
                );
            } else setEmailSendSuccess(true);
        }
    };

    const checkButtonDisableStatus = () => {
        return email === '' || errors
    }

    return (
        <AuthBaseScreen>
            {!emailSendSuccess ? (
                <Scrollbar trackYVisible={true} style={{position:''}}>
                    <form className="formElements email-to-reset-password" data-testid="input-email-to-reset-password">
                        <h3 className='forgot-password-title'>Forgot password?</h3>
                        <span
                            className='forgot-password-info'>No worries, we will send you reset details on the email</span>
                        <ul className='email-to-reset-password-list'>
                            <li>
                                <div>
                                    <label>Enter email</label>
                                    <input
                                        title=''
                                        autoComplete="email"
                                        className={clsx({'error': errors})}
                                        data-testid="input-email"
                                        id="email"
                                        maxLength="100"
                                        name="email"
                                        onChange={handleChange}
                                        onFocus={handleLabelPosition}
                                        onBlur={handleLabelPosition}
                                        type="email"
                                        value={email}
                                    />
                                </div>
                                {errors ? <div className={clsx('errorMsgBox', {'showError': errors})}>
                                    <div className="errorMsg">{errors}</div>
                                </div> : null}
                            </li>
                        </ul>
                        
                        <div className="footersection">
                            <div className="actionbtn">
                                <div className={clsx('sign-in-button-wrapper', {disabled: checkButtonDisableStatus()})}>
                                    <ButtonLoading
                                        disabled={email === '' || emailValidation(email) || errors}
                                        className={clsx('doneButton reset-email', {loading: loading})}
                                        isLoading={loading}
                                        loadingClassName="loading"
                                        onClick={handleSubmit}
                                        text="Send reset email"
                                        type="submit"
                                    />
                                </div>
                            </div>
                        </div>
                        
                        <BackToLogin/>
                    </form>
                </Scrollbar>
            ) : (
                <div className="emailSentSuccessfullyContainer">
                    <img className='sent-successful' src={SentSuccesful} alt="sent-successful"/>
                    <div className="textSendEmailSuccessfully" data-testid="send-email-successfully">
                        Reset password email sent
                    </div>
                    <span className='textSendEmailSuccessfully--info'>Please use the link from email to reset your password</span>
                    <BackToLogin/>
                </div>
            )}
        </AuthBaseScreen>
    );
}
export default InputEmailToResetPassword;
