import PropTypes from 'prop-types';

/**
 *
 */
const RectangleIcon = ({ width, height }) => {
    return (
        <svg
            fill="none"
            height={height}
            viewBox="0 0 20 20"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                clipRule="evenodd"
                d="M1.25 1.248h17.5v17.5H1.25v-17.5z"
                fillRule="evenodd"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </svg>
    );
}

RectangleIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number
}

RectangleIcon.defaultProps = {
    width: 20,
    height: 20
}

export default RectangleIcon;