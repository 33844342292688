import { fabric } from 'fabric';

/**
 * Calculates the positions of the attached element in the frame according to the frame.
 * @param {fabric.Object} frame 
 * @param {fabric.Object} obj 
 * @param {{x:number, y:number}=} overridedPos
 * @returns 
 */
export const calculateAttachedPos = (frame, obj, overridedPos) => {
    let wantedLeft = overridedPos?.x || obj.left;
    let wantedTop = overridedPos?.y || obj.top;

    if (obj.type === 'frame') {
        const object = obj.getActualPosition();
        wantedLeft = object.left;
        wantedTop = object.top;
    }
    return {
        x: frame.getActualPosition().left - wantedLeft,
        y: frame.getActualPosition().top - wantedTop,
    }
}

/**
 * Calculates the position of the attached element in the frame according to the canvas.
 * @param frame
 * @param attachedPos
 */
export const calculateObjPos = (frame, attachedPos) => {
    return {
        x: frame.left - attachedPos.x,
        y: frame.top - attachedPos.y,
    }
}

/**
 * Getting active object's position information or if exists active selection objects position information as calculated.
 * @param {fabric.Object} canvas 
 * @returns {{objPos: {x: number, y: number}, items: Record<string, {x: number, y:number}>}}
 */
export const getActiveObjectPosition = (canvas) => {
    const activeObject = canvas.getActiveObject();
    const activeObjPos = { x: activeObject.left, y: activeObject.top };
    const itemsPos = {};

    // If multiple objects are selected, then we need to calculate the objects' positions one by one.
    // Because object's position information is adjusted according to the active selection by fabric.
    // We need to calculate it for the canvas instead of active selection.
    if (activeObject.type === 'activeSelection') {
        const objects = activeObject.getObjects();
        const matrix = activeObject.calcTransformMatrix();
    
        objects.forEach((o) => {
            itemsPos[o.uuid] = fabric.util.transformPoint({ x: o.left, y: o.top }, matrix);
        });
    }

    return { objPos: activeObjPos, items: itemsPos }
}


/**
 * Returns the additional position for attaching.
 * This is required since we are not considering frame title while calculating the position of the frame.
 * @returns {{left: number, top: number}}
 */
export const getAdditionalPositionForAttaching = () => {
    return { left: 0, top: 0 };
}