import { useEffect } from 'react';
import { useNavigate,useLocation} from 'react-router-dom';
import { checkForAperios } from '../../services/DataService';
import { getUserQueryParams } from '../../helpers/CommonFunctions';

/**
 *
 */
function LandingComponent() {
    const navigate = useNavigate();
    const location = useLocation();

    (0, useEffect)(() => {
        const { email, name, params, pageId } = getUserQueryParams(location.search);

        let studio_build_card_id = location.pathname.split('/')[1];
        const url = params.get('url');
        checkForAperios(studio_build_card_id).then(data => {
            if (data && (data.aperios || data.aperios === null)) {
                goToAperios(studio_build_card_id, email, name, url, data.build_card_name, data.external_id, pageId);
            } else {
                goToNimaBoard(studio_build_card_id, email, name);
            }
        });
    }, []);

    const goToAperios = (studio_build_card_id,email,name,url, build_card_name, external_id, pageId) => {
        let apeirosURL = `/${studio_build_card_id}/teamBoard?email=${email}&name=${name}&url=${url}&build_card_name=${build_card_name}`
        if (external_id) {
            apeirosURL = apeirosURL + `&external_id=${external_id}`
        }

        if (pageId) {
            apeirosURL += `&pageId=${pageId}`;
        }

        navigate(apeirosURL);
    }

    const goToNimaBoard = (studio_build_card_id,email,name) => {
        navigate(`/${studio_build_card_id}/nimaBoard?email=${email}&name=${name}`);
    }
}

export default LandingComponent;