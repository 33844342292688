
import MyCanvas from '../../svgIcons/MyCanvas';
import ShareWithMe from '../../svgIcons/ShareWithMe';
import Trash from '../../svgIcons/Trash';

export default {
    // RECENT: {
    //   name: 'Recent',
    //   icon: Recent,
    //   isDisabled: false
    // },
    // PROJECTS: {
    //     name: 'Projects',
    //     icon: Projects,
    //     isDisabled: false
    // },
    ALL_CANVASES: {
        name: 'All canvases',
        title: 'All canvases',
        icon: MyCanvas,
        isDisabled: false
    },
    MY_CANVASES: {
        name: 'My canvases',
        title: 'My canvases',
        icon: MyCanvas,
        isDisabled: false
    },
    SHARED_WITH_ME: {
        name: 'Shared with me',
        title: 'Shared with me',
        icon: ShareWithMe,
        isDisabled: false
    },
    TRASH: {
        name: 'Trash',
        title: 'Deleted boards',
        icon: Trash,
        isDisabled: false
    }
    // FAVORITES: {
    //     name: 'Favorites',
    //     icon: Favorites,
    //     isDisabled: true
    // },
    // TEMPLATES: {
    //     name: 'Templates',
    //     icon: Templates,
    //     isDisabled: true
    // }
}