export const getSVGHead = ({width, height}) => {
    return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}" width="${width}" height="${height}" xmlns:xlink="http://www.w3.org/1999/xlink">`
}
  
export const getSVGDefsAndDesc = () => {
    return `<desc>
      Created with Apeiros - Builder AI
    </desc> 
    <defs>
    </defs>`
}

const replaceTransform = (body, { width, height }) => {
    const pattern = /<g transform=["'']matrix\(([\d|\s|.|\-|+]*)\)/;
    const match = body.match(pattern);
    if (match) {
        const transform = match[1];
        const [a, b, c, d] = transform.split(' ');
        const newTransform = `transform="matrix(${a} ${b} ${c} ${d} ${width / 2} ${height / 2})`;
        return body.replace(pattern, `<g ${newTransform}`);
    }
    return body;
}

  
export const transformAsSVG = (body, {width, height}) => {
    const head = getSVGHead({width, height});
    const defsAndDesc = getSVGDefsAndDesc();
    const transformedBody = replaceTransform(body, {width, height});
    return `${head}
    ${defsAndDesc}
    ${transformedBody}
    </svg>`
}