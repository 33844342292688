/**
 *
 */
const CopyIcon = () => {
    return (
        <svg
            fill="none"
            height="20"
            viewBox="0 0 22 22"
            width="20"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.875 16.625c-.69 0-1.25-.56-1.25-1.25v-12.5c0-.69.56-1.25 1.25-1.25h12.5c.69 0 1.25.56 1.25 1.25"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                clipRule="evenodd"
                d="M5.375 6.875a1.5 1.5 0 011.5-1.5h12a1.5 1.5 0 011.5 1.5v12a1.5 1.5 0 01-1.5 1.5h-12a1.5 1.5 0 01-1.5-1.5v-12z"
                fillRule="evenodd"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </svg>
    );
}

export default CopyIcon;