import React from 'react';
import PropTypes from 'prop-types';

function IconTextColor ({width, height, color}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox={`0 0 ${width} ${height}`}
        >
            <path
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.125"
                d="M2.002 5.377V4.012A1.55 1.55 0 013.555 2.46h10.89a1.55 1.55 0 011.552 1.552v1.365M9 15.54V3.083M6.045 15.54h5.91"
            ></path>
            <rect width="12" height="1" x="3" y="17" fill={color} rx="0.5"></rect>
        </svg>
    );
}

IconTextColor.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.string,
    
}

export default IconTextColor;
