import { fabric } from 'fabric';


/**
 * @param options
 * @param reviver
 */
export function toSVG(options, reviver) {
    options || (options = { });
    options.reviver = reviver;
    var markup = [];

    this._setSVGPreamble(markup, options);
    this._setSVGHeader(markup, options);
    if (this.clipPath) {
        markup.push('<g clip-path="url(#' + this.clipPath.clipPathId + ')" >\n');
    }
    this._setSVGBgOverlayImage(markup, 'backgroundImage', reviver);
    this._setSVGObjects(markup, reviver);
    if (this.clipPath) {
        markup.push('</g>\n');
    }
    this._setSVGBgOverlayColor(markup, 'overlay');
    this._setSVGBgOverlayImage(markup, 'overlayImage', reviver);

    markup.push('</svg>');

    return markup.join('');
}


/**
 * @param markup
 * @param options
 */
export function _setSVGHeader(markup, options) {
    var width = options.width || this.width,
        height = options.height || this.height,
        vpt, viewBox = 'viewBox="0 0 ' + this.width + ' ' + this.height + '" ',
        NUM_FRACTION_DIGITS = fabric.Object.NUM_FRACTION_DIGITS;

    if (options.viewBox) {
        viewBox = 'viewBox="' +
              options.viewBox.x + ' ' +
              options.viewBox.y + ' ' +
              options.viewBox.width + ' ' +
              options.viewBox.height + '" ';
    }
    else {
        if (this.svgViewportTransformation) {
            vpt = this.viewportTransform;
            viewBox = 'viewBox="' +
                fabric.util.toFixed(-vpt[4] / vpt[0], NUM_FRACTION_DIGITS) + ' ' +
                fabric.util.toFixed(-vpt[5] / vpt[3], NUM_FRACTION_DIGITS) + ' ' +
                fabric.util.toFixed(this.width / vpt[0], NUM_FRACTION_DIGITS) + ' ' +
                fabric.util.toFixed(this.height / vpt[3], NUM_FRACTION_DIGITS) + '" ';
        }
    }

    markup.push(
        '<svg ',
        'xmlns="http://www.w3.org/2000/svg" ',
        'xmlns:xlink="http://www.w3.org/1999/xlink" ',
        'version="1.1" ',
        'width="', width, '" ',
        'height="', height, '" ',
        viewBox,
        'xml:space="preserve">\n',
        '<desc>Created with Apeiros</desc>\n',
        '<defs>\n',
        this.createSVGFontFacesMarkup(),
        this.createSVGRefElementsMarkup(),
        this.createSVGClipPathMarkup(options),
        '</defs>\n'
    );
}


/**
 * ToSVG override methods for fabric.Object.
 * @param reviver
 */
export async function objectToSVG(reviver) {
    try {
        return await this._createBaseSVGMarkup(await this._toSVG(reviver), { reviver: reviver });
    } catch (err) {
        return '';
    }
}


/**
 * _toSVG override method for fabric.Group.
 * @param reviver
 */
export async function group_toSVG(reviver) {
    try {
        var svgString = ['<g ', 'COMMON_PARTS', ' >\n'];

        for (var i = 0, len = this._objects.length; i < len; i++) {
            svgString.push('\t\t', await this._objects[i].toSVG(reviver));
        }
        svgString.push('</g>\n');
        return svgString;
    } catch (err) {
        return '';
    }
}