import { useEffect, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { constShapes } from '../../../helpers/Constant';


/**
 * @param {object} props
 * @param props.onClickHandler
 * @param props.activeShapeType
 * @param props.tooltipRef
 */
const ShapeChangeMenu = ({
    onClickHandler,
    activeShapeType,
    tooltipRef
}) => {
    const menuRef = useRef();

    const SHAPES = constShapes.filter((item) => item.shapeType === 'primeShapes');

    useEffect(() => {
        const menuItem = menuRef.current;
        if (!menuItem) return;
        const clientRect = menuItem.getBoundingClientRect();
        if (Math.sign(clientRect.top) === -1) {
            menuItem.classList.add('onBottom');
            tooltipRef?.current && tooltipRef.current.classList.remove('onBottom');
        } else {
            menuItem.classList.remove('onBottom');
        }
    });

    const onItemClick = (event, shapeType) => {
        if (shapeType === activeShapeType) {
            event.stopPropagation(); // Dont close the menu if disabled item is clicked.
            return;
        }

        onClickHandler(shapeType);
    }

    return (
        <div className="changeShapeMenuListItems" ref={menuRef}>
            {SHAPES.map((shape) => (
                <div
                    className={clsx('menu-item', { disabled: activeShapeType === shape.shapeMode.replace('Mode', '') })}
                    key={shape.shapeMode}
                    onClick={(event) => onItemClick(event, shape.shapeMode.replace('Mode', ''))}
                >
                    {shape.shapeClass ? <em className={shape.shapeClass} /> : <shape.shapeSvg />}
                </div>
            ))}
        </div>
    )
}

ShapeChangeMenu.propTypes = {
    onClickHandler: PropTypes.func.isRequired,
    activeShapeType: PropTypes.string.isRequired,
    tooltipRef: PropTypes.shape({
        current: PropTypes.instanceOf(HTMLElement)
    })
}

export default ShapeChangeMenu;