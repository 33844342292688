// basically a queue of events that need to be processed

export default class EventQueue {
    constructor() {
        this.queue = [];
        this.isProcessing = false;
    }
  
    add(event) {
        this.queue.push(event);
        this.process();
    }
  
    async process() {
        if (this.isProcessing) return;
  
        if (this?.queue?.length > 0) {
            this.isProcessing = true;
            try {
                const event = this.queue.shift();
                await event.handler(event.data);
            } catch (error) {
                console.error('error while processing event', error);
            } finally {
                this.isProcessing = false;
            }
            await this.process();  // handle next event in queue
        }
    }
}