import React from 'react';

const ArrowLeft = () => {
    return (
        <svg
            width={18}
            height={16}
            viewBox="0 0 18 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.5 7.88013H1.5"
                stroke="#6200EA"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.5 0.880127L1.5 7.88013L8.5 14.8801"
                stroke="#6200EA"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default ArrowLeft;