import { useEffect, useState } from 'react';
import {getActiveWord, replaceAt} from '../helpers/mentions';

const useMention = (inputRef) => {
    const [value, setValue] = useState('');
    const [oldValue, setOldValue] = useState('');
    const [visibleValue, setVisibleValue] = useState('');
    const [queryFilter, setQueryFilter] = useState('');
    const [showMentions, setShowMentions] = useState(false);
    const [activeWord, setActiveWord] = useState({});
    const [newActiveWord, setNewActiveWord] = useState({});
    const [disabled, setDisabled] = useState(true);
    const [taggedUsers, setTaggedUsers] = useState([]);
    // const [mentionsMapping, setMentionsMapping] = useState({});

    const closeMentions = () => {
        setShowMentions(false);
    }
    
    const getNewIndex = () => {
        const [index] = newActiveWord.range;
        
        return index;
    }

    const handleSelect = (obj) => {
        setShowMentions(false);
    
        const [index] = activeWord.range;
        const replacement = `@${obj.commentUsername}`;
        const newReplacement = `@${obj.id}@`;
    
        const replacedText = replaceAt(
            visibleValue,
            replacement,
            index,
            activeWord.word.length
        )
    
        setVisibleValue(replacedText);
        if (inputRef.current) {
            setTimeout(() => {
                inputRef.current.selectionEnd = index + replacement.length;
                inputRef.current.focus();
                setVisibleValue(replacedText + ' ');
            }, 0);
        }
    
        const { name, id, commentUsername } = obj;
        setTaggedUsers([
            ...taggedUsers, { 
                name, 
                commentUsername,
                id,
                range: [index, index + replacement.length],
                newRange: [getNewIndex(), getNewIndex() + newReplacement.length],
                fullText: replacement,
                newFullText: newReplacement,
                index: activeWord.index
            }
        ]);
    }

    const handleChange = (e) => {
        let inputVal = e.target.value;
        
        const selectionStart = e.target.selectionStart;
        let {activeWord, newActiveWord} = getActiveWord(inputVal, taggedUsers, selectionStart);
        if (activeWord && activeWord.word.startsWith('@')) {
            let query = activeWord.word.substring(1);
            setQueryFilter(query);
            setActiveWord(activeWord);
            setNewActiveWord(newActiveWord);
            setShowMentions(true);
        } else {
            setQueryFilter('');
            setShowMentions(false);
        }
    
        setVisibleValue(inputVal);
        
        if (taggedUsers.length > 0) {
            let tempAddedLinks = [...taggedUsers];
            for (let i = 0; i < tempAddedLinks.length; i++ ) {
                const element = tempAddedLinks[i];
                if (inputVal.substring(element.range[0], element.range[1]) !== element.fullText) {
                    tempAddedLinks.splice(i);
                }
            }
            setTaggedUsers(tempAddedLinks);
        }
    }


    const getTaggedUserObject = () => [...taggedUsers];
    
    useEffect(() => {
        if (taggedUsers.length > 0) {
            let content = visibleValue.split(/[\s\n]/);
            let oldContent = visibleValue.split(/[\s\n]/);
            // let counter = 1;
            // let mentionsMappingObject = {};
            for (const element of taggedUsers) {
                //old
                let wordsCount = element.fullText.split(/\s/).length;
                oldContent.splice(element.index, 1, `@[${element.commentUsername}](${element.id})`);
                if (wordsCount > 1) {
                    oldContent.splice(element.index + 1, 1, null);
                }
                
                //mentions mapping
                // const pattern = new RegExp('@' + element.commentUsername.replaceAll('.', '\.'), 'gi');
                // content = content.replace(pattern, `@${content.includes(`@${counter}@`) ? counter + 1 : counter}@`);
                // mentionsMappingObject[counter] = element.id;
                // counter = counter + (content.includes(`@${counter + 1}@`) ? 2 : 1);
                
                //new way
                content.splice(element.index, 1, `@${element.id}@`);
                if (wordsCount > 1) {
                    content.splice(element.index + 1, 1, null);
                }
            }
            
            //old
            let newOldContent = oldContent.filter(item => item !== null);
            let newContent = content.filter(item => item !== null);
            setOldValue(newOldContent.join(' '));
            //new
            setValue(newContent.join(' '));
            // setMentionsMapping(mentionsMappingObject);
        } else {
            setValue(visibleValue);
            setOldValue(visibleValue);
        }
    }, [visibleValue, taggedUsers]);

    useEffect(() => {
        if (visibleValue.trim().length > 0) setDisabled(false);
        else setDisabled(true);
    }, [visibleValue])

    return {
        value,
        setValue,
        oldValue,
        setOldValue,
        visibleValue,
        setVisibleValue,
        queryFilter,
        showMentions,
        closeMentions,
        disabled,
        handleSelect,
        handleChange,
        getTaggedUserObject
        // mentionsMapping
    }
}


export default useMention;