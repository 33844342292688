import { useEffect, useState } from 'react'
import createLogFromEvent from '../helpers/activityLog/CreateLogFromEvent';

const useActivityLog = (users, whiteBoardId, setActivityLogs, currentUserId, dispatch) => {
    const [addedItem, setAddedItem] = useState({});
    const [modifiedItem, setModifiedItem] = useState({});
    const [deletedItem, setDeletedItem] = useState({});
    const [modifiedFrame, setModifiedFrame] = useState({});

    useEffect(() => {
        if (Object.entries(addedItem).length === 0) return;
        
        if (addedItem.hasOwnProperty('uuid')) {
            const eventName = addedItem.isRestored ? 'modified' : 'created';
            const logEvent = createLogFromEvent(eventName, addedItem, users, whiteBoardId, currentUserId);
            setActivityLogs(prevState => [logEvent, ...prevState ]);
            dispatch({
                type: 'activityHistory/modifyActivity',
                payload:logEvent
            });
        }
        setAddedItem({});
    }, [addedItem, users, whiteBoardId, setActivityLogs, currentUserId]);

    useEffect(() => {
        if (Object.entries(modifiedItem).length === 0) return;
        if (modifiedItem.hasOwnProperty('uuid')) {
            console.log(modifiedItem.modifiedBy);
            const logEvent = createLogFromEvent('modified', modifiedItem, users, whiteBoardId, currentUserId);
            setActivityLogs(prevState => [logEvent, ...prevState ]);
            dispatch({
                type: 'activityHistory/modifyActivity',
                payload:logEvent
            });
        }

        setModifiedItem({});
    }, [modifiedItem, users, whiteBoardId, setActivityLogs, currentUserId]);

    useEffect(() => {
        if (Object.entries(deletedItem).length === 0) return;

        if (deletedItem.hasOwnProperty('uuid')) {
            const logEvent = createLogFromEvent('deleted', deletedItem, users, whiteBoardId, currentUserId);
            setActivityLogs(prevState => [logEvent, ...prevState ]);
            dispatch({
                type: 'activityHistory/modifyActivity',
                payload:logEvent
            });
        }

        setDeletedItem({});
    }, [deletedItem, users, whiteBoardId, setActivityLogs, currentUserId]);

    useEffect(() => {
        if (Object.entries(modifiedFrame).length === 0) return;

        // add local updates of the shapes those attached or detached for the frame
        const { updatedAttachments, detachedObjects } = modifiedFrame;
        const updatedLogEvents = [];
        const detachedLogEvents = [];
        for (const obj of updatedAttachments) {
            const logEvent = createLogFromEvent('modified', obj, users, whiteBoardId, currentUserId);
            updatedLogEvents.unshift(logEvent);
        }
        for (const obj of detachedObjects) {
            const logEvent = createLogFromEvent('modified', obj, users, whiteBoardId, currentUserId);
            detachedLogEvents.unshift(logEvent);
        }
        setActivityLogs(prevState => [...detachedLogEvents, ...updatedLogEvents, ...prevState ]);
    }, [modifiedFrame, users, whiteBoardId, setActivityLogs, currentUserId]);

    return {
        setAddedItem: (item) => setAddedItem(item),
        setModifiedItem: (item) => setModifiedItem(item),
        setDeletedItem: (item) => setDeletedItem(item),
        setModifiedFrame: (item) => setModifiedFrame(item)
    }
}


export default useActivityLog;