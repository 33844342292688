import React from 'react';

const LockIcon = () => {
    return (
        <svg width="34" height="44" viewBox="0 0 34 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M1.875 19.375C1.875 18.5466 2.54657 17.875 3.375 17.875H30.625C31.4534 17.875 32.125 18.5466 32.125 19.375V41.125C32.125 41.9534 31.4534 42.625 30.625 42.625H3.375C2.54657 42.625 1.875 41.9534 1.875 41.125V19.375Z" stroke="#651FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.375 17.875V11C7.375 5.68426 11.6843 1.375 17 1.375C22.3157 1.375 26.625 5.68426 26.625 11V17.875" stroke="#651FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M17 34.375C19.2782 34.375 21.125 32.5282 21.125 30.25C21.125 27.9718 19.2782 26.125 17 26.125C14.7218 26.125 12.875 27.9718 12.875 30.25C12.875 32.5282 14.7218 34.375 17 34.375Z" stroke="#651FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export default LockIcon;