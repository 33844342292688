import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const historySlice = createSlice({
    name: 'history',
    initialState,
    reducers: {
        initHistory: (state, action) => {
            const pageId = action.payload.pageId
            if (!state[pageId]) {
                state[pageId] = {
                    shapes: [],
                    stackOrder: []
                }
            }

            state[pageId].shapes = action.payload.shapes;
        },
        addShapeToHistory: (state, action) => {
            const pageId = action.payload.pageId;

            if (!state[pageId]) {
                state[pageId] = {
                    shapes: [],
                    stackOrder: []
                }
            }

            state[pageId].shapes.push(action.payload.shape);
        },
        addShapesToHistory: (state, action) => {
            const pageId = action.payload.pageId;
            if (!state[pageId]) {
                state[pageId] = {
                    shapes: [],
                    stackOrder: []
                }
            }

            state[pageId].shapes.push(...action.payload.shapes);
        },
        initStackOrder: (state, action) => {
            const pageId = action.payload.pageId

            if (!state[pageId]) {
                state[pageId] = {
                    shapes: [],
                    stackOrder: []
                }
            }
            
            state[pageId].stackOrder = action.payload.stackOrder;
        }
    }
});

export default historySlice.reducer;