import React from 'react';
import { ClipLoader } from 'react-spinners';
import PropTypes from 'prop-types';

const ButtonLoading = ({
    className,
    onClick,
    isLoading,
    disabled,
    text,
    loadingClassName,
    type,
}) => {
    return (
        <button
            className={className}
            data-testid = "button-submit"
            disabled={(isLoading || disabled) ? true : false}
            onClick={onClick}
            // eslint-disable-next-line react/button-has-type
            type={type}
        >
            {isLoading ? <div className={loadingClassName}>
                <ClipLoader color="#fff" loading={isLoading} size={22} />
            </div> : null}

            {isLoading ? 'Loading...' : `${text}`}
        </button>
    );
};

ButtonLoading.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    loadingClassName: PropTypes.string,
    type: PropTypes.oneOf(['submit', 'reset', 'button']),
}

ButtonLoading.defaultProps = {
    type: 'button'
}

export default ButtonLoading;
