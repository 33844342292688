import PropTypes from 'prop-types';

/**
 *
 */
const StickyNoteIcon = ({ width, height }) => {
    return (
        <svg
            fill="none"
            height={height}
            viewBox="0 0 20 20"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                clipRule="evenodd"
                d="M11.303 19H2.2A1.2 1.2 0 011 17.8V2.2A1.2 1.2 0 012.2 1h15.6A1.2 1.2 0 0119 2.2v9.103a1.2 1.2 0 01-.351.848L12.15 18.65a1.2 1.2 0 01-.848.351z"
                fillRule="evenodd"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="M11.8 18.893V13a1.2 1.2 0 011.2-1.2h5.892M5.8 5.8h9.6M5.8 9.4H10"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </svg>
    );
}

StickyNoteIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number
}

StickyNoteIcon.defaultProps = {
    width: 20,
    height: 20
}

export default StickyNoteIcon;