import { fabric } from 'fabric';

const NOT_ALLOWED_OBJECT_TYPES = ['comment', 'frame'];

/*!Depreceated. sendToBack is used instead of this fn. I didn't remove it for future purposes.
Also, we may need to use sendBackwards fn again somewhere in the application.
Morever, fabricjs may use bringForward and sendBackwards functions in itself too.
So, this function shouldn't be removed.
*/
/**
 * @param object
 * @param idx
 * @param intersecting
 */
export function findNewLowerIndex(object, idx, intersecting) {
    let newIdx, i;

    if (intersecting) {
        newIdx = idx;

        // traverse down the stack looking for the nearest intersecting object
        for (i = idx - 1; i >= 0; --i) {

            let isIntersecting = object.intersectsWithObject(this._objects[i]) ||
                             object.isContainedWithinObject(this._objects[i]) ||
                             this._objects[i].isContainedWithinObject(object);

            if (isIntersecting) {
                newIdx = i;
                break;
            }
        }
    }
    else {
        newIdx = idx - 1;

        const thisObj = this._objects[idx];
        if (thisObj.type !== 'frame') {
            const prevObj = this._objects[newIdx];

            // if previous object is frame, then we shouldn't move the stack. Cuz frame is always on bottom of the stack
            if (prevObj.type && prevObj.type === 'frame') {
                newIdx++;
            }
        }
        return newIdx;
    }

    return newIdx;
}

/*!Depreceated. bringToFront is used instead of this fn. I didn't remove it for future purposes.
Also, we may need to use bringForward fn again somewhere in the application.
Morever, fabricjs may use bringForward and sendBackwards functions in itself too.
So, this function shouldn't be removed.
*/
/**
 * @param object
 * @param idx
 * @param intersecting
 */
export function findNewUpperIndex(object, idx, intersecting) {
    let newIdx, i, len;

    if (intersecting) {
        newIdx = idx;

        // traverse up the stack looking for the nearest intersecting object
        for (i = idx + 1, len = this._objects.length; i < len; ++i) {

            let isIntersecting = object.intersectsWithObject(this._objects[i]) ||
                             object.isContainedWithinObject(this._objects[i]) ||
                             this._objects[i].isContainedWithinObject(object);

            if (isIntersecting) {
                newIdx = i;
                break;
            }
        }
    }
    else {
        newIdx = idx + 1;

        // if next object is comment, then we shouldn't move the stack. Cuz comment is always on bottom of the stack
        const thisObj = this._objects[idx];
        const nextObj = this._objects[newIdx];
        if (thisObj.type !== 'frame') {
            const nextObj = this._objects[newIdx];
            if (nextObj.type && nextObj.type === 'comment') {
                newIdx--;
            }
        } else {
            if (nextObj.type !== 'frame')  {
                newIdx--;
            }
        }
    }

    return newIdx;
}

/**
 * This fn is used instead of the default sendToBack fn of fabricjs. 
 * Comments always should be in bottom of the stack, and frames should be in top.
 * Comments and frames stack order mustn't be changed.
 * @param {fabric.Object} object 
 * @returns
 */
export function sendToBack(object) {
    if (!object) { return this; }

    let activeSelection = this._activeObject,
        i, obj, objs, objIds

    if (object === activeSelection && object.type === 'activeSelection') {
        objIds = activeSelection._objects.filter((o) => !NOT_ALLOWED_OBJECT_TYPES.includes(o.type)).map((o) => o.uuid);
        objs = this._objects.filter((o) => objIds.includes(o.uuid));

        const items = [];

        for (i = 0; i < objs.length; i++) {
            obj = objs[i];

            if (!NOT_ALLOWED_OBJECT_TYPES.includes(obj.type)) {
                fabric.util.removeFromArray(this._objects, obj);
                items.push(obj);
            }
        }

        let firstAllowedObjIdx = this._objects.findLastIndex((o) => ['frame'].includes(o.type));
        if (firstAllowedObjIdx === -1) {
            firstAllowedObjIdx = 0;
        } else {
            firstAllowedObjIdx+=1;
        }

        this._objects.splice(firstAllowedObjIdx, 0, ...items);
    } else {
        if (['frame', 'mockFrame'].includes(object.type)) {
            fabric.util.removeFromArray(this._objects, object);
            this._objects.unshift(object);
        } else {
            // We need to find the first non-frame object index. Frames always should be in back.
            const firstAllowedObjIdx = this._objects.findIndex((o) => !NOT_ALLOWED_OBJECT_TYPES.includes(o.type));
            fabric.util.removeFromArray(this._objects, object);
            this._objects.splice(firstAllowedObjIdx, 0, object);
        }
    }

    this.renderOnAddRemove && this.requestRenderAll();

    return this;
}

/**
 * This fn is used instead of the default bringToFront fn of fabricjs. 
 * Comments always should be in bottom of the stack, and frames should be in top.
 * Comments and frames stack order mustn't be changed.
 * @param {fabric.Object} object 
 * @returns 
 */
export function bringToFront(object) {
    if (!object) { return this; }

    let activeSelection = this._activeObject,
        i, obj, objs, objIds;

    if (object === activeSelection && object.type === 'activeSelection') {
        objIds = activeSelection._objects.filter((o) => !NOT_ALLOWED_OBJECT_TYPES.includes(o.type)).map((o) => o.uuid);
        objs = this._objects.filter((o) => objIds.includes(o.uuid));
        const items = [];

        for (i = 0; i < objs.length; i++) {
            obj = objs[i];

            if (!NOT_ALLOWED_OBJECT_TYPES.includes(obj.type)) {
                fabric.util.removeFromArray(this._objects, obj);
                items.push(obj);
            }
        }

        let firstAllowedObjIdx = this._objects.findIndex((o) => ['comment'].includes(o.type));

        if (firstAllowedObjIdx === -1) {
            firstAllowedObjIdx = this._objects.length;
        }

        this._objects.splice(firstAllowedObjIdx, 0, ...items);
    } else {
    // We need to find the first non-comment object index. Comments always should be in front.
        const firstAllowedObjIdx = this._objects.findLastIndex((o) => !NOT_ALLOWED_OBJECT_TYPES.includes(o.type));
        fabric.util.removeFromArray(this._objects, object);
        this._objects.splice(firstAllowedObjIdx, 0, object);
    }
    this.renderOnAddRemove && this.requestRenderAll();
    return this;
}