import React from 'react';

const LayoutIcon = () => {
    return (
        <svg fill="none" height="16" viewBox="0 0 24 24" width="16" xmlns="http://www.w3.org/2000/svg">
            <path clipRule="evenodd" d="M1.5 2.99707C1.5 2.16864 2.17157 1.49707 3 1.49707H21C21.8284 1.49707 22.5 2.16864 22.5 2.99707V20.9971C22.5 21.8255 21.8284 22.4971 21 22.4971H3C2.17157 22.4971 1.5 21.8255 1.5 20.9971V2.99707Z" fillRule="evenodd" stroke="#3C3E49" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <path d="M16.5 1.49707V22.4971" stroke="#3C3E49" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
        </svg>
    )
};

export default LayoutIcon;
