import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
    useRef
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    emailCharactersValidation,
    emailWithSpecialCharsValidation
} from '../../helpers/Validation';
import { debounce } from '../../helpers/OptimizationUtils';
import AutocompleteSelectInput from '../inputs/autocomplete/AutocompleteSelectInput';
import { inviteUser, updateUserPermission, publicShareBoard } from '../../services/InviteService';
import getToastIcon from '../../helpers/media/GetToastIcon';
import { createInitialFromUsername, isUserHasAccessToFeature } from '../../helpers/CommonFunctions';
import { copyToClipboard } from '../../helpers/Clipboard';
import SelectInput from '../inputs/select/SelectInput';
import Avatar from '../../assets/images/avatar2.png';
import { BOARD_MESSAGES, USER_ROLES, AVAILABLE_PERMISSION_PER_USER, SHARED_PERMISSION_OPTIONS, SOCKET_EVENT, AUTH_VALIDATION_MESSAGE } from '../../helpers/Constant';
import { getUserRecommendations } from '../../services/UserService';
import {fitToScreenAssistance} from '../../helpers/FitToScreenAssistance';

import './Invite.scss';
import TooltipWrapper from '../tooltip/TooltipWrapper';

import CloseIcon from '../svgIcons/Close';
import Hyperlink3 from '../svgIcons/Hyperlink3';
import Spinner from '../svgIcons/Spinner';
import Message from '../svgIcons/Message';
import IconArrowBottom from '../svgIcons/arrows/ArrowBottom'
import { acceptRequestAccess, pendingRequestList } from '../../services/RequestAccessService';
import { ClipLoader } from 'react-spinners';
import eventEmitter from '../../helpers/EventEmitter';

const Invite = ({
    handleClose,
    setUserToRemove,
    isLoading,
    socketRef,
    onMemberListUpdated,
    deniedUserRef,
    activeUser
}) => {
    const ulRef = useRef(null);
    const ulAccessRef = useRef(null);
    const dispatch = useDispatch();

    const {
        publicSharePermission,
        users,
        whiteBoardOwnerId,
        whiteBoardSlugId,
        requestList
    } = useSelector((state) => state?.board);
    const { permission: userAccess, id } = useSelector((state) => state?.user);
    const studioBuildCardID = useSelector((state) => state.board?.studioBuildCardID);
    const [invitedUserPermission, setInvitedUserPermission] = useState(USER_ROLES.comment.id);

    const [emailValue, setEmailValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);

    const [sendingShareLink, setSendingShareLink] = useState(false);
    const [loadingSuggestions, setLoadingSuggestions] = useState(false);
    const [,setUpdatingPermissions] = useState(false);
    const [copyingLink, setCopyingLink] = useState(false);

    const [enableSendButton, setEnableSendButton] = useState(false);

    const isBuildCardLinkedBoard = !!studioBuildCardID;
    const displayPublicShareable = !isBuildCardLinkedBoard;
    const disableInvite = isBuildCardLinkedBoard;

    const isMe = useMemo(
        () => (userId) => activeUser?.info?.userId === userId,
        [activeUser]
    );

    /**
     * Ordering the users and memoized the result.
     */
    const orderedUsers = useMemo(() => {
        const clonedUsers = [...users].map((user) => {
            let isUserAlreadySigned = true;

            if (user.info.isUserSigned === false && user.name === 'FirstName') {
                isUserAlreadySigned = false;
            }

            return { ...user, isUserAlreadySigned };
        });

        const userIdx = users.findIndex((user) => user.info.userId === whiteBoardOwnerId);
        if (userIdx === -1) { return []; }

        const temp = clonedUsers[0];
        clonedUsers[0] = clonedUsers[userIdx];
        clonedUsers[userIdx] = temp;
        return clonedUsers;
    }, [users, whiteBoardOwnerId]);

    const scrollToLoading = () => {
        const list = document.querySelectorAll('.inviteModal__permissions--item.list--item');

        if (list.length) {
            list[list.length - 1].scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };


    /**
     * Inviting new user to the board.
     */
    const handleSendInvite = () => {
        if (!emailWithSpecialCharsValidation(emailValue)) {
            const message = emailValue.trim() === '' ?
                AUTH_VALIDATION_MESSAGE.EMAIL_REQUIRED :
                AUTH_VALIDATION_MESSAGE.ENTER_VALID_EMAIL;

            toast.error(message, {
                icon: getToastIcon('error'),
                className: 'wb_toast',
            });

            return;
        }

        const infoToast = toast.info('Sending invite...', {
            autoClose: false,
            icon: getToastIcon('info'),
            className: 'wb_toast',
        });

        setSendingShareLink(true);

        inviteUser(whiteBoardSlugId, emailValue, invitedUserPermission)
            .then((data) => {
                if (!data.error) {
                    setEmailValue('');

                    toast.update(infoToast, {
                        type: 'success',
                        render: data.message,
                        autoClose: 2500,
                        icon: getToastIcon('success'),
                    });

                    onMemberListUpdated({
                        email: data.email,
                        action: 'INVITE',
                        userId: data.id,
                        name: data.name,
                        commentEmail: data.commentEmail,
                        commentUsername: data.commentUsername,
                        userPermission: data.permission,
                        isUserSigned: data.isUserSigned
                    });
                } else {
                    toast.update(infoToast, {
                        type: 'error',
                        render: data.message,
                        autoClose: 2500,
                        icon: getToastIcon('error'),
                    });
                }
            })
            .finally(() => {
                setSendingShareLink(false);
                setEnableSendButton(false);
            });
    };

    /**
     * Copying the whiteboard link to clipboard.
     */
    const copyInvitationLink = async () => {
        setCopyingLink(true);

        const infoToast = toast.info('Copying link...', {
            autoClose: false,
            icon: getToastIcon('info'),
            className: 'wb_toast'
        });

        try {
            const data = await publicShareBoard(whiteBoardSlugId, publicSharePermission);

            if (!data.error) {
                const result = await copyToClipboard(
                    `${window.location.origin}/board/${data.link}`
                );

                if (result) {
                    toast.update(infoToast, {
                        type: 'success',
                        render: BOARD_MESSAGES.WB_LINK_COPIED,
                        autoClose: 2500,
                        icon: getToastIcon('success'),
                    });
                }
            } else {
                toast.update(infoToast, {
                    type: 'error',
                    render: data.message,
                    autoClose: 2500,
                    icon: getToastIcon('error'),
                });
            }
        } catch (err) {
            console.error('Error happened while copying to clipboard', err);
        } finally {
            setCopyingLink(false);
        }
    }

    /**
     * @param {number} userId 
     * @param {object} permission 
     * @returns 
     */
    const onRequestPermissionChange = (userId, permission) => {
        const editedList = requestList.map(item => {
            if (item.userId === userId) return { ...item, permission: permission.id }
            else return item
        })
        dispatch({
            type: 'board/changeBoardDetails',
            payload: {requestList: editedList}
        })
    }

    /**
     * @param {number} userId 
     * @param {'view'|'comment'|'edit'} permission 
     * @returns 
     */
    const onPermissionChange = useCallback((userId, permission) => {
        if (whiteBoardOwnerId === userId) {
            toast.error(BOARD_MESSAGES.USER_NOT_ALLOWED_TO_CHANGE_PERMISSION, {
                icon: getToastIcon('error'),
                className: 'wb_toast'
            });
            return;
        }

        setUpdatingPermissions(userId);

        updateUserPermission(whiteBoardSlugId, userId, permission)
            .then((response) => {
                if (response.permission) {
                    onMemberListUpdated({
                        userId,
                        action: 'UPDATE',
                        userPermission: response.permission
                    });
                }
            })
            .finally(() => setUpdatingPermissions(false));
    }, [onMemberListUpdated, whiteBoardOwnerId, whiteBoardSlugId]);

    /**
     * Checking is the user owner of the wb.
     * @param {number} userId 
     * @returns Boolean.
     */
    const isWhiteboardOwner = useCallback((userId) => {
        return userId === whiteBoardOwnerId
    }, [whiteBoardOwnerId]);

    const isActiveUserBoardOwner = useMemo(() => {
        return isWhiteboardOwner(activeUser?.info?.userId || activeUser?.id)
    }, [activeUser?.id, activeUser?.info?.userId, isWhiteboardOwner]);

    /**
     * Checking that is the user has access to change other users permission.
     * @returns Boolean.
     */
    const userHasAccessToEditPermissions = () => {
        if (!activeUser) return false;
        if (!isUserHasAccessToFeature('change_permission', userAccess)) return false;
        return true;
    };

    const selectedUsersEmail = useMemo(
        () => orderedUsers.map(({ email }) => email),
        [orderedUsers]
    );

    const autoCompleteOptions = useMemo(
        () =>
            suggestions
                .filter(({ email }) => !selectedUsersEmail.includes(email))
                .map(({ name, email }) => ({
                    value: email,
                    label: name,
                })),
        [suggestions, selectedUsersEmail]
    );

    const joinedUserOptions = useCallback(() => {
        let roles = Object.values(USER_ROLES).filter((item) => (
            item.id !== USER_ROLES.notAllowed.id &&
            (isActiveUserBoardOwner || (userAccess === USER_ROLES.edit.id) || item.id !== USER_ROLES.removeAccess.id)
        ));

        if (isBuildCardLinkedBoard) {
            roles = roles.filter((role) => role.id !== USER_ROLES.removeAccess.id);
        }

        return roles;
    }, [isActiveUserBoardOwner, userAccess, isBuildCardLinkedBoard]);

    const setShowDenyAccessOverlay = () => {
        setTimeout(() => {
            dispatch({
                type: 'modal/toggleDenyAccess',
                payload: {
                    shouldShow: true
                }
            })
        },100)
    }

    const handleShareVisibility = () => {
        return requestList.length === 0
    }

    /**
     * @param {('request'|'share')} type
     */
    const handleToggleAccordion = (type) => {
        
        const accordionList = document.querySelector('.inviteModal__permissions.requestWrapper');
        const generalShare = document.querySelector('.inviteModal__permissions.share');


        /**
         * @param {('open'|'close')} value
         */
        const handleToggleRequestList = (value) => {
            
            const arrowIcon = document.querySelector('.inviteModal--request-list-accordion svg');
            const requestCount = document.querySelector('.request-count');

            const handleCloseRequestList = () => {
                accordionList?.classList.remove('active');
                arrowIcon?.classList.remove('reverse');
                requestCount?.classList.add('active');
            }

            const handleOpenRequestList = () => {
                accordionList?.classList.add('active');
                arrowIcon?.classList.add('reverse');
                requestCount?.classList.remove('active');
            }

            if (value === 'open') handleOpenRequestList();
            else handleCloseRequestList();
        }

        /**
         * @param {('open'|'close')} value
         */
        const handleToggleGeneralShare = (value) => {
            const arrowIcon = document.querySelector('.toggle-general-access');


            const handleCloseGeneralShare = () => {
                generalShare?.classList.remove('active');
                arrowIcon?.classList.add('reverse');
            }

            const handleOpenGeneralShare = () => {
                generalShare?.classList.add('active');
                arrowIcon?.classList.remove('reverse');
            }

            if (value === 'open') handleOpenGeneralShare();
            else handleCloseGeneralShare();
        }

        if (type === 'request'){
            if (accordionList?.classList.contains('active')) handleToggleRequestList('close')
            else {
                if (generalShare?.classList.contains('active')) handleToggleGeneralShare('close')
                handleToggleRequestList('open');
            }
        }
        else {
            if (generalShare?.classList.contains('active')) handleToggleGeneralShare('close')
            else {
                if (accordionList?.classList.contains('active')) handleToggleRequestList('close')
                handleToggleGeneralShare('open');
            }
        }
        
    }

    /**
     * @param {object} user 
     * @param {boolean} value 
     */
    const acceptButtonLoadingState = (user, value) => {
        const editedList = requestList.map(item => {
            if (item.id === user.id) return { ...item, sendingRequest: value }
            return item
        })
        dispatch({
            type: 'board/changeBoardDetails',
            payload: {requestList: editedList}
        })
    }

    /**
     * @param user
     * @param status
     * @param event
     */
    const handleAcceptRejectAccess = async (user, status) => {
        if (status === 'accept') {
            acceptButtonLoadingState(user, true);
            let isError = false;
            try {
                const response = await acceptRequestAccess(whiteBoardSlugId, { requestId: user.id, permission: user.permission ?? 'comment' });
                if (response.success) {
                    acceptButtonLoadingState(user, false);
                    dispatch({
                        type: 'board/changeBoardDetails',
                        payload: {requestList: requestList.filter(item => item.id !== user.id)}
                    })
                    onMemberListUpdated({
                        email: emailValue,
                        action: 'INVITE',
                        userId: user.userId,
                        name: user.name,
                        commentEmail: user.email,
                        userPermission: user.permission ?? USER_ROLES.comment.id
                    });
                    
                }
            }
            catch (err) {
                isError = true;
                console.log(err)
                acceptButtonLoadingState(user, false);
            }
            finally {
                if (isError) {
                    acceptButtonLoadingState(user, false);
                }
            }
        }
        else if (status === 'reject') {
            deniedUserRef.current = user;
            setShowDenyAccessOverlay();
        }
    }

    const publicUserOptions = (name) =>
        AVAILABLE_PERMISSION_PER_USER[name].map((key) => USER_ROLES[key]);

    const addingUserLoader = () => {
        setTimeout(() => {
            scrollToLoading();
        }, [500]);

        return (
            <li
                className="inviteModal__permissions--item loading"
                data-testid="add-user-loading"
                key={`loading_${Math.random()}`}
            >
                <main className="inviteModal__permissions--user">
                    <aside>
                        <Spinner height={16} width={16} />
                    </aside>

                    <h3>Adding user</h3>
                </main>
            </li>
        );
    };

    const getUserRecommendationsCb = useCallback(debounce((_emailValue) => {
        if (!whiteBoardSlugId || !isUserHasAccessToFeature('users', userAccess)) { return false; }

        if (emailCharactersValidation(_emailValue) && _emailValue.length > 2) {
            setErrorMessage(null);
            setLoadingSuggestions(true);

            getUserRecommendations(whiteBoardSlugId, _emailValue)
                .then((data) => {
                    if (Array.isArray(data)) {
                        setSuggestions(data);
                    } else {
                        setErrorMessage(data?.error ?? BOARD_MESSAGES.INVALID_INPUT);
                        setEnableSendButton(false);
                    }
                })
                .finally(() => setLoadingSuggestions(false));

            if (
                _emailValue.length > 6 &&
                emailWithSpecialCharsValidation(_emailValue)
            ) {
                setEnableSendButton(true);
            }
        } else if (!emailCharactersValidation(_emailValue) && _emailValue?.length > 0) {
            setLoadingSuggestions(false);
            setErrorMessage(BOARD_MESSAGES.INVALID_INPUT);
        } else setErrorMessage(null);
    }, 500), []);

    const getPendingRequestList = async () => {
        try {
            const response = await pendingRequestList(whiteBoardSlugId);
            dispatch({
                type: 'board/changeBoardDetails',
                payload: {requestList: response.data}
            })
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        eventEmitter.on('updateRequestList', getPendingRequestList);
        return () => eventEmitter.off('updateRequestList', getPendingRequestList);
    },[])

    useEffect(() => getUserRecommendationsCb(emailValue), [whiteBoardSlugId, userAccess, emailValue]);

    useEffect(() => {
        try {
            fitToScreenAssistance.destroy(true)
        } catch (err) {
            console.error(err)
        }
    }, []);

    return (
        <section className={clsx('inviteModal', { loading: isLoading })}>
            {isLoading ? (
                <Spinner height={40} width={40} />
            ) : (
                <>
                    <header>
                        <h2>Manage access</h2>

                        <button type="button" data-testid="closeBtn" onClick={()=> handleClose()}>
                            <CloseIcon height={18} width={18} />
                        </button>
                    </header>
                    {
                        (requestList?.length > 0 && isWhiteboardOwner(id)) ? <div onClick={() => handleToggleAccordion('request')} className="inviteModal--request-list-accordion">
                            <span className='request-title'>
                                Access request{requestList.length > 1 ? 's' : ''}
                                <span className='request-count'>({requestList.length} pending request{requestList.length > 1 ? 's' : ''})</span>
                            </span>
                            <IconArrowBottom />
                        </div> : null
                    }
                    {(requestList.length > 0 && isWhiteboardOwner(id)) ? <>
                        <div className="inviteModal__permissions requestWrapper active">
                            <ul style={{ overflowY: requestList.length > 2 ? 'auto' : 'inherit'}} className="inviteModal__permissions request" ref={ulAccessRef}>
                                {requestList.map((user) => (
                                    <li
                                        className="inviteModal__permissions--item list--item"
                                        data-testid="invite-user-item"
                                        key={`${user.userId}_${Math.random()}`}
                                    >
                                        <main style={{ paddingRight: requestList.length > 2 ? '12px' : 0 }} className="inviteModal__permissions--request">
                                            <div className="inviteModal__permissions--request-left">
                                                <aside className={clsx({ letters: !user.avatar })}>
                                                    {user?.avatar ? (
                                                        <img alt="profile avatar" src={Avatar} />
                                                    ) : (
                                                        createInitialFromUsername(user.name)
                                                    )}
                                                </aside>
                                                <TooltipWrapper placement="bottom-start" text={user.email}>
                                                    <h3 className="inviteModal__tooltip requester-email">
                                                        {user.email}
                                                    </h3>
                                                </TooltipWrapper>
                                                {
                                                    (user.message && user.message !== '') ? <TooltipWrapper className='request-message-tooltip' triggerOffset={16} possiblePlacements={['top-start', 'bottom-start']} placement='top-start' text={user.message}>
                                                        <span className="inviteModal__permissions--request-message">
                                                            <Message />
                                                        </span>
                                                    </TooltipWrapper> : null
                                                }
                                            </div>
                                            <div className="inviteModal__permissions--request-right">
                                                <SelectInput
                                                    defaultValue={user.permission ?? 'comment'}
                                                    onChangeItem={(opt) => onRequestPermissionChange(user.userId, opt)}
                                                    options={joinedUserOptions().slice(0, -1)}
                                                    placement="bottom-end"
                                                />
                                                <button
                                                    disabled={user?.sendingRequest === true}
                                                    className="denyRequestBtn"
                                                    data-testid="inviteModalDenyRequestBtn"
                                                    onClick={() => handleAcceptRejectAccess(user, 'reject')}
                                                    type='submit'
                                                >
                                                    Deny
                                                </button>
                                                <button
                                                    disabled={user?.sendingRequest === true}
                                                    className="acceptRequestBtn"
                                                    data-testid="inviteModalAcceptRequestBtn"
                                                    onClick={() => handleAcceptRejectAccess(user, 'accept')}
                                                    type='submit'
                                                >
                                                    <ClipLoader color="#fff" loading={user.sendingRequest === true} size={22} /> {user.sendingRequest === true ? null : 'Accept'}
                                                </button>
                                            </div>
                                        </main>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <hr className='request-list-separator' />
                    </> : null
                    }
                    <main>
                        {!disableInvite ? (
                            <fieldset className="inviteModal__form">
                                <div>
                                    <AutocompleteSelectInput
                                        data={autoCompleteOptions}
                                        errorMessage={errorMessage}
                                        isLoading={loadingSuggestions}
                                        onInputChange={(value) => {
                                            setErrorMessage(null);
                                            setEnableSendButton(false);
                                            setEmailValue(value);
                                        }}
                                        onOptionClick={() => {
                                            setEnableSendButton(true);
                                        }}
                                        query={emailValue}
                                        required
                                        testId="inviteModalEmail"
                                        name="shareEmail"
                                        // type="email"
                                        placeHolder="Enter name or email"
                                    />

                                    <SelectInput
                                        defaultValue={invitedUserPermission}
                                        disabledOptions={[]}
                                        options={Object.values(USER_ROLES).slice(0, -2)}
                                        onChangeItem={({ id }) => {
                                            setInvitedUserPermission(id);
                                        }}
                                        // isLoading={sendingShareLink}
                                        placement="bottom-end"
                                    />
                                </div>
                                <button
                                    className="sendInviteBtn"
                                    data-testid="inviteModalSendInviteBtn"
                                    disabled={!enableSendButton || !!errorMessage}
                                    onClick={() => handleSendInvite()}
                                    type="submit"
                                >
                                    Send invite
                                </button>
                            </fieldset>
                        ) : null}

                        {userHasAccessToEditPermissions() ? <>
                            <hr />
                            <ul className="inviteModal__permissions" ref={ulRef}>
                                {sendingShareLink ? addingUserLoader() : null}
                                {orderedUsers.map((user) => (
                                    <li
                                        className={clsx(
                                            'inviteModal__permissions--item list--item',
                                            { 'non-registered-user': !user.isUserAlreadySigned }
                                        )}
                                        data-testid="invite-user-item"
                                        key={`${user.info.userId}_${Math.random()}`}
                                    >
                                        <main className="inviteModal__permissions--user">
                                            <aside className={clsx({ letters: !user.avatar })}>
                                                {user.isUserAlreadySigned ? (
                                                    user.avatar ? (
                                                        <img alt="profile avatar" src={Avatar} />
                                                    ) : (
                                                        createInitialFromUsername(user.name)
                                                    )
                                                ) : null}
                                            </aside>
                                            <TooltipWrapper placement="bottom-start" text={!user.isUserAlreadySigned ? BOARD_MESSAGES.USER_HAVENT_SIGNED_UP_YET : user.name}>
                                                <h3 className="inviteModal__tooltip">
                                                    {!user.isUserAlreadySigned ? user.email : user.name}
                                                    {/* {isMe(user.info.userId) ? <i>(Me)</i> : ""} */}
                                                </h3>
                                            </TooltipWrapper>
                                            {/* <span className="inviteModal__permissions--role designer">Designer</span> TODO: Will open after feature implemented */}
                                        </main>

                                        {isWhiteboardOwner(user.info.userId) ? (
                                            <span className="inviteModal__permissions--role owner">
                                                Owner
                                            </span>
                                        ) : (
                                            <SelectInput
                                                defaultValue={user.info.permission}
                                                disabled={isMe(user.info.userId)}
                                                disabledOptions={[]}
                                                onChangeItem={(opt) => {
                                                    if (
                                                        !isWhiteboardOwner(user.info.userId) &&
                                                        activeUser?.info?.userId !== user.info.userId &&
                                                        opt.id === 'removeAccess'
                                                    ) {
                                                        setUserToRemove({
                                                            name: user.name,
                                                            id: user.info.userId,
                                                        });

                                                        return handleClose();
                                                    }

                                                    return onPermissionChange(user.info.userId, opt.id);
                                                }}
                                                // isLoading={updatingPermission === user.info.userId}
                                                options={joinedUserOptions()}
                                                placement="bottom-end"
                                            />
                                        )}
                                    </li>
                                ))}
                            </ul>

                            {displayPublicShareable ? (
                                <>
                                    <hr />
                                    <hgroup className="inviteModal__form general-share">
                                        <h3>General Access</h3>
                                        <div>
                                            <button
                                                type="button"
                                                className="copyLinkBtn"
                                                data-testid="copyLinkBtn"
                                                disabled={copyingLink}
                                                onClick={copyInvitationLink}
                                            >
                                                {copyingLink ? (
                                                    <Spinner height={16} width={16} />
                                                ) : (
                                                    <Hyperlink3 height={16} width={16} />
                                                )}
                                                Copy link
                                            </button>
                                            <div className={clsx('toggle-general-access' , {reverse: !handleShareVisibility()})} onClick={() => handleToggleAccordion('share')}>
                                                <IconArrowBottom />
                                            </div>
                                            
                                        </div>
                                    </hgroup>
                                    <ul className={clsx('inviteModal__permissions share', { active: handleShareVisibility() })}>
                                        {SHARED_PERMISSION_OPTIONS.map((user) => (
                                            <li
                                                className="inviteModal__permissions--item"
                                                data-testid="public-user-item"
                                                key={`shared-user-${user.name}`}
                                            >
                                                <main className="inviteModal__permissions--user">
                                                    <aside>{user.icon}</aside>
                                                    {user.display}
                                                </main>

                                                <SelectInput
                                                    defaultValue={publicSharePermission[user.name]}
                                                    disabled={!isActiveUserBoardOwner}
                                                    key={Math.random()}
                                                    onChangeItem={(opt) => {
                                                        dispatch({
                                                            type: 'board/changeBoardDetails',
                                                            payload: {
                                                                publicSharePermission: {
                                                                    ...publicSharePermission,
                                                                    [user.name]: opt.id
                                                                },
                                                            }
                                                        })

                                                        socketRef.current.emit(
                                                            SOCKET_EVENT.UPDATE_PUBLIC_USER_PERMISSION,
                                                            { [user.name]: opt.id }
                                                        );
                                                    }}
                                                    options={publicUserOptions(user.name)}
                                                    placement="bottom-end"
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            ) : null}
                        </> : null}
                    </main>
                </>
            )}
        </section>
    );
}

Invite.propTypes = {
    handleClose: PropTypes.func,
    setUserToRemove: PropTypes.func,
    isLoading: PropTypes.bool,
    socketRef: PropTypes.shape({
        current: PropTypes.object
    }),
    deniedUserRef: PropTypes.shape({
        current: PropTypes.object
    }),
    onMemberListUpdated: PropTypes.func,
    activeUser: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        email: PropTypes.string,
        info: PropTypes.shape({
            userId: PropTypes.number,
            colorCode: PropTypes.string,
            status: PropTypes.string,
            backgroundColor: PropTypes.string,
            dotType: PropTypes.string,
            permission: PropTypes.oneOf(['view', 'comment', 'edit'])
        })
    }),
}

export default Invite;