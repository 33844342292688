import React from 'react';
import PropTypes from 'prop-types';

const ChevronDown = ({ width, height }) => (
    <svg
        fill="none"
        height={height}
        viewBox="0 0 24 13"
        width={width}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M23.25 1.31104L12.53 12.03C12.3895 12.1707 12.1988 12.2498 12 12.2498C11.8012 12.2498 11.6105 12.1707 11.47 12.03L0.75 1.31104"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
        />
    </svg>
);

ChevronDown.propTypes = {
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

ChevronDown.defaultProps = {
    width: 24,
    height: 24
}

export default ChevronDown;
