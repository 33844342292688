import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types'

const AddLineTextItem = ({
    isDisabled,
    onClick
}) => {
    return (
        <div className={clsx('lineAddText button', { disabled: isDisabled })} onClick={onClick}>
            <svg className='button__svg-icon' viewBox="0 0 32 32">
                <g xmlns="http://www.w3.org/2000/svg">
                    <g id="text-copy-21" transform="translate(4.000000, 4.000000)">
                        <path d="M8,5 L8,5 C8,5.55228475 8.44771525,6 9,6 L14,6 L14,18 C14,18.5522847 14.4477153,19 15,19 L15,19 C15.5522847,19 16,18.5522847 16,18 L16,6 L21,6 C21.5522847,6 22,5.55228475 22,5 L22,5 C22,4.44771525 21.5522847,4 21,4 L9,4 C8.44771525,4 8,4.44771525 8,5 Z" id="text" />
                        <path d="M4,12 L4,10 C4,9.44771525 4.44771525,9 5,9 C5.55228475,9 6,9.44771525 6,10 L6,12 L8,12 C8.55228475,12 9,12.4477153 9,13 C9,13.5522847 8.55228475,14 8,14 L6,14 L6,16 C6,16.5522847 5.55228475,17 5,17 C4.44771525,17 4,16.5522847 4,16 L4,14 L2,14 C1.44771525,14 1,13.5522847 1,13 C1,12.4477153 1.44771525,12 2,12 L4,12 Z" id="Combined-Shape" />
                    </g>
                </g>
            </svg>

            <span className="tooltipText">Add Text</span>
        </div>
    )
};

AddLineTextItem.propTypes = {
    isDisabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
}

export default AddLineTextItem;