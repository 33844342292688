import { useState, useId } from 'react';
import PropTypes from 'prop-types';

import './CheckboxInput.scss';


/**
 * Checkbox input for changing state between two states. 
 * It is looklike a regular HTML checkbox but it has some styles.
 * @param {() => void} onChange - Callback function for when the checkbox input is toggled.
 */
const CheckboxInput = ({
    label,
    onChange,
    disabled,
    defaultChecked
}) => {
    const [checked, setChecked] = useState(defaultChecked);
    const id = useId();
    return (
        <div className='checkbox-input'>
            <input 
                checked={checked}
                className="checkbox-input__input"
                disabled={disabled}
                id={id}
                onChange={(e) => {
                    setChecked(e.target.checked);
                    onChange(e.target.checked);
                }}
                type="checkbox" />
            <label htmlFor={id}>{label}</label>
        </div>
    )
}

CheckboxInput.propTypes = {
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    defaultChecked: PropTypes.bool
}

CheckboxInput.defaultProps = {
    disabled: false,
    defaultChecked: false
}

export default CheckboxInput;