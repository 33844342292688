import PropTypes from 'prop-types';

/**
 * @param {object} props
 * @param props.width
 * @param props.height
 * @param props.color
 */

const ConnectorIcon = ({
    width,
    height,
    color
}) => {
    return (
        <svg
            fill="none"
            height={height}
            viewBox="0 0 20 20"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.6 5.8L19 3.4 16.6 1"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="M1 19.001h4.8a3.021 3.021 0 003-3.13V6.48a3.2 3.2 0 013.26-3.08H19"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </svg>
    );
}

ConnectorIcon.propTypes = {
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    color: PropTypes.string
}

ConnectorIcon.defaultProps = {
    width: 20,
    height: 20,
    color: '#3C3E49'
}

export default ConnectorIcon;