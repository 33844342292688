/**
 * @param strDate
 */
export function formatTime(strDate) {
    const formatDate = new Date(strDate);
    const time = formatDate.toLocaleTimeString(navigator.language || 'en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    });
    const date = formatDate.toLocaleDateString(navigator.language || 'en-US', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
    });
    return [time, date];
}

/**
 * @param strDate
 */
export function formatDateTime(strDate) {
    let date;
    let month;
    let day;
    let year;
    let hour;
    let minute;
    let formatToParts = new Intl.DateTimeFormat(navigator.language || 'en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h24',
        hour12: false
    }).formatToParts(new Date(strDate))
    let formatToPartsObj = formatToParts.reduce((ac, a) => ({ ...ac, [a.type]: a.value }), {});

    month = formatToPartsObj['month'];
    day = formatToPartsObj['day'];
    year = formatToPartsObj['year'];
    hour = formatToPartsObj['hour'];
    minute = formatToPartsObj['minute'];
    
    date = month + ' ' + day + ', ' + year + ', ' + hour + ':' + minute;
    return date;
}
