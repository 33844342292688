import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CopyIcon from '../../svgIcons/canvasRedesign/IconCopy';

const DuplicateItem = ({
    isDisabled,
    cloneShape
}) => {

    return (
        <div className={clsx('button', { disabled: isDisabled })} onClick={cloneShape}>
            <CopyIcon />
            <span className="tooltipText">Duplicate</span>
        </div>
    )
}

DuplicateItem.propTypes = {
    isDisabled: PropTypes.bool,
    cloneShape: PropTypes.func.isRequired
}

export default DuplicateItem;