
import axios from 'axios';
import { apiEndpoints } from '../helpers/Constant';
import { environment } from '../environment';


/**
 * @param wbId
 * @param page
 * @param wbPageId
 */
export async function getActivityLogs(wbId, page = 1, wbPageId) {
    try {
        let url = `${environment.REACT_APP_API_ENDPOINT}${apiEndpoints.getActivityLogs.replace('{wbId}', wbId)}`;
        if (wbPageId) {
            url += `?wbPageId=${wbPageId}`
        }

        const response = await axios.get(url, {
            params: { page, limit: 1000 }
        });

        return response;
    } catch (error) {
        return [];
    }
}