import { FLOWCHART_ITEM_TYPE } from '../Constant';

const getFlowchartItemType = (object) => {
    if (object.type === 'frame') return FLOWCHART_ITEM_TYPE.SUB_FRAME;
    if (object.type === 'curvedLine') return FLOWCHART_ITEM_TYPE.HOTSPOT;
    return FLOWCHART_ITEM_TYPE.FEATURE;
}

/**
 * Makes the given object as flowchart shape. Adds flowchart props to the object depends on objects type.
 * @param canvas
 * @param {fabric.Object} object - The object that will turn into a flowchart shape.
 */
export function makeCustomFlowchartFeature(canvas, object) {
    if (!object) return;

    const attachedFrame = canvas.getObjects().find(item => item.uuid === object.attachedFrameId);
    if (attachedFrame && attachedFrame.flowchartProps) {
        if (object.constantFlowchartProps) {
            object.flowchartProps = {
                ...object.constantFlowchartProps,
            }
        } else if (object.type === 'optimizedImage') {
            object.flowchartProps = {
                ...object.flowchartProps,
                heroImageURL: object?.imageData?.full?.url,
                heroImageAttributes: {
                    width: (object.width / object.scaleX).toString(),
                    height: (object.height / object.scaleY).toString(),
                }
            }
        }
        object.flowchartProps = {
            ...object.flowchartProps,
            flowchartItemType: getFlowchartItemType(object),
            flowchartName: attachedFrame.flowchartProps.flowchartName,
            isUserCreated: object.constantFlowchartProps ? false : true,
        }
        
        if (object.type === 'frame') {
            const attachedItems = canvas.getObjects().filter(item => item.attachedFrameId === object.uuid);
            for (const attachedItem of attachedItems) {
                if (attachedItem.constantFlowchartProps) {
                    attachedItem.flowchartProps = {
                        ...attachedItem.constantFlowchartProps,
                    }
                } else {
                    attachedItem.flowchartProps = {
                        ...attachedItem.flowchartProps,
                        isUserCreated: true
                    }
                }
                attachedItem.flowchartProps = {
                    ...attachedItem.flowchartProps,
                    flowchartItemType: getFlowchartItemType(attachedItem),
                    flowchartName: attachedFrame.flowchartProps.flowchartName,
                }

                if (attachedItem.type === 'optimizedImage' && !attachedItem.constantFlowchartProps) {
                    attachedItem.flowchartProps = {
                        ...attachedItem.flowchartProps,
                        heroImageURL: attachedItem?.imageData?.full?.url,
                        heroImageAttributes: {
                            width: (attachedItem.width / attachedItem.scaleX).toString(),
                            height: (attachedItem.height / attachedItem.scaleY).toString(),
                        }
                    }
                }
            }
        }
    }
}


/**
 * @param {fabric.Object} line - User created hotspot.
 * @param {'left'|'right'} side 
 */
export function makeCustomFlowchartHotspot(line, side) {
    if (!line || (side !== 'left' && side !== 'right')) return;

    const lineFlowchartProps = {
        ...line.flowchartProps,
        flowchartItemType: FLOWCHART_ITEM_TYPE.HOTSPOT,
        isUserCreated: line.constantFlowchartProps ? false : true,
    }

    const polygon = line[`${side}Polygon`];
    if (polygon && polygon.flowchartProps) {
        if (polygon.flowchartProps.isUserCreated) {
            lineFlowchartProps[`${side}AttachedUserCreatedFlowchart`] = true;
        }
        lineFlowchartProps[`${side}flowchartName`] = polygon.flowchartProps.flowchartName;

        if (side === 'right' && !polygon?.flowchartProps?.isUserCreated) {

            if (polygon?.flowchartProps?.flowchartItemType === FLOWCHART_ITEM_TYPE.FEATURE) {
                lineFlowchartProps.targetFeatureUniqCode = polygon.flowchartProps.flowchartUniqCode;
            } else if (polygon?.flowchartProps?.flowchartItemType === FLOWCHART_ITEM_TYPE.SUB_FEATURE) {
                lineFlowchartProps.targetFeatureUniqCode = polygon.flowchartProps.subFeatureMainFeatureUniqCode;
                lineFlowchartProps.isConnectedToSubFeature = true;
            }
        }
    }


    line.flowchartProps = lineFlowchartProps;
}


/**
 * Removes flowchart props from object. 
 * If the object is a frame, removes flowchart props from all attached items.
 * @param {fabric.Object} object 
 */
export function removeFlowchartProps(object) {
    try {
        delete object.flowchartProps;
        if (object.type === 'frame' && object.canvas) {
            const attachedItems = object.canvas.getObjects().filter(item => item.attachedFrameId === object.uuid);
            for (const attachedItem of attachedItems) {
                delete attachedItem.flowchartProps;
            }
        }
    } catch (err) {
        console.log('error while deleting flowchart props', err);
    }
}


/**
 * Checks if the given object is a flowchart main frame.
 * @param {} obj 
 */
export function isObjectFlowchartFrame(obj) {
    return (obj?.flowchartProps?.flowchartItemType === FLOWCHART_ITEM_TYPE.FRAME);
}