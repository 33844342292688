
import axios from 'axios';
import { apiEndpoints } from '../helpers/Constant';
import { environment } from '../environment';

/**
 * @param buildCardID
 */
export async function getFlowchartData(buildCardID) {
    try {
        const response = await axios.post(
            environment.REACT_APP_API_ENDPOINT + apiEndpoints.importUserFlow.replace(':buildCardID', buildCardID),
        );
        return response;
    } catch (error) {
        return [];
    }
}