import React from 'react';

/**
 *
 */
const HyperlinkSVG = () => {
    return (
        <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.36403 18.5003L8.43203 19.4323C6.67466 21.1897 3.8254 21.1897 2.06803 19.4323C0.310657 17.6749 0.310657 14.8257 2.06803 13.0683L6.84103 8.29429C8.50064 6.63092 11.1607 6.52444 12.9479 8.04983C14.7352 9.57523 15.048 12.2189 13.666 14.1193" stroke="#3C3E49" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            <path d="M14.8181 5.56705L15.5681 4.81705C17.3255 3.05968 20.1747 3.05968 21.9321 4.81705C23.6895 6.57442 23.6895 9.42368 21.9321 11.1811L17.1591 15.9541C15.4991 17.6167 12.8395 17.7225 11.0527 16.197C9.26584 14.6716 8.9533 12.0282 10.3351 10.1281" stroke="#3C3E49" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        </svg>
    );
}

export default HyperlinkSVG;