import { fabric } from 'fabric';
import {EMITTER_TYPES, LINE_ATTACHABLE_OBJECT_TYPES, PAGE_SOCKET_EVENTS, SHAPE_DEFAULTS} from '../Constant';
import {NIMA_COMMENT_COLORS, NIMA_COMMENT_TAG_REGEX, RECTANGULAR_SHAPES, SHAPES, FONT_SIZE_CONSTRAINTS} from './Constants';
import {mentionPattern} from '../mentions';
import {customContainPointsWidthPadding} from '../lines/LineMethods';
import {attachToFrame} from '../frame/FrameMethods';
import eventEmitter from '../EventEmitter';
import {getShapeTextareaDimensions} from '../shapes/Common';

/**
 * Returns a path string from an array of points
 * @param {object[]} points
 * @returns
 */
export function getPathStringFromPoints(points) {
    let t = points[0], n = points[1];
    let pathString = `M ${t.x} ${t.y}`;

    const midPointBtw = (e, t) => {
        return {
            x: e.x + (t.x - e.x) / 2,
            y: e.y + (t.y - e.y) / 2
        }
    }

    for (var o = 1; o < points.length; o++) {
        var a = midPointBtw(t, n);
        pathString += ` Q ${t.x} ${t.y} ${a.x} ${a.y}`;
        t = points[o];
        n = points[o + 1];
    }
    return pathString;
}

/**
 * Returns a group of shape and text from given drawInstance and data
 * @param {fabric.Object} drawInstance
 * @param {object} data object command data
 * @returns
 */
export function createShapeGroup(drawInstance, data) {
    if (data.cmd === 'stickyNote') {
        let usableFontSize = data.fontSize || SHAPE_DEFAULTS.FONT_SIZE;
        if (usableFontSize >= FONT_SIZE_CONSTRAINTS.MAX) {
            usableFontSize = FONT_SIZE_CONSTRAINTS.MAX;
        } else if (usableFontSize <= FONT_SIZE_CONSTRAINTS.MIN) {
            usableFontSize = FONT_SIZE_CONSTRAINTS.MIN;
        }

        const text = new fabric.Textbox(data.text || '', {
            originX: 'center',
            originY: 'center',
            textAlign: 'center',
            fontSize: usableFontSize,
            fill: SHAPE_DEFAULTS.TEXT_COLOR,
            // splitByGrapheme: true,
            breakWords: true,
            fixedWidth: drawInstance.width - 24,
            width: drawInstance.width - 24,
            hasBorders: false,
            selectable: false,
            fontFamily: SHAPE_DEFAULTS.FONT_FAMILY,
            underline: false,
            fontStyle: 'normal',
            fontWeight: 'normal',
            lockScalingFlip : true,
            flipX :false,
            flipY:false
        })

        const stickyHeight = text.height + 24 > drawInstance.height ? text.height + 24 : drawInstance.height;

        const rect = new fabric.Rect({
            width: drawInstance.width,
            height: stickyHeight,
            fill: drawInstance.fill,
            rx: 10,
            ry: 10,
            originX: 'center',
            originY: 'center',
            selectable: false,
            shadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
            lockScalingFlip : true,
            flipX :false,
            flipY:false
        });

        return new fabric.Group([rect, text], {
            shapeType: 'sticky',
            left: drawInstance.left + drawInstance.width / 2,
            top: drawInstance.top + drawInstance.height / 2,
            originX: 'center',
            originY: 'center',
            lockScalingFlip : true,
            flipX :false,
            flipY:false
        });
    }

    const textareaDimensions = getShapeTextareaDimensions(drawInstance);
    let top = drawInstance.top;
    const left = drawInstance.left;

    if (drawInstance.type === 'triangle') {
        top = textareaDimensions.top;
    } else {
        top += textareaDimensions.top;
    }

    const text = new fabric.Textbox('', {
        originX: 'center',
        originY: 'center',
        textAlign: 'center',
        fill: SHAPE_DEFAULTS.TEXT_COLOR,
        fontSize: 1,
        // splitByGrapheme: true,
        breakWords: true,
        fixedWidth: drawInstance.width,
        width: textareaDimensions.width,
        height: textareaDimensions.height,
        top: top,
        left: left + textareaDimensions.left,
        hasBorders: false,
        selectable: false,
        fontFamily: SHAPE_DEFAULTS.FONT_FAMILY,
        fontWeight: 'normal',
        underline: false,
        fontStyle: 'normal',
        lockScalingFlip: true,
        flipX: false,
        flipY: false
    });

    return new fabric.Group([drawInstance, text], {
        shapeType: drawInstance.type,
        originX: 'center',
        originY: 'center',
        lockScalingFlip: true,
        flipX: false,
        flipY: false,
        objectCaching: false
        // minScaleLimit: 0.6,
    });
}

// TODO: REFACTOR TEXT FUNCTIONS
const GetTextWidth = (e) => {
    let t = e.split('\n');
    return {
        text: t.sort(((e,t)=>e.length - t.length))[t.length - 1],
        length: t.length
    }
}

const _getTextMeasure = (text) => {
    const e = GetTextWidth(text.value);
    return {
        size: measure(e.text, text.font, text.size + 'pt'),
        length: e.length
    }
}

function measure(text, fontFamily, fontSize) {
    const spanElement = document.createElement('span');
    spanElement.style.display = 'inline-block';
    spanElement.style.padding = 0;
    spanElement.style.margin = 0;
    spanElement.style.fontFamily = fontFamily;
    spanElement.style.fontSize = fontSize;
    spanElement.style.lineHeight = '100%';
    spanElement.style.whiteSpace = 'pre';
    spanElement.innerHTML = text;
    document.body.appendChild(spanElement);
    const widthHeight = {
        w: spanElement.offsetWidth,
        h: spanElement.offsetHeight
    };
    document.body.removeChild(spanElement);
    return widthHeight
}

/**
 * Calculate bounding box of an object
 * @param shape
 * @returns {{w: *, x: number, h: *, y: number}}
 */
export function getBoundingBoxOfObject(shape) {
    const getRectangularBoundingBox = (shape, thickness = 0) => {
        return {
            x: shape.x - thickness,
            y: shape.y - thickness,
            w: shape.w + thickness * 2,
            h: shape.h + thickness * 2
        }
    }
    let boundingBox = null;
    switch (shape.cmd) {
        case SHAPES.CIRCLE: {
            let thicknessCircle = shape.lineWidth / 2;
            boundingBox = {
                x: shape.x - shape.r - thicknessCircle,
                y: shape.y - shape.r - thicknessCircle,
                w: 2 * shape.r + shape.lineWidth,
                h: 2 * shape.r + shape.lineWidth
            }
            break;
        }
        case SHAPES.TEXT: {
            const n = _getTextMeasure(shape);
            boundingBox = {
                x: shape.x,
                y: shape.y,
                w: n.size.w,
                h: n.size.h * n.length
            };
            break;
        }
        case SHAPES.PEN: {
            const thicknessPen = shape.lineWidth / 2;

            const mapXPoints = shape.points.map(point => point.x);
            const mapYPoints = shape.points.map(point => point.y);
            let minX = Math.min(...mapXPoints);
            let minY = Math.min(...mapYPoints);
            let maxX = Math.max(...mapXPoints);
            let maxY = Math.max(...mapYPoints);

            boundingBox = {
                x: minX - thicknessPen,
                y: minY - thicknessPen,
                w: maxX - minX + shape.lineWidth,
                h: maxY - minY + shape.lineWidth
            }
            break;
        }
        case SHAPES.LINE: {
            let lineThickness = shape.lineWidth / 2,
                lineLeft = Math.min(shape.points[0].x, shape.points[1].x),
                lineTop = Math.min(shape.points[0].y, shape.points[1].y),
                lineRight = Math.max(shape.points[0].x, shape.points[1].x),
                lineBottom = Math.max(shape.points[0].y, shape.points[1].y);
            boundingBox = {
                x: lineLeft - lineThickness,
                y: lineTop - lineThickness,
                w: lineRight - lineLeft + shape.lineWidth,
                h: lineBottom - lineTop + shape.lineWidth
            }
            break;
        }
        case SHAPES.RECTANGLE:
        case SHAPES.FRAME:
        case SHAPES.STICKY_NOTE:
        case SHAPES.IMAGE: {
            // calculate bounding box for rectangular shapes
            let thickness = 0;
            if (shape.cmd === SHAPES.RECTANGLE) {
                thickness = shape.lineWidth / 2;
            } else if (shape.cmd === SHAPES.FRAME) {
                thickness = 2;
            }

            boundingBox = getRectangularBoundingBox(shape, thickness)
            break;
        }
        case SHAPES.COMPOSITE: {
            // if composite shape has no shapes, return 0
            if (shape?.shapes?.length < 1) {
                boundingBox = {
                    x: 0,
                    y: 0,
                    w: 0,
                    h: 0
                };
                break;
            }

            // calculate bounding box for composite shapes
            let compositeMinX = Number.MAX_SAFE_INTEGER,
                compositeMinY = Number.MAX_SAFE_INTEGER,
                compositeMaxX = Number.MIN_SAFE_INTEGER,
                compositeMaxY = Number.MIN_SAFE_INTEGER;
            // iterate over shapes and find min and max values for x and y
            for (const compositeShape of shape.shapes) {
                const compositeShapeBoundingBox = getBoundingBoxOfObject(compositeShape);
                compositeMinX = Math.min(compositeShapeBoundingBox.x, compositeMinX);
                compositeMinY = Math.min(compositeShapeBoundingBox.y, compositeMinY);
                compositeMaxX = Math.max(compositeShapeBoundingBox.x + compositeShapeBoundingBox.w, compositeMaxX);
                compositeMaxY = Math.max(compositeShapeBoundingBox.y + compositeShapeBoundingBox.h, compositeMaxY);
            }
            boundingBox = {
                x: compositeMinX,
                y: compositeMinY,
                w: compositeMaxX - compositeMinX,
                h: compositeMaxY - compositeMinY
            }
            break;
        }
        case SHAPES.COMMENT:
            boundingBox = {
                x: shape.x,
                y: shape.y,
                // for comments, width and heights are fixed
                w: 32,
                h: 32
            }
            break;
        default:
            boundingBox = {
                x: shape.x,
                y: shape.y,
                w: shape.w,
                h: shape.h
            }
            break;
    }
    return boundingBox;
}

/**
 * Returns thickness of shape
 * @param shape
 * @returns {number}
 */
export function getThickness(shape) {
    let thickness = 0;
    if (shape.cmd === SHAPES.RECTANGLE || shape.cmd === SHAPES.CIRCLE) {
        thickness = shape.lineWidth / 2;
    } else if (shape.cmd === SHAPES.FRAME) {
        thickness = 2;
    }
    return thickness;
}

/**
 * Updates object position
 * I know this looks so bad. But this is how they handle it in there...
 * @param e - x
 * @param t - y
 */
export function updatePosition(e, t) {
    if (this.cmd === SHAPES.CIRCLE) {
        let thickness = getThickness(this);
        this.x = e + this.r + thickness;
        this.y = t + this.r + thickness;
    } else if (this.cmd === SHAPES.PEN) {
        const n = getBoundingBoxOfObject(this);
        for (const o of this.points) {
            o.x = o.x - (n.x - e);
            o.y = o.y - (n.y - t);
        }
    } else if (this.cmd === SHAPES.LINE) {
        const n = getBoundingBoxOfObject(this);
        this.points[0].x = this.points[0].x - (n.x - e);
        this.points[0].y = this.points[0].y - (n.y - t);
        this.points[1].x = this.points[1].x - (n.x - e);
        this.points[1].y = this.points[1].y - (n.y - t);
    } else if (this.cmd === SHAPES.TEXT) {
        this.x = e;
        this.y = t;
    } else if (this.cmd === SHAPES.COMMENT || RECTANGULAR_SHAPES.includes(this.cmd)) { // comment, rectangle, frame, sticky note, image
        let thickness = getThickness(this);
        this.x = e + thickness;
        this.y = t + thickness;
    } else if (this.cmd === SHAPES.COMPOSITE) {
        const n = getBoundingBoxOfObject(this),
            o = n.x - e,
            a = n.y - t;
        for (const compositeShape of this.shapes) {
            const i = getBoundingBoxOfObject(compositeShape);
            updatePosition.bind(compositeShape)(i.x - o, i.y - a);
        }
    }
}

/**
 * Updates object size
 * I know this looks so bad. But this is how they handle it in there...
 * @param e - x
 * @param t - y
 * @param n - width
 * @param o - height
 */
export function updateObjectSize(e, t, n, o) {
    if (this.cmd === SHAPES.CIRCLE) {
        this.r = (Math.min(n, o) - this.lineWidth) / 2;
        updatePosition.bind(this)(e, t);
    } else if (this.cmd === SHAPES.RECTANGLE) {
        updatePosition.bind(this)(e, t);
        this.w = n - this.lineWidth;
        this.h = o - this.lineWidth;
    } else if (this.cmd === SHAPES.STICKY_NOTE) {
        updatePosition.bind(this)(e, t);
        this.w = n;
        this.h = o;
    } else if (this.cmd === SHAPES.PEN) {
        const a = getBoundingBoxOfObject(this),
            r = n / a.w,
            i = o / a.h;
        for (const s of this.points) {
            s.x = s.x * r;
            s.y = s.y * i;
        }
        updatePosition.bind(this)(e, t)
    } else if (this.cmd === SHAPES.LINE) {
        const a = getThickness(this);
        if (this.points[0].x < this.points[1].x) {
            this.points[0].x = e + a;
            this.points[1].x = e + n - a;
        } else if (this.points[0].x > this.points[1].x) {
            this.points[0].x = e + n + a;
            this.points[1].x = e - a;
        }

        if (this.points[0].y < this.points[1].y) {
            this.points[0].y = t + a;
            this.points[1].y = t + o - a;
        } else if (this.points[0].y > this.points[1].y) {
            this.points[0].y = t + o + a;
            this.points[1].y = t - a;
        }
    } else if (this.cmd === SHAPES.FRAME) {
        updatePosition.bind(this)(e, t);
        this.w = n - 4;
        this.h = o - 4;
    } else if (this.cmd === SHAPES.IMAGE) {
        let a, r;
        if (this.w > this.h) {
            a = n * this.h / this.w;
            r = n;
        } else {
            r = o * this.w / this.h;
            a = o;
        }
        this.w = r;
        this.h = a;
        updatePosition.bind(this)(e, t)
    } else if (this.cmd === SHAPES.TEXT) {
        let a = n / getBoundingBoxOfObject(this).w;
        this.size = this.size * a;
        updatePosition.bind(this)(e, t)
    } else if (this.cmd === SHAPES.COMPOSITE) {
        const a = getBoundingBoxOfObject(this),
            r = a.x - e,
            i = a.y - t,
            s = n / a.w,
            l = o / a.h;
        for (const shape of this.shapes) {
            const d = getBoundingBoxOfObject(shape);
            updateObjectSize.bind(shape)(d.x - r, d.y - i, d.w * s, d.h * l);
            const p = d.x - e - r,
                h = d.y - t - i;
            updatePosition.bind(shape)(e + p * s, t + h * l)
        }
    }
}

/**
 * Gets comment color from Nima comment color
 * @param colorCode
 */
export function getCommentColorFromNimaComment(colorCode) {
    const stringColorCode = colorCode.toString();
    if (Object.keys(NIMA_COMMENT_COLORS).includes(stringColorCode)) {
        return NIMA_COMMENT_COLORS[stringColorCode];
    }

    return NIMA_COMMENT_COLORS['1'];
}

/**
 * filters the commands that are not active
 * @param commands
 * @returns {array} commands
 */
export function filterCommands(commands) {
    const commandState = {};
    const undoActions = {};

    commands.forEach(cmd => {
        if (cmd.cmd !== 'undo') {
            // Regular command, set as active
            commandState[cmd.id] = true;
        } else {
            // Undo command
            // Check if it's a redo action (undoing a previous undo)
            const isRedo = undoActions[cmd.ref] && !commandState[cmd.ref];
            const targetId = isRedo ? undoActions[cmd.ref] : cmd.ref;

            // Toggle the state of the target command
            if (commandState[targetId] !== undefined) {
                commandState[targetId] = !commandState[targetId];
            }

            // Record the undo action
            undoActions[cmd.id] = targetId;
        }
    });

    return commands.filter(cmd => cmd.cmd !== 'undo' && commandState[cmd.id]);
}


/**
 * Maps comment message
 * @param {string} message
 * @param {object[]} addedUsersWithGuid
 */
export function mapCommentMessage(message, addedUsersWithGuid) {
    const mappedMessage = message.replace(NIMA_COMMENT_TAG_REGEX, (match, p1, p2) => {
        const user = addedUsersWithGuid[p2];
        if (!user) {
            return p1;
        }
        return `@[${user.commentUsername}](${user.id})`;
    })

    const taggedUsers = [];
    if (mappedMessage.match(mentionPattern)) {
        // keep track of the length of the message before the pattern is replaced
        let beforePatternLength = 0;
        for (const match of mappedMessage.matchAll(mentionPattern)) {
            const offset = match.index;
            const range = [offset - beforePatternLength, offset - beforePatternLength + match[1].length];
            const fullText = `@${match[1]}`;
            const beforeText = mappedMessage.substring(0, offset);
            const wordIndex = beforeText.split(/[\s\n]/).length - 1;

            beforePatternLength += match[0].length - fullText.length

            // add the tagged user to the list
            taggedUsers.push({
                id: match[2],
                commentUsername: match[1],
                range,
                fullText,
                index: wordIndex
            })
        }

    }

    return {message: mappedMessage, taggedUsers};
}

/**
 * Calculates the bounding box for a list of shapes
 * @param shapes
 * @returns {{w: number, x: number, h: number, y: number}}
 */
export function calculateBoundingBox(shapes) {
    let left = Infinity, top = Infinity, right = -Infinity, bottom = -Infinity;

    shapes.forEach(shape => {
        const boundingBox = getBoundingBoxOfObject(shape);
        left = Math.min(left, boundingBox.x);
        top = Math.min(top, boundingBox.y);
        right = Math.max(right, boundingBox.x + boundingBox.w);
        bottom = Math.max(bottom, boundingBox.y + boundingBox.h);
    });

    const width = right - left;
    const height = bottom - top;

    return { x: left, y: top, w: width , h: height };
}

/**
 * Removes images from list
 * @param canvas
 * @param list list of shapes -- this array will be modified
 * @param removeList
 */
export function removeImagesFromList(canvas, list, removeList) {
    removeList.forEach(shape => {
        shape.avoidEmittingOnRemove = true;  // make sure we are not logging this on activity log
        canvas.remove(shape)
        const index = list.findIndex(item => item.uuid === shape.uuid);
        if (index !== -1) {
            list.splice(index, 1);
        }
    });
    canvas.requestRenderAll()
}


/**
 * Attaches third party lines to polygons
 * @param canvasObjects list of canvas objects
 * @param lineShapes list of line shapes -- this list should be from third party whiteboard
 */
export function attachThirdPartyLines(canvasObjects, lineShapes) {
    try {
        for (const line of lineShapes) {
            const lineObject = canvasObjects.find(obj => obj.originalId === line.id)
            if (!lineObject) {
                console.log('line object is not found', line.id)
                continue;
            }

            const lineHeadPoints = lineObject.getHeadPoints();

            const lineX1 = lineHeadPoints[0].x;
            const lineY1 = lineHeadPoints[0].y;
            const lineX2 = lineHeadPoints[1].x;
            const lineY2 = lineHeadPoints[1].y;

            let leftPolygon = null;
            let rightPolygon = null;

            for (let i = canvasObjects.length - 1; i >=0; i--) {
                const obj = canvasObjects[i];
                if (obj === lineObject) {
                    continue;
                }
                if (!obj.uuid || !LINE_ATTACHABLE_OBJECT_TYPES.includes(obj.type)) {
                    continue;
                }
                if (!leftPolygon && customContainPointsWidthPadding(obj, { x: lineX1, y: lineY1 }, 5)) {
                    leftPolygon = obj;
                }
                if (!rightPolygon && customContainPointsWidthPadding(obj, { x: lineX2, y: lineY2 }, 5)) {
                    rightPolygon = obj;
                }

                if (leftPolygon && rightPolygon) {
                    break;
                }
            }

            // if both left and right polygons are not found, continue
            if (!leftPolygon && !rightPolygon) {
                continue;
            }

            // if left and right polygons are same, continue
            if (leftPolygon === rightPolygon) {
                continue;
            }

            // if polygons are found, attach the line to the polygon
            if (leftPolygon) {
                const centerPoint = leftPolygon.getCenterPoint();
                lineObject.leftDeltaX = lineX1 - centerPoint.x;
                lineObject.leftDeltaY = lineY1 - centerPoint.y;


                lineObject.leftPolygon = leftPolygon;
                if (!leftPolygon.lines) {
                    leftPolygon.lines = [];
                }
                leftPolygon.lines.push(lineObject.uuid);
            }

            if (rightPolygon) {
                const centerPoint = rightPolygon.getCenterPoint();
                lineObject.rightDeltaX = lineX2 - centerPoint.x;
                lineObject.rightDeltaY = lineY2 - centerPoint.y;


                lineObject.rightPolygon = rightPolygon;
                if (!rightPolygon.lines) {
                    rightPolygon.lines = [];
                }
                rightPolygon.lines.push(lineObject.uuid);
            }


        }
    } catch (err) {
        console.error('error in attachThirdPartyLines', err)
    }
}

/**
 * Attaches third party shapes to frames
 * @param canvasObjects list of canvas objects
 * @param frameShapes list of frame shapes -- this list should be from third party whiteboard
 */
export function attachThirdPartyShapesToFrames(canvasObjects, frameShapes) {
    for (const frame of frameShapes) {
        const frameObject = canvasObjects.find(obj => obj.originalId === frame.id)
        if (!frameObject) {
            console.log('frame object is not found', frame.id)
            continue;
        }
        for (const child of frame.children) {
            const childObject = canvasObjects.find(obj => obj.originalId === child)
            if (!childObject) {
                console.log('child object is not found', child)
                continue;
            }
            attachToFrame(childObject, frameObject, {allowAttachingToLockedFrame: true})
            if (!frame.attachments) {
                frame.attachments = []
            }
            frame.attachments.push(childObject.uuid)
        }
    }
}

/**
 * Creates a page for nima board data
 * @param socketRef
 * @returns
 */
export function createNimaBoardPage(socketRef) {
    return new Promise((resolve, reject) => {
        if (!socketRef || !socketRef?.current) {
            reject('socket connection is missing')
        }
        socketRef.current.emit(
            PAGE_SOCKET_EVENTS.PAGE_CREATED,
            {
                pageName: 'Old whiteboard data',
                forNima: true
            },
            (response) => {
                if (!response || response?.status !== 'ok' || !response?.emitData?.wbPageId) {
                    reject('response is missing for creating page')
                }

                resolve(response.emitData)
            }
        )
    })
}


/**
 * Returns the canvas instance of the old whiteboard data page
 * @returns {Promise<unknown>}
 */
export function getOldWhiteboardCanvasInstance() {
    return new Promise((resolve) => {
        const nimaCanvasInitializedListener = (canvas) => {
            eventEmitter.off(EMITTER_TYPES.NIMA_CANVAS_INITIALIZED, nimaCanvasInitializedListener)
            resolve(canvas)
        }
        eventEmitter.on(EMITTER_TYPES.NIMA_CANVAS_INITIALIZED, nimaCanvasInitializedListener)
    })
}