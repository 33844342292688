import { fabric } from 'fabric';
import { SHAPE_DEFAULTS, TEXTBOX_LINE_HEIGHT, TEXT_SIZE_OPTIONS } from '../Constant';
import { getTextHeight, wrapText } from '../TextWrapHelpers';
import { arrangeTextInsideShape, calculateIntersectionOfTwoLines, cloneFabricObjectHelper, createFabricInstance } from '../FabricMethods';
import { throttle } from '../OptimizationUtils';

/**
 * @param {*} object 
 * @param {*} text 
 * @param {*} width 
 * @param {*} _fontSize 
 * @returns {{ lines: Array, newWrappedText: string }}
 */
export function wrapShapeText(object, text, width, _fontSize) {
    if (!object || object.type !== 'group') {
        return { lines: [], newWrappedText: '' }
    }

    const textbox = object.editingTextObj ?? object.getObjects()[1];
    const fontSize = _fontSize ? _fontSize : textbox.fontSize;

    let newLines = [];
    const splittedTexts = text.split('\n');
    let deletedWhiteSpacesCount = [];

    textbox.isWrapping = true;
    for (let i = 0; i < splittedTexts.length; i++) {
        let { lines, removedWhiteSpacesByLineIndex } = wrapText(splittedTexts[i], fontSize, width, i, splittedTexts.length, textbox);
        if (!lines) lines = [];
        deletedWhiteSpacesCount = removedWhiteSpacesByLineIndex;
        newLines = newLines.concat(lines);
    }
    textbox.isWrapping = false;

    const newWrappedText = newLines.join('\n');
    return { lines: newLines, newWrappedText, deletedWhiteSpacesCount }
}

/**
 * @param {{ text: string, fontSize: number, fontFamily: string, width: number, height: number, scaleY: number, originalText: string, operation?: 'add' | 'remove', object: fabric.Object }} param0 
 * @returns {{ fontSize: number, fontHeight: number, firstFontHeight: number, prevFontHeight: number }}
 */
export function findFitFontSize({
    text,
    fontSize,
    fontFamily,
    width,
    height,
    originalText,
    operation = 'add',
    object
}) {
    let actualFontSize = fontSize;
    let fontHeight = getTextHeight(text, `${actualFontSize}px / ${TEXTBOX_LINE_HEIGHT} ${fontFamily}`, width);
    let prevFontHeight = 0;
    let firstFontHeight = fontHeight;

    // No need to check lower 10px anymore. We set minimum for 10px.
    if (operation === 'add') {
        while (fontHeight > height && fontSize >= 10) {
            prevFontHeight = fontHeight;
            fontSize--;
            actualFontSize = fontSize;
            const { newWrappedText } = wrapShapeText(object, originalText, width, fontSize);
            fontHeight = getTextHeight(newWrappedText, `${actualFontSize}px / ${TEXTBOX_LINE_HEIGHT} ${fontFamily}`, width);
        }
    } else if (operation === 'remove') {
        while (fontHeight < height && fontSize < SHAPE_DEFAULTS.FONT_SIZE) {
            prevFontHeight = fontHeight;
            fontSize++;
            actualFontSize = fontSize;
            const { newWrappedText } = wrapShapeText(object, originalText, width, fontSize);
            fontHeight = getTextHeight(newWrappedText, `${actualFontSize}px / ${TEXTBOX_LINE_HEIGHT} ${fontFamily}`, width);

            // If fontHeight is overflow the height; we need to decrease 1 because we have increased it in advance.
            if (fontHeight > height) fontSize--;
        }
    }

    return { fontSize, fontHeight, prevFontHeight, firstFontHeight };
}

/**
 * @param {fabric.Object} object 
 * @param {string} text 
 * @param {number} width 
 * @param {number} height 
 * @returns {{ maxFontSize: number, fontHeight: number }}
 */
export function _findMaxFontSize(object, text, width, height) {
    if (!text || text?.trim()?.length === 0) {
        return { maxFontSize: TEXT_SIZE_OPTIONS[TEXT_SIZE_OPTIONS.length - 1] };
    }

    const fontFamily = object.type === 'group' ? object.getObjects()[1]?.fontFamily : 'Rubik, sans-serif';

    let lastFontSize = TEXT_SIZE_OPTIONS[0];
    let lastFontHeight = getTextHeight(text, `${lastFontSize}px / ${TEXTBOX_LINE_HEIGHT} ${fontFamily}`, width);

    for (let i = 1; i < TEXT_SIZE_OPTIONS.length; i++) {
        const fontSize = TEXT_SIZE_OPTIONS[i];

        let actualFontSize = fontSize;
        const { newWrappedText } = wrapShapeText(object, text, width, fontSize);
        let fontHeight = getTextHeight(newWrappedText, `${actualFontSize}px / ${TEXTBOX_LINE_HEIGHT} ${fontFamily}`, width);

        if (fontHeight <= height) {
            lastFontSize = fontSize;
            lastFontHeight = fontHeight;
        }

        // No need to check after we found max chars. So exit from loop.
        if (fontHeight >= height) {
            break;
        }
    }

    return { maxFontSize: lastFontSize, fontHeight: lastFontHeight };
}

export const findMaxFontSize = throttle(_findMaxFontSize, 300);

/**
 * If the shape doesn't have a minimum width or height to place text in it
 * then set the minimum width and height .
 * @param {{ group: fabric.Object, shape: fabric.Object, minWidth: number, minHeight: number, canvas: fabric.Canvas }} param0 
 */
export function setMinWidthAndHeightToShape({
    group,
    shape,
    minWidth,
    minHeight,
    canvas
}) {
    let isSet = false;

    switch (group.shapeType) {
        case 'rect':
            if (group.width < minWidth * 2) {
                const width = minWidth * 2;
                group.set({ width });
                shape.set({ width });

                isSet = true;
            }
      
            if (group.height < minHeight) {
                group.set({ height: minHeight });
                shape.set({ height: minHeight });
                isSet = true;
            }
            break;
        case 'ellipse':
            if (group.width < minWidth * 1.25) {
                group.set({ width: (minWidth * 1.25) });
                shape.set({ rx: (minWidth * 1.25) / 2 })

                isSet = true;
            }

            if (group.height < minHeight) {
                group.set({ height: minHeight });
                shape.set({ ry: minHeight / 2 })

                isSet = true;
            }
            break;
        case 'rhombus':
            if (group.width < minWidth * 2) {
                const width = minWidth * 2;
                group.set({ width });
                shape.set({ width })

                isSet = true;
            }
            if (group.height < minHeight * 1.5) {
                const height = minHeight * 1.5;
                group.set({ height });
                shape.set({ height });

                isSet = true;
            }
            break;
        case 'triangle':
            if (group.width < minWidth * 2) {
                const width = minWidth * 2;
                group.set({ width });
                shape.set({ width })

                isSet = true;
            }
            if (group.height < minHeight * 1.5) {
                const height = minHeight * 1.5;
                group.set({ height });
                shape.set({ height, top: 0 })

                isSet = true;
            }
            break;
        case 'parallelogram':
            if (group.width < minWidth) {
                const width = minWidth * 2;
                group.set({ width });
                shape.set({ width })

                isSet = true;
            }
            if (group.height < minHeight) {
                group.set({ height: minHeight });
                shape.set({ height: minHeight })
                
                isSet = true;
            }
            break;
        default:
            break;
    }

    if (isSet) {
        // Dont turn this one to requestRenderAll. Some unexpected issues occuring for triangles.
        canvas.renderAll();
    }
}

/**
 * When shapes textbox onChange event triggered, then using this function for next operations.
 * @param {*} param0 
 */
export function onShapeTextboxChanged({
    opt,
    canvas,
    lastOnChangeInfo,
    group,
    textForEditing,
    textInstWidth,
    textInstHeight,
    actualScaleY
}) {
    const shapeType = group.shapeType;
    const isSticky = shapeType === 'sticky';
    canvas.fire('shapeText-editing');

    const insertedCharsCount = Array.isArray(opt.insertedText) ? opt.insertedText.length : 0;
    const removedCharsCount = Array.isArray(opt.removedText) ? opt.removedText.length : 0;
    const operation = (
        insertedCharsCount > removedCharsCount ||
        opt.insertedText?.some((ch) => ch === '\n') // Is new line exist
    ) ? 'added' : 'removed';

    let t1 = textForEditing;
    let textLength = t1.text.length;

    if (textLength === 0) {
        lastOnChangeInfo.linesCount = 0;
        t1.fontSize = SHAPE_DEFAULTS.FONT_SIZE;
        canvas.fire('reset-max-font-size', SHAPE_DEFAULTS.FONT_SIZE);

        return;
    }

    const { lines, newWrappedText: text } = wrapShapeText(group, t1.text, textInstWidth, t1.fontSize);
    if (lines.length === 0) {
        lastOnChangeInfo.linesCount = 0;
        canvas.fire('reset-max-font-size', SHAPE_DEFAULTS.FONT_SIZE);
        return;
    }

    canvas.fire('update-max-font-size', { text, width: textInstWidth, height: textInstHeight });

    if (lastOnChangeInfo.linesCount === lines.length && t1.fontSize !== 10) {
        return;
    }

    let linesCountCanBeInsert = Math.floor(textInstHeight / (t1.fontSize * TEXTBOX_LINE_HEIGHT));

    if (operation === 'added' && Array.isArray(opt.insertedText) && opt.insertedText.length > 0) {
        if (linesCountCanBeInsert < lines.length) {
            const fontMetrics = findFitFontSize({
                text,
                fontSize: t1.fontSize,
                fontFamily: t1.fontFamily,
                width: textInstWidth,
                height: textInstHeight,
                originalText: t1.text,
                object: group
            });

            const newFontSize = Math.max(parseInt(fontMetrics.fontSize, 10), 10);
            let lastLines = null;
            linesCountCanBeInsert = Math.floor(textInstHeight / (newFontSize * TEXTBOX_LINE_HEIGHT));

            // If multiple chars are inserted (especially the big paragraphs), 
            if (insertedCharsCount > 1) {
                const result = wrapShapeText(group, t1.text, textInstWidth, newFontSize);
                lastLines = result.lines;
            }

            if (fontMetrics.fontSize < 10) {
                let insertedLines = [];
                let start = opt.selectionStart || 0;

                // Get overflowed lines to find the character counts which we will remove.
                (lastLines || lines).slice(linesCountCanBeInsert).forEach((line) => {
                    insertedLines.push(line.length);
                });

                if (insertedLines.length > 0) {
                    let removeCharCount = 1;

                    if (insertedCharsCount > 1) {
                        removeCharCount = lastLines
                            .slice(linesCountCanBeInsert)
                            .map((item) => {
                                if (item === '') {
                                    return ' ';
                                }

                                return item;
                            }).join(' ').length;

                        removeCharCount = Math.min(removeCharCount, insertedCharsCount);
                    }

                    start += (insertedCharsCount - removeCharCount);
                    t1.removeChars(start, start + removeCharCount);
                    t1.hiddenTextarea.value = t1.text;

                    if (t1.textLines.length > linesCountCanBeInsert) {
                        t1.removeChars(start - 1, start);
                        t1.hiddenTextarea.value = t1.text;
                        start = t1.text.length;
                    }

                    t1.selectionEnd = start;
                    t1.setSelectionStart(start);

                    t1._splitText();

                    lastOnChangeInfo.linesCount = t1.textLines.length;
                }
            }

            t1.fontSize = Math.max(newFontSize, 10);
            canvas.fire('new-text-size', t1.fontSize);
            canvas.requestRenderAll();
        }
    } else if (
        (isSticky && t1.getScaledHeight() < group.getScaledHeight() - (50 * actualScaleY) && operation === 'removed') ||
        (!isSticky && t1.getScaledHeight() < textInstHeight * actualScaleY && operation === 'removed')
    ) {
        if (linesCountCanBeInsert > lines.length) {
            const fontMetrics = findFitFontSize({
                text,
                fontSize: t1.fontSize,
                fontFamily: t1.fontFamily,
                width: textInstWidth,
                height: textInstHeight,
                originalText: t1.text,
                operation: 'remove',
                object: group
            });

            const newFontSize = Math.max(parseInt(fontMetrics.fontSize, 10), 10);

            t1.fontSize = Math.max(newFontSize, 10);
            lastOnChangeInfo.linesCount = t1.textLines.length;
            canvas.fire('new-text-size', newFontSize);
            canvas.requestRenderAll();
        }
    }
}

/**
 * Calculates the padding of the shapes.
 * @param {fabric.Object} object
 * @param {number=0} minWidth
 * @param {number=0} minHeight
 */
export function getShapeTextareaDimensions(object) {
    const objectType = object.type === 'group' ? object.shapeType : object.type;
    const shape = object.type === 'group' ? object.getObjects()[0] : object;
    
    const shapeInnerWidth = object.width === shape.width ? (shape.width - shape.strokeWidth) : shape.width;
    const shapeInnerHeight = object.height === shape.height ? (shape.height - shape.strokeWidth) : shape.height;
    let SHAPE_PADDING = 24;

    let top = ['rhombus', 'parallelogram', 'rect', 'ellipse', 'sticky'].includes(objectType) ? shapeInnerHeight / 2 : 0;
    let left = (shapeInnerWidth / 2) + shape.strokeWidth;
    let width = 1;
    let height = 1;

    switch (objectType) {
        case 'triangle': {
            const triangleTextboxAreaWidthRate = 0.4;
            const triangleNonTextboxAreaWidthRate = 0.6;
            width = shapeInnerWidth * triangleTextboxAreaWidthRate;

            const s1 = { x: object.aCoords.bl.x, y: object.aCoords.bl.y };
            const s2 = { x: object.aCoords.tl.x + (shapeInnerWidth / 2), y: object.aCoords.tl.y };
            const d1 = { x: object.aCoords.bl.x + (shapeInnerWidth * triangleNonTextboxAreaWidthRate / 2), y: object.aCoords.bl.y };
            const d2 = { x: object.aCoords.tl.x + (shapeInnerWidth * triangleNonTextboxAreaWidthRate / 2), y: object.aCoords.tl.y };
        
            const intersectedPoint = calculateIntersectionOfTwoLines(s1, s2, d1, d2);
            height = Math.abs(intersectedPoint.y - d1.y);
            top = d1.y - (height / 2);
            height /= object.scaleY;
            height -= SHAPE_PADDING;

            break;
        } case 'ellipse':
            width = (shape.rx * 2) * 0.7;
            height = (shape.ry * 2) * 0.7;
            left = shapeInnerWidth / 2 + shape.strokeWidth;
            break;
        case 'rhombus':
            width = (shapeInnerWidth * 0.5) - (SHAPE_PADDING / 2);
            height = (shapeInnerHeight * 0.5) - (SHAPE_PADDING / 2);
            break;
        case 'parallelogram':
            width = (shapeInnerWidth - SHAPE_PADDING) / 2;
            height = shapeInnerHeight - SHAPE_PADDING;
            break;
        case 'rect':
            width = shapeInnerWidth - SHAPE_PADDING;
            height = shapeInnerHeight - SHAPE_PADDING;
            break;
        default: // For ex. Sticky
            width = shapeInnerWidth - SHAPE_PADDING;
            height = shapeInnerHeight - SHAPE_PADDING;
            break;
    }

    return { top, left, width, height, padding: SHAPE_PADDING };
}

/**
 * @param object
 * @param {object} props
 * @param props.isBold
 */
export function updateShapeTextboxHeightAfterStyleChanges(object, { isBold }) {
    if (!object || object.type !== 'group') return;

    const rect = object.getObjects()[0];
    const textbox = object.editingTextObj ?? object.getObjects()[1];
    const textareaDimensions = getShapeTextareaDimensions(object);
    const operation = isBold ? 'add' : 'remove';

    const width = textareaDimensions.width;
    const height = textareaDimensions.height;

    const { lines, newWrappedText: text } = wrapShapeText(object, textbox.text, textareaDimensions.width, textbox.fontSize);
    if (lines.length === 0) return;

    const fontMetrics = findFitFontSize({
        text: text,
        fontSize: textbox.fontSize,
        fontFamily: textbox.fontFamily,
        width: width,
        height: height,
        originalText: textbox.text,
        object,
        operation
    });

    let newFontSize = parseInt(fontMetrics.fontSize, 10);

    if (newFontSize === textbox.fontSize) return;

    if (operation === 'add') {
        if (newFontSize < 10) { // Increase the height
            textbox.set({ fontSize: 10 });
            const increaseCount = (fontMetrics.firstFontHeight - fontMetrics.fontHeight) / textbox.__lineHeights[0];
            const increaseAmount = Math.ceil(increaseCount) * textbox.__lineHeights[0];

            if (object.shapeType === 'ellipse') {
                rect.set({ ry: rect.ry + (increaseAmount / 2) })
            } else {
                rect.set({ height: rect.height + (increaseAmount) })
            }

            object.set({ height: object.height + (increaseAmount) });

            object.canvas.fire('shape-height-updated-by-bolder', { object });
            newFontSize = 10;
        } else { // Update the font size
            textbox.set({ fontSize: newFontSize });
        }
    } else { // Update the font size
        textbox.set({ fontSize: newFontSize });
    }

    object.canvas.fire('new-text-size', newFontSize);
    object.canvas.requestRenderAll();
}


/**
 * Creates a fabric object from data.
 * @param data.data
 * @param {object} data - The object data that we want to create an fabric instance.
 * @param data.target
 * @param data.canvas
 * @returns 
 */
export function createInstanceForChangedShape({
    data,
    target,
    canvas
}) {
    return new Promise((resolve) => {
        createFabricInstance(data, function (objects) {
            objects.forEach(function (o) {
                o.toObject = cloneFabricObjectHelper(o);

                o.avoidEmittingOnAdd = true;
                canvas.add(o);

                const textObj = target._objects[1];
                textObj.visible = true;
                textObj.breakWords = true;
                textObj.splitByGrapheme = false;
                textObj.objectCaching = false;
                arrangeTextInsideShape(target);

                resolve(o);
            });
        });
    });
}