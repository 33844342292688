import axios from 'axios';
import { environment } from '../environment';
import {apiEndpoints} from '../helpers/Constant';

export async function importImages(images, wbSlugId, boardId) {
    try {
        const response = await axios.post(
            environment.REACT_APP_API_ENDPOINT + apiEndpoints.migrationImportImages.replace('{wbSlugId}', wbSlugId)
            ,
            {
                nimaImages: images,
                boardId
            }
        );
        return response;
    } catch (error) {
        return {}
    }
}

export async function importComments(comments, wbSlugId) {
    try {
        const response = await axios.post(
            environment.REACT_APP_API_ENDPOINT + apiEndpoints.migrationImportComments.replace('{wbSlugId}', wbSlugId),
            {
                commentThreads: comments
            }
        );
        return response;
    } catch (error) {
        return {}
    }
}

export async function importMembers(users, wbSlugId) {
    try {
        const response = await axios.post(
            environment.REACT_APP_API_ENDPOINT + apiEndpoints.migrationImportMembers.replace('{wbSlugId}', wbSlugId),
            {
                users
            }
        );
        return response;
    } catch (error) {
        return {}
    }
}

export async function getNimaUserByGuid(guid) {
    try {
        const response = await axios.get(
            environment.NOW_API_ENDPOINT + apiEndpoints.migrationBNOWGetUser.replace('{guid}', encodeURIComponent(guid))
        )
        return response
    } catch (error) {
        console.error(`${error} -- for: ${guid}`)
        return null
    }
}

export async function getGUIDOfUserWithEmail(email, buildCardId) {
    try {
        return await axios.get(
            environment.NOW_API_ENDPOINT + apiEndpoints.migrationBNOWGetUserGUID.replace('{buildCardId}', buildCardId).replace('{email}', encodeURIComponent(email))
        )
    } catch (error) {
        console.error(`${error} -- for: ${email}`)
        return null
    }
}