import PropTypes from 'prop-types';

/**
 *
 */
const PenIcon = ({ width, height }) => {
    return (
        <svg
            fill="none"
            height={height}
            viewBox="0 0 22 22"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                clipRule="evenodd"
                d="M19.492 2.509a3.033 3.033 0 00-4.309.029L3.1 14.62l-1.475 5.754L7.379 18.9 19.462 6.816a3.03 3.03 0 00.03-4.307z"
                fillRule="evenodd"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="M14.838 2.883l4.278 4.278M3.1 14.621l4.283 4.275"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </svg>
    );
}

PenIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number
}

PenIcon.defaultProps = {
    width: 20,
    height: 20
}

export default PenIcon;