import { createSlice } from '@reduxjs/toolkit';
import { getCookie } from '../../../services/CookieService';
import { environment } from '../../../environment';
import { parseDataToObject } from '../../../helpers/CommonUtils';

let initialUserState = {
    id: null,
    name: null,
    email: null,
    properties: {},
    permission: 'NOT_ACCESSED'
}

try {
    const userCookieData = getCookie(environment.USER_COOKIE);
    if (userCookieData) {
        const user = JSON.parse(userCookieData[1]);
        initialUserState.id = user.id;
        initialUserState.name = user.name;
        initialUserState.email = user.email;
        // parse properties and set it to the state
        const properties = parseDataToObject(user?.properties);
        initialUserState.properties = properties;
    }
} catch (err) {
    console.error('Error happened during reading user cookie', err);
}

const userSlice = createSlice({
    name: 'user',
    initialState: initialUserState,
    reducers: {
        setUser: (state, action) => {
            state.id = action.payload.id;
            state.name = action.payload.name;
            state.email = action.payload.email;
            state.properties = parseDataToObject(action?.payload?.properties);
        },
        setNull: (state) => {
            state.id = null;
            state.name = null;
            state.email = null;
            state.properties = null;
        },
        setSeenTutorial: (state, action) => {
            const newProperties = {
                ...state.properties,
                seenTutorial: action.payload
            }
            state.properties = newProperties;
        },
        changePermission: (state, action) => {
            state.permission = action.payload;
        }
    },
});


export default userSlice.reducer;

