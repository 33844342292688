import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { colorOptions } from '../../../helpers/comments/GetCommentIcon';
import { isUserHasAccessToFeature } from '../../../helpers/CommonFunctions';
import useScrollOnMessage from '../../../hooks/UseScrollOnMessage';
import CheckCircle from '../../svgIcons/CheckCircle';
import VerticalMenuDots from '../../svgIcons/VerticalMenuDots';
import CommentInput from '../input/CommentInput';
import CommentItem from '../item/CommentItem';
import './CommentWrapper.scss'
import { getCommentThread } from '../../../services/CommentService';
import { setPositionOfHTMLElement } from '../../../hooks/UseComment';
import CommentWrapperSkeleton from './CommentWrapperSkeleton';
import {KEYS, usedControlKeys} from '../../../helpers/shortcuts/Keys';

/**
 * Comment wrapper to show when clicking the comment icon.
 * @param {object} props
 * @param props.selectedCommentIcon
 * @param props.setSelectedCommentIcon
 * @param props.selectedComment
 * @param props.currentUserId
 * @param props.socket
 * @param props.whiteBoardId
 * @param props.handleReadComment
 * @param props.handleMarkAsUnreadComment
 * @param props.handleAddReply
 * @param props.handleUpdateContent
 * @param props.handleResolveComment
 * @param props.handleDeleteComment
 * @param props.changeSelectedComment
 * @param props.users
 * @param props.userAccess
 */
const CommentWrapper = ({ 
    selectedCommentIcon,
    setSelectedCommentIcon,
    selectedComment,
    currentUserId,
    socket,
    whiteBoardId,
    handleReadComment,
    handleMarkAsUnreadComment,
    handleAddReply,
    handleUpdateContent,
    handleResolveComment, 
    handleDeleteComment, 
    changeSelectedComment,
    users,
    userAccess
}) => {
    const [fetchedData, setFetchedData] = useState(false);
    const [isRead, setIsRead] = useState(false);
    const timerRef = useRef();
    const wrapperRef = useRef();
    const commentListRef = useRef();
    const hasAccessToCommentModification = isUserHasAccessToFeature('comment_modification', userAccess);
    
    // Auto-scroll on new message
    useScrollOnMessage(commentListRef, selectedComment?.replies);

    const handleChangeColorClick = (e) => {
        document.querySelectorAll('.commentToolBar--wrapper').forEach(item => {
            item.classList.remove('selected');
        })
        e.target.parentNode.classList.add('selected');
        e.target.classList.add('selected');
        const selectedColor = e.target.dataset.color;
        selectedCommentIcon.updateColor(selectedColor);

        let updateCommentProps = {
            uuid: selectedCommentIcon.commentID,
            colorCode: selectedColor,
        };
        socket.emit('updateComment', updateCommentProps);
    }
    const sendReply = async (oldValue, value, taggedUsers) => {
        await handleAddReply(oldValue, value, taggedUsers, selectedComment?.uuid, selectedComment?.id.toString());
        selectedCommentIcon.updateColor(selectedCommentIcon.colorCode !== 'black' ? selectedCommentIcon.colorCode : selectedComment.colorCode);
    }

    useEffect(() => {
        if (!selectedComment || Object.entries(selectedComment).length === 0) return;
        const readComment = () => {
            timerRef.current = setTimeout(() => {
                setIsRead(true);
                handleReadComment(selectedComment?.uuid);
            }, 2000);
        }
        if (!isRead) {
            readComment();
        }
        
        return () => {
            clearTimeout(timerRef.current)
        }
    }, [handleReadComment, selectedComment, isRead])

    useEffect(() => {
        const getCommentData = async () => {
            const data = await getCommentThread(whiteBoardId, selectedCommentIcon.commentID);
            changeSelectedComment(data[0]);
            setFetchedData(true);
            setTimeout(() => {
                setPositionOfHTMLElement(selectedCommentIcon, wrapperRef?.current?.parentNode);
            }, 0);
        }
        if (!fetchedData) {
            getCommentData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchedData, selectedCommentIcon, whiteBoardId])

    useEffect(() => {
        const keydownHandler = async (event) => {
            if(event[KEYS.CTRL_OR_CMD]) {
                if (usedControlKeys.filter(key => key !== 'v' && key !== 'V').includes(event.key)){
                    await navigator.clipboard.writeText(window.getSelection().toString())
                }
            }
        };

        document.addEventListener('keydown', keydownHandler);
        return () => document.removeEventListener('keydown', keydownHandler);
    }, []);

    return (
        <section className="commentWrapper" ref={wrapperRef}>
            {fetchedData ? (
                <>
                    <header className="commentToolBar">
                        <div
                            className={clsx('commentToolBar--colors', {
                                disabled: selectedComment?.resolved,
                            })}
                        >
                            {Object.entries(colorOptions).map((item) => {
                                const color = item[0];
                                const id = item[1].id;
                                const isSelected =
                                    !selectedComment?.resolved &&
                                    selectedCommentIcon.colorCode === color;

                                return (
                                    <div
                                        className={clsx(`commentToolBar--wrapper ${color}`, { selected: isSelected })}
                                        data-color={color}
                                        key={id}
                                        onClick={(e) => handleChangeColorClick(e)}
                                    >
                                        <div
                                            className={`commentToolBar--colorBtn colorBtn--${color}`}
                                            data-color={color}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                        <div className='commentToolBar--options'>
                            {(!selectedComment?.resolved && selectedComment?.userId === currentUserId) ? <button
                                type="button"
                                aria-label="Resolve"
                                className={clsx('commentToolBar--optionsBtn', 'resolveBtn', { disabled: !hasAccessToCommentModification })}
                                onClick={() => {
                                    if (hasAccessToCommentModification) {
                                        handleResolveComment(selectedComment?.uuid, true);
                                    }
                                }}
                            >
                                <CheckCircle aria-hidden height={16} width={16} />
                                <span className='tooltipText'>Resolve</span>
                            </button> : null}
                            <div className='commentMoreOption'>
                                <button
                                    type="button"
                                    aria-label="More options"
                                    className="commentToolBar--optionsBtn moreOptionsBtn"
                                >
                                    <VerticalMenuDots height={16} width={16} />
                                </button>
                                <div className="commentMoreOptionsBlock commentHeaderOption">
                                    <div className="commentMoreOptionsInner">
                                        {(selectedComment?.userId === currentUserId) ? <div
                                            className={clsx('commentMoreOptionsInner__item', { disabled: !hasAccessToCommentModification })}
                                            onClick={() => {
                                                if (hasAccessToCommentModification) {
                                                    handleDeleteComment(selectedComment.uuid);
                                                }
                                            }}
                                        >
                                            <span>Delete</span>
                                        </div> : null}
                                        <div
                                            className="commentMoreOptionsInner__item"
                                            onClick={() => {
                                                setTimeout(() => {
                                                    handleMarkAsUnreadComment(selectedCommentIcon.commentID, selectedComment?.replies?.length + 1);
                                                },2001)
                                            }}
                                        >
                                            {/* TODO: We can create a child component in future */}
                                            {/* eslint-disable-next-line */}
                                            <span>Mark as unread</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <hr className="divider" />
                    <main className="commentDetail">
                        <ul className="commentList" ref={commentListRef}>
                            {selectedComment?.user ? <CommentItem
                                handleDeleteComment={handleDeleteComment}
                                handleUpdateContent={handleUpdateContent}
                                isOwner={selectedComment.userId === currentUserId}
                                isParent
                                parentUuid={selectedComment.uuid}
                                selectedComment={selectedComment}
                                selectedCommentIcon={selectedCommentIcon}
                                setSelectedCommentIcon={setSelectedCommentIcon}
                                userAccess={userAccess}
                                users={users}
                                wrapperRef={wrapperRef}
                            /> : null}
                            {selectedComment?.user ? selectedComment.replies.map(replyItem => (
                                <CommentItem
                                    handleDeleteComment={handleDeleteComment}
                                    handleUpdateContent={handleUpdateContent}
                                    isOwner={replyItem.userId === currentUserId}
                                    key={replyItem.uuid}
                                    parentUuid={selectedComment.uuid}
                                    selectedComment={replyItem}
                                    selectedCommentIcon={selectedCommentIcon}
                                    setSelectedCommentIcon={setSelectedCommentIcon}
                                    userAccess={userAccess}
                                    users={users}
                                    wrapperRef={wrapperRef}
                                />
                            )) : null}
                        </ul>
                        <footer className="commentAddForm">
                            <CommentInput 
                                additionalStyles={{ width: '100%' }}
                                handleAdd={sendReply}
                                handleResolveComment={handleResolveComment}
                                selectedComment={selectedComment}
                                userAccess={userAccess}
                                users={users}
                            />
                        </footer>
                    </main>
                </>
            ) : (<CommentWrapperSkeleton />)
            }
        </section>
    );
};

CommentWrapper.propTypes = {
    selectedCommentIcon: PropTypes.object,
    selectedComment: PropTypes.shape({
        id: PropTypes.number,
        whiteboardId: PropTypes.number,
        pageId: PropTypes.number,
        userId: PropTypes.number,
        parentId: PropTypes.number,
        content: PropTypes.string,
        contentSearch: PropTypes.string,
        taggedUserIds: PropTypes.any,
        resolved: PropTypes.any,
        position: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number
        }),
        colorCode: PropTypes.string,
        uuid: PropTypes.string,
        parentUuid: PropTypes.any,
        isDeleted: PropTypes.bool,
        isEdited: PropTypes.bool,
        threadLink: PropTypes.any,
        resolvedBy: PropTypes.object,
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
        replies: PropTypes.arrayOf(PropTypes.shape({
            userId: PropTypes.number,
            uuid: PropTypes.string,
            content: PropTypes.string,
            parentUuid: PropTypes.string,
            isDeleted: PropTypes.bool,
            user: PropTypes.shape({
                name: PropTypes.string,
            }),
            createdAt: PropTypes.string,
            userComment: PropTypes.arrayOf(PropTypes.shape({
                readAt: PropTypes.string
            }))
        })),
        userComment: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            userId: PropTypes.number,
            readAt: PropTypes.any
        })),
        user: PropTypes.shape({
            commentUsername: PropTypes.string,
            name: PropTypes.string
        })
    }),
    setSelectedCommentIcon: PropTypes.func,
    currentUserId: PropTypes.number,
    socket: PropTypes.object,
    whiteBoardId: PropTypes.number,
    handleReadComment: PropTypes.func,
    handleMarkAsUnreadComment: PropTypes.func,
    handleAddReply: PropTypes.func,
    handleUpdateContent: PropTypes.func,
    handleResolveComment: PropTypes.func,
    handleDeleteComment: PropTypes.func,
    changeSelectedComment: PropTypes.func,
    users: PropTypes.arrayOf(PropTypes.shape({
        commentEmail: PropTypes.string,
        commentUsername: PropTypes.string,
        email: PropTypes.string,
        id: PropTypes.number,
        name: PropTypes.string,
        permission: PropTypes.string
    })),
    userAccess: PropTypes.oneOf(['view', 'comment', 'edit', 'removeAccess', 'NOT_ALLOWED'])
}

export default CommentWrapper;
