import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import ErrorBoundary from '../ErrorBoundary';
import CommentDrawerErrorFallBack from './CommentDrawerErrorFallBack';
import { FILTER_OPTIONS, FILTER_OPTIONS_REQUESTS, MODAL_WINDOWS } from '../../helpers/Constant';
import CommentItemWrapper from '../comment/itemWrapper/CommentItemWrapper';
import { getComments } from '../../services/CommentService';
import SearchInput from '../inputs/searchInput/SearchInput';
import { useLayer } from 'react-laag';
import { motion, AnimatePresence } from 'framer-motion';
import Mark from 'mark.js'

// ASSETS
import './CommentDrawer.scss';
import FilterIcon from '../../assets/images/filter.svg';
import CheckboxInput from '../inputs/checkbox/CheckboxInput';
import DoubleTickIcon from '../svgIcons/DoubleTick';
import TickIcon from '../../assets/images/tick.svg';
import DrawerCloseIcon from '../../assets/images/search-close.svg'
import LoadingScreen from '../loader/Loading';
import useCatchComment from '../../hooks/UseCatchComment';
import PropTypes from 'prop-types';
import {fabric} from 'fabric';

const CommentDrawer = ({
    canvas,
    hideComments,
    hideResolvedComments,
    toggleHideComments,
    toggleHideResolvedComments,
    handleUpdateCommentContent,
    handleDeleteComment,
    handleReadAllComments,
    selectedCommentIcon,
    setSelectedCommentIcon,
    userAccessRef,
    users,
    currentUserId,
    whiteBoardId,
    handleToggleCommentDrawer,
    handleResolveComment,
    handleReadComment,
    handleMarkAsUnreadComment,
    setUnreadCommentCount,
    handleCloseFormInputWrapper,
    socketRef
}) => {
    const commentDrawerRef = useRef();

    const searchInputRef = useRef();
    const [comments, setComments] = useState([]);
    const [filter, setFilter] = useState('Show all');
    const [searchKeyword, setSearchKeyword] = useState('');
    const [loading, setLoading] = useState(true);
    const [isSearchEnabled, setIsSearchEnabled] = useState(false);
    const [page, setPage] = useState(1);
    const [markingReplies, setMarkingReplies] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [markResults, setMarkResults] = useState([]);
    const [currentMarkIndex, setCurrentMarkIndex] = useState(0);
    const [firstTime, setFirstTime] = useState(true);
    const observer = useRef();
    const listRef = useRef();
    const activePageId = useSelector(state => state.rightDrawer.activePage?.id);
    const activeWindowState = useSelector((state) => state?.modal?.activeWindow);

    const closeFilterMenu = () => setShowFilterMenu(false);

    const { triggerProps, layerProps, renderLayer } = useLayer({
        isOpen: showFilterMenu,
        onOutsideClick: closeFilterMenu,
        onDisappear: closeFilterMenu,
        auto: false,
        placement: 'bottom-end',
    });

    const lastCommentRef = useCallback(node => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            // if last element is visible, then load more comments
            if (entries[0].isIntersecting) {
                if (hasMore) {
                    setPage(prevPage => prevPage + 1);
                }
            }
        });
        if (node) observer.current.observe(node);
    }, [loading, hasMore, isSearchEnabled, filter]);

    useEffect(() => {
        if (markResults.length > 0) {
            markResults.forEach(item => item.classList.remove('current'));
            markResults[currentMarkIndex].classList.add('current');
            markResults[currentMarkIndex].offsetTop - 200;
        }
    }, [currentMarkIndex, markResults, loading, searchKeyword])
    
    useEffect(()=> {
        listRef?.current?.querySelector('.current')?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
        });
    },[currentMarkIndex])

    const handleNavigateBetweenMarks = (value) => {
        let calcCurrentIndex;
        if (markResults && markResults?.length) {
            calcCurrentIndex = currentMarkIndex + (value === 'up' ? -1 : 1)
            if (calcCurrentIndex < 0) {
                calcCurrentIndex = markResults.length - 1;
            }
            if (calcCurrentIndex > markResults.length - 1) {
                calcCurrentIndex = 0
            }
        }
        setCurrentMarkIndex(calcCurrentIndex);
        
    }

    useEffect(() => {
        const markContainer = new Mark(document.querySelector('.commentDrawer__content'));
        if (isSearchEnabled) {
            markContainer.unmark({
                done: function () {
                    markContainer.mark(searchKeyword, {
                        separateWordSearch: false,
                        exclude: ['div', 'h4', 'label', 'button', '.resolved span', '.error__message p', '.error__message h3', '.commentDrawer__noResult p'],
                        done: () => {
                            let results = document.querySelectorAll('mark')
                            setMarkResults(results);
                        }
                    });
                }
            });
        }
        else markContainer.unmark();
    }, [searchKeyword, comments, isSearchEnabled, markingReplies])

    const handleSearch = async (searchFilter, searchEmpty) => {
        if (!searchFilter && !searchEmpty) return;
        setLoading(true);
        setPage(1);
        setSearchKeyword(searchFilter);
        const response = await getComments(whiteBoardId, 1, activePageId, filter, searchFilter);
        setComments(response.comments);
        setHasMore(response.pages > 1);
        setUnreadCommentCount(response.canvasTotalUnreadCount);
        setUnreadCommentCountList(response.unreadCount);
        setLoading(false);
    }

    const handleResolve = async (parentUuid, resolved) => {
        let currentComment = comments.find(item => item.uuid === parentUuid);
        let currentCommentOnCanvas = canvas.getObjects().find(item => item.commentID === parentUuid);
        await handleResolveComment(parentUuid, resolved, currentComment.colorCode);
        currentCommentOnCanvas.updateColor(currentComment.colorCode);
        canvas.renderAll();
    }

    const handleRead = async (parentUuid) => {
        await handleReadComment(parentUuid);
    }

    const handleMarkAsUnread = async (parentUuid, badgeText) => {
        await handleMarkAsUnreadComment(parentUuid, badgeText);
    }

    const handleReadAll = () => {
        handleReadAllComments(searchKeyword, FILTER_OPTIONS_REQUESTS[filter]);
    }

    const handleFilter = (event, value) => {
        if(document.querySelector('.searchInput__input').value.trim() === ''){
            setIsSearchEnabled(false);
            setSearchKeyword('');
            searchInputRef.current.resetValue();
        }
        if(value === filter) return;
        setCurrentMarkIndex(0);
        setMarkResults([]);
        event.stopPropagation();
        setFilter(value);
        setPage(1);
        closeFilterMenu();
    }

    const getCommentsAsync = useCallback(async () => {
        setLoading(true);
        const data = await getComments(whiteBoardId, 1, activePageId, filter, searchKeyword);
        setComments(data.comments);
        setUnreadCommentCount(data.canvasTotalUnreadCount);
        setUnreadCommentCountList(data.unreadCount);
        setPage(1);
        setHasMore(data.pages > page);
        setLoading(false);
    }, [filter, activePageId, hasMore, page, searchKeyword]);

    useEffect(() => {
        if (firstTime && activeWindowState === 'commentDrawer') {
            getCommentsAsync();
            setFirstTime(false);
        }

        return () => setFirstTime(true);
    }, [activeWindowState])

    useEffect(() => {
        const getCommentsAsync = async () => {
            setLoading(true);
            const data = await getComments(whiteBoardId, 1, activePageId);
            setComments(data.comments);
            setHasMore(data.pages > page);
            setPage(1);
            setUnreadCommentCount(data.canvasTotalUnreadCount);
            setUnreadCommentCountList(data.unreadCount);
            setLoading(false);
        }
        if (!firstTime) {
            getCommentsAsync();
        }
    }, [activePageId])

    // if page changes, then load more comments
    useEffect(() => {
        const getCommentsAsync = async () => {
            const data = await getComments(whiteBoardId, page, activePageId, filter, searchKeyword);
            setComments(prevComments => {
                const newFilteredComments = data?.comments?.filter(comment =>
                    !prevComments.find(prevComment => prevComment.uuid === comment.uuid)
                );
                return [...prevComments, ...newFilteredComments]
            });
            setHasMore(data.pages > page);
            setUnreadCommentCount(data.canvasTotalUnreadCount);
            setUnreadCommentCountList(data.unreadCount);
        }
        if (page !== 1) getCommentsAsync();
    }, [page]);

    useEffect(() => {
        searchInputRef.current.resetValue();
        setIsSearchEnabled(false);
        setMarkResults([]);
        setCurrentMarkIndex(0);
        setSearchKeyword('');
        setMarkingReplies(prevState => !prevState);
        setPage(1);
        setFilter('Show all')
        setHasMore(false);
    }, [activePageId, activeWindowState]);

    useEffect(() => {
        if (!firstTime) getCommentsAsync();
    }, [filter])

    const handleReturnFooter = () => {
        return (
            <div className="commentDrawer__checkboxes">
                <div className="commentDrawer__checkboxGroup">
                    <CheckboxInput
                        label="Hide all"
                        onChange={(value) => toggleHideComments(value)}
                        defaultChecked={hideComments} />
                </div>
                <div className="commentDrawer__checkboxGroup">
                    <CheckboxInput
                        label="Hide resolved"
                        onChange={(value) => toggleHideResolvedComments(value)}
                        defaultChecked={hideResolvedComments} />
                </div>
            </div>
        )
    }
    
    const [unreadCommentCountList, setUnreadCommentCountList] = useCatchComment(socketRef,comments,setComments,setUnreadCommentCount,filter,searchKeyword,getCommentsAsync, setCurrentMarkIndex);

    return (
        <div onClick={handleCloseFormInputWrapper} className={clsx('commentDrawer', { __visible: activeWindowState === MODAL_WINDOWS.COMMENT_DRAWER })}>

            <div className="commentDrawer__title">
                <span>Comments</span>

                <button type="button" className="commentDrawer__closeBtn" onClick={() => {
                    handleToggleCommentDrawer({ shouldShow: false });
                }}>
                    <img src={DrawerCloseIcon} alt="close icon" />
                </button>
            </div>

            <ErrorBoundary fallback={<CommentDrawerErrorFallBack />}>
                <SearchInput
                    ref={searchInputRef}
                    placeholder="Search comments"
                    changeIsSearchEnabled={(data) => {
                        setIsSearchEnabled(data)
                    }}
                    onSearchClicked={handleSearch}
                    handleNavigateBetweenMarks={handleNavigateBetweenMarks}
                    currentMarkIndex={currentMarkIndex}
                    setCurrentMarkIndex={setCurrentMarkIndex}
                    totalMarks={markResults.length}
                    drawerSearch
                />

                <div className="commentDrawer__actions">
                    <button
                        type="button"
                        disabled={!unreadCommentCountList}
                        className={clsx('commentDrawer__actions--markBtn', { disabled: !unreadCommentCountList })}
                        onClick={() => handleReadAll()}
                    >
                        <DoubleTickIcon color={!unreadCommentCountList ? '#C0C3CE' : '#6200EA'} />
                        Mark as read
                    </button>
                    <div className="commentDrawer__actions--filter">
                        <button
                            type="button"
                            {...triggerProps}
                            className={clsx('commentDrawer__actions--filterBtn', { active: showFilterMenu })}
                            onMouseEnter={() => setShowFilterMenu(true)}
                            onMouseLeave={() => setShowFilterMenu(false)}
                            onClick={(e) => e.stopPropagation()}
                            aria-label="Filter menu"
                        >
                            <img src={FilterIcon} alt="Filters" />
                            Filters
                        </button>
                        {renderLayer(
                            <AnimatePresence>
                                {showFilterMenu ? <motion.div
                                    className="commentDrawer__actions--filterMenu"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ ease: 'easeInOut', duration: 0.2 }}
                                    {...layerProps}
                                    onMouseEnter={() => setShowFilterMenu(true)}
                                    onMouseLeave={() => setShowFilterMenu(false)}
                                >
                                    {
                                        FILTER_OPTIONS.map((item) => {
                                            return (
                                                <button
                                                    key={item}
                                                    type="button"
                                                    className="commentDrawer__actions--filterMenuItem"
                                                    onClick={(e) => handleFilter(e, item)}
                                                >
                                                    <div>{item}</div>
                                                    {
                                                        item === filter ? <img src={TickIcon} alt="Tick" /> : null
                                                    }
                                                </button>
                                            )
                                        })
                                    }
                                </motion.div> : null}
                            </AnimatePresence>
                        )}
                    </div>
                </div>

                <div className="commentDrawer__content">
                    <div ref={listRef} className="commentDrawer__commentList">
                        {
                            loading ? <div className="tab_loader">
                                <LoadingScreen />
                            </div> : comments?.length === 0 ? <div className="commentDrawer__noResult">
                                <p>No results</p>
                            </div> : comments?.map((comment, index) => {
                                const commentItemWrapperProps = {
                                    comment,
                                    wrapperRef: commentDrawerRef,
                                    users,
                                    handleUpdateCommentContent,
                                    handleDeleteComment,
                                    handleResolveComment: handleResolve,
                                    handleReadComment: handleRead,
                                    handleMarkAsUnreadComment: handleMarkAsUnread,
                                    currentUserId,
                                    userAccess: userAccessRef?.current,
                                    selectedCommentIcon,
                                    setSelectedCommentIcon,
                                    setMarkingReplies,
                                    markResults,
                                    canvas
                                }
                                if (comments?.length === index + 1) {
                                    return (
                                        <div className="lastElement" ref={lastCommentRef} key={comment.uuid}>
                                            <CommentItemWrapper
                                                {...commentItemWrapperProps}
                                            />
                                        </div>
                                    )
                                }
                                return (
                                    <CommentItemWrapper
                                        key={comment.uuid}
                                        {...commentItemWrapperProps}
                                    />
                                )
                            })
                        }
                    </div>
                    <div className="commentDrawer__footer">
                        {handleReturnFooter()}
                    </div>
                </div>
            </ErrorBoundary>
        </div>
    );
};

CommentDrawer.propTypes = {
    canvas: PropTypes.instanceOf(fabric.Canvas),
    hideComments: PropTypes.bool,
    hideResolvedComments: PropTypes.bool,
    toggleHideComments: PropTypes.func,
    toggleHideResolvedComments:PropTypes.func,
    handleUpdateCommentContent:PropTypes.func,
    handleDeleteComment:PropTypes.func,
    handleReadAllComments:PropTypes.func,
    selectedCommentIcon: PropTypes.object,
    setSelectedCommentIcon:PropTypes.func,
    userAccessRef: PropTypes.shape({
        current: PropTypes.oneOf(['view', 'comment', 'edit', 'removeAccess', 'NOT_ALLOWED'])
    }),
    users: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        info: PropTypes.shape({
            colorCode: PropTypes.string
        })
    })),
    currentUserId: PropTypes.number,
    whiteBoardId: PropTypes.number,
    handleToggleCommentDrawer:PropTypes.func,
    handleResolveComment:PropTypes.func,
    handleReadComment:PropTypes.func,
    handleMarkAsUnreadComment:PropTypes.func,
    setUnreadCommentCount:PropTypes.func,
    handleCloseFormInputWrapper:PropTypes.func,
    socketRef: PropTypes.shape({
        current: PropTypes.object
    }),
}

export default CommentDrawer;
