import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { createPortal } from 'react-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import CloseIcon from '../../assets/images/close.svg';
import './modal.scss';

const Modal = forwardRef(({
    title,
    description,
    actions,
    onCloseClick,
    className,
    disableClosing = false
}, ref) => {
    const [modalVisibility, setModalVisibility] = useState(false);

    const closeModal = () => {
        setModalVisibility(false);
        onCloseClick?.();
    };

    useImperativeHandle(ref, () => {
        return {
            openModal() {
                setModalVisibility(true);
            },
            closeModal() {
                setModalVisibility(false);
            }
        }
    }, [setModalVisibility]);    

    return createPortal((
        <div className={clsx('modal', className ,{ '--visible': modalVisibility })}>
            <div className="modal__backdrop" onClick={() => {
                if (disableClosing) return;
                closeModal()
            }} />

            <div className="modal__content">
                <div className="modal__title">
                    {title}

                    {
                        !disableClosing ? (
                            <button type="button" className="closeBtn" onClick={closeModal}>
                                <img src={CloseIcon} width={18} height={18} alt="close" />
                            </button>
                        ) : null
                    }
                </div>
                <div className="modal__description">{description}</div>

                {actions ? <div className="modal__actions">
                    {actions}
                </div> : null}
            </div>
        </div>
    ), document.body);
});

Modal.displayName = 'Modal';
Modal.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    actions: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    onCloseClick: PropTypes.func,
    className: PropTypes.string,
    disableClosing: PropTypes.bool
}

export default Modal;
