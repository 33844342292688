/**
 *
 */
const ParallelogramIcon = () => {
    return (
        <svg
            fill="none"
            height="20"
            viewBox="0 0 24 20"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.445.907A.25.25 0 018.677.75h13.846a.25.25 0 01.232.343l-7.2 18a.25.25 0 01-.232.157H1.477a.25.25 0 01-.232-.343l7.2-18z"
                stroke="#3C3E49"
                strokeWidth="1.5"
            />
        </svg>
    );
}

export default ParallelogramIcon;