/**
 *
 */
const IconLineDrawingTypeDashed = () => {
    return (
        <svg
            fill="none"
            height="18"
            viewBox="0 0 32 18"
            width="39"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5 9h22"
                stroke="#3C3E49"
                strokeDasharray="4 4"
                strokeLinecap="round"
                strokeWidth="2"
            />
        </svg>
    )
}

export default IconLineDrawingTypeDashed;