import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import DeleteIcon from '../../svgIcons/canvasRedesign/IconDelete';

const DeleteItem = ({
    isDisabled,
    isDeleteBlock,
    onClick,
    showDeleteOptions,
    removeShape,
    handleHoverDeleteItem
}) => {
    return (
        <div className={clsx('delete', { deleteBlock: isDeleteBlock })}>
            <div className={clsx('button', { disabled: isDisabled })}
                onClick={onClick}
            >
                <DeleteIcon />
                <span className="tooltipText">Delete</span>
                {(showDeleteOptions) ? <em className="icon-arrow-down" /> : null}
            </div>

            {(showDeleteOptions) ? (
                <div className="deleteOptionsBlock">
                    <div className="deleteOptionsInner">
                        <div
                            onMouseEnter={handleHoverDeleteItem}
                            onClick={() => removeShape(false, true)}
                            className="deleteOptionsInner__item active">Delete frame with objects</div>
                        <div
                            onMouseEnter={handleHoverDeleteItem}
                            onClick={() => removeShape(true, true)}
                            className="deleteOptionsInner__item">Delete frame only</div>
                    </div>
                </div>
            ) : null}
        </div>
    )
}

DeleteItem.propTypes = {
    isDisabled: PropTypes.bool,
    isDeleteBlock: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    showDeleteOptions: PropTypes.bool,
    removeShape: PropTypes.func.isRequired,
    handleHoverDeleteItem: PropTypes.func.isRequired,
}

export default DeleteItem;