import { fabric } from 'fabric'
/**
 * Adds comment ref wrapper object to the canvas. This is necessary for setting position of outside
 * canvas elements .
 * @param {fabric.Canvas} canvas 
 * @param commentFormWrapperRef
 */
export const addCommentRefWrapper = (canvas, commentFormWrapperRef) => {
    let wrapperRect = new fabric.Rect({
        top: 0,
        left: 0,
        width: 1,
        height: 1,
        fill: 'red',
        dirty: true,
        selectable: false,  // since this is just refference object, this must be unselectable by user
        visible: false
    })
    Object.defineProperty(wrapperRect, 'wiredTo', {
        value: commentFormWrapperRef,
        writable: true,
        configurable: true,
        enumerable: true,
    })

    Object.defineProperty(wrapperRect, 'type', {
        value: 'commentRefWrapper',
        writable: false,
        configurable: false,
    })
    canvas.add(wrapperRect);
    return wrapperRect;
}

/**
 * Gets comment ref object.
 * @param { fabric.Canvas } canvas
 * @returns { fabric.Object }
 */
export const getOrCreateCommentRefObject = (canvas) => {
    let commentRefObj = canvas._objects.find(obj => obj.type === 'commentRefWrapper');
    if (commentRefObj) return commentRefObj

    return addCommentRefWrapper(canvas, undefined);
}