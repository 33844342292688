import React from 'react';
/**
 *
 */
const MyCanvas = () => {
    return (
        <svg
            fill="none"
            height="21"
            viewBox="0 0 20 21"
            width="20"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.5 5H3.75C3.05964 5 2.5 5.55964 2.5 6.25V15H17.5V6.25C17.5 5.55964 16.9404 5 16.25 5H12.5"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="M1.25 15H18.75"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="M10 15V19.375"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="M6.875 15L5 19.375"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="M13.125 15L15 19.375"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                clipRule="evenodd"
                d="M11.25 6.25H8.75C8.05964 6.25 7.5 5.69036 7.5 5V5V4.375C7.5 4.02982 7.77982 3.75 8.125 3.75H11.875C12.2202 3.75 12.5 4.02982 12.5 4.375V5V5C12.5 5.69036 11.9404 6.25 11.25 6.25Z"
                fillRule="evenodd"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="M10 1.875V3.75"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </svg>
    );
}
export default MyCanvas;
