import React from 'react';
import PropTypes from 'prop-types';

const VerticalMenuDots = ({ width, height }) => (
    <svg
        fill="none"
        height={height}
        viewBox="0 0 6 24"
        width={width}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            clipRule="evenodd"
            d="M2.98633 23.2529C4.43608 23.2529 5.61133 22.0777 5.61133 20.6279C5.61133 19.1782 4.43608 18.0029 2.98633 18.0029C1.53658 18.0029 0.361328 19.1782 0.361328 20.6279C0.361328 22.0777 1.53658 23.2529 2.98633 23.2529Z"
            fill="black"
            fillRule="evenodd"
        />
        <path
            clipRule="evenodd"
            d="M2.98633 6.00293C4.43608 6.00293 5.61133 4.82768 5.61133 3.37793C5.61133 1.92818 4.43608 0.75293 2.98633 0.75293C1.53658 0.75293 0.361328 1.92818 0.361328 3.37793C0.361328 4.82768 1.53658 6.00293 2.98633 6.00293Z"
            fill="black"
            fillRule="evenodd"
        />
        <path
            clipRule="evenodd"
            d="M2.98633 14.6279C4.43608 14.6279 5.61133 13.4527 5.61133 12.0029C5.61133 10.5532 4.43608 9.37793 2.98633 9.37793C1.53658 9.37793 0.361328 10.5532 0.361328 12.0029C0.361328 13.4527 1.53658 14.6279 2.98633 14.6279Z"
            fill="black"
            fillRule="evenodd"
        />
    </svg>
);

VerticalMenuDots.propTypes = {
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

VerticalMenuDots.defaultProps = {
    width: 24,
    height: 24
}

export default VerticalMenuDots;
