import { fabric } from 'fabric';
import { getAllPositionsOfConnectorControls } from '../customControls/connector/ConnectorControlMethods';
import { isPointerContainedWithObject, isTargetAttachableToLine } from '../FabricMethods';
import { customContainPointsWidthPadding } from './LineMethods';
import { MAGNET_CIRCLE_TYPE } from '../Constant';
import { sortObjectsByZIndexes } from '../StackOrder';

const canConnectorVisible = (frontObjects, coords) => {
    if (frontObjects.length === 0) return true;

    return !(frontObjects.some((obj) => {
        return isPointerContainedWithObject(obj, coords);
    }));
}

const handleAddConnectorCircles = (obj, canvas, frontObjects) => {
    if (!obj.connectorCircles) {
        const connectorCoordinates = getAllPositionsOfConnectorControls(obj);
        const circleProps = {
            originX: 'center',
            originY: 'center',
            fill: '#B388FF',
            stroke: '#6200ea',
            radius: 5,
            ignoreZoom: true,
            selectable: false,
            hasBorders: false,
            hasControls: false,
            hasRotatingPoint: false,
            shapeType: MAGNET_CIRCLE_TYPE,
            connectedShapeUuid: obj.uuid,
            isDynamic: true  // tells that we will use this instance only for mocking
        }

        const circles = [];

        if (canConnectorVisible(frontObjects, connectorCoordinates.ml)) {
            circles.push(new fabric.Circle({
                left: connectorCoordinates.ml.x,
                top: connectorCoordinates.ml.y,
                circleFor: 'connectorLeft',
                ...circleProps
            }));
        }

        if (canConnectorVisible(frontObjects, connectorCoordinates.mt)) {
            circles.push(new fabric.Circle({
                left: connectorCoordinates.mt.x,
                top: connectorCoordinates.mt.y,
                circleFor: 'connectorTop',
                ...circleProps
            }));
        }

        if (canConnectorVisible(frontObjects, connectorCoordinates.mr)) {
            circles.push(new fabric.Circle({
                left: connectorCoordinates.mr.x,
                top: connectorCoordinates.mr.y,
                circleFor: 'connectorRight',
                ...circleProps
            }));
        }

        if (canConnectorVisible(frontObjects, connectorCoordinates.mb)) {
            circles.push(new fabric.Circle({
                left: connectorCoordinates.mb.x,
                top: connectorCoordinates.mb.y,
                circleFor: 'connectorBottom',
                ...circleProps
            }));
        }

        circles.forEach((circle) => canvas.add(circle));

        if (!obj.connectorCircles) obj.connectorCircles = [];
        obj.connectorCircles.push(...circles);
    }
}
const handleActivateCircles = (polygon, obj, actualPoint, currentPointIndex, zoomLevel) => {
    for (const circle of obj.connectorCircles) {
        const isNear = customContainPointsWidthPadding(circle, actualPoint, 10 / zoomLevel);
        if (isNear) {
            polygon.points[currentPointIndex] = {
                x: circle.left - polygon.left + polygon.pathOffset.x,
                y: circle.top - polygon.top + polygon.pathOffset.y
            };
            break;
        }
    }
    if (!polygon.activedCirclesOfShapes) polygon.activedCirclesOfShapes = new Map();
    polygon.activedCirclesOfShapes.set(obj.uuid, obj); 
}
const handleAddMouseUpListenerForPolygon = (polygon, options) => {
    if (!polygon.isMouseUpAdded && !options.dontAddMouseUpHandler) {
        polygon.isMouseUpAdded = true;
        polygon.on('mouseup', polygonMouseUpHandler);
    }
}

/**
 * @param polygon
 * @param currentPointIndex
 * @param options
 */
export default function magnetEffectForPolygon(polygon, currentPointIndex, options = {}) {
    // disable magnet effect for curve control point
    if (currentPointIndex !== 0 && currentPointIndex !== polygon.points.length - 1) return;

    const point = polygon.points[currentPointIndex];
    const actualPoint = {
        x: polygon.left + point.x - polygon.pathOffset.x,
        y: polygon.top + point.y - polygon.pathOffset.y
    }
    const canvas = polygon.canvas;
    const zoom = canvas.getZoom();
    const attachableObjects = canvas.getObjects().filter(obj => {
        return (isTargetAttachableToLine(obj) && !options.excludedObjects?.includes(obj.uuid));
    });

    let circleActivatedObjects = []

    // Iterate all attachable objects
    for (const obj of attachableObjects) {
        const isContain = customContainPointsWidthPadding(obj, actualPoint);
        
        if (isContain) {
            circleActivatedObjects.push(obj);
        } else if (isCirclesActivatedForShape(obj)) {
            removeObjConnectorCircles(obj);
        }

        handleAddMouseUpListenerForPolygon(polygon, options);
    }

    // We need to find intersected objects of these activated objects in order to hide circles which that behind of an object. 
    const { intersectedObjects } = findIntersectedObjectsOfActivedShapes(attachableObjects, circleActivatedObjects);
    const allActivableObjects  = sortObjectsByZIndexes([...circleActivatedObjects, ...intersectedObjects]);

    // Iterate the contained objects
    for (const obj of circleActivatedObjects) {
        const objIdx = allActivableObjects.findIndex((o) => o.uuid === obj.uuid);
        const frontObjects = allActivableObjects.slice(0, objIdx);

        handleAddConnectorCircles(obj, canvas, frontObjects);
        handleActivateCircles(polygon, obj, actualPoint, currentPointIndex, zoom) 
    }
}
  
const isCirclesActivatedForShape = (shape) => {
    let isActivated = false;
    if (shape && shape.connectorCircles) isActivated = true;
    return isActivated;
}

const polygonMouseUpHandler = (e) => {
    const { target } = e;
    if (target.activedCirclesOfShapes) {
        target.activedCirclesOfShapes.forEach((obj) => {
            removeObjConnectorCircles(obj);
        });
        target.activedCirclesOfShapes = null;
    }
}
  
const removeObjConnectorCircles = (obj) => {
    if (obj.connectorCircles && Array.isArray(obj.connectorCircles) && obj.canvas) {
        obj.connectorCircles.forEach((circle) => {
            obj.canvas.remove(circle);
        });
    }
    obj.connectorCircles = null;
}

/**
 * 
 * @param {[fabric.Object]} attachableObjects 
 * @param {[fabric.Object]} activedObjects 
 * @returns 
 */
const findIntersectedObjectsOfActivedShapes = (attachableObjects, activedObjects) => {
    const containedObjectUuids = activedObjects.map((p) => p.uuid);
    const intersectedObjectUuids = [];
    const intersectedObjects = [];

    for (const obj of activedObjects) {
        const intersectedObjectsOfObj = attachableObjects.filter((attachableObj) => {
            return (
                attachableObj.uuid !== obj.uuid &&
                !intersectedObjectUuids.includes(attachableObj.uuid) &&
                !containedObjectUuids.includes(attachableObj.uuid) &&
                obj.intersectsWithRect(attachableObj.aCoords.tl, attachableObj.aCoords.br, true)
            )
        });

        intersectedObjectUuids.push(...intersectedObjectsOfObj.map((p) => p.uuid));
        intersectedObjects.push(...intersectedObjectsOfObj);
    }

    return { intersectedObjects, intersectedObjectUuids }
}