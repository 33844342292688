import { fabric } from 'fabric';
import { CurvedLine } from '../../hooks/UseCurvedLine';
import {LINE_TYPES, SHAPE_DEFAULTS, SHAPE_DEFAULTS_AS_SHAPE_PROPS} from '../Constant';
import {
    attachThirdPartyLines, attachThirdPartyShapesToFrames,
    calculateBoundingBox,
    createShapeGroup, filterCommands,
    getBoundingBoxOfObject, getCommentColorFromNimaComment,
    getPathStringFromPoints, mapCommentMessage, removeImagesFromList,
    updateObjectSize,
    updatePosition
} from './Utils';
import OptimizedImage from '../../customClasses/image/OptimizedImage';
import {createObjectToBeEmitted, generateUuidForShape, wait} from '../CommonFunctions';
import { Frame } from '../../hooks/UseFrame';
import generateFrameText from '../frame/GenerateFrameText';
import {SHAPES, COMMANDS, VALID_SHAPES, VALID_COMMANDS, THIRD_PARTY_FONTS} from './Constants';
import {Table} from '../table/shapes/Table';
import {generateTableTitle} from '../table/TableEventHandlers';
import {onTableDrawn} from '../table/TableMethods';
import {hexToRGBA} from '../CommonUtils';
import {environment} from '../../environment';
import {getNimaUserByGuid, importComments, importImages, importMembers} from '../../services/MigrationService';
import renderAllComments from '../comments/RenderAllComments';
import {divideListToChunks} from '../OptimizationUtils';
import {loadImage} from '../media/LoadImageAsync';
import {customToObject} from '../FabricMethods';
import store from '../../redux/Store';

function handleCommand(objectMap, command, objStack) {
    switch (command.cmd) {
        case COMMANDS.MOVE:
            for (const shapeId of command.shapes) {
                const objectData = objectMap.get(shapeId)
                if (!objectData) {
                    continue;
                }
                const shapeBoundingBox = getBoundingBoxOfObject(objectData);

                updatePosition.bind(objectData)(shapeBoundingBox.x - command.dx, shapeBoundingBox.y - command.dy)
            }
            break;
        case COMMANDS.RESIZE: {
            const boundingBox = calculateBoundingBox(command.shapes.map(shapeId => objectMap.get(shapeId)))
            let offsetX = boundingBox.x - command.x,
                offsetY = boundingBox.y - command.y,
                scaleX = command.w / boundingBox.w,
                scaleY = command.h / boundingBox.h;

            for (const shapeId of command.shapes) {
                const shapeObject = objectMap.get(shapeId)
                if (!shapeObject) {
                    continue;
                }

                const shapeBoundingBox = getBoundingBoxOfObject(shapeObject);
                const updateSizeFunction = updateObjectSize.bind(shapeObject)
                updateSizeFunction(shapeBoundingBox.x - offsetX, shapeBoundingBox.y - offsetY, shapeBoundingBox.w * scaleX, shapeBoundingBox.h * scaleY);
                let positionX = shapeBoundingBox.x - command.x - offsetX,
                    positionY = shapeBoundingBox.y - command.y - offsetY;
                const updatePositionFunction = updatePosition.bind(shapeObject)
                updatePositionFunction(command.x + positionX * scaleX, command.y + positionY * scaleY)
            }
            break;
        }
        case COMMANDS.COLOR_MODIFY:
            for (const shapeId of command.shapes) {
                const objectData = objectMap.get(shapeId)
                // if the color modifier changes fill or stroke
                if (objectData.cmd !== SHAPES.STICKY_NOTE
                    && objectData.cmd !== SHAPES.FRAME
                    && objectData.cmd !== SHAPES.TEXT
                    && objectData.cmd !== SHAPES.LINE
                ) {
                    if (command.fill) {
                        objectData.fill = command.fill;
                    }
                    if (command.stroke) {
                        objectData.stroke = command.stroke;
                    }
                } else {
                    objectData.color = command.color === '' ? 'transparent' : command.color;
                }
            }
            break;
        case COMMANDS.STICKY_NOTE_MODIFIER: {
            const objectData = objectMap.get(command.shape)
            objectData.text = command.text;
            if (!objectData) {
                break;
            }
            if (command.hasOwnProperty('text')) {
                objectData.text = command.text;
            }
            if (command.hasOwnProperty('fontSize')) {
                objectData.fontSize = command.fontSize;
            }
            break;
        }
        case COMMANDS.SIZE_MODIFIER:
            for (const shapeId of command.shapes) {
                const objectData = objectMap.get(shapeId)
                if (!objectData) {
                    continue;
                }
                objectData.w = command.w;
                objectData.h = command.h;

                // if props are changed
                if (command?.innerContents?.length) {
                    for (const innerContent of command.innerContents) {
                        if (!innerContent?.props?.length) {
                            continue;
                        }
                        // create new command and handle it
                        const innerContentCommand = {
                            shapes: [innerContent.shape],
                            cmd: COMMANDS.PROPERTY_MODIFIER,
                            props: innerContent.props,
                        }
                        handleCommand(objectMap, innerContentCommand, objStack)
                    }
                }
            }
            break;
        case COMMANDS.TEXT_MODIFIER: {
            const shape = objectMap.get(command.shape)
            if (shape) {
                shape.value = command.value;
            }
            break;
        }
        case COMMANDS.FONT_SIZE_MODIFIER:
            for (const shapeId of command.shapes) {
                const objectData = objectMap.get(shapeId)
                if (!objectData) {
                    continue;
                }
                objectData.size = command.size;
            }
            break;
        case COMMANDS.LOCK:
            for (const shapeId of command.shapes) {
                const objectData = objectMap.get(shapeId)
                objectData.locked = command.locked;
            }
            break;
        case COMMANDS.REMOVE:
            for (const shapeId of command.shapes) {
                objectMap.delete(shapeId)
            }
            break;
        case COMMANDS.REPOSITION:
            for (const reposition of command.shapePositions) {
                const shapeData = objectMap.get(reposition.shape)
                if (shapeData) {
                    shapeData.x = reposition.x;
                    shapeData.y = reposition.y;
                }
            }
            break;
        case COMMANDS.CHANGE_ORDER:
            for (const shape of command.shapes) {
                const indexOfShape = objStack.indexOf(shape)
                if (indexOfShape !== -1) {
                    objStack.splice(indexOfShape, 1)
                    if (command.dir === 'up') {
                        objStack.push(shape)
                    } else {
                        objStack.unshift(shape)
                    }
                }
            }
            break;
        case COMMANDS.PROPERTY_MODIFIER: {
            const handlePropertyModify = (objectData, prop) => {
                // prop name is children means something happened with the frame.
                // Either we added a shape or removed a shape from frame
                if (prop.name === 'children' && objectData.children && Array.isArray(objectData.children)) {
                    if (prop.type === 'add') {
                        objectData.children.push(prop.value);
                    } else if (prop.type === 'del') {
                        const index = objectData.children.indexOf(prop.value);
                        if (index !== -1) {
                            objectData.children.splice(index, 1);
                        }
                    }
                } else {
                    // otherwise just update the prop name with the value
                    objectData[prop.name] = prop.value;
                }
            }
            for (const shapeId of command.shapes) {
                const objectData = objectMap.get(shapeId)
                if (!objectData || !command.props) {
                    continue;
                }

                for (const prop of command.props) {
                    handlePropertyModify(objectData, prop)
                }
            }
            break;
        }
        case COMMANDS.REPLACE:
            // remove the old shape
            objectMap.delete(command.shape);

            // add the new shape
            objectMap.set(command.newShape.id, command.newShape)
            break;
        default:
            console.log('unknown command', command);
            break;
    }
}


function setShapeProps(canvas, shapeInstance, data, options = {}) {
    if (data.locked) {
        shapeInstance.lockMovementX = true;
        shapeInstance.lockMovementY = true;
    }
    if (!shapeInstance.uuid) {
        shapeInstance.uuid = generateUuidForShape(canvas)
    }

    const { wbId, userId } = options;

    if ((!wbId || !userId)) {
        console.error('missing whiteboardId or userId')
        return;
    }

    shapeInstance.originalId = data.id;

    shapeInstance.set({
        whiteBoardId: wbId,
        createdBy: userId,
        modifiedBy: userId,
        isDeleted: false,
        importedFromThirdParty: true,
        hideFromActivityLog: true  // this is for hiding it in logs initially, then we are deleting this prop in activity log tab
    });

    if (shapeInstance.type === 'textbox' || shapeInstance.type === 'path') {
        shapeInstance.shapeType = shapeInstance.type
    }
}

async function createFromMap(canvas, objectMap, objStack, uploadedImages, options = {}) {
    const createdShapes = []
    const objects = Array.from(objectMap.values()).filter(obj => obj.visible !== false && obj.cmd !== SHAPES.COMMENT)

    objects.sort((a, b) => {
        const aIndex = objStack.indexOf(a.id)
        const bIndex = objStack.indexOf(b.id)
        return aIndex - bIndex
    });

    for (const data of objects) {
        switch (data.cmd) {
            case SHAPES.RECTANGLE: {
                const rect = new fabric.Rect({
                    width: data.w,
                    height: data.h,
                    left: data.x,
                    top: data.y,
                    fill: data.fill ? hexToRGBA(data.fill) : SHAPE_DEFAULTS.FILL,
                    stroke: data.stroke ? hexToRGBA(data.stroke) : SHAPE_DEFAULTS.STROKE,
                    strokeWidth: SHAPE_DEFAULTS.STROKE_WIDTH,
                });
                const rectGroup = createShapeGroup(rect, data)
                setShapeProps(canvas, rectGroup, data, options)

                createdShapes.push(rectGroup);
                break;
            }
            case SHAPES.CIRCLE: {
                const ellipse = new fabric.Ellipse({
                    left: data.x - data.r,
                    top: data.y - data.r,
                    rx: data.r,
                    ry: data.r,
                    fill: data.fill ? hexToRGBA(data.fill) : SHAPE_DEFAULTS.FILL,
                    stroke: data.stroke ? hexToRGBA(data.stroke) : SHAPE_DEFAULTS.STROKE,
                    strokeWidth: data.lineWidth,
                })
                const ellipseGroup = createShapeGroup(ellipse, data)
                setShapeProps(canvas, ellipseGroup, data, options)
                createdShapes.push(ellipseGroup)
                break;
            }
            case SHAPES.STICKY_NOTE: {
                const stickyNote = new fabric.Rect({
                    width: data.w,
                    height: data.h,
                    left: data.x,
                    top: data.y,
                    rx: 10,
                    ry: 10,
                    fill: data.color ? hexToRGBA(data.color) : SHAPE_DEFAULTS.FILL,
                    shadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
                });
                const stickyNoteGroup = createShapeGroup(stickyNote, data)
                setShapeProps(canvas, stickyNoteGroup, data, options)
                createdShapes.push(stickyNoteGroup)
                break;
            }
            case SHAPES.TEXT: {
                const text = new fabric.Text(data.value, {
                    fontFamily: SHAPE_DEFAULTS.FONT_FAMILY,
                    fontSize: data.size * 1.333,
                    left: data.x,
                    top: data.y,
                    objectCaching: false,
                    textAlign: data.align || 'left'
                });
                const actualFontSize = data.size * 1.333;

                const textBox = new fabric.Textbox(data.value, {
                    fontFamily: SHAPE_DEFAULTS.FONT_FAMILY,
                    fontSize: actualFontSize,
                    left: data.x,
                    top: data.y,
                    objectCaching: false,
                    width: text.width + 10,
                    fill: data?.color ? hexToRGBA(data.color) : SHAPE_DEFAULTS.TEXT_COLOR,
                    textAlign: data.align || 'left'
                });
                setShapeProps(canvas, textBox, data, options)
                createdShapes.push(textBox);
                break;
            }
            case SHAPES.LINE: {
                const points = [
                    new fabric.Point(data.points[0].x, data.points[0].y),
                    new fabric.Point(data.points[1].x, data.points[1].y)
                ]
                const line = new CurvedLine(points, {
                    stroke: data.color,
                    strokeWidth: data.lineWidth,
                    originY: 'center',
                    originX: 'center',
                    arrowEnabled: true,
                    arrowLeft: data?.type === 'barrow' || data.type === 'rarrow',
                    arrowRight: data?.type === 'arrow' || data?.type === 'barrow',
                    lineType: LINE_TYPES.STRAIGHT,
                    isInitiallyConnector: data?.type === 'arrow'
                });
                if (data.type === 'dashed' || data.type === 'dotted') {
                    line.set('strokeDashArray', [5, 5])
                }
                setShapeProps(canvas, line, data, options)
                createdShapes.push(line)
                break;
            }
            case SHAPES.IMAGE:
                try {
                    const uploadedImg = uploadedImages.find(img => img.resourceId === data.resource)
                    if (!uploadedImg) {
                        console.error('could not find the uploaded image', data.resource, uploadedImages)
                        continue;
                    }
                    let resource = data.resource;
                    const image = new OptimizedImage(null, {
                        width: data.w,
                        height: data.h,
                        left: data.x,
                        top: data.y,
                        imageData: uploadedImg.imageData,
                        resource: resource,

                    });
                    setShapeProps(canvas, image, data, options)
                    createdShapes.push(image)
                } catch (err) {
                    console.error('error while importing image', err)
                }
                break;
            case SHAPES.PEN: {
                const path = getPathStringFromPoints(data.points)
                const object = new fabric.Path(path, {
                    stroke: data.color,
                    fill: 'transparent',
                    strokeWidth: SHAPE_DEFAULTS.STROKE_WIDTH,
                })
                setShapeProps(canvas, object, data, options)
                createdShapes.push(object);
                break;
            }
            case SHAPES.FRAME: {
                const thickness = 4 / 2;  // 4 is the stroke width of the frame
                const shadow = new fabric.Shadow({
                    color: '#b388ff70',
                    blur: 3,
                });

                const frame = new Frame({
                    left: data.x - thickness,
                    top: data.y - thickness,
                    width: data.w,
                    height: data.h,
                    stroke: SHAPE_DEFAULTS_AS_SHAPE_PROPS.stroke,
                    strokeWidth: 1,
                    fill: !data?.color ? 'rgba(255, 255, 255, 0.5)' : hexToRGBA(data.color),
                    objectCaching: false,
                    shadow,
                    text: data.title || generateFrameText(canvas)
                });

                setShapeProps(canvas, frame, data, options)
                createdShapes.push(frame);
                break;
            }
            case SHAPES.COMPOSITE: {
                const metadata = data?.metadata?.['wt-df'];
                if (metadata?.type !== 'tabular') {
                    console.log('skipping composite since its not a table', data)
                    return;
                }

                const tableInfoStr = metadata?.data?.table;
                const rows = tableInfoStr.split('\n');
                const tableInfo = rows.map(row => row.split(','));

                const rowCount = tableInfo.length;
                const columnCount = tableInfo[0].length;
                const { x, y } = data.shapes[0];

                const tableCols = Array.from({length: columnCount}, () => ({ width: 0 }) )

                try {
                    // check if the table has border
                    const hasBorder = data?.shapes?.some(shape => shape.cmd === 'rectangle');

                    // get the table shapes.
                    // if the table has border, shapes will include rectangle shape. In that case we only need rectangles
                    const tableShapes = hasBorder ? data.shapes.slice(0, data.shapes.length / 2) : data.shapes;

                    let columnIdx = -1;
                    for (let shapeIdx = 0; shapeIdx < tableShapes?.length; shapeIdx++) {
                        // get the column id for this shape
                        columnIdx++;
                        if (columnIdx > tableCols.length - 1) {
                            columnIdx = 0;
                        }

                        const shape = tableShapes[shapeIdx];
                        const widthOfShape = shape.cmd === 'rectangle' ? shape.w : getBoundingBoxOfObject(shape).w;

                        // find maximum width for this column
                        if (tableCols[columnIdx].width < widthOfShape) {
                            tableCols[columnIdx].width = widthOfShape;
                        }
                    }
                } catch (err) {
                    tableCols.length = 0;  // in case of any error, use default table width
                }

                const table = new Table({
                    left: x,
                    top: y,
                    lockScalingFlip: true,
                    totalRows: rowCount,
                    totalCols: columnCount,
                    cellTexts: tableInfo,  // initialize the table with the texts
                    cellTextPadding: 1,
                    defaultCellHeight: 19,
                    cols: tableCols
                })
                setShapeProps(canvas, table, data, options)
                createdShapes.push(table);
                break;
            }
            default:
                console.log('unknown shape', data);
                break;
        }
    }


    // add the shapes
    for (const shape of createdShapes) {
        // generate the title
        if (shape.type === 'table') {
            shape.title = generateTableTitle(canvas);
        }
        canvas.add(shape)
        if (shape.type === 'table') {
            onTableDrawn(shape);
        }
    }
    return createdShapes;
}

/**
 * Handles the command loop
 * @param {Array} commandList list of commands
 * @param {Map<string, object>} objectMap map of commands
 * @param {Array} objStack list of stack of objects
 */
function handleCommandLoop(commandList, objectMap, objStack) {
    for (const data of commandList) {
        if (VALID_SHAPES.includes(data.cmd)) {
            // if the shape is a composite, and it's not a tabular composite, skip it
            if (data.cmd === 'composite' && (!data.metadata || data?.metadata['wt-df']?.type !== 'tabular')) {
                console.log('skipping composite', data);
                continue;
            }
            objectMap.set(data.id, data)
            objStack.push(data.id)
        } else if (VALID_COMMANDS.includes(data.cmd)) {
            if (data.cmd !== 'commandSet') {
                handleCommand(objectMap, data, objStack)
            } else {
                handleCommandLoop(data.commands, objectMap, objStack);
            }
        } else {
            console.log('skipping an unknown command', data)
        }
    }
}

/**
 * Get the member list of the board
 * @returns {Promise<unknown>}
 */
async function getMemberList(config) {
    console.log('getting member list')
    const { nimaWbId, guid } = config;
    const fetchOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        }
    }
    const clientId = environment.NIMA_BOARD_CLIENT_ID;
    const negotiationUrl = `https://www.whiteboard.team/board-hub/negotiate?uid=${guid}&negotiateVersion=1`
    const negotiationData = await fetch(negotiationUrl, fetchOptions).then(res => {
        return res.json()
    }).catch(err => {
        console.error('an error has occurred while getting whiteboard team data', err)
    })

    if (!negotiationData) {
        return Promise.reject('could not establish the ws connection')
    }

    let timeoutId;

    const onMeJoinedData = await new Promise((resolve, reject) => {
        const url = `wss://www.whiteboard.team/board-hub?uid=${guid}&id=${negotiationData.connectionToken}`
        const ws = new WebSocket(url)
        ws.onopen = async () => {
            console.log('socket connection (3-party whiteboard) is established')
            ws.send('{"protocol":"json","version":1}')
            ws.send(`{"arguments":[null,"${nimaWbId}","${clientId}",{"board":{},"participant":{"id":"${guid}"},"width":732,"height":751}],"invocationId":"0","target":"joinBoard","type":1}`)

            timeoutId = setTimeout(() => {
                reject('timeout')
            }, 15000)
        }

        const handleMessage = (msg) => {
            // eslint-disable-next-line no-control-regex
            const safeMessage = msg?.replaceAll(/\u001E/g, '')  // they are adding record separator (RS) character at the end of the messages...
            const messageData = JSON.parse(safeMessage);
            if (messageData?.type === 1 && messageData?.target === 'onMeJoined') {

                const boardId = messageData?.arguments[0]?.boardId;
                const memberList = messageData?.arguments[0]?.members;
                if (!Array.isArray(memberList)) {
                    reject('member list is not an array')
                } else {
                    resolve({ memberList, boardId })
                }
            } else if (messageData?.target === 'onClientError') {
                reject('client error')
            }
        }

        ws.onerror = (err) => {
            console.error('ws error', err)
        }
        ws.onmessage = (msg) => {
            clearTimeout(timeoutId)
            try {
                // eslint-disable-next-line no-control-regex
                const messages = msg?.data?.split(/\u001E/g)
                messages.filter(msg => msg !== '').forEach(msg => handleMessage(msg))
            } catch (err) {
                console.error('error while parsing message', err)
            }
        }
        ws.onclose = () => {
            console.log('socket connection (3-party whiteboard) is closed')
            reject('socket connection is closed')
        }
    })

    const fetchedMembers = []
    const { memberList= [], boardId= '' } = onMeJoinedData;
    for (const nimaMember of memberList) {
        try {
            const memberData = await getNimaUserByGuid(nimaMember.uid)
            if (memberData) {
                memberData.uid = memberData.guid;
                fetchedMembers.push(memberData)
            }
        } catch (err) {
            console.error(err)
        }
    }

    return {memberList: fetchedMembers, boardId};
}

export async function mapComments(canvas, commentShapes, { addedUsersWithGuid }, config = {}) {
    const { nimaWbId, guid } = config;

    const commentData = await fetch(`https://www.whiteboard.team/api/boards/${nimaWbId}/comments/?Uid=${guid}`, {
        'headers': {
            'accept': 'application/json, text/plain, */*',
        },
    }).then(res => {
        return res.json()
    }).catch(err => {
        console.error('an error has occurred while getting whiteboard team data', err)
    })

    if (!commentData) {
        return;
    }

    const comments = commentShapes.map(comment => {
        const commentThread = commentData.find(data => data.threadId === comment.threadId)
        if (!commentThread) {
            return null;
        }


        return {
            position: {
                x: comment.x,
                y: comment.y
            },
            color: getCommentColorFromNimaComment(comment.color),
            pageId: config?.activePageId,
            resolved: comment.resolved,
            threads: commentThread.comments.map(thread => {
                const mappedMessage = mapCommentMessage(thread.message, addedUsersWithGuid);

                const mappedThread = {
                    message: mappedMessage.message,
                    createdDate: thread.createdDate,
                    username: 'umitde296@gmail.com'
                }

                if (mappedMessage?.taggedUsers?.length) {
                    mappedThread.taggedUsers = mappedMessage.taggedUsers
                }
                return mappedThread;
            })
        }
    })

    return comments;
}


/**
 * Process the shapes data and add them to the canvas
 * @param canvas
 * @param options
 * @returns {Promise<Awaited<boolean>>}
 */
export async function fetchAndProcessShapeData(canvas, options = {}) {
    const { nimaWbId, guid } = options

    const dataUrl = `https://www.whiteboard.team/api/boards/${nimaWbId}/commands?Uid=${guid}`

    const data = await fetch(dataUrl).then(res => {
        return res.json()
    }).catch(err => {
        console.error('an error has occurred while getting third-party whiteboard data', err)
        return null;
    })

    if (!data) {
        return Promise.reject("couldn't fetch the shapes data")
    }


    const objectMap = new Map()
    const filteredData = filterCommands(data)
    const objStack = []

    handleCommandLoop(filteredData, objectMap, objStack)
    const allShapes = Array.from(objectMap.values())
    const comments = allShapes.filter(obj => obj.cmd === SHAPES.COMMENT)
    const regularShapes = allShapes.filter(obj => obj.cmd !== SHAPES.COMMENT)
    const imageResources = new Set();
    allShapes.filter(obj => obj.cmd === SHAPES.IMAGE).forEach((img) => {
        imageResources.add(img.resource)
    });

    return Promise.resolve({
        objectMap,
        objStack,
        imageResources,
        comments,
        shapes: regularShapes,
    })
}

async function addShapesToCanvas(canvas, objectMap, objStack, uploadedImages, options = {}) {
    try {
        console.log('adding shapes to canvas')
        const createdShapes = await createFromMap(canvas, objectMap, objStack, uploadedImages, options)

        const thirdPartyShapes = Array.from(objectMap.values()).filter(obj => obj.cmd !== SHAPES.COMMENT)
        // handle attaching objects to frames
        const frames = thirdPartyShapes.filter((obj) => obj.cmd === SHAPES.FRAME && obj?.children?.length)
        const canvasObjects = canvas.getObjects()
        if (frames.length) attachThirdPartyShapesToFrames(canvasObjects, frames)


        // handle attaching lines
        const lines = thirdPartyShapes.filter((obj) => obj.cmd === SHAPES.LINE)
        if (lines.length) attachThirdPartyLines(canvasObjects, lines)

        // clear the map for memory
        objectMap.clear()
        objStack.length = 0;

        return Promise.resolve(createdShapes)
    } catch (err) {
        return Promise.reject(err)
    }
}


/**
 * Loads the fonts
 * @returns {Promise<void>}
 */
async function loadFonts() {
    try {
        const fontPromises = THIRD_PARTY_FONTS.filter(font => font.url).map(font => {
            return new FontFace(font.name, `url(${font.url})`).load()
        })

        const fontLoadStats = await Promise.allSettled(fontPromises)
        if (fontLoadStats.some(stat => stat.status === 'rejected')) {
            console.error('error while loading some fonts', fontLoadStats.filter(stat => stat.status === 'rejected'))
        } else {
            console.log('all third party fonts are loaded')
        }
    } catch (err) {
        console.warn("couldn't load the fonts", err)
    }
    return Promise.resolve()
}

/**
 * Wait for the images to be uploaded
 * Listens to the imagesUploaded event
 * @param socket
 * @param {Set} images images to be waited
 * @param canvas canvas instance
 * @param {string} wbSlugId
 * @param {string} boardId nima board's id
 * @param {object} returnData It will be passed to the recursive functions, after all the attempts are done, this object will be returned
 * @param {number} attempt in which attempt we are -- starts from 0
 * @returns {Promise<unknown>}
 */
async function waitImagesToBeUploaded(socket, images, canvas, wbSlugId, boardId, returnData = {}, attempt = 0) {
    if (!images?.size) {
        return Promise.reject('no images to be uploaded')
    }

    let importImagesResponse;
    // if this function called in this function, that means we need to try to upload failed images
    if (attempt > 0) {
        try {
            await wait(3000)
            importImagesResponse = await importImages(Array.from(images), wbSlugId, boardId)
            const canvasObjects = canvas.getObjects()
            for (const image of importImagesResponse.images) {
                const imageInstances = canvasObjects.filter(obj => obj.resource === image.resourceId)
                for (const imageInstance of imageInstances) {
                    imageInstance.imageData = image.imageData
                }
            }
        } catch (err) {
            console.error(`error while trying to import again (atttempt: ${attempt}: ${err}`)
        }

    }

    const uploadListenerResponse = await new Promise((resolve) => {
        const imagesUploadedListener = (data) => {
            resolve(data)
        }
        socket.on('imagesUploaded_migration', imagesUploadedListener)
    });
    if (attempt === 0) {
        returnData = uploadListenerResponse
    } else if (uploadListenerResponse?.imagesUploaded) {  // if this retry for uploading images, and some of the images are uploaded
        // handle the arrays of the status of the images
        returnData.filesUploaded.push(...uploadListenerResponse.filesUploaded)
        returnData.filesNotUploaded.push(...uploadListenerResponse.filesNotUploaded)
        returnData.filesNotDownloaded.push(...uploadListenerResponse.filesNotDownloaded)

        // then search the successfully uploaded file in not uploaded arrays and delete them since we moved them to the files uploaded array
        for (const uploadedFile of uploadListenerResponse.filesUploaded) {
            const foundIndexInNotUploadedArray = returnData?.filesNotUploaded.indexOf(uploadedFile)
            const foundIndexInNotDownloadedArray = returnData?.filesNotDownloaded.indexOf(uploadedFile)
            if (foundIndexInNotUploadedArray !== -1) {
                returnData.filesNotUploaded.splice(foundIndexInNotUploadedArray, 1)
            }
            if (foundIndexInNotDownloadedArray !== -1) {
                returnData.filesNotDownloaded.splice(foundIndexInNotUploadedArray, 1)
            }
        }
    }


    // if some of the images are not uploaded, and we can still attempt to retry, then retry to upload failed images
    if ((returnData?.filesNotUploaded?.length || returnData?.filesNotDownloaded?.length) && attempt < 2) {
        return await waitImagesToBeUploaded(
            socket,
            new Set([...returnData.filesNotUploaded, ...returnData.filesNotDownloaded]), // images that we will try to upload again
            canvas,
            wbSlugId,
            boardId,
            returnData,
            attempt + 1  // here increase the attempt
        )
    }
    // if we don't have any not uploaded images or we exceed the maximum number of attempts
    return returnData
}


export async function importWhiteboardTeamData(canvas, config = {}) {
    const {
        wbSlugId,
        socket,
        nimaWbId,
        guid,
    } = config;

    if (!nimaWbId || !guid) {
        return Promise.reject('nima board ID and User id are required')
    }

    await loadFonts()

    const [shapePromise, memberListPromise] = await Promise.allSettled([
        fetchAndProcessShapeData(canvas, config),
        getMemberList(config)
    ])

    if (memberListPromise.status === 'rejected') {
        return Promise.reject(memberListPromise.reason)
    }
    if (shapePromise.status !== 'fulfilled') {
        return Promise.reject("couldn't fetch the shapes")
    }

    const { objectMap, objStack, imageResources, comments,  } = shapePromise.value;
    const { memberList, boardId } = memberListPromise.value;

    try {
        const importImagesResponse =
            imageResources?.size ? await importImages(Array.from(imageResources), wbSlugId, boardId) : { images: [] }
        const shapes = await addShapesToCanvas(canvas, objectMap, objStack, importImagesResponse?.images, config).then((shapes) => {
            console.log(`${shapes?.length} shapes are added to the canvas`)
            return shapes
        }).catch(err => {
            console.error('error while adding shapes to canvas', err)
            return null;
        })

        const [shapePromise, membersAndCommentPromise] = await Promise.allSettled([
            _handleEmittingShapes(canvas, shapes, socket, imageResources, config, boardId),
            _handleImportingMembersAndComments(canvas, memberList, comments, config)
        ])
        return [shapePromise.status === 'fulfilled', membersAndCommentPromise.status === 'fulfilled', false]
    } catch (err) {
        return [false, false, err]
    }
}

async function _handleEmittingShapes(canvas, shapes, socket, imageResources, config, boardId) {
    if (!shapes?.length) {
        return Promise.reject('shapes are missing')
    }

    const {
        wbSlugId,
        wbId,
        userId,
        activePageId
    } = config;

    const emitShapes = (addToHistory = true) => {
        canvas.fire('history-emit-data', {
            objects: shapes,
            action: 'created',
            createObjectOptions: {
                hideLog: true
            }
        })

        // add shapes to the history for supporting undo-redo
        if (addToHistory) {
            const objectJSONForHistory = []
            const canvasObjects = canvas.getObjects()

            for (const shape of shapes) {
                try {
                    const shapeInCanvas = canvasObjects.find(o => o.uuid === shape.uuid)
                    if (!shapeInCanvas) {
                        console.log('skipping', shape.uuid)
                        continue
                    }
                    objectJSONForHistory.push(
                        createObjectToBeEmitted(
                            wbId,
                            userId,
                            customToObject(shapeInCanvas, { shouldCopyDeeply: true }),
                            false,
                            shape.shapeType
                        )
                    )

                } catch (err) {
                    console.error('error while adding shape to the history', err, shape)
                }
            }

            try {
                store.dispatch({
                    type: 'history/addShapesToHistory',
                    payload: {
                        shapes: objectJSONForHistory,
                        pageId: activePageId
                    }
                })
            } catch (err) {
                console.error('error while adding shapes to the history', err)
            }
        }
    }

    if (!imageResources.size) {
        emitShapes()
        return Promise.resolve(true)
    }

    try {
        const data = await waitImagesToBeUploaded(socket, imageResources, canvas, wbSlugId, boardId)
        // if some images are not uploaded, remove them from the shapes list to make sure we are not sending them to the backend
        if (data?.filesNotUploaded?.length || data?.filesNotDownloaded?.length) {
            const failedToUploadImages = []
            try {
                failedToUploadImages.push(...data.filesNotUploaded);
                failedToUploadImages.push(...data.filesNotDownloaded);
            } catch (err) {
                console.error(err)
            }
            console.error('some images are not uploaded', failedToUploadImages)
            const notUploadedImageInstances = shapes.filter(obj => obj.type === 'optimizedImage' && failedToUploadImages.includes(obj.resource))
            removeImagesFromList(canvas, shapes, notUploadedImageInstances)
        }

        // load the images chunk by chunk
        const chunks = divideListToChunks(shapes.filter(o => o.type === 'optimizedImage'), 100)
        const failedToLoadImages = []
        // load the images chunk by chunk
        for (const chunk of chunks) {

            // create chunk promises
            const promises = chunk.map(img => {
                // eslint-disable-next-line no-async-promise-executor
                return new Promise(async(resolve) => {
                    const response = {
                        uuid: img.uuid,
                        instance: img,
                        isError: false,
                        image: null,
                    }
                    try {
                        response.image = await loadImage(
                            img?.imageData?.xs.url,
                            { crossOrigin: 'anonymous' }
                        )
                        resolve(response)
                    }
                    catch (err) {
                        // if loading fails, set the error flag to true
                        // we still need the instance to remove it from the canvas
                        response.isError = true
                        resolve(response)
                    }
                })

            })
            const chunkPromiseResults = await Promise.allSettled(promises)

            for (const chunkPromise of chunkPromiseResults.filter(p => p.status === 'fulfilled')) {
                // if the image is not loaded, add it to the failed list
                if (chunkPromise.value.isError) {
                    failedToLoadImages.push(chunkPromise.value.instance)
                    continue;
                }

                // set the image to the instance
                chunkPromise.value.instance.setDefaultElement(chunkPromise.value.image)
            }
        }

        canvas.requestRenderAll()

        // if there are failed images, remove them from the canvas and also from the shapes list
        if (failedToLoadImages.length) {
            console.log(`${failedToLoadImages.length} images failed to load`)
            removeImagesFromList(canvas, shapes, failedToLoadImages)
        }
    } catch (err) {
        console.error('error while handling emitting shapes', err)
        return Promise.reject(err)
    }

    emitShapes()
    return Promise.resolve(true)
}

async function _handleImportingMembersAndComments(canvas, members, comments, config) {
    const {
        wbSlugId,
        userId,
        shouldHideComments,
        shouldHideResolvedComments
    } = config;
    const addedMembers = await importMembers(members, wbSlugId)

    if (comments?.length) {
        const addedUsersWithGuid = {}
        for (const member of members) {
            const addedMember = addedMembers.find(addedMember => addedMember.email === member.email)
            if (!addedMember) {
                continue;
            }
            addedUsersWithGuid[member.guid] = addedMember
        }


        const mappedComments = await mapComments(canvas, comments, {members: addedMembers, addedUsersWithGuid}, config)
        const addedCommentResponse = await importComments(mappedComments, wbSlugId)
        renderAllComments({
            canvas,
            comments: addedCommentResponse.commentInitData,
            setSelectedCommentIcon: config.setSelectedCommentIcon,
            userId,
            shouldHideComments,
            shouldHideResolvedComments
        })
    }

    return true;
}