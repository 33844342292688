/**
 * Gets run when ctrl/cmd + a pressed.
 * @override
 */
export default function selectAll() {
    // override
    // call on shape changed to render this object again
    this.onShapeChanged()
    this.selectionStart = 0;
    this.selectionEnd = this._text.length;
    this._fireSelectionChanged();
    this._updateTextarea();
    return this;
}