import { fabric} from 'fabric';

export class EventEmitter {
    _removeEventListener(eventName, handler) {
        if (!this.__eventListeners[eventName]) {
            return;
        }
        var eventListener = this.__eventListeners[eventName];
        if (handler) {
            eventListener[eventListener.indexOf(handler)] = false;
        }
        else {
            fabric.util.array.fill(eventListener, false);
        }
    }
  
    on(eventName, handler) {
        if (!this.__eventListeners) {
            this.__eventListeners = { };
        }
        // one object with key/value pairs was passed
        if (arguments.length === 1) {
            for (var prop in eventName) {
                this.on(prop, eventName[prop]);
            }
        }
        else {
            if (!this.__eventListeners[eventName]) {
                this.__eventListeners[eventName] = [];
            }
            this.__eventListeners[eventName].push(handler);
        }
        return this;
    }
  
    _once(eventName, handler) {
        var _handler = function () {
            handler.apply(this, arguments);
            this.off(eventName, _handler);
        }.bind(this);
        this.on(eventName, _handler);
    }
  
    once(eventName, handler) {
        // one object with key/value pairs was passed
        if (arguments.length === 1) {
            for (var prop in eventName) {
                this._once.call(this, prop, eventName[prop]);
            }
        }
        else {
            this._once.call(this, eventName, handler);
        }
        return this;
    }
  
    off(eventName, handler) {
        if (!this.__eventListeners) {
            return this;
        }
  
        // remove all key/value pairs (event name -> event handler)
        if (arguments.length === 0) {
            for (eventName in this.__eventListeners) {
                this._removeEventListener.call(this, eventName);
            }
        }
        // one object with key/value pairs was passed
        else if (arguments.length === 1 && typeof arguments[0] === 'object') {
            for (var prop in eventName) {
                this._removeEventListener.call(this, prop, eventName[prop]);
            }
        }
        else {
            this._removeEventListener.call(this, eventName, handler);
        }
        return this;
    }
    
    fire(eventName, options) {
        if (!this.__eventListeners) {
            return this;
        }
  
        var listenersForEvent = this.__eventListeners[eventName];
        if (!listenersForEvent) {
            return this;
        }
  
        for (var i = 0, len = listenersForEvent.length; i < len; i++) {
            listenersForEvent[i] && listenersForEvent[i].call(this, options || { });
        }
        this.__eventListeners[eventName] = listenersForEvent.filter(function(value) {
            return value !== false;
        });
        return this;
    }
}
  
const eventEmitter = new EventEmitter();

export default eventEmitter;