import { useEffect, useState } from 'react'
import store from '../redux/Store';
import { getLocalStorage, setLocalStorage } from '../services/CookieService';

/**
 * Custom hook for hiding resolved comments on left drawer's comment tab.
 * @param canvas
 * @param defaultValue
 */
const useHideResolvedComments = (canvas, boardOptions, whiteBoardSlugId) => {
    const [hideResolvedComments, setHideResolvedComments] = useState(false);

    useEffect(() => {
        if (canvas) {
            const resolvedComments = canvas.getObjects().filter(comment => 
                comment.type === 'comment' && comment.resolved);
            for (const comment of resolvedComments) {
                if (hideResolvedComments) {
                    comment.hideComment();
                    canvas.fire('comment-hide', {comment: comment});
                } else {
                    comment.showComment();
                }
            }
            if (getLocalStorage('bboard_options')) {
                const options = JSON.parse(getLocalStorage('bboard_options'));
                options.hideResolvedComments = { [whiteBoardSlugId]: boardOptions.current.hideResolvedComments[whiteBoardSlugId] ?? false };
                setLocalStorage('bboard_options', JSON.stringify(options));
            }
            store.dispatch({
                type: 'board/changeHideResolvedComments',
                payload: hideResolvedComments
            });
            canvas.renderAll();
        }
    }, [canvas, hideResolvedComments, whiteBoardSlugId]);

    useEffect(() => {
        if (boardOptions?.current?.hideResolvedComments?.[whiteBoardSlugId]) setHideResolvedComments(boardOptions.current.hideResolvedComments[whiteBoardSlugId] ?? false)
    }, [whiteBoardSlugId, boardOptions])

    return [
        hideResolvedComments,
        (value) => {
            setHideResolvedComments(value)
            if (boardOptions?.current?.hideResolvedComments?.[whiteBoardSlugId]) {
                boardOptions.current.hideResolvedComments[whiteBoardSlugId] = value
            }
        }
    ]
}

export default useHideResolvedComments;