import React, {forwardRef, useState} from 'react';
import PropTypes from 'prop-types';
import Eye from '../../../assets/images/eye.svg';
import EyeClose from '../../../assets/images/eye-close.svg';
const PasswordInput = forwardRef(( { value, name, className, onChange, onFocus, onBlur, minLength }, ref) => {

    const [showPassword, setShowPassword] = useState(false);


    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div
            style={{ position: 'relative', display: 'inline-block', width: '100%' }}
        >
            <input
                ref={ref}
                id='password-input'
                autoComplete="new-password"
                className={className}
                data-testid={`password-input-${name}`}
                maxLength="100"
                minLength={minLength}
                name={name}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={onChange}
                required=""
                type={showPassword ? 'text' : 'password'}
                value={value}
            />
            <img
                className='toggle-password-img'
                alt="Toggle Password Visibility"
                onClick={toggleShowPassword}
                src={showPassword ? Eye : EyeClose}
                style={{
                    position: 'absolute',
                    top: '50%',
                    right: '5px',
                    transform:  'translate(-50%, -50%)',
                    cursor: 'pointer',
                    width: '24px',
                    height: '24px'
                }}
            />
            
        </div>
    );
});

PasswordInput.propTypes = {
    value: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    minLength: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
}

export default PasswordInput;
