import { fabric } from 'fabric';
import { v4 as uuidv4 } from 'uuid';
import OptimizedImage from '../../customClasses/image/OptimizedImage';
import { CurvedLine } from '../../hooks/UseCurvedLine';
import store from '../../redux/Store';
import { createObjectToBeEmitted } from '../CommonFunctions';
import { 
    FLOWCHART_ITEM_TYPE, 
    SHAPE_DEFAULTS,
    FLOWCHART_ROLE_DEVICE_PADDING,
    FLOWCHART_PADDING_X,
    FLOWCHART_PADDING_Y,
    FLOWCHART_FRAME_PADDING,
    FLOWCHART_SUB_FEATURE_PADDING_X,
    FLOWCHART_SUB_FEATURE_PADDING_Y,
    FLOWCHART_FEATURE_STROKE_WIDTH,
    EMITTER_TYPES,
} from '../Constant';
import { calculateObjectCenterPoint, calculateObjectPosition, customToObject, findEmptyAreaForObject } from '../FabricMethods';
import createFrameWithOptions from '../frame/CreateFrameWithOptions';
import { attachToFrame } from '../frame/FrameMethods';
import eventEmitter from '../EventEmitter';
import { getDefaultLineType } from '../lines/GetDefaultLineType';
import {getFlowchartData} from '../../services/FlowchartService';


/**
 * Calculates dimension of all flowcharts (all roles and devices) .
 * @param {Array} allFlowcharts
 * @param options
 * @returns {{width: number, height: number, singleFlowchartDimensions: []} dimension of all flowcharts.
 */
const getAllFlowchartDimensions = (allFlowcharts, options = {}) => {
    let totalWidth = 0;
    let totalHeight = 0;
    const flowchartDimensionCache = [];

    for (const flowchart of allFlowcharts) {
        // get single user flow's with and height
        const { width, height } = getWidthHeightOfFlowchart(flowchart.role_device_flowchart);

        // add padding of the flowchart's frame to the with and height
        const actualWidth = width + FLOWCHART_FRAME_PADDING * 2;
        const actualHeight = height + FLOWCHART_FRAME_PADDING * 2;

        totalWidth += actualWidth;

        if (actualHeight > totalHeight) {
            totalHeight = actualHeight;
        }

        // save single user flow's with and height to use later
        flowchartDimensionCache.push({
            width,
            height
        });
    }

    // if we need to get actual width of all flowcharts, include gap between flowcharts
    if (options.includeGap) {
        totalWidth += (allFlowcharts.length - 1) * FLOWCHART_ROLE_DEVICE_PADDING;
    }
    return {
        width: totalWidth,
        height: totalHeight,
        singleFlowchartDimensions: flowchartDimensionCache
    }
}

/**
 * Returns the width and height of the flowchart based on the flowchart array.
 * @param {object} arrayData - Flowchart data.
 */
const getWidthHeightOfFlowchart = (arrayData) => {
    let totalWidth = 0;
    let totalHeight = 0;

    for (const columnIndex in arrayData) {
        const column = arrayData[columnIndex];
        let columnHeight = 0;
        let maxflowchartWidth = 0;
        for (const feature of column) {
            const flowChartWidth = parseInt(feature.heroImages[0].hero_image_attributes.width);
            const flowChartHeight = parseInt(feature.heroImages[0].hero_image_attributes.height);

            let actualFlowchartWidth = flowChartWidth;
            let actualFlowchartHeight = flowChartHeight;

            const isActivatedSubFeatures = feature?.subFeatures?.length ? true : false;
            
            if (isActivatedSubFeatures) {
                const mappedSubFeatures = mapSubFeatures(feature.subFeatures);
                const subFeatureDimension = getSubFeatureDimensionWithPadding(
                    getDimensionsOfSubFeatures(
                        mappedSubFeatures,
                        {
                            getDimensionWithParent: true,
                            parentFlowchartItem: {
                                width: flowChartWidth,
                                height: flowChartHeight,
                            }
                        }
                    )
                );
                actualFlowchartWidth = subFeatureDimension.width;
                actualFlowchartHeight = subFeatureDimension.height;
            } else {
                actualFlowchartWidth += FLOWCHART_SUB_FEATURE_PADDING_X * 2;
                actualFlowchartHeight += FLOWCHART_SUB_FEATURE_PADDING_Y * 2;
            }

            if (actualFlowchartWidth > maxflowchartWidth) {
                maxflowchartWidth = actualFlowchartWidth;
            }
            columnHeight += actualFlowchartHeight;
        }
        columnHeight += (column.length - 1) * FLOWCHART_PADDING_Y;
        if (columnHeight > totalHeight) {
            totalHeight = columnHeight;
        }
        totalWidth += maxflowchartWidth;
    }
    // add paddings to the width
    totalWidth += (arrayData.length - 1) * FLOWCHART_PADDING_X;

    return {
        width: totalWidth,
        height: totalHeight,
    }
}

/**
 * Returns the width and height of the flowchart based on the flowchart array.
 * @param {object} arrayData - Flowchart data.
 * @param options
 */
const getDimensionsOfSubFeatures = (arrayData, options = {}) => {
    let totalWidth = 0;
    let totalHeight = 0;

    for (const columnIndex in arrayData) {
        const column = arrayData[columnIndex];
        let columnHeight = 0;
        let maxflowchartWidth = 0;
        for (const subFeature of column) {
            const flowChartWidth = parseInt(subFeature.heroImages[0].hero_image_attributes.width);
            const flowChartHeight = parseInt(subFeature.heroImages[0].hero_image_attributes.height);
            if (flowChartWidth > maxflowchartWidth) {
                maxflowchartWidth = flowChartWidth;
            }
            columnHeight += flowChartHeight;
        }
        columnHeight += (column.length - 1) * FLOWCHART_SUB_FEATURE_PADDING_Y;
        if (columnHeight > totalHeight) {
            totalHeight = columnHeight;
        }
        totalWidth += maxflowchartWidth;
    }
    // add paddings to the width
    totalWidth += (arrayData.length - 1) * FLOWCHART_SUB_FEATURE_PADDING_X;

    if (options.getDimensionWithParent) {
        const dimension = {
            width: totalWidth + options?.parentFlowchartItem?.width + FLOWCHART_SUB_FEATURE_PADDING_X,
            height: totalHeight,
        }
        if (dimension.height < options?.parentFlowchartItem?.height) {
            dimension.height = options?.parentFlowchartItem?.height;
        }
        return dimension;
    }

    return {
        width: totalWidth,
        height: totalHeight,
    }
}

/**
 * Returns the max width of the given column .
 * @param {Array} column - Column of flowchart array.
 * @returns {number} Width of the column.
 */
const getFlowchartColumnWidth = (column) => {
    let maxflowchartWidth = 0;
    for (const feature of column) {
        const flowChartWidth = parseInt(feature.heroImages[0].hero_image_attributes.width);
        const flowChartHeight = parseInt(feature.heroImages[0].hero_image_attributes.height);
        let actualWidth = flowChartWidth;

        if (feature?.subFeatures?.length) {
            const mappedSubFeatures = mapSubFeatures(feature.subFeatures);
            const subFeatureDimension = getSubFeatureDimensionWithPadding(
                getDimensionsOfSubFeatures(
                    mappedSubFeatures,
                    {
                        getDimensionWithParent: true,
                        parentFlowchartItem: {
                            width: flowChartWidth,
                            height: flowChartHeight,
                        }
                    }
                )
            );
            actualWidth = subFeatureDimension.width;
        }
        if (actualWidth > maxflowchartWidth) {
            maxflowchartWidth = actualWidth;
        }
    }
    return maxflowchartWidth;
}

/**
 * Returns the height of the column for features.
 * @param {Array} column - Sub feature column.
 * @returns {number} Height of the column.
 */
const getFlowchartColumnHeight = (column) => {
    return column.reduce((acc, feature, index) => {
        const flowChartWidth = parseInt(feature.heroImages[0].hero_image_attributes.width);
        const flowChartHeight = parseInt(feature.heroImages[0].hero_image_attributes.height);

        let actualHeight = flowChartHeight;

        if (feature?.subFeatures?.length) {
            const mappedSubFeatures = mapSubFeatures(feature.subFeatures);
            const subFeatureDimension = getSubFeatureDimensionWithPadding(
                getDimensionsOfSubFeatures(
                    mappedSubFeatures,
                    {
                        getDimensionWithParent: true,
                        parentFlowchartItem: {
                            width: flowChartWidth,
                            height: flowChartHeight,
                        }
                    }
                )
            ); 
            actualHeight = subFeatureDimension.height;
        } else {
            actualHeight += FLOWCHART_SUB_FEATURE_PADDING_Y * 2;
        }

        let additionalPadding = 0;
        if (index > 0 && index < column.length) {
            additionalPadding = FLOWCHART_PADDING_Y;
        }
        return acc + actualHeight + additionalPadding;
    }, 0);
}


/**
 * Returns the height of the column for sub features.
 * @param {Array} column - Sub feature column.
 * @returns {number} Height of the column.
 */
const getFlowchartSubFeaturesColumnHeight = (column) => {
    return column.reduce((acc, feature, index) => {
        let additionalPadding = 0;
        if (index > 0 && index < column.length) {
            additionalPadding = FLOWCHART_SUB_FEATURE_PADDING_Y;
        }
        return acc + parseInt(feature.heroImages[0].hero_image_attributes.height) + additionalPadding;
    }, 0);
}

const addLineForFlowchart = (canvas, flowChart, hotspot, options = {}) => {
    const canvasObjects = canvas.getObjects();
    const startFeature = canvasObjects?.find(obj =>
        obj.flowchartProps?.flowchartUniqCode === flowChart.uniq_code
        && obj.flowchartProps?.flowchartName === options.flowchartName
        && obj.flowchartProps?.processId === options.processId
    );
    const endFeature = canvasObjects?.find(obj =>
        obj.flowchartProps?.flowchartUniqCode === hotspot.targetFeature_uniqCode
        && obj.flowchartProps?.flowchartName === options.flowchartName
        && obj.flowchartProps?.processId === options.processId
    );

    if (!startFeature || !endFeature) {
        return;
    }
    
    // in order to calculate the position of the hotspot,
    // generate a rect object with the hotspot data, and use center point of it as the start point
    const hotspotRect = {
        left: startFeature.left + parseInt(hotspot.position.originX),
        top: startFeature.top + parseInt(hotspot.position.originY),
        width: parseInt(hotspot.position.width),
        height: parseInt(hotspot.position.height),
    }
    
    const startPoint = {
        x: hotspotRect.left + (hotspotRect.width / 2),
        y: hotspotRect.top + (hotspotRect.height / 2),
    } 

    const endFeatureStrokeWidth = endFeature.strokeWidth || FLOWCHART_FEATURE_STROKE_WIDTH;
    const additionalPadding = options.isPrevious ? endFeature.width + endFeatureStrokeWidth : 0;
    const endPoint = {
        x: endFeature.left + additionalPadding,
        y: endFeature.top + (endFeature.height / 2) + endFeatureStrokeWidth / 2,
    }

    const startFeatureCoordinates = calculateObjectPosition(startFeature);

    const lineCentralPoints = []

    let gap = options.shouldAddGap ? 100 : 0;

    if (!options.isPrevious) gap = -gap;

    if (!options.isPrevious) {
        let actualColumnWidthFromStartFeature = 0;
        // if start feature has sub features, we need to calculate the actual width of the column
        // since this feature will be on the frame
        if (startFeature?.subFrame) {
            const startFeatureAttachedFrameCoordinates = calculateObjectPosition(startFeature.wiredFrame);
            actualColumnWidthFromStartFeature = startFeatureCoordinates.tl.x  - startFeatureAttachedFrameCoordinates.tl.x;
        }

        const endOFCurrentColumnX = (startFeatureCoordinates.tl.x + options.currentColumnWidth) - actualColumnWidthFromStartFeature;
        const xPointForNextFeature = endOFCurrentColumnX + (FLOWCHART_PADDING_X / 2) + gap;
        lineCentralPoints.push(
            {
                x: xPointForNextFeature,
                y: startPoint.y
            },
            {
                x: xPointForNextFeature,
                y: endPoint.y
            }
        );
    } else {
        const xPointForPreviousFeature = (startFeatureCoordinates.tl.x - FLOWCHART_PADDING_X / 2) + gap;
        lineCentralPoints.push(
            {
                x: xPointForPreviousFeature,
                y: startPoint.y
            },
            {
                x: xPointForPreviousFeature,
                y: endPoint.y
            }
        );
    }

    const line = new CurvedLine([
        startPoint,
        ...lineCentralPoints,
        endPoint,
    ], {
        originY: 'center',
        originX: 'center',
        strokeUniform: true,
        arrowEnabled: true,
        arrowRight: true,
        arrowLeft: false,
        strokeWidth: 5,
        stroke: options.isPrevious ? 'red' : '#000',
        lineType: getDefaultLineType(),
        curvedLineVersion: 'v2'
    });

    if (options.isPrevious) {
        line.set('strokeDashArray', [5, 5]);
    }

    line.uuid = flowchartUUIDGenerator(canvas);
    if (!startFeature.lines || !Array.isArray(startFeature.lines)) {
        startFeature.lines = [];
    }
    if (!endFeature.lines || !Array.isArray(endFeature.lines)) {
        endFeature.lines = [];
    }
    startFeature.lines.push(line.uuid);
    endFeature.lines.push(line.uuid);
    line.leftPolygon = startFeature;
    line.rightPolygon = endFeature;
    const leftCoordinates = calculateObjectCenterPoint(startFeature);
    const rightCoordinates = calculateObjectCenterPoint(endFeature);
    line.leftDeltaX = startPoint.x - leftCoordinates.x;
    line.leftDeltaY = startPoint.y - leftCoordinates.y;
    line.rightDeltaX = endPoint.x - rightCoordinates.x;
    line.rightDeltaY = endPoint.y - rightCoordinates.y;
    canvas.add(line);

    setShapeProps(canvas, line, {
        type: FLOWCHART_ITEM_TYPE.HOTSPOT,
        flowchartName: options.flowchartName,
        targetFeatureUniqCode: hotspot.targetFeature_uniqCode,
        apeirosHotspotID: hotspot.id,
        hotspotID: hotspot.build_card_hotspot_id,
        position: hotspot.position,
        processId: options.processId
    });

    return line;
}

/**
 * @param canvas
 * @param flowChartArray
 * @param frame
 * @param options
 */
function generateNewLinesForFlowchart(canvas, flowChartArray, frame, options = {}) {
    const columnLineData = {}
    const generatedLines = [];

    for (const columnIndex in flowChartArray) {
        const parsedColumnIndex = parseInt(columnIndex);
        columnLineData[parsedColumnIndex] = {
            prev: false,
            next: false,
        } 
        const column = flowChartArray[parsedColumnIndex]; 
        for (const flowchartIndex in column) {
            const flowChart = column[flowchartIndex];

            if (Array.isArray(flowChart?.heroImages) && !!flowChart.heroImages[0]) {
                for (const hotspot of flowChart.heroImages[0].hotspots) {
                    const nextFeatureColumnIndex = flowChartArray.findIndex(column => column.find(flowChart => flowChart.uniq_code === hotspot.targetFeature_uniqCode));
    
                    if (nextFeatureColumnIndex - 1 === parsedColumnIndex) {
                        columnLineData[parsedColumnIndex].next = true;
                    }
                    if (nextFeatureColumnIndex + 1 === parsedColumnIndex) {
                        columnLineData[parsedColumnIndex].prev = true;
                    }
                }
            }
        }
    }

    for (const columnIndex in flowChartArray) {
        const parsedColumnIndex = parseInt(columnIndex);
        const column = flowChartArray[parsedColumnIndex]; 
        const thisColumnWidth = getFlowchartColumnWidth(column);

        for (const flowchartIndex in column) {
            const flowChart = column[flowchartIndex];

            let shouldAddGap = 
                parsedColumnIndex < flowChartArray.length - 1 &&
                columnLineData[parsedColumnIndex]?.next &&
                columnLineData[parsedColumnIndex + 1]?.prev;

            if (Array.isArray(flowChart?.heroImages) && !!flowChart.heroImages[0]) {
                for (const hotspot of flowChart.heroImages[0].hotspots) {
                    const generalOptions = {
                        currentColumnWidth: thisColumnWidth,
                        shouldAddGap,
                    }
    
                    const nextFeatureColumnIndex = flowChartArray.findIndex(column => column.find(flowChart => flowChart.uniq_code === hotspot.targetFeature_uniqCode));
                    let isPrevious = false, shouldAddLine = false;
                    if (nextFeatureColumnIndex - 1 === parsedColumnIndex || nextFeatureColumnIndex + 1 === parsedColumnIndex) {
                        shouldAddLine = true;
                    }
                    // for previous
                    if (nextFeatureColumnIndex + 1 === parsedColumnIndex) {
                        isPrevious = true;
                    }
                    if (shouldAddLine) {
                        const generatedLine = addLineForFlowchart(
                            canvas, 
                            flowChart, 
                            hotspot, 
                            {
                                isPrevious,
                                ...generalOptions,
                                flowchartName: options.flowchartName,
                                processId: options.processId
                            }
                        );
                        if (generatedLine) {
                            attachFlowchartItemsToFrame(generatedLine, frame);
                            generatedLines.push(generatedLine);
                        }
                        shouldAddLine = false;
                    }
                }
            }
        }
    }
    return generatedLines;
}

/**
 * @param canvas
 * @param options
 */
export function generateFrameForFlowchart(canvas, options) {
    const additionalProps = {}
    options.fill && (additionalProps.fill = options.fill);
    options.title && (additionalProps.title = options.title);

    const frame = createFrameWithOptions(canvas, {
        left: options.left,
        top: options.top,
        width: options.width,
        height: options.height,
        uuid: options?.uuid ? options.uuid : flowchartUUIDGenerator(canvas),
        ...additionalProps
    });

    canvas.renderAll();
    return frame;
}

/**
 * Generates flowchart on canvas.
 * @param {fabric.Canvas} canvas 
 * @param {object} data - Flowchart data.
 * @param dataArray
 * @param options
 */
export async function generateSingleFlowChart(canvas, dataArray, options = {}) {
    const allGeneratedFlowchartItems = [];

    const processId = options.processId || uuidv4();

    const startingLeft = options.startingLeft;
    const flowchartCenterY = options.startingTop;

    // add frame
    const featuresFrame = generateFrameForFlowchart(canvas, {
        left: startingLeft - FLOWCHART_FRAME_PADDING,
        top:  flowchartCenterY - options?.flowchartDimension?.height / 2 - FLOWCHART_FRAME_PADDING,
        width: options?.flowchartDimension?.width + FLOWCHART_FRAME_PADDING * 2,
        height: options?.flowchartDimension?.height + FLOWCHART_FRAME_PADDING * 2,
        title: options.frameName,
    });
    setShapeProps(canvas, featuresFrame, {
        type: FLOWCHART_ITEM_TYPE.FRAME,
        flowchartName: options.flowchartName,
        deviceName: options?.deviceName,
        processId
    });

    allGeneratedFlowchartItems.push(featuresFrame);

    let leftAligning = startingLeft;
    let maxFlowchartColumnTop;

    for (const column of dataArray) {
        let maxflowchartWidth = 0;

        const columnHeight = getFlowchartColumnHeight(column);
        const startingTop = flowchartCenterY - columnHeight / 2;
        let topAligning = startingTop;
    
        if (!maxFlowchartColumnTop) {
            maxFlowchartColumnTop = startingTop;
        }

        if (startingTop < maxFlowchartColumnTop) {
            maxFlowchartColumnTop = startingTop;
        }
    
        for (const flowchartIndex in column) {
            const flowchart = column[flowchartIndex];
            const isActivatedSubFeatures = flowchart?.subFeatures?.length ? true : false;
            let subFeatures = [];
        
            if (isActivatedSubFeatures) {
                const mappedSubFeatures = mapSubFeatures(flowchart.subFeatures);    
                subFeatures = [...mappedSubFeatures]
            }
        
            const flowChartWidth = parseInt(flowchart.heroImages[0].hero_image_attributes.width);
            const flowChartHeight = parseInt(flowchart.heroImages[0].hero_image_attributes.height);

            let actualFlowchartWidth = flowChartWidth + FLOWCHART_SUB_FEATURE_PADDING_X * 2;
            let actualFlowchartHeight = flowChartHeight + FLOWCHART_SUB_FEATURE_PADDING_Y * 2;

            const flowchartHeroImageData = flowchart.heroImages[0].optimizedImageData;
            const optimizedImageData = {
                full: {
                    url: flowchartHeroImageData.full
                },
                med: {
                    url: flowchartHeroImageData.med
                },
                sm: {
                    url: flowchartHeroImageData.sm
                },
                xs: {
                    url: flowchartHeroImageData.xs
                }
            }
            const flowchartItem = addFlowchartImage({
                left: leftAligning,
                top: topAligning,
                width: flowChartWidth,
                height: flowChartHeight,
                imageData: optimizedImageData,
                title: flowchart.title,
            })

            // if there is no sub features, add the flowchart item directly,
            // otherwise, add it after drawing subfeature frame
            if (!isActivatedSubFeatures) {
                const subFeatureFrame = generateFrameForFlowchart(canvas, {
                    width: flowChartWidth + FLOWCHART_SUB_FEATURE_PADDING_X * 2,
                    height: flowChartHeight + FLOWCHART_SUB_FEATURE_PADDING_Y * 2,
                    left: leftAligning,
                    top: topAligning,
                    fill: 'rgba(255, 255, 255, 1)',
                    title: flowchart.title,
                });

                setShapeProps(canvas, subFeatureFrame, {
                    type: FLOWCHART_ITEM_TYPE.SUB_FRAME,
                    flowchartName: options.flowchartName,
                    mainFeatureID: flowchart.build_card_feature_id,
                    mainFeatureUniqCode: flowchart.uniq_code,
                    processId
                }); 
                attachFlowchartItemsToFrame(subFeatureFrame, featuresFrame);

                allGeneratedFlowchartItems.push(subFeatureFrame);

                flowchartItem.left += FLOWCHART_SUB_FEATURE_PADDING_X;
                flowchartItem.top += FLOWCHART_SUB_FEATURE_PADDING_Y;

                canvas.add(flowchartItem)

                setShapeProps(canvas, flowchartItem, {
                    type: FLOWCHART_ITEM_TYPE.FEATURE,
                    flowchart,
                    flowchartName: options.flowchartName,
                    hasSubFeature: false,
                    processId
                });
                attachFlowchartItemsToFrame(flowchartItem, subFeatureFrame)
                allGeneratedFlowchartItems.push(flowchartItem);
            }


            // if there are sub features, add them
            if (isActivatedSubFeatures) {
                const generatedSubFeatureMap = addSubFeatures(canvas, {
                    subFeatures,
                    initialLeft: leftAligning + flowChartWidth,
                    initialTop: topAligning + (flowChartHeight / 2),
                    flowchartName: options.flowchartName,
                    mainFeatureID: flowchart.build_card_feature_id,
                    mainFeatureUniqCode: flowchart.uniq_code,
                    processId
                });
                // get dimension of whole subfeature
                const subFeatureDimension = getDimensionsOfSubFeatures(
                    subFeatures,
                    {
                        getDimensionWithParent: true,
                        parentFlowchartItem: {
                            width: flowChartWidth,
                            height: flowChartHeight,
                        }
                    }
                );

                const subFeaturePos = {
                    left: flowchartItem.left,
                    top: flowchartItem.top + (flowchartItem.height / 2) - (subFeatureDimension.height / 2),
                }
                // get the dimension for subfeature frame with padding
                const subFeatureDimensionWithPadding = getSubFeatureDimensionWithPadding({
                    ...subFeatureDimension,
                    left: subFeaturePos.left,
                    top: subFeaturePos.top,
                });
            
                // generate sub feature frame
                const subFeatureFrame = generateFrameForFlowchart(canvas, {
                    ...subFeatureDimensionWithPadding,
                    left: leftAligning,
                    top: topAligning,
                    fill: 'rgba(255, 255, 255, 1)',
                    title: flowchart.title,
                });
                setShapeProps(canvas, subFeatureFrame, {
                    type: FLOWCHART_ITEM_TYPE.SUB_FRAME,
                    flowchartName: options.flowchartName,
                    mainFeatureID: flowchart.build_card_feature_id,
                    mainFeatureUniqCode: flowchart.uniq_code,
                    processId,
                }); 
                attachFlowchartItemsToFrame(subFeatureFrame, featuresFrame);
                allGeneratedFlowchartItems.push(subFeatureFrame);

                const subFeatureFramePosition = {
                    left: subFeatureFrame.left,
                    top: subFeatureFrame.top
                }

                const subFeatureFrameBounding = {
                    ...subFeatureFramePosition,
                    width: subFeatureFrame.width,
                    height: subFeatureFrame.height,
                }

                const subFeatureItemsBounding = {
                    ...subFeatureDimension,
                    ...subFeaturePos,
                }

                // we need to center the flowchart item and subfeatures
                const flowchartItemNewPosition = centerFlowchartAndSubFeatures(
                    subFeatureFrameBounding,
                    subFeatureItemsBounding,
                    flowchartItem
                )
                flowchartItem.left = flowchartItemNewPosition.left;
                flowchartItem.top = flowchartItemNewPosition.top;

                // add flowchart item since we were waiting for the frame to be drawn
                canvas.add(flowchartItem)
                setShapeProps(canvas, flowchartItem, {
                    type: FLOWCHART_ITEM_TYPE.FEATURE,
                    flowchart,
                    flowchartName: options.flowchartName,
                    hasSubFeature: true,
                    processId
                });
                attachFlowchartItemsToFrame(flowchartItem, subFeatureFrame);
                flowchartItem.subFrame = subFeatureFrame;
                allGeneratedFlowchartItems.push(flowchartItem);

                // then add all subfeatures
                for (const subFeatureItem of generatedSubFeatureMap) {
                    const subFeatureItemNewPosition = centerFlowchartAndSubFeatures(
                        subFeatureFrameBounding,
                        subFeatureItemsBounding,
                        subFeatureItem
                    )
                    subFeatureItem.left = subFeatureItemNewPosition.left;
                    subFeatureItem.top = subFeatureItemNewPosition.top;
                    canvas.add(subFeatureItem);

                    attachFlowchartItemsToFrame(subFeatureItem, subFeatureFrame);
                    allGeneratedFlowchartItems.push(subFeatureItem);
                }

                // save the actual with to render other columns properly
                actualFlowchartWidth = subFeatureDimensionWithPadding.width;
                actualFlowchartHeight = subFeatureDimensionWithPadding.height;
            }

            if (actualFlowchartWidth > maxflowchartWidth) {
                maxflowchartWidth = actualFlowchartWidth;
            }

            topAligning += actualFlowchartHeight + FLOWCHART_PADDING_Y;
        }

        leftAligning += maxflowchartWidth + FLOWCHART_PADDING_X;
    }

    // generate lines for the flowchart
    const generatedLines = generateNewLinesForFlowchart(canvas, dataArray, featuresFrame, {
        flowchartName: options.flowchartName,
        processId
    });

    allGeneratedFlowchartItems.push(...generatedLines);
    
    return allGeneratedFlowchartItems;

}

/**
 * Generates all flowcharts .
 * @param {fabric.Canvas} canvas 
 * @param {string} buildCardID - Board's build card id.
 * @param additionalData
 */
export async function generateFlowChart(canvas, buildCardID, additionalData = {}) {
    try {
        const data = await getFlowchartData(buildCardID);
        data.featureFlowcharts = data.featureFlowcharts.filter(
            roleBasedFlowchart => Array.isArray(roleBasedFlowchart.role_device_flowchart) && roleBasedFlowchart.role_device_flowchart.length > 0
        )
        
        const processId = uuidv4()
        const dimension = getAllFlowchartDimensions(data.featureFlowcharts, { includeGap: true });

        const centerPoint = canvas.getCenter();
        const emptyPosition = findEmptyAreaForObject(
            canvas,
            {
                width: dimension.width,
                height: dimension.height,
            },
            {
                initiallyDesiredPosition: { 
                    x: centerPoint.left,
                    y: centerPoint.top
                }
            }
        );

        let startingLeft = emptyPosition.x - dimension.width / 2;  // left of the empty area
        let startingTop = emptyPosition.y; // center of the empty area
        
        const generatedFlowchartItems = [];

        for (const [index, featureFlowchartBaseRole] of Object.entries(data.featureFlowcharts)) {
            let deviceName = '';
            try {
                deviceName = featureFlowchartBaseRole.role_device_flowchart[0][0].heroImages[0].device;
            } catch (err) {
                console.error('Error happened', err);
            }
            try {
                const roleAndDeviceBasedFlowchartItems = await generateSingleFlowChart(canvas, featureFlowchartBaseRole.role_device_flowchart, {
                    startingLeft,
                    startingTop,
                    flowchartDimension: dimension.singleFlowchartDimensions[index],
                    allFlowchartDimension: {
                        width: dimension.width,
                        height: dimension.height,
                    },
                    flowchartName: featureFlowchartBaseRole.flowchartName,
                    frameName: featureFlowchartBaseRole.frameName,
                    deviceName,
                    processId
                });

                generatedFlowchartItems.push(...roleAndDeviceBasedFlowchartItems);
            } catch (err) {
                console.error('Error while generating single flowchart', err);
            } finally {
                startingLeft += dimension.singleFlowchartDimensions[index].width + FLOWCHART_ROLE_DEVICE_PADDING;
            }

        }

        canvas.renderAll();

        if (generatedFlowchartItems.length) {
            // add flowchart items to history, This should be disabled as of now
            addFlowchartItemsToHistory(canvas, generatedFlowchartItems.map(o => o.uuid), additionalData);

            // after generating all flowcharts, fit to screen
            eventEmitter.fire(EMITTER_TYPES.FIT_TO_SCREEN);

            // send flowchart shape data to backend
            canvas.fire('history-emit-data', {
                objects: generatedFlowchartItems,
                action: 'created'
            })
            
            return true;
        }
    }
    catch (err)  {
        console.error('Error while generating all flowchart', err);
    }
    return false;
}

/**
 * Adds flowchart items to history.
 * @param {fabric.Canvas} canvas 
 * @param {string[]} uuids - Flowchart uuid list.
 * @param additionalData
 */
const addFlowchartItemsToHistory = (canvas, uuids, additionalData) => {
    const flowchartJSON = [];

    for (const uuid of uuids) {
        const object = canvas.getObjects().find(object => object.uuid === uuid);
        if (object) {
            flowchartJSON.push(
                createObjectToBeEmitted(
                    store.getState().board.whiteBoardId,
                    additionalData.userId || store.getState().user.id,
                    customToObject(object),
                    false,
                    object.shapeType,
                )
            );
        }
    }

    store.dispatch({
        type: 'history/addShapesToHistory',
        payload: {
            shapes: flowchartJSON,
            pageId: additionalData.activePageId
        }
    })
}


const addFlowchartImage = (data) => {
    return new OptimizedImage(null, {
        left: data.left,
        top: data.top,
        width: data.width,
        height: data.height,
        imageData: data.imageData,
        text: data.title,
        // altUrl: 'https://google.com',
        stroke: SHAPE_DEFAULTS.STROKE,
        strokeWidth: FLOWCHART_FEATURE_STROKE_WIDTH,
        setSRCWithLoader: true
    });
}

/**
 * Maps sub features to columns .
 * @param {{Object}} data - Flowchart sub data.
 * @returns 
 */
const mapSubFeatures = (data) => {
    const MAX_COLUMNS = 3;

    const list = [];

    for (const [index, dataItem] of Object.entries(data)) {
        if (index < 6) {
            const rowIndex = Math.floor(index / 2);
            if (!list[rowIndex]) {
                list[rowIndex] = [];
            }
            list[rowIndex].push(dataItem);
        } else {
            const rowIndex = index % MAX_COLUMNS;
            list[rowIndex].push(dataItem);
        }
    }
    return list;
};


/**
 * Adds sub features of a flowchart.
 * @param canvas
 * @param {object} data 
 */
const addSubFeatures = (canvas, data) => {
    const { subFeatures, initialLeft, initialTop } = data;
    const generatedSubFeatures = [];

    let leftAligning = initialLeft + FLOWCHART_SUB_FEATURE_PADDING_X;

    let maxFlowchartColumnTop;

    for (const column of subFeatures) {
        const columnHeight = getFlowchartSubFeaturesColumnHeight(column);
        const startingTop = initialTop - columnHeight / 2;
        let topAligning = startingTop;
        let maxflowchartWidth = 0;

        if (!maxFlowchartColumnTop) {
            maxFlowchartColumnTop = startingTop;
        }
        if (startingTop < maxFlowchartColumnTop) {
            maxFlowchartColumnTop = startingTop;
        }

        for (const subFeature of column) {
            const subFeatureWidth = parseInt(subFeature.heroImages[0].hero_image_attributes.width);
            const subFeatureHeight = parseInt(subFeature.heroImages[0].hero_image_attributes.height);
            const featureImageData = subFeature.heroImages[0].optimizedImageData;
            const optimizedImageData = {
                full: {
                    url: featureImageData.full
                },
                med: {
                    url: featureImageData.med
                },
                sm: {
                    url: featureImageData.sm
                },
                xs: {
                    url: featureImageData.xs
                }
            }
            const generatedSubFeature = new OptimizedImage(null, {
                left: leftAligning,
                top: topAligning,
                width: subFeatureWidth,
                height: subFeatureHeight,
                imageData: optimizedImageData,
                text: '',
                // altUrl: 'https://google.com',
                stroke: SHAPE_DEFAULTS.STROKE,
                strokeWidth: 2,
                setSRCWithLoader: true
            });
            setShapeProps(canvas, generatedSubFeature, {
                type: FLOWCHART_ITEM_TYPE.SUB_FEATURE,
                flowchartName: data.flowchartName,
                subFeature,
                mainFeatureID: data.mainFeatureID,
                mainFeatureUniqCode: data.mainFeatureUniqCode,
                processId: data.processId
            });
            generatedSubFeatures.push(generatedSubFeature);

            topAligning += subFeatureHeight + FLOWCHART_SUB_FEATURE_PADDING_Y;
            if (subFeatureWidth > maxflowchartWidth) {
                maxflowchartWidth = subFeatureWidth;
            }
        }
        leftAligning += maxflowchartWidth + FLOWCHART_SUB_FEATURE_PADDING_X;
    }

    return generatedSubFeatures;
}

/**
 * Returns the dimension of the sub features with padding included.
 * @param {object} data 
 * @param {number} data.width 
 * @param {number} data.height
 * @param {number} data.left
 * @param {number} data.top 
 */
const getSubFeatureDimensionWithPadding = (data) => {
    return {
        width: data.width + FLOWCHART_SUB_FEATURE_PADDING_X * 2,
        height: data.height + FLOWCHART_SUB_FEATURE_PADDING_Y * 2,
        left: data.left - FLOWCHART_SUB_FEATURE_PADDING_X,
        top: data.top - FLOWCHART_SUB_FEATURE_PADDING_Y,
    }
}

/**
 * Center the subfeatures and flowchart item to its frame.
 * @param {object} frameBounding 
 * @param {object} featureAndSubFeatureBounding 
 * @param {object} item 
 * @returns {{left: number, top: number}}}.
 */
const centerFlowchartAndSubFeatures = (frameBounding, featureAndSubFeatureBounding, item) => {
    const frameBoundingCenter = {
        x: frameBounding.left + (frameBounding.width / 2),
        y: frameBounding.top + (frameBounding.height / 2),
    }
    const featureAndSubFeatureBoundingCenter = {
        x: featureAndSubFeatureBounding.left + (featureAndSubFeatureBounding.width / 2),
        y: featureAndSubFeatureBounding.top + (featureAndSubFeatureBounding.height / 2),
    }


    const deltaItemPos = {
        x: featureAndSubFeatureBoundingCenter.x - item.left,
        y: featureAndSubFeatureBoundingCenter.y - item.top,
    }
    const newleft = frameBoundingCenter.x - deltaItemPos.x;
    const newtop = frameBoundingCenter.y - deltaItemPos.y;
    return {
        left: newleft,
        top: newtop,
    }
}

/**
 * Generates UUID for flowchart item .
 * @param {fabric.Canvas} canvas 
 * @returns {string} Uuid.
 */
const flowchartUUIDGenerator = (canvas) => {
    let id = uuidv4();
    let result = canvas.getObjects().filter(e => e.id && e.id === id);
    if (result.length) return flowchartUUIDGenerator(canvas);
    else return id;
}

/**
 * Sets flowchart Item's shape props.
 * @param {fabric.Canvas} canvas 
 * @param {fabric.Object} shape 
 * @param {object} options 
 * @param {'feature'|'subFeature'|'hotspot'|'frame'|'subFrame'} options.type 
 * @param {string} options.flowchartName 
 */
export const setShapeProps = (canvas, shape, options = {}) => {
    if (!shape.uuid) {
        shape.set({ uuid: flowchartUUIDGenerator(canvas) })
    }
    const storeData = store.getState();
    let userId = storeData?.user?.id;
    let whiteBoardId = storeData?.board?.whiteBoardId;
    shape.set({ whiteBoardId: whiteBoardId, createdBy: userId, modifiedBy: userId, isDeleted: false });

    if (shape.type === 'frame') {
        shape.attachedShapes = [];
        shape.attachments = [];
    }

    if (options?.type) {
        const genericFlowchartProps = {
            flowchartName: options.flowchartName,
            processId: options.processId
        }
        if (options?.type === FLOWCHART_ITEM_TYPE.FEATURE) {
            const flowchartProps = {
                flowchartItemType: FLOWCHART_ITEM_TYPE.FEATURE,
                featureID: options?.flowchart?.build_card_feature_id,
                flowchartUniqCode: options?.flowchart?.uniq_code,
                hasSubFeature: options?.hasSubFeature,
                isLaunchScreen: options?.flowchart?.is_launch_screen,
                title: options?.flowchart?.title,
                apeirosID: options?.flowchart?.id,
                heroImageID: options?.flowchart?.heroImages[0]?.build_card_hero_image_id,
                heroImageApeirosID: options?.flowchart?.heroImages[0]?.id,
                heroImageAttributes: options?.flowchart?.heroImages[0]?.hero_image_attributes,
                heroImageURL: options?.flowchart?.heroImages[0]?.hero_image_url,
                heroImageProperties: options?.flowchart?.heroImages[0]?.properties,
                device: options?.flowchart?.heroImages[0]?.device,
                ...genericFlowchartProps
            }
            shape.flowchartProps = flowchartProps;
            shape.constantFlowchartProps = {
                ...flowchartProps
            }
        } else if (options?.type === FLOWCHART_ITEM_TYPE.SUB_FEATURE) {
            const flowchartProps = {
                flowchartItemType: FLOWCHART_ITEM_TYPE.SUB_FEATURE,
                subFeatureID: options?.subFeature?.build_card_feature_id,
                subFeatureMainFeatureID: options.mainFeatureID,
                subFeatureMainFeatureUniqCode: options.mainFeatureUniqCode,
                apeirosID: options?.subFeature?.id,
                isLaunchScreen: options?.subFeature?.is_launch_screen,
                isSubFeature: true,
                title: options?.subFeature?.title,
                heroImageID: options?.subFeature?.heroImages[0]?.build_card_hero_image_id,
                heroImageApeirosID: options?.subFeature?.heroImages[0]?.id,
                heroImageAttributes: options?.subFeature?.heroImages[0]?.hero_image_attributes,
                heroImageURL: options?.subFeature?.heroImages[0]?.hero_image_url,
                heroImageProperties: options?.subFeature?.heroImages[0]?.properties,
                ...genericFlowchartProps
            }
            shape.flowchartProps = flowchartProps;
            shape.constantFlowchartProps = {
                ...flowchartProps
            }
        } else if (options?.type === FLOWCHART_ITEM_TYPE.FRAME) {
            const flowchartProps = {
                flowchartItemType: FLOWCHART_ITEM_TYPE.FRAME,
                ...genericFlowchartProps
            }
            if (options.deviceName) {
                flowchartProps.deviceName = options.deviceName;
            }
            shape.flowchartProps = flowchartProps;
            shape.constantFlowchartProps = {
                ...flowchartProps
            }
        } else if (options?.type === FLOWCHART_ITEM_TYPE.SUB_FRAME) {
            const flowchartProps = {
                mainFeatureID: options?.mainFeatureID,
                mainFeatureUniqCode: options?.mainFeatureUniqCode,
                flowchartItemType: FLOWCHART_ITEM_TYPE.SUB_FRAME,
                ...genericFlowchartProps,
            }
            shape.flowchartProps = flowchartProps;
            shape.constantFlowchartProps = {
                ...flowchartProps
            }
        } else if (options?.type === FLOWCHART_ITEM_TYPE.HOTSPOT) {
            const flowchartProps = {
                flowchartItemType: FLOWCHART_ITEM_TYPE.HOTSPOT,
                targetFeatureUniqCode: options.targetFeatureUniqCode,
                apeirosHotspotID: options.apeirosHotspotID,
                hotspotID: options.hotspotID,
                position: options.position,
                ...genericFlowchartProps
            }
            shape.flowchartProps = flowchartProps;
            shape.constantFlowchartProps = {
                ...flowchartProps
            } 
        }
    }
}

/**
 * Removes flowchart items in the canvas.
 * Does not emit removed data.
 * @param {fabric.Canvas} canvas The canvas that flowchart items will be removed in.
 * @returns {fabric.Object[]} Removed flowchart items in the canvas.
 */
export const removeFlowchartItemsInCanvas = (canvas) => {
    const flowchartItems = canvas.getObjects().filter(obj => obj.flowchartProps && typeof obj.flowchartProps === 'object');

    const removedObjects = [];

    for (const flowchartItem of flowchartItems) {
        flowchartItem.avoidEmittingFlowchartRemove = true;
        canvas.remove(flowchartItem);
        removedObjects.push(flowchartItem);
    } 
    
    return removedObjects;
}

/**
 * Removes all the flowchart items from canvas.
 * Emits the data.
 * @param {fabric.Canvas} canvas The canvas that flowchart items will be removed in.
 */
export const handleRemoveFlowchartItemsInCanvas = (canvas) => {
    const removedObjects = removeFlowchartItemsInCanvas(canvas);
    
    canvas.fire('history-emit-data', {
        objects: removedObjects,
        action: 'deleted'
    })
}

/**
 * Attaches flowchart items to frame .
 * @param {fabric.Frame} frame - Flowchart frame.
 * @param {fabric.Object} object - Flowchart item.
 */
export const attachFlowchartItemsToFrame = (object, frame) => {
    try {
        attachToFrame(object, frame);
        if (!Array.isArray(frame?.attachments)) {
            frame.attachments = [];
        }
        if (!Object.isExtensible(frame.attachments)) {
            frame.attachments = [...frame.attachments];
        }

        if (frame?.attachments?.indexOf(object.uuid) === -1) {
            frame.attachments.push(object.uuid);
        }
    } catch (err) {
        console.error(`error while attaching flowchart item to the frame ${object} => ${frame}: `, err)
    }
}