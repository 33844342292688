import { fabric } from 'fabric';
import calculateTextDimensionsHTML from '../../../helpers/TextWrapHelpers';

export class CompositeRenderer {
    constructor(canvas) {
        this.fabricCanvas = canvas;
    }
    
    beginRender() {
        if (this.fabricCanvas.contextTopDirty && !this.fabricCanvas._groupSelector && !this.fabricCanvas.isDrawingMode) {
            this.fabricCanvas.clearContext(this.fabricCanvas.contextTop);
            this.fabricCanvas.contextTopDirty = false;
        }
        if (this.fabricCanvas.hasLostContext) {
            this.fabricCanvas.renderTopLayer(this.fabricCanvas.contextTop);
            this.fabricCanvas.hasLostContext = false;
        }
        
        const ctx = this.fabricCanvas.contextContainer;
        this.fabricCanvas.cancelRequestedRender();
        this.fabricCanvas.calcViewportBoundaries();
        this.fabricCanvas.clearContext(ctx);
        fabric.util.setImageSmoothing(ctx, this.fabricCanvas.imageSmoothingEnabled);
        this.fabricCanvas.fire('before:render', { ctx: ctx, });
    }
    renderBackground() {
        // I don't know why but this comes empty
        // if we decide the change background color of the board, please handle this also.
        if (this.fabricCanvas.backgroundColor === '') {
            this.fabricCanvas.backgroundColor = '#fafafa';
        }
        const ctx = this.fabricCanvas.contextContainer;
        this.fabricCanvas._renderBackground(ctx);
    }
    
    renderControlsUI() {
        const ctx = this.fabricCanvas.contextContainer;
        this.fabricCanvas.drawControls(ctx);
    }
    renderLockingUI() {
        const bbBoxOfLockedElements = this.fabricCanvas.getCollabLockedObjBbox()
        if (!bbBoxOfLockedElements) {
            return
        }
        for (const userBy of bbBoxOfLockedElements.values()) {
            const viewport = userBy.get('viewport');
            if (!viewport || !viewport.isFinite()) {
                return
            }
            const coords = viewport.getFabricStyleCoords(this.fabricCanvas.viewportTransform);

            const ctx = this.fabricCanvas.contextContainer;
            ctx.save()
            ctx.beginPath()
            ctx.lineWidth = 2;
            ctx.strokeStyle = '#DFE0E6';
            ctx.rect(coords.tl.x, coords.tl.y, coords.tr.x - coords.tl.x, coords.br.y - coords.tr.y);
            ctx.stroke()

            const text = userBy.get('user')?.name
            if (!text || text === '') {
                ctx.restore()
                continue
            }
            // draw text bg and text -- name
            const { width: widthOfNameText } = calculateTextDimensionsHTML(text, {
                fontFamily: 'Rubik, sans-serif',
                fontSize: '11px',
            })

            const textBgCoords = {
                x: coords.br.x - widthOfNameText - 8,
                y: coords.br.y + 6,
                w: widthOfNameText + 8,
                h: 20
            }

            const arcMargin = 6  // margin with text bg
            const arcRadius = 12
            const arcCoords = {
                x: textBgCoords.x - (arcRadius + arcMargin),
                y: textBgCoords.y + textBgCoords.h / 2
            }
            
            // if the text area is not enough to draw the name, skip for this user
            const nameAreaWidth = arcCoords.x - arcRadius - coords.tl.x
            if (nameAreaWidth < 0) {
                ctx.restore()
                continue;
            }
            
            ctx.fillStyle = '#676B7E';
            ctx.fillRect(textBgCoords.x, textBgCoords.y, textBgCoords.w, textBgCoords.h)

            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillStyle = '#fff';
            ctx.font = '11px Rubik, sans-serif';
            ctx.fillText(text, textBgCoords.x + textBgCoords.w / 2, textBgCoords.y + textBgCoords.h / 2)

            // Draw first letter of name
            const firstLetter = text[0].toUpperCase()
            ctx.beginPath()
            // generate 24x24 arc
            ctx.arc(arcCoords.x, arcCoords.y, arcRadius, 0, 2 * Math.PI);
            ctx.fillStyle = '#676B7E';
            ctx.fill()

            ctx.font = '12px Rubik, sans-serif';
            ctx.fillStyle = '#fff';
            ctx.textAlign = 'center'
            ctx.textBaseline = 'middle'
            ctx.fillText(firstLetter, arcCoords.x, arcCoords.y)

            ctx.restore() 
        }
    }
    
    renderDynamicObjects() {
        const vpt = this.fabricCanvas.viewportTransform;
        const ctx = this.fabricCanvas.contextContainer;
        ctx.save();
        ctx.transform(vpt[0], vpt[1], vpt[2], vpt[3], vpt[4], vpt[5]);
        for (const object of this.fabricCanvas.dirtyObjects) {
            if (!object) {
                continue
            }
            object.render(ctx, {
                isRenderingDynamic: true,
                isRenderingWithTiles: true
            });
        }
        ctx.restore();    
    }
    
    endRender() {
        const ctx = this.fabricCanvas.contextContainer;
        this.fabricCanvas.fire('after:render', { ctx: ctx, });
    }

    renderDefault() {
        const ctx = this.fabricCanvas.contextContainer;
        const v = this.fabricCanvas.viewportTransform;
        const objects = this.fabricCanvas._chooseObjectsToRender({ excludeDynamics: true });

        ctx.save();
        //apply viewport transform once for all rendering process
        ctx.transform(v[0], v[1], v[2], v[3], v[4], v[5]);
        this.fabricCanvas._renderObjects(ctx, objects, { renderingDefault: true });
        ctx.restore();
    }
}