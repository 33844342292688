/**
 *
 */
const RedoIcon = () => {
    return (
        <svg
            fill="none"
            height="20"
            viewBox="0 0 20 20"
            width="20"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.004 1.002v6h-6M10.005 19a9 9 0 118.48-12"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </svg>
    );
}

export default RedoIcon;