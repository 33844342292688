/* eslint-disable react/jsx-max-depth  */

import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import Board from '../board/Board';
import { Link, createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { builderLoginAutomated, loggedInUser, setAuthToken, setWbId, enterpriseLoginAutomated } from '../../services/AuthService';
import getParamFromURL, { getBoardSlugIdInURL } from '../../helpers/IframeHelper';
import { automatedBoard, automatedBMeetBoard, automatedEnterpriseBoard, getWhiteBoardDetails } from '../../services/DataService';
import { createObjectToBeEmitted, getUserQueryParams, isUserHasAccessToFeature, getHighestUserPermission, showUnauthorizedToastMessage } from '../../helpers/CommonFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { EMITTER_TYPES, SEGMENT_ANALYTICS_CONSTANTS, SOCKET_EVENT, SOCKET_STATUS_MODS, USER_ROLES, constShapes, modes, AUTH_VALIDATION_MESSAGE, DEFAULT_SHARED_PERMISSIONS, penShape, arrowShape, rectShape, MODAL_WINDOWS, APP_NAMES, NIMA_IMPORT_STATUS } from '../../helpers/Constant';
import useSocketConnection from '../../hooks/UseSocketConnection';
import useFeedback from '../../hooks/UseFeedback';
import FeedBack from '../feedback/FeedBack';
import TutorialErrorBoundary from '../tutorialCard/TutorialErrorBoundary';
import TutorialCard from '../tutorialCard/TutorialCard';
import { io } from 'socket.io-client';
import { environment } from '../../environment';
import overrideFabric from '../../helpers/fabricOverrides/OverrideFabric';
import { onAddComment, onUserInvited, onUserPermissionUpdated, onUserRemoved, onWbOwnershipTransferred, onWBPermissionUpdated } from '../../hooks/EventCatcher';
import changeCursor from '../../helpers/ChangeCursor';
import Header from '../header/Header';
import useComment, { setPositionOfHTMLElement } from '../../hooks/UseComment';
import ShortcutsList from '../shortcutList/ShortcutsList';
import { ToastContainer, toast } from 'react-toastify';
import ContextMenu from '../contextMenu/ContextMenu';
import TextEditor from '../textEditor/TextEditor';
import SubToolbarErrorBoundary from '../subToolbar/SubToolbarErrorBoundary';
import SubToolbar from '../subToolbar/SubToolbar';
import CommentInputWrapper from '../comment/input/CommentInputWrapper';
import { handleAddCommentCallback, handleAddReplyCallback, handleDeleteCommentCallback, handleMarkAsUnreadCommentCallback, handleReadAllCommentsCallback, handleReadCommentCallback, handleResolveCommentCallback, handleUpdateCommentContentCallback } from '../../helpers/comments/Handlers';
import useUpdateComment from '../../hooks/UseUpdateComment';
import useUser from '../../hooks/UseUser';
import useHideResolvedComments from '../../hooks/UseHideResolvedComments';
import useHideComments from '../../hooks/UseHideComments';
import { getLocalStorage } from '../../services/CookieService';
import { v4 as uuidv4 } from 'uuid';
import CommentWrapperWrap from '../comment/wrapper/CommentWrapperWrap';
import LeftDrawer from '../leftDrawer/LeftDrawer';
import RightDrawer from '../rightDrawer/RightDrawer';
import BottomBars from '../bottomBars';
import HyperLinkBox from '../hyperLinkBox/HyperLinkBox';
import useShortCuts from '../../hooks/UseShortcuts';
import { attachObjectToFrameDuringCreation } from '../../helpers/frame/FrameMethods';
import { customToObject, getFabricObject, isTargetLine } from '../../helpers/FabricMethods';
import { connectToGroup } from '../../hooks/UseLine';
import {compressData, debounce, decompressData} from '../../helpers/OptimizationUtils';
import { trackAction } from '../../helpers/SegmentEventTracker';
import { getBrowserName, getDeviceName } from '../../helpers/BrowserInfo';
import eventEmitter from '../../helpers/EventEmitter';
import { isObjectEmpty } from '../../helpers/Utils'
import useOverlay from '../../hooks/UseOverlay';
import EventQueue from '../../helpers/EventQueue';
import { removeAllExistedLasso } from '../../hooks/UseLasso';
import useCanvas from '../../hooks/UseCanvas';
import { CanvasesContext } from '../../context/canvases/CanvasesContext';
import getToastIcon from '../../helpers/media/GetToastIcon';
import FlowChartModal from '../flowchart/flowchartModal/FlowchartModal';
import { isBuilderAiUser } from '../../helpers/Validation';
import CommentDrawer from '../commentDrawer/CommentDrawer';
import LoadingScreen from '../loader/Loading';
import { pendingRequestList } from '../../services/RequestAccessService';
import { hasPublicAccess, isUserHasAccessToBoardWhileNotMember } from '../../helpers/UserPermissions';
import { closeRequestAccessToast } from '../../helpers/ToastHelper';
import { handleDecodingOfInviteSocketEvent } from '../../helpers/DecodeHelper';
import Toolbar from '../toolbar/Toolbar';
import ThirdPartyMigrationModal from '../thirdPartyMigrationModal/ThirdPartyMigrationModal';
import {fitToScreenAssistance} from '../../helpers/FitToScreenAssistance';
import {CanvasLockManager} from '../../core/collaboration/CanvasLockManager';

/**
 *
 */
const BoardWrapper = () => {
    // states
    const [whiteBoardSlugId, setWhiteBoardSlugId] = useState(null),
        [actionSelected, setActionSelected] = useState({
            selectMode: false,
            lassoMode: false,
            dragMode: false,
            commentMode: false,
            pencilMode: false,
            eraseMode: false,
            deleteMode: false,
            rectMode: false,
            ellipseMode: false,
            triangleMode: false,
            arrowMode: false,
            lineMode: false,
            stickyMode: false,
            frameMode: false,
            textMode: false,
            undoMode: false,
            redoMode: false,
            tableMode: false
        }),
        [selectedShape ,setShapeSelection] = useState(rectShape),
        [selectedPencilType ,setPencilSelection] = useState(penShape),
        [selectedArrowType ,setArrowSelection] = useState(arrowShape),
        [boardName, setBoardName] = useState(''),
        [userId, setUserId] = useState(loggedInUser() ? loggedInUser().id : null),
        [wbOwnerId, setWbOwnerId] = useState(),
        [whiteBoardId, setWhiteBoardId] = (0, useState)(),
        [showLoader, setShowLoader] = useState(true),
        [selectedComment, setSelectedComment] = useState({}),
        [selectedCommentIcon, setSelectedCommentIcon] = useState(null),
        // store all comments for commentTab
        [comments, setComments] = useState([]),
        // store comment results for searching and mention
        // storing in here necessary for handling updates
        [commentResults, setCommentResults] = useState([]),
        [isSocketConnected, setIsSocketConnected] = useState(false),
        [isJoinedToSocketRoom, setIsJoinedToSocketRoom] = useState(false),
        [, forceUpdate] = useState(Math.random()),
        [unreadCommentCount, setUnreadCommentCount] = useState(false),
        [showOverlay, setShowOverlay] = useState(false);
        

    // refs
    const userAccessRef = useRef(),
        socketRef = useRef(),
        // this is for adding comment form
        commentFormWrapperRef = useRef(null),
        // this is for form comment wrapper : (listing, adding reply etc...)
        commentWrapperRef = (0, useRef)(null),
        // store clicked mouse position for comment form wrapper
        commentInputClickedPos = (0, useRef)({x: 0, y: 0}),
        isLassoRef = useRef(false),
        boardOptions = useRef(getLocalStorage('bboard_options') ? JSON.parse(getLocalStorage('bboard_options')) : { hideComments: { [whiteBoardSlugId]: false }, hideResolvedComments: { [whiteBoardSlugId]: false } }),
        queueSetRef = useRef(new Set()),
        eventQueueRef = useRef(new EventQueue()),
        canvasRef = useRef(null),
        canvasInstanceRef = useRef(null),
        canvasesRef = useRef(null),
        currentPageId = useRef(null),
        initializePagesRef = useRef(null),
        boardStateRef = useRef(),
        pageReferencesRef = useRef({}),
        isFrameNotFoundFlowRunOnce = useRef(false),
        lockManagerRef = useRef(new CanvasLockManager()),
        waitingToLockObjects = useRef(new Map());
    

    // hooks
    const canvas = useCanvas();
    canvasInstanceRef.current = canvas;
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const currentURLNodes = location.pathname.split('/');

    const pages = useSelector(state => state?.rightDrawer?.pages);
    const activePageId = useSelector(state => state?.rightDrawer?.activePage?.id);
    const board = useSelector((state) => state.board);
    const activeUser = useSelector((state) => state.user);
    const { requestAccessModal } = useSelector(state => state?.modal);
    const { users } = board;

    boardStateRef.current = board;
    pageReferencesRef.current = {
        pages,
        activePageId
    };

    const activeWindowState = useSelector(state => state?.modal?.activeWindow),
        isUserSeenTutorial = useSelector(state => state?.user?.properties?.seenTutorial),
        setCommentState = useComment(
            canvas, 
            actionSelected, 
            commentFormWrapperRef, 
            commentWrapperRef, 
            commentInputClickedPos, 
            selectedCommentIcon,
            setSelectedCommentIcon,
            activeWindowState
        )[1],
        // boardOptions = useSelector((state) => state.board),
        currentUser = useUser(users, userId),
        [hideResolvedComments, toggleHideResolvedComments] = useHideResolvedComments(canvas, boardOptions, whiteBoardSlugId),
        [hideComments, toggleHideComments] = useHideComments(canvas, hideResolvedComments, boardOptions, whiteBoardSlugId),
        [
            setUpdatedComment, 
            setResolvedComment,
            setDeletedComment,
            setAddedComment
        ] = useUpdateComment({
            canvas, 
            comments, 
            userId,
            setComments, 
            setSelectedCommentIcon, 
            selectedComment, 
            selectedCommentIcon, 
            shouldHideResolvedComment: hideResolvedComments,
            setSelectedComment,
            commentResults,
            setCommentResults,
            socketRef,
            activePageId,
            pageReferencesRef
        });
    useSocketConnection({
        setWbId,
        setWhiteBoardId,
        setWhiteBoardSlugId,
        setBoardName,
        activePageId,
        initializePagesRef,
        setShowOverlay
    });
    useFeedback(whiteBoardSlugId);
    useOverlay(showOverlay, setShowOverlay, false);

    const displayCommentDrawer = activeWindowState === MODAL_WINDOWS.COMMENT_DRAWER;

    const canvasDetails = useContext(CanvasesContext);
    canvasesRef.current = canvasDetails.canvases;

    const emitData = useCallback((dataArray, event = SOCKET_EVENT.MODIFIED) => {
        if (!dataArray || dataArray?.length === 0) return;

        // Adding pageID to the event data
        dataArray = dataArray.map((item) => {
            item.pageId = pageReferencesRef.current.activePageId;
            return item;
        });

        const compressed = compressData(dataArray);
        if (compressed) socketRef.current.emit(event, compressed, (eventResult) => {
            if (eventResult?.status === 'ok' && [SOCKET_EVENT.CREATED, SOCKET_EVENT.DELETED, SOCKET_EVENT.MODIFIED].includes(event)) {
                canvasInstanceRef.current.fire('objects-emitted', { dataArray, event });
            }

            // For table objects, we need re-render due to the removed cell properties (selected, renderedText etc.)
            if (event === SOCKET_EVENT.MODIFIED && dataArray.some((o) => o.type === 'table')) {
                canvasInstanceRef.current.renderAll();
            }

            // After creation or removal of any object, we need to update the stack order.
            if (eventResult?.status === 'ok' && canvasInstanceRef.current && [SOCKET_EVENT.CREATED, SOCKET_EVENT.DELETED, SOCKET_EVENT.MODIFIED].includes(event)) {
                // Objects' stack orders cannot be changed in modifications. However, restore action from activityLog is emit the data as modified.
                // Actually it is re-create the deleted item. That's why we checked the modified items.
                if (SOCKET_EVENT.MODIFIED === event) {
                    const isAnyItemRestored = dataArray.some((item) => item.isRestored === true);
                    if (!isAnyItemRestored) { return; } 
                }
                const updatedStacks = dataArray.map(item => {
                    return { zIndex: item.properties.zIndex, uuid: item.uuid }
                })
                canvasInstanceRef.current.fire('stack-updated', {updatedStacks});
            }
        });
    }, [canvas, activePageId]);
    
    const emitModifiedQueue = useCallback(() => {
        const data = [];
        for (const uuid of queueSetRef.current) {
            const obj = getFabricObject(canvas, 'uuid', uuid);
            if (!obj) continue;
            let jsonData = createObjectToBeEmitted(
                whiteBoardId, 
                userId, 
                customToObject(obj, { useCalculatedPosition: true, narrowDefinitions: true }), 
                false, 
                obj.shapeType
            );
            jsonData = {...jsonData, actionTaken: 'modified'};
            data.push(jsonData);
        }
        // emit data
        emitData(data);
        // clear the queue
        queueSetRef.current.clear();
    }, [canvas, userId, whiteBoardId, emitData, activePageId]);
    
    const emitModifiedDataRef = useRef(emitModifiedQueue);

    const emitQueue = useMemo(() => {
        const func = () => {
            emitModifiedDataRef.current?.();
        }
        return debounce(func, 300);
    }, []);

    const uuidGenerator = useCallback((canvas) => { // todo generate more generic function for all pages
        let id = uuidv4();
        let result = canvas.getObjects().filter(e => e.id && e.id === id);
        if (result.length) return uuidGenerator(canvas);
        else return id;
    }, []);
    
    const mapFieldsToDrawInstance = useCallback((canvas, drawInstance, whiteBoardId, userId, options = {}) => {
        let id = drawInstance.uuid ? drawInstance.uuid : uuidGenerator(canvas);
        drawInstance.set({ whiteBoardId: whiteBoardId, uuid: id, createdBy: userId, modifiedBy: userId, shapeType: drawInstance.shapeType ? drawInstance.shapeType : drawInstance.type, isDeleted: false });
        // If object created into a frame, then we need to attach it to the frame.
        attachObjectToFrameDuringCreation(canvas, drawInstance);
        let obj = createObjectToBeEmitted(whiteBoardId, userId, customToObject(drawInstance, {narrowDefinitions: true}), false, drawInstance.shapeType);

        let actionTaken = drawInstance.isDuplicated ? 'duplicated' : 'created';
        obj = {...obj, actionTaken};
        // created part
        emitData([obj], SOCKET_EVENT.CREATED)

        if (obj.properties.attachedFrameId) {
            const frame = canvas.getObjects().find(item => item.uuid === obj.properties.attachedFrameId);
            frame.attachments = [...frame.attachments, obj.uuid]
            canvas.fire('frame-modified', { frame, updatedAttachments: [obj], detachedObjects: [] })

            let frameObj = createObjectToBeEmitted(whiteBoardId, userId, customToObject(frame), false, frame.shapeType)
            emitData([frameObj], SOCKET_EVENT.MODIFIED);
        }

        if (isTargetLine(obj) && !options.preventLineBinding) {
            if (!options.dontAttachLine) connectToGroup(canvas, drawInstance, socketRef, whiteBoardId, userId)
            // re-create the line object since it may attach to shapes
            obj = createObjectToBeEmitted(whiteBoardId, userId, customToObject(drawInstance, {narrowDefinitions: true}), false, drawInstance.shapeType);
            if (obj.properties.rightPolygon) {
                if (obj.properties.rightPolygon.hasOwnProperty('size') && typeof obj.properties.rightPolygon.size === 'function') {
                    obj.properties.rightPolygon.size = null;
                }
            }
            if (obj.properties.leftPolygon) {
                if (obj.properties.leftPolygon.hasOwnProperty('size') && typeof obj.properties.leftPolygon.size === 'function') {
                    obj.properties.leftPolygon.size = null;
                }
            }
        }
    
        if (drawInstance.type === 'frame' && drawInstance.attachments && !options.preventFrameElementEmitting) {
            const attachedShapeData = [];
            for (const attachedShapeUuid of drawInstance.attachments) {
                const attachedShape = getFabricObject(canvas, 'uuid', attachedShapeUuid);
                if (attachedShape) {
                    console.log('attachedShape', attachedShape);
                    let obj = createObjectToBeEmitted(whiteBoardId, userId, customToObject(attachedShape), false, attachedShape.shapeType);
                    obj.actionTaken = 'modified';
                    obj.modifiedBy = userId;
                    attachedShape.modifiedBy = userId;
                    attachedShapeData.push(obj);
                }

            }
            emitData(attachedShapeData);
        }
        try {
            dispatch({
                type: 'history/addShapeToHistory',
                payload: { shape: structuredClone(obj), pageId: activePageId }
            });
        } catch (err) {
            dispatch({
                type: 'history/addShapeToHistory',
                payload: { shape: obj, pageId: activePageId }
            });
        }
    
        if (!options?.preventAddToUndoStack) {
            canvas.fire('add-to-undo-stack', {objects: [drawInstance], processId: options?.processId});
        }
    }, [dispatch, emitData, uuidGenerator, socketRef, activePageId]);

    /**
     * Checking the user permission to the board and returned it.
     * @param {[{email:string, name: string, info: {backgroundColor: string, colorCode: string, dotType: string, permission: string, status: string, userId: number }}]} users
     * @param {number=} externalUserId
     * @param {object} data
     * @param externalUserEmail
     * @returns {'view' | 'comment' | 'edit' | 'NOT_ALLOWED'}
     */
    const handleUserAccess = (users, externalUserId, data = {}, externalUserEmail) => {
        const loggedUserEmail = externalUserEmail || loggedInUser()?.email;
        const userTarget = userId || externalUserId;
        const inBoardActiveUser = users?.find((u) => u.info.userId === userTarget);

        const accesses = {
            psRegularUser: data.publicSharePermission?.users ?? USER_ROLES.notAllowed.id,
            psBuilderUser: data.publicSharePermission?.employees ?? USER_ROLES.notAllowed.id,
            board: inBoardActiveUser?.info?.permission ? USER_ROLES[inBoardActiveUser.info.permission]?.id : USER_ROLES.notAllowed.id
        }

        // Now getting the highest level of permissions
        userAccessRef.current = getHighestUserPermission(
            accesses.board,
            accesses.psRegularUser,
            accesses.psBuilderUser,
            isBuilderAiUser(loggedUserEmail),
        );

        if (userAccessRef.current === USER_ROLES.notAllowed.id) {
            showUnauthorizedToastMessage(AUTH_VALIDATION_MESSAGE.BOARD_UNAUTHORIZED, false);
            setShowOverlay(true);
            return;
        }

        const isPublicAccess = accesses.board === USER_ROLES.notAllowed.id && isUserHasAccessToBoardWhileNotMember({
            userPermission: accesses.psRegularUser,
            builderUserPermission: accesses.psBuilderUser
        });

        dispatch({
            type: 'user/changePermission',
            payload: userAccessRef.current,
        });

        if (isPublicAccess) {
            dispatch({
                type: 'board/changeIsPublicAccess',
                payload: isPublicAccess
            })
        }

        return userAccessRef.current;
    };

    const handleToolbarItemVisibility = useCallback((item) => {
        return !isUserHasAccessToFeature(item, userAccessRef.current);
    }, [])


    /**
     * @param {{userId: number, action: 'REMOVE'|'INVITE'|'UPDATE',email: string, name: string, commentUsername: string, commentEmail: string, userPermission: 'view'|'comment'|'edit'}} param0
     */
    const onMemberListUpdated = useCallback(
        ({
            userId: updatedUserId,
            action,
            email,
            name,
            commentUsername,
            isUserSigned,
            userPermission,
            isReceivedFromWB = false,
            newIsPublicAccess
        }) => {
            const boardState = boardStateRef.current;
            let boardUsers = boardState.users;
            let isPublicAccess = newIsPublicAccess ?? board.isPublicAccess;

            if (action === 'REMOVE') {
                boardUsers = boardUsers.filter((user) => user.info.userId !== updatedUserId)

                if (board.isPublicLink || newIsPublicAccess) {
                    boardUsers = boardUsers.map((user) => {
                        if (user.id === updatedUserId) {
                            user.joinedViaPublicLink = true;
                        }

                        return user;
                    })
                }
            } else if (action === 'INVITE') {
                let shouldAppendUser = true;

                if (board.isPublicLink || board.isPublicAccess) {
                    const stateUser = boardUsers.find((_user) => _user?.id === updatedUserId);

                    if (stateUser && stateUser.joinedViaPublicLink === true) {
                        shouldAppendUser = false;

                        boardUsers = boardUsers.map((_user) => {
                            if (_user.id === updatedUserId) {
                                _user.joinedViaPublicLink = undefined;
                            }

                            return _user;
                        })
                    }
                }

                if (shouldAppendUser) {
                    if (isUserSigned === false && name === 'FirstName') { // If user is not signed up yet, then replace commentUsername with email
                        commentUsername = email;
                    }

                    boardUsers = [
                        ...boardUsers,
                        {
                            id: updatedUserId,
                            name,
                            email,
                            commentUsername,
                            info: {
                                backgroundColor: '#fafafa',
                                colorCode: '#8904db',
                                dotType: 'plain',
                                permission: userPermission,
                                isUserSigned: isUserSigned,
                                status: 'accepted',
                                userId: updatedUserId,
                            },
                        },
                    ];
                }
            } else if (action === 'UPDATE' && isReceivedFromWB !== true) {
                boardUsers = boardUsers.map((user) => {
                    if (user.info.userId === updatedUserId) {
                        const userInfo = { ...user.info, permission: userPermission };
                        user = { ...user, info: userInfo };
                    }

                    return user;
                });
            }

            if (userId === updatedUserId) {
                if (!isReceivedFromWB) {
                    userAccessRef.current = getHighestUserPermission(
                        userPermission,
                        boardState.publicSharePermission.users,
                        boardState.publicSharePermission.employees,
                        isBuilderAiUser(activeUser?.email)
                    );
                } else {
                    userAccessRef.current = userPermission;
                }

                if (action === 'REMOVE' && !isUserHasAccessToBoardWhileNotMember({})) {
                    canvasInstanceRef.current?.clear();
                    canvasInstanceRef.current?.dispose();
                    socketRef.current?.disconnect();
                }

                dispatch({
                    type: 'user/changePermission',
                    payload: userAccessRef.current,
                });
            }

            dispatch({
                type: 'board/changeBoardDetails',
                payload: { users: boardUsers },
            });

            if (isReceivedFromWB !== true) {
                dispatch({
                    type: 'board/changeBoardDetails',
                    payload: { isPublicAccess },
                });
            }
        },
        [
            board.users,
            board.publicShareKey,
            board.publicSharePermission?.users,
            board.publicSharePermission?.employees,
            activeUser?.id,
            activeUser?.email,
            userId,
            board.isPublicAccess,
            board.isPublicLink,
            dispatch,
        ]
    );

    const handleCloseFormInputWrapper = () => {
        const formInput = document.querySelector('.commentFormWrapper');
        if (formInput) {
            commentFormWrapperRef.current.style.display = 'none';
            setCommentState('initial');
        }
    }

    const handleChangeSelectMode = useCallback((selectMode, previousWindowState) => {
        selectMode !== 'comment' && previousWindowState === 'commentDrawer' && handleToggleCommentDrawer({ shouldShow: false })
        const mode = selectMode === 'comment' ? 'comment_modification' : selectMode;
        if (handleToolbarItemVisibility(mode)) { return; }
        isLassoRef.current = false;
        if (canvas) { removeAllExistedLasso(canvas); }
        eventEmitter.fire(EMITTER_TYPES.CHANGE_SELECT_MODE, selectMode)
        changeCursor(selectMode, canvas);
        if (selectMode === 'pan') {
            handleCloseFormInputWrapper();
            setActionSelected({
                'dragMode': true
            });
            dispatch({
                type: 'board/changeCurrentMode',
                payload: modes.PAN
            });
        } else if (selectMode === 'comment')  {
            setActionSelected({
                'commentMode': true
            });
            dispatch({
                type: 'board/changeCurrentMode',
                payload: modes.COMMENT
            });
        } else { // normal select mode
            setActionSelected({
                'selectMode': true
            });
            dispatch({
                type: 'board/changeCurrentMode',
                payload: modes.SELECT
            });
            handleCloseFormInputWrapper();
        }
    }, [dispatch, handleToolbarItemVisibility, canvas]);

    const handlePencilAndShapesMode = useCallback((shape, previousWindowState) => {
        handleCloseFormInputWrapper();
        previousWindowState === 'commentDrawer' && handleToggleCommentDrawer({ shouldShow: false });
        isLassoRef.current = false;
        if (handleToolbarItemVisibility(shape.shapeButton)) { return; }
        changeCursor(shape.shapeMode, canvas);
        setActionSelected({ [shape.shapeMode]: true,'lassoMode':false});
        eventEmitter.fire(EMITTER_TYPES.CREATE_GENERIC_SHAPE, shape);
        
        switch (shape.shapeButton) {
            case modes.TRIANGLE:
            case modes.RECTANGLE:
            case modes.ELLIPSE:
            case modes.PARALLELOGRAM:
            case modes.RHOMBUS:
                setShapeSelection(shape);
                break;
            case modes.PENCIL:
            case modes.ERASER:
                setPencilSelection(shape);
                break;
            case modes.LINE:
            case modes.ARROW:
                setArrowSelection(shape);
                break;
            default:
                break;
        }
    }, [handleToolbarItemVisibility, canvas]);

    /**
     * @param {'frame'|'ARROW'|'Sticky'|'text'|'table'} type 
     */
    const handleAddObject = useCallback((type, options = {}, previousWindowState) => {
        handleCloseFormInputWrapper();
        if (previousWindowState === 'commentDrawer') handleToggleCommentDrawer({ shouldShow: false })
        if (handleToolbarItemVisibility(type)) { return; }
        changeCursor(type, canvas);

        const modes = { frame: 'frameMode', Sticky: 'stickyMode', text: 'textMode', ARROW: 'arrowMode', table: 'tableMode' }
        if (modes[type]) {
            setActionSelected({
                [modes[type]]: true
            });
        }
        eventEmitter.fire(EMITTER_TYPES.CREATE_SHAPE, { type, options });
    }, [handleToolbarItemVisibility, canvas, activeWindowState])

    const handleAddComment = useCallback((oldValue, value, taggedUsers) => {
        handleAddCommentCallback({
            canvas,
            userId,
            activePageId,
            value,
            oldValue,
            commentInputClickedPos,
            whiteBoardId,
            commentFormWrapperRef,
            uuidGenerator,
            currentUser,
            taggedUsers,
            socketRef,
            setSelectedCommentIcon,
            hideComments,
            setComments
        })
    }, [commentInputClickedPos, canvas, commentFormWrapperRef, userId, whiteBoardId, uuidGenerator, currentUser, hideComments, activePageId]);

    const handleAddReply = useCallback((oldValue, value, taggedUserIds, parentUuid, parentId) => {
        handleAddReplyCallback({
            replyUuid: uuidv4(),
            value,
            oldValue,
            userId,
            parentUuid,
            whiteBoardId,
            currentUser,
            taggedUserIds,
            socketRef,
            setComments,
            setSelectedComment,
            activePageId,
            parentId
        });
    }, [userId, whiteBoardId, currentUser, setSelectedComment, activePageId, selectedComment])

    const handleReadComment = useCallback((commentUuid) => {
        handleReadCommentCallback(commentUuid, socketRef, canvas);
        eventEmitter.fire(EMITTER_TYPES.CHECK_UNREAD_COMMENTS);
    }, [canvas, socketRef]);

    const handleReadAllComments = useCallback(async (searchValue, filter) => {
        handleReadAllCommentsCallback(socketRef, canvas, activePageId, searchValue, filter);
        eventEmitter.fire(EMITTER_TYPES.CHECK_UNREAD_COMMENTS);
    }, [canvas, activePageId]);

    const handleMarkAsUnreadComment = (commentUuid, badgeText) => {
        handleMarkAsUnreadCommentCallback(commentUuid, badgeText, socketRef, canvas);
        eventEmitter.fire(EMITTER_TYPES.CHECK_UNREAD_COMMENTS);
    }

    const handleUpdateCommentContent = useCallback((data) => {
        handleUpdateCommentContentCallback(data, socketRef, setUpdatedComment);
    }, [setUpdatedComment]);

    const handleResolveComment = useCallback((commentUuid, resolved, colorCode) => {
        handleResolveCommentCallback(commentUuid, resolved, socketRef, setResolvedComment, colorCode, canvas);
    }, [setResolvedComment]);

    const handleDeleteComment = useCallback((commentUuid, parentUuid) => {
        handleDeleteCommentCallback(
            commentUuid, 
            parentUuid, 
            socketRef, 
            setDeletedComment, 
        );
    }, [setDeletedComment]);

    const handleToggleCommentDrawer = ({ shouldShow }) => {
        handleCommentElementsPositioningCalculation({ shouldShow });
        dispatch({
            type: 'modal/toggleCommentDrawer',
            payload: { shouldShow }
        });
        if (shouldShow) handleChangeSelectMode('comment');
        else {
            handleCloseFormInputWrapper();
            let currentMode
            if (isUserHasAccessToFeature('select', userAccessRef.current)) currentMode = { mode: 'selectMode', cursor: 'select', payload: modes.SELECT }
            else currentMode = { mode: 'dragMode', cursor: 'pan', payload: modes.PAN }
            setActionSelected({
                [currentMode.mode]: true
            });
            changeCursor(currentMode.cursor, canvas);
            dispatch({
                type: 'board/changeCurrentMode',
                payload: currentMode.payload
            });
            eventEmitter.fire(EMITTER_TYPES.CHANGE_SELECT_MODE, currentMode.cursor);
        }
    }

    useEffect(() => {
        if (!canvas) { return; }
        canvas.on('toggle-comment-drawer', () => handleToggleCommentDrawer({ shouldShow: !displayCommentDrawer }));
        canvas.on('close-comment-drawer', () => handleToggleCommentDrawer({ shouldShow: false }));

        return () => {
            canvas.off('toggle-comment-drawer');
            canvas.off('close-comment-drawer');
        }
    }, [handleToggleCommentDrawer]);

    const handleCommentElementsPositioningCalculation = ({ shouldShow }) => {
        let createCommentForm = document.querySelector('.commentFormWrapper');
        const formStyle = window.getComputedStyle(createCommentForm);
        const matrix = new DOMMatrixReadOnly(formStyle.transform);
        let widthDeflection;
        if (createCommentForm) {
            if (shouldShow && activeWindowState !== 'commentDrawer') widthDeflection = -283;
        }
        createCommentForm.style.transform = `translate(${matrix.m41 + (widthDeflection)}px, ${matrix.m42}px)`
    }

    useEffect(() => {
        let commentWrapperWrap = document.querySelector('.commentWrapper--wrap');
        if (commentWrapperWrap) setTimeout(() => { setPositionOfHTMLElement(selectedCommentIcon, commentWrapperWrap) }, 500);
    }, [activeWindowState])

    const handleBoardData = useCallback((data, wbId, appName) => {
        if (userAccessRef.current === USER_ROLES.notAllowed.id) return;

        setWhiteBoardId(() => data.id);
        setWhiteBoardSlugId(wbId);
        setBoardName(data.name);
        setWbOwnerId(data.ownerId);
        initializePagesRef.current(data);

        const usersData = data.users.map((user) => {
            if (user?.info?.isUserSigned === false && user?.name === 'FirstName') { // If user is not signed up yet, then replace commentUsername with email
                user.commentUsername = user.email;
            }

            return user;
        });

        const loggedUser = loggedInUser();

        if (board.isPublicAccess && loggedUser && !usersData.some((user) => user.id === loggedUser.id)) {
            usersData.push({
                email: loggedUser.email,
                commentUsername: loggedUser.name,
                id: loggedUser.id,
                name: loggedUser.name,
                joinedViaPublicLink: true,
                info: {
                    backgroundColor: '#fafafa',
                    colorCode: '#8904db',
                    dotType: 'plain',
                    permission: userAccessRef.current,
                    isUserSigned: true,
                    status: 'accepted',
                    userId: loggedUser.id,
                }
            })
        }

        dispatch({
            type: 'board/changeBoardDetails',
            payload: {
                boardName: data.name,
                publicShareKey: data.publicShareKey,
                publicSharePermission:
                    data.publicSharePermission || DEFAULT_SHARED_PERMISSIONS,
                userFlowDiagram_status:
                    data?.properties?.userFlowDiagram_status || 'pending',
                users: usersData,
                whiteBoardId: data.id,
                whiteBoardSlugId: data.wbId,
                whiteBoardOwnerId: data.ownerId,
                studioBuildCardID: data.studio_build_card_id,
                appName
            },
        });

        if (Array.isArray(data?.onlineUsers) && data.onlineUsers.length > 0) {
            dispatch({
                type: 'collaborators/initCollaborators',
                payload: {
                    collaborators: data.onlineUsers
                }
            });
        }
    }, [dispatch, setWhiteBoardId, setWhiteBoardSlugId, setBoardName, setWbOwnerId, board.isPublicAccess]);

    const isPageActive = useCallback((pageId) => {
        const page = pages.find((page) => page.id === pageId);
        if (!page) return false;

        return page.active === true;
    }, [pages]);

    const isPageRendering = useCallback((pageId, renderingPageId) => {
        const page = pages.find((page) => page.id === pageId);
        if (!page) return false;

        return page.active === true && renderingPageId === pageId;
    }, [pages]);

    const getPageCanvas = useCallback((pageId) => {
        const page = pages.find((page) => page.id === pageId);
        if (!page) return canvas;

        const canvasInstance = canvasesRef.current[page.wbPageId];
        return canvasInstance || canvas;
    }, [pages, canvas]);

    const toggleLasso = useCallback((previousWindowState) => {
        handleCloseFormInputWrapper();
        previousWindowState === 'commentDrawer' && handleToggleCommentDrawer({ shouldShow: false });
        setActionSelected({ 'lassoMode': true });
        isLassoRef.current = true;
        eventEmitter.fire(EMITTER_TYPES.CREATE_GENERIC_SHAPE, { shapeButton: 'LASSO' });
        changeCursor('lasso', canvas);
    }, [canvas])

    /**
    * @param wbId
    */
    const handleGetWhiteBoardDetails = (wbId) => {
        getWhiteBoardDetails(wbId).then(data => {
            if (!isObjectEmpty(data) && !data?.response?.status) {
                handleUserAccess(data.users, undefined, data);
                handleBoardData(data, wbId, APP_NAMES.APEIROS);
            } else if (data?.response?.status === 401) {
                setShowOverlay(true);
                navigate(`/request-access/${wbId}`);
            }
            else {
                setShowOverlay(true);
            }
        })
    }

    pageReferencesRef.current.isPageActive = isPageActive;
    pageReferencesRef.current.isPageRendering = isPageRendering;
    pageReferencesRef.current.getPageCanvas = getPageCanvas;

    initializePagesRef.current = useCallback((data, isReinit) => {
        if (userAccessRef.current === USER_ROLES.notAllowed.id) return;

        const { allPages, initPage } = data;
        allPages.sort((a, b) => a.id - b.id);

        const allPagesData = [];

        for (const page of allPages) {
            const isActive = page.id === initPage.id;
            const shapes = [];
            const stackOrder = [];

            allPagesData.push({
                ...page,
                shapes,
                stackOrder,
                active: isActive,
                isFetched: false
            });
        }

        currentPageId.current = initPage.id;

        dispatch({
            type: 'rightDrawer/initPages',
            payload: { pages: allPagesData, isReinit }
        })
    }, []);

    useEffect(() => {
        overrideFabric();
    }, []);
    
    useEffect(() => {
        emitModifiedDataRef.current = emitModifiedQueue;
    }, [emitModifiedQueue])

    useEffect(() => {
        const pattern = /(teamBoard|bmeetBoard|enterprise)/;

        if (!pattern.test(location.pathname)) {
            if(!loggedInUser()) {
                // save boardId and shape id as a query param to open the same board and shape after login
                const savingShapeUuid = getParamFromURL('shape', { testWithUuid: true });
                const boardId = getBoardSlugIdInURL();
                const urlCommentId = getParamFromURL('commentId');
                const urlPageId = getParamFromURL('pageId');
                if (savingShapeUuid && boardId) {
                    let url = `/?board=${boardId}&shape=${savingShapeUuid}`;
                    if (urlPageId) {
                        url += `&pageId=${urlPageId}`;
                    }

                    navigate(url);
                } else if (boardId.length > 16) {
                    navigate({
                        pathname: '/',
                        search: createSearchParams({
                            callback: encodeURIComponent(`/board/${boardId}`),
                        }).toString()
                    })
                } else if (boardId && urlCommentId && urlPageId) {
                    navigate({
                        pathname: '/',
                        search: createSearchParams({
                            callback: encodeURIComponent(`/board/${boardId}?pageId=${urlPageId}&commentId=${urlCommentId}`),
                        }).toString()
                    })
                } else if (boardId) {
                    navigate({
                        pathname: '/',
                        search: createSearchParams({
                            callback: encodeURIComponent(`/board/${boardId}`),
                        }).toString()
                    })
                } else {
                    navigate('/');
                }
            }
        }
        if(location.pathname.includes('teamBoard')){
            const value = getUserQueryParams(location.search);
      
            builderLoginAutomated(value).then(data=>{
                const externalUserId = data.id;
                setUserId(data.id);
                setAuthToken(data.authToken);
                dispatch({ type: 'user/setUser', payload: data });
                let wbIdFrame = currentURLNodes[1];
                automatedBoard(wbIdFrame, value.params.get('build_card_name'), null, value.params.get('external_id')).then(data=>{
                    if (!isObjectEmpty(data)) {
                        handleUserAccess(data.users, externalUserId, data, value?.email);
                        setWbId(data.wbId);
                        handleBoardData(data, data.wbId, APP_NAMES.TEAMBOARD);
                    // setShowLoader(false);
                    } else {
                    // setShowLoader(false);
                        setShowOverlay(true);
                    }
                })
            })
        }
        else if(location.pathname.includes('bmeetBoard')){
            const email = getUserQueryParams(location.search)?.email,
                wbId = location.pathname.split('/')[1];
      
            automatedBMeetBoard(wbId, email)
                .then(data => {
                    if (data?.success) {
                        const externalUserId = data?.userData?.id;
                        handleUserAccess(data?.whiteboardData?.users, externalUserId, data, email);

                        setUserId(data?.userData?.id);
                        setAuthToken(data?.userData?.authToken);
                        dispatch({ type: 'user/setUser', payload: data?.userData });
                        setWbId(data?.whiteboardData?.wbId);
                        handleBoardData(data?.whiteboardData, data?.whiteboardData?.wbId, APP_NAMES.BMEET);
                    }
                    else if (data.message) {
                        setShowOverlay(true);

                        let closable = true;
                        if (typeof data.message === 'string' && data.message.indexOf(AUTH_VALIDATION_MESSAGE.BOARD_UNAUTHORIZED) > -1) {
                            closable = false;
                        }

                        toast.error(data.message, {
                            className: 'wb_toast',
                            autoClose: false,
                            closeButton: closable,
                            draggable: false,
                            hideProgressBar: true,
                            icon: getToastIcon('error')
                        });
                    } else {
                        setShowOverlay(true);
                    }
                })
                // .finally(() => {
                //     setShowLoader(false);
                // });
        }
        else if (location.pathname.includes('enterprise')) {
            const value = getUserQueryParams(location.search);

            enterpriseLoginAutomated(value).then(data => {
                const externalUserId = data.id;
                setUserId(data.id);
                setAuthToken(data.authToken);
                dispatch({ type: 'user/setUser', payload: data });
                let wbIdFrame = location.pathname.split('/')[1];
                automatedEnterpriseBoard(wbIdFrame, value?.permission).then(data => {
                    handleUserAccess(data?.whiteboardData?.users, externalUserId);
                    setWbId(data?.whiteboardData?.wbId);
                    handleBoardData(data?.whiteboardData, data?.whiteboardData?.wbId, APP_NAMES.ENTERPRISE);
                    setShowLoader(false);
                });
            });
        }
        else{
            let wbId = location.pathname.split('/')[2];
            if (wbId) handleGetWhiteBoardDetails(wbId);
            else navigate('/boards');
        }

    }, [location, navigate, dispatch, setWhiteBoardId, handleBoardData]);

    // handle socket connection
    useEffect(() => {
        if (whiteBoardId && !isSocketConnected) {
            socketRef.current = io(environment.REACT_APP_API_ENDPOINT, {
                transports: ['websocket'], // use WebSocket first, if available
            });
    
            socketRef.current.on('connect', () => {
                let body = { roomId: whiteBoardId, userId: userId };
                if (board.isPublicAccess) body = { ...body, pSharedId: board.publicShareKey };

                socketRef.current.emit('join', body, () => {
                    dispatch({
                        type: 'socket/changeMode',
                        payload: SOCKET_STATUS_MODS.CONNECTED
                    });

                    lockManagerRef.current.setSocket(socketRef.current);
                    setIsJoinedToSocketRoom(true);
                });
            });
            socketRef.current.on('disconnect', (reason) => {
                // if the user is disconnected by unmointing this component, we dont need to show the overlay
                if (reason && reason === 'io client disconnect') {
                    return;
                }
                dispatch({
                    type: 'socket/changeMode',
                    payload: SOCKET_STATUS_MODS.DISCONNECTED
                }); 
            });
            socketRef.current.io.on('reconnect_attempt', () => {
                dispatch({
                    type: 'socket/changeMode',
                    payload: SOCKET_STATUS_MODS.RECONNECT_ATTEMPT
                }); 
            });
            socketRef.current.on(SOCKET_EVENT.USER_INVITED, (data) => {
                toast.update('unauthorized_user', { autoClose: 1000 });
                setShowOverlay(false);
                onUserInvited(handleDecodingOfInviteSocketEvent(data), onMemberListUpdated, userId);
                eventEmitter.fire('updateRequestList', data);
            });

            socketRef.current.on(SOCKET_EVENT.USER_ROLE_UPDATED, (data) => onUserPermissionUpdated(
                data,
                onMemberListUpdated,
                userId,
                forceUpdate,
                setSelectedComment,
                setSelectedCommentIcon,
                handleChangeSelectMode
            ));

            socketRef.current.on(SOCKET_EVENT.WP_PERMISSIONS_UPDATED, (data) => onWBPermissionUpdated(
                data,
                onMemberListUpdated,
                userId,
                forceUpdate,
                setSelectedComment,
                setSelectedCommentIcon,
                handleChangeSelectMode,
                isBuilderAiUser(activeUser?.email)
            ));
      
            socketRef.current.on(SOCKET_EVENT.USER_ROLE_REMOVED, (data) => onUserRemoved(
                data,
                navigate,
                whiteBoardSlugId || '',
                forceUpdate,
                onMemberListUpdated,
                userId
            ));
      
            socketRef.current.on(SOCKET_EVENT.OWNERSHIP_TRANSFFERRED, (data) => onWbOwnershipTransferred(
                data,
                forceUpdate,
                setWbOwnerId,
                (newOwnerId) => {
                    dispatch({
                        type: 'board/changeBoardDetails',
                        payload: {
                            whiteBoardOwnerId: newOwnerId
                        },
                    });
                }
            ));

            socketRef.current.on(SOCKET_EVENT.WHITEBOARD_STATUS, (data) => {
                if (!data) return;
                if (data.isDeleted) {
                    setShowOverlay(true);
                    dispatch({
                        type: 'modal/toggleInvite',
                        shouldShow: false
                    })
                    toast.error((
                        <>
                            {data.message}
                            <Link to="/boards" style={{ marginLeft: 3 }}>My Boards</Link>
                        </>
                    ), {
                        toastId: 'board-deleted',
                        autoClose: false,
                        closeOnClick: false,
                        draggable: false,
                        hideProgressBar: true,
                        closeButton: true,
                        position: 'bottom-center',
                        className: 'wb_toast',
                        icon: getToastIcon('error')
                    });
                }
                else if (data.isRestored) {
                    setShowOverlay(false);
                    toast.success((
                        <>
                            {data.message}
                        </>
                    ), {
                        toastId: 'board-restored',
                        autoClose: false,
                        closeOnClick: false,
                        draggable: false,
                        hideProgressBar: true,
                        closeButton: true,
                        position: 'bottom-center',
                        className: 'wb_toast',
                        icon: getToastIcon('success')
                    });
                }
            })

            socketRef.current.on(SOCKET_EVENT.ON_COLLABORATORS_UPDATED, (data = {}) => {
                const { operation, onlineUsers, ...collaborator } = data;
                if (!operation || !collaborator) return;

                dispatch({
                    type: `collaborators/updateCollaborators`,
                    payload: { collaborators: onlineUsers?.data, signedUserId: userId }
                });
            });

            socketRef.current.on(SOCKET_EVENT.UPDATE_PUBLIC_USER_PERMISSION, (data) => {
                if (
                    hasPublicAccess() &&
                    !isUserHasAccessToBoardWhileNotMember({ userPermission: data?.users, builderUserPermission: data?.employees })
                ) {
                    let wbId = location.pathname.split('/')[2];
                    navigate(`/request-access/${wbId}`);
                    userAccessRef.current = USER_ROLES.notAllowed.id;

                    canvasInstanceRef.current?.dispose();
                    socketRef.current?.disconnect();
                    return;
                }

                dispatch({
                    type: 'board/changeBoardDetails',
                    payload: {
                        publicSharePermission: data,
                    },
                });
            });

            socketRef.current.on('requestsList', (data) => {
                eventEmitter.fire('updateRequestList', data);
                dispatch({
                    type: 'board/changeBoardDetails',
                    payload: { requestList: data.requests }
                });
                const requestStatus = data.status
                if (data.requests.length === 0) {
                    toast.dismiss();
                    return
                }
                const accessRequestToast = document.querySelector('.access-requests_toast__text');
                if (requestStatus === 'byRequester') {
                    const isInviteModalOpen = document.querySelector('.inviteModal')
                    if (!accessRequestToast && !isInviteModalOpen) {
                        handleShowRequestAccessToast(data.requests);
                    }
                }
                if (accessRequestToast) {
                    const accessRequestToastText = document.querySelector('.access-requests_toast__text--main');
                    if (accessRequestToast) {
                        accessRequestToastText.innerHTML = `<span>${data.requests.length > 1 ? `${data.requests.length} pending` : data.requests[0].email}</span> ${data.requests.length === 1 ? ' is requesting access' : ' access requests'}`;
                    }
                }
            })

            socketRef.current.on('addComment', (data) => {
                onAddComment({
                    data,
                    pageReferencesRef,
                    onAddComment,
                    setSelectedCommentIcon,
                    userId,
                    setAddedComment,
                    shouldHideComments: hideComments,
                    shouldHideResolvedComments: hideResolvedComments,
                });
                eventEmitter.fire('addComment', data);
            });

            socketRef.current.on('resolveComment', (data) => {
                setResolvedComment(data.updatedCommentData);
                eventEmitter.fire('resolveComment', data);
            });
            socketRef.current.on('updateComment', (data) => {
                setUpdatedComment(data.updatedCommentData);
                eventEmitter.fire('updateComment', data);
            });
            socketRef.current.on('deleteComment', (data) => {
                setDeletedComment({...data.updatedCommentData, triggeredBy: 'socketListener'})
            });

            socketRef.current.on('migration_status', (data) => {
                dispatch({
                    type: 'board/changeBoardDetails',
                    payload: {
                        nimaImportStatus: data?.migration_status,
                        nimaImportUpdatedAt: data?.migration_status_updatedAt,
                        nimaImportUpdatedBy: data?.migration_status_updatedBy
                    }
                })
            })

            socketRef.current.on('sendBackFront', (data) => {
                eventEmitter.fire('sendBackFront', data)
            })

            socketRef.current.on(SOCKET_EVENT.COLOR_SELECTED, (data) => {
                if (Array.isArray(data?.colors) && data.colors.length > 0) {
                    dispatch({
                        type: 'board/updateRecentColorList',
                        payload: { colors: data.colors }
                    });
                }
            })

            setIsSocketConnected(true);
        }
    }, [
        activePageId,
        userId,
        whiteBoardId,
        canvas,
        hideComments,
        hideResolvedComments,
        isSocketConnected,
        board.users,
        board.publicShareKey,
        board.publicSharePermission.employees,
        board.publicSharePermission.users,
        activeUser?.email,
        board.isPublicAccess,
        board.isPublicLink,
        currentURLNodes,
        dispatch,
        handleChangeSelectMode,
        onMemberListUpdated,
        setAddedComment,
        setDeletedComment,
        setResolvedComment,
        setUpdatedComment,
        onAddComment
    ])

    useEffect(() => {
        
        const lockObjectsHandler = (canvas, shapes, status, userId) => {
            // find user
            const user = users.find(u => u.id === userId);
            if (!user) {
                return
            }
            
            // find shapes in canvas
            const shapesInCanvas = shapes.map(uuid => uuid ? getFabricObject(canvas, 'uuid', uuid) : null);

            // we need to disable undo action for locked objects
            canvas.fire('remove-from-undo-redo-stack', {shapes: shapesInCanvas});

            const activeObject = canvas.getActiveObject();

            // if active object has anything related to locked objects, remove it from active object
            if (activeObject) {
                if (activeObject.type === 'activeSelection') {
                    const updatedObjects = activeObject.getObjects();
                    for (const obj of updatedObjects) {
                        if (shapes.includes(obj.uuid)) {
                            activeObject.removeWithUpdate(obj)
                        }
                    }
                } else {
                    if (shapes.includes(activeObject.uuid)) {
                        canvas.discardActiveObject().requestRenderAll();
                    }
                }
            }

            if (status === 'freeze') {
                lockManagerRef.current.lockObjectsByUuid(canvas, shapes, {
                    id: user.id,
                    name: user.name
                })
                canvas.renderAll()
            } else if (status === 'unfreeze') {
                lockManagerRef.current.unlockObjectsByUuid(canvas, shapes);
                canvas.renderAll()
            }
        }
        
        if (canvas) {
            // if there are pending shapes to lock, lock them
            // this can happen when this canvas is not initiated yet but shapes are already locked
            if (waitingToLockObjects.current && waitingToLockObjects.current.has(canvas.pageId)) {
                const waitingShapesMap = waitingToLockObjects.current.get(canvas.pageId);
                const waitingShapes = waitingShapesMap.get('shapes');
                lockObjectsHandler(canvas, Array.from(waitingShapes), 'freeze', waitingShapesMap.get('userId'));
            }
        }
        
        if (isSocketConnected) {
            const collabLockHandler = data => {
                const decompressedData = decompressData(data);
                if (!decompressedData?.status) {
                    return
                }
                
                const shapes = decompressedData.shapes?.map(shape => shape?.shapeUUID);
                
                // if the canvas is not initiated yet, we need to wait until it is initiated
                if (!canvas || canvas.pageId !== decompressedData.pageId) {
                    // add shapes to waiting list
                    if (decompressedData.status === 'freeze') {
                        if (!waitingToLockObjects.current.has(decompressedData.pageId)) {
                            waitingToLockObjects.current.set(decompressedData.pageId, new Map());
                            const waitingShapes = waitingToLockObjects.current.get(decompressedData.pageId);
                            waitingShapes.set('shapes', new Set())
                            waitingShapes.set('userId', decompressedData.userId)
                        }
                        for (const shape of shapes) {
                            const waitingShapes = waitingToLockObjects.current.get(decompressedData.pageId);
                            waitingShapes.get('shapes').add(shape);
                        }
                    } else if (decompressedData.status === 'unfreeze') {
                        if (waitingToLockObjects.current.has(decompressedData.pageId)) {
                            const waitingShapes = waitingToLockObjects.current.get(decompressedData.pageId).get('shapes');
                            for (const shape of shapes) {
                                waitingShapes.delete(shape);
                            }
                            if (waitingShapes.size === 0) {
                                waitingToLockObjects.current.delete(decompressedData.pageId);
                            }
                        }
                    }
                    
                } else {
                    // if the canvas is initiated, we can lock/unlock the shapes
                    lockObjectsHandler(canvas, shapes, decompressedData.status, decompressedData.userId);
                }
            }
            socketRef.current.on('collabLock', collabLockHandler);
            
            return () => {
                socketRef.current.off('collabLock', collabLockHandler);
            }
        }
    }, [isSocketConnected, canvas]);

    /**
     * @param {array} requests 
     */
    const handleShowRequestAccessToast = (requests) => {
        if (window.location.pathname.split('/')[1] === 'board') {
            toast.info(
                <span className="access-requests_toast__text">
                    <span className="access-requests_toast__text--main"><span>{requests.length > 1 ? `${requests.length} pending` : requests[0].email}</span> {requests.length === 1 ? 'is requesting access' : 'access requests'}</span>
                    <span onClick={handleRequestAccessAction} className="access-requests_toast__button toast_btn">View</span>
                </span>,
                {
                    icon: false,
                    autoClose: false,
                    className: 'wb_toast wb_toast__access-requests',
                    draggable: false,
                    toastId: `requests-success-${Math.random()}`
                });
        }
    }

    const handleRequestAccessAction = () => {
        closeRequestAccessToast();
        dispatch({
            type: 'modal/toggleInvite',
            payload: { shouldShow: true }
        })
    }

    const handleGetPendingRequestList = () => {
        const inviteModal = document.querySelector('.inviteModal');
        pendingRequestList(whiteBoardSlugId).then(data => {
            const requestList = data.data;
            if (!requestList) return;
            dispatch({
                type: 'board/changeBoardDetails',
                payload: { requestList }
            })
            if (requestList.length > 0 && !inviteModal) handleShowRequestAccessToast(requestList);
        });
    }

    //load request list only for owner
    useEffect(() => {
        if (whiteBoardSlugId && (board?.whiteBoardOwnerId === activeUser?.id) && !showLoader && !requestAccessModal) {
            closeRequestAccessToast();
            handleGetPendingRequestList();
            dispatch({
                type: 'modal/toggleRequestAccessModal',
                payload: true
            })
        }
    }, [whiteBoardSlugId, showLoader])

    useEffect(() => {
        eventEmitter.on('pullRequestList', handleGetPendingRequestList);

        return () => eventEmitter.off('pullRequestList', handleGetPendingRequestList);
    } , [whiteBoardSlugId])

    useEffect(() => {
        const changeActionModeHandler = (actionMode) => {
            const mode = actionMode === 'comment' ? 'comment_modification' : actionMode;
            if (handleToolbarItemVisibility(mode)) { return; }

            if (actionMode === 'Sticky') {
                handleAddObject('Sticky');
            } else if (actionMode === 'Eraser' || actionMode === 'Pen' || actionMode === 'Rectangle' || actionMode === 'Ellipse' || actionMode === 'Triangle' || actionMode === 'Connector' || actionMode === 'Line') {
                const shape = constShapes.find(shape => shape.shapeName === actionMode);
                handlePencilAndShapesMode(shape, activeWindowState);
            } else if (actionMode === 'pan' || actionMode === 'select' || actionMode === 'comment') {
                handleChangeSelectMode(actionMode);
            } else if (actionMode === 'Text') {
                handleAddObject('text');
            } else if (actionMode === 'lasso') {
                toggleLasso(activeWindowState);
            }
        }
        eventEmitter.on(EMITTER_TYPES.CHANGE_ACTION_MODE, changeActionModeHandler);

        return () => {
            eventEmitter.off(EMITTER_TYPES.CHANGE_ACTION_MODE, changeActionModeHandler);
        }
    }, [handleAddObject, handleChangeSelectMode, handleToolbarItemVisibility, handlePencilAndShapesMode, toggleLasso])

    useEffect(() => {
        const handleUnload = () => {
            if (userId) {
                if (canvas) { canvas.fire('before-reload-canvas'); }
                trackAction(
                    SEGMENT_ANALYTICS_CONSTANTS.WHITEBOARD_REFRESHED,
                    SEGMENT_ANALYTICS_CONSTANTS.WHITEBOARD_REFRESHED_DESCRIPTION,
                    {
                        [SEGMENT_ANALYTICS_CONSTANTS.USER_ID] : userId,
                        [SEGMENT_ANALYTICS_CONSTANTS.WHITEBOARD_ID] : whiteBoardId,
                        [SEGMENT_ANALYTICS_CONSTANTS.USER_BROWSER]: getBrowserName(),
                        [SEGMENT_ANALYTICS_CONSTANTS.USER_DEVICE]: getDeviceName(),
                    }
                );
            }
        };
    
        window.addEventListener('beforeunload', handleUnload);
    
        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, [userId, whiteBoardId, canvas]);

    useEffect(() => {
        const beforePageChange = () => {
            eventEmitter.fire(EMITTER_TYPES.CHANGE_ACTION_MODE, 'select');
            canvas.discardActiveObject().renderAll();
            setSelectedCommentIcon(null);
            fitToScreenAssistance.destroy(true)
        };

        eventEmitter.on(EMITTER_TYPES.PAGE_CHANGED, beforePageChange);

        return () => {
            eventEmitter.off(EMITTER_TYPES.PAGE_CHANGED, beforePageChange);
        }
    }, [canvas, setSelectedCommentIcon]);
    
    useEffect(() => {
        const fn = () => {
            let wbId = location.pathname.split('/')[2];
            navigate(`/request-access/${wbId}`);
            return;
        }

        eventEmitter.on(EMITTER_TYPES.DEAUTHORIZE_THE_USER, fn);

        return () => {
            eventEmitter.off(EMITTER_TYPES.PAGE_CHANGED, fn);
        }
    }, []);

    useEffect(() => {
        if (selectedCommentIcon) {
            const deselectComments = () => {
                setSelectedCommentIcon(null)
            }
            eventEmitter.on(EMITTER_TYPES.DESELECT_COMMENTS, deselectComments)

            return () => {
                eventEmitter.off(EMITTER_TYPES.DESELECT_COMMENTS, deselectComments)
            }
        }
    }, [canvas, selectedCommentIcon]);

    useEffect(() => {
        return () => {
            setShowOverlay(false);
            fitToScreenAssistance.destroy(true)
            dispatch({
                type: 'rightDrawer/resetPagesData',
                payload: {}
            })

            dispatch({
                type: 'collaborators/clearCollaborators',
                payload: []
            });

            dispatch({
                type: 'board/changeIsPublicAccess',
                payload: null
            })

            dispatch({
                type: 'modal/toggleRequestAccessModal',
                payload: false
            })
            
            dispatch({
                type: 'board/resetPublicAccess',
                payload: []
            });

            // Reset all board state to initial board state
            dispatch({ type: 'board/resetBoardState' });

            // handle disconnecting socket on unmount
            if (socketRef?.current) {
                try {
                    socketRef?.current?.disconnect();
                } catch (e) {
                    console.error('error while disconnecting', e)
                }
            }
        }
    }, []);

    useShortCuts(
        canvas, 
        useCallback(mapFieldsToDrawInstance, [canvas, whiteBoardId, userId, uuidGenerator, SubToolbar]), // eslint-disable-line react-hooks/exhaustive-deps,
        userId,
        whiteBoardId,
        uuidGenerator,
        userAccessRef,
        activePageId,
        actionSelected,
        emitData,
    );

    const activePages = pages ? pages.filter((page) => page.active) : [];

    const isLeftDrawerOpen = activeWindowState === MODAL_WINDOWS.ACTIVITY_LOGS;
    const setIsLeftDrawerOpen = useCallback((value) => {
        dispatch({
            type: 'modal/toggleLeftDrawer',
            payload: {
                shouldShow: value
            }
        });
    }, [dispatch]);

    useEffect(() => {
        dispatch({
            type: 'modal/resetWindowState'
        })
    }, [activePageId])

    const isMinimapOpen = activeWindowState === MODAL_WINDOWS.MINI_MAP;

    return (
        <>
            {
                (showLoader && showOverlay !== true) ? <div className="loadingScreen">
                    <LoadingScreen />
                </div> : null
            }
            <div className="whiteboardContainer">
                <div className="whiteboard">
                    {(whiteBoardSlugId) ? <Header
                        canvas={canvas}
                        activePageId={activePageId}
                        boardName={boardName}
                        isLeftDrawerOpen={isLeftDrawerOpen}
                        location={location}
                        onMemberListUpdated={onMemberListUpdated}
                        shouldShowFeedBack={activeWindowState === MODAL_WINDOWS.FEEDBACK}
                        socketRef={socketRef}
                        userAccessRef={userAccessRef}
                        userId={userId}
                        userIsCommenting={actionSelected.commentMode}
                        wbOwnerId={wbOwnerId}
                        whiteBoardId={whiteBoardId}
                        whiteBoardSlugId={whiteBoardSlugId}
                        selectedCommentIcon={selectedCommentIcon}
                        handleToggleCommentDrawer={handleToggleCommentDrawer}
                        unreadCommentCount={unreadCommentCount}
                        shouldHideComments={hideComments}
                        shouldHideResolvedComments={hideResolvedComments}
                        setSelectedCommentIcon={setSelectedCommentIcon}
                    /> : null
                    }
                    <Toolbar
                        actionSelected={actionSelected}
                        selectedShape={selectedShape}
                        selectedPencilType={selectedPencilType}
                        handleChangeSelectMode={handleChangeSelectMode}
                        handleToolbarItemVisibility={handleToolbarItemVisibility}
                        handlePencilAndShapesMode={handlePencilAndShapesMode}
                        handleAddObject={handleAddObject}
                        isLassoRef={isLassoRef}
                        userAccessRef={userAccessRef}
                        activePageId={activePageId}
                    />
                    {
                        isSocketConnected ? activePages.map(page => (
                            <Board
                                actionSelected={actionSelected}
                                canvasRef={canvasRef}
                                commentFormWrapperRef={commentFormWrapperRef}
                                commentInputClickedPos={commentInputClickedPos}
                                commentResults={commentResults}
                                commentWrapperRef={commentWrapperRef}
                                comments={comments}
                                emitData={emitData}
                                emitQueue={emitQueue}
                                eventQueueRef={eventQueueRef}
                                forceUpdate={forceUpdate}
                                getPageCanvas={getPageCanvas}
                                handleToolbarItemVisibility={handleToolbarItemVisibility}
                                handleUserAccess={handleUserAccess}
                                hideComments={hideComments}
                                hideResolvedComments={hideResolvedComments}
                                isJoinedToSocketRoom={isJoinedToSocketRoom}
                                isLassoRef={isLassoRef}
                                isPageRendering={isPageRendering}
                                key={page.id}
                                mapFieldsToDrawInstance={mapFieldsToDrawInstance}
                                pageData={page}
                                pageId={page.id}
                                queueSetRef={queueSetRef}
                                selectedArrowType={selectedArrowType}
                                selectedComment={selectedComment}
                                selectedCommentIcon={selectedCommentIcon}
                                setAddedComment={setAddedComment}
                                setCommentResults={setCommentResults}
                                setCommentState={setCommentState}
                                setComments={setComments}
                                setResolvedComment={setResolvedComment}
                                setSelectedComment={setSelectedComment}
                                setSelectedCommentIcon={setSelectedCommentIcon}
                                setShowLoader={setShowLoader}
                                shouldLoadData={page.isFetched !== true ? page.id === activePageId : null}
                                socketRef={socketRef}
                                boardOptionsRef={boardOptions}
                                userAccessRef={userAccessRef}
                                userId={userId}
                                uuidGenerator={uuidGenerator}
                                wbOwnerId={wbOwnerId}
                                whiteBoardId={whiteBoardId}
                                whiteBoardSlugId={whiteBoardSlugId}
                                isFrameNotFoundFlowRunOnce={isFrameNotFoundFlowRunOnce}
                                lockManager={lockManagerRef.current}
                            />
                        )) : null
                    }

                    {(canvas && canvas.isCanvasInitalized) ? <BottomBars
                        canvas={canvas}
                        canvasRef={canvasRef}
                        isLeftDrawerOpen={isLeftDrawerOpen}
                        onActivityLogButtonClicked={(value) => setIsLeftDrawerOpen(value)}
                        handleToggleCommentDrawer={handleToggleCommentDrawer}
                        userAccessRef={userAccessRef}
                    /> : null}

                    {(canvas && canvas.isCanvasInitalized) ? <HyperLinkBox actionSelected={actionSelected} canvas={canvas} /> : null}

                    {(!actionSelected.commentMode) ? <SubToolbarErrorBoundary fallback={null}><SubToolbar
                        canvas={canvas}
                        userAccess={userAccessRef.current}
                        socketRef={socketRef}
                        whiteBoardId={whiteBoardId}
                    /></SubToolbarErrorBoundary> : null
                    }
                    <CommentInputWrapper 
                        handleAddComment={handleAddComment}
                        ref={commentFormWrapperRef}
                        userAccessRef={userAccessRef}
                        users={users} 
                    />
                    <CommentWrapperWrap
                        canvas={canvas}
                        changeSelectedComment={(data) => setSelectedComment(data)}
                        currentUserId={userId}
                        handleAddReply={handleAddReply}
                        handleDeleteComment={handleDeleteComment}
                        handleMarkAsUnreadComment={handleMarkAsUnreadComment}
                        handleReadComment={handleReadComment}
                        handleResolveComment={handleResolveComment}
                        handleUpdateCommentContent={handleUpdateCommentContent}
                        ref={commentWrapperRef}
                        selectedComment={selectedComment}
                        selectedCommentIcon={selectedCommentIcon}
                        socket={socketRef.current}
                        socketRef={socketRef}
                        userAccessRef={userAccessRef}
                        users={users}
                        whiteBoardId={whiteBoardId}
                    />
                    <LeftDrawer 
                        canvas={canvas}
                        closeDrawer={() => setIsLeftDrawerOpen(false)}
                        commentResults={commentResults}
                        comments={comments}
                        currentUserId={userId}
                        handleDeleteComment={handleDeleteComment}
                        handleReadAllComments={handleReadAllComments}
                        handleUpdateCommentContent={handleUpdateCommentContent}
                        hideComments={hideComments}
                        hideResolvedComments={hideResolvedComments}
                        opened={isLeftDrawerOpen}
                        selectedCommentIcon={selectedCommentIcon}
                        setCommentResults={setCommentResults}
                        setComments={setComments}
                        setSelectedCommentIcon={setSelectedCommentIcon}
                        socketRef={socketRef}
                        toggleHideComments={toggleHideComments}
                        toggleHideResolvedComments={toggleHideResolvedComments}
                        userAccess={userAccessRef.current}
                        users={users}
                        whiteBoardId={whiteBoardId}
                        emitData={emitData}
                    />
                    { activeWindowState === MODAL_WINDOWS.FEEDBACK ? <FeedBack /> : null }
                    { (canvas && canvas.isCanvasInitalized && isUserSeenTutorial === false && activeWindowState === MODAL_WINDOWS.TUTORIAL) ? <TutorialErrorBoundary><TutorialCard slug={whiteBoardSlugId} /> </TutorialErrorBoundary> : null }
                    <ShortcutsList activeWindowState={activeWindowState} />
                    <ToastContainer
                        autoClose={4000}
                        className={clsx('toast-container', { 'minimap-opened': isMinimapOpen })}
                        closeOnClick={false}
                        draggable={false}
                        hideProgressBar
                        position="bottom-center" />
                    <ContextMenu canvas={canvas} userAccess={userAccessRef.current} handleToggleCommentDrawer={handleToggleCommentDrawer} activePageId={activePageId} />
                    <TextEditor 
                        canvas={canvas} />
                    <FlowChartModal
                        canvas={canvas} 
                        socketRef={socketRef}
                        userAccessRef={userAccessRef} />
                    { board?.migrationData?.nimaImportStatus === NIMA_IMPORT_STATUS.IN_PROGRESS ? <ThirdPartyMigrationModal socketRef={socketRef}/> : null }
                    {/* white board div */}
                </div>

                {(canvas && canvas.isCanvasInitalized) ? <RightDrawer handleCommentElementsPositioningCalculation={handleCommentElementsPositioningCalculation} canvas={canvas} socketRef={socketRef} /> : null}
                {(canvas && canvas.isCanvasInitalized) ? <CommentDrawer
                    canvas={canvas}
                    boardOptions={boardOptions}
                    hideComments={hideComments}
                    hideResolvedComments={hideResolvedComments}
                    toggleHideComments={toggleHideComments}
                    toggleHideResolvedComments={toggleHideResolvedComments}
                    handleUpdateCommentContent={handleUpdateCommentContent}
                    handleDeleteComment={handleDeleteComment}
                    handleReadAllComments={handleReadAllComments}
                    selectedCommentIcon={selectedCommentIcon}
                    setSelectedCommentIcon={setSelectedCommentIcon}
                    userAccessRef={userAccessRef}
                    users={users}
                    currentUserId={userId}
                    whiteBoardId={whiteBoardId}
                    handleToggleCommentDrawer={handleToggleCommentDrawer}
                    handleResolveComment={handleResolveComment}
                    handleReOpenThread={handleAddReply}
                    handleReadComment={handleReadComment}
                    handleMarkAsUnreadComment={handleMarkAsUnreadComment}
                    unreadCommentCount={unreadCommentCount}
                    setUnreadCommentCount={setUnreadCommentCount}
                    handleCloseFormInputWrapper={handleCloseFormInputWrapper}
                    socketRef={socketRef}
                /> : null}
            </div>
            {/* handle copy text */}
            <input 
                aria-hidden
                id="text-copy-input"
                type="text" />
        </>

    )
}

export default BoardWrapper;