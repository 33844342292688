/**
 *
 */
const LineIcon = () => {
    return (
        <svg
            fill="none"
            height="20"
            viewBox="0 0 19 17"
            width="20"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.5 1l-8 7.5M1.5 16l8-7.5"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeWidth="1.5"
            />
        </svg>
    );
}

export default LineIcon;