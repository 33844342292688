import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { handleAttachComment } from '../helpers/comments/HandleAttachComments';
import { changeCommentCount } from '../helpers/comments/UpdateCommentBadge';
import { getFabricObject } from '../helpers/FabricMethods';
import getToastIcon from '../helpers/media/GetToastIcon';
import eventEmitter from '../helpers/EventEmitter';
import { EMITTER_TYPES } from '../helpers/Constant';

/**
 * Custom hook for updating comments according to socket events and handlers
 * IMPORTANT NOTE: updating comments locally handling by handlers. 
 * @param {object} props
 * @param props.canvas
 * @param props.comments
 * @param props.userId
 * @param props.setComments
 * @param props.setSelectedCommentIcon
 * @param props.selectedComment
 * @param props.selectedCommentIcon
 * @param props.shouldHideResolvedComment
 * @param props.setSelectedComment
 * @param props.commentResults
 * @param props.setCommentResults
 * @param props.socketRef
 * @param props.activePageId
 * @param props.pageReferencesRef
 */
const useUpdateComment = ({
    canvas, 
    comments, 
    userId,
    setComments, 
    setSelectedCommentIcon, 
    selectedComment, 
    selectedCommentIcon, 
    shouldHideResolvedComment,
    setSelectedComment,
    commentResults,
    setCommentResults,
    socketRef,
    activePageId,
    pageReferencesRef
}) => {
    const [updatedComment, setUpdatedComment] = useState({});
    const [resolvedComment, setResolvedComment] = useState({});
    const [deletedComment, setDeletedComment] = useState({});
    const [addedComment, setAddedComment] = useState({});

    useEffect(() => {
        if (Object.entries(updatedComment).length === 0) return;

        // If the updated comment is on another page, needed get that rendering canvas.
        const pageCanvas = updatedComment.pageId !== activePageId ?
            pageReferencesRef.current.getPageCanvas(updatedComment.pageId)
            : canvas;

        let updatedCommentObj;
        if (updatedComment.parentUuid) {
            updatedCommentObj = getFabricObject(pageCanvas, 'commentID', updatedComment.parentUuid);
        } else {
            updatedCommentObj = getFabricObject(pageCanvas, 'commentID', updatedComment.uuid);
        }
        if (updatedCommentObj && updatedComment.colorCode && updatedComment.colorCode !== updatedCommentObj.colorCode) {
            updatedCommentObj.updateColor(updatedComment.colorCode, pageCanvas);
        } 
        if (updatedCommentObj && updatedComment.position && (
            updatedComment.position.x !== updatedCommentObj.left ||
            updatedComment.position.y !== updatedCommentObj.top
        )) {
            updatedCommentObj.set('left', updatedComment.position.x);
            updatedCommentObj.set('top', updatedComment.position.y);
            updatedCommentObj.setCoords();
            pageCanvas.renderAll();
            handleAttachComment(updatedCommentObj, pageCanvas, updatedCommentObj.attachedShape);
        } 
        if (updatedComment.content) {
            if (updatedComment.parentUuid) {
                setComments(oldComments => (oldComments.map(comment => {
                    if (comment.uuid === updatedComment.parentUuid) {
                        comment.replies = comment.replies.map(reply => {
                            if (reply.uuid === updatedComment.uuid && (
                                reply.content !== updatedComment.content ||
                                reply.taggedUserIds !== updatedComment.taggedUserIds
                            )) {
                                reply.content = updatedComment.content;
                                reply.taggedUserIds = updatedComment.taggedUserIds;
                                reply.isEdited = true;
                            }
                            return reply;
                        });
                    }
                    return comment;
                })));
                if (selectedComment && selectedComment.uuid === updatedComment.parentUuid) {
                    setSelectedComment(oldComment => {
                        const newReplies = oldComment.replies.map(reply => {
                            if (reply.uuid === updatedComment.uuid && (
                                reply.content !== updatedComment.content ||
                                reply.taggedUserIds !== updatedComment.taggedUserIds
                            )) {
                                reply.content = updatedComment.content;
                                reply.taggedUserIds = updatedComment.taggedUserIds;
                                reply.isEdited = true;
                            }
                            return reply;
                        })
                        return {
                            ...oldComment,
                            replies: newReplies
                        }
                    });
                }
            } else {
                setComments(oldComments => (oldComments.map(comment => {
                    if (comment.uuid === updatedComment.uuid && (
                        comment.content !== updatedComment.content ||
                        comment.taggedUserIds !== updatedComment.taggedUserIds
                    )) {
                        comment.content = updatedComment.content;
                        comment.taggedUserIds = updatedComment.taggedUserIds;
                        comment.isEdited = true;
                    }
                    return comment;
                })));

                if (selectedComment && selectedComment.uuid === updatedComment.uuid) {
                    setSelectedComment(oldComment => {
                        if (oldComment.content !== updatedComment.content ||
                            oldComment.taggedUserIds !== updatedComment.taggedUserIds) {
                            return {
                                ...oldComment,
                                content: updatedComment.content,
                                comment_content: updatedComment.comment_content,
                                taggedUserIds: updatedComment.taggedUserIds,
                                isEdited: true
                            }
                        }
                        return oldComment;
                    });
                }
            }

            const foundInResults = commentResults.find(({uuid}) => uuid === updatedComment.uuid);

            if (foundInResults && ( 
                foundInResults.content !== updatedComment.content || 
                foundInResults.taggedUserIds !== updatedComment.taggedUserIds)) {
                setCommentResults(oldResults => oldResults.map(resultComment => {
                    if (resultComment.uuid === updatedComment.uuid) {
                        resultComment.content = updatedComment.content;
                        resultComment.taggedUserIds = updatedComment.taggedUserIds;
                        resultComment.isEdited = true;
                    }
                    return resultComment;
                }));
                    
            }
        }
        setUpdatedComment({});
    }, [
        canvas, 
        updatedComment, 
        comments, 
        setComments, 
        selectedComment, 
        setSelectedComment,
        commentResults,
        setCommentResults,
        activePageId
    ]);

    useEffect(() => {
        if (Object.entries(resolvedComment).length === 0) return;

        // If the resolved comment is on another page, needed get that rendering canvas.
        const pageCanvas = resolvedComment.pageId !== activePageId ?
            pageReferencesRef.current.getPageCanvas(resolvedComment.pageId)
            : canvas;

        const commentUuid = resolvedComment.uuid;
        const commentObj = getFabricObject(pageCanvas, 'commentID', commentUuid);
        const filterComment = comments?.find(item => item?.uuid === commentUuid);
        
        let colorCode = filterComment?.colorCode;
        if((resolvedComment.colorCode && !resolvedComment.resolved )) colorCode = resolvedComment.colorCode
            
        commentObj?.resolveComment(
            resolvedComment.resolved,
            shouldHideResolvedComment,
            canvas,
            colorCode,
        );

        pageCanvas.renderAll();

        if (selectedComment && selectedComment.uuid === commentUuid) {
            setSelectedComment(comment => ({...comment, resolved: resolvedComment.resolved}));
        }

        setResolvedComment({});
    }, [
        canvas, 
        resolvedComment,
        comments,
        setComments,
        shouldHideResolvedComment,
        selectedComment,
        setSelectedComment,
        activePageId
    ]);

    useEffect(() => {
        if (Object.entries(deletedComment).length === 0) return;
        
        eventEmitter.fire('deleteComment', deletedComment);

        // If the deleted comment is on another page, needed get that rendering canvas.
        const pageCanvas = deletedComment.pageId !== activePageId ?
            pageReferencesRef.current.getPageCanvas(deletedComment.pageId)
            : canvas;

        //if a comment removed by another user in the same page, we need to display toast message
        if (deletedComment.triggeredBy === 'socketListener' && deletedComment.pageId === activePageId) {
            console.log('2222');
            toast.info('Comment deleted', {
                icon: getToastIcon('info'),
                className: 'wb_toast'
            });
        }

        if (deletedComment.hasOwnProperty('parentUuid') && deletedComment.parentUuid) {
            setComments(oldComments => (oldComments.map(comment => {
                if (comment.uuid === deletedComment.parentUuid) {
                    comment.replies = comment.replies.filter(reply => reply.uuid !== deletedComment.uuid);
                }
                return comment;
            })));

            if (selectedComment && selectedComment.uuid === deletedComment.parentUuid) {
                setSelectedComment(oldComment => ({
                    ...oldComment,
                    replies: oldComment.replies.filter(reply => reply.uuid !== deletedComment.uuid)
                }));
            }
            changeCommentCount(pageCanvas, deletedComment.parentUuid, userId, deletedComment.taggedUserIds, 'decrease');
            setDeletedComment({});
            
        } else {
            const commentUuid = deletedComment.uuid;
            const commentObj = getFabricObject(pageCanvas, 'commentID', commentUuid)
            pageCanvas.remove(commentObj);
            pageCanvas.renderAll();
            
            setComments(oldComments => oldComments.filter(comment => comment.uuid !== commentUuid));

            if (selectedCommentIcon && selectedCommentIcon.commentID === commentUuid) {
                setSelectedCommentIcon(null);
            }
            setDeletedComment({});
        }

        const foundInResults = commentResults.find(({uuid}) => uuid === deletedComment.uuid);
        if (foundInResults) {
            setCommentResults(oldResults => oldResults.filter(result => result.uuid !== foundInResults.uuid));
        }
    }, [
        deletedComment,
        canvas,
        comments,
        setComments,
        setSelectedCommentIcon,
        selectedCommentIcon,
        userId,
        selectedComment,
        setSelectedComment,
        commentResults,
        setCommentResults,
        activePageId
    ])

    useEffect(() => {
        const updateComments = () => {
            if (addedComment.isReply) {
                const commentObj = getFabricObject(canvas, 'commentID', addedComment.parentUuid);
                const zoom = canvas.getZoom();
                const scaleX = commentObj?.scaleX;
                const scaleY = commentObj?.scaleY;
                const width = commentObj?.width;
                const height = commentObj?.height;
                //update coordinates of new comment object
                setComments(oldComments => (oldComments.map(comment => {
                    if (comment.uuid === addedComment.parentUuid) {
                        let repliesUuids = comment.replies.map(item => item.uuid);
                        if (!comment.replies || !Array.isArray(comment.replies)) {
                            comment.replies = [];
                        }
                        if(!repliesUuids.includes(addedComment.uuid)){
                            comment.replies = [...comment.replies, addedComment];
                        }
                    }
                    return comment;
                })));
                
                //necessary to keep comment icon's original size
                commentObj?.set({ scaleX: scaleX * zoom, scaleY: scaleY * zoom, width, height });
                //update coordinates of new comment object
                commentObj?.addWithUpdate();
                commentObj?.setCoords();
                if (selectedComment?.uuid && selectedComment?.uuid === addedComment?.parentUuid && canvas.getObjects().find(i => i.commentID === addedComment.parentUuid)?.isOpened) {
                    setSelectedComment(oldComment => {
                        if (!oldComment.replies || !Array.isArray(oldComment.replies)) {
                            oldComment.replies = [];
                        }
                        oldComment.replies = [...oldComment.replies, addedComment];
                        return oldComment
                    });
                    // if comment is selected, read it
                    socketRef.current.emit('readComments', {uuid: addedComment.parentUuid});
                    eventEmitter.fire(EMITTER_TYPES.CHECK_UNREAD_COMMENTS)
                } else {
                    changeCommentCount(
                        canvas, 
                        addedComment?.parentUuid, 
                        userId, 
                        addedComment?.taggedUserIds, 
                        'increase',
                        addedComment.colorCode
                    );
                    //necessary to keep comment icon's original size
                    commentObj?.set({ scaleX: scaleX * zoom, scaleY: scaleY * zoom, width, height });
                    //update coordinates of new comment object
                    commentObj?.addWithUpdate();
                    commentObj?.setCoords();
                }
            } else {
                // QUESTION should we add the comment to the comment tab?
                setComments(oldComments => [...oldComments, addedComment]);
            }
        }
          
        if (Object.entries(addedComment).length > 0) {
            updateComments();
            setAddedComment({});
        }
    }, [addedComment, canvas, userId, setComments, selectedComment, selectedCommentIcon, setSelectedComment, socketRef])
   
    return [
        setUpdatedComment,
        setResolvedComment,
        setDeletedComment,
        (addedComment) => setAddedComment(addedComment)
    ]
}


export default useUpdateComment;
