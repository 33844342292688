
import axios from 'axios';
import { apiEndpoints } from '../helpers/Constant';
import { environment } from '../environment';
import { handleDecodingOfInviteEndpoint } from '../helpers/DecodeHelper';


/**
 * @param wbId
 * @param email
 * @param permission
 */
export async function inviteUser(wbId, email, permission) {
    try {
        const response = await axios.post(
            environment.REACT_APP_API_ENDPOINT + apiEndpoints.inviteUser.replace('{wbId}', wbId),
            { email, permission }
        );
        return handleDecodingOfInviteEndpoint(response);
    } catch (error) {
        let message = '';
        if (error.response.data) message = error.response.data.message;
        return {
            message: message.length > 0 ? message : 'An error has occurred',
            error: true
        }
    }
}

/**
 * @param whiteboardId
 * @param userId
 * @param permission
 */
export async function updateUserPermission(whiteboardId, userId, permission) {
    try {
        const response = await axios.post(environment.REACT_APP_API_ENDPOINT + apiEndpoints.updateUserPermission.replace('{wbId}', whiteboardId), {
            whiteboardId,
            userId,
            permission
        });

        return response;
    } catch (error) {
        return {}
    }
}

/**
 * @param whiteboardId
 * @param userId
 */
export async function removeUserAccess(whiteboardId, userId) {
    try {
        const response = await axios.post(environment.REACT_APP_API_ENDPOINT + apiEndpoints.removeUserAccess.replace('{wbId}', whiteboardId), {
            whiteboardId,
            userId
        });

        return response;
    } catch (error) {
        return {}
    }
}

/**
 * @param whiteboardId
 * @param permissions
 */
export async function publicShareBoard(whiteboardId, permissions) {
    try {
        const response = await axios.post(environment.REACT_APP_API_ENDPOINT + apiEndpoints.publicShareBoard.replace('{wbId}', whiteboardId),
            permissions
        );

        return response;
    } catch (error) {
        let message = '';

        if (error.response.data) message = error.response.data.error;

        return {
            message: message.length > 0 ? message : 'An error has occurred!',
            error: true,
        };
    }
}
